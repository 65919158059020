import { useState, useEffect, useLayoutEffect, useRef } from 'react';

const useGuestEntry = ({
  state,
  setstate,
  data,
  reservations,
  setOpenModal,
  setCustomAttribute,
  onBlur,
}: any) => {
  const [oldfilter, setOldFilter] = useState(data.attr || []);
  const [newCustomFilter, setNewCustomFilter] = useState(data.customAttr || []);

  const FilterArray = ['regular', 'vip', 'blacklist'];
  const StatusArray = ['Confirmed', 'Canceled', 'No-Shows', 'Umsatz'];

  const tabs = [
    {
      id: 0,
      label: 'Contact',
      badge: 0,
      translation: 'dashboard',
    },
    {
      id: 1,
      label: 'Comment',
      badge: 0,
      translation: 'dashboard',
    },
    {
      id: 2,
      label: 'Reservations',
      badge: 0,
      translation: 'dashboard',
    },
    {
      id: 4,
      label: 'Payments',
      badge: 0,
      translation: 'dashboard',
    },
    {
      id: 5,
      label: 'Ratings',
      badge: 0,
      translation: 'dashboard',
    },
  ];

  const handleChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (e) => {
    let { name, value } = e.target;

    if (name === 'guestComment') {
      setstate((st: any) =>
        !st ? null : { ...st, [name]: value, hostComment: '' }
      );
    }

    setstate((st: any) => (!st ? null : { ...st, [name]: value }));
  };

  const handleNewFilter = () => setOpenModal((value: any) => !value);

  let newFilter = [...oldfilter];

  const handleFilterClick = (updateFilter: any) => {
    if (!FilterArray.includes(updateFilter)) {
      return;
    }

    let index = newFilter.indexOf(updateFilter);

    if (index !== -1) {
      newFilter.splice(index, 1);
      setOldFilter(newFilter);
      setstate((st: any) => (!st ? null : { ...st, attr: newFilter }));
      onBlur(state?.id || 'noId', 'attr', newFilter);
    } else {
      newFilter = [...newFilter, updateFilter];
      setOldFilter(newFilter);
      setstate((st: any) => (!st ? null : { ...st, attr: newFilter }));
      onBlur(state?.id || 'noId', 'attr', newFilter);
    }
  };

  const handleFilterSubmit = (newCustAttr: string) => {
    let newUserFilter = [...newCustomFilter];
    let index = newUserFilter.indexOf(newCustAttr);
    if (index !== -1) {
      newUserFilter.splice(index, 1);
      setNewCustomFilter(newUserFilter);
      setstate((st: any) =>
        !st ? null : { ...st, customAttr: newUserFilter }
      );
      onBlur(state?.id || 'noId', 'customAttr', newUserFilter);
    } else {
      newUserFilter = [...newUserFilter, newCustAttr];
      setNewCustomFilter(newUserFilter);
      setstate((st: any) =>
        !st ? null : { ...st, customAttr: newUserFilter }
      );
      onBlur(state?.id || 'noId', 'customAttr', newUserFilter);
    }
    setOpenModal(false);
    setCustomAttribute('');
  };

  let newCustUserFilter = [...newCustomFilter];
  const handleClose = (oldCustomAttr: string) => {
    let index = newCustUserFilter.indexOf(oldCustomAttr);
    newCustUserFilter.splice(index, 1);
    setNewCustomFilter(newCustUserFilter);
    setstate((st: any) =>
      !st ? null : { ...st, customAttr: newCustUserFilter }
    );
    onBlur(state?.id || 'noId', 'customAttr', newCustUserFilter);
  };

  return {
    tabs,
    oldfilter,
    newCustomFilter,
    newFilter,
    newCustUserFilter,
    FilterArray,
    StatusArray,
    handleChange,
    handleNewFilter,
    handleFilterSubmit,
    handleClose,
    handleFilterClick,
  };
};
export default useGuestEntry;
