import React from "react";
import Grid, { GridProps } from "@material-ui/core/Grid";
import "./styles.scss";

const GridContainer = ({ children, container, ...props }: GridProps) => {
  return (
    <Grid container spacing={2} {...props}>
      {children}
    </Grid>
  );
};

export default GridContainer;
