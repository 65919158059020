import React, { useContext, useMemo } from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import Box from "Components/Atoms/Box";
import Typography from "Components/Atoms/Typography";
import { dateHelper, noop } from "utils/helper";
import "./styles.scss";

interface DayProps {
  disabled: boolean;
  date: string;
  shift: boolean;
  active: boolean;
  onClick?: (newDate: string, endDate?: string) => void;
  wDay: number;
  className?: "first-day" | "last-day" | "day-in-between";
}

const Day = ({
  disabled,
  date,
  shift,
  onClick = noop,
  active,
  wDay,
  className,
}: DayProps) => {
  const day = date.split("-")[2];

  const today = date === dateHelper();

  // const { restaurantId } = useContext(RestaurantContext);
  // const [profileDoc] = useDocument<ProfileSettings>(
  //   `restaurants/${restaurantId}/settings`,
  //   `profile`
  // );
  // const profileData = profileDoc.data as ProfileSettings;
  // console.log("heyyyyy", profileData.contact);

  // const openingDays = useMemo(() => {
  //   if (!profileData || profileData === null) {
  //     return [];
  //   } else {
  //     return [...profileData.openingHours];
  //   }
  // }, [profileData]);

  // let isOpened = openingDays[wDay] === null ? true : false;

  return (
    <Box flex className="flex-column" width="24px" style={{ height: 31 }}>
      <ButtonBase
        onClick={() => onClick(date)}
        className={`day day-btn ${active ? "active" : ""} ${
          today ? "today" : ""
        } ${className}`}
      >
        <Typography
          variant="text-3"
          weight="medium"
          color={disabled ? "disabled" : "textPrimary"}
          textAlign="center"
        >
          {parseInt(day)}
        </Typography>
      </ButtonBase>
      {/* !isOpened && !disabled */}
      {shift && (
        <span
          style={{
            width: 6,
            height: 6,
            borderRadius: 3,
            backgroundColor: "var(--color-tertiary)",
          }}
        />
      )}
    </Box>
  );
};

export default Day;
