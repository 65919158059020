import { SvgIcon, SvgIconProps } from "@material-ui/core";
import { ThemeContext } from "Contexts/ThemeContext";
import React, { useContext } from "react";

const EmptyState = (props: SvgIconProps) => {
  const { darkMode } = useContext(ThemeContext);

  const color1 = darkMode ? "#1A202C" : "#F7FAFC";
  const color2 = darkMode ? "#718096" : "#E2E8F0";
  const color3 = darkMode ? "#4A5568" : "#CBD5E0";

  return (
    <SvgIcon
      width="56px"
      height="56px"
      viewBox="0 0 56 56"
      fill="none"
      {...props}
    >
      <circle cx="28" cy="28" r="28" fill={color3} />
      <path
        d="M38.1091 54.1114C34.3055 55.584 30.2261 56.2078 26.1562 55.9392C22.0863 55.6706 18.1243 54.5161 14.5472 52.5565C10.97 50.5968 7.86424 47.8793 5.447 44.594C3.02976 41.3088 1.35945 37.5351 0.552904 33.5369C-0.253646 29.5387 -0.176961 25.4126 0.777594 21.4471C1.73215 17.4816 3.54153 13.7726 6.07917 10.5794C8.61681 7.38622 11.8214 4.786 15.4689 2.9606C19.1164 1.13521 23.1186 0.128727 27.1956 0.0115561L28 28L38.1091 54.1114Z"
        fill={color2}
      />
      <path
        d="M0 28C-3.99341e-07 32.5679 1.11758 37.0665 3.25526 41.1034C5.39295 45.1402 8.48582 48.5928 12.2641 51.1599C16.0425 53.7271 20.3915 55.3309 24.9319 55.8314C29.4723 56.3319 34.0663 55.714 38.3131 54.0315L28 28L0 28Z"
        fill={color1}
      />
    </SvgIcon>
  );
};

export default EmptyState;
