import { useContext, useMemo } from "react";
import { Translations } from "utils/types";
import { LanguageContext } from "../Contexts/LanguageContext";
import { useTranslation } from "react-i18next";

type TranslationObjectInner = {
  title?: string;
  description?: string;
  tags?: string;
  label?: string;
};

type TranslationObject<T = TranslationObjectInner> = {
  [key: string]: T;
};

export function translationsHelper<T = TranslationObjectInner>(
  language: string,
  translationObject: TranslationObject<T>
) {
  if (typeof translationObject !== "object") {
    return {} as T;
  }
  if (language in translationObject) {
    return translationObject[language];
  } else if ("de" in translationObject) {
    return translationObject.de;
  } else if ("en" in translationObject) {
    return translationObject.en;
  } else if (Object.keys(translationObject).length) {
    return translationObject[Object.keys(translationObject)[0]];
  } else {
    return {} as T;
  }
}

function useTranslations<T = TranslationObjectInner>(
  translationObject: TranslationObject<T>
) {
  const { language } = useContext(LanguageContext);
  const translation = useMemo(
    () => translationsHelper<T>(language, translationObject),
    [language, translationObject]
  );
  return translation;
}
export default useTranslations;
