import React, { useContext, useMemo } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import {
  FIXED_CANCELABLE_WARNINGS,
  ReservationActionTypes,
  ReservationContext,
} from 'Contexts/ReservationContext';
import { Close, Warning as Icon } from '@material-ui/icons';
import Typography from 'Components/Atoms/Typography';
import { IconButton } from '@material-ui/core';

export type WarningProps = {
  id: string;
  hide?: boolean;
  style?: React.CSSProperties;
};

const Warning: React.FC<WarningProps> = ({ id, hide = false, style = {} }) => {
  const { warnings, onReservationAction, showWarnings } =
    useContext(ReservationContext);

  const warning = useMemo(() => {
    return warnings.filter((w) => w.id === id && w.text);
  }, [warnings, id]);

  const cancelWarning = async (text: string) => {
    onReservationAction(id, ReservationActionTypes.CANCEL_WARNING, text);
  };
  if (!warning.length || (hide && !showWarnings)) return null;

  return (
    <Box style={{ padding: '8px 8px 0px 8px', ...style }}>
      {warning.map((w, i) => (
        <Box
          key={i}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            gap: 8,
            alignItems: 'center',
            padding: w.cancelable ? '4px 8px 4px 12px' : '8px 12px',
            backgroundColor: 'var(--color-warning)',
            borderRadius: 4,
            marginBottom: 8,
          }}
        >
          <Icon style={{ height: 16, width: 16 }} />
          <Typography
            variant="text-3"
            style={{ flexGrow: 10 }}
            block
            translation="reservations"
          >
            {w.text}
          </Typography>
          {w.cancelable ||
            (FIXED_CANCELABLE_WARNINGS.includes(w.text ?? '') && (
              <IconButton
                size="small"
                onClick={() => cancelWarning(w.text ?? '')}
              >
                <Close fontSize="small" />
              </IconButton>
            ))}
        </Box>
      ))}
    </Box>
  );
};

export default Warning;
