import Box from 'Components/Atoms/Box';
import Button from 'Components/Atoms/Button';
import IconButton from 'Components/Atoms/IconButton';
import { CloseRounded, Delete } from 'Components/Atoms/Icons';
import Select from 'Components/Atoms/Select';
import TextField from 'Components/Atoms/TextField';
import Typography from 'Components/Atoms/Typography';
import { AuthContext } from 'Contexts/AuthContext';
import useRestaurant from 'CustomHooks/useRestaurant';
import useTranslations from 'CustomHooks/useTranslations';
import React, { useContext, useMemo, useState } from 'react';
import { Guest } from 'types/reservations';
import ConfirmationModal from './ConfirmationModal';
import CustomTagModal from './CustomTagModal';
import FixedTableModal, { FixedTable } from '../../FixedTableModal';

type ContactProps = {
  onBlur: (
    id: string,
    key: string,
    newValue: string | string[] | FixedTable
  ) => void;
  state: Guest | null;
  handleChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  FilterArray: string[];
  oldfilter: any[];
  handleFilterClick: (updateFilter: any) => void;
  newCustomFilter: any[];
  handleClose: (oldCustomAttr: string) => void;
  handleNewFilter: () => void;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleFilterSubmit: (newCustAttr: string) => void;
  customAttribute: string;
  setCustomAttribute: React.Dispatch<React.SetStateAction<string>>;
  handleDelete: (guestId: string) => Promise<void>;
};

const Contact = ({
  onBlur,
  state,
  handleChange,
  FilterArray,
  oldfilter,
  handleFilterClick,
  newCustomFilter,
  handleClose,
  handleNewFilter,
  openModal,
  setOpenModal,
  handleFilterSubmit,
  customAttribute,
  setCustomAttribute,
  handleDelete,
}: ContactProps) => {
  const handleSelect = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    if (e.target.name && typeof e.target.value === 'string') {
      onBlur(state?.id || 'noId', e.target.name, e.target.value);
    }
  };

  const { reservationSettings } = useRestaurant();

  const { isAdmin } = useContext(AuthContext);
  const [openConfirmationModal, setopenConfirmationModal] =
    useState<boolean>(false);

  const { title } = useTranslations({
    en: {
      title: 'Language',
      label: 'en',
    },
    fr: {
      title: 'Langue',
      label: 'fr',
    },
    de: {
      title: 'Sprache',
      label: 'de',
    },
  });

  const { missingCustomAttr, customAttr } = useMemo(() => {
    return {
      missingCustomAttr: newCustomFilter.filter(
        (x) => !reservationSettings?.customAttr?.includes(x)
      ),
      customAttr: reservationSettings?.customAttr.sort(
        (a, b) =>
          (newCustomFilter.includes(a) ? 0 : 1) -
          (newCustomFilter.includes(b) ? 0 : 1)
      ),
    };
  }, [reservationSettings, newCustomFilter]);

  return (
    <>
      <Box style={{ paddingRight: 14 }}>
        <TextField
          fullWidth
          className="textFieldMargin"
          labelTranslation="common"
          label="Name"
          onBlur={(e) =>
            onBlur(state?.id || 'noId', e.target.name, e.target.value)
          }
          onChange={handleChange}
          name="name"
          value={state?.name || ''}
        />
      </Box>

      <Box className="flex-column">
        <Box flex>
          <TextField
            fullWidth
            className="textFieldMargin"
            labelTranslation="common"
            label="Email"
            onBlur={(e) =>
              onBlur(state?.id || 'noId', e.target.name, e.target.value)
            }
            onChange={handleChange}
            name="email"
            value={state?.email || ''}
          />
          <TextField
            fullWidth
            className="textFieldMargin"
            labelTranslation="common"
            label="Phone Number"
            onBlur={(e) =>
              onBlur(state?.id || 'noId', e.target.name, e.target.value)
            }
            onChange={handleChange}
            name="phone"
            value={state?.phone || ''}
          />
        </Box>
        <Box flex style={{ alignItems: 'center' }}>
          <Select
            options={[
              { id: 'de', label: 'German', translation: 'common' },
              { id: 'en', label: 'English', translation: 'common' },
              { id: 'fr', label: 'French', translation: 'common' },
            ]}
            value={state?.preferredLanguage || ''}
            name="preferredLanguage"
            onChange={handleSelect}
            className="selectInput guest-contact-select"
            label={title}
            fullWidth
          />
          <TextField
            fullWidth
            className="textFieldMargin"
            labelTranslation="common"
            label="Company"
            onBlur={(e) =>
              onBlur(state?.id || 'noId', e.target.name, e.target.value)
            }
            onChange={handleChange}
            name="company"
            value={state?.company || ''}
          />
        </Box>
        <Box flex>
          <TextField
            fullWidth
            className="textFieldMargin"
            labelTranslation="common"
            label="Birthday"
            onBlur={(e) =>
              onBlur(state?.id || 'noId', e.target.name, e.target.value)
            }
            onChange={handleChange}
            name="birthday"
            value={state?.birthday || ''}
            type="text"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            fullWidth
            className="textFieldMargin"
            labelTranslation="common"
            label="Anniversary"
            onBlur={(e) =>
              onBlur(state?.id || 'noId', e.target.name, e.target.value)
            }
            name="anniversary"
            value={state?.anniversary || ''}
            type="text"
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box flex>
          <FixedTableModal
            value={state?.hasFixedTables}
            onChange={(hasFixedTables) =>
              onBlur(state?.id || 'noId', 'hasFixedTables', hasFixedTables)
            }
            className="textFieldMargin"
            fullWidth
          />
          <Box className="textFieldMargin" style={{ width: '100%' }}></Box>
        </Box>
      </Box>
      <Box flex style={{ alignItems: 'flex-start' }}>
        <Box className="AttributeContainer">
          {FilterArray.map(
            (newFilter: any, index: React.Key | null | undefined) => (
              <Box
                key={index}
                className={`flex center filterButton ${
                  oldfilter.includes(newFilter) && 'filterApplied'
                } `}
                width="auto"
                padding="sm"
                onClick={() => handleFilterClick(newFilter)}
              >
                <Typography
                  variant="text-3"
                  translation="takeAway"
                  weight="medium"
                  style={{ textTransform: 'capitalize' }}
                >
                  {newFilter}
                </Typography>
              </Box>
            )
          )}
          {customAttr?.map((x) => (
            <Box
              key={x}
              className={`flex center filterButton ${
                newCustomFilter.includes(x) && 'filterApplied'
              } `}
              width="auto"
              padding="sm"
              onClick={() =>
                newCustomFilter.includes(x)
                  ? handleClose(x)
                  : handleFilterSubmit(x)
              }
            >
              <Typography
                variant="text-3"
                translation="takeAway"
                weight="medium"
                style={{ textTransform: 'capitalize' }}
              >
                {x}
              </Typography>
            </Box>
          ))}
          {missingCustomAttr.length > 0 &&
            missingCustomAttr.map(
              (newCustFilter: any, index: React.Key | null | undefined) => (
                <Box
                  key={index}
                  className={`flex center filterButton ${
                    newCustomFilter.includes(newCustFilter) && 'filterApplied'
                  } `}
                  width="auto"
                  padding="sm"
                >
                  <Typography
                    variant="text-3"
                    translation="takeAway"
                    weight="medium"
                    style={{ textTransform: 'capitalize' }}
                  >
                    {newCustFilter}
                  </Typography>
                  <IconButton onClick={() => handleClose(newCustFilter)}>
                    <CloseRounded
                      color="action"
                      style={{ height: 14, width: 14 }}
                    />
                  </IconButton>
                </Box>
              )
            )}
        </Box>
        <Box className="flex-column customAttribute" margin="sm" padding="sm">
          <Typography
            variant="text-3"
            weight="regular"
            onClick={handleNewFilter}
            textAlign="right"
            color="subdued"
          >
            Add Custom Attributes
          </Typography>
          {isAdmin && (
            <Box className="delete-guest-container">
              <Button
                className="delete-guest-btn"
                endIcon={() => <Delete style={{ marginLeft: 8 }} />}
                onClick={() => setopenConfirmationModal(true)}
              >
                Delete Guest
              </Button>
            </Box>
          )}
        </Box>
      </Box>

      {openConfirmationModal && (
        <ConfirmationModal
          guestName={state?.name || ''}
          guestId={state?.id || ''}
          handleDeleteGuest={handleDelete}
          {...{ openConfirmationModal, setopenConfirmationModal }}
        />
      )}

      {openModal && (
        <CustomTagModal
          {...{
            openModal,
            setOpenModal,
            handleFilterSubmit,
            setCustomAttribute,
            customAttribute,
          }}
        />
      )}
    </>
  );
};

export default Contact;
