import React, { useMemo } from "react";
import Box from "Components/Atoms/Box";
import { Reservation } from "types/reservations";
import "./styles.scss";
import Typography from "Components/Atoms/Typography";
import IconButton from "Components/Atoms/IconButton";
import { CheckCircle, Seat } from "Components/Atoms/Icons";
import { noop } from "utils/helper";
import { ReservationActionTypes } from "Contexts/ReservationContext";

export type SmallReservationCardProps = {
  reservation: Reservation;
  handleAction: (
    id: string,
    type: ReservationActionTypes,
    payload?: any
  ) => void;
};

const SmallReservationCard = ({
  reservation,
  handleAction = noop,
}: SmallReservationCardProps) => {
  const {
    id = "",
    currentStatus = "",
    time = "",
    guests = 0,
    guest,
    reservationLength = 4,
  } = reservation;

  const coloredLogo = useMemo(() => {
    return ["normal", "done", "failed"].includes(currentStatus);
  }, [currentStatus]);

  const resaHeight = useMemo(() => {
    return reservationLength * 12;
  }, [reservationLength]);

  return (
    <Box
      outline
      elevation={0}
      className={`resa-${currentStatus} reservation-card space-between small-card`}
      style={{ padding: "6px 8px", height: resaHeight }}
      fullSize
    >
      <Box className="flex-column flex-start">
        <Typography
          variant="text-5"
          className={coloredLogo ? "text-subdued" : "text-white"}
        >
          {time} • {guests} Pax
        </Typography>
        <Typography
          variant="text-3"
          className={coloredLogo ? "" : "text-white"}
        >
          {guest?.name || ""}
        </Typography>
      </Box>
      <Box style={{ width: "fit-content" }}>
        {currentStatus === "seated" ? (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleAction(id, ReservationActionTypes.MARK_AS_DONE);
            }}
            size="small"
            className={coloredLogo ? " text-subdued" : " text-white"}
          >
            <CheckCircle />
          </IconButton>
        ) : (
          currentStatus !== "done" && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleAction(id, ReservationActionTypes.SEAT);
              }}
              size="small"
              className={coloredLogo ? " text-subdued" : " text-white"}
            >
              <Seat />
            </IconButton>
          )
        )}
      </Box>
    </Box>
  );
};

export default SmallReservationCard;
