import React, { useState } from "react";
import Card from "Components/Molecules/Card";
import { Forecast } from "utils/types";
import MobileToday from "./Components/MobileToday";
import Box from "Components/Atoms/Box";
import Typography from "Components/Atoms/Typography";
import { useHistory } from "react-router-dom";
import Day from "../TotalReservationsCard/Components/Day";
import Divider from "Components/Atoms/Divider";
import useStatistics from "CustomHooks/useStatistics";
import MobileReservationModal from "App/Dashboard/Components/MobileReservationModal";

import "./styles.scss";

export type MobileTotalReservationsCardProps = {
  forecast: null | Forecast;
  totalReservations?: number;
  update?: () => void;
};

const MobileTotalReservationsCard = ({
  update,
  forecast = null,
  totalReservations,
}: MobileTotalReservationsCardProps) => {
  const [today = null, ...days] = forecast?.days || [];
  const history = useHistory();
  const isQuicklookPage: boolean = window.location.pathname.includes(
    "/quicklook"
  )
    ? true
    : false;

  const [dateToModify, setDateToModify] = useState<string>("");
  const { openModal, setOpenModal } = useStatistics(dateToModify, update);

  const handleAction = (date: string) => {
    setDateToModify(date);
  };

  return (
    <Card
      title="Total Reservations"
      hideHeader={true}
      titleTranslation="dashboard"
      className="mobile-total-res-card"
      onClick={() => history.push("/m/quicklook")}
      bottomPartClassName="bottom-part"
      bottomPart={
        isQuicklookPage && (
          <>
            <Divider
              margin="md"
              style={{ borderBottom: "solid 0.25px var(--color-border)" }}
            />
            <Box style={{ overflowX: "scroll" }}>
              <Box inlineFlex style={{ padding: "0px 16px" }}>
                {days.map((day) =>
                  day ? (
                    <Day
                      key={day.date}
                      {...day}
                      openReservationModal={() => setOpenModal(day.date)}
                      handleAction={handleAction}
                    />
                  ) : (
                    <></>
                  )
                )}
              </Box>
            </Box>
          </>
        )
      }
    >
      {!!today ? (
        <>
          <MobileToday
            today={today}
            totalReservations={totalReservations}
            updatedAt={forecast?.updatedAt}
            openReservationModal={() => setOpenModal(today.date)}
            handleAction={handleAction}
          />
          {openModal && (
            <MobileReservationModal
              isReservationModalOpen={!!openModal}
              update={update}
              dateToModify={dateToModify}
              closeReservationModal={() => setOpenModal(null)}
            />
          )}
        </>
      ) : (
        <Box style={{ height: 270 }}>
          <Typography variant="text-3" color="subdued" translation="dashboard">
            Currently, there is no data to show
          </Typography>
        </Box>
      )}
    </Card>
  );
};

export default MobileTotalReservationsCard;
