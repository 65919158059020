import React, { useCallback } from "react";
import "./styles.scss";

import Box from "Components/Atoms/Box";
import { AnyShift, Date as DayType } from "gastronaut-shared/types/helper/shifts";
import Typography from "Components/Atoms/Typography";
import dayjs from "dayjs";

import { blockToTime, dateHelper } from "utils/helper";
import {
  Comment,
  Create,
  Done,
  LocalActivity,
  Lock,
  NotInterested,
  ShoppingBasket,
  Star,
} from "@material-ui/icons";
import { Moped } from "Components/Atoms/Icons";
import { isShiftDoneHandler } from "../MultipleShiftsSidebar";
import { CalendarShift } from "../Week";
import { ActiveShift } from "CustomHooks/useCalendarDates";

interface ShiftsListViewProps {
  days: DayType<AnyShift>[];
  currentShift: null | { date: string; shiftId: string };
  handleShiftClick: (date: string, shiftId: string) => void;
  activeShift?: ActiveShift<AnyShift> | undefined;
}
const ShiftsListView = ({
  days,
  currentShift,
  handleShiftClick,
  activeShift,
}: ShiftsListViewProps) => {
  const getStyles = useCallback(
    (id: string, date: string) => {
      if (activeShift) {
        if (activeShift.shift?.id === id) {
          if (
            (activeShift.allUpcomingShifts || activeShift.type === "new") &&
            date >= activeShift.date
          ) {
            return {
              border: "1px solid var(--color-primary)",
              outline: "2px solid var(--color-primary)",
            };
          } else if (activeShift.date === date) {
            return {
              border: "1px solid var(--color-primary)",
              outline: "2px solid var(--color-primary)",
            };
          }
        }
      } else if (currentShift) {
        if (currentShift.shiftId === id && currentShift.date === date)
          return {
            border: "1px solid var(--color-primary)",
            outline: "1px solid var(--color-primary)",
          };
      }

      return { border: "1px solid transparent" };
    },
    [activeShift, currentShift]
  );

  return (
    <Box className="shifts-list-view-wrapper">
      <Box className="shifts-list-view-container">
        {days.map((day) => {
          const isToday = day.date === dateHelper(); // new Date().toISOString().split("T")[0];
          return (
            <Box className="day-row-container">
              <Box className="day-row-date">
                <Typography
                  variant="h3"
                  style={{ marginRight: 10 }}
                  color={isToday ? "primary" : "textPrimary"}
                >
                  {dayjs(day.date).format("D")}
                </Typography>
                <Typography
                  variant="text-3"
                  color={isToday ? "primary" : "subdued"}
                  textAlign="center"
                  style={{ marginTop: 4 }}
                >
                  {dayjs(day.date).format("ddd, MMMM")}
                </Typography>
              </Box>
              <Box className="day-row-shifts">
                {day.shifts
                  .sort((a, b) => a.start - b.start)
                  .map((shift) => (
                    <Box
                      className="day-row-shift"
                      onClick={() => handleShiftClick(day.date, shift.id)}
                      style={{
                        opacity: shift.disabled ? 0.5 : 1,
                        ...getStyles(shift.id, day.date),
                        borderRadius: 4,
                      }}
                    >
                      <div
                        className="shift-list-item-circle"
                        style={{ background: shift.color }}
                      />
                      <Typography variant="text-4" color="subdued">
                        {blockToTime(shift.start)} - {blockToTime(shift.close)}
                      </Typography>
                      <Typography variant="text-3" weight="bold">
                        {shift.name}
                      </Typography>
                      {shift?.commentOnly && (
                        <Comment width={10} height={10} style={{ display: "block" }} />
                      )}
                      {!shift.regular && !shift?.commentOnly && (
                        <Star width={10} height={10} style={{ display: "block" }} />
                      )}
                      {shift.edited && (
                        <Create width={10} height={10} style={{ display: "block" }} />
                      )}
                      {!!shift.closed && (
                        <NotInterested
                          width={10}
                          height={10}
                          style={{ display: "block" }}
                        />
                      )}
                      {!!shift?.overwrite?.blockReservations && (
                        <Lock
                          width={10}
                          height={10}
                          style={{
                            display: "block",
                            minWidth: 14,
                          }}
                        />
                      )}

                      {!!shift?.additional?.eventV02?.active && (
                        <LocalActivity
                          width={10}
                          height={10}
                          style={{
                            display: "block",
                            minWidth: 14,
                          }}
                        />
                      )}
                      {shift?.deliveryMethods?.includes("delivery") && (
                        <Moped
                          width={14}
                          height={14}
                          style={{
                            display: "block",
                            minWidth: 14,
                          }}
                        />
                      )}
                      {shift?.deliveryMethods?.includes("pickup") && (
                        <ShoppingBasket
                          width={14}
                          height={14}
                          style={{
                            display: "block",
                            fontSize: 14,
                          }}
                        />
                      )}
                      {!!shift?.advert?.active && (
                        <LocalActivity
                          width={10}
                          height={10}
                          style={{
                            display: "block",
                            minWidth: 14,
                          }}
                        />
                      )}
                      {isShiftDoneHandler({ ...shift, date: day.date }) && (
                        <Done
                          width={10}
                          height={10}
                          style={{
                            display: "block",
                            minWidth: 14,
                          }}
                        />
                      )}
                    </Box>
                  ))}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default ShiftsListView;
