import { Day } from 'utils/types';
import server from '../index';

export type Forecast = {
  updatedAt: number;
  days: Day[];
  message?: null | string;
  error?: boolean;
};

const loadForecast = async (restaurantId: string) => {
  try {
    const response = await server.get(
      `/v03/statistics/${restaurantId}/forecastV02`
    );

    const data: Forecast = response.data;

    if (data.error) {
      throw new Error(data.message || '');
    }

    return data;
  } catch (error: any) {
    if (error.isAxiosError) {
      throw new Error(error?.response?.data?.message || error.message);
    } else {
      throw error;
    }
  }
};

export default loadForecast;
