import React, { useRef, useEffect, useState } from "react";
import { dateHelper, timeHelper } from "utils/helper";

const useTimer = (date: string = dateHelper()) => {
  const [time, settime] = useState(date === dateHelper() ? timeHelper() : null);
  const interval = useRef<any>(null);

  useEffect(() => {
    if (interval.current) {
      clearInterval(interval.current);
      interval.current = null;
    }

    if (date === dateHelper()) {
      settime(timeHelper());
      interval.current = setInterval(() => {
        console.log("timer");
        settime(timeHelper());
      }, 30000);
    } else {
      settime(null);
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = null;
      }
    };
  }, [date]);

  return time;
};

export default useTimer;
