import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const SpreadVertical = (props: SvgIconProps) => {
  return (
    <SvgIcon width="36" height="36" viewBox="0 0 36 36" fill="none">
      <rect width="36" height="36" rx="18" fill="#EDF2F7" />
      <path d="M8.625 8.625H27.375H8.625Z" fill="#011311" />
      <path d="M8.625 8.625H27.375" stroke="#011311" stroke-linecap="round" />
      <path d="M8.625 27.375H27.375H8.625Z" fill="#011311" />
      <path d="M8.625 27.375H27.375" stroke="#011311" stroke-linecap="round" />
      <path
        d="M27.375 13H8.625C8.27982 13 8 13.2798 8 13.625V22.375C8 22.7202 8.27982 23 8.625 23H27.375C27.7202 23 28 22.7202 28 22.375V13.625C28 13.2798 27.7202 13 27.375 13Z"
        fill="#011311"
      />
    </SvgIcon>
  );
};

export default SpreadVertical;
