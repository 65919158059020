import React, { useState } from "react";
import Box from "Components/Atoms/Box";
import "./styles.scss";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { NoShowModal } from "Contexts/ReservationContext";
import TextField from "Components/Atoms/TextField";
import InputAdornmentAtom from "Components/Atoms/InputAdornment";
import Typography from "Components/Atoms/Typography";
import Button from "Components/Atoms/Button";
import server from "utils/server";
import useToast from "CustomHooks/useToast";
import { Severity } from "Contexts/RestaurantContext";
import { AxiosError } from "axios";

export type NoShowModalProps = {
  noShowModal: null | NoShowModal;
  setNoShowModal: React.Dispatch<React.SetStateAction<NoShowModal | null>>;
  restaurantId: string | null;
};

const NoShowModalComponent: React.FC<NoShowModalProps> = ({
  noShowModal,
  setNoShowModal,
  restaurantId,
}) => {
  const toast = useToast();

  const [loading, setloading] = useState(false);

  const onSubmit = async () => {
    if (!noShowModal || loading) return;

    setloading(true);

    try {
      if (!noShowModal.amount || noShowModal.amount < 1)
        throw new Error("Gebühr kann nicht weniger als 1€ sein.");

      if (noShowModal.amount > 1000)
        throw new Error(
          "Gebühr kann nicht höher als 1000€ sein. Alternativ kann Stripe direkt genutzt werden"
        );

      if (!noShowModal.statement_descriptor)
        throw new Error("Zahlungsbeschreibung fehlt");

      await server.post(
        `/v03/reservations/chargeNoShow/${restaurantId}/${noShowModal?.reservationId}`,
        {
          amount: noShowModal.amount * 100,
          description: noShowModal.description,
          statement_descriptor: noShowModal.statement_descriptor,
          uid: noShowModal.uid,
        }
      );

      toast("No Show Zahlung wurde erfolgreich erstellt", Severity.SUCCESS);

      setNoShowModal(null);
    } catch (error: any) {
      toast(
        error?.response?.body?.message ?? error?.message ?? "",
        Severity.ERROR
      );
    }

    setloading(false);
  };

  return (
    <Dialog
      open={!!noShowModal}
      onClose={() => setNoShowModal(null)}
      title="No Show Gebühr"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        <Typography variant="h5">No Show Gebühr</Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Gebühr in €"
          value={noShowModal?.amount || ""}
          type="number"
          onChange={(e) =>
            setNoShowModal((noShowModal) =>
              !!noShowModal
                ? { ...noShowModal, amount: e.target.value as any }
                : null
            )
          }
          onBlur={() =>
            setNoShowModal((noShowModal) =>
              !!noShowModal
                ? { ...noShowModal, amount: noShowModal.amount || 0 }
                : null
            )
          }
          InputProps={{
            endAdornment: (
              <InputAdornmentAtom position="start">
                <Typography variant="text-2">€</Typography>
              </InputAdornmentAtom>
            ),
          }}
          fullWidth
          className="mg-bt-sm"
        />

        <TextField
          label="Zahlungsbeschreibung"
          value={noShowModal?.statement_descriptor || ""}
          helperText="Dieser Text wird auf dem Kontoauszug des Kunden angezeig"
          onChange={(e) =>
            setNoShowModal((noShowModal) =>
              !!noShowModal
                ? {
                    ...noShowModal,
                    statement_descriptor: e.target.value.slice(0, 22) as any,
                  }
                : null
            )
          }
          fullWidth
          className="mg-bt-sm"
          InputProps={{
            endAdornment: (
              <InputAdornmentAtom position="start">
                <Typography variant="text-2">
                  {(noShowModal?.statement_descriptor || "").length} / 22
                </Typography>
              </InputAdornmentAtom>
            ),
          }}
        />

        <TextField
          label="Beschreibung (nur Intern)"
          value={noShowModal?.description || ""}
          onChange={(e) =>
            setNoShowModal((noShowModal) =>
              !!noShowModal
                ? { ...noShowModal, description: e.target.value as any }
                : null
            )
          }
          fullWidth
          className="mg-bt-sm"
          multiline
          minRows={2}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setNoShowModal(null)}>Abbrechen</Button>
        <Button onClick={onSubmit} loading={loading} variant="primary">
          Einfordern
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NoShowModalComponent;
