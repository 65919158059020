import React, { useState } from "react";

function useLocalStorageState<T>(id: string, defaultVal: T) {
  const value = window.localStorage.getItem(id);

  if (!value) {
    window.localStorage.setItem(id, JSON.stringify({ value: defaultVal }));
  }

  const [state, setstate] = useState<T>(
    value ? JSON.parse(value)?.value : defaultVal
  );

  function changeState(param: ((oldState: T) => T) | T) {
    let value: T;

    if (typeof param === "function") {
      value = (param as any)(state);
    } else {
      value = param;
    }

    window.localStorage.setItem(id, JSON.stringify({ value }));

    setstate(value);
  }

  return [state, changeState] as [T, (param: ((oldState: T) => T) | T) => void];
}

export default useLocalStorageState;
