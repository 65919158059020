import { CircularProgress } from "@material-ui/core";
import Box from "Components/Atoms/Box";
import Button from "Components/Atoms/Button";
import InstagramColoredLogo from "Components/Atoms/Icons/InstagramColoredLogo";
import TextField from "Components/Atoms/TextField";
import Typography from "Components/Atoms/Typography";
import { ImageContext } from "Contexts/ImageContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import GridContainer from "Components/Atoms/GridContainer";
import GridItem from "Components/Atoms/GridItem";
import Checkbox from "Components/Atoms/Checkbox";

const InstagramImagesList = () => {
  const {
    images: imagesData,
    currentImage,
    setcurrentImage,
    multiple,
    selectedImages,
    setselectedImages,
    imageLoading,
    instagramID,
    setInstagramID,
    submitInstagramID,
    imageError,
    isFetching,
    fetchInstagramData,
  } = useContext(ImageContext);
  const [imageWidth, setImageWidth] = useState<number | null>(null);

  const { filteredData: images, loading, error } = imagesData;

  const imageRef = useRef<HTMLDivElement | null>(null);

  const handleImageClick = (id: string) => {
    setcurrentImage(id);
    if (multiple) {
      if (selectedImages.includes(id)) {
        setselectedImages((i) => i.filter((img) => img !== id));
      } else {
        setselectedImages((i) => [...i, id]);
      }
    }
  };

  useEffect(() => {
    setImageWidth(imageRef.current ? imageRef.current.offsetWidth : 152);
  });

  return (
    <Box
      style={{
        width: currentImage ? "calc(62% - 200px)" : "calc(100% - 200px)",
      }}
      padding={0}
      id="instagram-image-list"
    >
      {(loading || imageLoading) && (
        <Box className="center">
          <CircularProgress />
        </Box>
      )}
      {!loading && !images.length && (
        <Box className="no-instagram-screen" padding={30}>
          <div>
            <InstagramColoredLogo className="insta-logo" />
          </div>
          <Typography
            textAlign="center"
            variant="h4"
            block
            className="mb-16"
            translation="settings"
          >
            Download your Instagram Images
          </Typography>
          <Typography
            textAlign="center"
            variant="text-4"
            color="subdued"
            block
            className="mb-40"
            translation="settings"
          >
            By entering your Instagram Username we will check your Instagram
            Profile daily and add Images that have been uploaded so that you can
            easily use them in all products. Your profile needs to be public in
            order for it to work
          </Typography>
          {(error || imageError) && (
            <Box className="center">
              <Typography variant="text-2" color="error" translation={null}>
                {error ? error : imageError}
              </Typography>
            </Box>
          )}

          <TextField
            value={instagramID ?? ""}
            onChange={(e) => setInstagramID(e.target.value)}
            onBlur={submitInstagramID}
            placeholder="Search your username"
            className="insta-text-field"
          />
          <Button
            onClick={fetchInstagramData}
            variant="primary"
            loading={isFetching}
            translation="settings"
          >
            Fetch All Images
          </Button>
        </Box>
      )}
      {!loading && !!images.length && (
        <Box className="instagram-screen" padding={24}>
          <Box className="insta-header">
            <div className="flex" style={{ width: "fit-content" }}>
              <InstagramColoredLogo className="insta-logo" />
              <div className="text-part">
                <Typography
                  variant="h4"
                  block
                  className="mb-16"
                  translation="settings"
                >
                  Download your Instagram Images
                </Typography>
                <Typography
                  variant="text-4"
                  color="subdued"
                  block
                  translation="settings"
                >
                  By entering your Instagram Username we will check your
                  Instagram Profile daily and add Images that have been uploaded
                  so that you can easily use them in all products. Your profile
                  needs to be public in order for it to work
                </Typography>
              </div>
            </div>
            <div className="input-part">
              <TextField
                value={instagramID ?? ""}
                onChange={(e) => setInstagramID(e.target.value)}
                onBlur={submitInstagramID}
                placeholder="Search your username"
                className="mb-16"
              />
              <Button
                translation="settings"
                onClick={fetchInstagramData}
                loading={isFetching}
                className="refetch-btn"
              >
                Refetch images
              </Button>
            </div>
          </Box>
          {(error || imageError) && (
            <Box className="center">
              <Typography variant="text-2" color="error" translation={null}>
                {error ? error : imageError}
              </Typography>
            </Box>
          )}
          <GridContainer
            style={{
              marginTop: 24,
              height: "calc(90vh - 209px - 132px)",
              overflowY: "scroll",
            }}
          >
            {images.map((image, index) => (
              <GridItem
                xs={currentImage ? 4 : 3}
                className="image-container"
                style={{ overflow: "hidden", position: "relative" }}
                key={"image" + image.id}
              >
                {multiple && (
                  <Checkbox
                    label=""
                    checked={selectedImages.includes(image.id)}
                    color="primary"
                    className={
                      selectedImages.includes(image.id)
                        ? "image-checkbox checked"
                        : "image-checkbox"
                    }
                    onChange={() => handleImageClick(image.id)}
                  />
                )}
                <div
                  ref={index === 0 ? imageRef : null}
                  className={
                    image.id === currentImage ? "image active" : "image"
                  }
                  style={{
                    backgroundImage: `url("${image.url}")`,
                    height: imageWidth ? imageWidth : 152,
                  }}
                  onClick={() => handleImageClick(image.id)}
                />
                <Typography variant="text-4">{image.originalName}</Typography>
              </GridItem>
            ))}
          </GridContainer>
        </Box>
      )}
    </Box>
  );
};

export default InstagramImagesList;
