import React, {
  createContext,
  useState,
  useMemo,
  useEffect,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react';
import { dateHelper, daysInFuture, noop } from 'utils/helper';
import {
  ShiftType,
  Date,
  ReservationShift,
  GuestOrderingShift,
  OffersShift,
  TakeAwayShift,
  Shift,
  AnyShift,
} from 'types/shifts';
import useCalendarDates, { ActiveShift } from 'CustomHooks/useCalendarDates';
import { FloorPlan } from 'types/reservations';
import { db } from 'utils/firebase';
import { ReservationSettings } from 'types/settings';
import { Menu, MenuDraft } from '../types/menu';
import { RestaurantContext, Severity } from './RestaurantContext';
import { CalendarShift } from 'App/Calendar/Components/Week';
import { Event } from 'gastronaut-shared/types/documents/restaurants';

export function checkActiveShift(
  activeShift: ActiveShift<AnyShift> | undefined,
  shiftId: string | undefined,
  date: string
) {
  if (!activeShift) return false;
  if (activeShift.allUpcomingShifts || activeShift.type === 'new')
    return shiftId === activeShift.shift.id && date >= activeShift.date;
  else return shiftId === activeShift.shift.id && date === activeShift.date;
}

export enum View {
  WEEK = 'WEEK',
  LIST = 'LIST',
  // DAY = "DAY", // Not used currently
  // MONTH = "MONTH", // Not used currently
}

export enum ShiftActionType {
  EDIT_SHIFT = 0,
  OPEN_RESERVATIONS = 1,
  CLOSE_RESERVATIONS = 2,
  OPEN_TERRACE = 3,
  CLOSE_TERRACE = 4,
  DELETE_SHIFT = 5,
  NEW_SHIFT = 6,
  DUPLICATE_SHIFT = 7,
  RESTORE_SHIFT = 8,
  ADD_EVENT = 9,
  EDIT_EVENT = 10,
  BRING_BACK_FROM_DELETED = 11,
}

type Props = {
  children: React.ReactNode;
  restaurantId: string;
  date: string;
  onDateChange: (newDate: string) => void;
};

export interface CurrentShiftPropsType<T extends Shift = Shift> {
  date: Date<T>;
  shiftId: string;
  onShiftAction: (
    id: string,
    date: string,
    type: ShiftActionType,
    payload?: any
  ) => void;
  onClose: () => void;
}

type Context = {
  date: string;
  onDateChange: (newDate: string) => void;
  currentShift: null | { date: string; shiftId: string };
  setCurrentShift: Dispatch<
    SetStateAction<null | { date: string; shiftId: string }>
  >;
  handleShiftClick: (date: string, shiftId: string) => void;
  type: ShiftType;
  settype: Dispatch<SetStateAction<ShiftType>>;
  dates: Date<AnyShift>[];
  loading: boolean;
  error: null | string;
  nextWeek: VoidFunction;
  lastWeek: VoidFunction;
  setdates: React.Dispatch<React.SetStateAction<Date<AnyShift>[]>>;
  view: View;
  setview: Dispatch<SetStateAction<View>>;
  onShiftAction: (
    id: string,
    date: string,
    type: ShiftActionType,
    payload?: any
  ) => void;
  CurrentShiftProps: null | CurrentShiftPropsType;
  ActiveShiftProps: null | {
    activeShift: ActiveShift<AnyShift>;
    setactiveShift: Dispatch<React.SetStateAction<ActiveShift<AnyShift>>>;
    onClose: () => void;
    onSubmit: () => Promise<void>;
    settings?: {
      occassions: { id: string; title: string }[];
      spaces: { id: string; title: string }[];
      floorPlans: FloorPlan[];
      menues: MenuDraft[];
      maxGuestsOnWebsite: number;
    };
    spaces: { id: string; title: string }[];
  };
  handleNewShift: (dateId?: string, from?: string, till?: string) => void;
  closeNewShift: () => void;
  recentShifts: AnyShift[];
  newShiftFromTemplate: (
    id: string,
    action: 'fromTemplate' | 'noTemplate' | 'fromRecent' | 'commentTemplate'
  ) => void;
  newShiftSidebar: string | null;
  currentMultipleShifts: CalendarShift[] | null;
  setCurrentMultipleShifts: Dispatch<SetStateAction<CalendarShift[] | null>>;
  selectedMultiCard: string | null;
  setSelectedMultiCard: Dispatch<SetStateAction<string | null>>;
  eventTemplates: Event[];
};

export const CalendarContext = createContext<Context>({
  date: dateHelper(),
  onDateChange: noop,
  currentShift: null,
  setCurrentShift: noop,
  handleShiftClick: noop,
  type: ShiftType.RESERVATION,
  settype: noop,
  dates: [],
  loading: false,
  error: null,
  nextWeek: noop,
  lastWeek: noop,
  view: View.WEEK,
  setview: noop,
  onShiftAction: noop,
  CurrentShiftProps: null,
  ActiveShiftProps: null,
  handleNewShift: noop,
  closeNewShift: noop,
  recentShifts: [],
  newShiftFromTemplate: noop,
  newShiftSidebar: null,
  currentMultipleShifts: null,
  setCurrentMultipleShifts: noop,
  selectedMultiCard: null,
  setSelectedMultiCard: noop,
  setdates: noop,
  eventTemplates: [],
});

/*

    [x] - Handle Reservation Shift Click
    [x] - Handle Reservation Shift Actions
    [ ] - Show disabled Shifts ?
    [ ] - Load Occassions / Spaces
    [ ] - Edit Reservation
    [ ] - New Reservation
 
*/

//TO MODIFY
const loadSettings = async (restaurantId: string) => {
  try {
    const occassionPromise = db
      .collection(`restaurants/${restaurantId}/settingDrafts`)
      .doc('reservationsV02')
      .get()
      .then((doc) => {
        let data = doc.data() as ReservationSettings;
        if (!data) return { occassions: [], standardFloorPlan: null };

        let { occassions, standardFloorPlan = null } = data;
        if (!occassions.length && data.reservationType === 'light') {
          occassions.push({
            id: 'allgemein',
            title: 'Allgemein',
            hidden: false,
          });
        }

        return {
          occassions,
          standardFloorPlan,
          maxGuestsOnWebsite: data.maxGuestsOnWebsite,
        };
      });

    const floorPlanPromise = db
      .collection(`restaurants/${restaurantId}/floorPlanDrafts`)
      .get()
      .then((res) =>
        res.docs.map((doc) => ({ ...doc.data(), id: doc.id } as FloorPlan))
      );

    const menuesPromise = db
      .collection(`restaurants/${restaurantId}/menues`)
      .get()
      .then((res) =>
        res.docs.map((doc) => ({ ...doc.data(), id: doc.id } as Menu))
      );

    const [
      { occassions, standardFloorPlan, maxGuestsOnWebsite = 12 },
      floorPlans,
      menues,
    ] = await Promise.all([occassionPromise, floorPlanPromise, menuesPromise]);

    const spaces = floorPlans.reduce(
      (acc: { id: string; title: string }[], cV) => [
        ...acc,
        ...cV.spaces
          .filter((s) => !acc.find((s1) => s1.id === s.id))
          .map((s) => ({ id: s.id, title: s.name, floorPlan: cV.id })),
      ],
      []
    );

    return {
      spaces,
      standardFloorPlan,
      occassions,
      floorPlans,
      menues,
      maxGuestsOnWebsite,
    };
  } catch (error) {
    console.error(error);
    return {
      spaces: [],
      occassions: [],
      floorPlans: [],
      menues: [],
      standardFloorPlan: null,
      maxGuestsOnWebsite: 12,
    };
  }
};

const CalendarContextProvider: React.FC<Props> = ({
  children,
  restaurantId,
  date,
  onDateChange,
}) => {
  const [type, settype] = useState(ShiftType.RESERVATION);
  const [view, setview] = useState(View.WEEK);

  const [settings, setsettings] = useState<{
    occassions: { id: string; title: string }[];
    spaces: { id: string; title: string }[];
    standardFloorPlan: null | string;
    floorPlans: FloorPlan[];
    menues: MenuDraft[];
    maxGuestsOnWebsite: number;
  }>();

  const { alert, newToast } = useContext(RestaurantContext);

  useEffect(() => {
    loadSettings(restaurantId).then((settings) => setsettings(settings));
  }, [restaurantId]);

  const {
    dates,
    loading,
    error,
    onShiftAction,
    currentShift,
    setcurrentShift,
    activeShift,
    setactiveShift,
    saveActiveShift,
    handleNewShift,
    closeNewShift,
    recentShifts,
    newShiftFromTemplate,
    newShiftSidebar,
    currentMultipleShifts,
    setCurrentMultipleShifts,
    selectedMultiCard,
    setSelectedMultiCard,
    setdates,
    eventTemplates,
  } = useCalendarDates(
    restaurantId,
    type,
    date,
    view,
    settings?.occassions,
    onDateChange
  );

  const spaces = useMemo(() => {
    if (!settings) return [];

    const floorPlanSpaces = settings.floorPlans.reduce(
      (acc: Record<string, { id: string; title: string }[]>, cV) => {
        let spaces = cV.spaces.map((s) => ({ id: s.id, title: s.name }));

        return {
          ...acc,
          all: [
            ...acc.all,
            ...spaces.filter((s) => !acc.all.find((s1) => s1.id === s.id)),
          ],
          [cV.id]: spaces,
        };
      },
      { all: [] }
    );

    if (!activeShift || !settings?.standardFloorPlan) {
      return floorPlanSpaces.all ?? [];
    } else if (
      activeShift.shift.type === ShiftType.RESERVATION &&
      !(activeShift as any)?.shift?.additional?.floorPlan
    ) {
      return floorPlanSpaces[settings.standardFloorPlan] ?? [];
    } else {
      return floorPlanSpaces[settings.standardFloorPlan] ?? [];
    }
  }, [activeShift, settings]);

  const nextWeek = () => onDateChange(daysInFuture(7, date));

  const lastWeek = () => onDateChange(daysInFuture(-7, date));

  const handleShiftClick = (date: string, shiftId: string) => {
    if (activeShift) {
      newToast(
        'Save the current changes before to open another shift.',
        Severity.WARNING,
        'errors'
      );
      console.log('there is an active shift!');
      return;
    }
    setcurrentShift({ date, shiftId });
  };

  const CurrentShiftProps = useMemo(() => {
    if (!currentShift) return null;

    const date = dates.find((d) => d.date === currentShift.date);

    if (!date) {
      setcurrentShift(null);
      return null;
    }

    return {
      date,
      shiftId: currentShift.shiftId,
      onClose: () => setcurrentShift(null),
      onShiftAction,
      settings,
    };
  }, [currentShift, dates, settings, setcurrentShift]);

  const ActiveShiftProps = useMemo(() => {
    if (!activeShift) return null;

    return {
      activeShift,
      spaces,
      setactiveShift,
      onClose: () => setactiveShift(null),
      onSubmit: saveActiveShift,
      settings,
    };
  }, [activeShift, spaces, settings]);

  return (
    <CalendarContext.Provider
      value={{
        date,
        onDateChange,
        type,
        settype,
        dates,
        loading,
        error,
        nextWeek,
        lastWeek,
        currentShift,
        setCurrentShift: setcurrentShift,
        handleShiftClick,
        view,
        setview,
        onShiftAction,
        CurrentShiftProps,
        ActiveShiftProps,
        handleNewShift,
        closeNewShift,
        recentShifts,
        newShiftFromTemplate,
        newShiftSidebar,
        currentMultipleShifts,
        setCurrentMultipleShifts,
        selectedMultiCard,
        setSelectedMultiCard,
        setdates,
        eventTemplates,
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
};

export default CalendarContextProvider;
