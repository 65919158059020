import { useState, useEffect } from 'react';
import axios from 'axios';
import { ChangeLog } from 'api/Changelog/types';

type ChangelogsResponse = {
  id: string;
  createdTime: string;
  fields: ChangeLog;
};

const useChangelog = (): {
  changelog: ChangelogsResponse[];
  loading: boolean;
} => {
  const [changelog, setChangelog] = useState<ChangelogsResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchChangelog = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          // `https://api.airtable.com/v0/${process.env.AIRTABLE_BASE_KEY}/Changelog`,
          `https://api.airtable.com/v0/appq6x7Qb43vjUcp5/Changelog?view=Live`,
          {
            headers: {
              // Authorization: `Bearer ${process.env.AIRTABLE_API_KEY}`,
              Authorization: `Bearer patNPb1rv3SGCHEIH.f2b5252980ba64769eef34c7b9eb19c5403153d765aec2f98a8242dbae5447da`,
            },
          }
        );
        const formatedResponse = response.data.records.map((record: any) => ({
          ...record,
          fields: {
            ...record.fields,
            updates: JSON.parse(record.fields.updates || '[]'),
          },
        }));

        console.log({ formatedResponse });

        setChangelog(formatedResponse);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };

    fetchChangelog();
  }, []);

  return { changelog, loading };
};

export default useChangelog;
