import { SiaShiftDocument } from 'gastronaut-shared/types/documents/restaurants';
import { SiaSettingsDocument } from 'gastronaut-shared/types/documents/restaurants/settings';
import {
  ConnectTo,
  ShiftMode,
  ShiftType,
} from 'gastronaut-shared/types/helper/sia';
import React, { useMemo } from 'react';
import { dateHelper, timeHelper, timeToBlock } from 'utils/helper';
import useCollection from './useCollection';
import useDocument from './useDocument';
import useImpulse from './useImpulse';

export type SiaRule = { connectTo: ConnectTo; value?: any };

export type CurrentRule = {
  incoming?: SiaRule;
  redirect?: SiaRule;
  contactPerson?: SiaRule;
  event?: SiaRule;
  error?: SiaRule;
};

const useSiaRules = (restaurantId: string | null) => {
  const [siaShifts] = useCollection<SiaShiftDocument>(
    `/restaurants/${restaurantId}/siaShifts`
  );

  const [siaSettings] = useDocument<SiaSettingsDocument>(
    `/restaurants/${restaurantId}/settings`,
    'siaSettingsV02'
  );

  const impulse = useImpulse(300000);

  const currentRule: CurrentRule = useMemo(() => {
    const activeShifts = siaShifts.data
      .filter((r) => {
        if (!r.active) return;

        const currentTime = timeHelper();

        switch (r.type) {
          case ShiftType.WDAYS: {
            const wDay = new Date().getDay();

            const matching = r.matching as {
              close: string;
              start: string;
              wDays: number[];
            };

            if (matching.wDays?.includes?.(wDay)) {
              let close =
                matching.close <= matching.start
                  ? timeToBlock(matching.close) + 96
                  : timeToBlock(matching.close);

              let start = timeToBlock(matching.start);

              let time = timeToBlock(currentTime);

              return start <= time && close >= time;
            }

            break;
          }
          case ShiftType.DATES: {
            const matching = r.matching as {
              dates: string[];
              close: string;
              start: string;
            };

            const today = dateHelper();

            if (matching?.dates?.includes?.(today)) {
              let close =
                matching.close <= matching.start
                  ? timeToBlock(matching.close) + 96
                  : timeToBlock(matching.close);

              let start = timeToBlock(matching.start);

              let time = timeToBlock(currentTime);

              return start <= time && close >= time;
            }

            break;
          }
          case ShiftType.TS: {
            return (r.matching?.till ?? 0) >= Date.now();
          }

          default:
            break;
        }

        return false;
      })
      .sort((a, b) => a.type - b.type);

    const incoming =
      activeShifts.find((s) => s.mode === ShiftMode.INCOMING_CALL) ??
      siaSettings.data?.standard;

    const redirect = activeShifts.find((s) => s.mode === ShiftMode.REDIRECT);

    const event = activeShifts.find((s) => s.mode === ShiftMode.EVENT);

    const error = activeShifts.find((s) => s.mode === ShiftMode.ERROR);

    return {
      incoming: incoming
        ? { connectTo: incoming.connectTo, value: incoming.value }
        : siaSettings.data?.standard,
      contactPerson: redirect
        ? { connectTo: redirect.connectTo, value: redirect.value }
        : {
            connectTo: ConnectTo.TEXT_MESSAGE,
            value: siaSettings?.data?.fallbackMessage,
          },
      redirect: redirect
        ? { connectTo: redirect.connectTo, value: redirect.value }
        : siaSettings.data?.redirectStandard,
      event: event
        ? { connectTo: event.connectTo, value: event.value }
        : siaSettings.data?.redirectStandard,
      error: error
        ? { connectTo: error.connectTo, value: error.value }
        : siaSettings.data?.redirectStandard,
    };
  }, [siaShifts, siaSettings, impulse]);

  return {
    siaShifts: siaShifts.data,
    siaSettings: siaSettings.data,
    currentRule,
    loading: siaSettings.loading || siaShifts.loading,
  };
};

export default useSiaRules;
