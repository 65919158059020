import React, { useMemo } from 'react';
import useImpulse from './useImpulse';

const useTime = () => {
  const impulse = useImpulse(60000);

  const dateNow = useMemo(() => {
    return Date.now();
  }, [impulse]);

  return dateNow;
};

export default useTime;
