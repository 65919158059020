import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import Box from 'Components/Atoms/Box';
import IconButton from 'Components/Atoms/IconButton';
import LockedProductIcon from 'Components/Atoms/Icons/LockedProduct';
import MenuItem from 'Components/Atoms/MenuItem';
import Typography from 'Components/Atoms/Typography';
import Accordion from 'Components/Molecules/Accordion';
import usePinAuth from 'CustomHooks/usePinAuth';
import React, { useMemo, useState } from 'react';
import { TimeSlot } from 'types/reservations';
import {
  blockToTime,
  dateHelper,
  noop,
  timeHelper,
  timeToBlock,
} from 'utils/helper';

export type TimeSlotsProps = {
  value: null | TimeSlot;
  timeSlots: null | TimeSlot[];
  onChange: (nV: TimeSlot & { protectedTime: boolean }) => void;
  timeSlotError?: string | null;
  lightReservation?: boolean;
  date?: string;
  waitinglist?: null | {
    offerWaitinglist: boolean;
    slots?: string[];
  }; // only when it's a new Reservation
};

const TopPart: any = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => (
  <Box
    className="flex space-between"
    style={{ padding: '4px 8px' }}
    onClick={() => setOpen((x) => !x)}
  >
    <Typography variant="h6" translation="reservations">
      Past Times
    </Typography>
    <IconButton size="small">
      {!open ? <ArrowDropDown /> : <ArrowDropUp />}
    </IconButton>
  </Box>
);

const TimeSlots = ({
  date,
  timeSlots,
  value,
  onChange = noop,
  timeSlotError,
  lightReservation = false,
  waitinglist,
}: TimeSlotsProps) => {
  const [openPastTimes, setOpenPastTimes] = useState(false);

  const { upcomingTimes = [], pastTimes = [] } = useMemo(() => {
    if (!timeSlots?.length) return { upcomingTimes: [], pastTimes: [] };

    if (date && date === dateHelper()) {
      let currentTime = timeToBlock(timeHelper());

      let pastTimes = timeSlots.filter((t) => t.block < currentTime);

      let upcomingTimes = timeSlots.filter((t) => t.block >= currentTime);

      return { pastTimes, upcomingTimes };
    } else {
      return { upcomingTimes: timeSlots, pastTimes: [] };
    }
  }, [date, timeSlots]);

  const { authenticate, checkAuthentication } = usePinAuth();

  return (
    <>
      {!!pastTimes.length && (
        <Accordion
          open={openPastTimes}
          TopPart={TopPart({ open: openPastTimes, setOpen: setOpenPastTimes })}
        >
          {pastTimes.map((t) => {
            const noAvailability =
              t.blockedSlots >= t.totalSlots ||
              t.maxGuestsReached ||
              (!t.combinations.length && !lightReservation);

            const color = noAvailability ? 'var(--color-critical)' : '';

            const backgroundColor = noAvailability
              ? 'rgba(204, 16, 35, 0.17)'
              : '';

            return (
              <MenuItem
                key={t.block}
                onClick={() =>
                  authenticate('reservations.blockedTime.write', () =>
                    onChange({
                      ...t,
                      protectedTime: checkAuthentication(
                        'reservations.blockedTime.write'
                      ),
                    })
                  )
                }
                className="flex space-between"
                style={{
                  color,
                  backgroundColor,
                }}
                translation={null}
                selected={value?.block === t.block}
              >
                <span>
                  {t.time}{' '}
                  {t.shorterReservationLength &&
                    `${blockToTime(t.reservationLength)}h`}
                </span>
                <span>
                  {t.blockedSlots} / {t.totalSlots}
                </span>
              </MenuItem>
            );
          })}
        </Accordion>
      )}
      {upcomingTimes.map((t) => {
        const noAvailability =
          t.blockedSlots >= t.totalSlots ||
          t.maxGuestsReached ||
          (!t.combinations.length && !lightReservation);
        const color = noAvailability ? 'var(--color-critical)' : '';

        const backgroundColor = noAvailability ? 'rgba(204, 16, 35, 0.17)' : '';

        let hasWaitinglist = !!noAvailability && !!waitinglist;

        let protectedTime =
          !!noAvailability &&
          checkAuthentication('reservations.blockedTime.write');

        return (
          <MenuItem
            key={t.block}
            onClick={() =>
              authenticate(
                !waitinglist ? 'reservations.blockedTime.write' : 'none',
                () =>
                  onChange({ ...t, waitinglist: hasWaitinglist, protectedTime })
              )
            }
            className="flex space-between"
            style={{
              color,
              backgroundColor,
            }}
            translation={null}
            selected={value?.block === t.block}
            iconRight={
              protectedTime && !waitinglist ? (
                <LockedProductIcon
                  width="20"
                  height="20"
                  noStroke
                  style={{ marginLeft: 4, color: 'var(--color-secondary)' }}
                />
              ) : undefined
            }
          >
            <span style={{ flexGrow: 10 }}>
              {t.time}{' '}
              {t.shorterReservationLength && (
                <span style={{ opacity: 0.5 }}>
                  (max {blockToTime(t.reservationLength)}h)
                </span>
              )}
            </span>
            <span>
              {hasWaitinglist && (
                <span
                  style={{
                    display: 'inline-block',
                    height: 10,
                    width: 10,
                    borderRadius: 5,
                    background: 'var(--color-secondary)',
                    marginRight: 10,
                    marginTop: 2,
                  }}
                />
              )}
              {t.blockedSlots} / {t.totalSlots}
            </span>
          </MenuItem>
        );
      })}
      {timeSlotError && (
        <MenuItem
          style={{ color: 'var(--color-critical)' }}
          translation="reservations"
        >
          {timeSlotError}
        </MenuItem>
      )}
      {!timeSlotError && timeSlots && !timeSlots.length && (
        <MenuItem
          style={{ color: 'var(--color-critical)' }}
          translation="reservations"
        >
          No Available Times
        </MenuItem>
      )}
    </>
  );
};

export default TimeSlots;
