import { useState, useEffect } from "react";
import { throttle } from "lodash";

function useThrottle<T = any>(val: T, ms = 200) {
  const [data, set] = useState<T>(val);

  useEffect(() => throttle(() => set(val), ms)(), [val, ms]);

  return data;
}

export default useThrottle;
