import React, { useState } from 'react';
import Box from 'Components/Atoms/Box';
import Button from 'Components/Atoms/Button';
import Typography from 'Components/Atoms/Typography';
import './styles.scss';
import AuthNavBar from 'App/Auth/Components/AuthNavBar';
import Divider from 'Components/Atoms/Divider';
import TextField from 'Components/Atoms/TextField';
import { ProviderDataProps } from 'Contexts/AuthContext';
import Checkbox from 'Components/Atoms/Checkbox';
import Link from 'Components/Atoms/Link';
import { classHelper, emailRegex } from 'utils/helper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Email } from 'Components/Atoms/Icons';

type ErrorState = {
  email?: string;
};

export type LoginProps = {
  onSignIn: (method: string, providerData?: ProviderDataProps) => Promise<void>;
  signInError: false | string;
};

const Login = ({ onSignIn, signInError }: LoginProps) => {
  const [loading, setloading] = useState(false);
  const [providerData, setproviderData] = useState<ProviderDataProps>({
    email: '',
    password: '',
    rememberMe: false,
  });
  const [errors, seterrors] = useState<ErrorState>({ email: '' });
  const isMobile = useMediaQuery('(max-width: 450px)');
  const [emailOpen, setemailOpen] = useState<boolean>(false);

  const handleEmailSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setloading(true);
    await onSignIn('email', providerData);
    setloading(false);
  };

  const handleEmailBlur = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;

    if (!emailRegex(value)) {
      seterrors((err) => ({ ...err, email: 'No valid Emailadress' }));
    } else {
      seterrors((err) => ({ ...err, email: '' }));
    }
  };

  return (
    <Box style={{ width: '100vw', height: '100vh' }}>
      <AuthNavBar />
      <Box flex className="space-between auth-container">
        <Box
          flex
          className="flex-grow center"
          style={{ padding: isMobile ? '0 16px' : '' }}
        >
          <Box
            className="flex-column space-between"
            style={{ height: isMobile ? 'calc(100vh - 150px)' : 'auto' }}
          >
            {isMobile && (
              <Box className="mobile-text-container">
                <Typography
                  variant="h3"
                  className="mg-bt-sm"
                  translation="auth"
                >
                  Login Now
                </Typography>
                <Typography
                  variant="text-3"
                  color="subdued"
                  component="p"
                  style={{ marginBottom: !emailOpen ? 160 : 40 }}
                  translation="auth"
                >
                  See your dashboard and manage everything!
                </Typography>
              </Box>
            )}

            <Box
              style={{
                maxWidth: isMobile ? 'none' : 350,
                width: isMobile ? '100%' : 'auto',
              }}
            >
              {!isMobile && (
                <Box className="mobile-text-container">
                  <Typography
                    variant="h3"
                    className="mg-bt-sm"
                    translation="auth"
                  >
                    Login Now
                  </Typography>
                  <Typography
                    variant="text-3"
                    color="subdued"
                    component="p"
                    style={{ marginBottom: 40 }}
                    translation="auth"
                  >
                    See your dashboard and manage everything!
                  </Typography>
                </Box>
              )}

              <Button
                variant="outlined"
                onClick={() => onSignIn('google')}
                startIcon={() => (
                  <img
                    alt="google"
                    src="/Icons/google.svg"
                    style={{ marginRight: 24 }}
                  />
                )}
                fullWidth
                className="mg-bt-md"
                translation="auth"
              >
                Sign in with Google
              </Button>
              <Button
                variant="outlined"
                onClick={() => onSignIn('facebook')}
                startIcon={() => (
                  <img
                    alt="facebook"
                    src="/Icons/facebook.svg"
                    style={{ marginRight: 24 }}
                  />
                )}
                fullWidth
                translation="auth"
              >
                Sign in with Facebook
              </Button>

              {isMobile ? (
                <Box className="or-divider" flex>
                  <div className="dividers"></div>
                  <Typography
                    color="subdued"
                    block
                    variant="text-2"
                    className="or-text"
                  >
                    or
                  </Typography>
                  <div className="dividers"></div>
                </Box>
              ) : (
                <Divider margin="lg" />
              )}

              {isMobile ? (
                !emailOpen ? (
                  <Button
                    className="email-btn"
                    onClick={() => setemailOpen(true)}
                    variant="outlined"
                    startIcon={() => <Email />}
                    fullWidth
                  >
                    Login with Email
                  </Button>
                ) : (
                  <Box component="form" onSubmit={handleEmailSubmit}>
                    <TextField
                      paper
                      value={providerData.email}
                      onBlur={handleEmailBlur}
                      errorMessage={errors.email}
                      onChange={(e) =>
                        setproviderData((pD) => ({
                          ...pD,
                          email: e.target.value,
                        }))
                      }
                      marginBottom="md"
                      fullWidth
                      variant="filled"
                      label="Email"
                      labelTranslation="auth"
                      size="small"
                      type="email"
                    />
                    <TextField
                      paper
                      value={providerData.password}
                      errorMessage={signInError || ''}
                      onChange={(e) =>
                        setproviderData((pD) => ({
                          ...pD,
                          password: e.target.value,
                        }))
                      }
                      marginBottom="md"
                      fullWidth
                      variant="filled"
                      label="Password"
                      labelTranslation="auth"
                      size="small"
                      type="password"
                    />
                    <Box
                      flex
                      className="space-between"
                      style={{ marginBottom: 40 }}
                    >
                      <Checkbox
                        color="primary"
                        checked={providerData.rememberMe}
                        translation="auth"
                        onChange={(e) =>
                          setproviderData((pD) => ({
                            ...pD,
                            rememberMe: e.target.checked,
                          }))
                        }
                        label="Remember Me"
                      />
                      <Link
                        linkTo="/auth/forgot-password"
                        translation="auth"
                        variant="text-2"
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        Forgot Password?
                      </Link>
                    </Box>
                    <Button
                      loading={loading}
                      variant="primary"
                      fullWidth
                      className="mg-bt-md"
                      type="submit"
                      translation="auth"
                    >
                      Login Now
                    </Button>
                  </Box>
                )
              ) : (
                <Box component="form" onSubmit={handleEmailSubmit}>
                  <TextField
                    paper
                    value={providerData.email}
                    onBlur={handleEmailBlur}
                    errorMessage={errors.email}
                    onChange={(e) =>
                      setproviderData((pD) => ({
                        ...pD,
                        email: e.target.value,
                      }))
                    }
                    marginBottom="md"
                    fullWidth
                    variant="filled"
                    label="Email"
                    labelTranslation="auth"
                    size="small"
                    type="email"
                  />
                  <TextField
                    paper
                    value={providerData.password}
                    errorMessage={signInError || ''}
                    onChange={(e) =>
                      setproviderData((pD) => ({
                        ...pD,
                        password: e.target.value,
                      }))
                    }
                    marginBottom="md"
                    fullWidth
                    variant="filled"
                    label="Password"
                    labelTranslation="auth"
                    size="small"
                    type="password"
                  />
                  <Box
                    flex
                    className="space-between"
                    style={{ marginBottom: 40 }}
                  >
                    <Checkbox
                      color="primary"
                      checked={providerData.rememberMe}
                      translation="auth"
                      onChange={(e) =>
                        setproviderData((pD) => ({
                          ...pD,
                          rememberMe: e.target.checked,
                        }))
                      }
                      style={{ marginRight: 4 }}
                      label="Remember Me"
                    />
                    <Link
                      linkTo="/auth/forgot-password"
                      translation="auth"
                      variant="text-2"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      Forgot Password?
                    </Link>
                  </Box>
                  <Button
                    loading={loading}
                    variant="primary"
                    fullWidth
                    className="mg-bt-md"
                    type="submit"
                    translation="auth"
                  >
                    Login Now
                  </Button>
                </Box>
              )}

              <Box flex>
                <Typography
                  translation="auth"
                  variant="text-2"
                  style={{ marginRight: 4 }}
                >
                  Not registered yet?
                </Typography>
                <Link
                  href="https://get.gastronaut.ai"
                  translation="auth"
                  variant="text-2"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  Become a part of Gastronaut!
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          className="flex-grow"
          hideOn={['md']}
          style={{
            height: 'calc(100vh - 65px)',
            backgroundImage: 'url("/login.jpg")',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        />
      </Box>
    </Box>
  );
};

export default Login;
