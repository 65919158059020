import React from 'react';
import Box from 'Components/Atoms/Box';
import Button from 'Components/Atoms/Button';
import { Backspace } from 'Components/Atoms/Icons';

export type KeyboardProps = {
  value?: number | null;
  onChange: (newValue: number) => void;
  onClose: VoidFunction;
};

const OPTIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((id) => ({
  id,
}));

const Keyboard = ({ value, onChange, onClose }: KeyboardProps) => {
  const [newlyOpened, setNewlyOpened] = React.useState(true);

  const handleChange = (button = '0') => {
    if (button === 'C') {
      onChange(Number(null));
    } else if (button === 'back') {
      let val = String(value ?? '').slice(0, -1);

      if (isNaN(Number(val))) {
        val = '1';
      }

      onChange(Number(val));
    } else {
      let val = String(newlyOpened ? '' : value ?? '') + button;
      onChange(Number(val));
    }
    setNewlyOpened(false);
  };

  return (
    <div>
      <Box
        flex
        className="flex-column"
        width="60%"
        padding={16}
        style={{ minWidth: 228 }}
      >
        <Box flex className="flex-column" style={{ margin: 8 }}>
          <Box flex className="flex-column">
            <Box flex style={{ justifyContent: 'space-between' }}>
              {OPTIONS.map(
                (opt) =>
                  opt.id <= 3 &&
                  opt.id >= 1 && (
                    <Box
                      key={opt.id}
                      className="guestsKey"
                      padding={5}
                      margin={5}
                      flex
                      onClick={() => {
                        handleChange(String(opt.id));
                      }}
                    >
                      {opt.id}
                    </Box>
                  )
              )}
            </Box>

            <Box flex style={{ justifyContent: 'space-between' }}>
              {OPTIONS.map(
                (opt) =>
                  opt.id > 3 &&
                  opt.id <= 6 && (
                    <Box
                      key={opt.id}
                      className="guestsKey"
                      padding={5}
                      margin={5}
                      flex
                      onClick={() => {
                        handleChange(String(opt.id));
                      }}
                    >
                      {opt.id}
                    </Box>
                  )
              )}
            </Box>

            <Box flex style={{ justifyContent: 'space-between' }}>
              {OPTIONS.map(
                (opt) =>
                  opt.id > 6 &&
                  opt.id <= 9 && (
                    <Box
                      key={opt.id}
                      className="guestsKey"
                      padding={5}
                      margin={5}
                      flex
                      onClick={() => {
                        handleChange(String(opt.id));
                      }}
                    >
                      {opt.id}
                    </Box>
                  )
              )}
            </Box>
          </Box>

          <Box flex className="flex-column">
            <Box flex style={{ justifyContent: 'space-between' }}>
              <Box
                className="guestsKey"
                margin={5}
                padding={5}
                flex
                onClick={() => handleChange('C')}
              >
                C
              </Box>
              <Box
                className="guestsKey"
                margin={5}
                padding={5}
                flex
                onClick={() => {
                  handleChange('0');
                }}
              >
                0
              </Box>
              <Box
                margin={5}
                padding={5}
                className="guestsKey"
                flex
                onClick={() => handleChange('back')}
              >
                <Backspace />
              </Box>
            </Box>
          </Box>
        </Box>
        <Button
          translation="common"
          variant="outlined"
          style={{ width: '100%', margin: 5 }}
          onClick={() => {
            onClose();
          }}
        >
          Close
        </Button>
      </Box>
    </div>
  );
};

export default Keyboard;
