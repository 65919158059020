import React, { KeyboardEventHandler, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Translations } from "utils/types";
import "../styles.scss";

interface EditableTableCellProps {
  name?: string;
  value?: string | number;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  translation?: Translations | null;
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>;
  style?: React.CSSProperties;
  type?: string;
  disabled?: boolean;
}

const EditableTableCell = ({
  name,
  value,
  placeholder,
  onChange,
  onBlur,
  onKeyPress,
  style,
  translation = "common",
  type = undefined,
  disabled = false,
}: EditableTableCellProps) => {
  const [inputValue, setInputValue] = useState<string | number>("");
  useEffect(() => setInputValue(String(value) ?? ""), [value]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (onChange) onChange(e);
  };

  const { t } = useTranslation(translation || undefined);

  return (
    <input
      className="editable-table-cell-input"
      name={name}
      value={inputValue}
      placeholder={placeholder ? t(placeholder) : undefined}
      onChange={handleInputChange}
      onBlur={onBlur}
      onKeyPress={onKeyPress}
      style={style}
      type={type}
      disabled={disabled}
    />
  );
};

export default EditableTableCell;
