import React from "react";

const LinkChainIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.74996 5.83439L12.5144 3.02282C13.9698 1.54258 16.3528 1.53251 17.8207 3.00039V3.00039C19.2799 4.45955 19.2799 6.82531 17.8207 8.28446L14.9372 11.1679C13.4165 12.6887 10.9655 12.7347 9.38866 11.2722L8.91663 10.8344"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.8794 14.8453L8.11499 17.6569C6.65957 19.1371 4.27657 19.1472 2.80869 17.6793V17.6793C1.34953 16.2201 1.34953 13.8544 2.80869 12.3952L5.69215 9.51176C7.21289 7.99101 9.66392 7.94494 11.2407 9.40747L11.7128 9.84529"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default LinkChainIcon;
