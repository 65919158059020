import React, { useState, useEffect } from 'react';
import { BoxProps } from 'Components/Atoms/Box';
import Card, { CardProps } from '../Card';
import Tabs, { Tab } from 'Components/Atoms/Tabs';
import { noop } from 'utils/helper';
import { Modify, Translations } from 'utils/types';

type Children = {
  (value: string | number | null): React.ReactNode;
};

export interface TabCardProps extends Omit<CardProps, 'children' | 'onChange'> {
  tabs?: any[];
  children: Children | React.ReactNode;
  id?: string;
  titleTranslation?: Translations;
  headerPadding?: 'sm' | 'md' | 'lg';
  titleAlignment?: 'left' | 'center' | 'right';
  headerRight?: null | React.ReactNode;
  lowerHeader?: null | React.ReactNode;
  boxProps?: BoxProps;
  subHeader?: null | React.ReactNode;
  subHeaderRight?: null | React.ReactNode;
  value?: string | number | null;
  onChange?: (value: string | number | null) => void;
  hideHeader?: boolean;
  style?: React.CSSProperties;
  bodyProps?: BoxProps;
  onCloseClick?: (id: string) => void;
  hideTabsWhenOnlyOne?: boolean;
  badge?: number;
  scrollOnOverflow?: boolean;
}

const TabCard = ({
  tabs = [],
  id,
  children,
  value: outsideValue,
  onChange = noop,
  onCloseClick = noop,
  hideTabsWhenOnlyOne = false,
  subHeaderRight,
  scrollOnOverflow = false,
  ...props
}: TabCardProps) => {
  const [value, setvalue] = useState<string | number | null>(null);

  useEffect(() => {
    if (!value && tabs.length) {
      setvalue(tabs[0].id);
    }
  }, [value, tabs]);

  const val = outsideValue ?? value;

  const handleChange = (v: string | number | null) => {
    setvalue(v);
    onChange(v);
  };

  return (
    <Card
      {...props}
      subHeader={
        !(hideTabsWhenOnlyOne && tabs.length === 1) && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Tabs
              id={id || ''}
              tabs={tabs}
              value={val}
              onChange={handleChange}
              onCloseClick={onCloseClick}
              scrollOnOverflow={scrollOnOverflow}
            />
            {subHeaderRight}
          </div>
        )
      }
    >
      {typeof children === 'function' ? children(val) : children}
    </Card>
  );
};

export default TabCard;
