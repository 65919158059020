import React from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import TextField from 'Components/Atoms/TextField';
import { Edit, Fullscreen } from '@material-ui/icons';
import Modal from 'Components/Molecules/Modal';
import SwitchBox from 'Components/Atoms/Switch';

export type FixedTable = {
  tables: string[];
  active: boolean;
  type: 'override' | 'no_conflict';
  comment?: string;
  reservationLength?: number;
};

export type FixedTableModalProps = {
  value?: FixedTable;
  onChange: (value: FixedTable) => void;
  fullWidth?: boolean;
  className?: string;
};

const FixedTableModal: React.FC<FixedTableModalProps> = ({
  value,
  onChange,
  fullWidth = false,
  className,
}) => {
  const [modal, setModal] = React.useState<
    (FixedTable & { tableString: string }) | null
  >(null);

  const handleSubmit = () => {
    if (modal) {
      let { tableString, ...state } = modal;

      onChange({
        ...state,
        tables: tableString.split(',').map((table) => table.trim()),
      });
      setModal(null);
    }
  };

  return (
    <>
      <TextField
        value={
          value?.active
            ? value.tables.join(', ') + ' ' + value.comment ?? ''
            : 'Not active'
        }
        fullWidth={fullWidth}
        label="Stammtisch"
        onClick={() => {
          setModal({
            active: true,
            type: 'override',
            tables: [],
            comment: '',
            ...(value || {}),
            tableString: value?.tables.join(', ') || '',
          });
        }}
        labelTranslation="reservations"
        InputProps={{
          endAdornment: (
            <Edit
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setModal({
                  active: true,
                  type: 'override',
                  tables: [],
                  comment: '',
                  ...(value || {}),
                  tableString: value?.tables.join(', ') || '',
                });
              }}
            />
          ),
        }}
        className={className}
      />
      <Modal
        open={!!modal}
        onClose={() => setModal(null)}
        title="Fixer Tisch"
        titleTranslation="reservations"
        maxWidth="sm"
        fullWidth
        onSubmit={handleSubmit}
        actions={[
          {
            id: 'cancel',
            label: 'Cancel',
            onClick: () => setModal(null),
          },
          {
            id: 'save',
            label: 'Save',
            onClick: handleSubmit,
            variant: 'primary',
          },
        ]}
      >
        <Box className="comment-modal">
          <SwitchBox
            checked={modal?.active || false}
            onChange={(e) =>
              setModal((m) => (m ? { ...m, active: e.target.checked } : null))
            }
            label="Active"
            translation="reservations"
          />

          <TextField
            value={modal?.tableString || ''}
            onChange={(e) =>
              setModal((m) =>
                m ? { ...m, tableString: e.target.value } : null
              )
            }
            fullWidth
            label="Tables"
            labelTranslation="reservations"
            helperText="Aktuell wird der Gast nur auf dem Tisch platziert wenn dieser frei ist."
          />

          <TextField
            value={modal?.comment || ''}
            onChange={(e) =>
              setModal((m) => (m ? { ...m, comment: e.target.value } : null))
            }
            fullWidth
            multiline
            rows={10}
            label="Comment"
            labelTranslation="reservations"
          />
        </Box>
      </Modal>
    </>
  );
};

export default FixedTableModal;
