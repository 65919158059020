import { IconButton } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import Box from "Components/Atoms/Box";
import Typography from "Components/Atoms/Typography";
import React from "react";

const CallSettings: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <Box className="currentCall paper" outline elevation={4}>
      <Box className="flex space-between mg-bt-sm">
        <Typography block variant="h5">
          SIA - Einstellungen
        </Typography>
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        >
          <ArrowForward />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CallSettings;
