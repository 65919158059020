import React, { useContext, useMemo } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Box from "Components/Atoms/Box";
import { Home, InsertChart, Weekend, Call } from "Components/Atoms/Icons";
import { classHelper, dateHelper } from "utils/helper";
import { RestaurantContext } from "Contexts/RestaurantContext";

import "./styles.scss";

export type BottomNavBarProps = {
  current?: "home" | "quicklook" | "reservations" | "sia";
};

const BottomNavBar: React.FC<BottomNavBarProps> = ({ current }) => {
  const history = useHistory();
  const { restaurantId } = useContext(RestaurantContext);

  const today = dateHelper();

  const selected = useMemo<
    "home" | "quicklook" | "reservations" | "sia" | null
  >(() => {
    if (current) return current;
    if (history.location.pathname === "/") return "home";
    if (history.location.pathname.includes("sia")) return "sia";
    else if (history.location.pathname === "/m/quicklook") return "quicklook";
    else if (history.location.pathname.includes("/reservations"))
      return "reservations";
    else return null;
  }, [history.location.pathname, current]);

  return (
    <Box className={classHelper(["bottom-nav-bar", "icons"])}>
      <NavLink to={`/${restaurantId}/sia`} exact>
        <Call className={classHelper([selected === "sia" && "selected"])} />
      </NavLink>
      <NavLink to="/" exact>
        <Home className={classHelper([selected === "home" && "selected"])} />
      </NavLink>

      <NavLink to={`/${restaurantId}/reservations/${today}/tablePlan`} exact>
        <Weekend
          className={classHelper([selected === "reservations" && "selected"])}
        />
      </NavLink>
    </Box>
  );
};

export default BottomNavBar;
