import React, { useContext, useEffect, useRef, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import Box from 'Components/Atoms/Box';
import Button from 'Components/Atoms/Button';
import GridContainer from 'Components/Atoms/GridContainer';
import GridItem from 'Components/Atoms/GridItem';
import { Add, Photo } from 'Components/Atoms/Icons';
import Typography from 'Components/Atoms/Typography';
import SearchBar from 'Components/Molecules/SearchBar';
import { ImageContext } from 'Contexts/ImageContext';
import Checkbox from 'Components/Atoms/Checkbox';
import { dateHelper } from 'utils/helper';

const ImageList = () => {
  const [imageWidth, setImageWidth] = useState<number | null>(null);

  const {
    images: imagesData,
    currentImage,
    setcurrentImage,
    handleImageUpload,
    multiple,
    selectedImages,
    setselectedImages,
    imageLoading,
    imageError,
    search,
    setsearch,
  } = useContext(ImageContext);
  const { filteredData: images, loading, error } = imagesData;

  const imageRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setImageWidth(imageRef.current ? imageRef.current.offsetWidth : 152);
  });

  const handleImageClick = (id: string) => {
    setcurrentImage(id);
    if (multiple) {
      if (selectedImages.includes(id)) {
        setselectedImages((i) => i.filter((img) => img !== id));
      } else {
        setselectedImages((i) => [...i, id]);
      }
    }
  };

  const [listView, setListView] = useState<boolean>(false);

  return (
    <Box
      style={{
        width: currentImage ? 'calc(62% - 200px)' : 'calc(100% - 200px)',
      }}
      padding={16}
      id="image-list"
    >
      <Box className="space-between">
        <input
          type="file"
          onChange={handleImageUpload}
          name="picture"
          accept=".png,.jpg,.jpeg"
          multiple
          className="image-input"
        />
        <Button
          typographyProps={{ variant: 'text-3' }}
          endIcon={() => <Add fontSize="small" style={{ marginLeft: 8 }} />}
          onClick={(e) => e.stopPropagation()}
          translation="settings"
        >
          Upload Image
        </Button>
        <Box style={{ width: 'fit-content' }}>
          <Button
            typographyProps={{ variant: 'text-3' }}
            style={{ marginRight: 8 }}
            variant={!listView ? 'primary' : 'default'}
            onClick={() => setListView(false)}
            translation="settings"
          >
            Gallery
          </Button>
          <Button
            typographyProps={{ variant: 'text-3' }}
            variant={listView ? 'primary' : 'default'}
            onClick={() => setListView(true)}
            translation="settings"
          >
            List
          </Button>
        </Box>
        <SearchBar
          value={search}
          onChange={setsearch}
          width={200}
          outline={false}
          boxStyle={{
            backgroundColor: 'var(--color-btn-default)',
            height: 32,
            borderRadius: 4,
          }}
        />
      </Box>
      {(loading || imageLoading) && (
        <Box className="center">
          <CircularProgress />
        </Box>
      )}
      {(error || imageError) && (
        <Box className="center">
          <Typography variant="text-2" color="error">
            {error ? error : imageError}
          </Typography>
        </Box>
      )}
      {!loading && search && !images.length && (
        <Box className="center">
          <Typography variant="text-2" color="subdued">
            No results
          </Typography>
        </Box>
      )}

      {!loading && !!images.length && (
        <GridContainer
          style={{
            marginTop: 24,
            height: 'calc(90vh - 209px)',
            overflowY: 'scroll',
          }}
        >
          {images
            .sort((a, b) => b.createdAt - a.createdAt)
            .map((image, index) => (
              <GridItem
                xs={listView ? 10 : currentImage ? 3 : 2}
                className="image-container"
                style={{ overflow: 'hidden', position: 'relative' }}
                key={'image' + image.id}
              >
                {multiple && (
                  <Checkbox
                    label=""
                    checked={selectedImages.includes(image.id)}
                    color="primary"
                    className={
                      selectedImages.includes(image.id)
                        ? 'image-checkbox checked'
                        : 'image-checkbox'
                    }
                    onChange={() => handleImageClick(image.id)}
                  />
                )}
                {listView ? (
                  <div
                    ref={index === 0 ? imageRef : null}
                    onClick={() => handleImageClick(image.id)}
                    style={{ display: 'flex', cursor: 'pointer' }}
                  >
                    <Photo
                      style={{
                        color: 'var(--color-text-subdued)',
                        marginRight: 8,
                      }}
                    />
                    <Box>
                      <Typography
                        variant="text-3"
                        block
                        translation={null}
                        style={
                          image.id === currentImage ? { fontWeight: 700 } : {}
                        }
                      >
                        {image.originalName}
                      </Typography>
                      <Typography variant="text-4" color="subdued" block>
                        {dateHelper(new Date(image.createdAt))}
                      </Typography>
                    </Box>
                  </div>
                ) : (
                  <>
                    <div
                      ref={index === 0 ? imageRef : null}
                      className={
                        image.id === currentImage ? 'image active' : 'image'
                      }
                      style={{
                        backgroundImage: `url("${image.url}")`,
                        height: imageWidth ? imageWidth : 152,
                      }}
                      onClick={() => handleImageClick(image.id)}
                    />
                    <Typography variant="text-4">
                      {image.originalName}
                    </Typography>
                  </>
                )}
              </GridItem>
            ))}
        </GridContainer>
      )}
    </Box>
  );
};

export default ImageList;
