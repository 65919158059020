import React from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import useAlert from 'CustomHooks/useAlert';

export type ReservationOptimizationProps = {
  scoreDiff: number;
  style?: React.CSSProperties;
  onClick?: () => void;
  showNoScore?: boolean;
};

const ReservationOptimization: React.FC<ReservationOptimizationProps> = ({
  scoreDiff,
  style,
  onClick,
  showNoScore = false,
}) => {
  const pctReal = scoreDiff > 2 ? 0 : Math.max(scoreDiff * 100 - 100, 0);
  const pct = Math.min(pctReal, 100);

  const alert = useAlert();

  const handleClick = () => {
    alert({
      title: 'Experimentel',
      description:
        'Diese Funktion ist experimentell und kann unerwartetes Verhalten zeigen. Kontrolliere den Tag nach der Optimierung.',
      onSubmit: () => (onClick ? onClick() : undefined),
    });
  };

  return (
    <div
      onClick={pctReal ? handleClick : undefined}
      style={{
        ...style,
        position: 'relative',
        marginBottom: -8,
        cursor: pctReal || showNoScore ? 'pointer' : 'not-allowed',
      }}
    >
      <svg height="36px" viewBox="0 0 36 27">
        <circle
          stroke="var(--grey-4, #CBD5E0)"
          fill="transparent"
          strokeWidth={2}
          r="16"
          cx="18"
          cy="18"
          strokeDasharray="66 34"
          strokeDashoffset="58"
          strokeLinecap="round"
        ></circle>
        {!!pctReal && (
          <circle
            stroke="var(--color-secondary, #6369D1)"
            strokeWidth={2}
            fill="transparent"
            strokeLinecap="round"
            r="16"
            cx="18"
            cy="18"
            strokeDasharray={`${pct * 0.66} ${100 - pct * 0.66}`}
            strokeDashoffset="58"
            style={{
              transition: 'stroke-dasharray 0.5s ease-in-out',
            }}
          ></circle>
        )}
      </svg>
      <span
        style={{
          display: 'block',
          position: 'absolute',
          width: '100%',
          top: 15,
          color:
            pctReal || showNoScore
              ? 'var(--color-secondary, #6369D1)'
              : 'var(--color-disabled)',
          textAlign: 'center',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '140%',
        }}
      >
        {pctReal || showNoScore ? `+${pctReal.toFixed(0)}%` : '-'}
      </span>
    </div>
  );
};

export default ReservationOptimization;
