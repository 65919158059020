import { Notes, Pause, PlayArrow } from '@material-ui/icons';
import { Rating } from '@material-ui/lab';
import Mailbox from 'App/Dashboard/Components/Mailbox';
import AudioWave from 'App/Dashboard/Screens/SiaCurrentDay/Components/AudioWave';
import { durationToMin } from 'App/Dashboard/Screens/SiaCurrentDay/Components/CallCard';
import Box from 'Components/Atoms/Box';
import IconButton from 'Components/Atoms/IconButton';
import Popover from 'Components/Atoms/Popover';
import Typography from 'Components/Atoms/Typography';
import useDocument from 'CustomHooks/useDocument';
import useRestaurant from 'CustomHooks/useRestaurant';
import { useRouter } from 'CustomHooks/useRouter';
import { RequestV03Document } from 'gastronaut-shared/types/documents';
import {
  AddOnCard,
  ReservationCard,
  DateCard,
  MailboxCard,
  RatingCard,
  Card,
  NotificationV03,
  WaitingListEntryCard,
} from 'gastronaut-shared/types/helper/notification';
import React, { useEffect, useMemo, useState } from 'react';
import { db } from 'utils/firebase';
import { classHelper, toEuropeanDate } from 'utils/helper';

const WEEKDAYS = [
  'Sonntag',
  'Montag',
  'Dienstag',
  'Mittwoch',
  'Donnerstag',
  'Freitag',
  'Samstag',
];

export const ReservationCardComponent: React.FC<{
  card: ReservationCard;
}> = ({ card }) => {
  const [reservation] = useDocument<RequestV03Document>(
    'requestsV03',
    card.reservation.id
  );

  const { reservationSettings } = useRestaurant();
  const router = useRouter();

  const occassionTitle = useMemo(
    () =>
      (reservationSettings?.occassions?.length || 0) > 1
        ? reservationSettings?.occassions?.find(
            (o) =>
              o.id ===
              (reservation.data?.occassion ?? card.reservation.occassion)
          )?.title
        : '',
    [reservationSettings, reservation.data]
  );

  if (!reservation.data) return <></>;

  return (
    <div
      onClick={() =>
        router.push(
          `/${reservation.data?.restaurant}/reservations/${reservation.data?.date}/tablePlan?reservationId=${card.reservation.id}`
        )
      }
      style={{ cursor: 'pointer' }}
      className={classHelper(['card reservation', reservation.data.status])}
    >
      <p className="top">Reservierung</p>
      <Typography variant="h4" component="h4">
        {reservation.data.guest.name}
      </Typography>
      <Typography
        variant="text-3"
        weight="medium"
        component="p"
        color="subdued"
      >
        {toEuropeanDate(card.reservation.date, true)}, {occassionTitle || ''}
        {!!occassionTitle ? ', ' : ''}
        {card.reservation.time}, {card.reservation.guests} Personen
      </Typography>
    </div>
  );
};

export const WaitinglistCard: React.FC<{
  card: WaitingListEntryCard;
}> = ({ card }) => {
  const [waitinglistEntry] = useDocument<RequestV03Document>(
    'waitinglistEntries',
    card.waitinglistEntry.id
  );

  const { reservationSettings } = useRestaurant();
  const router = useRouter();

  const occassionTitle = useMemo(
    () =>
      (reservationSettings?.occassions?.length || 0) > 1
        ? reservationSettings?.occassions?.find(
            (o) =>
              o.id ===
              (waitinglistEntry.data?.occassion ??
                card.waitinglistEntry.occassion)
          )?.title
        : '',
    [reservationSettings, waitinglistEntry.data]
  );

  if (!waitinglistEntry.data) return <></>;

  return (
    <div
      onClick={() =>
        router.push(
          `/${waitinglistEntry.data?.restaurant}/reservations/${waitinglistEntry.data?.date}/tablePlan`
        )
      }
      style={{ cursor: 'pointer' }}
      className={classHelper([
        'card reservation',
        waitinglistEntry.data.status,
      ])}
    >
      <p className="top">Reservierung</p>
      <Typography variant="h4" component="h4">
        {waitinglistEntry.data.guest.name}
      </Typography>
      <Typography
        variant="text-3"
        weight="medium"
        component="p"
        color="subdued"
      >
        {toEuropeanDate(waitinglistEntry?.data?.date, true)},{' '}
        {occassionTitle || ''}
        {!!occassionTitle ? ', ' : ''}
        {waitinglistEntry?.data?.time}, {waitinglistEntry?.data?.guests}{' '}
        Personen
      </Typography>
    </div>
  );
};

export const AddOnCardComponent: React.FC<{
  card: AddOnCard;
}> = ({ card }) => {
  return <></>; //<div className="card addOn">AddOn</div>;
};

export const DateCardComponent: React.FC<{
  card: DateCard;
}> = ({ card }) => {
  return (
    <div className="card date">
      <div className="left">
        <p className="top">
          {WEEKDAYS[card.date.wDay]} {toEuropeanDate(card.date.date)}
        </p>
        <Typography variant="h4" component="h4">
          {card.date.reservations} Personen
        </Typography>
        <Typography
          variant="text-3"
          weight="medium"
          component="p"
          color="subdued"
        >
          Durchsschnitt {card.date.average}
        </Typography>
      </div>
      {!!card.date?.weather ? (
        <div className="right">
          <img
            src={`https://www.weatherbit.io/static/img/icons/${card.date.weather.weather.icon}.png`}
            alt={card.date.weather.weather.description || ''}
            width={24}
            height={24}
          />
          <Typography variant="h5">
            {card.date.weather.temp.toFixed(0)}°C
          </Typography>
        </div>
      ) : (
        <div className="right"></div>
      )}
    </div>
  );
};

export const MailboxCardComponent: React.FC<{
  card: MailboxCard;
  id: string;
  notification: NotificationV03;
}> = ({ card: data, id, notification }) => {
  const [playing, setplaying] = useState(false);
  const [transcriptAnchor, settranscriptAnchor] = useState<any>();
  const [moreAnchor, setmoreAnchor] = useState<any>();
  const [progress, setprogress] = useState(0);
  const handlePlayed = (ms = 60000) => {
    if (!data?.mailbox?.played) {
      setTimeout(
        () =>
          db
            .collection(`notificationsV03`)
            .doc(id)
            .update({
              cards: notification.cards.map((c) =>
                c.type === 'mailbox'
                  ? { ...c, mailbox: { ...c.mailbox, played: true } }
                  : c
              ),
            }),
        ms
      );
    }
  };
  useEffect(() => {
    if (data.mailbox?.recordingUrl) {
      let audioElement = document.createElement('audio');
      audioElement.src = data.mailbox?.recordingUrl;
      audioElement.loop = false;
      document.body.appendChild(audioElement);

      audioElement.addEventListener('timeupdate', (e: any) => {
        if (e.srcElement.duration === e.srcElement.currentTime) {
          (document as any).playing = false;
          setplaying(false);
          setprogress(0);
        } else {
          setprogress(e.srcElement.currentTime / e.srcElement.duration);
        }
      });

      audioElement.addEventListener(
        'canplay',
        (...x) => ((document as any).canPlay = true)
      );

      async function fn() {
        // if (interval) {
        //   clearInterval(interval);
        //   interval = null;
        // }
        if (!(document as any).canPlay) return;
        if ((document as any).playing) {
          audioElement.pause();

          (document as any).playing = false;
          setplaying(false);
        } else {
          try {
            await audioElement.play();

            (document as any).playing = true;
            // interval = setInterval(() => setprogress((x) => x + 1), 1000);
            setplaying(true);
            handlePlayed((data.mailbox?.duration ?? 60) * 1000);
          } catch (error) {
            console.error(error);
          }
        }
      }

      document.getElementById(`play-${id}`)?.addEventListener('click', fn);
      return () => {
        document.getElementById(`play-${id}`)?.removeEventListener('click', fn);

        audioElement.remove();
      };
    }
  }, [data.mailbox?.recordingUrl]);

  return (
    <div>
      {!!data.mailbox && (
        <Box>
          {/* <Typography
            variant={'text-2'}
            weight="medium"
            style={{ marginBottom: 8 }}
            component="p"
          >
            "
            {data.mailbox.optimizedTranscription ||
              data.mailbox.transcription ||
              'Nichts erkannt'}
            "
          </Typography> */}

          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              gap: 8,
              alignItems: 'center',
              marginBottom: 8,
            }}
          >
            <div
              className="center"
              id={`play-${id}`}
              style={{
                width: 40,
                height: 40,
                background: data.mailbox?.played
                  ? 'var(--color-secondary)'
                  : 'var(--color-primary)',
                borderRadius: 20,
                cursor: 'pointer',
              }}
            >
              {!playing ? (
                <PlayArrow htmlColor="#ffffff" />
              ) : (
                <Pause htmlColor="#ffffff" />
              )}
            </div>
            <AudioWave
              url={
                (data.mailbox as any).twilioRecordingUrl ??
                data.mailbox.recordingUrl
              }
              callSid={id}
              preLoadedAudioWave={(data.mailbox as any)?.audioWave}
              progress={progress}
            />
            <Typography variant="text-3">
              {durationToMin(data.mailbox.duration * 1000)}
            </Typography>
            <IconButton
              size="small"
              onClick={(e) => settranscriptAnchor(e.target)}
            >
              <Notes />
            </IconButton>
            <Popover
              open={!!transcriptAnchor && !!data.mailbox.transcription}
              anchorEl={transcriptAnchor}
              onClose={() => settranscriptAnchor(null)}
              style={{ maxWidth: '240px' }}
            >
              <Typography variant="text-3" weight="bold">
                Transkription:
              </Typography>
              <Typography variant="text-3" color="subdued">
                {data.mailbox.transcription}
              </Typography>
            </Popover>
          </Box>
        </Box>
      )}
    </div>
  );
};

export const RatingCardComponent: React.FC<{
  card: RatingCard;
}> = ({ card }) => {
  return (
    <div className="card rating">
      <p className="top">Bewertung</p>
      <Typography
        variant="text-3"
        weight="medium"
        component="p"
        color="subdued"
      >
        {card.rating?.comment ?? ''}
      </Typography>
      {card.rating && <Rating value={card.rating.rating} readOnly />}
    </div>
  );
};

const CardComponent: React.FC<{
  card: Card;
  id: string;
  notification: NotificationV03;
}> = ({ card, id, notification }) => {
  if (card.type === 'reservation') {
    return <ReservationCardComponent card={card} />;
  } else if (card.type === 'addOn') {
    return <AddOnCardComponent card={card} />;
  } else if (card.type === 'date') {
    return <DateCardComponent card={card} />;
  } else if (card.type === 'mailbox') {
    return (
      <MailboxCardComponent card={card} id={id} notification={notification} />
    );
  } else if (card.type === 'rating') {
    return <RatingCardComponent card={card} />;
  } else if (card.type === 'waitinglistEntry') {
    return <WaitinglistCard card={card} />;
  } else {
    return <></>;
  }
};

export default CardComponent;
