import React, { useEffect } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import Modal from 'Components/Molecules/Modal';
import TextField from 'Components/Atoms/TextField';
import Select from 'Components/Atoms/Select';
import AttributeDropdown from 'App/ReservationBook/Components/AttributeDropdown';
import useRestaurant from 'CustomHooks/useRestaurant';

export type NewGuestModal = {
  name: string;
  email: string;
  phone: string;
  guestComment: string;
  anniversary?: string;
  birthday?: string;
  customAttr?: string[];
  attr?: string[];
  preferredLanguage?: string;
  company?: string;
};

export type NewGuestModalProps = {
  open: boolean;
  onSubmit: (guest: NewGuestModal) => void | Promise<void>;
  onClose: () => void;
};

const NewGuestModal: React.FC<NewGuestModalProps> = ({
  open,
  onSubmit,
  onClose,
}) => {
  const [errors, seterrors] = React.useState<Record<string, string>>({});

  const [state, setNewGuestModal] = React.useState<NewGuestModal | null>(null);

  const { experimentalFlags } = useRestaurant();

  useEffect(() => {
    if (open && !state) {
      setNewGuestModal({
        name: '',
        email: '',
        phone: '',
        guestComment: '',
        anniversary: '',
        birthday: '',
        customAttr: [],
        attr: [],
      });
    } else if (!open && state) {
      setNewGuestModal(null);
    }
  }, [open]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    setNewGuestModal((g) => (!!g ? { ...g, [e.target.name]: value } : null));
    seterrors((x) => ({ ...x, [e.target.name]: '' }));
  };

  const handleSubmit = () => {
    let errors: Record<string, string> = {};

    if (!state?.name) {
      errors.name = 'Name is required';
    }

    if (state?.email && !state?.email.includes('@')) {
      errors.email = 'Invalid email';
    }

    if (state?.phone && state?.phone.length < 7 && state?.phone.length > 3) {
      errors.phone = 'Invalid phone number';
    }

    // if (!state?.email && !state?.phone) {
    //   errors.email = 'Email or phone number is required';
    // }

    if (Object.keys(errors).length > 0) {
      seterrors(errors);
      return;
    }

    console.log(state);

    if (state) {
      onSubmit(state);
    }
  };

  return (
    <Modal
      open={!!state}
      title="New Guest"
      titleTranslation="dashboard"
      actions={[
        { id: 'cancel', label: 'Cancel', onClick: onClose },
        {
          id: 'save',
          label: 'Save',
          variant: 'primary',
          onClick: handleSubmit,
        },
      ]}
      fullWidth
      // onClose={onClose}
      maxWidth="md"
    >
      <Box
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: 16,
          width: '100%',
        }}
      >
        <Box>
          <TextField
            label="Name"
            name="name"
            value={state?.name ?? ''}
            onChange={handleChange}
            errorMessage={errors.name}
            fullWidth
          />

          <TextField
            label="Email"
            name="email"
            value={state?.email ?? ''}
            onChange={handleChange}
            error={!!errors.email}
            errorMessage={errors.email}
            type="email"
            fullWidth
          />

          <TextField
            label="Phone Number"
            name="phone"
            value={state?.phone ?? ''}
            onChange={handleChange}
            error={!!errors.phone}
            errorMessage={errors.phone}
            type="phone"
            defaultCountry={
              experimentalFlags?.data?.phoneDefaultCountry || 'de'
            }
            fullWidth
          />
          <AttributeDropdown
            onChange={(attr) =>
              setNewGuestModal((g) => (!!g ? { ...g, attr } : null))
            }
            value={state?.attr || []}
            guestBook
            style={{
              minHeight: 46,
              paddingTop: 6,
              paddingBottom: 8,
              paddingLeft: 16,
              border: 'none',
            }}
          />
        </Box>
        <Box>
          <TextField
            fullWidth
            labelTranslation="common"
            label="Company"
            onChange={handleChange}
            name="company"
            value={state?.company || ''}
          />
          <TextField
            fullWidth
            labelTranslation="common"
            label="Birthday"
            onChange={handleChange}
            name="birthday"
            value={state?.birthday || ''}
            type="text"
          />
          <TextField
            fullWidth
            labelTranslation="common"
            label="Anniversary"
            onChange={handleChange}
            name="anniversary"
            value={state?.anniversary || ''}
            type="text"
          />
          <Select
            options={[
              { id: 'de', label: 'German', translation: 'common' },
              { id: 'en', label: 'English', translation: 'common' },
              { id: 'fr', label: 'French', translation: 'common' },
            ]}
            value={state?.preferredLanguage || 'de'}
            name="preferredLanguage"
            onChange={(e) =>
              setNewGuestModal((g) =>
                !!g
                  ? { ...g, preferredLanguage: e.target.value as string }
                  : null
              )
            }
            // style={{
            //   margin: '0',
            // }}
            margins={0}
            className="selectInput new-guest-select"
            label="Language"
            translation="common"
            fullWidth
          />
        </Box>
      </Box>
      <TextField
        label="Comment"
        name="guestComment"
        value={state?.guestComment ?? ''}
        onChange={handleChange}
        fullWidth
        multiline
        minRows={5}
      />
    </Modal>
  );
};

export default NewGuestModal;
