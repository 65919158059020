import Box from "Components/Atoms/Box";
import Typography from "Components/Atoms/Typography";
import React, { useMemo } from "react";
import { classHelper } from "utils/helper";
import { Column } from "..";

type RowProps = {
  columns: Column[];
  selected: boolean;
  id: any;
  data: object | any;
  onClick: (id: any) => void;
  ContentWidth: string[] | any;
  className?: string;
  style?: object;
};

const Row = ({
  id,
  onClick,
  selected,
  data,
  columns,
  ContentWidth,
  className,
  style,
}: RowProps) => {
  const findLongest = (words: any) =>
    Math.max(...(words?.map((el: string | any[]) => el?.length ?? 0) ?? 1));

  const outputWidth = useMemo(() => {
    let output: any = {};
    for (let [key, value] of Object.entries(ContentWidth)) {
      if (value) output[key] = findLongest(value) * 9;
    }
    return output;
  }, [ContentWidth]);
  return (
    <Box
      flex
      padding="md"
      underline
      onClick={() => onClick(id)}
      className={classHelper([className, selected && "selected-row"])}
      style={style}
    >
      {columns.map((column) => (
        <>
          <Box
            flex
            style={{
              alignItems:
                column.align === "right"
                  ? "flex-end"
                  : column.align === "left"
                  ? "flex-start"
                  : "center",
              justifyContent:
                column.align === "right"
                  ? "flex-end"
                  : column.align === "left"
                  ? "flex-start"
                  : "center",
              minWidth: column.minWidth
                ? column.minWidth
                : outputWidth[column.id],
              width: "inherit",
            }}
          >
            <Typography
              variant="text-3"
              translation={null}
              color="subdued"
              textAlign={column.align}
              style={
                column.minWidth
                  ? { minWidth: column.minWidth }
                  : ContentWidth?.[column.id]
                  ? {
                      minWidth:
                        column.label.length * 12.25 >
                        Math.max(
                          ...ContentWidth?.[column.id].map(
                            (el: any) => String(el).length * 8
                          )
                        )
                          ? column.label.length * 12.25
                          : Math.max(
                              ...ContentWidth?.[column.id].map(
                                (el: any) => String(el).length * 9
                              )
                            ),
                      flex: 1,
                    }
                  : { flex: 1 }
              }
            >
              {data?.[column.id] || ""}
            </Typography>
          </Box>
        </>
      ))}
    </Box>
  );
};

export default Row;
