import Box from 'Components/Atoms/Box';
import Typography from 'Components/Atoms/Typography';
import useCollection from 'CustomHooks/useCollection';
import React, { useEffect, useMemo } from 'react';
import { Reservation } from 'types/reservations';
import {
  blockToTime,
  dateHelper,
  historyKeyHelper,
  noop,
  timeStampToDateAndHour,
} from 'utils/helper';
import { History, Change } from '../index';

function cleanUpName({ name, uid }: { name: string; uid: string }) {
  if (uid === 'system') {
    return 'System';
  } else if (uid === 'guest') {
    return 'Gast';
  } else if (uid.length < 6) {
    return uid;
  } else {
    return name || (uid.length > 10 ? 'Unb. Nutzer' : uid);
  }
}

type ReservationHistoryProps = {
  history: History[] | null;
  reservationId: string;
  reservation: Reservation;
  setHasOverflow: (value: { bottom: boolean; top: boolean }) => void;
  handleScrolling?: (
    event: React.UIEvent<HTMLDivElement | HTMLLabelElement | HTMLFormElement>
  ) => void;
};

function statusMap(status: string) {
  return (
    {
      waiting: 'Ausstehend',
      pending: 'Ausstehend',
      success: 'Gesendet',
      delivered: 'Gesendet',
      failed: 'Fehlgeschlagen',
      opened: 'Geöffnet',
    }[status] ||
    status ||
    '-'
  );
}

function checkEmail(
  resa: Reservation,
  type:
    | 'confirmationEmail'
    | 'waitingList'
    | 'canceled'
    | 'reminder'
    | 'followUp',
  history: History[] | null
) {
  switch (type) {
    case 'confirmationEmail': {
      if (resa.source?.toLocaleLowerCase() !== 'inhouse') {
        if (!resa.guest.email) {
          return 'keine Email';
        } else if (!resa.guest.sendEmail) {
          return 'Gast hat dem senden nicht zugestimmt';
        } else if (resa.validTill) {
          return 'Resa nicht Final';
        }
      } else if (resa.source === 'phone') {
        return 'SMS versendet';
      } else if (resa.status === 'positive' || resa.status === 'pending') {
        return 'Noch nicht bestätigt';
      }

      if (
        history?.some(
          (x) =>
            x?.updateNote?.note === 'Sende Emails an Gast eingeschaltet' ||
            x.changes.some(
              (x) => x.title === 'Sende Emails an Gast eingeschaltet'
            )
        )
      ) {
        return 'Gast hat dem senden nicht zugestimmt';
      }

      return 'N. gesendet';
    }
    case 'canceled': {
      if (resa.status !== 'failed') return null;

      if (resa.canceledBy === 'guest') return 'Wurde von Gast storniert';

      if (!resa.sendCancelationEmail)
        return 'Service hat nicht auf senden geklickt nach dem stornieren';

      return 'Unbekannt';
    }
    case 'reminder': {
      let createdAt = dateHelper(resa.createdAt);

      if (createdAt === resa.date) return 'Resa vom selben Tag';

      if (resa.source?.toLocaleLowerCase() === 'inhouse') {
        if (!resa.guest.email) {
          return 'keine Email';
        } else if (!resa.guest.sendEmail) {
          return 'Gast hat dem senden nicht zugestimmt';
        } else if (resa.validTill) {
          return 'Resa nicht Final';
        }
      } else if (resa.source === 'phone') {
        return 'SMS versendet';
      }

      return 'Wird am morgen der Resa versendet';
    }
    default: {
      return null;
    }
  }
}

const ReservationHistory = ({
  history,
  reservationId,
  reservation,
  setHasOverflow,
  handleScrolling = noop,
}: ReservationHistoryProps) => {
  useEffect(() => {
    const container = document.getElementById('contact-container');
    if (!!container) {
      const overFlow = container.clientHeight < container.scrollHeight;
      setHasOverflow({ bottom: overFlow, top: false });
    }
  }, [setHasOverflow]);

  const getChangeString = (change: Change) => {
    let answer: string = historyKeyHelper(change.key);

    if (change.title) return change.title;

    if (typeof change.after === 'boolean') {
      return (answer += change.after
        ? 'Wurde auf WAHR gesetzt'
        : 'Wurde auf FALSCH gesetzt');
    }

    if (!change.after && !change.before) return '';

    if (typeof change.after === 'object' && typeof change.before === 'object')
      return '';

    if (change.before) {
      if (typeof change.before !== 'object') {
        let beforeValue = change.before;
        if (
          ['started', 'oldReservationLength', 'reservationLength'].includes(
            change.key
          )
        ) {
          beforeValue = blockToTime(beforeValue);
        }
        answer += ' ' + beforeValue;
      }
    }
    if (change.after) {
      let afterValue = change.after;

      if (typeof change.after !== 'object') {
        if (
          ['started', 'oldReservationLength', 'reservationLength'].includes(
            change.key
          )
        ) {
          afterValue = blockToTime(afterValue);
        }
        answer += ' to ' + afterValue;
      }
    }
    return answer;
  };

  const [emails] = useCollection<{
    type:
      | 'confirmationEmail'
      | 'waitingList'
      | 'canceled'
      | 'reminder'
      | 'followUp';
    status:
      | 'waiting'
      | 'pending'
      | 'success'
      | 'delivered'
      | 'failed'
      | 'opened';
  }>('emails', {
    filter: [['belongsTo', '==', reservationId]],
  });

  const emailMap = useMemo(() => {
    if (reservation.source === 'phone') return null;

    if (
      !(
        !!reservation.guest.email &&
        (reservation.source?.toLocaleLowerCase() !== 'inhouse' ||
          reservation.guest.sendEmail)
      )
    ) {
      return {
        confirmation: null,
        reminder: null,
        canceled: null,
        waitingList: null,
        reason:
          'Der Gast hat dem senden von E-Mails nicht zugestimmt. Beim Anlegen der Reservierung habt ihr die Möglichkeit einen Haken zur Einwilligung zu setzen.  Im Nachgang ist das auch in Kontakt noch möglich.',
      };
    }

    // if (!emails?.data?.length) return null;

    let confirmation =
        emails.data.find((e) => e.type === 'confirmationEmail')?.status ??
        checkEmail(reservation, 'confirmationEmail', history),
      reminder =
        emails.data.find((e) => e.type === 'reminder')?.status ??
        checkEmail(reservation, 'reminder', history),
      canceled =
        emails.data.find((e) => e.type === 'canceled')?.status ??
        checkEmail(reservation, 'canceled', history),
      waitingList =
        emails.data.find((e) => e.type === 'waitingList')?.status ??
        checkEmail(reservation, 'waitingList', history);

    return {
      confirmation,
      reminder,
      canceled,
      waitingList,
    };
  }, [emails, reservation, history]);

  return (
    <Box
      className="reservation-side-bar"
      id="history-container"
      style={{ maxHeight: '100%', paddingTop: 3 }}
      onScroll={(e) => handleScrolling(e)}
    >
      {!!emailMap && (
        <>
          <Box style={{ padding: 16, paddingBottom: 0 }}>
            <Typography variant="text-3" block weight="medium">
              Emails
            </Typography>
          </Box>
          {emailMap?.reason && (
            <Box className="space-between reservation-field">
              <Typography variant="text-3" translation="reservations">
                {emailMap.reason}
              </Typography>
            </Box>
          )}
          {emailMap.waitingList && (
            <Box
              className="space-between reservation-field"
              style={{ gap: 8, alignItems: 'flex-start' }}
            >
              <Typography variant="text-3" translation="reservations">
                Später bestätigen
              </Typography>
              <Typography
                color="subdued"
                variant="text-3"
                translation="reservations"
                textAlign="right"
              >
                {statusMap(emailMap.waitingList)}
              </Typography>
            </Box>
          )}
          {emailMap.confirmation && (
            <Box
              className="space-between reservation-field"
              style={{ gap: 8, alignItems: 'flex-start' }}
            >
              <Typography variant="text-3" translation="reservations">
                Bestätigung
              </Typography>
              <Typography
                color="subdued"
                variant="text-3"
                translation="reservations"
                textAlign="right"
              >
                {statusMap(emailMap.confirmation)}
              </Typography>
            </Box>
          )}
          {emailMap.reminder && (
            <Box
              className="space-between reservation-field"
              style={{ gap: 8, alignItems: 'flex-start' }}
            >
              <Typography variant="text-3" translation="reservations">
                Erinnerung
              </Typography>
              <Typography
                color="subdued"
                variant="text-3"
                translation="reservations"
                textAlign="right"
              >
                {statusMap(emailMap.reminder)}
              </Typography>
            </Box>
          )}
          {emailMap.canceled && (
            <Box
              className="space-between reservation-field"
              style={{ gap: 8, alignItems: 'flex-start' }}
            >
              <Typography variant="text-3" translation="reservations">
                Absage
              </Typography>
              <Typography
                color="subdued"
                variant="text-3"
                translation="reservations"
                textAlign="right"
              >
                {statusMap(emailMap.canceled)}
              </Typography>
            </Box>
          )}
          <Box style={{ padding: 16, paddingBottom: 8 }}>
            <Typography variant="text-3" block weight="medium">
              Historie
            </Typography>
          </Box>
        </>
      )}
      {history
        ?.sort((a, b) => (+a.id || 0) - (+b.id || 0))
        ?.map((h, index) => (
          <Box key={index} style={{ paddingLeft: 16, paddingTop: 8 }}>
            <Box flex className="flex-start">
              <div className="primary-dot"></div>
              <Box
                className="space-between flex-start"
                style={{ paddingRight: 16, paddingLeft: 8 }}
              >
                <Typography variant="text-3" translation="common">
                  {cleanUpName(h.updatedBy)}
                </Typography>
                <Typography
                  variant="text-3"
                  color="subdued"
                  translation={null}
                  block
                >
                  {timeStampToDateAndHour(h.id).date}
                </Typography>
              </Box>
            </Box>
            <Box flex style={{ alignItems: 'stretch' }}>
              {index < history.length - 1 ? (
                <div className="history-line"></div>
              ) : (
                <div className="no-line"></div>
              )}
              <Box
                className="space-between flex-start"
                style={{
                  padding: '0px 16px 11px 8px',
                  marginBottom: 2,
                  borderBottom: '1px solid var(--color-border)',
                }}
              >
                {!h.updateNote?.note && (
                  <Box>
                    {h.changes.map((change, i) => (
                      <Typography
                        key={i}
                        variant="text-3"
                        translation="reservations"
                        color="subdued"
                        block
                      >
                        {getChangeString(change)}
                      </Typography>
                    ))}
                  </Box>
                )}
                {!!h.updateNote?.note && (
                  <Typography
                    variant="text-3"
                    translation="reservations"
                    color="subdued"
                    block
                  >
                    {h.updateNote.note}
                  </Typography>
                )}
                <Typography
                  variant="text-3"
                  color="subdued"
                  translation={null}
                  block
                >
                  {timeStampToDateAndHour(h.id).time}
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      {!history && (
        <Box padding="md" fullSize>
          <Typography variant="text-2" color="subdued" textAlign="center" block>
            No data yet
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ReservationHistory;
