import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import Box from 'Components/Atoms/Box';
import Typography from 'Components/Atoms/Typography';
import React, { useState } from 'react';
import { classHelper } from 'utils/helper';

const AdditionalSettings: React.FC<{}> = (props) => {
  const [open, setopen] = useState(false);

  return <>{props.children}</>;

  // return (
  //   <Box className="additional_settings">
  //     <Box
  //       className={classHelper(["top", open && "open"])}
  //       onClick={() => setopen((o) => !o)}
  //     >
  //       <Typography variant="h6" translation="calendar">
  //         Additional Settings
  //       </Typography>
  //       {open ? <ArrowDropUp /> : <ArrowDropDown />}
  //     </Box>
  //     {open && <Box>{props.children}</Box>}
  //   </Box>
  // );
};

export default AdditionalSettings;
