import React, { useEffect, useState } from 'react';
import server from 'utils/server';
import Box from 'Components/Atoms/Box';
import Typography from 'Components/Atoms/Typography';
import Button, { ButtonVariants } from 'Components/Atoms/Button';
import { CircularProgress } from '@material-ui/core';

type LinkType =
  | 'self'
  | 'chargebacks'
  | 'customers'
  | 'invoices'
  | 'payments'
  | 'profiles'
  | 'refunds'
  | 'settlements'
  | 'dashboard'
  | 'documentation';

type MollieOrganization = {
  resource: 'organization';
  id: string;
  name: string;
  email: string;
  address: {
    streetAndNumber: string;
    postalCode: string;
    city: string;
    country: string;
  };
  registrationNumber: string;
  vatNumber: string;
  vatRegulation: 'shifted' | 'dutch';
  _links: Record<
    LinkType,
    { href: string; type: 'application/hal+json' | 'text|html' }
  >;
  onboarding: OnboardingStatus;
};

type OnboardingStatus = {
  resource: 'onboarding';
  name: string;
  signedUpAt: string;
  canReceivePayments: boolean;
  canReceiveSettlements: boolean;
  status: 'completed' | 'in-review' | 'needs-data';
};

export type MolliePageProps = {
  restaurantId: string;
};

const Mollie: React.FC<{
  restaurantId: string;
  buttonVariant?: ButtonVariants;
}> = ({ restaurantId, buttonVariant = 'primary' }) => {
  const [loading, setLoading] = useState(true);
  const [mollieAccount, setMollieAccount] = useState<MollieOrganization | null>(
    null
  );
  const [error, seterror] = useState<string | null>(null);
  useEffect(() => {
    setLoading(true);
    setMollieAccount(null);
    seterror(null);

    server
      .get<MollieOrganization>(`/v03/mollie/organization/${restaurantId}`)
      .then(async ({ data }) => {
        setMollieAccount(data);
      })
      .catch((err) => {
        if (err?.response?.status !== 404) {
          seterror(
            err?.response?.data?.message ??
              err?.response?.data ??
              'Es ist ein Fehler aufgetreten'
          );
        }
        setMollieAccount(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [restaurantId]);

  return (
    <Box>
      {!!error && (
        <Typography
          variant="text-3"
          color="error"
          block
          style={{ marginTop: 3, marginBottom: 17 }}
        >
          {error}
        </Typography>
      )}
      {loading && (
        <Box flex className="center">
          <CircularProgress />
        </Box>
      )}

      {!loading && !mollieAccount && !error && (
        <Button
          variant={buttonVariant}
          href={`https://api.gastronaut.ai/v03/mollie/connectWithMollie/${restaurantId}`}
        >
          Connect Mollie Account
        </Button>
      )}
      {!loading &&
        !error &&
        !!mollieAccount &&
        (mollieAccount?.onboarding?.status !== 'needs-data' ? (
          <Button
            href={mollieAccount._links.dashboard.href}
            variant={buttonVariant}
            target="_blank"
            translation="settings"
          >
            Open Mollie Dashboard
          </Button>
        ) : (
          <>
            <Typography
              block
              variant="text-3"
              color="error"
              style={{ marginBottom: 8 }}
              translation="settings"
            >
              Not all data has yet been sent to Mollie. To accept payments,
              please complete the missing information by clicking on the button.
            </Typography>
            <Button
              href={mollieAccount._links.dashboard.href}
              variant={buttonVariant}
            >
              Continue
            </Button>
          </>
        ))}
    </Box>
  );
};

export default Mollie;
