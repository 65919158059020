import TextField from "Components/Atoms/TextField";
import Modal from "Components/Molecules/Modal";
import React from "react";

type CustomTagModalProps = {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleFilterSubmit: (attr: string) => void;
  setCustomAttribute: React.Dispatch<React.SetStateAction<string>>;
  customAttribute: string;
};

const CustomTagModal = ({
  openModal,
  setOpenModal,
  handleFilterSubmit,
  setCustomAttribute,
  customAttribute,
}: CustomTagModalProps) => {
  return (
    <Modal
      open={openModal}
      title="Add Custom Tags"
      titleTranslation="common"
      onClose={() => setOpenModal(false)}
      actions={[
        {
          id: "cancel",
          label: "Cancel",
          onClick: () => setOpenModal(false),
        },
        {
          id: "submit",
          label: "Submit",
          onClick: () => {
            handleFilterSubmit(customAttribute);
          },
          variant: "primary",
        },
      ]}
    >
      <TextField
        fullWidth
        id="newFilters"
        label="Add New Tag"
        labelTranslation="common"
        value={customAttribute}
        style={{ margin: "8px" }}
        name="customAttr"
        onChange={(e) => setCustomAttribute(e.target.value)}
      />
    </Modal>
  );
};

export default CustomTagModal;
