import ImageModal from 'Components/Organisms/ImageModal';
import LinkGenerator from 'Components/Organisms/LinkGenerator';
import useImage from 'CustomHooks/useImage';
import { url } from 'inspector';
import React, { createContext, useEffect, useState } from 'react';
import { Folder, Image } from 'types/images';
import { db, functions, storageRef } from 'utils/firebase';
import { asyncVoid, noop } from 'utils/helper';

type Props = {
  restaurantId: null | string;
  children?: React.ReactNode;
  storyBook?: boolean;
};

type Context = {
  open: boolean;
  onClose: () => void;
  multiple: boolean;
  folders: {
    data: Folder[];
    loading: boolean;
    error: null | string;
    listener: any[];
  };
  images: {
    data: Image[];
    filteredData: Image[];
    loading: boolean;
    error: null | string;
    listener: any[];
  };
  currentFolder: string;
  setcurrentFolder: React.Dispatch<React.SetStateAction<string>>;
  currentImage: string | null;
  setcurrentImage: React.Dispatch<React.SetStateAction<string | null>>;
  selectedImages: string[];
  setselectedImages: React.Dispatch<React.SetStateAction<string[]>>;
  onAddFolder: (name: string) => Promise<string>;
  editImage: (id: string, name: string, value: string | string[]) => void;
  handleImageUpload: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  deleteImage: (id: string, deleteFile: boolean) => Promise<void>;
  openImageModal: (
    onSubmitFunction: (s: string | null | (string | null)[]) => void,
    modalOptions: {
      multiple: boolean;
      id: boolean;
      delete?: boolean;
      search?: string | null;
      value?: null | string | (string | null)[];
    }
  ) => void;
  handleSubmit: () => Promise<void>;
  imageLoading: boolean;
  imageError: string | null;
  search: null | string;
  setsearch: React.Dispatch<React.SetStateAction<string | null>>;
  getUrl: (id: string, thumbnail: boolean) => Promise<string>;
  getImages: (
    images: (string | null)[],
    type: 'id' | 'url'
  ) => Promise<{ id: string; url: string; originalName: string }[]>;
  instagramID: string | null;
  setInstagramID: React.Dispatch<React.SetStateAction<string | null>>;
  submitInstagramID: () => Promise<void>;
  fetchInstagramData: () => Promise<void>;
  isFetching: boolean;
  openLinkGenerator: () => void;
};

export const ImageContext = createContext<Context>({
  open: false,
  onClose: noop,
  multiple: false,
  folders: {
    data: [],
    loading: false,
    error: null,
    listener: [],
  },
  images: {
    data: [],
    filteredData: [],
    loading: false,
    error: null,
    listener: [],
  },
  currentFolder: 'general',
  setcurrentFolder: noop,
  currentImage: null,
  setcurrentImage: noop,
  selectedImages: [],
  setselectedImages: noop,
  onAddFolder: async () => '',
  editImage: noop,
  handleImageUpload: asyncVoid,
  deleteImage: asyncVoid,
  openImageModal: noop,
  handleSubmit: asyncVoid,
  imageLoading: false,
  imageError: null,
  search: null,
  setsearch: noop,
  getUrl: async () => '',
  getImages: async () => [],
  instagramID: null,
  setInstagramID: noop,
  submitInstagramID: asyncVoid,
  fetchInstagramData: asyncVoid,
  isFetching: false,
  openLinkGenerator: noop,
});

const onSubmitTest = (s: string | null | (string | null)[]) =>
  console.log('submited', s);

const ImageContextProvider = ({
  restaurantId,
  children,
  storyBook = false,
}: Props) => {
  const [modal, setmodal] = useState<{
    open: boolean;
    options: {
      multiple: boolean;
      id: boolean;
      delete?: boolean;
      value?: null | string | (string | null)[];
    };
    onSubmit: (s: string | null | (string | null)[]) => void;
  }>({
    open: false,
    options: { multiple: false, id: false, delete: false },
    onSubmit: onSubmitTest,
  });
  const [currentFolder, setcurrentFolder] = useState<string>('general');
  const [currentImage, setcurrentImage] = useState<string | null>(null);
  const [selectedImages, setselectedImages] = useState<string[]>([]);

  const [imageLoading, setimageLoading] = useState<boolean>(false);
  const [imageError, setimageError] = useState<string | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const [search, setsearch] = useState<null | string>(null);

  const { folders, images } = useImage(restaurantId, currentFolder, search);

  const folderRef = db.collection(`restaurants/${restaurantId}/imageFolders`);
  const imageRef = db.collection(`restaurants/${restaurantId}/images`);

  //INSTAGRAM ID
  const instagramIDRef = db
    .collection(`restaurants/${restaurantId}/settings`)
    .doc('social-media');

  const [instagramID, setInstagramID] = useState<string | null>(null);

  // useEffect(() => {
  //   const getInstaIDFromDB = async () => {
  //     try {
  //       const socialMediaData = await instagramIDRef.get();
  //       if (!socialMediaData.exists) {
  //         return;
  //       }
  //       const instaID = socialMediaData.data()?.instagram ?? '';
  //       setInstagramID(instaID);
  //     } catch (error) {
  //       console.log('error on fecth instagram ID', error);
  //     }
  //   };
  //   getInstaIDFromDB();

  //   return () => {

  //   }
  // }, [restaurantId]);

  const submitInstagramID = async () => {
    try {
      await instagramIDRef.update({
        instagram: instagramID?.split('@').join('') ?? '',
      });
      await db
        .collection(`restaurants/${restaurantId}/settingDrafts`)
        .doc('social-media')
        .update({ instagram: instagramID?.split('@').join('') ?? '' });
    } catch (error) {
      console.log('error on submit instagram id', error);
    }
  };

  const fetchInstagramData = async () => {
    try {
      setIsFetching(true);
      let fetchInstagramDataFct = functions.httpsCallable('fetchInstagramData');
      await fetchInstagramDataFct({ restaurantId, instagramId: instagramID });
      setIsFetching(false);
    } catch (error: any) {
      console.log('error on fetch instagram data', error);
      setimageError('Something went wrong : ' + error.message);
      setIsFetching(false);
    }
  };

  const openImageModal = (
    onSubmit: (s: string | null | (string | null)[]) => void = onSubmitTest,
    modalOptions: {
      multiple: boolean;
      id: boolean;
      delete?: boolean;
      search?: string | null;
      value?: null | string | (string | null)[];
    } = {
      multiple: false,
      id: false,
      delete: false,
    }
  ) => {
    setsearch(modalOptions.search || null);
    setmodal({ open: true, options: { ...modalOptions }, onSubmit });
    if (Array.isArray(modalOptions.value)) {
      setselectedImages(modalOptions.value as any);
    }
    setcurrentImage(null);
  };

  const onClose = () => setmodal((m) => ({ ...m, open: false }));

  const onAddFolder = async (name: string) => {
    const reference = `/restaurants/${restaurantId}/images/${name}`;
    const data = {
      name,
      reference,
    };
    const id = name.toLocaleLowerCase();
    await folderRef.doc(id).set(data);
    setcurrentFolder(id);
    return id;
  };

  const editImage = (id: string, name: string, value: string | string[]) => {
    if (!!name) {
      imageRef.doc(id).update({ [name]: value });
    }
  };

  const uploadImage = async (
    image: File,
    folder: string = 'general',
    id: string | null = null
  ) => {
    let { size, name: imageName, type } = image;

    if (id) {
      await imageRef.doc(id).update({
        imageName,
        originalName: imageName,
        size,
        type,
        thumbnails: [],
        updatedAt: Date.now(),
      });

      const doc = await imageRef.doc(id).get();
      if (doc.exists) {
        folder = doc.data()?.folder || 'general';
      }
    } else {
      const doc = await imageRef.add({
        folder,
        originalName: imageName,
        imageName,
        size,
        type,
        alt: '',
        tags: [],
        thumbnails: [],
        createdAt: Date.now(),
      });

      id = doc.id;
    }

    const imageType = `.` + imageName.split('.').slice(-1)[0];

    imageName = imageName.replace(imageType, '') + '_id_' + id + imageType;

    const reference = `/restaurants/${restaurantId}/images/${folder}/${imageName}`;

    const fileRef = storageRef.child(reference);

    try {
      await fileRef.put(image);

      const url = await fileRef.getDownloadURL();

      await imageRef.doc(id).update({ reference, imageName, url });

      return {
        url,
        id,
      };
    } catch (error) {
      await imageRef.doc(id).delete();

      // throw error;

      return {
        url: null,
        id,
      };
    }
  };

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      // console.log(e.target.files);
      setimageLoading(true);
      setimageError(null);
      const { files = [] } = e.target;

      if (!files || !files.length) return;

      let promises: Promise<{ url: string; id: string }>[] = [];

      for (let index = 0; index < files.length; index++) {
        const image = files[index];
        if (image.size > 1000000) {
          setimageError(
            'Die maximale Bildgröße beträgt 1 MB. Bitte wählen Sie ein anderes Bild aus oder verkleinern Sie dieses.'
          );
        } else if (!image.type.includes('image')) {
          setimageError('Es können nur Bilder hochgeladen werden');
        } else {
          promises.push(uploadImage(image, currentFolder));
        }
      }
      if (!imageError) {
        const imagesList = await Promise.all(promises);
        setimageLoading(false);
        setcurrentImage(imagesList[imagesList.length - 1].id || null);
      } else {
        setimageLoading(false);
        return;
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const deleteImage = async (id: string, deleteFile: boolean = true) => {
    const ref = imageRef.doc(id);

    const doc = await ref.get();

    if (!doc.exists) {
      throw new Error("Can't find Doc with id of " + id);
    }

    const { reference } = doc.data() as Image;

    const fileRef = storageRef.child(reference);

    await fileRef.delete();

    if (deleteFile) {
      await ref.delete();
    }

    setcurrentImage(null);
    return;
  };

  const handleSubmit = async () => {
    let selected: string | (string | null)[] | null = selectedImages.length
      ? selectedImages
      : [currentImage];

    if (!modal.options.id) {
      selected = selected.map(
        (x) => images.data.find((i) => i.id === x)?.url || x
      );
    }

    if (!modal.options.multiple) {
      selected = selected[0];
    }

    if (!!modal.onSubmit) {
      modal.onSubmit(selected);
    }

    setmodal((m) => ({
      ...m,
      open: false,
      options: { multiple: false, id: false },
    }));
  };

  const getUrl = async (id: string, thumbnail: boolean = false) => {
    const ref = db.collection(`restaurants/${restaurantId}/images`).doc(id);

    const doc = await ref.get();

    // console.log(doc.exists);

    if (!doc.exists) {
      return '';
    }

    const { url = '', thumbnails = [] } = doc.data() as Image;

    if (thumbnail && thumbnails.length) return thumbnails[0].url;

    return url;
  };

  const getImages = async (
    images: (string | null)[],
    type: 'id' | 'url' = 'id'
  ) => {
    let imagesArray: { id: string; url: string; originalName: string }[] = [];

    if (type === 'url') {
      return images
        .map((x, i) => ({
          id: x,
          url: x,
          originalName: '',
        }))
        ?.filter((x) => x.url) as {
        id: string;
        url: string;
        originalName: string;
      }[];
    }

    const imagesRef = await db
      .collection(`restaurants/${restaurantId}/images`)
      .get();
    imagesRef.forEach((doc) => {
      const { url, originalName } = doc.data() as Image;
      if (images.includes(doc.id)) {
        imagesArray.push({ id: doc.id, url, originalName });
      }
    });

    return imagesArray;
  };

  const [linkGeneratorOpen, setlinkGeneratorOpen] = useState(false);

  return (
    <ImageContext.Provider
      value={{
        open: modal.open,
        onClose,
        multiple: modal.options.multiple,
        folders,
        images,
        currentFolder,
        setcurrentFolder,
        currentImage,
        setcurrentImage,
        selectedImages,
        setselectedImages,
        onAddFolder,
        editImage,
        handleImageUpload,
        deleteImage,
        openImageModal,
        handleSubmit,
        imageLoading,
        imageError,
        search,
        setsearch,
        getUrl,
        getImages,
        instagramID,
        setInstagramID,
        submitInstagramID,
        fetchInstagramData,
        isFetching,
        openLinkGenerator: () => setlinkGeneratorOpen(true),
      }}
    >
      <ImageModal
        open={modal.open}
        onClose={onClose}
        allowDelete={modal.options.delete}
      />
      <LinkGenerator
        open={linkGeneratorOpen}
        onClose={() => setlinkGeneratorOpen(false)}
      />
      {children}
    </ImageContext.Provider>
  );
};

export default ImageContextProvider;
