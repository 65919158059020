import React from 'react';
import Card from 'Components/Molecules/Card';
import { Forecast } from 'utils/types';
import './styles.scss';
import Today from './Components/Today';
import Box from 'Components/Atoms/Box';
import Day from './Components/Day';
import Typography from 'Components/Atoms/Typography';
import useAuth from 'CustomHooks/useAuth';

export type TotalReservationsCardProps = {
  forecast: null | Forecast;
  totalReservations?: number;
  handleAction: (
    date: string,
    target: 'close' | 'terrace' | 'optimize',
    open?: boolean
  ) => void;
};

const TotalReservationsCard = ({
  forecast = null,
  totalReservations,
  handleAction,
}: TotalReservationsCardProps) => {
  const [today = null, ...days] = forecast?.days || [];

  const hasAddOns = React.useMemo(
    () => days?.some((d) => d.addOnStats),
    [forecast]
  );

  const showOptimization =
    days.some((d) => d.scoreDiff && d.scoreDiff > 1 && d.scoreDiff < 3) ||
    !!(today?.scoreDiff && today.scoreDiff > 1 && today.scoreDiff < 3);

  return (
    <Card
      title="Total Reservations"
      titleTranslation="dashboard"
      className="mg-bt-md"
    >
      {!!today ? (
        <>
          <Today
            today={today}
            totalReservations={totalReservations}
            handleAction={handleAction}
            updatedAt={forecast?.updatedAt}
            showOptimization={showOptimization}
          />
          <Box
            flex
            className="space-between total-res"
            style={{ paddingTop: 0 }}
            padding="sm"
          >
            {days.map((day) =>
              day ? (
                <Day
                  key={day.date}
                  {...day}
                  handleAction={handleAction}
                  hasAddOns={hasAddOns}
                  showOptimization={showOptimization}
                />
              ) : (
                <></>
              )
            )}
          </Box>
        </>
      ) : (
        <Box style={{ height: 270 }}>
          <Typography variant="text-3" color="subdued">
            Currently, there is no data to show
          </Typography>
        </Box>
      )}
    </Card>
  );
};

export default TotalReservationsCard;
