import auth from "./auth.json";
import common from "./common.json";
import dashboard from "./dashboard.json";
import errors from "./errors.json";
import products from "./products.json";
import takeAway from "./takeAway.json";
import allergens from "./allergens.json";
import meals from "./meals.json";
import feedback from "./feedback.json";
import reservations from "./reservations.json";
import settings from "./settings.json";
import vouchers from "./vouchers.json";
import healthcheck from "./healthcheck.json";
import website from "./website.json";
import onboarding from "./onboarding.json";
import calendar from "./calendar.json";
import tickets from "./tickets.json";
import experiences from "./experiences.json";


const languages = {
  auth,
  common,
  dashboard,
  errors,
  products,
  takeAway,
  allergens,
  meals,
  feedback,
  reservations,
  settings,
  vouchers,
  healthcheck,
  website,
  onboarding,
  calendar,
  tickets,
  experiences,
};

export default languages;
