import React, { useEffect, useState } from "react";
import "./styles.scss";
import IconButton from "../../../../Components/Atoms/IconButton";
import { Colorize } from "Components/Atoms/Icons";
//@ts-ignore
import { ChromePicker, Circle } from "react-color";
import OutsideAlerter from "../../../../CustomHooks/useOutsideListener";

interface Position {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}
export type ShiftColorProps = {
  value: string;
  options?: string[];
  onChange: (nV: string) => void;
  verticalPosition?: Partial<Position>;
  horizontalPosition?: Partial<Position>;
};

const ShiftColor: React.FC<ShiftColorProps> = ({
  value,
  options = ["#10CCB9", "#6369D1", "#EE4266", "#1E90FF", "#CCC910"],
  onChange,
  horizontalPosition,
  verticalPosition,
}) => {
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const [showPopover, setShowPopover] = useState<boolean>(false);

  function togglePopover() {
    setShowPopover(!showPopover);
  }

  function togglePicker() {
    setShowPicker(!showPicker);
  }

  function outsidePopoverListener() {
    if (showPicker) {
      setShowPicker(false);
    }
    setShowPopover(false);
  }

  return (
    <OutsideAlerter stateHandler={outsidePopoverListener}>
      <div className="shift-color-container">
        <div
          className="current-color"
          style={{ background: value }}
          onClick={togglePopover}
        ></div>

        {showPopover && (
          <div className="option-colors-container">
            {options.map((opt) => (
              <div
                key={opt}
                className={opt === value ? "color active" : "color"}
                style={{ background: opt }}
                onClick={() => {
                  onChange(opt);
                  setShowPopover(false);
                }}
              ></div>
            ))}
            <OutsideAlerter
              stateHandler={() => {
                setShowPicker(false);
              }}
            >
              <div style={{ position: "relative" }}>
                <IconButton color="textPrimary" size="small">
                  <Colorize onClick={togglePicker} />
                </IconButton>
                {showPicker && (
                  <div
                    style={{
                      position: "absolute",
                      top: `${verticalPosition?.top || "40px"}`,
                      bottom: `${verticalPosition?.bottom || "0px"}`,
                      left: `${horizontalPosition?.left || "0px"}`,
                      right: `${horizontalPosition?.right || "0px"}`,
                    }}
                  >
                    <ChromePicker
                      color={value}
                      onChange={(color: any) => {
                        // console.log("blur", { color });
                        onChange(color.hex);
                      }}
                      onChangeComplete={(color: any) => onChange(color.hex)}
                    />
                  </div>
                )}
              </div>
            </OutsideAlerter>
          </div>
        )}
      </div>
    </OutsideAlerter>
  );
};

export default ShiftColor;
