import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useToast from 'CustomHooks/useToast';
import server from 'utils/server';
import {
  monthHandler,
  monthNames,
} from 'App/GuestBook/Screens/Statistics/GuestStatistics';
import { RestaurantContext, Severity } from './RestaurantContext';
import dayjs from 'dayjs';
import { ReservationStatistics } from 'App/GuestBook/Screens/Statistics/types';
import { dateHelper } from 'utils/helper';

type DropdownOption = {
  id: string;
  label: string;
};

interface ContextProps {
  restaurantId: null | string;
  children?: React.ReactNode;
  storyBook?: boolean;
}

type GuestStatisticsContextType = {
  loading?: boolean;
  setloading?: Dispatch<SetStateAction<boolean>>;
  statisticsData: ReservationStatistics | null;
  timeSpan: string | { from: string; till: string };
  setTimeSpan: Dispatch<
    SetStateAction<string | { from: string; till: string }>
  >;
  availableMonths: DropdownOption[];
  availableMonthsHandler: (id: string) => DropdownOption;
  availableOccasions: DropdownOption[];
  pickedOccasion: DropdownOption;
  setPickedOccasion: Dispatch<SetStateAction<DropdownOption>>;
  availableSources: DropdownOption[];
  pickedSource: DropdownOption;
  setPickedSource: Dispatch<SetStateAction<DropdownOption>>;
};

export const GuestStatisticsContext = createContext<GuestStatisticsContextType>(
  {
    statisticsData: null,
    timeSpan: dateHelper().slice(0, 7),
    setTimeSpan: () => {},
    availableMonthsHandler: () => ({ label: 'This month', id: '2022-11' }),
    availableMonths: [],
    availableOccasions: [],
    pickedOccasion: {
      label: 'Alle Anlässe',
      id: 'all-occasions',
    },
    setPickedOccasion: () => {},
    availableSources: [],
    pickedSource: {
      label: 'Quellen',
      id: 'sources',
    },
    setPickedSource: () => {},
  }
);

const GuestStatisticsContextProvider = ({
  restaurantId,
  children,
  storyBook,
}: ContextProps) => {
  const { reservationSettings } = useContext(RestaurantContext);
  const toast = useToast();
  const [statisticsData, setStatisticsData] = useState<ReservationStatistics>();
  const [loading, setloading] = useState<boolean>(false);
  const [timeSpan, setTimeSpan] = useState<
    string | { from: string; till: string }
  >(dateHelper().slice(0, 7));

  const [pickedOccasion, setPickedOccasion] = useState<DropdownOption>({
    label: 'Alle Anlässe',
    id: 'all-occasions',
  });

  const [pickedSource, setPickedSource] = useState<DropdownOption>({
    label: 'Quellen',
    id: 'sources',
  });

  function getPrevious12Months(): DropdownOption[] {
    const previous12Months = [
      0, -1, -2, -3, -4, -5, -6, -7, -8, -9, -10, -11,
    ].map((x) => {
      if (dayjs().add(x, 'month').month() + 1 < 10) {
        return {
          id: `${dayjs().add(x, 'month').year()}-0${
            dayjs().add(x, 'month').month() + 1
          }`,
          label: `${monthNames[dayjs().add(x, 'month').month()]}, ${dayjs()
            .add(x, 'month')
            .year()}`,
        };
      }
      return {
        id: `${dayjs().add(x, 'month').year()}-${
          dayjs().add(x, 'month').month() + 1
        }`,
        label: `${monthNames[dayjs().add(x, 'month').month()]}, ${dayjs()
          .add(x, 'month')
          .year()}`,
      };
    });
    return previous12Months;
  }

  const availableMonths = useMemo(() => getPrevious12Months(), []);

  const availableOccasions: DropdownOption[] = useMemo(() => {
    if (reservationSettings)
      return [
        { id: 'all-occasions', label: 'Alle Anlässe' },
        ...reservationSettings.occassions.map((o) => ({
          id: o.id,
          label: o.title,
        })),
      ] as DropdownOption[];
    else
      return [
        {
          label: 'Alle Anlässe',
          id: 'all-occasions',
        },
      ];
  }, [reservationSettings]);

  const availableSources: DropdownOption[] = [
    {
      id: 'phone',
      label: 'Phone',
    },
    {
      id: 'website',
      label: 'Website',
    },
    {
      id: 'inHouse',
      label: 'InHouse',
    },
  ];

  function availableMonthsHandler(id: string) {
    return availableMonths.filter((m) => m.id === id)[0];
  }

  async function getStatisticsHandler() {
    try {
      if (loading) return;
      setloading(true);
      let queryParams = {};
      if (pickedOccasion.id !== 'all-occasions') {
        queryParams = {
          occassion: pickedOccasion.id,
        };
      }

      if (typeof timeSpan === 'string') {
        const res = await server.get(
          `/v03/guests/${restaurantId}/reservationStatistics/${timeSpan}`,
          {
            params: { ...queryParams },
          }
        );
        setStatisticsData(res.data);
      } else {
        const res = await server.get(
          `/v03/guests/${restaurantId}/reservationStatistics/${timeSpan.from}/${timeSpan.till}`,
          {
            params: { ...queryParams },
          }
        );
        setStatisticsData(res.data);
      }

      setloading(false);
    } catch (e) {
      console.error('Error while getting statistics: ', e);
      setloading(false);
      // toast('Failed to load data.', Severity.ERROR, 'errors');
    }
  }

  useEffect(() => {
    getStatisticsHandler();
  }, [restaurantId, timeSpan, pickedOccasion]);

  useEffect(() => {
    setPickedOccasion({ id: 'all-occasions', label: 'Alle Anlässe' });
  }, [restaurantId]);

  return (
    <GuestStatisticsContext.Provider
      value={{
        loading,
        statisticsData: statisticsData as ReservationStatistics,
        timeSpan,
        setTimeSpan,
        availableMonths,
        availableMonthsHandler,
        availableOccasions,
        pickedOccasion,
        setPickedOccasion,
        availableSources,
        pickedSource,
        setPickedSource,
      }}
    >
      {children}
    </GuestStatisticsContext.Provider>
  );
};

export default GuestStatisticsContextProvider;
