import { dateHelper, timeHelper } from 'utils/helper';
import server from '../index';

export default async (restaurantId:string, body:object) => {
    try {

        const response = await server.post(`/v02/menues/takeAway/${restaurantId}/createPayment`, { ...body, version: 'v02' });

        const data:{id:string,success:boolean} = response.data;
        
        return data;

    } catch (error:any) {
        if(error.isAxiosError) {
            throw new Error(error?.response?.data?.message || error.message);
        } else {
            throw error
        }
    }
}