import React, { useState } from 'react';
import { Event } from 'types/calendar';
import Box from 'Components/Atoms/Box';
import Typography from 'Components/Atoms/Typography';
import SwitchBox from 'Components/Atoms/Switch';
import SeeMore from 'Components/Molecules/SeeMore';
import Translations from 'App/Settings/Components/Translations';
import TextField from 'Components/Atoms/TextField';
import ImageField from 'Components/Organisms/ImageField';
import Select from 'Components/Atoms/Select';
import produce from 'immer';
import { capitalize } from 'lodash';

interface EventEmailSettingsProps {
  eventState: Event | null;
  setEventState: React.Dispatch<React.SetStateAction<Event | null>>;
}

const EventEmailSettings = ({
  eventState,
  setEventState,
}: EventEmailSettingsProps) => {
  const [seeMore, setSeeMore] = useState<string[]>([]);

  function uploadConfirmationEmailImageHandler(
    val: string | (string | null)[] | null
  ) {
    if (!eventState || !eventState.confirmation_email?.translations) return;

    setEventState({
      ...eventState,
      confirmation_email: {
        ...eventState?.confirmation_email,
        image: typeof val === 'string' ? val : '',
      },
    });
  }

  function uploadReminderEmailImageHandler(
    val: string | (string | null)[] | null
  ) {
    if (!eventState || !eventState.reminder_email?.translations) return;

    setEventState({
      ...eventState,
      reminder_email: {
        ...eventState?.reminder_email,
        image: typeof val === 'string' ? val : '',
      },
    });
  }

  function confirmationButtonURLHandler(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    if (!eventState || !eventState.confirmation_email?.translations) return;

    setEventState({
      ...eventState,
      confirmation_email: {
        ...eventState?.confirmation_email,
        button: e.target.value,
      },
    });
  }

  function reminderButtonURLHandler(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    if (!eventState || !eventState.reminder_email?.translations) return;

    setEventState({
      ...eventState,
      reminder_email: {
        ...eventState?.reminder_email,
        button: e.target.value,
      },
    });
  }

  function updateSendAt(e: any) {
    const { name, value } = e.target;

    setEventState(
      (prev) => {
        if (!prev) return prev;

        console.log({
          prev,
          name,
          value,
        });

        let reminder_email = prev.reminder_email;

        if (!reminder_email) {
          reminder_email = {
            active: true,
            sendBefore: {
              unit: 'hours',
              value: 3,
            },
            translations: {
              de: {
                title: '',
                description: '',
                button: '',
              },
              en: {
                title: '',
                description: '',
                button: '',
              },
            },
          };
        } else if (!reminder_email.sendBefore) {
          reminder_email.sendBefore = {
            unit: 'hours',
            value: 3,
          };
        }

        if (name === 'value') {
          reminder_email.sendBefore.value = Number(value);
        } else {
          reminder_email.sendBefore.unit =
            value?.toLocaleLowerCase() ?? 'hours';
        }

        return {
          ...prev,
          reminder_email,
        };
      }
      //   produce((draft) => {
      //     if (!draft) return;

      //     if (!draft.reminder_email) {
      //       draft.reminder_email = {
      //         active: true,
      //         sendBefore: {
      //           unit: 'hours',
      //           value: 3,
      //         },
      //         translations: {
      //           de: {
      //             title: '',
      //             description: '',
      //             button: '',
      //           },
      //           en: {
      //             title: '',
      //             description: '',
      //             button: '',
      //           },
      //         },
      //       };
      //     }

      //     console.log({
      //       draft,
      //       name,
      //       value,
      //     });

      //     if (name === 'value') {
      //       draft.reminder_email.sendBefore.value = Number(value);
      //     } else {
      //       draft.reminder_email.sendBefore.unit =
      //         value?.toLocaleLowerCase() ?? 'hours';
      //     }

      //     return draft;
      //   })
    );
  }

  if (!eventState) {
    return <></>;
  }

  return (
    <Box className="event-email-settings-container ">
      {/* Confirmation email */}
      <Box className="confirmation-email-container">
        <Box flex className="mg-bt-lg">
          <Box>
            <Typography variant="h5" translation="calendar">
              Send confirmation email
            </Typography>
            <Typography color="subdued" variant="text-3" translation="calendar">
              Email will trigger to confirm reservation
            </Typography>
          </Box>
          <SwitchBox
            className="event-switch-width"
            checked={eventState && !!eventState.confirmation_email?.active}
            onChange={() => {
              return setEventState({
                ...eventState,
                confirmation_email: eventState.confirmation_email
                  ? {
                      ...eventState.confirmation_email,
                      active: !eventState.confirmation_email?.active,
                    }
                  : null,
              });
            }}
            label=""
          />
        </Box>
        {!!eventState?.confirmation_email?.active && (
          <>
            <Box flex className="space-between flex-start">
              <Translations
                borders
                addLanguage={false}
                state={{
                  de: {
                    title:
                      eventState?.confirmation_email?.translations?.de?.title ||
                      '',
                    description:
                      eventState?.confirmation_email?.translations?.de
                        ?.description || '',
                    button:
                      eventState?.confirmation_email?.translations?.de
                        ?.button || '',
                  },
                  en: {
                    title:
                      eventState?.confirmation_email?.translations?.en?.title ||
                      '',
                    description:
                      eventState?.confirmation_email?.translations?.en
                        ?.description || '',
                    button:
                      eventState?.confirmation_email?.translations?.de
                        ?.button || '',
                  },
                }}
                onChange={(nS) => {
                  if (!eventState) return;
                  setEventState({
                    ...eventState,
                    confirmation_email: {
                      ...eventState?.confirmation_email,
                      active: eventState?.confirmation_email?.active ?? false,
                      translations: nS as Record<
                        string,
                        { title: string; description: string; button: string }
                      >,
                    },
                  });
                }}
                keys={[
                  {
                    id: 'title',
                    label: 'Title',
                    required: true,
                  },
                  {
                    id: 'description',
                    label: 'Description',
                    required: true,
                    multiline: true,
                  },
                  {
                    id: 'button',
                    label: 'Button text',
                    required: true,
                  },
                ]}
              />
              <ImageField
                type="url"
                value={eventState?.confirmation_email?.image || ''}
                onChange={uploadConfirmationEmailImageHandler}
                label="Hero Image"
                labelTranslation="calendar"
                style={{ marginTop: 0, marginLeft: 16, width: 'auto' }}
              />
            </Box>
            <Box style={{ marginTop: 12, marginBottom: 32 }}>
              <TextField
                fullWidth
                label="Button url"
                labelTranslation="calendar"
                value={eventState?.confirmation_email?.button}
                onChange={(e) => confirmationButtonURLHandler(e)}
                style={{ marginTop: 12 }}
              />
            </Box>
          </>
        )}
      </Box>

      {/* Reminder email */}
      <Box className="reminder-email-container">
        <Box flex className="mg-bt-lg">
          <Box>
            <Typography variant="h5" translation="calendar">
              Send reminder email
            </Typography>
            <Typography color="subdued" variant="text-3" translation="calendar">
              Email will be sent as a reminder
            </Typography>
          </Box>
          <SwitchBox
            className="event-switch-width"
            checked={!!eventState?.reminder_email?.active}
            onChange={() => {
              return setEventState({
                ...eventState,
                reminder_email: eventState.reminder_email
                  ? {
                      ...eventState.reminder_email,
                      active: !eventState.reminder_email.active,
                    }
                  : null,
              });
            }}
            label=""
          />
        </Box>
        {!!eventState?.reminder_email?.active && (
          <>
            <Box flex className="space-between flex-start">
              <Translations
                borders
                state={{
                  de: {
                    title:
                      eventState?.reminder_email?.translations?.de?.title || '',
                    description:
                      eventState?.reminder_email?.translations?.de
                        ?.description || '',
                    button:
                      eventState?.reminder_email?.translations?.de?.button ||
                      '',
                  },
                  en: {
                    title:
                      eventState?.reminder_email?.translations?.en?.title || '',
                    description:
                      eventState?.reminder_email?.translations?.en
                        ?.description || '',
                    button:
                      eventState?.reminder_email?.translations?.de?.button ||
                      '',
                  },
                }}
                onChange={(nS) => {
                  if (!eventState) return;
                  setEventState({
                    ...eventState,
                    reminder_email: eventState.reminder_email
                      ? {
                          ...eventState?.reminder_email,
                          active: eventState?.reminder_email?.active ?? false,
                          translations: nS as Record<
                            string,
                            {
                              title: string;
                              description: string;
                              button: string;
                            }
                          >,
                        }
                      : null,
                  });
                }}
                keys={[
                  {
                    id: 'title',
                    label: 'Title',
                    required: true,
                  },
                  {
                    id: 'description',
                    label: 'Description',
                    required: true,
                    multiline: true,
                  },
                  {
                    id: 'button',
                    label: 'Button text',
                    required: true,
                  },
                ]}
              />
              <ImageField
                type="url"
                value={eventState?.reminder_email?.image || ''}
                onChange={uploadReminderEmailImageHandler}
                label="Hero Image"
                labelTranslation="calendar"
                style={{ marginTop: 0, marginLeft: 16, width: 'auto' }}
              />
            </Box>
            <Box style={{ marginTop: 12 }}>
              <TextField
                fullWidth
                label="Button url"
                labelTranslation="calendar"
                value={eventState?.reminder_email?.button}
                onChange={reminderButtonURLHandler}
                style={{ marginTop: 12 }}
              />
              <Box
                flex
                className="block-table mg-bt-lg"
                style={{ marginTop: 12 }}
              >
                <TextField
                  InputProps={{
                    disableUnderline: true,
                  }}
                  type="number"
                  fullWidth
                  label="Send reminder before"
                  labelTranslation="calendar"
                  name="value"
                  value={eventState?.reminder_email?.sendBefore?.value || 30}
                  onChange={updateSendAt}
                />
                <Select
                  className="conf-email-type-unit"
                  translation="common"
                  value={capitalize(
                    eventState?.reminder_email?.sendBefore?.unit || 'hours'
                  )}
                  options={['Minutes', 'Hours', 'Days']}
                  name="unit"
                  style={{ maxWidth: 'max-content' }}
                  onChange={updateSendAt}
                />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default EventEmailSettings;
