import React, { useState } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import Typography from 'Components/Atoms/Typography';
import { dateHelper } from 'utils/helper';
import IconButton from 'Components/Atoms/IconButton';
import { Edit } from 'Components/Atoms/Icons';
import Popover from 'Components/Atoms/Popover';
import DateRangeCalendar from './Components/DateRangeCalendar';
import useDateString from 'CustomHooks/useDateString';
import { Translations } from 'utils/types';

type State = {
  startAt?: string; // if undefined === dateHelper()
  endAt?: string | null;
  regular?: boolean;
  dates?: string[];
};

export type DateRangeProps = {
  label?: string;
  labelTranslation?: Translations;
  state: State;
  style?: React.CSSProperties;
  onChange: React.Dispatch<React.SetStateAction<State>>;
  onlyOneDay?: boolean;
  hideEndDatePhrase?: boolean;
  disabled?: boolean;
  date?: string;
};

const DateRange: React.FC<DateRangeProps> = ({
  labelTranslation,
  state,
  style,
  onlyOneDay = false,
  date,
  label = onlyOneDay ? 'Date' : 'Date Range',
  hideEndDatePhrase = false,
  disabled = false,
  onChange,
}: DateRangeProps) => {
  const [open, setopen] = useState<null | HTMLElement | EventTarget>(null);

  const handleOpen = (e: React.SyntheticEvent) => {
    if (disabled) return;
    setopen(e.currentTarget.parentElement);
  };

  const handleClose = () => setopen(null);

  const startDateString = useDateString(state?.startAt || date || dateHelper());
  const endDateString =
    useDateString(state?.endAt || '') || (!hideEndDatePhrase && 'No End Date');

  return (
    <Box className="date-range-container" style={style}>
      <Typography
        block
        variant="text-4"
        color="subdued"
        translation={labelTranslation}
      >
        {label}
      </Typography>
      <Box className="space-between date-range-input">
        <Box>
          <Typography
            color={disabled ? 'subdued' : 'textPrimary'}
            variant="text-3"
          >
            {startDateString}
          </Typography>
          {!onlyOneDay && (
            <>
              {!hideEndDatePhrase && (
                <Typography variant="text-3"> - </Typography>
              )}
              {endDateString && hideEndDatePhrase && (
                <Typography variant="text-3"> - </Typography>
              )}
              <Typography variant="text-3">{endDateString}</Typography>
            </>
          )}
        </Box>
        {!disabled && (
          <IconButton size="small" onClick={handleOpen}>
            <Edit className="edit-btn" />
          </IconButton>
        )}
      </Box>
      <Popover
        anchorEl={open}
        open={!!open}
        onClose={handleClose}
        placement="bottom"
        width="auto"
      >
        <DateRangeCalendar
          {...{ state, onChange, onlyOneDay, hideEndDatePhrase, date }}
        />
      </Popover>
    </Box>
  );
};

export default DateRange;
