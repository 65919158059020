import React from "react";
import {
  default as MaterialInputAdornment,
  InputAdornmentProps as MaterialInputAdornmentProps,
} from "@material-ui/core/InputAdornment";
import "./styles.scss";

export type InputAdornmentProps = MaterialInputAdornmentProps;

const InputAdornmentAtom: React.FC<InputAdornmentProps> = ({ ...props }) => {
  return (
    <>
      <MaterialInputAdornment {...props} />
    </>
  );
};

export default InputAdornmentAtom;
