import { loadMealData } from "api";
import useUnavailable from "CustomHooks/useUnavailable";
import useOrders from "CustomHooks/useOrders";
import React, { createContext, useEffect, useState } from "react";
import {
  TakeAwayOrder,
  TakeAwayOrderSnippet,
  TakeAwayAnalytics,
  EmergencyStopState,
  PrinterState,
  OrderStatus,
} from "types/takeAway";
import { Category, Meal } from "types/menu";
import { dateHelper, noop } from "utils/helper";
import useWaitingTimes from "CustomHooks/useWaitingTimes";
import useEmergencyStop from "CustomHooks/useEmergencyStop";
import usePrinter from "CustomHooks/usePrinter";
import useAlarm from "CustomHooks/useAlarm";

interface Props {
  restaurantId: null | string;
  setrestaurantId: Function;
  children: any;
}

type Context = {
  setdate: React.Dispatch<React.SetStateAction<string>>;
  waitingTimes: {
    delivery: string | null;
    pickup: string | null;
  };
  handleWaitingTimesChange: (
    key: "takeAway" | "pickup",
    newValue: string
  ) => void;
  orders: TakeAwayOrder[];
  printer: null | PrinterState;
  emergencyStop: null | EmergencyStopState;
  handleOrderAction: (id: string, action: string, payload?: any) => void;
  date: string;
  unavailable: string[];
  setunavailable: React.Dispatch<React.SetStateAction<string[]>>;
  categories: Category[];
  meals: Meal[];
  analytics: TakeAwayAnalytics;
};

const EmptyState = {
  setdate: noop,
  orders: [],
  printer: null,
  emergencyStop: null,
  handleWaitingTimesChange: noop,
  handleOrderAction: noop,
  waitingTimes: {
    delivery: null,
    pickup: null,
  },
  date: dateHelper(),
  meals: [],
  categories: [],
  unavailable: [],
  setunavailable: noop,
  analytics: { dates: {}, lastOrders: [], loading: true },
};

export const TakeAwayContext = createContext<Context>(EmptyState);

/*
    Everything needed for Take Away
*/

const TakeAwayContextProvider = (props: Props) => {
  const [date, setdate] = useState<string>(dateHelper());

  const { orders, handleOrderAction, analytics } = useOrders(
    props.restaurantId,
    date
  );

  const [playAlarmSound, stopAlarmSound] = useAlarm(
    "/alarm.mp3",
    props.restaurantId
  );

  useEffect(() => {
    stopAlarmSound("all");

    orders.forEach((order) => {
      console.warn("order", order);
      if (order.status === OrderStatus.PENDING && !order.canceled) {
        playAlarmSound(order.id);
      }
    });
  }, [orders]);

  const [meals, setmeals] = useState<Meal[]>([]);
  const [categories, setcategories] = useState<Category[]>([]);

  const [unavailable, setunavailable] = useUnavailable(
    props.restaurantId,
    date
  );

  const { waitingTimes, handleWaitingTimesChange } = useWaitingTimes(
    props.restaurantId
  );

  const emergencyStop = useEmergencyStop(props.restaurantId);
  const printer = usePrinter(props.restaurantId);

  useEffect(() => {
    if (props.restaurantId) {
      loadMealData(props.restaurantId, true)
        .then(
          ({
            meals,
            categories,
          }: {
            meals: Meal[];
            categories: Category[];
          }) => {
            setmeals(meals);
            setcategories(categories);
          }
        )
        .catch(() => {
          //@TODO Handle Error
          setmeals([]);
          setcategories([]);
        });
    }
  }, [props]);

  return (
    <TakeAwayContext.Provider
      value={{
        unavailable,
        setunavailable,
        setdate,
        orders,
        printer,
        emergencyStop,
        handleWaitingTimesChange,
        handleOrderAction,
        waitingTimes,
        date,
        categories,
        meals,
        analytics,
      }}
    >
      {props.children}
    </TakeAwayContext.Provider>
  );
};

export default TakeAwayContextProvider;
