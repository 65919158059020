import React, { useMemo, useState } from "react";
import Box from "Components/Atoms/Box";
import dayjs from "dayjs";
import "./styles.scss";
import { Date as DateType, OffersShift } from "types/shifts";
import { ShiftActionType } from "Contexts/CalendarContext";
import Sidebar from "Components/Molecules/Sidebar";
import ActionButtons from "./Components/ActionButtons";
import DetailsTab from "./Components/DetailsTab";

export type CurrentOfferShiftCardProps = {
  restaurantId: string;
  date: DateType<OffersShift>;
  shiftId: string;
  onShiftAction: (
    id: string,
    date: string,
    type: ShiftActionType,
    payload?: any
  ) => void;
  onClose: () => void;
};

const CurrentOfferShiftCard: React.FC<CurrentOfferShiftCardProps> = ({
  restaurantId,
  date,
  shiftId,
  onClose,
  onShiftAction,
}) => {
  const [currentTab, setCurrentTab] = useState<string>("0");
  const currentShift: OffersShift = useMemo(
    () => date.shifts.find((shift) => shift.id === shiftId) as OffersShift,
    [date, shiftId]
  );

  return (
    <Box className="current-offer-shift-card-container">
      <Sidebar
        id={shiftId}
        titleTranslation={null}
        title={
          currentShift?.name + " - " + dayjs(date.date).format("DD.MM.YYYY")
        }
        actions={<ActionButtons {...{ currentShift, date, onShiftAction }} />}
        onClose={onClose}
        tabs={[
          {
            id: "0",
            label: "Details",
            translation: "settings",
          },
          {
            id: "1",
            label: "Items / Advert",
            translation: "calendar",
          },
        ]}
        onTabChange={(id) => setCurrentTab(id as string)}
        currentTab={currentTab}
        deductFromHeight={12}
      >
        {currentTab === "0" && <DetailsTab {...{ currentShift, date }} />}
        {currentTab === "1" && <Box>Upcoming</Box>}
      </Sidebar>
    </Box>
  );
};

export default CurrentOfferShiftCard;
