import React, { RefObject, useEffect, useRef } from "react";

interface OutsideAlerterProps {
  children: React.ReactNode;
  stateHandler: VoidFunction;
}
/** Hook that alerts clicks outside of the passed ref */
function useOutsideListener(
  ref: RefObject<HTMLDivElement>,
  stateHandler: VoidFunction
) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        stateHandler();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

/** Component that alerts if you click outside of it */
export default function OutsideAlerter({
  children,
  stateHandler,
}: OutsideAlerterProps) {
  const wrapperRef = useRef(null);
  useOutsideListener(wrapperRef, stateHandler);

  return <div ref={wrapperRef}>{children}</div>;
}
