import server from '../index';

export default async (restaurantId:string, address:string) => {
    try {
        const response = await server.get(`/v02/menues/takeAway/${restaurantId}/calculateDeliveryCosts/${address}`);

        const data:{ price:number, message:null|string, error:boolean, minOrderValue:{ delivery:number } } = response.data;

        if(data.error) {
            throw new Error(data.message || '');
        }

        return data;

    } catch (error:any) {
        if(error.isAxiosError) {
            throw new Error(error?.response?.data?.message || error.message);
        } else {
            throw error
        }
    }
}