import React, { useContext, useState } from 'react';
import '../styles.scss';
import Box from 'Components/Atoms/Box';
import { Lock, PersonAdd, Send } from 'Components/Atoms/Icons';
import IconButton from 'Components/Atoms/IconButton';
import MenuItem from 'Components/Atoms/MenuItem';
import { InputBase } from '@material-ui/core';
import Typography from 'Components/Atoms/Typography';
import useTranslations from 'CustomHooks/useTranslations';
import { Signature, SignatureContext } from 'Contexts/SignatureContext';
import Button from 'Components/Atoms/Button';
import NewSignatureModal from 'Components/Organisms/NewSignatureModal';

export type SignatureDropdownOptionsProps = {
  value?: string | null;
  onChange: (newValue: string) => void;
  closeOptions: () => void;
  setAddNewSignatureModal: (val: boolean) => void;
  style?: React.CSSProperties;
  global?: boolean;
};

const SignatureDropdownOptions: React.FC<SignatureDropdownOptionsProps> = ({
  value = null,
  onChange,
  closeOptions,
  setAddNewSignatureModal,
  style,
  global = false,
}) => {
  const { signatures, setPincodeModalData } = useContext(SignatureContext);

  console.log({ signatures });

  const { title = '' } = useTranslations({
    en: {
      title: 'Add Signature',
    },
    de: {
      title: 'Add Signature',
    },
    fr: {
      title: 'Ajouter une signature',
    },
  });

  function lockedSignatureClicked(s: Signature) {
    closeOptions();
    setPincodeModalData({
      type: 'validate',
      open: true,
      signatureModalData: s,
      onValidCode: () => onChange(s.signature),
    });
  }

  return (
    <Box
      className="column"
      style={{ ...style, maxHeight: '80vh', overflowY: 'scroll' }}
    >
      {!!global && (
        <MenuItem
          value=""
          onClick={() => {
            onChange('');
            closeOptions();
          }}
          className="flex space-between"
          translation={null}
          selected={!value}
        >
          <Box className="signature-item-container">Kein Kürzel</Box>
        </MenuItem>
      )}
      {signatures.length ? (
        signatures.map((s) => (
          <MenuItem
            key={s.signature}
            value={s.signature}
            onClick={() => {
              !s.code && onChange(s.signature);
              closeOptions();
            }}
            className="flex space-between"
            translation={null}
            selected={s.signature === value}
          >
            <Box
              className="signature-item-container"
              onClick={() =>
                s.code ? lockedSignatureClicked(s) : console.log('no pin')
              }
            >
              {s.signature}
              {signatures.length > 5 && ' - ' + s.name.slice(0, 14)}
              {s.code && <Lock color="inherit" />}
            </Box>
          </MenuItem>
        ))
      ) : (
        <Box
          flex
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            margin: '24px 8px 16px',
            textAlign: 'center',
          }}
        >
          <Typography variant="text-4" color="subdued" translation="common">
            No Signatures available.
          </Typography>

          <Typography variant="text-4" color="subdued" translation="common">
            Please create a new one.
          </Typography>
        </Box>
      )}
      <Box flex className="add-signature-input">
        <Button
          variant="transparent"
          className="add-new-signature-button"
          translation="settings"
          onClick={() => {
            setAddNewSignatureModal(true);
            closeOptions();
          }}
        >
          {title}
        </Button>
      </Box>
    </Box>
  );
};

export default SignatureDropdownOptions;
