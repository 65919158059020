import Box from "Components/Atoms/Box";
import Typography from "Components/Atoms/Typography";
import React from "react";
import { Translations } from "utils/types";
import EmptyStateIcon from "./EmptyStateIcon";

interface EmptyStateProps {
  description?: string;
  translation?: Translations;
}
const EmptyState = ({
  description = "No Data to show",
  translation,
}: EmptyStateProps) => {
  return (
    <Box flex className="flex-column">
      <EmptyStateIcon className="mg-bt-md" style={{ width: 200, height: 72 }} />
      <EmptyStateIcon className="mg-bt-md" style={{ width: 200, height: 72 }} />
      <Typography
        variant="text-3"
        textAlign="center"
        component="p"
        translation={translation || "common"}
      >
        {description}
      </Typography>
    </Box>
  );
};

export default EmptyState;
