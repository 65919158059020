import React from "react";

const OtherAttachmentIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M104 126H24C18.5 126 14 121.5 14 116V12C14 6.5 18.5 2 24 2H64.7C67.4 2 69.9 3 71.8 4.9L111.1 44.2C113 46.1 114 48.6 114 51.3V116C114 121.5 109.5 126 104 126ZM24 6C20.7 6 18 8.7 18 12V116C18 119.3 20.7 122 24 122H104C107.3 122 110 119.3 110 116V51.3C110 49.7 109.4 48.2 108.2 47.1L68.9 7.8C67.8 6.6 66.3 6 64.7 6H24Z"
        fill="#10CCB9"
      />
    </svg>
  );
};

export default OtherAttachmentIcon;
