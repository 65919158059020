import { AxiosResponse } from 'axios';
import {
  checkActiveShift,
  ShiftActionType,
  View,
} from 'Contexts/CalendarContext';
import { RestaurantContext, Severity } from 'Contexts/RestaurantContext';
import React, { useState, useEffect, useContext, useMemo } from 'react';
import {
  ShiftType,
  Date as DateType,
  ReservationShift,
  GuestOrderingShift,
  OffersShift,
  TakeAwayShift,
  Shift,
  AnyShift,
} from 'types/shifts';
import { db } from 'utils/firebase';
import { dateHelper, getCalendarWeek, timeToBlock } from 'utils/helper';
import server from 'utils/server';
import { getCalendarWeekArray } from './useCalendar';
import {
  NewGuestOrderingShift,
  NewHappyHoursShift,
  NewReservationShift,
} from 'utils/ShiftClasses';
import useAuth from './useAuth';
import useDateString from './useDateString';
import useLanguage from './useLanguage';
import TEMPLATES from 'utils/calendarTemplates';
import { CalendarShift } from 'App/Calendar/Components/Week';
import useRecentShifts from './useRecentShifts';
import { triggerCustomEvent } from './useCustomEvent';

function getDateArray(dateStr: string, n: number) {
  let dateArray: string[] = [];

  for (let i = 0; i < n; i++) {
    let date = new Date(dateStr);
    date.setDate(date.getDate() + i);
    dateArray.push(date.toISOString().split('T')[0]);
  }
  return dateArray;
}

type ShiftActionBody = {
  date: string;
  allFutureDates?: boolean;
  type?: ShiftActionType;
  loadDates?: boolean;
  data?: AnyShift;
  disableOnly?: boolean;
};

const COLORS = ['#10CCB9', '#6369D1', '#EE4266', '#1E90FF', '#CCC910'];

export type ActiveShift<T extends Shift = Shift> = {
  shift: Partial<T>;
  date: string;
  type: 'new' | 'edit' | 'duplication' | 'close';
  allUpcomingShifts?: boolean;
  eventOnly?: boolean;
} | null;

const useCalendarDates = <TShift extends Shift = AnyShift>(
  restaurantId: string,
  type: ShiftType,
  date: string,
  view: View,
  allOccassions: { id: string; title: string }[] = [],
  setDate: (date: string) => void
) => {
  const [currentMultipleShifts, setCurrentMultipleShifts] = useState<
    CalendarShift[] | null
  >(null);

  const { newToast, alert, products } = useContext(RestaurantContext);
  const { uid } = useAuth();
  const { language } = useLanguage();

  const [selectedMultiCard, setSelectedMultiCard] = useState<string | null>(
    null
  );

  const lightReservations = products.includes('light-reservation');

  const [dates, setdates] = useState<DateType<TShift>[]>([]);
  const [calendarWeek, setcalendarWeek] = useState<number | null>(null);
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState<string | null>(null);

  const [offset, setoffset] = useState(0);

  const [currentShift, setcurrentShift] = useState<null | {
    date: string;
    shiftId: string;
  }>(null);

  const [activeShift, setactiveShift] = useState<ActiveShift<TShift>>(null);

  // used when user asked whether he wants to edit only current shift
  // or all upcoming shifts with same id

  const loadWeek = (
    newCalendarWeek: number = calendarWeek || getCalendarWeek(new Date(date))
  ) => {
    console.log('load week');
    setloading(true);
    setcalendarWeek(newCalendarWeek);

    let serverCall: Promise<
      AxiosResponse<{ dates: DateType<TShift>[]; offset?: number }>
    >;

    let weekId =
      newCalendarWeek >= 0
        ? date.slice(0, 4) + '-' + newCalendarWeek
        : Number(date.slice(0, 4)) -
          1 +
          '-' +
          getCalendarWeek(new Date(`${Number(date.slice(0, 4)) - 1}-12-31`));

    if (view === View.WEEK || view === View.LIST) {
      serverCall = server.get<{ dates: DateType<TShift>[] }>(
        `/v03/shifts/${restaurantId}/weeks/${weekId}/${type}`
      );
    } else if (view === View.LIST) {
      serverCall = server.post<{ dates: DateType<TShift>[]; offset: number }>(
        `/v03/${restaurantId}/shifts/dates/${type}`,
        { date, view, offset }
      );
    } else {
      serverCall = server.post<{ dates: DateType<TShift>[] }>(
        `/v03/${restaurantId}/shifts/dates/${type}`,
        { date, view }
      );
    }

    serverCall
      .then(({ data }) => {
        const { dates, offset } = data;

        if (offset) {
          setdates((d) => [...d, ...dates]);
        } else {
          setdates(dates);
          if (
            currentShift &&
            !dates
              .find((d) => d.date === currentShift.date)
              ?.shifts?.find((s) => s.id === currentShift.shiftId)
          ) {
            setcurrentShift(null);
          }
        }

        seterror(null);
      })
      .catch((err) => {
        let dates: DateType<TShift>[] = getCalendarWeekArray(
          newCalendarWeek,
          parseInt(date.slice(0, 4))
        ).map((date) => ({
          date,
          shifts: [],
          wDay: new Date(date).getDay(),
          holiday: null,
        }));

        setdates(dates);
        seterror(err?.response?.data?.message || err.message);
      })
      .finally(() => {
        setloading(false);
      });
  };

  const [oldType, setOldType] = useState<ShiftType>(type);

  useEffect(() => {
    const newCalendarWeek = getCalendarWeek(new Date(date));

    if (newCalendarWeek !== calendarWeek || oldType !== type) {
      loadWeek(newCalendarWeek);
      setOldType(type);
    }
  }, [restaurantId, type, date, view]);

  const onShiftAction = (
    id: string,
    dateId: string,
    actionType: ShiftActionType,
    payload?: any
  ) => {
    const date = dates.find((d) => d.date === dateId);

    const shift = date?.shifts.find((s) => s.id === id) ?? payload?.shift;

    let shiftType = shift?.type ?? type;

    const subCollection =
      shiftType === ShiftType.RESERVATION
        ? 'reservationDates'
        : shiftType === ShiftType.GUEST_ORDERING
        ? 'guestOrderingDates'
        : shiftType === ShiftType.HAPPY_HOURS
        ? 'happyHourDates'
        : shiftType === ShiftType.TAKEAWAY
        ? 'takeAwayDates'
        : '';

    // console.log({ actionType, shift, subCollection, shiftType });

    if (!subCollection) return;

    const ref = db
      .collection(`restaurants/${restaurantId}/${subCollection}`)
      .doc(dateId);

    if (!shift && actionType !== ShiftActionType.BRING_BACK_FROM_DELETED) {
      newToast('An Error occured', Severity.ERROR, 'errors');
      return;
    }

    switch (actionType) {
      case ShiftActionType.CLOSE_RESERVATIONS: {
        const s = shift as unknown as ReservationShift;

        if (
          (s.type === ShiftType.RESERVATION &&
            s.overwrite?.blockReservations) ||
          ((shift.type === ShiftType.GUEST_ORDERING ||
            shift.type === ShiftType.HAPPY_HOURS) &&
            s.closed)
        ) {
          newToast('Shift is allready Blocked', Severity.ERROR, 'errors');
          return;
        }

        let newShift: any;
        let shifts: any[] = [];

        if (shift.type === ShiftType.RESERVATION) {
          newShift = {
            ...s,
            overwrite: s.overwrite
              ? { ...s.overwrite, blockReservations: true }
              : { blockReservations: true },
          } as ReservationShift;

          shifts =
            date?.shifts.map((s) =>
              s.id === newShift.id
                ? (newShift as ReservationShift)
                : (s as unknown as ReservationShift)
            ) || ([] as ReservationShift[]);
        }

        if (shift.type === ShiftType.GUEST_ORDERING) {
          newShift = {
            ...shift,
            closed: true,
          } as unknown as GuestOrderingShift;

          shifts =
            date?.shifts.map((s) =>
              s.id === newShift.id
                ? (newShift as GuestOrderingShift)
                : (s as unknown as GuestOrderingShift)
            ) || ([] as GuestOrderingShift[]);
        }

        if (shift.type === ShiftType.HAPPY_HOURS) {
          newShift = { ...shift, closed: true } as OffersShift;
          shifts =
            date?.shifts.map((s) =>
              s.id === newShift.id
                ? (newShift as OffersShift)
                : (s as OffersShift)
            ) || ([] as OffersShift[]);
        }

        if (!shifts.length) return;

        ref
          .update({ shifts })
          .then(() => {
            setdates((dates) =>
              dates.map((d) => (d.date === dateId ? { ...d, shifts } : d))
            );
            newToast(
              'Shift was blocked for further Reservations',
              Severity.SUCCESS
            );
          })
          .catch((error) => {
            newToast(error.message, Severity.ERROR, 'errors');
          });

        return;
      }
      case ShiftActionType.OPEN_RESERVATIONS: {
        const s = shift as unknown as ReservationShift;

        if (
          (shift.type === ShiftType.RESERVATION &&
            !s.overwrite?.blockReservations) ||
          ((shift.type === ShiftType.GUEST_ORDERING ||
            shift.type === ShiftType.HAPPY_HOURS) &&
            !s.closed)
        ) {
          newToast('Shift is not blocked', Severity.ERROR, 'errors');
          return;
        }

        let newShift: any;
        let shifts: any[] = [];

        if (shift.type === ShiftType.RESERVATION) {
          newShift = {
            ...s,
            overwrite: s.overwrite
              ? { ...s.overwrite, blockReservations: false }
              : { blockReservations: false },
          } as ReservationShift;

          shifts =
            date?.shifts.map((s) =>
              s.id === newShift.id
                ? newShift
                : (s as unknown as ReservationShift)
            ) || [];
        }

        if (shift.type === ShiftType.GUEST_ORDERING) {
          newShift = {
            ...shift,
            closed: false,
          } as unknown as GuestOrderingShift;

          shifts =
            date?.shifts.map((s) =>
              s.id === newShift.id
                ? (newShift as GuestOrderingShift)
                : (s as unknown as GuestOrderingShift)
            ) || ([] as GuestOrderingShift[]);
        }

        if (shift.type === ShiftType.HAPPY_HOURS) {
          newShift = { ...shift, closed: false } as OffersShift;

          shifts =
            date?.shifts.map((s) =>
              s.id === newShift.id
                ? (newShift as OffersShift)
                : (s as OffersShift)
            ) || ([] as OffersShift[]);
        }

        if (!shifts.length) return;

        ref
          .update({ shifts })
          .then(() => {
            setdates((dates) =>
              dates.map((d) => (d.date === dateId ? { ...d, shifts } : d))
            );
            newToast('Shift was successfully unblocked', Severity.SUCCESS);
          })
          .catch((error) => {
            newToast(error.message, Severity.ERROR, 'errors');
          });

        return;
      }
      case ShiftActionType.CLOSE_TERRACE: {
        const s = shift as unknown as ReservationShift;

        if (shift.type !== ShiftType.RESERVATION) return;

        if (
          s.overwrite?.placeAt === 'indoor' ||
          (s.overwrite?.placeAt === undefined &&
            s.additional?.placeAt === 'indoor')
        ) {
          newToast(
            'Seating is allready only allowed indoors',
            Severity.ERROR,
            'errors'
          );
          return;
        }

        let newShift: ReservationShift = {
          ...s,
          overwrite: s.overwrite
            ? { ...s.overwrite, placeAt: 'indoor' }
            : { placeAt: 'indoor' },
        };

        let shifts: ReservationShift[] =
          date?.shifts.map((s) =>
            s.id === newShift.id
              ? (newShift as ReservationShift)
              : (s as unknown as ReservationShift)
          ) || [];

        if (!shifts.length) return;

        ref
          .update({ shifts })
          .then(() => {
            setdates((dates) =>
              dates.map((d) =>
                d.date === dateId ? ({ ...d, shifts } as any) : d
              )
            );
            newToast('Terrace was successfully closed', Severity.SUCCESS);
            //@TODO Ask if they want to move all Reservations indoor;
          })
          .catch((error) => {
            newToast(error.message, Severity.ERROR, 'errors');
          });

        return;
      }
      case ShiftActionType.OPEN_TERRACE: {
        if (shift.type !== ShiftType.RESERVATION) return;

        const s = shift as unknown as ReservationShift;

        if (
          s.overwrite?.placeAt !== 'indoor' &&
          s.overwrite?.placeAt === undefined &&
          s.additional?.placeAt !== 'indoor'
        ) {
          newToast(
            'Terrace is allready open for Reservations',
            Severity.ERROR,
            'errors'
          );
          return;
        }

        let newShift: ReservationShift = {
          ...s,
          overwrite: s.overwrite
            ? { ...s.overwrite, placeAt: null }
            : { placeAt: null },
        };

        let shifts: ReservationShift[] =
          date?.shifts.map((s) =>
            s.id === newShift.id
              ? (newShift as ReservationShift)
              : (s as unknown as ReservationShift)
          ) || [];

        if (!shifts.length) return;

        ref
          .update({ shifts })
          .then(() => {
            setdates((dates) =>
              dates.map((d) =>
                d.date === dateId ? ({ ...d, shifts } as any) : d
              )
            );
            newToast('Terrace was successfully opened', Severity.SUCCESS);
            //@TODO Ask if they want to move all Reservations outdoor;
          })
          .catch((error) => {
            newToast(error.message, Severity.ERROR, 'errors');
          });

        return;
      }
      case ShiftActionType.EDIT_SHIFT: {
        if (shift.regular && !shift.edited) {
          alert({
            title: 'Edit all upcoming Shifts?',
            maxWidth: 'sm',
            fullWidth: true,
            description:
              'Choose between editing all upcoming shifts or only on the selected dates',
            actions: [
              {
                label: 'No, only on this day',
                onClick: () => {
                  setDate(dateId);
                  setactiveShift({
                    shift,
                    date: dateId,
                    type: 'edit',
                    allUpcomingShifts: false,
                  });
                },
                id: 'onlyThisShift',
              },
              {
                label: 'Yes, on all future dates',
                variant: 'primary',
                id: 'allUpcomingShifts',
                onClick: () => {
                  setDate(dateId);
                  setactiveShift({
                    shift: {
                      ...shift,
                      startAt: dateId,
                      dates:
                        shift?.dates?.filter((d: any) => d < dateId) ??
                        undefined,
                    },
                    date: dateId,
                    type: 'edit',
                    allUpcomingShifts: true,
                  });
                },
              },
            ],
            onSubmit: () => {},
          });
        } else {
          setDate(dateId);
          setactiveShift({
            shift,
            date: dateId,
            type: 'edit',
          });
        }
        return;
      }
      case ShiftActionType.DELETE_SHIFT: {
        alert({
          title: 'Delete Shift completly',
          description: 'Do you want to delete this shift?',
          secondaryAction: shift.regular
            ? {
                label: 'Delete all upcoming Shifts',
              }
            : undefined,
          onSubmit: (allFutureDates: boolean = false) => {
            setloading(true);

            server
              .post<{ success: boolean }>(
                `/v03/shifts/${restaurantId}/shift/${shift.id}/${shift.type}`,
                {
                  date: dateId,
                  allFutureDates,
                  type: actionType,
                  disableOnly: !shift.disabled && !shift.commentOnly,
                  uid,
                } as ShiftActionBody
              )
              .then(() => {
                loadWeek();
                newToast('Shift was successfully deleted', Severity.SUCCESS);
                setcurrentShift(null);
              })
              .catch((err) => {
                newToast(
                  err?.response?.data?.message ?? err.message,
                  Severity.ERROR,
                  'errors'
                );
              })
              .finally(() => {
                triggerCustomEvent('triggerShiftReload');
                setloading(false);
              });
          },
        });

        return;
      }
      case ShiftActionType.RESTORE_SHIFT: {
        setloading(true);

        server
          .post<{ success: boolean }>(
            `/v03/shifts/${restaurantId}/shift/${shift.id}/${shift.type}`,
            {
              date: dateId,
              allFutureDates: true,
              type: actionType,
              uid,
            } as ShiftActionBody
          )
          .then(() => {
            loadWeek();
            newToast('Shift was successfully restored', Severity.SUCCESS);
            setcurrentShift(null);
          })
          .catch((err) => {
            newToast(
              err?.response?.data?.message ?? err.message,
              Severity.ERROR,
              'errors'
            );
          })
          .finally(() => setloading(false));

        return;
      }
      case ShiftActionType.DUPLICATE_SHIFT: {
        setactiveShift({
          shift: {
            ...shift,
            name: `${shift.name} Kopie`,
            id: 'newId',
            startAt: dateId,
          },
          date: dateId,
          type: 'duplication',
          allUpcomingShifts: true,
        });

        return;
      }
      case ShiftActionType.ADD_EVENT: {
        const event = payload;

        let newShift = { ...shift };

        if (event && (newShift as any).additional) {
          newShift = {
            ...newShift,
            additional: {
              ...newShift.additional,
              eventV02: event,
            },
          };
        }

        if (shift.regular) {
          alert({
            title: 'Edit all upcoming Events of this Shift?',
            maxWidth: 'sm',
            fullWidth: true,
            description:
              'Choose between editing all upcoming shifts or only on the selected dates',
            actions: [
              {
                label: 'No, only on this day',
                onClick: () => {
                  setDate(dateId);
                  setactiveShift({
                    shift: newShift,
                    date: dateId,
                    type: 'edit',
                    allUpcomingShifts: false,
                    eventOnly: true,
                  });
                },
                id: 'onlyThisShift',
              },
              {
                label: 'Yes, on all future dates',
                variant: 'primary',
                id: 'allUpcomingShifts',
                onClick: () => {
                  setDate(dateId);
                  setactiveShift({
                    shift: newShift,
                    date: dateId,
                    type: 'edit',
                    allUpcomingShifts: true,
                    eventOnly: true,
                  });
                },
              },
            ],
            onSubmit: () => {},
          });
        } else {
          setDate(dateId);
          setactiveShift({
            shift: newShift,
            date: dateId,
            type: 'edit',
            eventOnly: true,
          });
        }
        return;
      }
      case ShiftActionType.EDIT_EVENT: {
        if (shift.regular) {
          // @ts-ignore
          if ((shift as ReservationShift)?.additional?.eventV02?.edited) {
            setactiveShift({
              shift,
              date: dateId,
              type: 'edit',
              eventOnly: true,
              allUpcomingShifts: false,
            });
          } else {
            alert({
              title: 'Edit all upcoming Events of this Shift?',
              maxWidth: 'sm',
              fullWidth: true,
              description:
                'Choose between editing all upcoming shifts or only on the selected dates',
              actions: [
                {
                  label: 'No, only on this day',
                  onClick: () => {
                    setDate(dateId);
                    setactiveShift({
                      shift,
                      date: dateId,
                      type: 'edit',
                      allUpcomingShifts: false,
                      eventOnly: true,
                    });
                  },
                  id: 'onlyThisShift',
                },
                {
                  label: 'Yes, on all future dates',
                  variant: 'primary',
                  id: 'allUpcomingShifts',
                  onClick: () => {
                    setDate(dateId);
                    setactiveShift({
                      shift,
                      date: dateId,
                      type: 'edit',
                      allUpcomingShifts: true,
                      eventOnly: true,
                    });
                  },
                },
              ],
              onSubmit: () => {},
            });
          }
        } else {
          setDate(dateId);
          setactiveShift({
            shift,
            date: dateId,
            type: 'edit',
            eventOnly: true,
          });
        }
        return;
      }
      case ShiftActionType.BRING_BACK_FROM_DELETED: {
        // console.log({ payload });
        // if (payload.shift) {
        //   let { shift } = payload;

        //   setDate(dateId);
        //   setactiveShift({
        //     shift,
        //     date: dateId,
        //     type: "edit",
        //     allUpcomingShifts: false,
        //   });
        // }

        //  console.log(`TESTING`);

        server
          .get(
            `/v03/shifts/${restaurantId}/shift/${id}/${type}/${dateId}/bringBackFromDeleted`
          )
          .then(() => {
            loadWeek();
            newToast('Shift was successfully restored', Severity.SUCCESS);
          })
          .catch((error) => {
            newToast(
              error?.response?.data?.message ?? error.message,
              Severity.ERROR,
              'errors'
            );
          });

        return;
      }
      default:
        newToast('An error occurred', Severity.ERROR, 'errors');
        return;
    }
  };

  const [newShiftSidebar, setnewShiftSidebar] = useState<null | string>(null);

  const handleNewShift = (
    dateId: string = date,
    from?: string,
    till?: string
  ) => {
    if (!from) {
      setnewShiftSidebar(dateId);
    } else {
      newShiftFromTemplate('new', 'noTemplate', dateId, from, till);
    }
    setcurrentShift(null);
    setCurrentMultipleShifts(null);
    setSelectedMultiCard(null);
  };

  const closeNewShift = () => setnewShiftSidebar(null);

  // const recentShifts = useMemo(
  //   () =>
  //     dates.reduce((acc, cV) => {
  //       cV.shifts.forEach((s) => {
  //         if (s.regular && !acc.find((s1) => s.id === s1.id)) {
  //           acc.push(s as AnyShift);
  //         }
  //       });
  //       return acc;
  //     }, [] as AnyShift[]),
  //   [dates]
  // );

  const { recentShifts, eventTemplates } = useRecentShifts(
    restaurantId,
    type,
    dates
  );

  const newShiftFromTemplate = (
    id: string,
    action: 'fromTemplate' | 'noTemplate' | 'fromRecent' | 'commentTemplate',
    dateId?: string,
    from?: string,
    till?: string
  ) => {
    // if (!newShiftSidebar) return;

    let startAt: string | null = null;

    if (!dateId) {
      let dObj = new Date(date);
      let wDay = dObj.getDay();

      if (wDay !== 1) {
        startAt = dateHelper(
          new Date(dObj.getTime() - (wDay - 1) * 24 * 60000 * 60)
        );
      }

      if (date < dateHelper()) {
        startAt = dateHelper();
      }
    }

    dateId = dateId || startAt || newShiftSidebar || date;

    if (!startAt) {
      startAt = dateId;
    }

    if (dateId < dateHelper()) {
      dateId = dateHelper();
    }

    let colors = dates.reduce((acc: string[], cV) => {
      return acc.filter(
        (c) => !cV.shifts.find((s) => s.regular && s.color === c)
      );
    }, COLORS);

    let color = colors?.[0] ?? '#10CCB9';

    let weekDays = [new Date(dateId).getDay()];

    // load occassions

    switch (action) {
      case 'noTemplate': {
        let start = timeToBlock(from ?? '18:00');
        let close = timeToBlock(till ?? '22:00');
        let service = close - 8;

        if (close < start) {
          close += 96;
        }

        if (service < start) {
          service = close;
        }

        console.log({ start, close, service, from, till });

        const occasions = allOccassions.map((o) => o.id);

        let newShift: Partial<AnyShift> = {
          id: 'new-shift',
          name: '',
          color,
          active: true,
          regular: true,
          priority: 0,
          weekDays,
          startAt,
          start,
          service,
          close,
          type: type as
            | ShiftType.RESERVATION
            | ShiftType.GUEST_ORDERING
            | ShiftType.HAPPY_HOURS
            | undefined,
        };

        if (newShift.type === ShiftType.RESERVATION) {
          newShift.additional = {
            blockedAt: [],
            slotsPerBlock: {
              rules: [],
              standard: 20,
            },
            reservationLength: {
              rules: [],
              standard: 8,
            },
            floorPlan: null,
            placeAt: null,
          };
          newShift.spaces = [];
          newShift.occassions = allOccassions.map((o) => o.id);
        }

        if (newShift.type === ShiftType.GUEST_ORDERING) {
          (newShift as GuestOrderingShift).menu = null;
        }

        if (newShift.type === ShiftType.TAKEAWAY) {
          (newShift as TakeAwayShift).menu = null;
        }

        setactiveShift({
          date: dateId,
          shift: newShift as Partial<TShift>,
          type: 'new',
          allUpcomingShifts: true,
        });
        setDate(dateId);
        setCurrentMultipleShifts(null);
        setSelectedMultiCard(null);
        setcurrentShift(null);

        break;
      }
      case 'commentTemplate': {
        let newShift: ReservationShift = {
          id: 'new-shift',
          name: '',
          color,
          regular: false,
          priority: 0,
          weekDays: [],
          dates: [dateId],
          endAt: dateId,
          startAt: dateId,
          start: 72,
          service: 92,
          close: 96,
          additional: {
            blockedAt: [],
            slotsPerBlock: {
              rules: [],
              standard: 20,
            },
            reservationLength: {
              rules: [],
              standard: 8,
            },
            floorPlan: null,
            placeAt: null,
          },
          spaces: [],
          occassions: [],
          active: true,
          createdAt: Date.now(),
          commentOnly: true,
          createdBy: uid ?? '',
          type: ShiftType.RESERVATION,
        };

        setactiveShift({
          date: dateId,
          shift: newShift as unknown as Partial<TShift>,
          type: 'new',
          allUpcomingShifts: true,
        });
        setDate(dateId);
        setCurrentMultipleShifts(null);
        setSelectedMultiCard(null);
        setcurrentShift(null);

        break;
      }
      case 'fromRecent': {
        const recent = recentShifts.find((r) => r.id === id) as TShift;

        if (!recent) return;

        let newShift: Partial<TShift> & { overwrite?: any; occassions?: any } =
          {
            ...recent,
            id: 'new-shift',
            name: '',
            startAt: dateId,
            color,
            disabled: false,
          };

        if (newShift.type === ShiftType.RESERVATION) {
          newShift.overwrite = {};
          newShift.occassions = allOccassions.map((o) => o.id);
        }

        setactiveShift({
          date: dateId,
          shift: newShift,
          type: 'new',
          allUpcomingShifts: true,
        });
        setDate(dateId);
        setCurrentMultipleShifts(null);
        setSelectedMultiCard(null);
        setcurrentShift(null);

        break;
      }
      case 'fromTemplate': {
        const template = TEMPLATES[id];

        const occassions = allOccassions.map((o) => o.id);

        if (id === 'template-3') {
          template.dates = getDateArray(dateId, 7);
          template.endAt = template.dates[template.dates.length - 1];
        } else if (!template.regular) {
          template.dates = [dateId];
        }

        let newShift: Partial<AnyShift> = {
          ...template,
          id: 'new-shift',
          startAt: dateId,
          color,
        };

        if (newShift.type === ShiftType.RESERVATION) {
          newShift.overwrite = {};
          newShift.occassions = occassions;
        }

        setactiveShift({
          date: dateId,
          shift: newShift as unknown as Partial<TShift>,
          type: 'new',
          allUpcomingShifts: true,
        });
        setDate(dateId);
        setCurrentMultipleShifts(null);
        setSelectedMultiCard(null);
        setcurrentShift(null);

        break;
      }
      default:
        break;
    }

    setnewShiftSidebar(null);
  };

  const endAt = useDateString(activeShift?.shift?.endAt ?? '');

  const saveActiveShift = async () => {
    if (!activeShift) return;

    const shift = activeShift.shift;

    triggerCustomEvent('triggerShiftReload');

    switch (activeShift.type) {
      case 'edit': {
        let shiftBefore =
          dates
            .find((d) => d.date === currentShift?.date)
            ?.shifts?.find((s) => s.id === currentShift?.shiftId) ?? null;

        let wDaysChanged =
          !shiftBefore ||
          (shiftBefore.weekDays?.length ?? 0) !==
            (shift.weekDays?.length ?? 0) ||
          !!(shiftBefore.weekDays ?? []).find(
            (s) => !shift.weekDays?.includes(s)
          );

        let endAtChanged = !!shiftBefore && shiftBefore.endAt !== shift.endAt;

        let endAtSmaller =
          !!shiftBefore &&
          (!shiftBefore.endAt || (shift.endAt ?? '') < shiftBefore.endAt);

        if (endAtChanged && endAtSmaller && shift.endAt) {
          let title =
            language === 'de'
              ? `Alle Schichten nach dem ${endAt} löschen?`
              : `Do you want to delete all shifts after ${endAt}?`;
          let description =
            language === 'de'
              ? 'Wähle zwischen dem löschen aller Schichten nach dem neuen Enddatum und dem behalten mit alten Einstellungen'
              : 'Choose between deleting shifts that are further in the future and falling back to the old settings.';

          alert({
            title,
            maxWidth: 'sm',
            fullWidth: true,
            description,
            actions: [
              {
                label: 'No, keep the future shifts',
                onClick: () => {
                  setloading(true);
                  server
                    .post<{ success: boolean }>(
                      `/v03/shifts/${restaurantId}/shift/${shift.id}/${shift.type}`,
                      {
                        date: activeShift.date,
                        allFutureDates: true,
                        deleteFuture: false,
                        type: ShiftActionType.EDIT_SHIFT,
                        data: shift,
                        uid,
                      } as ShiftActionBody
                    )
                    .then(() => {
                      loadWeek();
                      newToast(
                        'Shift was successfully edited',
                        Severity.SUCCESS
                      );
                    })
                    .catch((err) => {
                      newToast(
                        err?.response?.data?.message ?? err.message,
                        Severity.ERROR,
                        'errors'
                      );
                    })
                    .finally(() => setloading(false));
                },
                id: 'onlyThisShift',
              },
              {
                label: 'Yes, delete future shifts',
                variant: 'primary',
                id: 'onlyThisShift',
                onClick: () => {
                  setloading(true);
                  server
                    .post<{ success: boolean }>(
                      `/v03/shifts/${restaurantId}/shift/${shift.id}/${shift.type}`,
                      {
                        date: activeShift.date,
                        allFutureDates: true,
                        deleteFuture: true,
                        type: ShiftActionType.EDIT_SHIFT,
                        data: shift,
                        uid,
                      } as ShiftActionBody
                    )
                    .then(() => {
                      loadWeek();
                      newToast(
                        'Shift was successfully edited',
                        Severity.SUCCESS
                      );
                    })
                    .catch((err) => {
                      newToast(
                        err?.response?.data?.message ?? err.message,
                        Severity.ERROR,
                        'errors'
                      );
                    })
                    .finally(() => setloading(false));
                },
              },
            ],
            onSubmit: () => {},
          });
        } else if (
          (shift.regular && !wDaysChanged && !endAtChanged) ||
          (shift.dates &&
            shift.dates.filter((d) => d > activeShift.date).length)
        ) {
          if (!activeShift?.allUpcomingShifts) {
            setloading(true);
            server
              .post<{ success: boolean }>(
                `/v03/shifts/${restaurantId}/shift/${shift.id}/${shift.type}`,
                {
                  date: activeShift.date,
                  allFutureDates: false,
                  type: ShiftActionType.EDIT_SHIFT,
                  data: shift,
                  uid,
                } as ShiftActionBody
              )
              .then(() => {
                loadWeek();
                newToast('Shift was successfully edited', Severity.SUCCESS);
              })
              .catch((err) => {
                newToast(
                  err?.response?.data?.message ?? err.message,
                  Severity.ERROR,
                  'errors'
                );
              })
              .finally(() => setloading(false));
          } else if (activeShift?.allUpcomingShifts) {
            setloading(true);
            server
              .post<{ success: boolean }>(
                `/v03/shifts/${restaurantId}/shift/${shift.id}/${shift.type}`,
                {
                  date: activeShift.date,
                  allFutureDates: true,
                  type: ShiftActionType.EDIT_SHIFT,
                  data: shift,
                  uid,
                } as ShiftActionBody
              )
              .then(() => {
                loadWeek();
                newToast('Shift was successfully edited', Severity.SUCCESS);
              })
              .catch((err) => {
                newToast(
                  err?.response?.data?.message ?? err.message,
                  Severity.ERROR,
                  'errors'
                );
              })
              .finally(() => setloading(false));
          }
        }
        // alert({
        //   title: "Edit all upcoming Shifts?",
        //   maxWidth: "sm",
        //   fullWidth: true,
        //   description:
        //     "Choose between editing all upcoming shifts or only on the selected dates",
        //   actions: [
        //     {
        //       label: "No, only on this day",
        //       onClick: () => {
        //         setloading(true);
        //         server
        //           .post<{ success: boolean }>(
        //             `/v03/shifts/${restaurantId}/shift/${shift.id}/${shift.type}`,
        //             {
        //               date: activeShift.date,
        //               allFutureDates: false,
        //               type: ShiftActionType.EDIT_SHIFT,
        //               data: shift,
        //               uid,
        //             } as ShiftActionBody
        //           )
        //           .then(() => {
        //             loadWeek();
        //             newToast("Shift was successfully edited", Severity.SUCCESS);
        //           })
        //           .catch((err) => {
        //             newToast(
        //               err?.response?.data?.message ?? err.message,
        //               Severity.ERROR,
        //               "errors"
        //             );
        //           })
        //           .finally(() => setloading(false));
        //       },
        //       id: "onlyThisShift",
        //     },
        //     {
        //       label: "Yes, on all future dates",
        //       variant: "primary",
        //       id: "onlyThisShift",
        //       onClick: () => {
        //         setloading(true);
        //         server
        //           .post<{ success: boolean }>(
        //             `/v03/shifts/${restaurantId}/shift/${shift.id}/${shift.type}`,
        //             {
        //               date: activeShift.date,
        //               allFutureDates: true,
        //               type: ShiftActionType.EDIT_SHIFT,
        //               data: shift,
        //               uid,
        //             } as ShiftActionBody
        //           )
        //           .then(() => {
        //             loadWeek();
        //             newToast("Shift was successfully edited", Severity.SUCCESS);
        //           })
        //           .catch((err) => {
        //             newToast(
        //               err?.response?.data?.message ?? err.message,
        //               Severity.ERROR,
        //               "errors"
        //             );
        //           })
        //           .finally(() => setloading(false));
        //       },
        //     },
        //   ],
        //   onSubmit: () => {},
        // });
        else {
          setloading(true);

          let oldDate = activeShift.date;

          let date = !activeShift.shift.regular
            ? activeShift.shift.dates?.[0] ?? activeShift.date
            : activeShift.date;

          server
            .post<{ success: boolean }>(
              `/v03/shifts/${restaurantId}/shift/${shift.id}/${shift.type}`,
              {
                date,
                oldDate,
                allFutureDates: activeShift.allUpcomingShifts,
                type: ShiftActionType.EDIT_SHIFT,
                deleteFuture: false,
                data: shift,
                uid,
              } as ShiftActionBody
            )
            .then(() => {
              loadWeek();
              newToast('Shift was successfully edited', Severity.SUCCESS);
            })
            .catch((err) => {
              newToast(
                err?.response?.data?.message ?? err.message,
                Severity.ERROR,
                'errors'
              );
            })
            .finally(() => {
              setloading(false);
              triggerCustomEvent('triggerShiftReload');
            });
        }

        break;
      }
      case 'new': {
        const data =
          shift.type === ShiftType.RESERVATION
            ? new NewReservationShift(shift as any).toData()
            : shift.type === ShiftType.GUEST_ORDERING
            ? new NewGuestOrderingShift(shift as any).toData()
            : shift.type === ShiftType.HAPPY_HOURS
            ? new NewHappyHoursShift(shift as any).toData()
            : shift.type === ShiftType.TAKEAWAY
            ? new NewHappyHoursShift(shift as any).toData()
            : {};

        if (shift.regular && shift.weekDays && shift.weekDays.length === 0) {
          alert({
            title: 'Keine Wochentage ausgewählt',
            description:
              'Bitte wähle mindestens einen Wochentag aus um fortzufahren',
            onSubmit: () => {},
            actions: [
              {
                id: 'ok',
                label: 'Ok',
                variant: 'primary',
              },
            ],
          });
          return;
        }

        setloading(true);

        server
          .post<{ success: boolean }>(
            `/v03/shifts/${restaurantId}/shift/new/${shift.type}`,
            {
              date: shift.startAt,
              type: ShiftActionType.NEW_SHIFT,
              data,
              uid,
            } as ShiftActionBody
          )
          .then(() => {
            loadWeek();
            newToast('Shift was successfully created', Severity.SUCCESS);
          })
          .catch((err) => {
            newToast(
              err?.response?.data?.message ?? err.message,
              Severity.ERROR,
              'errors'
            );
          })
          .finally(() => {
            setloading(false);
            triggerCustomEvent('triggerShiftReload');
          });
        break;
      }
      case 'duplication': {
        // @TODO Clean up data
        if (shift.type !== ShiftType.RESERVATION) return;
        const data = new NewReservationShift(
          shift as unknown as ReservationShift
        ).toData();

        setloading(true);

        server
          .post<{ success: boolean }>(
            `/v03/shifts/${restaurantId}/shift/new/${shift.type}`,
            {
              date: shift.startAt || activeShift.date,
              type: ShiftActionType.NEW_SHIFT,
              data,
              uid,
            } as ShiftActionBody
          )
          .then(() => {
            loadWeek();
            newToast('Shift was successfully duplicated', Severity.SUCCESS);
          })
          .catch((err) => {
            newToast(
              err?.response?.data?.message ?? err.message,
              Severity.ERROR,
              'errors'
            );
          })
          .finally(() => {
            setloading(false);
            triggerCustomEvent('triggerShiftReload');
          });
        break;
      }
      default: {
        newToast('An Error occured', Severity.ERROR, 'errors');
        break;
      }
    }

    setactiveShift(null);
  };

  const datesWithActiveShifts = useMemo(() => {
    return !activeShift
      ? dates
      : dates.map((d) => {
          const shift = activeShift.shift;

          let wDay = new Date(d.date).getDay();

          let isRegularShift =
            shift.regular &&
            shift.startAt &&
            shift.startAt <= d.date &&
            (!shift.endAt || shift.endAt >= d.date) &&
            shift.weekDays &&
            shift.weekDays.includes(wDay);

          let isSpecialShift =
            !shift.regular && shift.startAt && shift.startAt === d.date;

          let isOnThisDay =
            (isRegularShift || isSpecialShift) &&
            checkActiveShift(activeShift, activeShift?.shift?.id, d.date);

          if (!isOnThisDay) {
            return d;
          }

          const shifts = Array.from([...d.shifts]);

          let i = shifts.findIndex((s) =>
            checkActiveShift(activeShift, s.id, d.date)
          );

          if (i === -1) {
            shifts.push(shift as any);
          } else {
            shifts[i] = shift as any;
          }

          return {
            ...d,
            shifts,
          };
        });
  }, [dates, activeShift]);

  return {
    dates: datesWithActiveShifts,
    calendarWeek,
    loading,
    error,
    onShiftAction,
    activeShift,
    setactiveShift,
    handleNewShift,
    saveActiveShift,
    currentShift,
    setcurrentShift,
    closeNewShift,
    recentShifts,
    newShiftFromTemplate,
    newShiftSidebar,
    currentMultipleShifts,
    setCurrentMultipleShifts,
    selectedMultiCard,
    setSelectedMultiCard,
    setdates,
    eventTemplates,
  };
};

export default useCalendarDates;
