import useAlert from 'CustomHooks/useAlert';
import useAuth from 'CustomHooks/useAuth';
import usePinAuth, { SecurityPlusKeys } from 'CustomHooks/usePinAuth';
import { db } from './firebase';

const useProtectedAction = (typeGlobal?: SecurityPlusKeys) => {
  const { roleMap, restaurantId } = useAuth();

  const alert = useAlert();

  const { checkAuthentication, authenticate } = usePinAuth();

  function protectedAction(
    role: 'waiter' | 'chefWaiter' | 'admin' | 'tablet' = 'waiter',
    what: string,
    action: () => any,
    type = typeGlobal
  ) {
    const handleAction = (signature: string) => {
      if (signature && what) {
        db.collection('signatureLogs').add({
          restaurantId,
          what,
          ts: Date.now(),
          signature,
        });
      }

      action();
    };

    if (checkAuthentication(type ?? 'none', false)) {
      authenticate(type ?? 'none', handleAction);
      return;
    }

    if (roleMap[role]) {
      handleAction('');
    } else {
      alert({
        title: 'Keine Berechtigung',
        description:
          'Um dies auszuführen müssen Sie mindestens Chef Kellner sein',
        onSubmit: () => {},
        submitLabel: 'Okay',
      });
    }
  }

  return protectedAction;
};

export default useProtectedAction;
