import React, { useState, useEffect } from "react";
import Box from "../../../../../Components/Atoms/Box";
import {
  AnyShift,
  Date as DateType,
  ShiftComment,
  ShiftType,
} from "../../../../../types/shifts";

import "../styles.scss";
import Typography from "Components/Atoms/Typography";
import { blockToTime, dateHelper, getTime } from "utils/helper";
import dayjs from "dayjs";
import { InnerNotes } from "./NotesTab";
import server from "utils/server";
import { Error, Info, Warning } from "@material-ui/icons";
import EventCard from "./EventCard";
interface DetailsTabProps {
  shiftDetails: AnyShift | undefined;
  date: DateType;
  reservationsGroups?: string;
  spaces?: { id: string; title: string }[];
  occassions?: { id: string; title: string }[];
  lightReservations?: boolean;
  currentShiftComment?: ShiftComment | null;
  restaurantId: string;
  shiftId: string;
  modalOpen: boolean;
  setModalOpen: (x: boolean) => void;
}

const CHECK_SEVERITY = ["INFO", "WARNING", "ERROR"];

const joinArrays = (...args: (string[] | undefined)[]) =>
  args.reduce((acc: string[], cV) => [...acc, ...(cV ?? [])], []);

const useShiftCheck = (
  date: string,
  restaurantId: string,
  shift: AnyShift | undefined
) => {
  const [check, setCheck] = useState<{
    runAt: number;
    errors?: {
      id: string;
      severity: "ERROR" | "WARNING" | "INFO";
      message: string;
    }[];
  } | null>(null);

  useEffect(() => {
    setCheck(null);
    if (
      shift?.type === ShiftType.RESERVATION &&
      !shift.commentOnly &&
      date >= dateHelper()
    ) {
      server
        .get(
          `/v03/shifts/${restaurantId}/checkReservationShift/${date}/${shift.id}`
        )
        .then(({ data }) => setCheck(data.check ?? null));
    }
  }, [date, shift, restaurantId]);

  return check;
};

const DetailsTab = ({
  shiftDetails,
  date,
  reservationsGroups = "",
  spaces = [],
  occassions = [],
  lightReservations = false,
  restaurantId,
  ...props
}: DetailsTabProps) => {
  // console.log({ spaces });

  function statusHandler() {
    if (shiftDetails?.commentOnly) return "Note Shift";
    if (shiftDetails?.type !== ShiftType.RESERVATION) return "";
    if (
      Date.now() > getTime(date.date, shiftDetails?.close, shiftDetails?.start)
    )
      return "Done";
    if (shiftDetails?.overwrite?.blockReservations) return "Blocked";
    if (shiftDetails?.edited) return "Edited";
    if (shiftDetails?.regular) return "Standard";
    else return "Special";
  }

  const spacesOccassions =
    shiftDetails?.type !== ShiftType.RESERVATION
      ? []
      : joinArrays(
          shiftDetails?.spaces?.map(
            (s) => spaces.find((s1) => s1.id === s)?.title || s
          ),
          shiftDetails?.occassions?.map(
            (o) => occassions.find((o1) => o1.id === o)?.title || o
          )
        ).reduce((acc, cV, i, arr) => {
          if (i === 0) {
            return cV;
          } else if (arr.length - 1 === i) {
            return `${acc} ${shiftDetails?.matchBoth ? "&" : "or"} ${cV}`;
          } else {
            return `${acc}, ${cV}`;
          }
        }, "");

  const commentOnly = !!shiftDetails?.commentOnly;

  const check = useShiftCheck(date.date, restaurantId, shiftDetails);

  return (
    <Box className="details-container">
      <div className="details-row">
        <Typography
          variant="text-3"
          className="details-row-title"
          translation="settings"
        >
          Status
        </Typography>
        <Typography
          variant="text-3"
          color="subdued"
          className="details-row-value"
          translation="settings"
        >
          {statusHandler()}
        </Typography>
      </div>
      <div className="details-row">
        <Typography
          variant="text-3"
          className="details-row-title"
          translation="settings"
        >
          Date
        </Typography>
        <Typography
          variant="text-3"
          color="subdued"
          className="details-row-value"
        >
          {dayjs(date.date).format("DD.MM.YYYY")}
        </Typography>
      </div>
      {!lightReservations && !commentOnly && (
        <div className="details-row">
          <Typography
            variant="text-3"
            className="details-row-title"
            translation="settings"
          >
            Spaces & Occassions
          </Typography>
          <Typography
            variant="text-3"
            color="subdued"
            className="details-row-value"
          >
            {spacesOccassions}
          </Typography>
        </div>
      )}
      <div className="details-row">
        <Typography
          variant="text-3"
          className="details-row-title"
          translation="settings"
        >
          Start time
        </Typography>
        <Typography
          variant="text-3"
          color="subdued"
          className="details-row-value"
          translation={null}
        >
          {blockToTime(shiftDetails?.start ?? 64)}
        </Typography>
      </div>
      {!commentOnly && !shiftDetails?.closed && !!shiftDetails?.service && (
        <div className="details-row">
          <Typography
            variant="text-3"
            className="details-row-title"
            translation="settings"
          >
            Last Reservation
          </Typography>
          <Typography
            variant="text-3"
            color="subdued"
            className="details-row-value"
            translation={null}
          >
            {blockToTime(shiftDetails.service)}
          </Typography>
        </div>
      )}
      <div className="details-row">
        <Typography
          variant="text-3"
          className="details-row-title"
          translation="settings"
        >
          Closing Time
        </Typography>
        <Typography
          variant="text-3"
          color="subdued"
          className="details-row-value"
          translation={null}
        >
          {blockToTime(shiftDetails?.close ?? 64)}
        </Typography>
      </div>
      {!commentOnly && !shiftDetails?.closed && (
        <div className="details-row">
          <Typography
            variant="text-3"
            className="details-row-title"
            translation="settings"
          >
            Reservations / Groups
          </Typography>
          <Typography
            variant="text-3"
            color="subdued"
            className="details-row-value"
          >
            {reservationsGroups}
          </Typography>
        </div>
      )}
      {!commentOnly && !shiftDetails?.additional?.eventV02?.active && (
        <div className="details-row">
          <Typography
            variant="text-3"
            className="details-row-title"
            translation="calendar"
          >
            Event
          </Typography>
          <Typography
            variant="text-3"
            color="subdued"
            className="details-row-value"
          >
            {shiftDetails?.additional?.eventV02?.title?.de ?? ""}
          </Typography>
        </div>
      )}
      {!!commentOnly && (
        <Box padding="md">
          <InnerNotes
            date={date}
            shiftDetails={shiftDetails}
            restaurantId={restaurantId}
            {...props}
          />
        </Box>
      )}
      {!!check?.errors?.length && (
        <Box padding="md">
          <Typography
            variant="text-3"
            className="details-row-title md-bt-sm"
            translation="calendar"
          >
            Conflicts:
          </Typography>

          {check.errors
            .sort(
              (a, b) =>
                CHECK_SEVERITY.indexOf(b.severity) -
                CHECK_SEVERITY.indexOf(a.severity)
            )
            .map((err) => (
              <Box key={err.id} className="flex flex-start mg-bt-sm">
                {err.severity === "ERROR" && (
                  <Error
                    style={{
                      width: 20,
                      height: 20,
                      color: "var(--color-critical)",
                      marginRight: 5,
                    }}
                  />
                )}
                {err.severity === "WARNING" && (
                  <Warning
                    style={{
                      width: 20,
                      height: 20,
                      color: "var(--color-warning)",
                      marginRight: 5,
                    }}
                  />
                )}
                {err.severity === "INFO" && (
                  <Info
                    style={{
                      width: 20,
                      height: 20,
                      color: "dodgerblue",
                      marginRight: 5,
                    }}
                  />
                )}
                <Typography variant="text-3">{err.message}</Typography>
              </Box>
            ))}
        </Box>
      )}
      {!!shiftDetails?.additional?.eventV02 && (
        <Box padding="sm">
          <EventCard
            shift={shiftDetails}
            date={date.date}
            restaurantId={restaurantId}
          />
        </Box>
      )}
    </Box>
  );
};

export default DetailsTab;
