import Box from 'Components/Atoms/Box';
import { ArrowDropDown } from 'Components/Atoms/Icons';
import LockedProductIcon from 'Components/Atoms/Icons/LockedProduct';
import MenuItem from 'Components/Atoms/MenuItem';
import Popover from 'Components/Atoms/Popover';
import Typography from 'Components/Atoms/Typography';
import usePinAuth from 'CustomHooks/usePinAuth';
import React, { useState, useEffect } from 'react';

type OccassionDropdownProps = {
  isHidden: boolean;
  occassions: { id: string; title: string }[];
  value: string | undefined;
  onChange: (newValue: string | number) => void;
  style?: React.CSSProperties;
};

const OccassionDropdown = ({
  isHidden,
  occassions,
  value,
  onChange,
  style,
}: OccassionDropdownProps) => {
  const [anchorEl, setanchorEl] = useState<null | HTMLElement>(null);

  const { checkAuthentication, authenticate } = usePinAuth();

  useEffect(() => {
    if (isHidden && occassions.length) {
      onChange(occassions[0].id);
    }
  }, [isHidden]);

  if (isHidden && occassions.length) return <></>;

  return (
    <Box>
      <Typography
        block
        variant="text-3"
        translation="reservations"
        style={{ marginBottom: 8 }}
      >
        Occassion
      </Typography>
      <Box
        outline
        background
        flex
        padding="sm"
        onClick={(e) => setanchorEl((x) => (!x ? e.currentTarget : null))}
        className="space-between clickable"
        style={style}
      >
        <Typography variant="text-3" color="subdued" block>
          {occassions.find((o) => o.id === value)?.title || value}
        </Typography>
        <ArrowDropDown style={{ fill: 'var(--color-text-subdued)' }} />
      </Box>
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setanchorEl(null)}
        placement="bottom"
        padding={0}
      >
        {occassions.map((o) => (
          <MenuItem
            value={o.id}
            selected={o.id === value}
            onClick={(e, nV) =>
              authenticate(o.id as any, () => {
                onChange(nV);
                setanchorEl(null);
              })
            }
            key={o.id}
            iconRight={
              checkAuthentication(o.id as any) ? (
                <LockedProductIcon
                  width="20"
                  height="20"
                  noStroke
                  style={{ color: 'var(--color-secondary)' }}
                />
              ) : undefined
            }
          >
            {o.title}
          </MenuItem>
        ))}
      </Popover>
    </Box>
  );
};

export default OccassionDropdown;
