import React, { useMemo, useState } from 'react';
import Box from 'Components/Atoms/Box';
import Typography from 'Components/Atoms/Typography';
import { Restaurant } from 'utils/types';
import './styles.scss';
import { Settings } from 'Components/Atoms/Icons';
import SearchBar from 'Components/Molecules/SearchBar';
import IconButton from 'Components/Atoms/IconButton';
import { useMediaQuery } from '@material-ui/core';
import { classHelper } from 'utils/helper';

interface DropdownOptionsProps {
  activeOption?: string;
  restaurants: Restaurant[];
  goToSettings: (restaurantId: string) => void;
  switchRestaurant: (restaurantId: string) => void;
}

function searchable(str: string) {
  return str.toLowerCase().replace(/\'|\`|\"|\-/gm, '');
}

export default function DropdownOptions({
  restaurants,
  goToSettings,
  switchRestaurant,
  activeOption,
}: DropdownOptionsProps) {
  const [search, setSearch] = useState('');
  const isMobile = useMediaQuery('(max-width:450px)');

  const options = useMemo(() => {
    if (!search) return restaurants;
    return restaurants.filter(
      (option) =>
        searchable(option?.title ?? '').includes(searchable(search)) ||
        searchable(option?.id ?? '').includes(searchable(search))
    );
  }, [restaurants, search]);

  const mobileStyles = {
    maxHeight: '500px',
  };

  const classNames = classHelper([
    'dropdown-options-container',
    isMobile && 'mobile-dropdown',
  ]);

  return (
    <Box className={classNames} style={isMobile ? mobileStyles : {}}>
      <Box
        className="dropdown-title-container"
        onClick={(event) => event.preventDefault()}
      >
        <Typography variant="h5" translation="dashboard">
          Select Restaurant
        </Typography>
        {restaurants.length > 3 && (
          <SearchBar
            className="search-bar"
            value={null}
            onChange={setSearch}
            width="108px"
          />
        )}
      </Box>
      <Box flex className="flex-column" style={isMobile ? mobileStyles : {}}>
        {options.map((res: Restaurant) => (
          <Box
            key={res.id}
            className={`dropdown-item ${
              activeOption === res.id ? 'active' : ''
            }`}
            onClick={() => switchRestaurant(res.id)}
          >
            <Box className="dropdown-info">
              <Typography variant="text-3">{res.title}</Typography>
              <Typography variant="text-5" color="subdued">
                #{res.id}
              </Typography>
            </Box>
            {res.admin && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  goToSettings(res.id);
                }}
                color="subdued"
                size="medium"
                aria-label="Settings"
              >
                <Settings />
              </IconButton>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
}
