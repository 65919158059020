import React, { useContext, useMemo, useState } from "react";
import "../styles.scss";
import Box from "Components/Atoms/Box";
import { AuthContext } from "Contexts/AuthContext";
import { RestaurantContext, Severity } from "Contexts/RestaurantContext";
import TextField from "Components/Atoms/TextField";
import Typography from "Components/Atoms/Typography";
import { Check, Close } from "Components/Atoms/Icons";
import Button from "Components/Atoms/Button";

interface MobilePasswordModalProps {
  open: boolean;
  onClose: () => void;
}

const MobilePasswordModal = ({ open, onClose }: MobilePasswordModalProps) => {
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const { handleChangePassword } = useContext(AuthContext);
  const { newToast } = useContext(RestaurantContext);

  const checkPasswordStrength = useMemo(() => {
    let minCharacters: boolean = false;
    let upperCasing: boolean = false;
    let includesNumber: boolean = false;
    let allTrue: boolean = false;

    const upperCasePattern = new RegExp("(?=.*[A-Z])");
    const numPattern = new RegExp("(?=.*\\d)");

    if (newPassword.length >= 8) {
      minCharacters = true;
    }

    if (upperCasePattern.test(newPassword)) {
      upperCasing = true;
    }

    if (numPattern.test(newPassword)) {
      includesNumber = true;
    }

    if (minCharacters && upperCasePattern && numPattern) {
      allTrue = true;
    }

    return { minCharacters, upperCasing, includesNumber, allTrue };
  }, [newPassword]);

  const handleSubmit = async () => {
    if (!oldPassword) {
      setError("Please enter your actual password");
      return;
    }
    if (!newPassword) {
      setError("Please choose a new password");
      return;
    }
    setLoading(true);
    const result = await handleChangePassword(oldPassword, newPassword);
    if (result.success) {
      newToast(result.message, Severity.SUCCESS, "auth");
      setLoading(false);
      onClose();
    } else {
      setError(result.message);
      setLoading(false);
    }
  };

  return (
    <Box className="change-password-form-container">
      <TextField
        fullWidth
        type="password"
        label="Enter your actual password"
        labelTranslation="auth"
        value={oldPassword}
        onChange={(e) => setOldPassword(e.target.value)}
        required
        error={error.includes("actual password")}
        errorMessage={error.includes("actual password") ? error : ""}
        paper
      />
      <TextField
        fullWidth
        type="password"
        label="Enter your new password"
        labelTranslation="auth"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        required
        error={error.includes("new password")}
        errorMessage={error.includes("new password") ? error : ""}
        paper
      />
      {!!error &&
        !error.includes("new password") &&
        !error.includes("actual password") && (
          <Typography variant="text-3" color="error" translation={"auth"}>
            {error}
          </Typography>
        )}

      <Box className="password-rules-container">
        <Box className="password-rule-row">
          {checkPasswordStrength.minCharacters ? (
            <Check color="primary" width={20} height={20} />
          ) : (
            <Close color="error" width={20} height={20} />
          )}
          <Typography
            variant="text-3"
            color={
              checkPasswordStrength.minCharacters ? "textPrimary" : "subdued"
            }
          >
            Minimum 8 characters
          </Typography>
        </Box>

        <Box className="password-rule-row">
          {checkPasswordStrength.upperCasing ? (
            <Check color="primary" width={20} height={20} />
          ) : (
            <Close color="error" width={20} height={20} />
          )}
          <Typography
            variant="text-3"
            color={
              checkPasswordStrength.upperCasing ? "textPrimary" : "subdued"
            }
          >
            At least one uppercase letter
          </Typography>
        </Box>

        <Box className="password-rule-row">
          {checkPasswordStrength.includesNumber ? (
            <Check color="primary" width={20} height={20} />
          ) : (
            <Close color="error" width={20} height={20} />
          )}
          <Typography
            variant="text-3"
            color={
              checkPasswordStrength.includesNumber ? "textPrimary" : "subdued"
            }
          >
            At least one number
          </Typography>
        </Box>

        <Box className="mobile-actions-btns-bottom-transparent">
          <Button
            loading={loading}
            startIcon={() => <Check style={{ marginRight: 12 }} />}
            onClick={handleSubmit}
            variant="primary"
            fullWidth
            style={{
              backgroundColor: "rgba(16, 204, 185, 0.06)",
              border: "1px solid var(--color-primary)",
            }}
            disabled={!checkPasswordStrength.allTrue}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MobilePasswordModal;
