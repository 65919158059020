import React, { useState, useRef, useEffect, useMemo } from "react";
import Box from "Components/Atoms/Box";
import IconButton from "Components/Atoms/IconButton";
import { ArrowLeft, ArrowRight } from "Components/Atoms/Icons";
import { changeMonth, monthHelper, noop } from "utils/helper";
import Typography from "Components/Atoms/Typography";
import { useTranslation } from "react-i18next";

export type MonthPickerProps = {
  currentMonth: string;
  onChange: (month: string) => void;
  width?: number | string;
};

const MonthPicker = ({
  currentMonth = "",
  onChange = noop,
  width = 232,
}: MonthPickerProps) => {
  const { t } = useTranslation("common");

  const monthStr = useMemo(() => {
    if (!currentMonth || !currentMonth.includes("-")) return "";

    const [yyyy, mm] = currentMonth.split("-");

    return `${t(monthHelper(mm))} ${yyyy}`;
  }, [currentMonth]);

  const prevMonth = () => onChange(changeMonth(-1, currentMonth));
  const nextMonth = () => onChange(changeMonth(1, currentMonth));

  return (
    <Box flex className="space-between" width={width}>
      <IconButton size="small" onClick={prevMonth}>
        <ArrowLeft />
      </IconButton>
      <Typography variant="h5" style={{ margin: "0px 8px" }}>
        {monthStr}
      </Typography>
      <IconButton size="small" onClick={nextMonth}>
        <ArrowRight />
      </IconButton>
    </Box>
  );
};

export default MonthPicker;
