import React, { useState } from "react";
import Button from "Components/Atoms/Button";
import IconButton from "Components/Atoms/IconButton";
import { Close, CheckCircle } from "Components/Atoms/Icons";
import Card from "Components/Molecules/Card";
import "./styles.scss";
import { EditReservation, TimeSlot } from "types/reservations";
import { noop } from "utils/helper";
import FirstStep from "../NewReservationSidebar/Components/FirstStep";
import SignatureDropdown from "Components/Organisms/SignatureDropdown";
import Sidebar from "Components/Molecules/Sidebar";

export type EditReservationSidebarProps = {
  settings?: {
    hideResLength: boolean;
    hideOccassion: boolean;
  };
  restaurantId: string;
  uid: string;
  occassions: { id: string; title: string }[];
  editReservation: EditReservation;
  seteditReservation: React.Dispatch<
    React.SetStateAction<EditReservation | null>
  >;
  handleSubmit: () => Promise<void>;
  onClose: () => void;
  useSignature?: boolean;
  lightReservation?: boolean;
  setStorageTableStrs?: (s: string) => void;
};

const EditReservationSidebar = ({
  editReservation,
  seteditReservation = noop,
  settings,
  occassions,
  handleSubmit,
  onClose = noop,
  restaurantId,
  uid,
  useSignature = false,
  lightReservation = false,
  setStorageTableStrs,
}: EditReservationSidebarProps) => {
  console.log(editReservation, "editReservation");
  const [currentTable, setCurrentTable] = useState<string>(
    editReservation?.tableStr ?? ""
  );

  return (
    <Sidebar
      id="edit-reservation"
      title={editReservation?.guest?.name || "Edit Reservation"}
      onClose={onClose}
      // style={{ height: '100%' }}
      // bodyProps={{
      //   style: { height: 'calc(100% - 60px)' },
      //   className: 'flex-column space-between'
      // }}
      actions={
        <Button
          fullWidth
          endIcon={() => (
            <CheckCircle color="primary" style={{ marginLeft: 8 }} />
          )}
          onClick={() => {
            if (setStorageTableStrs) setStorageTableStrs(currentTable);
            handleSubmit();
          }}
          variant="outlined"
          style={{ backgroundColor: "var(--color-bg)", marginTop: 12 }}
        >
          Save
        </Button>
      }
    >
      <FirstStep
        reservation={editReservation}
        onReservationChange={seteditReservation}
        lightReservation={lightReservation}
        {...{
          settings,
          restaurantId,
          occassions,
          uid,
          currentTable,
          setCurrentTable,
        }}
      />
      {useSignature && (
        <SignatureDropdown
          value={editReservation.waiterSignature}
          onChange={(waiterSignature) =>
            seteditReservation((nR) =>
              nR !== null ? { ...nR, waiterSignature } : null
            )
          }
        />
      )}
    </Sidebar>
  );
};

export default EditReservationSidebar;
