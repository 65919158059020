import React from "react";
import useCollection from "./useCollection";

function useShiftComment<T = any>(date: string, restaurantId: string | null) {
  return useCollection<T>(`restaurants/${restaurantId ?? ""}/shiftComments`, {
    filter: [["date", "==", date]],
  });
}

export default useShiftComment;
