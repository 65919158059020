import React, { useMemo, useState } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';

import {
  Column,
  Table as ReactTable,
  PaginationState,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  ColumnDef,
  OnChangeFn,
  flexRender,
} from '@tanstack/react-table';
import { AddOnPurchase } from '../AddOnDetails';
import useDocument from 'CustomHooks/useDocument';
import { Reservation } from 'gastronaut-shared/types/helper/reservations';
import IconButton from 'Components/Atoms/IconButton';
import { HighlightOff } from '@material-ui/icons';
import Tooltip from 'Components/Atoms/Tooltip';
import { useRouter } from 'CustomHooks/useRouter';
import axios from 'axios';
import useAlert from 'CustomHooks/useAlert';
import useToast from 'CustomHooks/useToast';
import { Severity } from 'Contexts/RestaurantContext';
import { a } from 'react-spring';
import Modal from 'Components/Molecules/Modal';
import Checkbox from 'Components/Atoms/Checkbox';
import Typography from 'Components/Atoms/Typography';
import Button from 'Components/Atoms/Button';

function toDateFormat(date: string | number, withTime = false) {
  let time = '';

  if (typeof date === 'number') {
    time = new Date(date).toLocaleTimeString('de-DE').slice(0, 5);
    date = new Date(date).toISOString().slice(0, 10);
  }

  const [year, month, day] = date.split('-');

  if (parseInt(year) !== new Date().getFullYear()) {
    return `${day}.${month}.${year.slice(2)}`;
  }

  return withTime ? `${day}.${month} ${time}` : `${day}.${month}`;
}

function getColor(status: Reservation['status'], done: boolean) {
  if (done) {
    return {
      background: 'var(--color-primary-2)',
      color: 'white',
    };
  }
  if (status === 'failed') {
    return {
      background: 'var(--color-tertiary)',
      color: 'white',
    };
  } else {
    return {
      background: 'var(--color-secondary)',
      color: 'white',
    };
  }
}

function toCurrencyString(value: number) {
  if (value.toLocaleString) {
    return value.toLocaleString('de-DE', {
      style: 'currency',
      currency: 'EUR',
    });
  } else {
    return value.toFixed(2).replace('.', ',') + '€';
  }
}

export type PurchaseTableProps = {
  data: AddOnPurchase[];
  total: number;
  addOns: number;
};

const ReservationSnippet = ({ id }: { id: string }) => {
  const [reservation] = useDocument<Reservation>('requestsV03', id);

  const router = useRouter();

  if (reservation.loading) {
    return <>Loading...</>;
  } else if (reservation.error) {
    return <>{reservation.error}</>;
  } else if (reservation.data === null) {
    return <>No Reservation</>;
  }

  return (
    <div>
      <Box
        className="reservationSnippet"
        style={getColor(reservation.data.status, !!reservation.data.done)}
        onClick={() =>
          router.push(
            `/${reservation.data?.restaurant}/reservations/${reservation?.data?.date}/tablePlan?reservationId=${id}`
          )
        }
      >
        <span>{reservation.data.guest.name}</span>
        <span>{toDateFormat(reservation.data.date)}</span>
        <span>{reservation.data.time}</span>
        <span>{reservation.data.guests} P</span>
      </Box>
    </div>
  );
};

const PurchaseTable: React.FC<PurchaseTableProps> = ({
  data,
  total,
  addOns,
}) => {
  const [refunded, setrefunded] = useState<string[]>([]);
  const [refundModal, setRefundModal] = useState<
    (AddOnPurchase & { cartItems?: string[] }) | null
  >(null);

  const alert = useAlert();
  const toast = useToast();

  const refundPayment = (id: string, cartItems: string[], description = '') => {
    axios
      .post(
        `https://europe-west3-schillingroofbarhd.cloudfunctions.net/experienceAPI/refundPayment/${id}`,
        {
          cartItems,
          description,
        }
      )
      .then((x) => {
        if (x.data.success) {
          setrefunded((r) => [...r, ...cartItems.map((x) => `${id}-${x}`)]);
          toast(
            x.data.refundedAmount.toFixed(2).replace('.', ',') +
              '€ wurden erfolgreich erstattet',
            Severity.SUCCESS
          );
        }
      })
      .catch((err) =>
        alert({
          title: 'Es ist ein Fehler aufgetreten',
          description:
            'Folgender Fehler wurde angezeigt: ' +
            (err?.response?.data?.message ?? err.message ?? '').replace(
              '_',
              ''
            ),
          onSubmit: () => {},
          actions: [
            {
              id: '',
              onClick: () => {},
              label: 'Okay',
            },
          ],
        })
      )
      .finally(() => {
        setRefundModal(null);
      });
  };

  const dataWithNewlyRefunded = useMemo(() => {
    return data.map((x) => {
      const refundedAddOns = refunded
        .filter((y) => y.startsWith(x.id))
        .map((y) => y.replace(`${x.id}-`, ''));

      return {
        ...x,
        description: x.description.map((a) => ({
          ...a,
          refunded: a.refunded || refundedAddOns.includes(a.id),
        })),
      };
    });
  }, [data, refunded]);

  const columns = React.useMemo<ColumnDef<AddOnPurchase, any>[]>(
    () => [
      {
        header: 'Code',
        cell: (info) => '#' + info.getValue(),
        accessorKey: 'customId',
      },
      {
        header: 'Reservierung',
        accessorKey: 'reservationId',
        cell: (info) => <ReservationSnippet id={info.getValue()} />,
      },
      {
        header: 'Erstellt am',
        cell: (info) => toDateFormat(info.getValue(), true),
        accessorKey: 'createdAt',
      },
      {
        header: 'Summe',
        footer: () => toCurrencyString(total),
        accessorFn: (row) => (
          <span>
            {!!row.refundedAmount && (
              <>{toCurrencyString(row.total - row.refundedAmount)} </>
            )}
            <span
              style={
                row.status === 'refunded' || !!row.refundedAmount
                  ? { textDecoration: 'line-through' }
                  : {}
              }
            >
              {toCurrencyString(row.total)}
            </span>{' '}
            {row.serviceFee ? `(+${toCurrencyString(row.serviceFee)})` : ''}
          </span>
        ),
        cell: (info) => info.getValue(),
      },
      {
        header: 'Add Ons',
        footer: (props) => `${addOns} Add Ons`,
        accessorFn: (row) =>
          row.addOns.length < 10 ? (
            <p
              dangerouslySetInnerHTML={{
                __html: row.description
                  .map((x) =>
                    !x.refunded
                      ? `<strong>${x.text1}</strong> ${
                          x.detailedText || x.text2
                            ? `(${x.detailedText || x.text2})`
                            : ''
                        }`
                      : `<span style="text-decoration:line-through;"><strong>${
                          x.text1
                        }</strong> ${
                          x.detailedText || x.text2
                            ? `(${x.detailedText || x.text2})`
                            : ''
                        }</span>`
                  )
                  .join('<br/>'),
              }}
            />
          ) : (
            <Tooltip
              content={
                <p>
                  {row.description.map((x) => (
                    <React.Fragment key={x.id}>
                      {`${x.text1} ${x.text2 ? `(${x.text2})` : ''}`}
                      <br />
                    </React.Fragment>
                  ))}
                </p>
              }
            >
              <span>{row.addOns.length} Add Ons</span>
            </Tooltip>
          ),
        cell: (info) => info.getValue(),
      },
      {
        header: ' ',
        cell: (info) => (
          <>
            {info.row.original.status !== 'refunded' && (
              <IconButton
                onClick={() => setRefundModal(info.row.original)}
                size="x-small"
              >
                <HighlightOff />
              </IconButton>
            )}
          </>
        ),
      },
    ],
    [dataWithNewlyRefunded, total, addOns]
  );

  const table = useReactTable({
    data: dataWithNewlyRefunded,
    columns,
    // Pipeline
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel({
      initialSync: true,
    }),
    //
    debugTable: true,
    initialState: {
      pagination: {
        pageSize: 10,
        pageIndex: 0,
      },
    },
  });

  return (
    <Box className="purchaseTable">
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {/* {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} table={table} />
                          </div>
                        ) : null} */}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((footer) => {
                return (
                  <th key={footer.id} colSpan={footer.colSpan}>
                    {footer.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          footer.column.columnDef.footer,
                          footer.getContext()
                        )}
                        {/* {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} table={table} />
                          </div>
                        ) : null} */}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </tfoot>
      </table>
      <div className="pagination">
        <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {'<<'}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<'}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>'}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {'>>'}
        </button>
        <span className="flex items-center gap-1">
          <div style={{ marginRight: 2 }}>Seite </div>
          <strong>
            {table.getState().pagination.pageIndex + 1} von{' '}
            {table.getPageCount()}
          </strong>
        </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize} pro Seite
            </option>
          ))}
        </select>
        <div>{data.length} Verkäufe</div>
      </div>
      <Modal
        open={!!refundModal}
        onClose={() => setRefundModal(null)}
        title="Rückerstattung"
      >
        {!!refundModal && (
          <>
            <Typography variant="text-2">
              Markiere alle Add Ons die zurückerstattet werden sollen
            </Typography>
            {refundModal.description.map((r) => (
              <Checkbox
                key={r.id}
                checked={r.refunded || refundModal.cartItems?.includes(r.id)}
                disabled={r.refunded}
                onClick={() =>
                  !r.refunded &&
                  setRefundModal((m) =>
                    !!m
                      ? {
                          ...m,
                          cartItems: m.cartItems?.includes(r.id)
                            ? m.cartItems?.filter((x) => x !== r.id) ?? []
                            : [...(m?.cartItems ?? []), r.id],
                        }
                      : null
                  )
                }
                label={r.text1}
              />
            ))}
            <Button
              fullWidth
              disabled={!refundModal?.cartItems?.length}
              variant="primary"
              onClick={() =>
                refundPayment(refundModal.id, refundModal.cartItems ?? [])
              }
            >
              Erstatten (Nicht wiederufbar)
            </Button>
          </>
        )}
      </Modal>
    </Box>
  );
};

export default PurchaseTable;
