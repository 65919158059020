import Box from "Components/Atoms/Box";
import Typography from "Components/Atoms/Typography";
import React from "react";
import { Date as DateType, OffersShift } from "types/shifts";
import { blockToTime, getTime } from "utils/helper";
import dayjs from "dayjs";

type DetailsTabProps = {
  currentShift: OffersShift;
  date: DateType<OffersShift>;
};

const DetailsTab: React.FC<DetailsTabProps> = ({ currentShift }, date) => {
  const statusHandler = () => {
    if (
      Date.now() > getTime(date.date, currentShift?.close, currentShift?.start)
    )
      return "Done";
    if (currentShift?.edited) return "Edited";
    if (currentShift?.regular) return "Standard";
    else return "Special";
  };

  return (
    <Box className="details-container">
      <div className="details-row">
        <Typography variant="text-3" translation="settings">
          Status
        </Typography>
        <Typography variant="text-3" color="subdued" translation="settings">
          {statusHandler()}
        </Typography>
      </div>
      <div className="details-row">
        <Typography variant="text-3" translation="settings">
          Date
        </Typography>
        <Typography variant="text-3" color="subdued">
          {dayjs(date.date).format("DD.MM.YYYY")}
        </Typography>
      </div>
      <div className="details-row">
        <Typography variant="text-3" translation="calendar">
          Start Time
        </Typography>
        <Typography variant="text-3" color="subdued">
          {blockToTime(currentShift?.start ?? 64)}
        </Typography>
      </div>
      <div className="details-row">
        <Typography variant="text-3" translation="calendar">
          Close Time
        </Typography>
        <Typography variant="text-3" color="subdued">
          {blockToTime(currentShift?.close ?? 64)}
        </Typography>
      </div>
      <div className="details-row">
        <Typography variant="text-3" translation="calendar">
          Concerned Items
        </Typography>
        <Typography variant="text-3" color="subdued">
          {currentShift?.offers?.length ?? 0}
        </Typography>
      </div>
      {currentShift?.ad && (
        <div className="details-row">
          <Typography variant="text-3" translation="calendar">
            Advert
          </Typography>
          <Typography variant="text-3" color="subdued">
            {currentShift?.ad.translations.de.title}
          </Typography>
        </div>
      )}
    </Box>
  );
};

export default DetailsTab;
