import React, { useState, useEffect, useRef } from "react";
import { db } from "utils/firebase";
import { daysInFuture } from "utils/helper";
import useCollection from "./useCollection";

const usePrinter = (restaurantId: string | null) => {
  const currentTime = useRef(Date.now());

  const [collectionPath, setcollectionPath] = useState<string | undefined>();

  const endOfDay = useRef(new Date(daysInFuture(1)).getTime());

  const [outstandingPrintableItems] = useCollection(collectionPath, {
    filter: [
      ["printAt", ">", currentTime.current],
      ["printAt", "<", endOfDay.current],
      ["printed", "==", false],
    ],
  });

  useEffect(() => {
    if (restaurantId) {
      db.collection(`restaurants/${restaurantId}/settings`)
        .doc("printer")
        .get()
        .then((doc) => {
          if (doc.exists) {
            setcollectionPath(`restaurants/${restaurantId}/printableItems`);
          } else {
            console.log("Not found");
            setcollectionPath(undefined);
          }
        });
    }
  }, [restaurantId]);

  const outstanding = outstandingPrintableItems?.data?.length || 0;

  const printOutstanding = async () => {
    const ref = outstandingPrintableItems.ref;

    currentTime.current = Date.now();

    await Promise.all(
      outstandingPrintableItems.data?.map((doc) =>
        ref.doc(doc.id).update({ printAt: currentTime.current - 5 })
      )
    );
  };

  return !collectionPath
    ? null
    : {
        outstanding,
        printOutstanding,
      };
};

export default usePrinter;
