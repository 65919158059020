import React from "react";
import useLanguage from "CustomHooks/useLanguage";
import Box from "../../../../../Components/Atoms/Box";
import Typography from "Components/Atoms/Typography";
import { EventDescription } from "App/Calendar/Components/EventSettings";
import { toCurrencyString } from "utils/helper";
import { ExtendedEvent } from "..";

const EventCard: React.FC<{
  event: ExtendedEvent;
  onClick: () => void;
  selected?: boolean;
}> = ({ event, onClick, selected = false }) => {
  const { language } = useLanguage();

  const tickets = event.tickets;

  const description = EventDescription(event, language);

  const soldTickets = tickets?.filter(
    (t) => t.status === "succedded" || t.status === "processing"
  );

  const revenue = soldTickets?.reduce((acc, cV) => acc + cV.amount, 0);

  const soldTicketCount =
    soldTickets?.reduce((acc, cV) => acc + cV.guests, 0) ?? 0;

  return (
    <Box
      style={{
        padding: 10,
        borderRadius: 4,
        marginBottom: 8,
        background: selected
          ? "var(--color-disabled)"
          : "var(--color-btn-default)",
      }}
      data-event={JSON.stringify({ id: event.id, shift: event.shift })}
      onClick={onClick}
    >
      <Typography
        block
        color="textPrimary"
        variant="h5"
        style={{ marginBottom: 3 }}
      >
        {event?.title?.de || "Kein Event Name"}
      </Typography>
      <Typography
        component="p"
        color="subdued"
        variant="text-3"
        style={{
          justifySelf: "flex-start",
          alignSelf: "flex-start",
          marginBottom: 6,
        }}
      >
        {description}
      </Typography>
      {!event?.active && (
        <Typography
          component="p"
          color="error"
          variant="text-3"
          translation="calendar"
          style={{
            justifySelf: "flex-start",
            alignSelf: "flex-start",
            marginBottom: 6,
          }}
        >
          Event is deactivated
        </Typography>
      )}
      <Typography
        component="p"
        variant="text-3"
        style={{
          justifySelf: "flex-start",
          alignSelf: "flex-start",
          marginBottom: 2,
        }}
      >
        Tickets: {soldTicketCount} (
        {toCurrencyString(revenue ?? 0, "€", false, 1)})
      </Typography>
    </Box>
  );
};

export default EventCard;
