import React, { createContext, useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import de from 'translations/de';
import en from 'translations/en';
import fr from 'translations/fr';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import useLocalStorageState from 'CustomHooks/useLocalStorageState';

export const TEST_TRANSLATIONS =
  document.location.host.includes('localhost') && false;

const defaultLanguage =
  JSON.parse(window.localStorage.getItem('language') || '{}')?.value || 'de';

function testTranslations(
  lang: Record<string, Record<string, Record<string, string>>>
) {
  if (!TEST_TRANSLATIONS) return lang;

  let obj: Record<string, Record<string, string>> = {};

  let cats = Array.from(
    new Set([
      ...Object.keys(lang.de),
      ...Object.keys(lang.fr),
      ...Object.keys(lang.en),
    ])
  );

  cats.forEach((cat) => {
    let keys = Array.from(
      new Set([
        ...Object.keys(lang.de[cat] ?? {}),
        ...Object.keys(lang.fr[cat] ?? {}),
        ...Object.keys(lang.en[cat] ?? {}),
      ])
    );

    obj[cat] = keys.reduce(
      (acc, cV) => ({ ...acc, [cV]: `${cat.slice(0, 2)}_${cV}` }),
      {} as Record<string, string>
    );
  });

  return {
    de: obj,
    en: obj,
    fr: obj,
  };
}

dayjs.extend(relativeTime);

type Language = {
  id: string;
  label: string;
};

interface Props {
  language?: string;
  children: any;
}

const LANGUAGES: Language[] = [
  {
    id: 'de',
    label: 'Deutsch',
  },
  {
    id: 'en',
    label: 'English',
  },
  {
    id: 'fr',
    label: 'Français',
  },
];

i18next.init({
  interpolation: { escapeValue: false },
  lng: defaultLanguage,
  resources: testTranslations({
    en,
    de,
    fr,
  }),
  nsSeparator: '_',
  keySeparator: '__',
});

console.log('CCC', { de, en, fr });

export const LanguageContext = createContext({
  language: defaultLanguage,
  LANGUAGES,
  changeLanguage: (id: string) => {},
  dayjs,
});

const LanguageContextProvider = (props: Props) => {
  const [language, setlanguage] = useLocalStorageState('language', 'de');

  useEffect(() => {
    changeLanguage(props.language || '');
  }, [props.language]);

  const changeLanguage = (id: string) => {
    if (LANGUAGES.find((l) => l.id === id)) {
      setlanguage(id);
      i18next.changeLanguage(id);
    }
  };

  dayjs.locale(language);

  return (
    <LanguageContext.Provider
      value={{ language, LANGUAGES, changeLanguage, dayjs }}
    >
      <I18nextProvider i18n={i18next}>{props.children}</I18nextProvider>
    </LanguageContext.Provider>
  );
};

export default LanguageContextProvider;
