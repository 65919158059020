import React, { useEffect, useLayoutEffect, useMemo } from "react";

const CurrentTime = ({
  currentTime,
  associatedTableId,
  start,
  trackRect = null,
  id,
  index,
}: {
  currentTime: number;
  associatedTableId: string;
  start: number;
  trackRect: DOMRect | null;
  id: string;
  index: number;
}) => {
  const style: React.CSSProperties = useMemo(() => {
    if (!trackRect) return { display: "none" };

    let table = document.getElementById(associatedTableId);

    if (!table) {
      return { display: "none" };
    }

    let tableRect = table.getBoundingClientRect();

    let top = tableRect.top - trackRect.top - 10,
      height = tableRect.height + 10,
      left = 85 + (currentTime - start) * 60;

    setTimeout(() => {
      let container: any = document.querySelector(".timeTable-container");

      if (container) {
        container.scroll({
          top: 0,
          left: left - container.offsetWidth / 2,
          behaviour: "smooth",
        });
      }
    }, 1000);

    return {
      top,
      height,
      left,
    };
  }, [currentTime, associatedTableId, start, trackRect]);

  useLayoutEffect(() => {
    if (id && index === 0) {
      let element = document.getElementById(id);

      console.log({ element });

      if (element) {
        var elementPosition = element.getBoundingClientRect().left;
        var offsetPosition = elementPosition - 600;

        document.getElementById("timeTableCard")?.scrollTo({
          left: offsetPosition,
          behavior: "auto",
        });

        console.log({
          offsetPosition,
          el: document.getElementById("timeTableCard"),
        });
      }
    }
  }, [style, index]);

  return <div className="currentTime" style={style} id={id} />;
};

export default CurrentTime;
