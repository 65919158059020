import React, { useContext, useMemo } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import { ReservationContext } from 'Contexts/ReservationContext';
import { LanguageContext } from 'Contexts/LanguageContext';
import { blockToTime, classHelper } from 'utils/helper';
import { ErrorRounded, InfoRounded } from 'Components/Atoms/Icons';
import Typography from 'Components/Atoms/Typography';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router';
import { slateToHtml } from 'Components/Atoms/RichTextField';
import { Event, EventTypes } from 'types/event';
import useRestaurant from 'CustomHooks/useRestaurant';

export type ShiftMessageProps = {};

export const capitalizeString = (str: string = '') =>
  str
    .split(' ')
    .map((s) => (s.length ? s[0].toLocaleUpperCase() + s.substr(1) : ''))
    .join(' ');

export const commaAndReducer = (arr: string[]) =>
  arr.reduce((acc: string, cV: string, i) => {
    if (i === 0) {
      return capitalizeString(cV);
    } else if (i === arr.length - 1) {
      return acc + ' & ' + capitalizeString(cV);
    } else {
      return acc + ', ' + capitalizeString(cV);
    }
  }, '');

const ShiftMessage: React.FC<ShiftMessageProps> = ({}) => {
  const { shifts, shiftComments, date, occassions, floorPlanProps } =
    useContext(ReservationContext);
  const { language } = useContext(LanguageContext);

  const { experimentalFlags } = useRestaurant();

  console.log(shiftComments);

  const history = useHistory();

  const isGerman = language === 'de';

  const message: null | {
    title: string;
    message: string;
    learnMore?: boolean;
    error?: boolean;
  } = useMemo(() => {
    if (!shifts?.length) {
      return {
        title: isGerman ? 'Keine Schichten' : 'No Shifts',
        message: isGerman
          ? 'Es gibt keine Schichten an diesem Tag'
          : 'There are no Shifts on this Day',
        learnMore: false,
      };
    }

    let hasEvent = shifts.find(
      (s) => s.active && !s.closed && s?.additional?.eventV02?.active
    );

    let closedOccassion = shifts.find((s) => s.closed && s.occassions.length);

    if (closedOccassion && !hasEvent && !shiftComments.length) {
      let occassionStr = commaAndReducer(
        closedOccassion.occassions.map(
          (o) => occassions?.find((o1) => o1.id === o)?.title ?? o
        )
      );

      if (
        (closedOccassion.start === 0 && closedOccassion.close === 96) ||
        closedOccassion.allDay
      ) {
        return {
          title: closedOccassion.name ?? '',
          message: isGerman
            ? `Keine Resas für ${occassionStr} annehmen`
            : `No Resas for ${occassionStr}`,
          learnMore: true,
          error: true,
        };
      } else {
        let start = blockToTime(closedOccassion.start);
        let close = blockToTime(closedOccassion.close);

        return {
          title: `${closedOccassion.name} (${start} - ${close})`,
          message: isGerman
            ? `Keine Resas für ${occassionStr} annehmen`
            : `No Resas for ${occassionStr}`,
          learnMore: true,
          error: true,
        };
      }
    }

    let closedSpace = shifts.find((s) => s.closed && s.spaces.length);

    if (closedSpace && !hasEvent && !shiftComments.length) {
      let spaceStr = commaAndReducer(
        closedSpace.spaces.map(
          (s) =>
            floorPlanProps.floorPlan?.spaces?.find((s1) => s1.id === s)?.name ??
            s
        )
      );

      console.log(
        spaceStr,
        closedSpace.spaces.map(
          (s) =>
            floorPlanProps.floorPlan?.spaces?.find((s1) => s1.id === s)?.name ??
            s
        )
      );

      if (
        (closedSpace.start === 0 && closedSpace.close === 96) ||
        closedSpace.allDay
      ) {
        return {
          title: closedSpace.name ?? '',
          message: isGerman
            ? `Bereich ${spaceStr} ist blockiert`
            : `Spaces ${spaceStr} are blocked`,
          learnMore: true,
          error: true,
        };
      } else {
        let start = blockToTime(closedSpace.start);
        let close = blockToTime(closedSpace.close);

        return {
          title: `${closedSpace.name} (${start} - ${close})`,
          message: isGerman
            ? `Bereich ${spaceStr} ist blockiert`
            : `Spaces ${spaceStr} are blocked`,
          learnMore: true,
          error: true,
        };
      }
    }

    let closedWithoutOccOrSpace = shifts.find(
      (s) => s.closed && !s.spaces.length && !s.occassions.length
    );

    if (closedWithoutOccOrSpace && !shiftComments.length && !hasEvent) {
      let hasComment = shiftComments.find(
        (s) => s.shiftId === closedWithoutOccOrSpace?.id
      );

      if (
        (closedWithoutOccOrSpace.start === 0 &&
          closedWithoutOccOrSpace.close === 96) ||
        closedWithoutOccOrSpace.allDay
      ) {
        return {
          title: closedWithoutOccOrSpace.name ?? '',
          message: hasComment
            ? isGerman
              ? 'Kommentar verfügbar'
              : 'Comment available'
            : isGerman
            ? 'Ganzer Tag'
            : 'All Day',
          learnMore: true,
          error: false,
        };
      } else {
        let start = blockToTime(closedWithoutOccOrSpace.start);
        let close = blockToTime(closedWithoutOccOrSpace.close);

        return {
          title: `${closedWithoutOccOrSpace.name} (${start} - ${close})`,
          message: hasComment
            ? isGerman
              ? 'Kommentar verfügbar'
              : 'Comment available'
            : isGerman
            ? ''
            : '',
          learnMore: true,
          error: false,
        };
      }
    }

    if (shiftComments.length) {
      const shiftIds = shifts.map((s) => s.id);

      let message = shiftComments
        .filter((s) => shiftIds.includes(s.shiftId))
        .reduce((acc, cV) => {
          return (
            acc +
            slateToHtml(cV.slateDescendants)
              .replace(/<[^>]p>/gm, ' ')
              .replace(/<[^>]*>/gm, '')
              .replace(/&gt;/gm, '')
          );
        }, '');

      if (!message || !!experimentalFlags?.data?.disableShiftCommentAtBottom)
        return null;

      return {
        title: isGerman ? 'Kommentare verfügbar' : 'Comments available',
        message,
        learnMore: message.length >= 120,
        error: false,
      };
    }

    if (hasEvent) {
      let event = hasEvent?.additional?.eventV02 as Event;

      let title = (isGerman ? event?.title?.de : event?.title?.en) ?? 'Event';

      let message = '';

      if (event.type === EventTypes.DINNER_TICKET) {
        message = 'Dinner Event';
      } else if (event.type === EventTypes.MINIMUM_CONSUMPTION) {
        let rules = '';

        if (event.rules?.minGuests) {
          rules = isGerman
            ? ` ab ${event.rules.minGuests} Pax`
            : ` for ${event.rules.minGuests} Guests and more`;
        }

        message = isGerman
          ? `Mindestverzehr ${
              event.amount?.toFixed(2)?.replace('.', ',') ?? '-'
            }€ ${rules}`
          : `Minimum Consumption ${
              event.amount?.toFixed(2)?.replace('.', ',') ?? '-'
            }€ ${rules}`;
      }

      return {
        title: `${title} (${blockToTime(hasEvent.start)} - ${blockToTime(
          hasEvent.close
        )})`,
        message,
        learnMore: false,
        error: false,
      };
    }

    return null;
  }, [shifts, shiftComments, date, language, experimentalFlags.data]);

  if (!message) return <></>;

  return (
    <Box className={classHelper(['shiftMessage', message.error && 'error'])}>
      {message.error ? (
        <InfoRounded color="inherit" className="icon" />
      ) : (
        <ErrorRounded color="inherit" className="icon" />
      )}
      <Box className="inner">
        <Typography variant="h5" style={{ marginBottom: 1 }} translation={null}>
          {message.title}
        </Typography>
        <Box>
          <Typography variant="text-3" translation={null}>
            {message.message.slice(0, 120)}
            {message.learnMore && '...'}
          </Typography>
          {message.learnMore && (
            <a
              href="#"
              onClick={() =>
                history.push(
                  window.location.pathname.replace('tablePlan', 'statistics')
                )
              }
            >
              <Trans i18nKey="common_Learn More">Learn More</Trans>
            </a>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ShiftMessage;
