import React from 'react';

const PinReservation = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36 72C55.8823 72 72 55.8823 72 36C72 16.1177 55.8823 0 36 0C16.1177 0 0 16.1177 0 36C0 55.8823 16.1177 72 36 72ZM32.0556 60.3371V44.6628C33.175 44.8816 34.3267 45.0019 35.5 45.0019C36.6733 45.0019 37.825 44.8816 38.9444 44.6628V60.3371C38.9444 60.884 38.8153 61.42 38.5805 61.9023L37.0402 65.0328C36.4051 66.3224 34.5942 66.3224 33.9596 65.0328L32.4193 61.9023C32.18 61.4162 32.0556 60.8804 32.0556 60.3371ZM20 25.7508C20 17.0518 26.9395 10 35.5 10C44.0573 10 51 17.0518 51 25.7509C51 34.4499 44.0605 41.5017 35.5 41.5017C26.9395 41.5017 20 34.4499 20 25.7508ZM28.6111 25.6524C28.6111 21.7935 31.7025 18.652 35.5 18.652C36.4472 18.652 37.2222 17.6724 37.2222 16.8035C37.2222 15.9346 36.452 15.1519 35.5 15.1519C29.8016 15.1519 25.1667 19.8618 25.1667 25.6524C25.1667 26.7134 25.9369 27.4025 26.8889 27.4025C27.8408 27.4025 28.6111 26.6198 28.6111 25.6524Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PinReservation;
