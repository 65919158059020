import { IconButton } from '@material-ui/core';
import { ArrowForward, ArrowBack } from '@material-ui/icons';
import Box from 'Components/Atoms/Box';
import Button from 'Components/Atoms/Button';
import Typography from 'Components/Atoms/Typography';
import ShiftReservation from 'Components/Organisms/ShiftReservationCard/Components/ShiftReservation';
import { ExtendedCall } from 'CustomHooks/useCalls';
import useCollection from 'CustomHooks/useCollection';
import useRestaurant from 'CustomHooks/useRestaurant';
import useSiaContext from 'CustomHooks/useSiaContext';
import { CallEvent, Conversation } from 'gastronaut-shared/types/helper/sia';
import React, { useEffect, useMemo, useState } from 'react';
import QRCode from 'react-qr-code';
import { Reservation } from 'types/reservations';
import { db, FieldValue } from 'utils/firebase';
import { classHelper, dateHelper, reservationToSnippet } from 'utils/helper';

const ConversationEvent: React.FC<{ data: CallEvent }> = ({ data }) => {
  return (
    <div className="callEvent">
      <div>{data.result}</div>
    </div>
  );
};

const ConversationItem: React.FC<{ data: Conversation }> = ({ data }) => {
  return (
    <div className="callConversation">
      {!!data.text && data.text !== 'Anruf gestartet' && (
        <div className="caller">
          <div>{data.text}</div>
        </div>
      )}
      {!!data.response && (
        <div className="callee">
          <div>{data.response}</div>
        </div>
      )}
    </div>
  );
};

const Paginate: React.FC<{
  page: string | null;
  index?: number;
  onNext: () => void;
  onPrevious: () => void;
  count: number;
}> = ({ page, index = 0, onNext, onPrevious, count }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 4,
      }}
    >
      <IconButton size="small" onClick={onNext} disabled={index >= count - 1}>
        <ArrowBack fontSize="small" />
      </IconButton>
      <Typography variant="text-3" textAlign="center">
        {page}
      </Typography>
      <IconButton size="small" onClick={onPrevious} disabled={index <= 0}>
        <ArrowForward fontSize="small" />
      </IconButton>
    </div>
  );
};

const ConversationWrapper: React.FC<{}> = () => {
  const { currentCall } = useSiaContext();
  const [index, setindex] = useState<number>();

  const conversationItems = useMemo(() => {
    if (!currentCall) return [];

    let call =
      index && currentCall
        ? currentCall.calls?.sort(
            (a, b) =>
              (a.id === currentCall.id ? 0 : 1) -
              (b.id === currentCall.id ? 0 : 1)
          )?.[index]
        : currentCall;

    if (!call) return [];

    const events = call.events.map((e) => ({
      ts: e.ts,
      type: 'event',
      data: e,
    }));

    const conversations = call.conversations.map((c) => ({
      ts: c.ts,
      type: 'conversation',
      data: c,
    }));

    return [...events, ...conversations].sort((a, b) => a.ts - b.ts);
  }, [currentCall, index]);

  useEffect(() => {
    setindex(undefined);
  }, [currentCall]);

  const { callCount, page } = useMemo(() => {
    if (!currentCall) return { page: null, callCount: 0 };

    let call =
      index && currentCall
        ? currentCall.calls?.sort(
            (a, b) =>
              (a.id === currentCall.id ? 0 : 1) -
              (b.id === currentCall.id ? 0 : 1)
          )?.[index]
        : currentCall;

    if (!call) return { page: null, callCount: 0 };

    return {
      page: call.time,
      callCount: currentCall.calls?.length ?? 0,
    };
  }, [currentCall, index]);

  return (
    <>
      {callCount > 1 && (
        <Paginate
          {...{ index, page, count: callCount }}
          onNext={() => setindex((x) => (x ? x + 1 : 1))}
          onPrevious={() => setindex((x) => (x ? x - 1 : 0))}
        />
      )}
      {conversationItems.map((i) =>
        i.type === 'event' ? (
          <ConversationEvent key={i.ts} data={i.data as CallEvent} />
        ) : (
          <ConversationItem key={i.ts} data={i.data as Conversation} />
        )
      )}
    </>
  );
};

const UpcomingReservations: React.FC<{
  callerId: string;
  guestId?: string;
  date?: string;
}> = ({ callerId, guestId, date = dateHelper() }) => {
  const { restaurantId } = useRestaurant();

  const [reservationsByPhone] = useCollection<Reservation>('requestsV03', {
    filter: [
      ['guest.phone', '==', callerId.replace('+', '')],
      ['date', '>=', date],
      ['restaurant', '==', restaurantId],
    ],
  });

  const [reservationsByGuestId] = useCollection<Reservation>('requestsV03', {
    filter: [
      ['guest.guestId', '==', guestId || 'no guestid was provided'],
      ['date', '>=', date],
      ['restaurant', '==', restaurantId],
    ],
  });

  const reservations = useMemo(() => {
    return Array.from(
      new Set([...reservationsByPhone?.data, ...reservationsByGuestId?.data])
    ).sort((a, b) => a.date.localeCompare(b.date));
  }, [reservationsByPhone, reservationsByGuestId]);

  return (
    <div>
      {!!reservations.length && (
        <Typography variant="text-4" className="mg-bt-xs" block>
          Reservierungen:
        </Typography>
      )}
      {reservations.map((res) => (
        <ShiftReservation
          key={res.id}
          reservation={{ ...reservationToSnippet(res), date: res.date }}
          clickable
          restaurantId={res.restaurant}
          hideDivider
          noPadding
        />
      ))}
    </div>
  );
};

const History: React.FC<{ calls: ExtendedCall[] }> = ({ calls = [] }) => {
  const callMap = useMemo(() => {
    return calls.reduce((acc, cV) => {
      let [date, time] = cV.dateString.split(', ');

      const { id, statusText, error } = cV;

      return {
        ...acc,
        [date]: [...(acc?.[date] ?? []), { id, statusText, error, time }],
      };
    }, {} as Record<string, { id: string; time: string; statusText: string; error: boolean }[]>);
  }, [calls]);

  // if (calls.length < 2) return <></>;
  return (
    <div className="mg-bt-sm">
      <Typography variant="text-4">Anrufe:</Typography>
      {Object.entries(callMap).map(([date, calls]) => {
        return (
          <div key={date}>
            <Typography variant="text-4">{date}</Typography>
            {calls.map((call) => (
              <div
                key={call.id}
                className={classHelper([
                  'flex flex-start',
                  call.error && 'error',
                ])}
              >
                <Typography variant="text-3" style={{ minWidth: 45 }}>
                  {call.time}
                </Typography>
                <Typography variant="text-3">{call.statusText}</Typography>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};

const CurrentCall = () => {
  const {
    currentCall,
    onChangeCurrentCall,
    currentCallPage,
    setcurrentCallPage,
  } = useSiaContext();

  const markAsDone = () => {
    if (!currentCall) return;
    const ref = db.collection('calls').doc(currentCall?.id ?? '');

    ref.update({
      events: FieldValue.arrayUnion({
        ts: Date.now(),
        result: 'Als erledigt markiert',
        success: true,
        id: 'markAsDone',
      }),
      updatedAt: Date.now(),
    });
  };

  if (!currentCall) return <></>;

  return (
    <Box className="currentCall paper" outline elevation={4}>
      <Box className="flex space-between mg-bt-sm">
        <div>
          <Typography block variant="h5">
            {currentCall.name}
          </Typography>
          <Typography block variant="text-4">
            {currentCall.statusText}
          </Typography>
        </div>
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            onChangeCurrentCall(null);
          }}
        >
          <ArrowForward />
        </IconButton>
      </Box>
      {currentCallPage === 'info' && (
        <>
          <Box style={{ flexGrow: 100, overflow: 'scroll' }}>
            {!!currentCall.calls && <History calls={currentCall.calls} />}
            <UpcomingReservations
              callerId={currentCall.callerId}
              guestId={currentCall?.guest?.guestId}
              date={currentCall?.date as unknown as string}
            />
          </Box>
          {currentCall.error && (
            <Box className="callActions oneButton" style={{ marginBottom: 8 }}>
              <Button onClick={markAsDone} variant="default">
                Mark as Done
              </Button>
            </Box>
          )}
          <Box className="callActions">
            <Button
              onClick={() => setcurrentCallPage('conversation')}
              variant="default"
              disabled={!currentCall.conversations.length}
            >
              Conversation
            </Button>
            <Button
              onClick={() => setcurrentCallPage('qr-code')}
              variant="default"
            >
              Call back
            </Button>
          </Box>
        </>
      )}
      {currentCallPage === 'conversation' && (
        <>
          <Box
            className="conversationWrapper"
            style={{ flexGrow: 100, overflow: 'scroll' }}
          >
            <ConversationWrapper />
          </Box>
          <Box className="callActions oneButton">
            <Button
              onClick={() => setcurrentCallPage('info')}
              variant="default"
            >
              Go Back
            </Button>
          </Box>
        </>
      )}
      {currentCallPage === 'qr-code' && (
        <>
          <Box
            className="flex"
            style={{
              flexGrow: 100,
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <QRCode value={'tel:' + currentCall.callerId} size={128} />
            <Box style={{ width: '80%', marginTop: 8 }}>
              <Typography textAlign="center" component="p" variant="text-3">
                Scane den QR-Code mit dem Handy um den Gast anzurufen oder
                benutze die Nummer
              </Typography>
              <Typography
                textAlign="center"
                variant="text-2"
                style={{
                  display: 'block',
                  fontWeight: 'bolder',
                }}
              >
                {currentCall.callerId}
              </Typography>
            </Box>
          </Box>
          <Box className="callActions oneButton">
            <Button
              onClick={() => setcurrentCallPage('info')}
              variant="default"
            >
              Go Back
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default CurrentCall;
