import React, { useState, useEffect } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import { Tab } from 'Components/Atoms/Tabs';
import TabCard from '../TabCard';
import IconButton from 'Components/Atoms/IconButton';
import { Close } from 'Components/Atoms/Icons';
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill';
import { Translations } from 'utils/types';

export type SidebarProps = {
  title: React.ReactNode;
  titleTranslation?: Translations;
  onClose?: () => void;
  actions?: React.ReactNode;
  children: React.ReactNode;
  tabs?: Tab[];
  currentTab?: string;
  onTabChange?: (id: string | number | null) => void;
  id: string;
  className?: string;
  width?: string | number;
  deductFromHeight?: number;
  closable?: boolean;
  hideOverflowGradient?: 'top' | 'bottom' | 'both' | null;
};

const Sidebar: React.FC<SidebarProps> = ({
  title,
  onClose,
  actions,
  children,
  tabs = [],
  currentTab,
  onTabChange,
  id,
  titleTranslation,
  width,
  deductFromHeight = 0,
  className = '',
  closable = true,
  hideOverflowGradient,
}) => {
  const [hasOverflow, setHasOverflow] = useState<{
    bottom: boolean;
    top: boolean;
  }>({ top: false, bottom: false });

  const actionsResize = useResizeDetector();

  const childrenResize = useResizeDetector();

  const handleScrolling = (
    event: React.UIEvent<HTMLDivElement | HTMLLabelElement | HTMLFormElement>
  ) => {
    if (
      event.currentTarget.scrollHeight - event.currentTarget.scrollTop <=
      event.currentTarget.clientHeight + (actionsResize?.height ?? 0)
    ) {
      setHasOverflow({
        top: event.currentTarget.scrollTop > 30,
        bottom: false,
      });
    } else if (event.currentTarget.scrollTop === 0) {
      setHasOverflow({ top: false, bottom: true });
    } else if (
      event.currentTarget.scrollHeight - event.currentTarget.scrollTop >
        event.currentTarget.clientHeight &&
      event.currentTarget.scrollTop > 0
    ) {
      setHasOverflow({ top: true, bottom: true });
    }
  };

  useEffect(() => {
    const container = document.getElementsByClassName(
      'inner-scrollable-container'
    )?.[0];
    if (container && childrenResize.height) {
      const overFlow =
        childrenResize.height <
        container.scrollHeight - (actionsResize?.height ?? 0);
      setHasOverflow({ bottom: overFlow, top: false });
    }
  }, [setHasOverflow, childrenResize.height, actionsResize.height]);

  return (
    <TabCard
      tabs={tabs}
      id={id}
      className={`side-bar corner ${className}`}
      width={width}
      value={currentTab}
      onChange={onTabChange}
      title={title}
      titleTranslation={titleTranslation}
      bodyProps={{
        style: {
          padding: '0px !important',
          position: 'relative',
          height: tabs.length
            ? `calc(100% - ${111 + deductFromHeight}px)`
            : `calc(100% - ${60 + deductFromHeight}px)`,
          maxHeight: tabs.length
            ? `calc(100% - ${111 + deductFromHeight}px)`
            : `calc(100% - ${60 + deductFromHeight}px)`,
        },
        className: 'space-between flex-column',
      }}
      style={{ height: '100%', zIndex: 100 }}
      headerRight={
        onClose &&
        closable && (
          <IconButton size="small" color="subdued" onClick={onClose}>
            <Close />
          </IconButton>
        )
      }
    >
      {hideOverflowGradient !== 'top' &&
        hideOverflowGradient !== 'both' &&
        hasOverflow.top && <div className="topOverflowGradient" />}
      <div
        ref={childrenResize.ref}
        style={{
          maxHeight: `calc(100% - ${
            (actionsResize?.height || 0) - (actions ? 40 : 0)
          }px)`,
          padding: '12px',
        }}
        className="inner-scrollable-container"
        onScroll={handleScrolling}
      >
        {children}
      </div>
      {hideOverflowGradient !== 'bottom' &&
        hideOverflowGradient !== 'both' &&
        hasOverflow.bottom && (
          <div
            className="bottomOverflowGradient"
            style={{
              height: (actionsResize?.height || 0) + (actions ? 50 : 90),
              bottom: actions ? 33 : 0,
            }}
          />
        )}
      {!!actions && (
        <div
          ref={actionsResize.ref}
          style={{
            zIndex: 102,
            padding: 16,
            background: 'var(--color-page)',
            width: '100%',
          }}
        >
          {actions}
        </div>
      )}
    </TabCard>
  );
};

export default Sidebar;
