import { CircularProgress } from '@material-ui/core';
import Box from 'Components/Atoms/Box';
import { Image as ImageIcon } from 'Components/Atoms/Icons';
import React from 'react';
import { noop } from 'utils/helper';

type ImageFieldOneImageProps = {
  loading: boolean;
  previewType?: 'div' | 'img';
  imageStyle?: React.CSSProperties;
  backgroundStyle?: React.CSSProperties;
  imageUrl: string;
  simpleStyle?: boolean;
  openImageModal: (
    onSubmitFunction: (s: string | null | (string | null)[]) => void,
    modalOptions: {
      multiple: boolean;
      id: boolean;
      delete?: boolean;
      search?: string | null;
      value?: null | string | (string | null)[];
    }
  ) => void;
  type?: 'id' | 'url';
  onChange: (val: null | string | (string | null)[]) => void;
  search?: string | null;
};
const ImageFieldOneImage = ({
  loading,
  imageStyle,
  backgroundStyle,
  imageUrl,
  previewType = 'img',
  simpleStyle = false,
  openImageModal,
  type,
  onChange,
  search,
}: ImageFieldOneImageProps) => {
  return (
    <>
      {previewType === 'img' && (
        <Box
          flex
          className="center image-field-box"
          style={{
            ...backgroundStyle,
          }}
          onClick={() => {
            return simpleStyle
              ? openImageModal(onChange, {
                  multiple: false,
                  id: type === 'id',
                  delete: true,
                  search,
                })
              : noop;
          }}
        >
          {loading && <CircularProgress />}
          {!loading && !imageUrl && (
            <ImageIcon
              fontSize={simpleStyle ? 'small' : 'large'}
              style={{ color: 'var(--color-text-subdued)' }}
            />
          )}
          {!loading && !!imageUrl && (
            <img
              src={imageUrl}
              alt={imageUrl}
              className="center image-field-img"
              style={imageStyle}
            />
          )}
        </Box>
      )}
      {previewType === 'div' && (
        <Box
          flex
          className="center image-field-box"
          style={{
            ...imageStyle,
            backgroundImage: `url("${imageUrl}")`,
          }}
        >
          {loading && <CircularProgress />}
          {!loading && !imageUrl && (
            <ImageIcon
              fontSize="large"
              style={{ color: 'var(--color-text-subdued)' }}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default ImageFieldOneImage;
