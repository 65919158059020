import React, { useMemo, useCallback } from 'react';
import { Table, Reservation } from 'types/reservations';
import { ReservationShift } from 'types/shifts';
import { db } from 'utils/firebase';
import { blockToTime, stitchShifts } from 'utils/helper';

const useTableSideBar = (
  currentTable: string | null,
  tables: Table[],
  reservations: Reservation[],
  currentShift: string | null,
  shifts: null | ReservationShift[],
  currentDate: string,
  restaurantId: string
) => {
  const table = useMemo(
    () => tables?.find((t) => t.id === currentTable) || null,
    [currentTable, tables]
  );

  const filteredReservations = useMemo(
    () => reservations.filter((res) => res.tables?.includes(table?.name || '')),
    [table, reservations]
  );

  const blocked = useMemo(
    () => !!filteredReservations?.find((r) => r.blocked),
    [filteredReservations]
  );

  const [start, close] = useMemo(() => {
    let { startStop } = stitchShifts(shifts || [], currentShift);

    let start = startStop[0] || 0;
    let close = startStop[1] || 0;

    return [start - (start % 2), close + (close % 2)];
  }, [shifts, currentShift]);

  const onToggleBlock = useCallback(
    (id: string, name: string) => {
      let block = filteredReservations?.find((r) => r.blocked);

      if (!table) return;

      console.log({ block });

      if (block) {
        db.collection('requestsV03').doc(block.id).delete();
      } else {
        db.collection(`requestsV03`)
          .add({
            guest: {
              email: '',
              phone: '',
              name: 'Blockiert',
              comment: '',
              attr: [],
            },
            source: 'InHouse',
            restaurant: restaurantId,
            space: table?.space,
            date: currentDate,
            tables: [table?.name],
            guests: 0,
            fixed: true,
            time: blockToTime(start),
            reservationLength: close - start,
            createdAt: 10000000000000,
            blocked: true,
            blockedFullShift: true,
            status: 'success',
          })
          .then(() => console.log('block'))
          .catch((e) => console.error('block', e));
      }
    },
    [filteredReservations, table, start, close]
  );

  return {
    blocked,
    reservations: filteredReservations,
    name: table?.name || '',
    id: currentTable || '',
    table,
    start,
    close,
    onToggleBlock,
  };
};

export default useTableSideBar;
