import React, { useContext, useMemo, useState } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import IconButton from 'Components/Atoms/IconButton';
import { NotificationsNone } from 'Components/Atoms/Icons';
import Popover from 'Components/Atoms/Popover';
import { noop } from 'utils/helper';
import Typography from 'Components/Atoms/Typography';
import { CircularProgress } from '@material-ui/core';
import EmptyState from '../ShiftReservationCard/Components/EmptyState';
import NotificationCard from 'Components/Molecules/NotificationCard';
import Badge from 'Components/Atoms/Badge';
import { Notification } from 'utils/types';
import { NotificationV02 } from 'CustomHooks/useNotification';
import { RestaurantContext } from 'Contexts/RestaurantContext';
import { useHistory } from 'react-router';

export type NotificationsProps = {
  onClose?: VoidFunction;
  onHideCard?: (id: string) => void;
  type?: 'reservation' | 'takeAway' | 'user' | 'voucher' | 'system' | 'ticket';
};

const Notifications = ({
  onClose = noop,
  onHideCard = noop,
  type = 'system',
}: NotificationsProps) => {
  const history = useHistory();
  const [anchorEl, setanchorEl] = useState<null | HTMLElement>(null);

  const {
    notifications,
    notificationsLoading,
    markNotificationAsSeen,
    markAllNotificationsAsSeen,
    deleteNotification,
    setnotificationsV03Open,
    filteredNotifications,
  } = useContext(RestaurantContext);

  const handleClose = (e: any) => {
    e.stopPropagation();
    setanchorEl(null);
    markAllNotificationsAsSeen();
    onClose();
  };

  const handleOpen = (e: any) => {
    setnotificationsV03Open((x) => !x);
    // if (!!anchorEl) {
    //   onClose();
    // }
    // setanchorEl((oV) => (!oV ? e.currentTarget : null));
  };

  const newNotifications = useMemo(
    () => notifications.filter((n) => !n.seen).length,
    [notifications]
  );

  return (
    <>
      <IconButton onClick={handleOpen}>
        <Badge number={filteredNotifications.length}>
          <NotificationsNone />
        </Badge>
      </IconButton>
      <Popover
        width="auto"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        placement="top-end"
        padding={0}
        style={{ marginTop: 16 }}
      >
        <Box
          flex
          className="space-between"
          width="380px"
          padding="md"
          underline
        >
          <Typography variant="h5">Notifications</Typography>
        </Box>
        {notificationsLoading && (
          <Box flex className="center" style={{ height: 300 }}>
            <CircularProgress />
          </Box>
        )}

        {!notificationsLoading && !!notifications.length && (
          <Box style={{ maxHeight: 400, overflow: 'scroll' }} width="380px">
            {notifications.map((n, i) => (
              <NotificationCard
                key={n.id}
                notification={n}
                onClick={() => {
                  if (n.link) history.push(n.link);
                }}
                onClose={(id) => {
                  onHideCard(id);
                  deleteNotification(id);
                }}
                lastOne={i === notifications.length - 1}
              />
            ))}
          </Box>
        )}

        {notifications.length === 0 ||
          (notificationsLoading && (
            <Box flex className="center" style={{ height: 300 }}>
              <EmptyState />
            </Box>
          ))}
      </Popover>
    </>
  );
};

export default Notifications;
