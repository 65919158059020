import Box from 'Components/Atoms/Box';
import SwitchBox from 'Components/Atoms/Switch';
import TextField from 'Components/Atoms/TextField';
import Calendar from 'Components/Molecules/Calendar';
import React, { useEffect, useState } from 'react';
import { dateHelper, toEuropeanDate } from 'utils/helper';
import { DateRangeProps } from '..';

const DateRangeCalendar = ({
  state,
  onChange,
  onlyOneDay,
  hideEndDatePhrase,
  date,
  dateRangeByDefault,
  disabledDetector,
}: DateRangeProps & {
  dateRangeByDefault?: boolean;
  disabledDetector?: (date: Date) => boolean;
}) => {
  const [dateRange, setdateRange] = useState<boolean>(
    !!state && !!state.endAt && !onlyOneDay
  );
  const [innerState, setinnerState] = useState<{
    startAt?: string;
    endAt?: string | null;
  }>({});
  const [focus, setfocus] = useState<'START_DATE' | 'END_DATE' | 'AUTOMATIC'>(
    'AUTOMATIC'
  );

  useEffect(() => {
    setinnerState({
      endAt: state?.endAt || undefined,
      startAt: state?.startAt || date || dateHelper(),
    });
  }, [state]);

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;

    let newState = { ...innerState, [name]: value };
    setinnerState(newState);
  };

  const handleSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (!checked) {
      setinnerState((iS) => ({ ...iS, endAt: null }));
      onChange({ ...innerState, endAt: null });
    }
    setdateRange(checked);
  };

  const handleBlur = () => {
    let state = { ...innerState };

    Object.keys(state).forEach((key) => {
      if ((state as any)?.[key]?.includes('.')) {
        let [dd, mm, yyyy] = (state as any)[key].split('.');

        (state as any)[key] = `${yyyy}-${mm}-${dd}`;
      }
    });

    onChange(state);
  };

  return (
    <Box flex className="flex-start" padding={12}>
      <Calendar
        value={state?.startAt || date || dateHelper()}
        endDate={state?.endAt || undefined}
        dateRange={dateRangeByDefault ?? dateRange}
        focus={focus}
        onFocusChange={setfocus}
        onChange={(newDate: string, endDate?: string) =>
          onChange({ startAt: newDate, endAt: endDate })
        }
        disabledDetector={disabledDetector}
      />
      {!onlyOneDay && (
        <Box style={{ marginLeft: 12 }}>
          <TextField
            fullWidth
            label="Start Date"
            value={toEuropeanDate(innerState?.startAt || dateHelper())}
            onChange={handleChange}
            onBlur={handleBlur}
            name="startAt"
            onClick={() => setfocus('START_DATE')}
          />
          {!dateRangeByDefault && (
            <SwitchBox
              checked={dateRange}
              translation="settings"
              label={
                hideEndDatePhrase ? 'Date Range ?' : 'Only for a limited time ?'
              }
              onChange={handleSwitch}
              color="primary"
            />
          )}
          {(dateRangeByDefault ?? dateRange) && (
            <TextField
              fullWidth
              label="Close Date"
              value={
                !!innerState?.endAt ? toEuropeanDate(innerState?.endAt) : ''
              }
              onClick={() => setfocus('END_DATE')}
              onChange={handleChange}
              onBlur={handleBlur}
              name="endAt"
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default DateRangeCalendar;
