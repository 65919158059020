import React, { useContext } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import {
  RequestStatus,
  WaitingListEntry,
} from 'gastronaut-shared/types/helper/reservations';
import GTable, {
  ContextAction,
  ListConfigHeader,
} from 'Components/Organisms/GTable';
import Typography from 'Components/Atoms/Typography';
import { dateHelper, toEuropeanDate } from 'utils/helper';
import { Reservation } from 'types/reservations';
import { CancelOutlined, CheckOutlined } from '@material-ui/icons';
import { ReservationContext } from 'Contexts/ReservationContext';
import { WaitingListActionType } from 'CustomHooks/useWaitinglist';
import FindTable from 'Components/Atoms/Icons/FindTable';

export type WaitinglistTableProps = {
  waitingList: (WaitingListEntry & {
    index: number;
  })[];
  occassions: { id: string; title: string }[];
  inPast?: boolean;
  onRowClick?: (id: string) => void;
  hasWaitinglist: boolean;
};

const WaitinglistTable: React.FC<WaitinglistTableProps> = ({
  waitingList,
  inPast,
  onRowClick,
  hasWaitinglist,
  ...props
}) => {
  const { handleWaitinglistAction } = useContext(ReservationContext);

  const headers: ListConfigHeader<
    WaitingListEntry & {
      index: number;
    }
  >[] = [
    {
      field: 'id',
      headerName: 'Code',
      headerTranslation: 'reservations',
      sortableBy: true,
      width: 90,
      displayFunction: (data) => (
        <Typography variant="text-3" color="subdued">
          #{data.customId}
        </Typography>
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      headerTranslation: 'reservations',
      sortableBy: true,
      width: 200,
      displayFunction: (data) => (
        <Typography
          variant="text-3"
          color="subdued"
          style={{ display: 'flex', alignItems: 'center', gap: 8 }}
        >
          {data.guest.name}{' '}
          {data.guest.company ? `(${data.guest.company})` : ''}
        </Typography>
      ),
    },
    {
      field: 'guests',
      headerName: 'PAX',
      headerTranslation: 'reservations',
      sortableBy: true,
      displayFunction: (data) => (
        <Typography variant="text-3" color="subdued">
          {data.guests}
        </Typography>
      ),
    },
    {
      field: 'time',
      headerName: 'Time',
      headerTranslation: 'reservations',
      sortableBy: true,
      width: 118,
      displayFunction: (data) => (
        <Typography
          variant="text-3"
          color="subdued"
          style={{ whiteSpace: 'nowrap' }}
        >
          {data.time}{' '}
          {data.flexibleTime
            ? `(${data.flexibleTime.earliest} - ${data.flexibleTime.latest})`
            : ''}
        </Typography>
      ),
    },
    {
      field: 'occassion',
      headerName: 'Occassion',
      headerTranslation: 'reservations',
      sortableBy: true,
      width: 120,
      displayFunction: (data) => (
        <Typography variant="text-3" color="subdued">
          {props.occassions.find((x) => x.id === data.occassion)?.title ??
            data.occassion}
        </Typography>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      headerTranslation: 'reservations',
      sortableBy: true,
      width: 120,
      displayFunction: (data) => (
        <Typography variant="text-3" color="subdued">
          {toEuropeanDate(dateHelper(data.createdAt))}
        </Typography>
      ),
    },
    {
      field: 'comment',
      headerName: 'Comment',
      headerTranslation: 'reservations',
      sortableBy: true,
      displayFunction: (data) => (
        <Typography variant="text-3" color="subdued">
          {data.guest.comment}
        </Typography>
      ),
    },
  ];

  const contextActions: ContextAction<WaitingListEntry>[] = [
    {
      icon: <CancelOutlined color="inherit" />,
      iconColor: 'inherit',
      onClick: (waitinglist) =>
        handleWaitinglistAction(waitinglist.id, WaitingListActionType.CANCEL),
      tooltipContent: 'Cancel',
      tooltipContentTranslation: 'reservations',
      showCondition: (o) =>
        !inPast &&
        (o.status === RequestStatus.PENDING ||
          o.status === RequestStatus.WAITING),
    },
    {
      icon: <FindTable color="inherit" />,
      iconColor: 'inherit',
      onClick: (waitinglist) =>
        handleWaitinglistAction(
          waitinglist.id,
          WaitingListActionType.FIND_TABLE
        ),
      tooltipContent: 'Find Table',
      tooltipContentTranslation: 'reservations',
      showCondition: (o) => !inPast && o.status === RequestStatus.PENDING,
    },
    {
      icon: <CheckOutlined color="inherit" />,
      iconColor: 'inherit',
      onClick: (waitinglist) =>
        handleWaitinglistAction(waitinglist.id, WaitingListActionType.ACCEPT),
      tooltipContent: 'Accept',
      tooltipContentTranslation: 'reservations',
      showCondition: (o) => !inPast && o.status === RequestStatus.WAITING,
    },
  ];

  if (!hasWaitinglist) return null;

  return (
    <Box>
      <Typography
        variant="h4"
        color="textPrimary"
        block
        className="mg-bt-sm"
        style={{ marginTop: 8 }}
      >
        Anfragen
      </Typography>
      <GTable
        headers={headers}
        data={waitingList}
        contextActions={contextActions}
        onRowClick={onRowClick}
      />
    </Box>
  );
};

export default WaitinglistTable;
