import React, { useContext, useEffect, useState } from "react";
import Box from "Components/Atoms/Box";
import "./styles.scss";
import MonthPicker from "./Components/MonthPicker";
import WeekDayLabel from "./Components/WeekDayLabel";
import useCalendar from "CustomHooks/useCalendar";
import Week from "./Components/Week";
import { noop } from "utils/helper";
import Card from "../Card";
import { ReservationContext } from "Contexts/ReservationContext";

export type CalendarProps = {
  disabled?: boolean;
  value: string | null;
  onChange?: (newDate: string, endDate?: string) => void;
  shiftDetector?: (date: Date) => boolean;
  disabledDetector?: (date: Date) => boolean;
  onMonthChange?: (month: string) => void;
  width?: string | number;
  minDate?: string;
  endDate?: string;
  dateRange?: boolean;
  focus?: "START_DATE" | "END_DATE" | "AUTOMATIC";
  onFocusChange?: (newFocus: "START_DATE" | "END_DATE") => void;
  mobileVersion?: boolean;
};

const Calendar = ({
  value = null,
  onMonthChange,
  disabledDetector,
  shiftDetector,
  onChange,
  width = 232,
  minDate,
  endDate = undefined,
  dateRange = false,
  focus = "START_DATE",
  onFocusChange = noop,
  mobileVersion = false,
}: CalendarProps) => {
  const { monthId, setmonthId, month } = useCalendar(value);

  const [innerFocus, setinnerFocus] = useState("START_DATE");

  const handleFocusChange = (focus: "START_DATE" | "END_DATE") => {
    setinnerFocus(focus);
    onFocusChange(focus);
  };

  useEffect(() => {
    if (innerFocus !== focus) {
      setinnerFocus(focus);
    }
  }, [focus]);

  useEffect(() => {
    if (monthId && onMonthChange) {
      onMonthChange(monthId);
    }
  }, [monthId]);

  useEffect(() => {
    if (focus === "AUTOMATIC") {
      setinnerFocus("START_DATE");
    }
  }, []);

  const onDateChange = (date: string) => {
    console.log("onChange in", date, onChange);
    if (onChange) {
      if (!dateRange) {
        console.log("nodaterange", date);
        onChange(date);
      } else if (dateRange) {
        if (innerFocus === "START_DATE") {
          if (endDate && date >= endDate) {
            onChange(date, undefined);
          } else {
            onChange(date, endDate);
          }
          handleFocusChange("END_DATE");
        } else if (innerFocus === "END_DATE") {
          if (value && date <= value) {
            onChange(date, value);
          } else {
            onChange(value || "", date);
          }
          handleFocusChange("START_DATE");
        }
      }
    }
  };

  // console.log("****", reservations);

  if (mobileVersion)
    return (
      <Card
        style={{ maxWidth: width, width: width }}
        title={
          <MonthPicker
            width={width}
            currentMonth={monthId}
            onChange={setmonthId}
          />
        }
      >
        <WeekDayLabel width={width} />
        {!!month?.weeks &&
          month.weeks.map((week) => (
            <Week
              key={week.calendarWeek}
              minDate={minDate}
              days={week.days}
              width={width}
              disabledDetector={disabledDetector}
              shiftDetector={shiftDetector}
              currentDate={value}
              onDateChange={onDateChange}
              endDate={endDate}
              dateRange={dateRange}
            />
          ))}
      </Card>
    );

  return (
    <Box style={{ maxWidth: width }}>
      <MonthPicker width={width} currentMonth={monthId} onChange={setmonthId} />
      <WeekDayLabel width={width} />
      {!!month?.weeks &&
        month.weeks.map((week) => (
          <Week
            key={week.calendarWeek}
            minDate={minDate}
            days={week.days}
            width={width}
            disabledDetector={disabledDetector}
            shiftDetector={shiftDetector}
            currentDate={value}
            onDateChange={onDateChange}
            endDate={endDate}
            dateRange={dateRange}
          />
        ))}
    </Box>
  );
};

export default Calendar;
