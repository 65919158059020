import { CallDocument } from 'gastronaut-shared/types/documents';
import { CallEvent } from 'gastronaut-shared/types/helper/sia';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { db } from 'utils/firebase';
import useCollection from './useCollection';
import { dateDiffInDays, dateHelper } from 'utils/helper';
import { NumberLiteralType } from 'typescript';

const WEEKDAYS = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];

function displayDate(ts: number) {
  const dateObj = new Date(ts);

  const diff = dateDiffInDays(dateObj, new Date());

  if (diff === 0) {
    return 'Heute, ' + dateObj.toLocaleTimeString().slice(0, 5);
  } else if (diff === 1) {
    return 'Gestern, ' + dateObj.toLocaleTimeString().slice(0, 5);
  } else if (diff < 7) {
    return (
      WEEKDAYS[dateObj.getDay()] +
      ', ' +
      dateObj.toLocaleTimeString().slice(0, 5)
    );
  } else {
    const [yyyy, mm, dd] = dateHelper(dateObj).split('-');

    return `${dd}.${mm}.`;
  }
}

export type CallWithId = CallDocument & {
  id: string;
  updatedAt?: number;
  seen?: number;
};

export type ExtendedCall = CallWithId & {
  wasSeen: boolean;
  lastEvent?: CallEvent;
  isLive: boolean;
  statusText: string;
  name: string;
  dateString: string;
  calls?: ExtendedCall[];
  error: boolean;
  first: boolean;
  count: number;
  unseenCalls: string[];
};

const useCalls = (
  restaurantId: string | null,
  hasSia = false,
  perPage = 20
) => {
  const ts = useMemo(() => Date.now(), [restaurantId]);

  const lastEl = useRef<number | null>(null);

  const [newCalls] = useCollection<CallWithId>(`/calls`, {
    filter: [
      ['restaurantId', '==', restaurantId],
      ['createdAt', '>=', ts],
    ],
  });

  const [updatedCalls] = useCollection<CallWithId>(`/calls`, {
    filter: [
      ['restaurantId', '==', restaurantId],
      ['updatedAt', '>=', ts],
    ],
  });

  // Paginated Calls
  const [page, setpage] = useState<number | null>(null);

  const [paginatedCalls, setpaginatedCalls] = useState<CallWithId[]>([]);

  const [info, setinfo] = useState<{
    loading: boolean;
    error?: string;
    hasMore: boolean;
  }>({ loading: false, hasMore: true });

  useEffect(() => {
    setpage(0);
    lastEl.current = null;
    setinfo({ loading: false, hasMore: true });
    setcurrentCallId(null);
  }, [restaurantId, hasSia]);

  useEffect(() => {
    (async () => {
      try {
        // console.log(hasSia, page, restaurantId, info);

        if (info.loading) return;

        if (!hasSia || page === null) {
          setinfo({
            loading: false,
            error: 'Restaurant does not use SIA',
            hasMore: false,
          });
          setpaginatedCalls([]);
          return;
        }

        setinfo({ loading: true, hasMore: true });

        const ref = db
          .collection('calls')
          .where('restaurantId', '==', restaurantId)
          .where('createdAt', '<', ts)
          .orderBy('createdAt', 'desc')
          .limit(perPage);

        const newPaginatedCalls: CallWithId[] = [];

        let hasMore = false;

        if (page === 0) {
          const docs = await ref.get();

          docs.forEach((doc) => {
            let data = { ...doc.data(), id: doc.id } as CallWithId;

            newPaginatedCalls.push(data);
            lastEl.current = data.createdAt;
          });

          hasMore = !(docs.size < perPage);

          setpaginatedCalls(newPaginatedCalls);
        } else {
          const docs = await ref.startAfter(lastEl.current).get();

          docs.forEach((doc) => {
            let data = { ...doc.data(), id: doc.id } as CallWithId;

            newPaginatedCalls.push(data);
            lastEl.current = data.createdAt;
          });

          hasMore = !(docs.size < perPage);

          setpaginatedCalls((calls) =>
            Array.from(new Set([...calls, ...newPaginatedCalls]))
          );
        }

        setinfo({ loading: false, hasMore });
      } catch (error: any) {
        setinfo({ loading: false, hasMore: false, error: error.message });
      }
    })();
  }, [page, hasSia, restaurantId]);

  const nextPage = () => {
    // console.log({ page, info });
    if (page !== null && info.hasMore && !info.loading) {
      setpage((p) => (p !== null ? p + 1 : null));
    }
  };

  const { calls, callIds } = useMemo(() => {
    const map = [
      ...newCalls.data,
      ...updatedCalls.data,
      ...paginatedCalls,
    ].reduce((acc, cV) => {
      return {
        ...acc,
        [cV.id]:
          (acc?.[cV.id]?.updatedAt ?? 0) > (cV.updatedAt ?? 1)
            ? acc[cV.id]
            : cV,
      };
    }, {} as Record<string, CallWithId>);

    const calls = Object.values(map).sort((a, b) => b.createdAt - a.createdAt);

    const callerIds = calls.map((call) => call.callerId);

    return {
      calls: calls.map((call, i) => {
        const wasSeen = !!call.seen && call.seen < Date.now();

        const lastEvent = call?.events?.[(call?.events?.length ?? 0) - 1];

        const isLive = !call.endedAt;

        const error = !lastEvent || !lastEvent.success;

        const first = callerIds.indexOf(call.callerId) === i;

        const count = callerIds.filter((c) => c === call.callerId).length;

        const statusText = isLive
          ? 'Aktuell in der Leitung'
          : lastEvent?.result ?? 'Aufgelegt / ohne Aktion';

        const unseenCalls = calls
          .filter((c) => !c.seen && c.callerId === call.callerId)
          .map((c) => c.id);

        return {
          ...call,
          wasSeen,
          lastEvent,
          isLive,
          error,
          statusText,
          first,
          count,
          unseenCalls,
          name: call.guest?.name ?? call.callerId,
          dateString: displayDate(call.createdAt),
        } as ExtendedCall;
      }),
      callIds: Object.keys(map),
    };
  }, [newCalls.data, updatedCalls.data, paginatedCalls, restaurantId]);

  // Current Call

  console.log({ calls });

  const [currentCallId, setcurrentCallId] = useState<string | null>(null);

  const currentCall = useMemo(() => {
    const currentCall = currentCallId
      ? calls.find((c) => c.id === currentCallId) ?? null
      : null;

    if (!currentCall) return null;

    const otherCalls = calls.filter((c) => c.callerId === currentCall.callerId);

    return {
      ...currentCall,
      calls: otherCalls,
    };
  }, [currentCallId, calls]);

  const onChangeCurrentCall = (
    id: string | null,
    page?: 'info' | 'qr-code' | 'conversation'
  ) => {
    if (callIds.includes(id ?? '')) {
      setcurrentCallId(id);
    } else {
      setcurrentCallId(null);
    }
    setcurrentCallPage(page ?? 'info');
  };

  const [currentCallPage, setcurrentCallPage] = useState<
    'info' | 'qr-code' | 'conversation'
  >('info');

  const activeCalls = useMemo(() => {
    return calls.filter(
      (c) => !c.endedAt && c.createdAt > Date.now() - 1000 * 60 * 60
    );
  }, [calls]);

  // unseen calls

  const unseenCalls = useMemo(() => {
    return calls.reduce((acc, cV) => acc + cV.unseenCalls.length, 0);
  }, [calls, callIds]);

  return {
    nextPage,
    ...info,
    calls,
    currentCall,
    onChangeCurrentCall,
    currentCallPage,
    setcurrentCallPage,
    activeCalls,
    unseenCalls,
  };
};

export default useCalls;
