import React, { useMemo, useState } from "react";
import Box from "Components/Atoms/Box";
import "./styles.scss";
import useSiaContext from "CustomHooks/useSiaContext";
import { classHelper } from "utils/helper";
import { IconButton } from "@material-ui/core";
import { ArrowForward, Close } from "@material-ui/icons";
import SiaModalCard from "./Components/SiaModalCard";
import Typography from "Components/Atoms/Typography";
import Button from "Components/Atoms/Button";
import CallListItem, { NextPage } from "./Components/CallListItem";
import CurrentCall from "./Components/CurrentCall";
import CallSettings from "./Components/CallSettings";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export type SiaModalProps = {
  onClose?: () => void;
  className?: string;
  mobile?: boolean;
};

const SiaModal: React.FC<SiaModalProps> = ({
  onClose = () => {},
  className = "",
  mobile = false,
}) => {
  const { calls, currentCall, onChangeCurrentCall, callStatistics } =
    useSiaContext();

  const [tab, settab] = useState(0);

  const filteredCalls = useMemo(() => {
    return calls.filter((c) => {
      if (tab === 0) {
        return c.first;
      } else {
        return (
          c.first &&
          !!c.endedAt &&
          !c.events?.[(c?.events?.length ?? 1) - 1]?.success
        );
      }
    });
  }, [calls, tab]);

  const [settingsOpen, setSettingsOpen] = useState(false);

  const isMobile = useMediaQuery("(max-width: 450px)");

  return (
    <Box className={classHelper(["siaModal", className])}>
      <Box className="mainWindow paper" outline elevation={4}>
        {!mobile && (
          <Box className="flex space-between mg-bt-sm">
            <Typography block variant="h5">
              Call Manager
            </Typography>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                if (isMobile) document.body.style.overflow = "auto";
                onClose();
              }}
            >
              <Close />
            </IconButton>
          </Box>
        )}

        <SiaModalCard
          onOpenSettings={() => {
            setSettingsOpen(true);
            onChangeCurrentCall(null);
            if (isMobile) document.body.style.overflow = "hidden";
          }}
        />
        <Box className="tabs">
          <Button
            className={tab === 0 ? "active" : ""}
            onClick={() => settab(0)}
            variant="transparent"
          >
            All Calls
          </Button>
          <Button
            className={tab === 1 ? "active" : ""}
            onClick={() => settab(1)}
            variant="transparent"
          >
            Re-Check
          </Button>
        </Box>
        <div className="list" id="callList">
          {filteredCalls.map((call) => (
            <CallListItem
              key={call.id}
              call={call}
              active={currentCall?.id === call.id}
              onClick={() => setSettingsOpen(false)}
            />
          ))}
          {filteredCalls.length >= 1 && <NextPage />}
        </div>
        <div style={{ paddingTop: 8, textAlign: "right" }}>
          <Typography variant="text-3" style={{ marginRight: 8 }}>
            Heute:
          </Typography>
          <Typography variant="text-3" style={{ marginRight: 8 }}>
            {callStatistics?.calls ?? 0} Anrufe
          </Typography>
          <Typography variant="text-3">
            {callStatistics?.reservations ?? 0} Reservierungen
          </Typography>
        </div>
      </Box>
      {!!currentCall && !settingsOpen && <CurrentCall />}
      {settingsOpen && <CallSettings onClose={() => setSettingsOpen(false)} />}
    </Box>
  );
};

export default SiaModal;
