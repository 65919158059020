import { Block, ReservationSnippet, Size } from './types';
import { TakeAwayCartItem } from 'types/takeAway';
import { Reservation } from 'types/reservations';
import { ReservationShift } from 'types/shifts';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { FieldValue } from './firebase';
import { CalendarShift } from 'App/Calendar/Components/Week';

import {
  DayOfWeek,
  ReservationRule,
} from 'gastronaut-shared/types/helper/reservations';

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

// a and b are javascript Date objects
export function dateDiffInDays(a: Date, b: Date) {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export const cubicBezier = (
  n: number,
  values: number[] = [1, 1, 0, 0],
  options?: { min: number; max: number }
) => {
  let diff = options ? options.max - options.min : 1;

  let min = options?.min || 0;

  let x = options ? (n - options.min) / (options.max - options.min) : n;

  return diff * values.reduce((a, cV) => a + cV * x, 0) + min;
};

export const maxMin = (n: number, min: number, max: number) => {
  if (n < min) return min;
  if (n > max) return max;
  return n;
};

export const sizeHelper = (size: Size | undefined) => {
  switch (size) {
    case 'sm': {
      return 8;
    }
    case 'md': {
      return 16;
    }
    case 'lg': {
      return 24;
    }
    default: {
      return size;
    }
  }
};

export const emailRegex = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
};

export const classHelper = (arr: any[]) => arr.filter((x) => !!x).join(' ');

export const noop = () => {};

export const asyncVoid = (...args: any) =>
  new Promise<void>((resolve: Function) => {
    setTimeout(() => {
      console.log(args);
      resolve();
    }, 1000);
  });

export const textAlignToJustifyContent = (
  textAlign: 'right' | 'left' | 'center'
) => {
  switch (textAlign) {
    case 'right': {
      return 'flex-end';
    }
    case 'left': {
      return 'flex-start';
    }
    case 'center': {
      return 'center';
    }
    default:
      return 'flex-start';
  }
};

export const textAlignToPlacement = (
  textAlign: 'right' | 'left' | 'center'
) => {
  switch (textAlign) {
    case 'right': {
      return 'bottom-end';
    }
    case 'left': {
      return 'bottom-start';
    }
    case 'center': {
      return 'bottom';
    }
    default:
      return 'bottom-start';
  }
};

export const attrHelper = (attr: string) => {
  switch (attr) {
    case 'vip': {
      return 'VIP';
    }
    case 'specialOccassion': {
      return 'Special Occassion';
    }
    case 'regular': {
      return 'Regular';
    }
    case 'window': {
      return 'Window Seat';
    }
    case 'blacklist': {
      return 'Blacklist';
    }
    case 'terrace': {
      return 'Terrasse';
    }
    default:
      return attr;
  }
};

export const wDayHelper = (
  wDay: number,
  long: boolean = false,
  lang?: 'en' | 'de' | 'fr'
) => {
  if (long) {
    if (lang === 'fr') {
      const wDayArr = [
        'Dimanche',
        'Lundi',
        'Mardi',
        'Mercredi',
        'Jeudi',
        'Vendredi',
        'Samedi',
      ];
      return wDayArr[wDay];
    } else if (lang === 'de') {
      const wDayArr = [
        'Sonntag',
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag',
      ];
      return wDayArr[wDay];
    }

    const wDayArr = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    return wDayArr[wDay];
  }

  const wDayArr = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  return wDayArr[wDay];
};

export const monthHelper = (month: string, long: boolean = false) => {
  if (long) {
    const monthArr = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return monthArr[+month - 1];
  }

  const monthArr = [
    'Jan.',
    'Feb.',
    'Mar.',
    'Apr.',
    'May',
    'June',
    'July',
    'Aug.',
    'Sept.',
    'Oct.',
    'Nov.',
    'Dec.',
  ];
  return monthArr[+month - 1];
};

// export const timeToBlock = (time: string, plus = 0) => {
//   if (!time) {
//     return NaN;
//   }

//   if (Math.floor(parseInt(time.split(':')[1])) > 60) {
//     return NaN;
//   }

//   if (parseInt(time.split(':')[0]) > 24) {
//     return NaN;
//   }

//   return (
//     parseInt(time.split(':')[0]) * 4 +
//     Math.floor((parseInt(time.split(':')[1]) + plus) / 15)
//   );
// };

// export const blockToTime = (block: number) => {
//   let h: string | number = Math.floor(block / 4);
//   let m: string | number = (block % 4) * 15;
//   if (m < 10) {
//     m = '0' + m;
//   }
//   if (h >= 24) {
//     h -= 24;
//   }
//   if (h < 10) {
//     h = '0' + h;
//   }
//   return h + ':' + m;
// };

// export const randomNumberBetweenTwoDigits = (min: number, max: number) => {
//   let diff = max - min;
//   return min + Math.round(diff * Math.random());
// };

// export const randomValueFromArr = (arr: any[]) =>
//   arr[Math.floor(arr.length * Math.random())];

// type numberOrNull = number | null;

// export const reduceToStartAndClose = (shifts: Shift[]) => {
//   return shifts.reduce(
//     (acc: numberOrNull[], cV) => {
//       let [start, close] = acc;

//       if (
//         !cV.closed &&
//         (start === null || (cV.start !== null && start > cV.start))
//       ) {
//         start = cV.start;
//       }

//       if (
//         !cV.closed &&
//

export const timeToBlock = (
  time: string,
  plus = 0,
  add24HoursAfterMidnight = false
) => {
  if (!time) {
    return NaN;
  }

  if (Math.floor(parseInt(time.split(':')[1])) > 60) {
    return NaN;
  }

  let blocks =
    parseInt(time.split(':')[0]) * 4 +
    Math.floor((parseInt(time.split(':')[1]) + plus) / 15);

  if (blocks <= 16) {
    blocks += 24 * 4;
  }

  return blocks;
};

export const blockToTime = (block: number, pad = true, moreThan24 = false) => {
  let h: string | number = Math.floor(block / 4);
  let m: string | number = (block % 4) * 15;
  if (m < 10) {
    m = '0' + m;
  }
  if (h >= 24 && !moreThan24) {
    h -= 24;
  }
  if (h < 10 && pad) {
    h = '0' + h;
  }
  return h + ':' + m;
};

export const randomNumberBetweenTwoDigits = (min: number, max: number) => {
  let diff = max - min;
  return min + Math.round(diff * Math.random());
};

export const randomValueFromArr = (arr: any[]) =>
  arr[Math.floor(arr.length * Math.random())];

type numberOrNull = number | null;

//get all dates between two dates
export const dateRangeHelper = (
  startDate: string,
  endDate: string,
  wDays: number[] = [0, 1, 2, 3, 4, 5, 6]
) => {
  let arr: string[] = [];

  let i = 0;

  while (!arr.includes(endDate)) {
    const date = new Date(startDate);
    date.setDate(date.getDate() + i);

    let isoDate = dateHelper(date);

    if (wDays.includes(date.getDay())) arr.push(isoDate);

    if (isoDate === endDate) break;

    ++i;
  }

  return arr;
};

export const reduceToStartAndClose = (shifts: ReservationShift[]) => {
  return shifts.reduce(
    (acc: numberOrNull[], cV) => {
      let [start, close] = acc;

      if (
        !cV.closed &&
        (start === null || (cV.start !== null && start > cV.start)) &&
        (cV.start || cV.start === 0)
      ) {
        start = cV.start;
      }

      if (
        !cV.closed &&
        (close === null || (cV.close !== null && close < cV.close)) &&
        (cV.close || cV.close === 0)
      ) {
        close = cV.close;
      }

      return [start, close];
    },
    [null, null]
  );
};

export const createBlocks = (
  start: number,
  close: number,
  reservations: ReservationSnippet[]
): Block[] => {
  if (start >= close || isNaN(start) || isNaN(close)) return [];

  const blocks = Array.from(new Array(Math.max(close - start, 0)), (x, i) => {
    return {
      block: start + i,
      time: blockToTime(start + i),
      reservations: reservations
        .filter((r) => r.start === start + i)
        .filter((r, i, arr) => arr.findIndex((r1) => r1.id === r.id) === i),
    };
  });

  return blocks;
};

export const createBlocksForUtilization = (
  start: number,
  close: number,
  reservations: ReservationSnippet[]
): Block[] => {
  try {
    // if (start >= close || typeof start !== 'number' || typeof close !== 'number' || !isNaN(start) || !isNaN(close)) return [];

    const blocks = Array.from(new Array(close - start), (x, i) => {
      let block = start + i;

      return {
        block,
        time: blockToTime(block),
        reservations: reservations
          .filter(
            (r) => r.start <= block && block <= r.start + r.reservationLength
          )
          .filter((r, i, arr) => arr.findIndex((r1) => r1.id === r.id) === i),
      };
    });

    return blocks;
  } catch (error) {
    return [];
  }
};

export const dateHelper = (
  date: Date | number | string = new Date(Date.now() - 60 * 4 * 60000)
) => {
  if (typeof date !== 'object') {
    date = new Date(new Date(date).getTime() - 60 * 5 * 60000);
  }
  return date.toISOString().split('T')[0];
};

export const getTime = (
  date: string,
  time: number = 4 * 24,
  start: number = 0
) => {
  let timeStamp = new Date(date + 'T' + blockToTime(time));

  if (blockToTime(time) <= blockToTime(start)) {
    timeStamp.setDate(timeStamp.getDate() + 1);
  }

  // if(time >= (4 * 24)) {
  //   timeStamp.setDate(timeStamp.getDate() + 1);
  // }

  return timeStamp.getTime() - new Date().getTimezoneOffset();
};

export const timeHelper = (addBlocks = 0) =>
  blockToTime(timeToBlock(new Date().toTimeString().split(' ')[0]) + addBlocks);

export const toTimeArr = (start: string, close: string) => {
  let s = timeToBlock(start);
  let c = timeToBlock(close) + 1;

  if (s >= c) {
    return [];
  }

  return Array.from(Array(c - s), (x, b) => blockToTime(b + s));
};

export const daysFromToday = (date: string | Date) => {
  if (!date) {
    return 0;
  }

  if (typeof date !== 'object') {
    date = new Date(date);
  }

  return dateDiffInDays(new Date(), date);
};

export const daysInFuture = (days = 1, date: Date | string = new Date()) => {
  if (typeof date === 'string') {
    if (days === 0) return date;
    date = new Date(date + 'T12:00:00.000Z');
  }
  date.setDate(date.getDate() + days);

  return date.toISOString().split('T')[0];
};

export const toPad = (x: string | number) => {
  x = +x;

  if (x < 10) {
    return '0' + x;
  } else {
    return String(x);
  }
};

export const changeMonth = (months = 1, month: string) => {
  let [yyyy, mm] = month.split('-').map((m) => parseInt(m) - 1);

  if (months === 0) return month;

  if (months > 0) {
    mm += months;

    if (mm > 11) {
      yyyy += 1;
      mm = mm % 12;
    }
  } else {
    mm += months;

    if (mm < 0) {
      yyyy -= 1;
      mm += 12;
    }
  }

  return `${yyyy + 1}-${toPad(mm + 1)}`;
};

export const timeString = ({
  block,
  time,
}: {
  block: number;
  time?: string;
}) => {
  let rest = block % 4;
  if (rest === 0) return time || blockToTime(block);
  if (rest === 1) return ':15';
  if (rest === 2) return ':30';
  if (rest === 3) return ':45';
  return '';
};

export const secondsToHrs = (sec: number) => {
  let seconds = Math.round(sec);
  let hh: number | string = Math.floor(seconds / 3600);
  let divisor_for_minutes = seconds % (60 * 60);
  let mm: number | string = Math.floor(divisor_for_minutes / 60);
  if (hh < 10) {
    hh = '0' + hh;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  const kilometer = `${hh}:${mm}`;
  return kilometer;
};

export const metersToKm = (distance: number) => {
  const meter = (distance * 0.001).toFixed(1) + 'km';
  return meter;
};

export const currencyToNumber = (currency: string, fallback?: number) => {
  let val = currency.replace(/(?!\d|,|\.)./gm, '');

  if (val === '') {
    return fallback;
  }
  return parseFloat(val.replace(',', '.'));
};

export const toCurrencyString = (
  number: number,
  currency = '€',
  inverted = false,
  devideBy = 100
) => {
  if (typeof number !== 'number' || isNaN(number)) return '-';

  return inverted
    ? currency + ' ' + (number / devideBy).toFixed(2).replace('.', ',')
    : (number / devideBy).toFixed(2).replace('.', ',') + currency;
};

export const calcItemPrice = (item: TakeAwayCartItem, amount = 1) => {
  let price = Number(item.price); // @TODO: options;

  if (!item?.options?.length) return price * amount * 100;

  item.options.forEach(({ id, value }) => {
    let option = item?.optionsV02?.find((o) => o.id === id);

    if (!option) {
      return price;
    } else if (Array.isArray(value)) {
      value.forEach((v) => {
        let opt: any = option?.options?.find((o) => v === o.id) || {};

        price += Number(opt.price) || 0;
      });
    } else {
      let opt: any = option?.options?.find((o) => value === o.id) || {};
      price += Number(opt.price) || 0;
    }
  });

  return price * amount * 100;
};

export const stringifyOrderOptions = (item: TakeAwayCartItem) => {
  let string = '';
  if (!!item?.options?.length) {
    item.options.forEach((opt, index) => {
      const optionObject = item?.optionsV02?.find((o) => o.id === opt.id);
      if (!!optionObject) {
        if (typeof opt.value === 'string') {
          const optionOption = optionObject?.options?.find(
            (o) => o.id === opt.value
          );
          if (!!optionOption) {
            string += `${optionOption.shortName}`;
          }
        } else {
          opt.value.forEach((val, i) => {
            const optionOption = optionObject?.options?.find(
              (o) => o.id === val
            );
            if (!!optionOption) {
              string += `${optionOption.shortName}`;
              if (!!opt.value.length && i < opt.value.length - 1)
                string += ', ';
            }
          });
        }
        if (!!item?.options?.length && index < item.options.length - 1)
          string += ', ';
      }
    });
  }
  return string;
};

export const pasteInPlace = (arr: any[], index: number, newValue: any) => {
  let newArr = Array.from(arr);
  newArr.splice(index, 1);
  newArr.splice(index, 0, newValue);
  return newArr;
};

export const capitalizeWord = (word: string | undefined) => {
  return word ? word.trim().replace(/^\w/, (c) => c.toUpperCase()) : '';
};

export const toEuropeanDate = (date: string, short?: boolean) => {
  if (short) {
    let digitsYear = date
      .split('T')[0]
      .split('-')
      .reverse()
      .join('.')
      .split('.');
    let twoDigitsYear = digitsYear[digitsYear.length - 1]
      .split('T')[0]
      .split('')
      .splice(2, 2)
      .join('');
    digitsYear.pop();

    return digitsYear.join('.') + '.' + twoDigitsYear;
  }
  return date.split('T')[0].split('-').reverse().join('.');
};

export const languageHelper = (lang: string) => {
  switch (lang) {
    case 'de':
      return 'German';
    case 'en':
      return 'English';
    case 'fr':
      return 'French';
    default:
      return lang;
  }
};

export const sourceHelper = (src: string) => {
  switch (src.toLowerCase()) {
    case 'inhouse':
      return 'In House';
    case 'website':
      return 'Website';
    case 'opentable':
      return 'Open Table';
    case 'phone':
      return 'Phone';
    case 'ticketshop':
      return 'Ticket Shop';
    case 'walkin':
      return 'Walk in';
    default:
      return src;
  }
};

export const historyKeyHelper = (key: string) => {
  switch (key.toLowerCase()) {
    case 'tables':
      return 'Tables';
    case 'createdat':
      return 'Created at';
    case 'excludefromslots':
      return 'Exclude from slots';
    case 'time':
      return 'Time';
    case 'guests':
      return 'Guests';
    case 'started':
      return 'Started';
    case 'oldreservationlength':
      return 'Old reservation length';
    case 'reservationlength':
      return 'Reservation length';
    case 'done':
      return 'Done';
    case 'sendConfirmationEmail': {
      return 'Bestätigungsemail';
    }
    default:
      return key;
  }
};

export const timeStampToDateAndHour = (timeStamp: string | number) => {
  console.log({ timeStamp });

  if (isNaN(+timeStamp)) return { date: '-', time: '-' };

  const fullDate = new Date(
    typeof timeStamp === 'string' ? parseInt(timeStamp) : timeStamp
  );

  const date = fullDate.toLocaleDateString('de');
  const time = fullDate.toTimeString().slice(0, 5);
  return { date, time };
};

const DAY = 60000 * 60 * 24;

const shiftStartOfWeek = (wDay: number) => (!!wDay ? (wDay - 1) % 7 : 6);

export const getCalendarWeek = (date = new Date()) => {
  if (!(date instanceof Date)) {
    date = new Date(date);
  } else {
    date.setHours(2);
  }

  const firstJanuary = new Date(date.getFullYear(), 0, 1, 2, 0);

  let days =
    shiftStartOfWeek(firstJanuary.getDay()) <= 3
      ? shiftStartOfWeek(firstJanuary.getDay()) * -1
      : 7 - shiftStartOfWeek(firstJanuary.getDay());

  const startOfFirstWeek = new Date(date.getFullYear(), 0, 1 + days, 2, 0);

  const daysPased = Math.ceil(
    (date.getTime() - startOfFirstWeek.getTime()) / DAY
  );

  const calendarWeek = Math.floor(
    (daysPased - shiftStartOfWeek(date.getDay())) / 7
  );

  return calendarWeek;
};
//getUnique(array, 'id') will get all the array elements with a unique id
export function getUnique(
  arr: any[],
  comp: string | ((x: any) => string)
): CalendarShift[] {
  return (
    arr
      .map((e) => {
        return typeof comp === 'string' ? e[comp] : comp(e);
      })
      .map((e, i, final) => final.indexOf(e) === i && i)
      // @ts-ignore
      .filter((e) => arr[e])
      // @ts-ignore
      .map((e) => arr[e])
  );
}

export const findOverlaps = (
  res: Reservation,
  b1: number,
  b2: number,
  countTouchAsOverlap = true
) => {
  let a1 = res.startTimeInBlocks || timeToBlock(res.time),
    a2 = res.endTimeInBlocks || a1 + (res?.reservationLength || 0);

  if (!countTouchAsOverlap) {
    if (
      (a1 === b1 && a2 === b2) ||
      (a2 > b1 && a1 < b1) ||
      (b2 > a1 && b1 < a1)
    ) {
      return true;
    }
  } else {
    if (
      (a1 === b1 && a2 === b2) ||
      (a2 >= b1 && a1 <= b1) ||
      (b2 >= a1 && b1 <= a1)
    ) {
      return true;
    }
  }

  return false;
};

export const stitchShifts = (
  shifts: ReservationShift[],
  currentShift: null | string
) => {
  const filteredShifts = shifts.filter(
    (s) => currentShift === null || s.id === currentShift
  );

  const currentShiftIsNotLastShift =
    !!currentShift &&
    shifts?.some(
      (s) =>
        s.active &&
        !s.closed &&
        s.start > filteredShifts?.[0]?.start &&
        s.start <= filteredShifts?.[0]?.close
    );

  const startStop: [number | null, number | null] = filteredShifts.reduce(
    (acc: [number | null, number | null], cV) => {
      if (
        typeof cV.start === 'number' &&
        (acc[0] === null || acc[0] > cV.start)
      ) {
        acc[0] = cV.start;
      }

      let close = !currentShiftIsNotLastShift
        ? cV.close
        : cV.service ?? cV.close;

      if (typeof close === 'number' && (acc[1] === null || acc[1] < close)) {
        acc[1] = close;
      }

      return acc;
    },
    [null, null]
  );

  return { startStop, filteredShifts };
};

export const createRandomId = (length = 4) => {
  const arr = Array.from('ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789');

  var id = '';

  for (var i = 0; i < length; i++) {
    id += arr[Math.round(Math.random() * (arr.length - 1))];
  }

  return id;
};

export const reservationToSnippet: (r: Reservation) => ReservationSnippet = (
  r
) => {
  let {
    id,
    time,
    reservationLength,
    guests,
    occassion,
    status,
    source = 'inHouse',
    tables = [],
    tableStr = null,
    date,
  } = r;

  let { attr = [], name, comment = null, hostComment = null } = r.guest;

  if (!tables?.length && tableStr) {
    tables = [tableStr];
  } else if (!tables) {
    tables = [];
  }

  return {
    id,
    time,
    start: r.startTimeInBlocks,
    reservationLength,
    guests,
    attr,
    comment,
    hostComment,
    date,
    name,
    occassion,
    source,
    status,
    tables,
  };
};

// return boolean for intersection
export function checkIntersection(
  array1: Array<any>,
  array2: Array<any>
): boolean {
  for (let i = 0; i < array1.length; i++) {
    for (let j = 0; j < array2.length; j++) {
      if (array1[i] === array2[j]) {
        // Return if common element found
        return true;
      }
    }
  }
  // Return if no common element exist
  return false;
}

export function copyToClipboard(containerid: string) {
  try {
    if (window.getSelection) {
      // @ts-ignore
      if (window.getSelection().empty) {
        // @ts-ignore
        window.getSelection().empty();
        // @ts-ignore
      } else if (window.getSelection().removeAllRanges) {
        // @ts-ignore
        window.getSelection().removeAllRanges();
      }
      // @ts-ignore
    } else if (document.selection) {
      // @ts-ignore
      document.selection.empty();
    }

    // @ts-ignore
    if (document.selection) {
      // @ts-ignore
      var range = document.body.createTextRange();
      range.moveToElementText(document.getElementById(containerid));
      range.select().createTextRange();
      document.execCommand('copy');
    } else if (window.getSelection) {
      var range = document.createRange();
      range.selectNode(document.getElementById(containerid));
      // @ts-ignore
      window.getSelection().addRange(range);
      document.execCommand('copy');
      // @ts-ignore
      document.selection.empty();
    }
  } catch (e) {
    console.error(e);
  }
}

export const isTouchDevice = () => {
  return (
    'ontouchstart' in window || navigator.maxTouchPoints > 0
    // navigator.msMaxTouchPoints > 0 ??
  );
};

export const revertRotation = ({ x, y }: { x: number; y: number }) => {
  if (x > 40 && y > 40) return false;

  let blockedOnLeft = x <= 40;
  let blockedOnTop = y <= 40;

  return blockedOnLeft || blockedOnTop;
};

export const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => clearTimeout(handler);
  }, [value]);

  return debouncedValue;
};

export const handleTextsVariable = (
  string: string,
  data: Record<string, string>
) => {
  let newString = string;
  const regex = /\{\{[a-zA-Z]*}}/g;
  const variables = string?.match(regex) || [];
  variables.forEach((v) => {
    const cleanedVariable = v.slice(2, -2);
    newString = newString.replace(v, data[cleanedVariable] ?? v);
  });
  return newString;
};

export function removeUndefinedValues<T = any>(input: T) {
  let isArray = Array.isArray(input);
  let data = _.omitBy<any>(input, _.isUndefined);

  Object.entries(data).forEach(([key, val]) => {
    if (!!val && typeof val === 'object') {
      data[key] = removeUndefinedValues(val);
    } else if (val === undefined) {
      data[key] = FieldValue.delete() as unknown as undefined;
    }
  });

  if (isArray) {
    return Object.values(data) as unknown as T;
  }

  return data as T;
}

function addDays(date: Date, days: number): Date {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

function daysBetween(startDate: Date, endDate: Date): number {
  const oneDay = 1000 * 60 * 60 * 24;
  const start = Date.UTC(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate()
  );
  const end = Date.UTC(
    endDate.getFullYear(),
    endDate.getMonth(),
    endDate.getDate()
  );

  return (end - start) / oneDay;
}

function getNthWeekdayOfMonth(
  month: number,
  year: number,
  weekday: number,
  n: number
): Date {
  let count = 0;
  let date = new Date(year, month, 1);
  while (count < 6) {
    if (date.getDay() === weekday) {
      if (++count === n) {
        return date;
      }
    }
    date = addDays(date, 1);
  }

  return date;
}

export function getNthWeekdayOfMonthFromDate(dateString: string): number {
  let count = 0;
  let date = new Date(dateString);
  let weekday = date.getDay();
  date.setDate(1);
  while (count < 6) {
    if (date.getDay() === weekday) {
      ++count;
      if (date.toISOString().split('T')[0] === dateString) {
        return count;
      }
    }
    date = addDays(date, 1);
  }

  return count;
}

export function matchReservationRules(
  rule: ReservationRule,
  start: string,
  end: string
): string[] {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const totalDays = daysBetween(startDate, endDate);
  const matchedDates: string[] = [];

  for (let i = 0; i <= totalDays; i++) {
    const currentDate = addDays(startDate, i);
    switch (rule.type) {
      case 'daily':
        if (i % rule.every === (rule.offset ?? 0)) {
          matchedDates.push(currentDate.toISOString().split('T')[0]);
        }
        break;
      case 'weekly':
        if (
          rule.days.includes(currentDate.getDay() as DayOfWeek) &&
          Math.floor(i / 7) % rule.every === (rule.offset ?? 0)
        ) {
          matchedDates.push(currentDate.toISOString().split('T')[0]);
        }
        break;
      case 'monthlyDayOfWeek':
        if (rule.days.includes(currentDate.getDay() as DayOfWeek)) {
          const nthWeekday = getNthWeekdayOfMonth(
            currentDate.getMonth(),
            currentDate.getFullYear(),
            currentDate.getDay(),
            rule.option
          );
          if (currentDate.getDate() === nthWeekday.getDate()) {
            matchedDates.push(currentDate.toISOString().split('T')[0]);
          }
        }
        break;
      case 'monthlySpecificDay':
        if (currentDate.getDate() === rule.day) {
          matchedDates.push(currentDate.toISOString().split('T')[0]);
        }
        break;
      case 'yearlySpecificDate':
        const [day, month] = rule.date.split('.').map(Number);
        if (
          currentDate.getDate() === day &&
          currentDate.getMonth() === month - 1
        ) {
          matchedDates.push(currentDate.toISOString().split('T')[0]);
        }
        break;
    }
  }
  return matchedDates;
}
