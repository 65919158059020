import server from '../index';
import { TakeAwayShift } from '../../types/takeAway';
import { Meal, Category } from '../../types/menu';

// SpecialTakeAway

type type = (restaurantId:string, takeAway?:boolean) => Promise<{ meals:Meal[], categories:Category[], isOpen:boolean, restaurant?: { minOrderValue?:{ pickup:number, delivery:number }, shifts:TakeAwayShift[] }, specialDays:any[], currentWaitingTimes: { pickup:null|string, delivery:null|string } }>

const loadMealData:type = async (restaurantId:string, takeAway = true) => {
    try {
        // @TODO add type
        const response = await server.get(takeAway ? `/v03/takeAway/${restaurantId}` : `/v02/menues/${restaurantId}`);

        const data = response.data;

        return data;

    } catch (error:any) {
        if(error.isAxiosError) {
            return { meals: [], categories: [], isOpen: false, specialDays: [], currentWaitingTimes: { pickup: null, delivery: null } }
        } else {
            throw error
        }
    }
}

export default loadMealData