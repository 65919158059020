import React, { useState } from 'react';
import IconButton from 'Components/Atoms/IconButton';
import { Sort } from 'Components/Atoms/Icons';
import Popover from 'Components/Atoms/Popover';
import Box from 'Components/Atoms/Box';
import Typography from 'Components/Atoms/Typography';
import Checkbox from 'Components/Atoms/Checkbox';
import RadioButtonGroup from 'Components/Atoms/RadioButtonGroup';
import Badge from 'Components/Atoms/Badge';
import Button from 'Components/Atoms/Button';
import useRestaurant from 'CustomHooks/useRestaurant';

export type SortButtonProps = {
  filtersOptions: {
    occassions: { id: string; title: string }[];
    spaces: { id: string; name: string }[];
    sort: { value: string; label: string }[];
  };
  filters: {
    occassions: string[];
    spaces: string[];
    sort: 'time' | 'createdAt' | 'tables';
    showWalkIns?: 'both' | 'hide' | 'only';
  };
  setFilters: React.Dispatch<
    React.SetStateAction<{
      occassions: string[];
      spaces: string[];
      sort: 'time' | 'createdAt' | 'tables';
      showWalkIns?: 'both' | 'hide' | 'only';
    }>
  >;
};

const SortButton = ({
  filtersOptions,
  filters,
  setFilters,
}: SortButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null);

  const handleClick = (e: React.SyntheticEvent) => setAnchorEl(e.currentTarget);

  const handleClose = (e: React.MouseEvent<Document>) => setAnchorEl(null);

  const handleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean = true
  ) => {
    const { name } = event.target;
    const type = name.split('.')[0];
    const value = name.split('.')[1];
    let newFilters = { ...filters };

    if (type === 'occassions' || type === 'spaces') {
      if (checked) {
        newFilters[type].push(value);
      } else {
        newFilters[type] = filters[type].filter((val: string) => val !== value);
      }
    } else if (type === 'sort') {
      const sortValue = event.target.value;
      if (
        sortValue === 'time' ||
        sortValue === 'createdAt' ||
        sortValue === 'tables'
      ) {
        newFilters[type] = sortValue;
      }
    } else if (type === 'showWalkIns') {
      const value = event.target.value;
      if (value === 'both') {
        delete newFilters[type];
      } else {
        newFilters[type] = value as 'hide' | 'only';
      }
    }
    setFilters(newFilters);
  };

  const { experimentalFlags } = useRestaurant();

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const activeFilters = [
    filters?.occassions?.length < filtersOptions?.occassions?.length,
    filters?.spaces?.length,
    filters?.showWalkIns,
  ].filter((x) => !!x).length;

  return (
    <>
      <IconButton size="small" onClick={handleClick}>
        <Badge number={activeFilters}>
          <Sort style={{ transform: 'scaleX(-1)' }} />
        </Badge>
      </IconButton>
      <Popover
        {...{ anchorEl, open, id }}
        width="auto"
        onClose={handleClose}
        placement="bottom-end"
      >
        <Button
          fullWidth
          disabled={!activeFilters}
          variant="secondary"
          style={{
            marginBottom: 8,
          }}
          onClick={() => {
            // setCurrentShift(null);
            setFilters((f) => ({
              ...f,
              occassions: filtersOptions.occassions.map((x) => x.id),
              spaces: [],
              sort: 'time',
              showWalkIns: undefined,
            }));
          }}
        >
          Reset Filter ({activeFilters})
        </Button>

        <Box style={{ marginBottom: 12, maxHeight: 300, overflow: 'scroll' }}>
          <Box className="flex space-between">
            <Typography
              variant="text-2"
              color="subdued"
              translation="reservations"
            >
              Occassions
            </Typography>
            <Typography
              variant="text-2"
              color="subdued"
              translation="reservations"
              weight="medium"
              onClick={() => setFilters((f) => ({ ...f, occassions: [] }))}
              style={{ cursor: 'pointer' }}
            >
              Alle abwählen
            </Typography>
          </Box>
          {filtersOptions.occassions.map((occ) => (
            <Checkbox
              key={'occ-' + occ.id}
              label={occ.title}
              name={'occassions.' + occ.id}
              checked={filters.occassions.includes(occ.id)}
              onChange={handleFilterChange}
              color="primary"
            />
          ))}
        </Box>

        <Box style={{ marginBottom: 12, maxHeight: 300, overflow: 'scroll' }}>
          <Typography
            variant="text-2"
            color="subdued"
            translation="reservations"
          >
            Spaces
          </Typography>
          {filtersOptions.spaces.map((space) => (
            <Checkbox
              key={'space-' + space.id}
              label={space.name}
              name={'spaces.' + space.id}
              checked={filters.spaces.includes(space.id)}
              onChange={handleFilterChange}
              color="primary"
            />
          ))}
        </Box>

        <Box>
          <Typography
            variant="text-2"
            color="subdued"
            translation="reservations"
          >
            Sort
          </Typography>
          <RadioButtonGroup
            options={filtersOptions.sort.map((s) => ({
              ...s,
              translation: 'reservations',
            }))}
            value={filters.sort}
            name="sort.reservations"
            onChange={(e) => handleFilterChange(e)}
          />
          {/* {filtersOptions.sort.map(s => (
            <Checkbox
              key={'sort-' + s.id}
              label={s.title}
              name={'sort.' + s.id}
              checked={filters.sort === s.id}
              onChange={handleFilterChange}
              color='primary'
              translation='reservations'
            />
          ))} */}
        </Box>

        <Box>
          <Typography
            variant="text-2"
            color="subdued"
            translation="reservations"
          >
            Walk Ins
          </Typography>
          <RadioButtonGroup
            options={[
              { value: 'both', label: 'Normal' },
              { value: 'hide', label: 'Verstecken' },
              { value: 'only', label: 'Nur Walk Ins' },
            ]}
            value={filters.showWalkIns ?? 'both'}
            name="showWalkIns.reservations"
            onChange={(e) => handleFilterChange(e)}
          />
          {/* {filtersOptions.sort.map(s => (
            <Checkbox
              key={'sort-' + s.id}
              label={s.title}
              name={'sort.' + s.id}
              checked={filters.sort === s.id}
              onChange={handleFilterChange}
              color='primary'
              translation='reservations'
            />
          ))} */}
        </Box>
      </Popover>
    </>
  );
};

export default SortButton;
