import React, { useMemo, useState } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import Typography from 'Components/Atoms/Typography';
import { ArrowDropDown } from 'Components/Atoms/Icons';
import Popover from 'Components/Atoms/Popover';
import List from './Components/List';
import Keyboard from './Components/Keyboard';
import useRestaurant from 'CustomHooks/useRestaurant';
import usePinAuth from 'CustomHooks/usePinAuth';
import internal from 'node:stream';

export type GuestsDropdownProps = {
  value?: number | null;
  onChange: (newValue: number) => void;
  style?: React.CSSProperties;
};

const GuestsDropdown = ({ value, onChange, style }: GuestsDropdownProps) => {
  const [anchorEl, setanchorEl] = useState<null | HTMLElement>(null);

  const [internalValue, setInternalValue] = React.useState<number | null>(
    value ?? null
  );

  React.useEffect(() => {
    if (internalValue === null && type === 'keyboard') {
      setInternalValue(value ?? null);
    }
  }, [value]);

  const handleKeyboardSubmit = () => {
    if (maxGuests && maxGuests < (internalValue ?? 0)) {
      authenticate(
        'reservations.moreThanOnWebsite.write',
        () => {
          setInternalValue(null);
          onChange(internalValue ?? value ?? 1);
        },
        () => {
          setInternalValue(null);
          onChange(value ?? 1);
        }
      );
    } else {
      setInternalValue(null);
      onChange(internalValue ?? value ?? 1);
    }

    setanchorEl(null);
  };

  const handleChange = setInternalValue;

  const { reservationSettings } = useRestaurant();

  const { authenticate, checkAuthentication } = usePinAuth();

  const maxGuests = useMemo(() => {
    const hasMaxGuests = checkAuthentication(
      'reservations.moreThanOnWebsite.write'
    );

    if (!hasMaxGuests) return null;

    return reservationSettings?.maxGuestsOnWebsite;
  }, [reservationSettings, checkAuthentication]);

  const [type, settype] = useState<'keyboard' | 'list'>(
    value && value > (maxGuests ?? 9) ? 'keyboard' : 'list'
  );

  React.useEffect(() => {
    if (!anchorEl) {
      if (value && value < (maxGuests ?? 10)) {
        settype('list');
      }
    }
  }, [anchorEl]);

  React.useEffect(() => {
    if (internalValue && type !== 'keyboard') {
      setInternalValue(null);
    }

    if (!internalValue && type === 'keyboard') {
      setInternalValue(value ?? null);
    }
  }, [type]);

  const VALUE = internalValue ?? value;

  console.log({
    VALUE,
    value,
    internalValue,
  });

  return (
    <>
      <Box
        outline
        background
        padding="sm"
        flex
        className="space-between clickable"
        onClick={(e) =>
          !!anchorEl
            ? type === 'keyboard'
              ? handleKeyboardSubmit()
              : setanchorEl(null)
            : setanchorEl(e.currentTarget)
        }
        style={style}
      >
        <div className="space-between" style={{ width: '100%' }}>
          {!!VALUE && (
            <Typography
              variant="text-3"
              style={{ width: 100 }}
              translationKey={
                VALUE > 1 ? `common_guestsNumber` : `common_guestNumber`
              }
              color="subdued"
            >
              {{ nb: VALUE }} {VALUE && VALUE > 2 ? ` Guests` : ` Guest`}
            </Typography>
          )}
          {!VALUE && <span></span>}
          <ArrowDropDown style={{ fill: 'var(--color-text-subdued)' }} />
        </div>
      </Box>
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => handleKeyboardSubmit()}
        placement="bottom"
        padding={0}
      >
        {type === 'list' && (
          <List
            value={value}
            maxGuests={maxGuests}
            onChange={(nV) => {
              if (maxGuests && maxGuests < nV) {
                authenticate('reservations.moreThanOnWebsite.write', () => {
                  onChange(nV);
                  setanchorEl(null);
                });
              } else {
                onChange(nV);
                setanchorEl(null);
              }
            }}
            onKeyboardOpen={() => {
              let anchor = anchorEl;
              authenticate('reservations.moreThanOnWebsite.write', () => {
                settype('keyboard');
                setanchorEl(anchor);
              });
            }}
          />
        )}
        {type === 'keyboard' && (
          <Keyboard
            value={internalValue}
            onChange={(nV) => {
              handleChange(nV);
            }}
            onClose={() => {
              handleKeyboardSubmit();
            }}
          />
        )}
      </Popover>
    </>
  );
};

export default GuestsDropdown;
