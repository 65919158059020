import { IconButton, InputBase } from '@material-ui/core';
import { DeleteForever } from '@material-ui/icons';
import { Amount } from '@mollie/api-client/dist/types/src/data/global';
import Typography from 'Components/Atoms/Typography';
import React from 'react';
import { Redeem } from 'types/vouchersV02';

const EMPTY_ITEM: {
  name: string;
  quantity: number;
  totalAmount: {
    currency: string;
    value: string;
  };
  unitPrice: {
    currency: string;
    value: string;
  };
  vatAmount: number;
  vatRate: string;
  type?: 'regular' | 'delivery_fee' | 'fee' | 'tip';
  sku?: string;
} = {
  name: '',
  quantity: 1,
  totalAmount: {
    currency: 'EUR',
    value: '0.00',
  },
  unitPrice: {
    currency: 'EUR',
    value: '0.00',
  },
  vatAmount: 0,
  vatRate: '19',
};

const Field: React.FC<{
  value: string | number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  right?: string;
  type?: string;
  maxWidth?: number;
  options?: string[];
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  hasError?: boolean;
}> = ({
  value,
  onChange,
  placeholder,
  right,
  type,
  maxWidth,
  onBlur,
  options,
  hasError = false,
}) => {
  return (
    <td style={{ border: '1px solid var(--color-border)', padding: '0px 6px' }}>
      {!options && (
        <InputBase
          value={value}
          onChange={onChange}
          disabled={!onChange}
          placeholder={placeholder}
          endAdornment={right}
          type={type}
          style={{
            maxWidth,
            minWidth: maxWidth,
            fontSize: 16,
            color: hasError ? 'var(--color-critical)' : 'var(--color-text)',
          }}
          onBlur={onBlur}
        />
      )}
      {!!options && (
        <select
          value={value}
          className="input-style"
          onChange={(e) => onChange && onChange(e as any)}
          style={{ maxWidth, minWidth: maxWidth, fontSize: 16 }}
        >
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      )}
    </td>
  );
};

const THead: React.FC<{
  children: string;
  maxWidth?: number;
}> = ({ children, maxWidth }) => {
  return (
    <td style={{ border: '1px solid var(--color-border)', padding: '2px 6px' }}>
      <Typography variant="text-2" style={{ fontSize: '13px' }}>
        {children}
      </Typography>
    </td>
  );
};

const ItemsTable: React.FC<{
  items: Redeem['items'];
  onChange: (newItems: Redeem['items']) => void;
  maxAmount?: Amount;
}> = ({ items = [], onChange, maxAmount }) => {
  const addItem = () => {
    onChange([...items, EMPTY_ITEM]);
  };

  const removeItem = (index: number) => {
    onChange(items.filter((_, i) => i !== index));
  };

  const updateName = (index: number, name: string) => {
    const newItems = [...items];
    newItems[index].name = name;
    onChange(newItems);
  };

  const recalcItem = (item: typeof items[0]) => {
    let clone = { ...item };

    let totalAmount = parseFloat(clone.unitPrice.value) * clone.quantity;
    let vatAmount =
      totalAmount - totalAmount / (1 + parseFloat(clone.vatRate) / 100);
    return {
      ...item,
      totalAmount: {
        value: totalAmount.toFixed(2),
        currency: item.totalAmount.currency,
      },
      vatAmount: vatAmount,
    };
  };

  const updateQuantity = (index: number, quantity: number) => {
    const newItems = [...items];
    newItems[index] = recalcItem({ ...newItems[index], quantity });

    onChange(newItems);
  };

  const updateUnitPrice = (
    index: number,
    unitPrice: string,
    recalc = false
  ) => {
    const newItems = [...items];
    if (recalc) {
      newItems[index] = recalcItem({
        ...newItems[index],
        unitPrice: { value: unitPrice, currency: 'EUR' },
      });
    } else {
      newItems[index].unitPrice.value = unitPrice;
    }

    onChange(newItems);
  };

  const updateVatRate = (index: number, vatRate: string) => {
    const newItems = [...items];
    newItems[index] = recalcItem({ ...newItems[index], vatRate });
    onChange(newItems);
  };

  const totalAmount = items.reduce(
    (acc, cV) => acc + parseFloat(cV.totalAmount.value),
    0
  );

  const totalVat = items.reduce((acc, cV) => acc + cV.vatAmount, 0).toFixed(2);

  return (
    <table
      style={{
        textAlign: 'left',
        borderSpacing: 'inherit',
        borderCollapse: 'collapse',
      }}
    >
      <thead>
        <tr>
          <THead>Name</THead>
          <THead>Quantity</THead>
          <THead>VAT</THead>
          <THead>Unit Price</THead>
          <THead>Total Amount</THead>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <tr key={index}>
            <Field
              value={item.name}
              placeholder="New Line"
              onChange={(e) => updateName(index, e.target.value)}
            />
            <Field
              type="number"
              value={item.quantity}
              maxWidth={60}
              right="x"
              onChange={(e) => updateQuantity(index, parseInt(e.target.value))}
            />
            <Field
              value={item.vatRate}
              options={['0', '7', '19']}
              maxWidth={55}
              right="%"
              onChange={(e) => updateVatRate(index, e.target.value)}
            />
            <Field
              type="number"
              value={item.unitPrice.value}
              onChange={(e) => updateUnitPrice(index, e.target.value)}
              right="€"
              maxWidth={80}
              onBlur={(e) =>
                updateUnitPrice(index, e.target.value.replace(',', '.'), true)
              }
            />
            <Field maxWidth={80} value={item.totalAmount.value} right="€" />
            <td style={{ paddingLeft: 10, minWidth: 41, maxWidth: 41 }}>
              <IconButton onClick={() => removeItem(index)} size="small">
                <DeleteForever />
              </IconButton>
            </td>
          </tr>
        ))}
        {(!items.length || !!items?.[items.length - 1]?.name) && (
          <tr
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              addItem();
            }}
          >
            <Field value="" placeholder="New Line" onChange={() => {}} />
            <Field
              value=""
              type="number"
              placeholder="1"
              maxWidth={60}
              right="x"
              onChange={() => {}}
            />
            <Field
              value=""
              type="number"
              placeholder="19"
              maxWidth={55}
              options={['0', '7', '19']}
              right="%"
              onChange={() => {}}
            />
            <Field
              value=""
              type="number"
              placeholder="0.00"
              right="€"
              onChange={() => {}}
              maxWidth={80}
            />
            <Field value="" maxWidth={80} placeholder="0.00" right="€" />
            <td style={{ paddingLeft: 10, minWidth: 41, maxWidth: 41 }}></td>
          </tr>
        )}
      </tbody>
      <tfoot>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td style={{ padding: '2px 6px' }}>
            <Typography
              variant="text-1"
              color={
                maxAmount && totalAmount > parseFloat(maxAmount.value)
                  ? 'error'
                  : 'textPrimary'
              }
              style={{ fontSize: '16px' }}
            >
              Total
            </Typography>
          </td>
          <Field
            value={totalAmount.toFixed(2)}
            maxWidth={80}
            right="€"
            hasError={maxAmount && totalAmount > parseFloat(maxAmount.value)}
          />
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td style={{ padding: '2px 6px' }}>
            <Typography variant="text-2" style={{ fontSize: '13px' }}>
              inkl. MwSt.
            </Typography>
          </td>
          <Field value={totalVat} maxWidth={80} right="€" />
          <td></td>
        </tr>
      </tfoot>
    </table>
  );
};

export default ItemsTable;
