import React, { useState } from 'react';
import Box from 'Components/Atoms/Box';
import { Combination } from 'types/reservations';
import MenuItem from 'Components/Atoms/MenuItem';

export type TableDropdownCombinationsProps = {
  value: null | string[];
  combinations: null | Combination[];
  onChange: (nV: string[]) => void;
};

const TableDropdownCombinations = ({
  value,
  combinations,
  onChange,
}: TableDropdownCombinationsProps) => {
  return (
    <>
      {combinations?.map((c) => {
        return (
          <MenuItem
            key={combinations.indexOf(c)}
            selected={value === c.tables}
            onClick={() => onChange(c.tables)}
          >
            <span>
              {c.tables.join(', ')}
              {c.alias?.length ? ` (${c.alias.join(', ')})` : ''}
            </span>
            <span style={{ color: 'var(--color-text-subdued)', marginLeft: 5 }}>
              {' '}
              {c.expression
                ? c.expression.replace(
                    'Kürzere Reservierungslänge',
                    'Kürzere. Reslänge'
                  )
                : ''}
            </span>
          </MenuItem>
        );
      })}
    </>
  );
};

export default TableDropdownCombinations;
