import Box from 'Components/Atoms/Box';
import Typography from 'Components/Atoms/Typography';
import React, { useMemo, useEffect } from 'react';
import { Guest, Reservation } from 'types/reservations';
import { languageHelper, noop, sourceHelper } from 'utils/helper';
import EditableField from './EditableField';
import { Translations } from 'utils/types';
import { Button } from '@material-ui/core';
import CustomElements from '../../NewReservationSidebar/Components/CustomElements';
import { CustomDataValue } from 'gastronaut-shared/types/helper/customElements';

export type ReservationContactProps = {
  guest: null | Guest;
  reservation: Reservation;
  onGuestAction: (id: string, type: string, payload?: any) => void;
  setHasOverflow: (value: { bottom: boolean; top: boolean }) => void;
  handleScrolling?: (
    event: React.UIEvent<HTMLDivElement | HTMLLabelElement | HTMLFormElement>
  ) => void;
};

const ReservationContact = ({
  guest,
  reservation,
  onGuestAction = noop,
  setHasOverflow,
  handleScrolling = noop,
}: ReservationContactProps) => {
  const contactField = useMemo(() => {
    let array: {
      id: string;
      label: string;
      value: any;
      editable: boolean;
      type?: string;
      action?: string;
      options?: {
        id: string | number | null;
        label?: string;
        translation?: null | Translations;
      }[];
    }[] = [
      {
        id: 'guest.name',
        label: 'Name',
        value: reservation.guest.name || guest?.name || '',
        editable: true,
        type: 'text',
        action: 'guest.name',
      },
      {
        id: 'guest.email',
        label: 'Email',
        value: reservation.guest.email || guest?.email || '-',
        editable: true,
        type: 'email',
        action: 'guest.email',
      },
      {
        id: 'guest.sendEmail',
        label: 'Emails senden',
        value:
          !!reservation.guest.email &&
          (reservation.source?.toLocaleLowerCase() !== 'inhouse' ||
            reservation.guest.sendEmail),
        editable:
          reservation.source?.toLocaleLowerCase() === 'inhouse' &&
          !!reservation.guest.email,
        type: 'checkbox',
        action: 'guest.sendEmail',
      },
      {
        id: 'guest.phone',
        label: 'Telephone',
        value: reservation.guest.phone || guest?.phone || '-',
        editable: true,
        type: 'text',
        action: 'guest.phone',
      },
      ...(guest?.phoneNumbers?.length ||
      reservation?.guest?.phoneNumbers?.length
        ? Array.from(
            new Set([
              ...(guest?.phoneNumbers ?? []),
              ...(reservation?.guest?.phoneNumbers ?? []),
            ])
          ).map((g, i) => ({
            id: 'guest.phoneNumbers[' + i + ']',
            label: 'Telefon ' + (i + 1),
            value: g,
            editable: true,
            type: 'text',
            action: 'guest.phoneNumbers[' + i + ']',
          }))
        : []),
      {
        id: 'guest.phoneNumbersAdd',
        label: 'Altern. Nummer hinzufügen',
        value: '-',
        editable: true,
        type: 'button',
        action: 'guest.phoneNumbersAdd',
      },
      {
        id: 'guest.language',
        label: 'Language',
        value: guest?.preferredLanguage,
        editable: true,
        type: 'select',
        action: 'guest.preferredLanguage',
        options: [
          { id: 'de', label: 'German' },
          { id: 'en', label: 'English' },
          { id: 'fr', label: 'French' },
        ],
      },
    ];

    if (!!guest && !!(guest.id || guest.guestId)) {
      array.push(
        {
          id: 'guest.company',
          label: 'Company',
          value: reservation?.guest?.company || guest.company || '-',
          editable: true,
          type: 'text',
          action: 'guest.company',
        },
        {
          id: 'guest.birthday',
          label: 'Birthday',
          value: reservation?.guest?.birthday || guest.birthday || '',
          editable: true,
          type: 'date',
          action: 'guest.birthday',
        },
        {
          id: 'guest.anniversary',
          label: 'Anniversary',
          value: reservation?.guest?.anniversary || guest.anniversary || '',
          editable: true,
          type: 'date',
          action: 'guest.anniversary',
        }
      );
    }

    if (guest?.previousPayments?.length) {
      array.push({
        id: 'guest.lifetimeValue',
        label: 'Lifetime Value',
        value:
          guest.previousPayments
            .reduce((acc, cV) => acc + cV.paidAmount, 0)
            .toFixed(2)
            .replace('.', ',') + '€',
        editable: false,
      });
    }

    array.push({
      id: 'source',
      label: 'Source',
      value: sourceHelper(reservation.source || '-'),
      editable: false,
    });

    array.push({
      id: 'customId',
      label: 'Custom Id',
      value: reservation.customId || '-',
      editable: false,
    });

    return array;
  }, [guest, reservation]);

  useEffect(() => {
    const container = document.getElementById('contact-container');
    if (!!container) {
      const overFlow = container.clientHeight < container.scrollHeight;
      setHasOverflow({ bottom: overFlow, top: false });
    }
  }, [setHasOverflow]);

  const [customData, setCustomData] = React.useState<
    Record<string, CustomDataValue>
  >(reservation.customData ?? {});

  return (
    <Box
      className="reservation-side-bar"
      id="contact-container"
      style={
        ['failed', 'done'].includes(reservation?.currentStatus || '')
          ? { maxHeight: 'calc(100% - 50px)' }
          : { maxHeight: 'calc(100% - 150px)' }
      }
      onScroll={(e) => handleScrolling(e)}
    >
      {contactField.map((f, i) =>
        f.type !== 'button' ? (
          <Box key={i} className="space-between reservation-field">
            <Typography
              variant="text-3"
              translation="reservations"
              style={{ whiteSpace: 'nowrap' }}
            >
              {f.label}
            </Typography>
            {f.editable || f.type === 'checkbox' ? (
              <EditableField
                field={f}
                onAction={onGuestAction}
                id={guest ? guest.id || guest.guestId || '' : ''}
              />
            ) : (
              <Typography
                variant="text-2"
                color="subdued"
                translation={null}
                style={{ marginRight: '1.5rem' }}
              >
                {f.value}
              </Typography>
            )}
          </Box>
        ) : (
          <Button
            key={i}
            variant="text"
            color="primary"
            fullWidth
            onClick={() =>
              onGuestAction(
                guest ? guest.id || guest.guestId || '' : '',
                f.action || f.id
              )
            }
          >
            {f.label}
          </Button>
        )
      )}
      <Box padding="sm">
        <CustomElements
          customData={customData}
          id={reservation.id}
          onCustomDataUpdate={(customData, complete) =>
            setCustomData((x) =>
              complete ? customData : { ...x, ...customData }
            )
          }
          updateInDatabase
        />
      </Box>
    </Box>
  );
};

export default ReservationContact;
