import React, { useMemo, useRef, useState } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import useCollection from 'CustomHooks/useCollection';
import { SiaSession } from 'gastronaut-shared/types/helper/sia';
import useRestaurant from 'CustomHooks/useRestaurant';
import { CallCard } from 'App/Dashboard/Screens/SiaCurrentDay/SiaCurrentDay';
import {
  dateHelper,
  daysFromToday,
  toEuropeanDate,
  wDayHelper,
} from 'utils/helper';
import CallCardComponent from 'App/Dashboard/Screens/SiaCurrentDay/Components/CallCard';
import Close from '@material-ui/icons/Close';
import useLocalStorageState from 'CustomHooks/useLocalStorageState';

export type MailboxAlertProps = {};

const MailboxAlert: React.FC<MailboxAlertProps> = ({}) => {
  const { restaurantId } = useRestaurant();

  const [started, setstarted] = useLocalStorageState(
    'started',
    Date.now() - 7 * 24 * 60 * 60000
  );

  const [callsWithMailbox] = useCollection<SiaSession>('calls', {
    filter: [
      ['restaurantId', '==', restaurantId],
      ['hasMailbox', '==', true],
      ['createdAt', '>=', started],
    ],
  });

  const callCards = useMemo(
    () =>
      callsWithMailbox.data
        .sort((a, b) => b.createdAt - a.createdAt)
        .map((call) => {
          let caller = call.guest?.name
            ? `${call.guest.name} (${call.callerId})`
            : call.callerId;

          let duration = (call.endedAt ?? call.createdAt) - call.createdAt;

          let dateObj = new Date(call.createdAt);

          const dateDiff = daysFromToday(dateObj);

          let time = dateObj.toLocaleTimeString('de-DE').slice(0, 5);
          let ts = '';

          if (dateDiff === 0) {
            ts = 'Heute, ' + time;
          } else if (dateDiff === -1) {
            ts = 'Gestern, ' + time;
          } else if (dateDiff > -7) {
            ts = wDayHelper(dateObj.getDay(), true, 'de') + ', ' + time;
          } else {
            ts = toEuropeanDate(dateHelper(dateObj)) + ', ' + time;
          }

          return {
            id: call.callSid,
            ts,
            type: 'mailbox',
            data: call,
            caller,
            duration,
            takenCareOf: call.takenCareOf,
          } as CallCard;
        }),
    [callsWithMailbox]
  );

  const handleClose = () => {
    setstarted(Date.now());
  };

  if (!callCards.length) {
    return <></>;
  }

  return (
    <Box className="mailbox-container">
      <div className="close-button-container">
        <button color="inherit" className="close-button" onClick={handleClose}>
          Close All <Close color="inherit" />
        </button>
      </div>

      {callCards.map((call) => (
        <CallCardComponent onDashboard {...call} />
      ))}
    </Box>
  );
};

export default MailboxAlert;
