import React, { useState, useEffect } from "react";
import { FloorPlan, ResSettings } from "types/reservations";
import { ReservationSettings } from "types/settings";
import { db } from "utils/firebase";
import useRestaurant from "./useRestaurant";

const useInitializeReservations = (restaurantId: string | null) => {
  const [floorPlans, setfloorPlans] = useState<FloorPlan[]>([]);
  // const [reservationSettings, setReservationSettings] = useState<
  //   null | (ReservationSettings & { settings: ResSettings })
  // >(null);
  const [listener, setlistener] = useState<any[]>([]);

  const { reservationSettings, apps } = useRestaurant();

  useEffect(() => {
    if (listener.length) {
      listener.forEach((x) => x());
    }

    let unsubscribe = db
      .collection(`restaurants/${restaurantId}/floorPlans`)
      .onSnapshot(
        (docSnapshot) => {
          let FloorPlansArray: FloorPlan[] = [];

          docSnapshot.docs.forEach((doc) => {
            FloorPlansArray.push({ ...doc.data(), id: doc.id } as FloorPlan);
          });

          setfloorPlans(FloorPlansArray);
        },
        (err) => {
          console.log("Error on loading floorplans", err);
        }
      );

    setlistener([unsubscribe]);

    return () => {
      if (listener.length) {
        listener.forEach((x) => x());
      }
    };
  }, [restaurantId]);

  // useEffect(() => {
  //   if (restaurantId) {
  //     db.collection(`restaurants/${restaurantId}/settings`)
  //       .doc("reservationsV02")
  //       .get()
  //       .then((doc) => {
  //         if (!doc.exists) {
  //           setReservationSettings(null);
  //           return;
  //         }

  //         const data = doc.data() as ReservationSettings;

  //         setReservationSettings({
  //           ...data,
  //           settings: {
  //             hideOccassion: data.occassions.length === 1,
  //             hideResLength: false,
  //           },
  //         });
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //         setReservationSettings(null);
  //       });

  //     //   db.collection(`restaurants/${restaurantId}/floorPlans`)
  //     //     .get()
  //     //     .then(docs => {
  //     //       let arr: FloorPlan[] = [];

  //     //       docs.forEach(doc => {
  //     //         let data = { ...doc.data(), id: doc.id };
  //     //         arr.push(data as FloorPlan);
  //     //       });

  //     //       setfloorPlans(arr);
  //     //     })
  //     //     .catch(err => {
  //     //       console.error(err);
  //     //       setfloorPlans([]);
  //     //     });
  //   } else {
  //     setReservationSettings(null);
  //   }
  // }, [restaurantId]);

  return {
    ...reservationSettings,
    signatureRequired: !!reservationSettings?.signatureRequired,
    settings: {
      hideOccassion: reservationSettings?.occassions?.length === 1,
      hideResLength: false,
    },
    floorPlans,
  };
};

export default useInitializeReservations;
