import React from "react";
import { useTranslation } from "react-i18next";
import { monthHelper, wDayHelper } from "utils/helper";

function shiftedWDay(date: Date) {
  let wday = date.getDay();

  if (wday === 0) {
    wday = 6;
  } else {
    wday -= 1;
  }

  return wday;
}

function toEuropeanDate(date: Date, showYear = false) {
  let [yyyy, mm, dd] = date.toISOString().split("T")[0].split("-");

  return `${dd}.${mm}.${showYear ? yyyy.slice(-2) : ""}`;
}

function shiftDateByDays(date: Date, shiftBy = 0) {
  let newDate = new Date(date);

  return new Date(newDate.setDate(newDate.getDate() + shiftBy));
}

const useDateString = (
  day?: string,
  type: "day" | "week" | "month" = "day"
) => {
  const { t } = useTranslation("common");

  if (!day) return "";

  let dateObj = new Date(day);

  let [yyyy, mm, dd] = day.split("-");

  let showYear = new Date().getFullYear() !== +yyyy;

  if (type === "day") {
    let wDay = t(wDayHelper(dateObj.getDay()));

    let month = t(monthHelper(mm));

    return `${wDay}. ${dd}. ${month}${showYear ? " " + yyyy.slice(2, 4) : ""}`;
  } else if (type === "week") {
    let wday = shiftedWDay(dateObj);

    let startOfWeek = toEuropeanDate(shiftDateByDays(dateObj, 0 - wday), false);

    let endOfWeek = toEuropeanDate(shiftDateByDays(dateObj, 6 - wday), true);

    return `${startOfWeek} - ${endOfWeek}`;
  } else if (type === "month") {
    let month = t(monthHelper(mm));

    return `${month}${showYear ? " " + yyyy.slice(2, 4) : ""}`;
  }
};

export default useDateString;
