import React from "react";
import Box from "Components/Atoms/Box";
import "./styles.scss";
import Card from "../Card";
import IconButton from "Components/Atoms/IconButton";
import {
  CloseOutlined,
  Warning,
  Info,
  Error,
  CheckCircle,
} from "Components/Atoms/Icons";
import Typography from "Components/Atoms/Typography";
import { Translations } from "utils/types";
import Button from "Components/Atoms/Button";

enum Severity {
  ERROR = "error",
  SUCCESS = "success",
  WARNING = "warning",
  INFO = "info",
}

export type ToastProps = {
  id: string | number;
  severity: Severity;
  description: string;
  descriptionTranslation?: Translations;
  width?: number;
  onClose?: (id: string | number) => void;
  onRevert?: VoidFunction;
};

const Toast: React.FC<ToastProps> = ({
  id,
  severity,
  width,
  description,
  onClose,
  onRevert,
  descriptionTranslation,
}) => {
  return (
    <Box
      flex
      elevation={2}
      padding="md"
      className={`toast ${severity}BodyWrapper mg-bt-sm`}
      style={{ width: width || "330px" }}
    >
      <Box width="auto" className="alignErrorIcon">
        {severity === "error" ? (
          <Error className="logo" />
        ) : severity === "success" ? (
          <CheckCircle className="logo" />
        ) : severity === "warning" ? (
          <Warning className="logo" />
        ) : (
          <Info className="logo" />
        )}
      </Box>
      <Box className="flex-column" style={{ alignItems: "flex-start" }}>
        <Box flex>
          <Box>
            <Typography
              variant="text-3"
              weight="medium"
              className="severityTitle"
            >
              {severity}
            </Typography>
          </Box>
          <Box flex width="auto" style={{ justifyContent: "flex-end" }}>
            {!!onClose && (
              <IconButton onClick={() => onClose?.(id)}>
                <CloseOutlined className="closeBtn" />
              </IconButton>
            )}
          </Box>
        </Box>
        <Box className="descriptionWrapper">
          <Typography
            variant="text-3"
            color="subdued"
            translation={descriptionTranslation}
          >
            {description}
          </Typography>
        </Box>
        {!!onRevert && (
          <Button
            onClick={() => {
              onClose?.(id);
              onRevert();
            }}
            variant="outlined"
            style={{ padding: "0.25rem 0.5rem", marginTop: 8 }}
          >
            Undo
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default Toast;
