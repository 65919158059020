import Box from "Components/Atoms/Box";
import Typography from "Components/Atoms/Typography";
import React, { useMemo } from "react";
import { Block as BlockType } from "utils/types";

type BlockProps = {
  block: BlockType;
  maxvalue: number;
  clientHeight: number | any;
};
type GroupProps = {
  res: number;
  maxvalue: number;
  clientHeight: number | any;
};

const timeString = ({ block, time }: BlockType) => {
  if (block % 2) {
    return " ";
  } else if (!(block % 4)) {
    return time;
  } else {
    return ":30";
  }
};

const Group = ({ res, maxvalue, clientHeight }: GroupProps) => {
  const GraphHeight = ((clientHeight - 45) / maxvalue) * res;
  return (
    <Box
      className="group"
      style={{ background: "var(--color-primary)", height: GraphHeight }}
    ></Box>
  );
};

const Block = ({ block, maxvalue, clientHeight }: BlockProps) => {
  const totalGuests = useMemo(
    () => block.reservations.reduce((acc: number, cV) => acc + cV.guests, 0),
    [block]
  );

  return (
    <Box
      flex
      className="flex-column"
      style={{ justifyContent: "flex-end", minWidth: 26, width: 26 }}
    >
      {!!totalGuests && (
        <>
          <Typography
            variant="text-3"
            textAlign="center"
            style={{ marginBottom: 2 }}
          >
            {totalGuests}
          </Typography>
          <Group res={totalGuests} {...{ maxvalue, clientHeight }} />
        </>
      )}

      <Typography
        variant="text-4"
        color="subdued"
        textAlign="left"
        style={{ marginTop: 8, height: 16, width: "100%" }}
        translation={null}
      >
        {timeString(block)}
      </Typography>
    </Box>
  );
};

export default Block;
