export const extractKeys = (data, previousKey = '') => {
  return Object.keys(data ?? {}).reduce((acc, cV) => {
    if (data[cV] && typeof data[cV] === 'object' && !Array.isArray(data[cV])) {
      acc.push(...extractKeys(data[cV], `${previousKey}${cV}.`));
    } else {
      acc.push(`${previousKey}${cV}`);
    }

    return acc;
  }, []);
};

export const createMapWithNoNestedItems = (
  inputData,
  keys,
  ommittedKeys = []
) => {
  let data = {};

  let filteredKeys = keys.filter((k) => !ommittedKeys.includes(k));

  filteredKeys.forEach((k) => {
    let [key, ...parts] = k.split('.');

    let x = inputData?.[key] ?? '';

    parts.forEach((k) => {
      x = x?.[k] ?? '';
    });

    data[k] = x;
  });

  return data;
};

export const jsonToCSV = (arr = []) => {
  const headers = extractKeys(arr[0]);
  headers.filter((h) => h !== 'profileData.data.pictures');
  const flatArr = arr.map((i) => createMapWithNoNestedItems(i, headers));
  return flatArr;
};

export function convertArrayOfObjectsToCSV(args) {
  var result, ctr, keys, columnDelimiter, lineDelimiter, data;

  data = args.data || null;
  if (data == null || !data.length) {
    return null;
  }

  columnDelimiter = args.columnDelimiter || ',';
  lineDelimiter = args.lineDelimiter || '\n';

  keys = extractKeys(data[0]);

  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  data.forEach(function (item) {
    ctr = 0;
    keys.forEach(function (key) {
      if (ctr > 0) result += columnDelimiter;

      result += `"${item[key]}"`;
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

export function downloadCSV(args, data) {
  var data, filename, link;
  var csv = convertArrayOfObjectsToCSV({
    data: jsonToCSV(data.response),
  });
  if (csv == null) return;

  filename = args?.filename || 'export.csv';

  if (!csv.match(/^data:text\/csv/i)) {
    csv = 'data:text/csv;charset=utf-8,' + csv;
  }
  data = encodeURI(csv);

  console.log('csv', csv);

  link = document.createElement('a');
  link.setAttribute('href', data);
  link.setAttribute('download', filename);
  link.click();
}
