import React, { useEffect } from "react";
import Box from "Components/Atoms/Box";
import Typography from "Components/Atoms/Typography";
import Button from "Components/Atoms/Button";
import Checkbox from "Components/Atoms/Checkbox";
import Add from "@material-ui/icons/Add";

type State = {
  spaces: string[];
  occassions: string[];
  matchBoth?: boolean; // matchBoth = TRUE => and
};

export type OccassionsAndSpacesPopoverProps = {
  state: State;
  onChange: (newState: State) => void;
  occassions: { id: string; title: string }[];
  spaces: { id: string; title: string }[];
  stateOccassions: any;
  stateSpaces: any;
  onAddOccassion?: () => void;
};

const OccassionsAndSpacesPopover: React.FC<OccassionsAndSpacesPopoverProps> = ({
  state,
  occassions,
  spaces,
  onChange,
  stateOccassions,
  stateSpaces,
  onAddOccassion,
}) => {
  const handleChangeOcc = (id: any) => {
    let newOccassions: string[] = [];
    stateOccassions.length === 0 && newOccassions.push(id);
    stateOccassions.forEach((o: string) => {
      if (o === id) {
        newOccassions = stateOccassions.filter((elem: string) => elem !== id);
      } else if (stateOccassions.indexOf(o) === 0) {
        newOccassions = [...stateOccassions];
        !newOccassions.includes(id) && newOccassions.push(id);
      }
    });

    onChange({
      ...state,
      occassions: newOccassions.filter((o) =>
        occassions.find((occ) => occ.id === o)
      ),
    });
  };

  const handleChangeSpaces = (id: any) => {
    let newSpaces: string[] = [];
    stateSpaces.length === 0 && newSpaces.push(id);
    stateSpaces.forEach((o: string) => {
      if (o === id) {
        newSpaces = stateSpaces.filter((elem: string) => elem !== id);
      } else if (stateSpaces.indexOf(o) === 0) {
        newSpaces = [...stateSpaces];
        !newSpaces.includes(id) && newSpaces.push(id);
      }
    });
    onChange({
      ...state,
      spaces: newSpaces.filter((s) => spaces.find((sp) => sp.id === s)),
    });
  };

  useEffect(() => {
    if (
      state.occassions.length + state.spaces.length === 1 &&
      state.matchBoth
    ) {
      onChange({
        ...state,
        matchBoth: false,
      });
    }
  }, [state]);

  return (
    <>
      <Typography color="subdued" variant="text-4">
        Occassions
      </Typography>
      <Box>
        {occassions.map((occassion) => (
          <Checkbox
            key={occassion.id}
            checked={stateOccassions.includes(occassion.id) ? true : false}
            onChange={() => {
              handleChangeOcc(occassion.id);
            }}
            label={occassion.title}
            color="primary"
          />
        ))}
        {!!onAddOccassion && (
          <Button
            onClick={onAddOccassion}
            style={{
              background: "var(--color-btn-default)",
              padding: "4px 10px",
              fontSize: "14px",
              lineHeight: "19.6px",
              color: "var(--color-grey-9)",
            }}
            endIcon={(p) => <Add {...p} />}
            translation="calendar"
          >
            Add Occassion
          </Button>
        )}
      </Box>

      <Typography color="subdued" variant="text-4">
        Spaces
      </Typography>
      <Box>
        {spaces.map((space) => (
          <Checkbox
            key={space.id}
            checked={stateSpaces.includes(space.id) ? true : false}
            onChange={() => {
              handleChangeSpaces(space.id);
            }}
            label={space.title}
            color="primary"
          />
        ))}
      </Box>

      {!!stateSpaces.length && !!stateOccassions.length && (
        <>
          <Typography
            color="subdued"
            variant="text-4"
            style={{ marginBottom: 4 }}
            translation="settings"
          >
            Match Space and/or Occassion
          </Typography>
          <Box flex style={{ marginBottom: 4 }}>
            <Button
              variant={state.matchBoth === false ? "primary" : "default"}
              style={{ padding: 4, borderRadius: 4, marginRight: 8 }}
              onClick={() => onChange({ ...state, matchBoth: false })}
              translation="calendar"
            >
              Space or Occassion
            </Button>

            <Button
              variant={state.matchBoth === true ? "primary" : "default"}
              style={{ padding: 4, borderRadius: 4 }}
              onClick={() => onChange({ ...state, matchBoth: true })}
              translation="calendar"
            >
              Space & Occassion
            </Button>
          </Box>
          <Typography color="subdued" variant="text-4" translation="calendar">
            Should both Space and Occassion match for a table. Or only one of
            them
          </Typography>
        </>
      )}
    </>
  );
};

export default OccassionsAndSpacesPopover;
