import { CalendarShift } from "App/Calendar/Components/Week";
import React, { useContext, useMemo } from "react";
import dayjs from "dayjs";
import { CalendarContext } from "Contexts/CalendarContext";

export type SeperatedShift = CalendarShift & {
  multiple?: boolean;
  shifts?: CalendarShift[];
  offset?: number; // default = 0 ; either 0 | 1 | 2
  width?: number; // default = 1; either 0.5 | 1
};

function overlap(
  a: { start: number; close: number },
  b: { start: number; close: number }
) {
  let start = Math.min(a.start, b.start),
    close = Math.max(a.close, b.close);

  for (let i = start; i < close; i++) {
    if (a.start <= i && a.close > i && b.start <= i && b.close > i) return true;
  }

  return false;
}

const shiftByOne = (n: number) => (n === 0 ? 6 : n - 1);

const useShiftSeperator = (shifts: CalendarShift[], dayWidth: number) => {
  const { currentShift, ActiveShiftProps } = useContext(CalendarContext);

  const activeShift = ActiveShiftProps?.activeShift;

  return useMemo(() => {
    function isActiveShift(s: CalendarShift) {
      if (activeShift?.shift?.id !== s.id) return false;

      if (activeShift.date === s.date) return true;

      if (
        activeShift.allUpcomingShifts &&
        activeShift.date <= s.date &&
        (!s.endAt || s.endAt >= s.date)
      )
        return true;

      return false;
    }

    function isActive(s: CalendarShift) {
      return (
        (currentShift?.shiftId === s.id && currentShift?.date === s.date) ||
        isActiveShift(s)
      );
    }

    function shiftReducer(
      acc: SeperatedShift[],
      cV: CalendarShift,
      i: number,
      arr: CalendarShift[]
    ) {
      let usedShifts = Array.from(
        new Set(
          acc
            .filter((s) => s.date === cV.date)
            .map((s) => s?.shifts?.map((s) => `${s.id}__${s.date}`) ?? [])
            .flat(1)
        )
      );

      let ID = `${cV.id}__${cV.date}`;

      if (usedShifts.includes(ID)) return acc;

      let overlappingShifts = arr.filter(
        (s) =>
          s.date === cV.date &&
          // (!isActive(s) || ID === `${s.id}__${s.date}`) &&
          !usedShifts.includes(`${s.id}__${s.date}`) &&
          overlap(cV, s)
      );

      if (isActive(cV)) console.log({ overlappingShifts, cV });

      let isCurrentShift = isActive(cV);

      if (overlappingShifts.length < 2) {
        return [...acc, cV];
      }

      let multiple =
        !isCurrentShift &&
        !overlappingShifts.find((x) => isActive(x)) &&
        overlappingShifts.length >= 3;

      if (multiple) {
        let start = Math.min(...overlappingShifts.map((x) => x.start)),
          close = Math.max(...overlappingShifts.map((x) => x.close));

        let overlappingShiftIds = overlappingShifts.map((x) => x.id);

        let additional = arr.filter(
          (s) =>
            s.date === cV.date &&
            // !isActive(s) &&
            !usedShifts.includes(`${s.id}__${s.date}`) &&
            !overlappingShiftIds.includes(s.id) &&
            overlap({ start, close }, s)
        );

        start = Math.min(start, ...additional.map((x) => x.start));
        close = Math.max(close, ...additional.map((x) => x.close));

        if (!additional.find((x) => isActive(x))) {
          return [
            ...acc,
            {
              ...cV,
              start,
              close,
              shifts: [...overlappingShifts, ...additional],
              multiple: true,
            },
          ];
        }
      }

      let positions = [0, 2, 1, 0, 2, 1, 0];

      let overlappingShiftIds = arr
        .filter(
          (s) =>
            s.date === cV.date &&
            !usedShifts.includes(`${s.id}__${s.date}`) &&
            overlap(cV, s)
        )
        .map((x) => x.id);

      console.log({ overlappingShiftIds });

      let indexForPos = overlappingShiftIds.indexOf(cV.id);

      return [
        ...acc,
        {
          ...cV,
          offset: positions[indexForPos],
          width: 0.5,
        },
      ];
    }

    return shifts
      .sort((a, b) => a.start - b.start)
      .reduce(shiftReducer, [])
      .map(({ multiple = false, shifts, ...shift }) => {
        const dayOfWeek = dayjs(shift.date).day();

        let top = shift.start ? 87 + shift.start * 8 - 4 * 32 : 0; // 87 = Table Header height
        let bareLeft = dayWidth * shiftByOne(dayOfWeek) + 51;
        let width = dayWidth * (shift.width ?? 1);

        let quarterDayWidth = dayWidth / 4;

        let left = bareLeft + (shift.offset ?? 0) * quarterDayWidth;

        return {
          position: {
            top,
            left,
            width,
          },
          multiple,
          shift,
          otherShifts: shifts,
        };
      });
  }, [shifts, dayWidth, currentShift, activeShift]);
};

export default useShiftSeperator;
