import React, { useContext } from "react";
import Box from "Components/Atoms/Box";
import "./styles.scss";
import Typography from "Components/Atoms/Typography";
import { RestaurantContext } from "Contexts/RestaurantContext";
import { Products } from "utils/types";
import { Event } from "Components/Atoms/Icons";
import { ShiftType } from "types/shifts";

export type SelectCalendarProps = {
  warnOnLeave?: boolean;
  onLeave?: VoidFunction;
  date?: string;
  current: ShiftType;
  onCalendarChange: (id: ShiftType) => void;
};

type CalendarOptionType = {
  product: Products;
  title: string;
  topTitle: string;
  id: string;
};

const CALENDAR_OPTIONS: CalendarOptionType[] = [
  {
    id: "reservation",
    product: "reservation",
    topTitle: "Tables",
    title: "Reservation Calendar",
  },
  {
    id: "guestOrdering",
    product: "guest-ordering",
    topTitle: "Orders",
    title: "Guest Ordering",
  },
  {
    id: "takeAway",
    product: "delivery",
    topTitle: "Orders",
    title: "Take Away",
  },
  {
    id: "happyHours",
    product: "guest-ordering",
    topTitle: "Extra",
    title: "Happy Hours",
  },
];

const TOP_TITLES: string[] = ["Tables", "Orders", "Extra"];

const CalendarLink = ({
  option,
  onClick,
  isCurrent,
}: {
  option: CalendarOptionType;
  onClick: (id: string) => void;
  isCurrent: boolean;
}) => {
  return (
    <Box
      className={isCurrent ? "oneOption currentOption" : "oneOption"}
      flex
      onClick={() => onClick(option.id)}
    >
      <Event className="eventLogo" />
      <Typography variant="text-3" translation="calendar" color="inherit">
        {option.title}
      </Typography>
    </Box>
  );
};

const SelectCalendar: React.FC<SelectCalendarProps> = ({
  warnOnLeave = false,
  date,
  onLeave,
  current,
  onCalendarChange,
}) => {
  const { products, alert } = useContext(RestaurantContext);

  const restaurantOptions = CALENDAR_OPTIONS.filter((c) =>
    products.includes(c.product)
  );

  const handleClick = (id: string) => {
    if (warnOnLeave) {
      alert({
        title: "Are you sure?",
        description: "You will loose the current progress!",
        onSubmit: () => {
          onLeave?.();
          onCalendarChange(id as ShiftType);
        },
      });
    } else {
      onLeave?.();
      onCalendarChange(id as ShiftType);
    }
  };

  return (
    <Box className="selectCalendar">
      <Typography variant="h5" translation="calendar">
        Select the calendar
      </Typography>
      {TOP_TITLES.map((topTitle) => (
        <>
          {!!restaurantOptions.filter((opt) => opt.topTitle === topTitle)
            .length && (
            <Box className="optionSection">
              <Typography variant="text-3" translation="calendar">
                {topTitle}
              </Typography>
              {restaurantOptions
                .filter((o) => o.topTitle === topTitle)
                .map((opt) => (
                  <CalendarLink
                    option={opt}
                    onClick={handleClick}
                    isCurrent={current === opt.id}
                  />
                ))}
            </Box>
          )}
        </>
      ))}
    </Box>
  );
};

export default SelectCalendar;
