import React, { useMemo } from "react";
import Box from "Components/Atoms/Box";
import "./styles.scss";
import Card, { CardProps } from "../Card";
import { Modify, TranslationObject, Translations } from "utils/types";
import { SvgIcon, useMediaQuery } from "@material-ui/core";
import Typography from "Components/Atoms/Typography";
import EmptyState from "./Components/EmptyState";

const PI = Math.PI;

const SIZE = 200;

const HSIZE = 100;

const COLORS = ["#10CCB9", "#6369D1", "#EE4266", "#F6AE2D", "#E1EF1A"];

type DataSet = {
  id: string | number | null;
  label: string;
  value: number;
};

export type PieChartCardProps = {
  data: DataSet[];
  pieSize?: number | string;
};

const PieChartCard = ({
  title,
  titleTranslation,
  data = [],
  pieSize = 56,
  ...props
}: Modify<CardProps, PieChartCardProps>) => {
  const { enhancedData = [], total = 0 } = useMemo(() => {
    let total = data.reduce((acc: number, cV) => acc + cV.value, 0);
    let past = 0;

    let arr = [];

    let d = data.sort((a, b) => b.value - a.value);

    for (let i = 0; i < d.length; i++) {
      const dataSet = d[i];
      let pct = Math.ceil((dataSet.value / total) * 360) / 360;

      let rad = (past / total) * 360 - 90;

      arr.push({ ...dataSet, pct, rad, color: COLORS[i] });
      past += dataSet.value;
    }

    return { enhancedData: arr, total };
  }, [data]);

  const isMobile = useMediaQuery("(max-width: 450px)");

  return (
    <Card
      {...props}
      title={title}
      titleTranslation={titleTranslation}
      titleAlignment="center"
      hideHeader={isMobile}
      bodyProps={{ padding: "md", flex: true, className: "center flex-column" }}
    >
      {data.length && total ? (
        <>
          {isMobile && (
            <Typography
              variant="text-2"
              weight="bold"
              style={{ marginBottom: 16 }}
            >
              {title}
            </Typography>
          )}
          <svg
            height={pieSize}
            width={pieSize}
            style={{ marginBottom: !isMobile ? 24 : 12 }}
            viewBox={`0 0 ${SIZE} ${SIZE}`}
          >
            <circle r={HSIZE} cx={HSIZE} cy={HSIZE} fill="transparent"></circle>
            {enhancedData.map(({ id, pct, rad, color }) => (
              <circle
                key={id}
                r={HSIZE / 2}
                cx={HSIZE}
                cy={HSIZE}
                fill="transparent"
                stroke={color}
                strokeWidth={HSIZE}
                strokeDasharray={`calc(${pct} * ${HSIZE * PI}) ${HSIZE * PI}`}
                transform={`rotate(${rad} ${HSIZE} ${HSIZE})`}
              ></circle>
            ))}
          </svg>

          <Box
            flex
            className="flex-column"
            width="auto"
            style={{ alignItems: "flex-start", height: "100%" }}
          >
            {enhancedData.map(({ id, label, color, value }) => (
              <Box
                flex
                className="mg-bt-sm"
                key={id}
                width="auto"
                style={{ whiteSpace: "nowrap" }}
              >
                <span
                  style={{
                    background: color,
                    width: 8,
                    height: 8,
                    borderRadius: 2,
                    marginRight: isMobile ? 8 : 16,
                  }}
                />
                <Typography
                  variant="text-3"
                  style={{
                    maxWidth: "9ch",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                  color={isMobile ? "subdued" : "textPrimary"}
                >
                  {label}
                </Typography>
                <Typography
                  variant="text-3"
                  translation={null}
                  color={isMobile ? "subdued" : "textPrimary"}
                >
                  : {value}
                </Typography>
              </Box>
            ))}
          </Box>
        </>
      ) : (
        <>
          <EmptyState
            style={{ width: pieSize, height: pieSize, marginBottom: 16 }}
          />
          <Typography variant="text-3" textAlign="center" component="p">
            Currently, there is no data to show
          </Typography>
        </>
      )}
    </Card>
  );
};

export default PieChartCard;
