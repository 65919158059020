import server from '../utils/server';
import React, { useState } from 'react';
import useLanguage from './useLanguage';
import useExperimentalFlags from './useExperimentalFlags';

const usePhoneNumberValidator = (callback?: (number: string) => void) => {
  const { language } = useLanguage();

  const [error, seterror] = useState<Record<string, string> | null>(null);

  const experimentalFlags = useExperimentalFlags();

  const ping = () => seterror(null);

  const validatePhoneNumber = async (number: string) => {
    const { valid, message, result } = (
      await server
        .get<{
          valid: boolean;
          message?: Record<string, string>;
          result?: { phone_number: string };
        }>(`/v03/reservations/phoneCheck/${number}`)
        .catch(
          (err) =>
            err?.response ?? {
              data: {
                valid: false,
                message: { de: err.message, en: err.message },
              },
            }
        )
    ).data;

    if (!valid && message) {
      seterror(message);
    } else {
      if (callback && result?.phone_number) callback(result.phone_number);
      seterror(null);
    }

    return;
  };

  const handleBlur = (e: any) => {
    console.log({ ev: e, target: e.target });

    if (e.target.value.startsWith('+0')) {
      if (experimentalFlags?.data?.phoneDefaultCountry === 'at') {
        validatePhoneNumber(e.target.value.replace('+0', '+43'));
      } else {
        validatePhoneNumber(e.target.value.replace('+0', '+49'));
      }

      return;
    }

    validatePhoneNumber(e.target.value);
  };

  return {
    error: error ? error?.[language as any] ?? error.de : null,
    validatePhoneNumber,
    ping,
    handleBlur,
    hook: {
      helperText: error ? error?.[language as any] ?? error.de : undefined,
      error: !!error,
      inputProps: {
        onKeyDown: () => seterror(null),
        onBlur: handleBlur,
      },
    },
  };
};

export default usePhoneNumberValidator;
