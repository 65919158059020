import React, { useEffect, useState } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import axios from 'axios';
import Typography from 'Components/Atoms/Typography';
import useLocalStorageState from 'CustomHooks/useLocalStorageState';
import Dropdown from 'Components/Molecules/Dropdown';
import { CircularProgress } from '@material-ui/core';
import Tooltip from 'Components/Atoms/Tooltip';
import { Info, InfoOutlined } from '@material-ui/icons';

export type CallEventsPerMonth = {
  id: string;
  created_at: string;
  month: string;
  restaurant_id: string;
  calls: number;
  minutes: number;
  redirect_minutes: number;
  mailbox_minutes: number;
  sia_reservations: number;
  non_reservation_minutes: number;
};

function leftPad(str: string, len: number, ch = '0') {
  return new Array(len - str.length).fill(ch).join('') + str;
}

function thousandDot(num: number) {
  let numStr = num.toFixed(0);

  if (numStr.length < 4) return numStr;

  return (
    numStr.slice(0, numStr.length - 3) + '.' + numStr.slice(numStr.length - 3)
  );
}

export type Stats = {
  numberOfCalls: number;
  numberOfWarnings: number;
  numberOfErrors: number;
  numberOfReservations: number;
  averageCallLengthInSec: number;
  numberOfSuccessfulRedirects: number;
  numberOfFailedRedirects: number;
  numberOfNoSeats: number;
  numberOfNoAction: number;
  numberOfMessagesPlayed: number;
  timeSavedInMinutes: number;
  numberOfMinutes: number;
  numberOfReservationGuests: number;
  billableMinutes: number;
  projectedCosts: number;
  successfulCalls: number;
  projectedCostsRedirectOnly: number;
  numberOfCallsThatCalledAgain: number;
  mailbox: number;
  percentage: {
    noAction: number;
    pressedTwo: number;
    reservations: number;
    noSeats: number;
    hangUps: number;
    mailbox: number;
  };
};

export type SiaStatisticProps = {
  month: string;
  restaurantId: string;
};

const StatBox: React.FC<{
  label: string;
  value: string | number;
  secondary: string;
  info?: string;
}> = ({ label, value, secondary, info }) => {
  return (
    <div
      style={{
        padding: 12,
        background: 'var(--color-paper)',
        borderRadius: 4,
        position: 'relative',
      }}
    >
      <Typography variant="text-4" textAlign="right" block>
        {label}
      </Typography>
      <Typography variant="h2" textAlign="right" block>
        {value}
      </Typography>
      <Typography variant="text-4" textAlign="right" block>
        {secondary}
      </Typography>
      {!!info && (
        <Tooltip
          content={info}
          width="300px"
          style={{ position: 'absolute', top: 10, left: 10 }}
        >
          <InfoOutlined />
        </Tooltip>
      )}
    </div>
  );
};

const SiaStatistic: React.FC<SiaStatisticProps> = ({ month, restaurantId }) => {
  const [stats, setstats] = useState<CallEventsPerMonth | null>(null);
  const [loading, setloading] = useState(false);

  const [revenuePerGuest, setRevenuePerGuest] = useLocalStorageState(
    `revenuePerGuest-${restaurantId}`,
    50
  );

  useEffect(() => {
    setstats(null);
    setloading(true);
    axios
      .get<CallEventsPerMonth>(
        `https://europe-west3-schillingroofbarhd.cloudfunctions.net/getSiaStatisticsForAMonth?restaurantId=${restaurantId}&month=${month}`
      )
      .then(({ data }) => {
        setstats(data);
      })
      .catch(() => setstats(null))
      .finally(() => setloading(false));
  }, [restaurantId, month]);

  if (loading) {
    return (
      <Box className="flex center" style={{ height: 120 }}>
        <CircularProgress />
      </Box>
    );
  } else if (!stats || !stats.calls) {
    return <></>;
  }

  return (
    <Box style={{ marginTop: '20px' }}>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 16,
        }}
      >
        <div>
          <Typography variant="h5" translation="reservations">
            SIA in Zahlen
          </Typography>
          <Typography
            variant="text-3"
            translation="reservations"
            className="mg-bt-sm"
            color="subdued"
          >
            Zahlen werden alle 2 Stunden geupdated. Der Anlass hat keinen
            Einfluss auf diese Daten.
          </Typography>
        </div>
        <Dropdown<number>
          value={revenuePerGuest}
          options={[
            { id: 10, label: '10€ Umsatz/Gast' },
            { id: 15, label: '15€ Umsatz/Gast' },
            { id: 20, label: '20€ Umsatz/Gast' },
            { id: 25, label: '25€ Umsatz/Gast' },
            { id: 40, label: '40€ Umsatz/Gast' },
            { id: 50, label: '50€ Umsatz/Gast' },
            { id: 60, label: '60€ Umsatz/Gast' },
            { id: 70, label: '70€ Umsatz/Gast' },
            { id: 80, label: '80€ Umsatz/Gast' },
            { id: 90, label: '90€ Umsatz/Gast' },
            { id: 100, label: '100€ Umsatz/Gast' },
            { id: 125, label: '125€ Umsatz/Gast' },
            { id: 150, label: '150€ Umsatz/Gast' },
            { id: 175, label: '175€ Umsatz/Gast' },
            { id: 200, label: '200€ Umsatz/Gast' },
            { id: 250, label: '250€ Umsatz/Gast' },
          ]}
          onChange={(x: number) => setRevenuePerGuest(x)}
          buttonProps={{ variant: 'outlined' }}
        />
      </Box>

      <Box
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr',
          gap: 16,
        }}
      >
        <StatBox label="Anrufe" value={stats.calls} secondary="" />
        <StatBox
          label="Gesamte Minuten"
          value={stats.minutes}
          secondary={`Angefangenen Minuten`}
        />
        <StatBox
          label="Weitergeleitete Minuten"
          value={stats.redirect_minutes}
          secondary={`Angefangenen Minuten`}
        />
        <StatBox
          label="Reservierungen durch SIA"
          value={stats.sia_reservations}
          secondary={`${stats.minutes - stats.non_reservation_minutes} Minuten`}
        />
        <StatBox
          label="Mehr Umsatz"
          value={
            thousandDot(stats.sia_reservations * 2.5 * revenuePerGuest) + '€'
          }
          secondary={`${revenuePerGuest}€ / Gast`}
        />

        <StatBox
          label="Mailbox Nachrichten"
          value={stats.mailbox_minutes}
          secondary={`Angefangenen Minuten`}
        />
      </Box>
    </Box>
  );
};

export default SiaStatistic;
