import React, { useState, useEffect, useMemo } from "react";
import { Folder, Image } from "types/images";
import { db } from "utils/firebase";
import useRestaurant from "./useRestaurant";

const useImages = (
  restaurantId: string | null,
  currentFolder: string,
  search: null | string
) => {
  const [images, setimages] = useState<{
    data: Image[];
    filteredData: Image[];
    listener: any[];
    loading: boolean;
    error: null | string;
  }>({ data: [], filteredData: [], listener: [], loading: false, error: null });
  const [folders, setfolders] = useState<{
    data: Folder[];
    listener: any[];
    loading: boolean;
    error: null | string;
  }>({ data: [], listener: [], loading: false, error: null });

  const folderRef = db.collection(`restaurants/${restaurantId}/imageFolders`);

  const imageRef = useMemo(() => {
    return currentFolder === "general"
      ? db.collection(`restaurants/${restaurantId}/images`)
      : db
          .collection(`restaurants/${restaurantId}/images`)
          .where("folder", "==", currentFolder);
  }, [currentFolder, restaurantId]);

  //Get Folders
  useEffect(() => {
    if (folders.listener && folders.listener.length) {
      folders.listener.forEach((unsubscribe) => unsubscribe());
    }
    if (restaurantId) {
      setfolders((f) => ({ ...f, loading: true, data: [], error: null }));
      let unsubscribe = folderRef.onSnapshot(
        (querySnapshots) => {
          let arr: Folder[] = [];
          querySnapshots.forEach((doc) => {
            const data = { ...doc.data(), id: doc.id };
            arr.push(data as Folder);
          });

          setfolders((f) => ({
            ...f,
            data: arr,
            loading: false,
          }));
        },
        (err) => {
          setfolders((f) => ({ ...f, error: err.message, loading: false }));
        }
      );
      setfolders((f) => ({ ...f, listener: [unsubscribe] }));
    }

    return () => {
      if (folders.listener && folders.listener.length) {
        folders.listener.forEach((unsubsribe) => unsubsribe());
      }
    };
  }, [restaurantId]);

  //Get Images
  useEffect(() => {
    if (images.listener && images.listener.length) {
      images.listener.forEach((unsubscribe) => unsubscribe());
    }
    if (restaurantId) {
      setimages((i) => ({ ...i, loading: true, data: [], error: null }));
      let unsubscribe = imageRef.onSnapshot(
        (querySnapshots) => {
          let arr: any[] = [];
          querySnapshots.forEach((doc) => {
            const data = { ...doc.data(), id: doc.id };
            arr.push(data as Image);
          });

          setimages((i) => ({
            ...i,
            data: arr,
            filteredData: arr,
            loading: false,
          }));
        },
        (err) => {
          setimages((i) => ({ ...i, error: err.message, loading: false }));
        }
      );
      setimages((i) => ({ ...i, listener: [unsubscribe] }));
    }

    return () => {
      if (images.listener && images.listener.length) {
        images.listener.forEach((unsubsribe) => unsubsribe());
      }
    };
  }, [restaurantId, currentFolder]);

  useEffect(() => {
    let searchArray = [...images.data];
    if (search !== null) {
      searchArray = images.data.filter(
        (img) =>
          img?.originalName?.toLowerCase().includes(search.toLowerCase()) ||
          img?.alt?.toLowerCase().includes(search.toLowerCase())
      );
    }
    setimages((i) => ({ ...i, filteredData: searchArray }));
  }, [search]);

  return {
    images,
    folders,
  };
};

export const useImage = (imageId?: string) => {
  const { restaurantId } = useRestaurant();
  const [url, seturl] = useState<string | null>(null);

  useEffect(() => {
    if (!imageId || imageId.startsWith("http")) {
      seturl(imageId ?? null);
    } else {
      seturl(null);
      db.collection(`restaurants/${restaurantId}/images`)
        .doc(imageId)
        .get()
        .then((doc) => {
          seturl(doc?.data()?.url ?? null);
        });
    }
  }, [imageId, restaurantId]);

  return url;
};

export default useImages;
