import React from "react";
import "../styles.scss";
import Box from "../../../../../Components/Atoms/Box";
import {
  AnyShift,
  Date as DateType,
  GuestOrderingShift,
  OffersShift,
  ReservationShift,
  ShiftComment,
} from "../../../../../types/shifts";
import ShiftCommentModal from "../../../../ReservationBook/Components/ShiftCommentModal";
import Typography from "../../../../../Components/Atoms/Typography";
import { slateToHtml } from "../../../../../Components/Atoms/RichTextField";
import Attachments from "../../../../ReservationBook/Components/ShiftComment/Components/Attachments";

interface NotesTabProps {
  currentShiftComment?: ShiftComment | null;
  restaurantId: string;
  shiftId: string;
  shiftDetails: Partial<AnyShift> | undefined;
  date: DateType;
  modalOpen: boolean;
  setModalOpen: (x: boolean) => void;
}

export const InnerNotes = ({
  currentShiftComment,
  shiftDetails,
  restaurantId,
  date,
  shiftId,
  modalOpen,
  setModalOpen,
}: NotesTabProps) => {
  return (
    <>
      {currentShiftComment ? (
        <div>
          <div
            style={{ padding: 16 }}
            dangerouslySetInnerHTML={{
              __html: slateToHtml(currentShiftComment.slateDescendants ?? []),
            }}
          />
          <Attachments
            attachments={currentShiftComment.attachments}
            className="columnDirection"
          />
        </div>
      ) : (
        <Typography variant="text-2" translation="reservations">
          No comments yet.
        </Typography>
      )}
      <ShiftCommentModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        shiftId={shiftId}
        date={date.date}
        restaurantId={restaurantId}
      />
    </>
  );
};

const NotesTab = (props: NotesTabProps) => {
  return (
    <Box className="notes-tab-container">
      <InnerNotes {...props} />
    </Box>
  );
};

export default NotesTab;
