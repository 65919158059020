import {
  ReservationLength,
  ShiftType,
  SlotPerBlock,
  ReservationShift as ReservationShiftType,
  GuestOrderingShift as GuestOrderingShiftType,
  OffersShift as OffersShiftType,
  TakeAwayShift as TakeAwayShiftType,
  HappyHoursOffer,
} from 'types/shifts';
import { dateHelper, daysInFuture } from './helper';
import { Translation } from './types';

const createDateArray = (till: string, from: string = dateHelper()) => {
  let i = 0;

  if (till < from) {
    return [];
  } else if (till === from) {
    return [from];
  }

  let dates: string[] = [];

  while (!dates.includes(till) || i > 500) {
    dates.push(daysInFuture(i, from));
    i += 1;
  }

  return dates;
};

export class NewReservationShift {
  id?: string;
  name?: string;
  type: ShiftType = ShiftType.RESERVATION;

  color: string;

  regular: boolean;
  edited?: boolean; // ?
  priority: number = 0;

  active: boolean = true;
  disabled?: boolean;
  dates?: string[];
  weekDays?: number[];
  startAt?: string;
  endAt?: string | null;

  start: number;
  close: number;
  service?: number;

  createdAt: number = Date.now();
  createdBy: string;
  updatedAt?: number;
  updatedBy?: string;
  additional?: {
    blockedAt: number[];
    slotsPerBlock: SlotPerBlock;
    reservationLength: ReservationLength;
    floorPlan: null | string;
    placeAt: null | 'indoor' | 'outdoor';
    maxGuests?: number;
  };
  matchBoth?: boolean;
  spaces: string[] = [];
  occassions: string[] = [];
  overwrite?: {
    blockReservations?: boolean;
    maxGuests?: number;
    placeAt?: null | 'indoor' | 'outdoor';
  };
  closed?: boolean;
  message?: Translation<string>;
  button?: Translation<string>;
  link?: string;

  constructor(data: Partial<ReservationShiftType>) {
    let keys = Object.keys(data) as (keyof ReservationShiftType)[];

    if (data.regular) {
      let filterOut = ['dates'];
      keys = keys.filter((k) => !filterOut.includes(k));
    } else {
      let filterOut = ['startAt', 'endAt', 'weekDays', 'dates'];
      keys = keys.filter((k) => !filterOut.includes(k));
      let from = data.startAt ?? dateHelper();

      console.log(
        'TESTING3',
        data.endAt,
        from,
        createDateArray(data.endAt || from, from)
      );

      this.dates = createDateArray(data.endAt || from, from);
    }

    keys.forEach((k) => {
      let value = data?.[k];

      if (k === 'id' && value === 'new-shift') return;

      if (value !== undefined) {
        (this as any)[k] = value;
      }
    });
  }

  toData() {
    let data: any = { ...this };

    Object.entries(data).forEach(([key, val]) => {
      if (val === undefined) {
        delete data[key];
      }
    });

    return data;
  }
}

export class NewGuestOrderingShift {
  id?: string;
  name?: string;
  type: ShiftType = ShiftType.GUEST_ORDERING;

  color: string;

  regular: boolean;
  edited?: boolean; // ?
  priority: number = 0;

  active: boolean = true;
  disabled?: boolean;
  dates?: string[];
  weekDays?: number[];
  startAt?: string;
  endAt?: string | null;

  start: number;
  close: number;
  service?: number;

  createdAt: number = Date.now();
  createdBy: string;
  updatedAt?: number;
  updatedBy?: string;
  closed?: boolean;
  menu: string | null;

  constructor(data: Partial<GuestOrderingShiftType>) {
    let keys = Object.keys(data) as (keyof GuestOrderingShiftType)[];

    if (data.regular) {
      let filterOut = ['dates'];
      keys = keys.filter((k) => !filterOut.includes(k));
    } else {
      let filterOut = ['startAt', 'endAt', 'weekDays', 'dates'];
      keys = keys.filter((k) => !filterOut.includes(k));
      let from = data.startAt ?? dateHelper();
      this.dates = createDateArray(data.endAt ?? from, from);
    }

    keys.forEach((k) => {
      let value = data?.[k];

      if (k === 'id' && value === 'new-shift') return;

      if (value !== undefined) {
        (this as any)[k] = value;
      }
    });
  }

  toData() {
    let data: any = { ...this };

    Object.entries(data).forEach(([key, val]) => {
      if (val === undefined) {
        delete data[key];
      }
    });

    return data;
  }
}

export class NewHappyHoursShift {
  id?: string;
  name?: string;
  type: ShiftType = ShiftType.HAPPY_HOURS;

  color: string;

  regular: boolean;
  edited?: boolean; // ?
  priority: number = 0;

  active: boolean = true;
  disabled?: boolean;
  dates?: string[];
  weekDays?: number[];
  startAt?: string;
  endAt?: string | null;

  start: number;
  close: number;
  service?: number;

  createdAt: number = Date.now();
  createdBy: string;
  updatedAt?: number;
  updatedBy?: string;
  offers?: HappyHoursOffer[];

  ad?: {
    translations: Record<string, { title: string; description: string }>;
    imageId?: string;
    imageUrl?: string;
  } | null;
  closed?: boolean;

  constructor(data: Partial<OffersShiftType>) {
    let keys = Object.keys(data) as (keyof OffersShiftType)[];

    if (data.regular) {
      let filterOut = ['dates'];
      keys = keys.filter((k) => !filterOut.includes(k));
    } else {
      let filterOut = ['startAt', 'endAt', 'weekDays', 'dates'];
      keys = keys.filter((k) => !filterOut.includes(k));
      let from = data.startAt ?? dateHelper();
      this.dates = createDateArray(data.endAt ?? from, from);
    }

    keys.forEach((k) => {
      let value = data?.[k];

      if (k === 'id' && value === 'new-shift') return;

      if (value !== undefined) {
        (this as any)[k] = value;
      }
    });
  }

  toData() {
    let data: any = { ...this };

    Object.entries(data).forEach(([key, val]) => {
      if (val === undefined) {
        delete data[key];
      }
    });

    return data;
  }
}

export class TakeAwayShift {
  id?: string;
  name?: string;
  type: ShiftType = ShiftType.TAKEAWAY;

  color: string;

  regular: boolean;
  edited?: boolean; // ?
  priority: number = 0;

  active: boolean = true;
  disabled?: boolean;
  dates?: string[];
  weekDays?: number[];
  startAt?: string;
  endAt?: string | null;

  start: number;
  close: number;
  service?: number;

  createdAt: number = Date.now();
  createdBy: string;
  updatedAt?: number;
  updatedBy?: string;
  offers?: HappyHoursOffer[];

  ad?: {
    translations: Record<string, { title: string; description: string }>;
    imageId?: string;
    imageUrl?: string;
  } | null;
  closed?: boolean;

  constructor(data: Partial<TakeAwayShiftType>) {
    let keys = Object.keys(data) as (keyof TakeAwayShiftType)[];

    if (data.regular) {
      let filterOut = ['dates'];
      keys = keys.filter((k) => !filterOut.includes(k));
    } else {
      let filterOut = ['startAt', 'endAt', 'weekDays', 'dates'];
      keys = keys.filter((k) => !filterOut.includes(k));
      let from = data.startAt ?? dateHelper();
      this.dates = createDateArray(data.endAt ?? from, from);
    }

    keys.forEach((k) => {
      let value = data?.[k];

      if (k === 'id' && value === 'new-shift') return;

      if (value !== undefined) {
        (this as any)[k] = value;
      }
    });
  }

  toData() {
    let data: any = { ...this };

    Object.entries(data).forEach(([key, val]) => {
      if (val === undefined) {
        delete data[key];
      }
    });

    return data;
  }
}
