import Box from 'Components/Atoms/Box';
import Button from 'Components/Atoms/Button';
import { Add, Delete } from 'Components/Atoms/Icons';
import React from 'react';
import { noop } from 'utils/helper';

type ImageFieldButtonsProps = {
  value: null | string | (string | null)[];
  multiple?: boolean;
  type?: 'id' | 'url';
  onChange: (val: null | string | (string | null)[]) => void;
  openImageModal: (
    onSubmitFunction: (s: string | null | (string | null)[]) => void,
    modalOptions: {
      multiple: boolean;
      id: boolean;
      delete?: boolean;
      search?: string | null;
      value?: null | string | (string | null)[];
    }
  ) => void;
  disabled?: boolean;
  search?: string | null;
};

const ImageFieldButtons = ({
  value,
  multiple = false,
  onChange = noop,
  openImageModal = noop,
  type = 'id',
  disabled = false,
  search,
}: ImageFieldButtonsProps) => {
  return (
    <Box style={{ marginTop: 8 }} className="image-field-btn-container">
      {multiple ? (
        <Button
          variant="default"
          style={{ marginRight: 16 }}
          fullWidth
          endIcon={() => <Add />}
          color="subdued"
          onClick={() =>
            openImageModal(onChange, {
              multiple: multiple,
              id: type === 'id',
              delete: false,
              search,
              value,
            })
          }
          disabled={disabled}
        >
          Add Image
        </Button>
      ) : (
        <Box flex>
          <Button
            variant="primary"
            className="image-field-add-btn"
            endIcon={() => (!value ? <Add /> : '')}
            onClick={() =>
              openImageModal(onChange, {
                multiple: multiple,
                id: type === 'id',
                delete: false,
              })
            }
            disabled={disabled}
          >
            {!!value ? 'Change Image' : 'Add Image'}
          </Button>
          <Button
            endIcon={() => <Delete style={{ marginLeft: 8 }} />}
            disabled={disabled || !value}
            onClick={() => onChange(null)}
          >
            Clear
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ImageFieldButtons;
