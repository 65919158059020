import React, { useEffect, useState } from "react";
import Box from "Components/Atoms/Box";
import "./styles.scss";
import GridItem from "Components/Atoms/GridItem";
import Card from "Components/Molecules/Card";
import useToast from "CustomHooks/useToast";
import { Severity } from "Contexts/RestaurantContext";
import Typography from "Components/Atoms/Typography";

export type ConnectionStatusProps = {};

export const useConnection = () => {
  const [isOnline, setIsOnline] = useState<boolean | null>(null);
  const [downLink, setDownlink] = useState<number | null>(null);

  const toast = useToast();

  useEffect(() => {
    let interval: NodeJS.Timeout | null;
    let eventListener: (e: any) => void;

    if (typeof navigator.onLine !== "undefined") {
      setIsOnline(navigator.onLine);

      interval = setInterval(() => {
        setIsOnline(navigator.onLine);
      }, 30000);
    } else {
      setIsOnline(true);
    }

    if (typeof (navigator as any).connection !== "undefined") {
      setDownlink((navigator as any)?.connection?.downlink ?? null);

      if (((navigator as any)?.connection?.downlink ?? 1) < 0.2) {
        toast(
          "Your internet connection is very. Please check your connection.",
          Severity.WARNING,
          "common"
        );
      }

      eventListener = (e: any) => {
        let downlink = e.target.downlink;

        setDownlink(downlink);
        if (downlink < 0.2) {
          toast(
            "Your internet connection is very slow. Please check your connection.",
            Severity.WARNING,
            "common"
          );
        }
      };

      (navigator as any).connection.addEventListener("change", eventListener);
    }

    return () => {
      if (eventListener) {
        (navigator as any).connection.removeEventListener(
          "change",
          eventListener
        );
      }
      if (interval) {
        clearInterval(interval);
        interval = null;
      }
    };
  }, []);

  return {
    isOnline,
    downLink,
  };
};

const ConnectionStatus: React.FC<ConnectionStatusProps> = ({}) => {
  const { isOnline, downLink } = useConnection();

  if (isOnline && (downLink === null || downLink >= 0.3)) {
    return <></>;
  }

  return (
    <GridItem xs={12}>
      <Card title="Connection Status" className="errorCard">
        <Typography variant="text-2">
          Your internet connection is very slow. Please check your connection.
        </Typography>
      </Card>
    </GridItem>
  );
};

export default ConnectionStatus;
