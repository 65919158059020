import React, { useContext, useState } from 'react';
import { PinAuth, RestaurantContext } from 'Contexts/RestaurantContext';
import { AuthContext } from 'Contexts/AuthContext';

type UsePinAuth = () => {
  authenticate: (
    type: SecurityPlusKeys,
    onSubmit: (signature: string) => void | Promise<void>,
    onDenial?: () => void | Promise<void>
  ) => void;
  checkAuthentication: (
    type: SecurityPlusKeys,
    ignoreSession?: boolean
  ) => boolean;
};

const usePinAuth: UsePinAuth = () => {
  const { pinAuth, securityPlus, storedPins, experimentalFlags } =
    useContext(RestaurantContext);

  const { isAdmin } = useContext(AuthContext);

  function getSignatures(type: SecurityPlusKeys) {
    if (type === 'none') return null;

    // return ['LD'];

    if (!securityPlus?.active || !securityPlus?.settings) return null;

    let x: any = securityPlus.settings.rights[type];

    if (!Array.isArray(x)) return null;

    let pins: string[] = [];

    for (const key of x) {
      if (securityPlus.settings.signatures[key]) {
        pins.push(key);
      } else {
        let rightGroups = securityPlus.settings.rightGroups.find(
          (x) => x.id === key
        );

        if (rightGroups?.signatures) {
          pins.push(...rightGroups.signatures);
        }
      }
    }

    return pins;
  }

  function checkAuthentication(type: SecurityPlusKeys, ignoreSession = true) {
    if (experimentalFlags?.data?.disableSecurityForAdmins && isAdmin)
      return false;

    let signatures = getSignatures(type);

    console.log(signatures);

    if (
      !ignoreSession &&
      !!signatures?.some((x) => storedPins[x] && storedPins[x] > Date.now())
    )
      return false;

    return !!signatures?.length;
  }

  function authenticate(
    type: SecurityPlusKeys,
    onSubmit: (signature: string) => void | Promise<void>,
    onDenial?: () => void | Promise<void>
  ) {
    if (experimentalFlags?.data?.disableSecurityForAdmins && isAdmin) {
      onSubmit('');
      return;
    }

    const signatures = getSignatures(type);

    let signature = signatures?.find(
      (x) => storedPins[x] && storedPins[x] > Date.now()
    );

    if (signature) {
      onSubmit(signature);
      return;
    }

    if (!signatures?.length) {
      onSubmit('');
    } else {
      pinAuth({
        onSubmit,
        signatures,
        type,
        onClose: onDenial
          ? () => {
              onDenial();
            }
          : undefined,
      });
    }
  }

  return {
    authenticate,
    checkAuthentication,
  };
};

export default usePinAuth;

export type SecurityPlusKeys =
  | 'dashboard.statistics.read'
  | 'dashboard.calendar.read'
  | 'dashboard.guestBook.read'
  | 'dashboard.feedback.read'
  | 'dashboard.sia.read'
  | 'dashboard.delivery.read'
  | 'dashboard.menu.read'
  | 'dashboard.voucher.read'
  | 'dashboard.settings.read'
  | 'dashboard.printReservationList.write'
  | 'calendar.newShift.write'
  | 'calendar.blockReservations.write'
  | 'calendar.editShift.write'
  | 'calendar.deleteShift.write'
  | 'calendar.addNote.write'
  | 'calendar.editNote.write'
  | 'reservations.newReservation.write'
  | 'reservations.cancelReservation.write'
  | 'reservations.moreThanOnWebsite.write'
  | 'reservations.reservationLength.write'
  | 'reservations.chooseTableThatIsNotRecommended.write'
  | 'reservations.walkin.read'
  | 'reservations.walkin.write'
  | 'reservations.hostComment.read'
  | 'reservations.hostComment.write'
  | 'reservations.occassions.[occassionId].read'
  | 'reservations.occassions.[occassionId].write'
  | 'reservations.blockedTime.read'
  | 'reservations.blockedTime.write'
  | 'reservations.spaceBlocked'
  | 'none';
