import { ThemeContext } from "Contexts/ThemeContext";
import React, { useContext } from "react";

const Illustration = () => {
  const { darkMode } = useContext(ThemeContext);

  const color1 = darkMode ? "black" : "white";
  const color2 = darkMode ? "#718096" : "#E2E8F0";
  const color3 = darkMode ? "#4A5568" : "#CBD5E0";
  const color4 = darkMode ? "#2d3748" : "#e2e8f0";

  return (
    <svg
      width="278"
      height="178"
      viewBox="0 0 278 178"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.3334 106.65C19.4493 106.391 7.34787 159.687 53.3366 166.63C53.3366 166.63 66.8656 120.35 20.3334 106.65Z"
        fill="#10CCB9"
      />
      <path
        d="M30.2303 124.243L52.6894 165.974"
        stroke={color1}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M29.1024 137.261L39.7357 141.9"
        stroke={color1}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M48.4373 138.777L45.5174 152.647"
        stroke={color1}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M0 96.5527H73.4117C73.4117 96.5527 68.6263 88.677 55.4704 91.5158C55.4704 91.5158 52.9885 80.7607 40.0354 80.7607C28.8099 80.7607 25.6466 91.5158 25.6466 91.5158C25.6466 91.5158 17.4871 85.6354 10.9173 94.168C10.9173 94.168 1.60596 91.386 0 96.5527Z"
        fill={color2}
      />
      <path
        d="M195.545 29.2724H268.957C268.957 29.2724 264.172 21.3967 251.016 24.2355C251.016 24.2355 248.534 13.4805 235.581 13.4805C224.355 13.4805 221.192 24.2355 221.192 24.2355C221.192 24.2355 213.033 18.3551 206.463 26.8878C206.463 26.8878 197.151 24.1057 195.545 29.2724Z"
        fill={color2}
      />
      <path
        d="M78.4497 40.6923C77.9306 38.3564 76.9411 36.1826 75.5704 34.3171L77.9955 30.8132L76.5274 29.3452L75.911 28.7287L74.4429 27.2607L70.7606 29.8156C68.8221 28.5665 66.5997 27.7149 64.2394 27.358H64.2313L63.4689 23.1484H61.3925H60.5246H58.4482L57.6453 27.577C55.3093 28.0717 53.1518 29.0694 51.2863 30.4401L47.7743 28.0069L46.3063 29.4749L45.6898 30.0914L44.2217 31.5594L46.7767 35.2418C45.5276 37.1965 44.676 39.3945 44.3191 41.7792L40.1014 42.5416V44.618V45.4858V47.5622L44.5056 48.3652C45.0247 50.7011 46.0143 52.8749 47.385 54.7404L44.9598 58.2443L46.4279 59.7123L47.0443 60.3288L48.5124 61.7968L52.1948 59.2419C54.1333 60.491 56.3557 61.3426 58.7159 61.6995H58.724L59.4864 65.9091H61.5629H62.4307H64.5071L65.3101 61.4805C67.646 60.9858 69.7954 59.9881 71.6609 58.6174L75.181 61.0506L76.6491 59.5826L77.2655 58.9661L78.7336 57.4981L76.1787 53.8076C77.4278 51.8691 78.2794 49.6467 78.6363 47.2865V47.2783L82.8458 46.5159V44.4395V43.5717V41.4953L78.4497 40.6923ZM61.4655 54.6755C55.8609 54.6755 51.3188 50.1334 51.3188 44.5288C51.3188 38.9241 55.8609 34.382 61.4655 34.382C67.0701 34.382 71.6122 38.9241 71.6122 44.5288C71.6122 50.1415 67.0701 54.6755 61.4655 54.6755Z"
        fill={color2}
      />
      <path
        d="M263.344 86.665C262.955 84.9212 262.217 83.2909 261.195 81.8958L263.012 79.276L261.909 78.1729L261.446 77.7106L260.343 76.6075L257.585 78.5136C256.134 77.5808 254.471 76.9401 252.703 76.6724C252.703 76.6724 252.703 76.6724 252.695 76.6724L252.119 73.5254H250.561H249.912H248.355L247.755 76.8346C246.003 77.2077 244.397 77.9458 243.002 78.9759L240.374 77.1591L239.271 78.2622L238.809 78.7245L237.706 79.8275L239.612 82.5853C238.679 84.0533 238.046 85.6917 237.779 87.468L234.623 88.0439V89.6012V90.2501V91.8073L237.916 92.4076C238.306 94.1595 239.044 95.7817 240.066 97.1768L238.249 99.7966L239.352 100.9L239.814 101.362L240.918 102.465L243.675 100.559C245.127 101.492 246.782 102.133 248.55 102.392H248.558L249.134 105.539H250.691H251.34H252.897L253.498 102.23C255.249 101.857 256.855 101.119 258.251 100.089L260.878 101.914L261.982 100.81L262.444 100.348L263.547 99.245L261.641 96.4873C262.574 95.0355 263.214 93.3809 263.474 91.6127V91.6046L266.621 91.0287V89.4714V88.8225V87.2652L263.344 86.665ZM250.642 97.12C246.449 97.12 243.051 93.7215 243.051 89.5282C243.051 85.3349 246.449 81.9364 250.642 81.9364C254.836 81.9364 258.234 85.3349 258.234 89.5282C258.234 93.7215 254.836 97.12 250.642 97.12Z"
        fill={color2}
      />
      <path
        d="M96.811 24.0157C96.5352 22.7828 96.0161 21.6392 95.2943 20.6577L96.5758 18.8085L95.7971 18.0379L95.4727 17.7135L94.7022 16.943L92.7636 18.2894C91.7417 17.6324 90.5737 17.1782 89.3246 16.9916C89.3246 16.9916 89.3246 16.9916 89.3165 16.9916L88.911 14.7773H87.816H87.3618H86.2668L85.845 17.1052C84.6122 17.3647 83.4767 17.8919 82.4953 18.6138L80.646 17.3323L79.8673 18.1109L79.5429 18.4354L78.7723 19.2059L80.1188 21.1444C79.4618 22.1745 79.0157 23.3343 78.8291 24.5834L76.6068 24.989V26.0839V26.5381V27.6331L78.9265 28.0549C79.2022 29.2877 79.7213 30.4314 80.4432 31.4128L79.1617 33.254L79.9403 34.0326L80.2648 34.357L81.0434 35.1276L82.9819 33.7812C84.0039 34.4382 85.1719 34.8843 86.4128 35.0708H86.4209L86.8265 37.2851H87.9215H88.3757H89.4706L89.8924 34.9491C91.1253 34.6896 92.2527 34.1624 93.2422 33.4405L95.0915 34.722L95.8701 33.9434L96.1946 33.6189L96.9651 32.8403L95.6187 30.9018C96.2757 29.8798 96.7218 28.7119 96.9083 27.4709C96.9083 27.4709 96.9083 27.4709 96.9083 27.4628L99.1307 27.0572V25.9623V25.5081V24.4131L96.811 24.0157ZM87.8728 31.3803C84.9204 31.3803 82.5277 28.9876 82.5277 26.0353C82.5277 23.0829 84.9204 20.6902 87.8728 20.6902C90.8251 20.6902 93.2179 23.0829 93.2179 26.0353C93.2179 28.9876 90.8251 31.3803 87.8728 31.3803Z"
        fill={color2}
      />
      <path
        d="M163.977 147.318H142.654V166.89H163.977V147.318Z"
        fill={color2}
      />
      <path
        d="M170.987 165.973H135.94C134.975 165.973 134.188 166.759 134.188 167.725C134.188 168.69 134.975 169.477 135.94 169.477H170.987C171.952 169.477 172.739 168.69 172.739 167.725C172.739 166.759 171.952 165.973 170.987 165.973Z"
        fill={color2}
      />
      <path
        d="M240.009 159.599H66.7606C64.9925 159.599 63.5649 158.171 63.5649 156.403V47.725C63.5649 45.9568 64.9925 44.5293 66.7606 44.5293H240.009C241.778 44.5293 243.205 45.9568 243.205 47.725V156.403C243.205 158.171 241.778 159.599 240.009 159.599Z"
        fill={color2}
      />
      <path
        d="M68.2606 156.224C67.847 156.224 67.5063 155.883 67.5063 155.47V48.6654C67.5063 48.2518 67.847 47.9111 68.2606 47.9111H238.525C238.938 47.9111 239.279 48.2518 239.279 48.6654V155.47C239.279 155.883 238.938 156.224 238.525 156.224H68.2606Z"
        fill={color1}
      />
      <path
        d="M118.183 63.2324H81.0192V100.397H118.183V63.2324Z"
        fill={color4}
      />
      <path
        d="M222.937 63.2891H124.438V100.453H222.937V63.2891Z"
        fill={color4}
      />
      <path
        d="M222.937 104.331H124.438V109.554H222.937V104.331Z"
        fill={color4}
      />
      <path d="M222.88 113.18H124.381V118.403H222.88V113.18Z" fill={color4} />
      <path
        d="M118.183 104.274H81.0193V109.498H118.183V104.274Z"
        fill={color4}
      />
      <path d="M118.183 113.18H81.0193V118.403H118.183V113.18Z" fill={color4} />
      <path
        d="M243.06 127.746H63.5737V140.813H243.06V127.746Z"
        fill="#FFCE2B"
      />
      <path
        d="M77.2081 127.746L69.0567 140.813H63.5737V140.675L71.6359 127.746H77.2081Z"
        fill="black"
      />
      <path
        d="M89.6502 127.746L81.4988 140.813H75.9428L84.0943 127.746H89.6502Z"
        fill="black"
      />
      <path
        d="M102.108 127.746L93.957 140.813H88.3848L96.5363 127.746H102.108Z"
        fill="black"
      />
      <path
        d="M114.558 127.746L106.415 140.813H100.843L108.994 127.746H114.558Z"
        fill="black"
      />
      <path
        d="M127.016 127.746L118.865 140.813H113.3L121.452 127.746H127.016Z"
        fill="black"
      />
      <path
        d="M139.477 127.746L131.326 140.813H125.761L133.913 127.746H139.477Z"
        fill="black"
      />
      <path
        d="M151.935 127.746L143.783 140.813H138.219L146.363 127.746H151.935Z"
        fill="black"
      />
      <path
        d="M164.375 127.746L156.224 140.813H150.668L158.819 127.746H164.375Z"
        fill="black"
      />
      <path
        d="M176.833 127.746L168.681 140.813H163.125L171.277 127.746H176.833Z"
        fill="black"
      />
      <path
        d="M189.291 127.746L181.14 140.813H175.567L183.719 127.746H189.291Z"
        fill="black"
      />
      <path
        d="M201.744 127.746L193.601 140.813H188.028L196.18 127.746H201.744Z"
        fill="black"
      />
      <path
        d="M214.202 127.746L206.05 140.813H200.486L208.638 127.746H214.202Z"
        fill="black"
      />
      <path
        d="M226.66 127.746L218.508 140.813H212.944L221.095 127.746H226.66Z"
        fill="black"
      />
      <path
        d="M239.119 127.746L230.968 140.813H225.396L233.547 127.746H239.119Z"
        fill="black"
      />
      <path
        d="M243.061 132.467V140.813H237.853L243.061 132.467Z"
        fill="black"
      />
      <path
        d="M143.472 54.0996H125.101V58.6255H143.472V54.0996Z"
        fill={color2}
      />
      <path
        d="M168.833 76.9252L102.105 87.8662L105.491 108.516L172.219 97.5751L168.833 76.9252Z"
        fill="#10CCB9"
      />
      <path
        d="M103.259 87.687C102.407 88.0682 134.299 75.0664 134.299 75.0664L168.827 76.9319"
        stroke="#10CCB9"
        stroke-miterlimit="10"
      />
      <path
        d="M277.466 169.478H18.2495V177.58H277.466V169.478Z"
        fill={color3}
      />
      <path
        d="M222.926 7.54297H203.346V44.5368H222.926V7.54297Z"
        fill={color3}
      />
      <path d="M226.09 0H191.902V34.1874H226.09V0Z" fill={color3} />
      <path
        d="M134.193 67.2877C137.423 67.2877 140.041 64.6695 140.041 61.4397C140.041 58.21 137.423 55.5918 134.193 55.5918C130.963 55.5918 128.345 58.21 128.345 61.4397C128.345 64.6695 130.963 67.2877 134.193 67.2877Z"
        fill={color3}
      />
      <path d="M125.62 10.9902H124.379V58.6255H125.62V10.9902Z" fill={color3} />
      <path
        d="M134.661 10.9902H133.421V58.6255H134.661V10.9902Z"
        fill={color3}
      />
      <path
        d="M144.227 10.9902H142.986V58.6255H144.227V10.9902Z"
        fill={color3}
      />
      <path
        d="M134.29 69.5355C133.957 69.5355 133.69 69.2679 133.69 68.9353V62.2682C133.69 61.9356 133.957 61.668 134.29 61.668C134.623 61.668 134.89 61.9356 134.89 62.2682V68.9353C134.882 69.2679 134.614 69.5355 134.29 69.5355Z"
        fill={color3}
      />
      <path
        d="M144.225 58.1387H124.378V63.2323H144.225V58.1387Z"
        fill={color3}
      />
      <path
        d="M134.191 76.9402C133.34 76.9402 132.48 76.6887 131.734 76.1696C130.785 75.5126 130.152 74.5231 129.941 73.3876C129.739 72.2521 129.982 71.1003 130.647 70.1513C131.466 68.9672 132.821 68.3102 134.256 68.2939C134.589 68.3021 134.848 68.5697 134.848 68.8942C134.848 69.2267 134.532 69.4538 134.248 69.4862C133.17 69.4781 132.229 69.9729 131.637 70.8327C131.158 71.5221 130.98 72.3494 131.126 73.1767C131.272 74.004 131.734 74.7178 132.423 75.1963C133.843 76.1777 135.797 75.829 136.779 74.4096C137.136 73.8905 137.33 73.2903 137.339 72.6657C137.347 72.3413 137.606 72.0736 137.939 72.0736H137.947C138.271 72.0817 138.539 72.3494 138.539 72.6738C138.531 73.5417 138.263 74.369 137.768 75.0828C136.909 76.2913 135.562 76.9402 134.191 76.9402Z"
        fill={color3}
      />
      <path d="M222.991 0H119.123V17.6088H222.991V0Z" fill={color3} />
      <path
        d="M135.256 3.04199L128.605 14.5595L121.946 3.04199H135.256Z"
        fill={color1}
      />
      <path
        d="M145.435 14.5595L138.776 3.04199L132.125 14.5595H145.435Z"
        fill={color1}
      />
      <path
        d="M125.003 14.5669H119.123V4.3877L125.003 14.5669Z"
        fill={color1}
      />
      <path
        d="M156.296 3.04199L149.645 14.5595L142.986 3.04199H156.296Z"
        fill={color1}
      />
      <path
        d="M166.476 14.5595L159.817 3.04199L153.166 14.5595H166.476Z"
        fill={color1}
      />
      <path
        d="M177.337 3.04199L170.678 14.5595L164.027 3.04199H177.337Z"
        fill={color1}
      />
      <path
        d="M187.506 14.5595L180.855 3.04199L174.204 14.5595H187.506Z"
        fill={color1}
      />
      <path
        d="M198.375 3.04199L191.716 14.5595L185.065 3.04199H198.375Z"
        fill={color1}
      />
      <path
        d="M208.547 14.5595L201.896 3.04199L195.245 14.5595H208.547Z"
        fill={color1}
      />
      <path
        d="M219.408 3.04199L212.757 14.5595L206.106 3.04199H219.408Z"
        fill={color1}
      />
      <path
        d="M223.001 3.14676V14.5669H216.286L222.936 3.0332L223.001 3.14676Z"
        fill={color1}
      />
      <path d="M119.124 0H115.888V17.6088H119.124V0Z" fill={color3} />
      <path d="M226.09 0H222.853V17.6088H226.09V0Z" fill={color3} />
      <path
        d="M273.33 123.748C273.962 123.561 282.576 161.52 249.816 166.468C249.824 166.468 240.189 133.505 273.33 123.748Z"
        fill="#10CCB9"
      />
      <path
        d="M249.59 167.725L267.426 134.275"
        stroke={color1}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M258.512 139.256L259.396 149.346"
        stroke={color1}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M255.874 155.94C255.784 156.11 264.171 152.484 264.171 152.484L255.874 155.94Z"
        fill={color1}
      />
      <path
        d="M255.874 155.94C255.784 156.11 264.171 152.484 264.171 152.484"
        stroke={color1}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M206.099 58.8691H203.349V172.405H206.099V58.8691Z"
        fill={color3}
      />
      <path
        d="M222.929 58.8691H220.18V172.405H222.929V58.8691Z"
        fill={color3}
      />
      <path d="M221.08 72.3818H204.728V73.8411H221.08V72.3818Z" fill={color3} />
      <path d="M221.08 86.9922H204.728V88.4515H221.08V86.9922Z" fill={color3} />
      <path d="M221.08 101.609H204.728V103.069H221.08V101.609Z" fill={color3} />
      <path d="M221.08 116.22H204.728V117.679H221.08V116.22Z" fill={color3} />
      <path d="M221.08 130.829H204.728V132.288H221.08V130.829Z" fill={color3} />
      <path d="M221.08 145.438H204.728V146.898H221.08V145.438Z" fill={color3} />
    </svg>
  );
};
export default Illustration;
