import React from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import TextField from 'Components/Atoms/TextField';
import { Translations } from 'utils/types';
import { Fullscreen } from '@material-ui/icons';
import Modal from '../Modal';
import EditableField from 'App/ReservationBook/Components/ReservationSideBar/Components/EditableField';
import Typography from 'Components/Atoms/Typography';
import IconButton from 'Components/Atoms/IconButton';

export type CommentModalProps = {
  value: string;
  onChange: (value: string) => void;
  label: string;
  labelTranslation?: Translations;
  fullWidth?: boolean;
  editableField?: boolean;
  action?: string;
  id?: string;
};

const CommentModal: React.FC<CommentModalProps> = ({
  value,
  onChange,
  label,
  labelTranslation = 'reservations',
  fullWidth,
  editableField,
  action,
  id,
}) => {
  const [modal, setModal] = React.useState<{ open: boolean; state: string }>({
    open: false,
    state: '',
  });

  return (
    <>
      {!editableField && (
        <TextField
          value={value}
          onChange={(e) => onChange(e.target.value)}
          fullWidth={fullWidth}
          label={label}
          labelTranslation={labelTranslation}
          InputProps={{
            endAdornment: (
              <Fullscreen
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setModal({ open: true, state: value });
                }}
              />
            ),
          }}
        />
      )}
      {editableField && (
        <Box className="reservation-field">
          <Box style={{ marginBottom: 8 }} className="space-between">
            <Typography variant="text-3" translation={labelTranslation} block>
              {label}
            </Typography>
            <IconButton
              className="small-icon"
              onClick={() => setModal({ open: true, state: value })}
            >
              <Fullscreen
                style={{
                  width: '1rem',
                  height: '1rem',
                  color: 'var(--color-text-subdued)',
                }}
                className="small-icon"
              />
            </IconButton>
          </Box>
          <EditableField
            field={{
              action,
              type: 'text',
              value,
              editable: true,
            }}
            hideButton
            onAction={(id, _, payload) => onChange(payload)}
            id={id ?? ''}
            oneLine={false}
            placeholder="..."
          />
        </Box>
      )}
      <Modal
        open={modal.open}
        onClose={() => setModal({ open: false, state: '' })}
        title={label}
        titleTranslation={labelTranslation}
        maxWidth="md"
        fullWidth
        onSubmit={() => {
          onChange(modal.state);
          setModal({ open: false, state: '' });
        }}
        actions={[
          {
            id: 'cancel',
            label: 'Cancel',
            onClick: () => setModal({ open: false, state: '' }),
          },
          {
            id: 'save',
            label: 'Save',
            onClick: () => {
              onChange(modal.state);
              setModal({ open: false, state: '' });
            },
            variant: 'primary',
          },
        ]}
      >
        <Box className="comment-modal">
          <TextField
            value={modal.state}
            onChange={(e) => setModal({ open: true, state: e.target.value })}
            fullWidth
            multiline
            rows={26}
            label={label}
            // style={{ height:  }}
            labelTranslation={labelTranslation}
          />
        </Box>
      </Modal>
    </>
  );
};

export default CommentModal;
