import React, { useMemo, useContext, useEffect } from 'react';
import Box from 'Components/Atoms/Box';
import NavBar from 'Components/Organisms/NavBar';
import { SettingsRounded } from 'Components/Atoms/Icons';
import Container from 'Components/Atoms/Container';
import GridItem from 'Components/Atoms/GridItem';
import Divider from 'Components/Atoms/Divider';
import IconButton from 'Components/Atoms/IconButton';
import ProductIcons from '../../Components/ProductIcons';
import { Forecast, ReservationSnippet, UserData } from 'utils/types';
import TotalReservationsCard from 'Components/Organisms/TotalReservationsCard';
import GroupsPerInterval from 'Components/Organisms/GroupsPerInterval';
import { dateHelper, findOverlaps, reservationToSnippet } from 'utils/helper';
import ShiftReservationCard from 'Components/Organisms/ShiftReservationCard';
import GridContainer from 'Components/Atoms/GridContainer';
import PieChartCard from 'Components/Molecules/PieChartCard';
import Notifications from 'Components/Organisms/Notifications';
import SelectRestaurant from 'App/Dashboard/Components/SelectRestaurant';
import SettingsDropdown from 'App/Dashboard/Components/SettingsDropdown';
import { RestaurantContext } from 'Contexts/RestaurantContext';
import { useHistory } from 'react-router';
import UtilizationByTime from 'Components/Organisms/UtilizationByTime';
import { ReservationContext } from 'Contexts/ReservationContext';
import CloseShiftModal from 'Components/Organisms/CloseShiftModal';
import useStatistics from 'CustomHooks/useStatistics';
import { Date as DateType, ReservationShift } from 'types/shifts';
import { ReservationStatus } from 'types/reservations';
import useProductTour from 'CustomHooks/useProductTour';
import ConnectionStatus from 'App/Dashboard/Components/ConnectionStatus';
import ShiftCommentTabs from 'App/ReservationBook/Components/ShiftCommentTabs';
import useShiftComments from 'CustomHooks/useShiftComments';
import { Button, useMediaQuery } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import Mailbox from 'App/Dashboard/Components/Mailbox';
import useDocument from 'CustomHooks/useDocument';
import { SiaSettingsDocument } from 'gastronaut-shared/types/documents/restaurants/settings';
import useLocalStorageState from 'CustomHooks/useLocalStorageState';
import ChangelogModal from 'App/Dashboard/Components/ChangelogModal';
import { ChangelogContext } from 'Contexts/ChangelogContext';
import usePinAuth from 'CustomHooks/usePinAuth';
import NotificationV03List from 'Components/Organisms/NotificationV03List';
import server from 'utils/server';
import { AuthContext } from 'Contexts/AuthContext';
import PurchaseCard from 'App/Experiences/Components/PurchaseCard';
import RichAd from 'App/Dashboard/Components/RichAd';
import MissingMollie from 'App/Experiences/Components/Mollie';
import useAlert from 'CustomHooks/useAlert';
import AdminNotes from 'App/Dashboard/Components/AdminNotes';

import firebase from 'utils/firebase';

export type DashboardProps = {
  user: null | UserData;
  forecast: null | Forecast;
  update?: () => void;
};

const Dashboard = ({
  user = null,
  forecast = null,
  update,
}: DashboardProps) => {
  // const [dateToModify, setDateToModify] = useState<string>("");

  const [] = useLocalStorageState('showMessage-apps', true);

  const INDEX = useMemo(() => {
    return Math.round(Math.random() * 2);
  }, []);

  const {
    date,
    dateData,
    submitDateData,
    setDateData,
    openModal,
    setOpenModal,
  } = useStatistics('', update);

  const {
    restaurantId,
    switchRestaurant,
    products,
    lightReservation,
    notificationsV03Open,
  } = useContext(RestaurantContext);

  const { uid } = useContext(AuthContext);

  const { authenticate } = usePinAuth();

  const commentShifts = useShiftComments(restaurantId);

  const navigateToAppB = async () => {
    try {
      const user = firebase.auth().currentUser;
      if (user) {
        const idToken = await user.getIdToken(/* forceRefresh */ true);
        // Redirect to App B with the token
        window.location.href = `http://localhost:5173/${restaurantId}?token=${idToken}`;
      } else {
        // Handle the case where the user is not logged in
      }
    } catch (error) {
      window.alert(error);
    }
  };

  const isAdmin =
    !!user?.isGastronautAdmin ||
    !!user?.restaurants?.find((r) => r.id === restaurantId)?.admin;

  const alert = useAlert();

  const {
    reservations,
    shifts,
    occassions = [],
  } = useContext(ReservationContext);

  const history = useHistory();
  const isMobile = useMediaQuery('(max-width:450px)');

  const handleAction = (
    date: string,
    target: 'close' | 'terrace' | 'optimize',
    open?: boolean
  ) => {
    // console.log('date To change', date);
    // console.log('dateData', dateData);
    if (target === 'close') {
      setOpenModal(date);
    } else if (target === 'terrace') {
      if (open) {
        alert({
          title: 'Terrasse schließen?',
          description:
            'Wenn du fortführst wird die Terrasse geschlossen. Möchtest du fortfahren?',
          secondaryAction: {
            label: 'Reservierung nach innen verschieben?',
            default: false,
          },
          onSubmit: (secAction) => {
            server
              .get(
                `/v03/reservations/toggleTerrace/${restaurantId}/${date}/${uid}?moveReservations=${
                  secAction ? 'true' : 'false'
                }`
              )
              .then(() => {
                update?.();
              });
          },
        });
      } else if (typeof open === 'boolean' && !open) {
        alert({
          title: 'Terrasse öffnen?',
          description:
            'Wenn du fortführst wird die Terrasse geöffnet. Möchtest du fortfahren?',
          secondaryAction: {
            label: 'Reservierung auf Terrasse verschieben?',
            default: false,
          },
          onSubmit: (secAction) => {
            server
              .get(
                `/v03/reservations/toggleTerrace/${restaurantId}/${date}/${uid}?moveReservations=${
                  secAction ? 'true' : 'false'
                }`
              )
              .then(() => {
                update?.();
              });
          },
        });
      } else {
        server
          .get(`/v03/reservations/toggleTerrace/${restaurantId}/${date}/${uid}`)
          .then(() => {
            update?.();
          });
      }
    } else if (target === 'optimize') {
      alert({
        title: 'Reservierungen optimieren',
        description:
          'Reservierung automatisch umplatzieren? Dadurch können mehr Gäste Platz finden.',
        onSubmit: () => {
          server
            .get(
              `/v03/reservations/optimizeReservations/${restaurantId}/${date}/${uid}`
            )
            .then(() => {
              update?.();
            });
        },
      });
    }
  };

  const reservationSnippets = useMemo(
    () =>
      reservations
        .filter((r) => !r.blockedFullShift && r.guests)
        .map(reservationToSnippet),
    [reservations]
  );

  const { richShifts, otherReservations } = useMemo(() => {
    let richShifts: (ReservationShift & {
      reservations: ReservationSnippet[];
    })[] = [];
    let resaIds: string[] = [];

    (shifts ?? []).forEach((s) => {
      let filteredRes = reservations.filter(
        (r) =>
          !r.blockedFullShift &&
          (s.occassions?.includes(r.occassion) ||
            s.spaces?.includes(r?.space ?? '')) &&
          findOverlaps(r, s.start || 0, s.close || 0, true) &&
          r.status !== ReservationStatus.FAILED
      );

      richShifts.push({
        ...s,
        reservations: filteredRes.map(reservationToSnippet),
      });

      resaIds.push(...filteredRes.map((r) => r.id));
    });

    let otherReservations = reservations
      .filter(
        (r) => !resaIds.includes(r.id) && r.status !== ReservationStatus.FAILED
      )
      .map(reservationToSnippet);

    console.log({ otherReservations });

    return { richShifts, otherReservations };
  }, [reservations, shifts]);

  const reservationsByOccassions = useMemo(() => {
    return occassions
      .map((occ) => ({
        value: reservationSnippets
          .filter(
            (res) =>
              res.occassion === occ.id &&
              res.status !== ReservationStatus.FAILED
          )
          .reduce((acc: number, cV) => acc + cV.guests, 0),
        label: occ.title,
        id: occ.id,
      }))
      .filter((x) => !!x.value);
  }, [reservationSnippets, occassions]);

  const reservationBySource = useMemo(() => {
    let sourceTable = reservationSnippets
      .filter((res) => res.status !== ReservationStatus.FAILED)
      .reduce((acc: any, cV) => {
        if (!acc[cV.source]) {
          acc[cV.source] = cV.guests;
        } else {
          acc[cV.source] += cV.guests;
        }

        return acc;
      }, {});

    return Object.keys(sourceTable)
      .map((id) => ({
        id,
        label: id,
        value: sourceTable[id],
      }))
      .filter((x) => !!x.value);
  }, [reservationSnippets]);

  const userRestaurants = user?.restaurants || [];

  const handleRestaurantChange = (rId: string) => {
    switchRestaurant(rId);
    history?.push(`/${rId}`);
  };

  const handleSettingsClick = (rId: string) => {
    switchRestaurant(rId);
    history?.push(`/${rId}/settings`);
  };

  // const [siaSettings] = useDocument<SiaSettingsDocument>(
  //   `/restaurants/${restaurantId}/settings`,
  //   'siaSettingsV02'
  // );

  const totalReservations = reservationSnippets
    .filter((r) => r.status !== ReservationStatus.FAILED)
    .reduce((acc, cV) => acc + cV.guests, 0);

  const {
    allShowingChangelogs,
    showChangelog,
    setshowChangelog,
    updateProfilesLatestVersion,
  } = useContext(ChangelogContext);

  // @Leon
  // this is written just for the Pincode modal to open
  // delete upon completion

  return (
    <Box background style={{ display: 'flex', alignItems: 'stretch' }}>
      <Box style={{ overflow: 'scroll', height: '100vh' }}>
        <NavBar
          title="Dashboard"
          hideMenu
          right={
            <>
              <Notifications />
              {user !== null && !isMobile && <SettingsDropdown user={user} />}
            </>
          }
        />

        {user && showChangelog && (
          <ChangelogModal
            isOpen={showChangelog}
            onClose={() => setshowChangelog(false)}
            data={allShowingChangelogs}
            onOk={() => updateProfilesLatestVersion(user?.uid ?? '')}
          />
        )}
        <Container
          className="homeScreen"
          style={{ width: notificationsV03Open ? 'calc(100vw - 378px)' : '' }}
        >
          <GridItem xs={12}>
            <Box flex className="space-between" style={{ marginTop: 32 }}>
              <SelectRestaurant
                restaurantId={restaurantId}
                restaurants={userRestaurants}
                onRestaurantChange={handleRestaurantChange}
                onSettingsClick={handleSettingsClick}
              />
              {isAdmin && (
                <IconButton
                  size="large"
                  disabled={!restaurantId}
                  target="_blank"
                  color="subdued"
                  aria-label="Settings"
                  onClick={() =>
                    authenticate('dashboard.settings.read', () =>
                      history?.push(`/${restaurantId}/settings`)
                    )
                  }
                >
                  <SettingsRounded color="inherit" />
                </IconButton>
              )}
            </Box>
          </GridItem>

          {/* {showMessage && (
            <GridItem xs={12}>
              <Alert
                severity="info"
                style={{ fontSize: '90%' }}
                onClose={() => setShowMessage(false)}
              >
                <AlertTitle style={{ fontSize: '110%', fontWeight: 'bolder' }}>
                  Neue Features in den Einstellungen!
                </AlertTitle>
                Reduziere No-Shows mit einer Kreditkartenabfrage, integriere
                deine Reservierungen in soziale Medien wie Instagram und
                Facebook und fülle kurzfristige Stornos effizient wieder auf,
                mithilfe der Warteliste!
              </Alert>
            </GridItem>
          )} */}

          {/* <RichAd
            id="addons-2"
            title="Verkauft Extras im Reservierungsprozess "
            image="/cash.png"
            imageStyle={{
              width: 200,
              position: 'absolute',
              right: '0',
              top: '39px',
              zIndex: 0,
            }}
            button={{
              text: 'Bis zu 3000€ mehr Umsatz im Monat',
              onClick: () => {
                history.push(`/${restaurantId}/experiences`);
              },
            }}
            description={
              'Nutze unser neuestes Feature "Add-Ons", um neue Extras wie ein <strong>Candle Light Dinner, garantierter Fensterplatz, Chefstable, Infinity Rosen, Geburtstagskuchen, Aperitif und vieles mehr</strong> anzubieten. Lass dich von unseren Vorlagen inspirieren und generiere damit neuen nie zuvor dagewesenen Umsatz!'
            }
          /> */}

          <RichAd
            id="voucher"
            title={
              [
                'Verwandelt Gutscheine in Geschenke – Mit Gastronaut Wertgutscheinen',
                'Wertgutscheine – Die perfekte Balance für euer Restaurant',
                'Mehr Umsatz mit unseren Wertgutscheinen – Einfach, elegant, effektiv!',
              ][INDEX]
            }
            image="/voucher1.png"
            imageStyle={{
              width: 130,
              position: 'absolute',
              right: '-10px',
              top: '-44px',
              zIndex: 0,
            }}
            button={{
              text: 'Mehr erfahren',
              onClick: () => {
                history.push(
                  `/${restaurantId}/settings/general/apps?appId=voucher`
                );
              },
            }}
            description={
              [
                'Unsere Wertgutscheine sind einfach zu kaufen und zu verwalten. Passt die Liefermethoden an und macht aus einem einfachen Gutschein ein unvergessliches Geschenk. Entdeckt, wie leicht es ist, eure alten Gutscheine zu importieren und von geringen Gebühren zu profitieren.',
                'Findet die perfekte Balance mit unseren Wertgutscheinen. Einfach zu kaufen, individuell anpassbar und mit verschiedenen Liefermethoden erhältlich. Ideal für Upselling und einfach zu verwalten. Importiert eure alten Gutscheine und genießt niedrige Gebühren.',
                'Steigert euren Umsatz mit Gastronaut Wertgutscheinen. Unsere Gutscheine lassen sich online und vor Ort kaufen, haben ein anpassbares Design und bieten zahlreiche Upselling-Möglichkeiten. Einfache Handhabung und niedrige Gebühren machen den Umstieg leicht. Erfahrt mehr über die Vorteile!',
              ][INDEX]
            }
          />

          <MissingMollie restaurantId={restaurantId || ''} />

          <GridItem xs={12}>
            <ProductIcons products={products} restaurantId={restaurantId} />
          </GridItem>

          <ConnectionStatus />

          {products.includes('phone') && <Mailbox />}

          {products.includes('addOns') && !!restaurantId && (
            <GridItem xs={12}>
              <PurchaseCard restaurantId={restaurantId} />
            </GridItem>
          )}

          {(products.includes('reservation') ||
            products.includes('light-reservation')) && (
            <>
              <GridItem
                xs={12}
                {...(notificationsV03Open ? { lg: 7, md: 12 } : { md: 7 })}
              >
                {!!restaurantId &&
                  !!commentShifts.filter((x) => x.comment).length && (
                    <ShiftCommentTabs
                      small
                      shiftOptions={commentShifts
                        .filter((x) => !!x.comment)
                        .map((x) => ({
                          id: x.shift.id,
                          label: x.shift.name || '',
                          shift: x.shift,
                          comment: x.comment,
                        }))}
                      restaurantId={restaurantId}
                      date={date}
                      style={{ marginBottom: 16 }}
                    />
                  )}

                <TotalReservationsCard
                  totalReservations={totalReservations}
                  forecast={forecast}
                  handleAction={handleAction}
                />
                <GroupsPerInterval
                  shifts={richShifts}
                  otherReservations={otherReservations}
                  style={{ marginBottom: 16 }}
                />
                <UtilizationByTime
                  shifts={richShifts}
                  otherReservations={otherReservations}
                />
              </GridItem>

              <GridItem
                xs={12}
                {...(notificationsV03Open ? { lg: 5, md: 12 } : { md: 5 })}
              >
                <GridContainer style={{ paddingBottom: 16 }}>
                  <GridItem
                    xs={12}
                    {...(notificationsV03Open ? { lg: 6, md: 12 } : { md: 6 })}
                  >
                    <PieChartCard
                      title="Occassions"
                      data={reservationsByOccassions}
                      style={{ height: '100%' }}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    {...(notificationsV03Open ? { lg: 6, md: 12 } : { md: 6 })}
                  >
                    <PieChartCard
                      title="Sources"
                      data={reservationBySource}
                      style={{ height: '100%' }}
                    />
                  </GridItem>
                </GridContainer>
                <ShiftReservationCard
                  reservations={reservationSnippets}
                  restaurantId={restaurantId ?? ''}
                  date={date || dateHelper()}
                  lightReservation={lightReservation}
                />
              </GridItem>
            </>
          )}
          {!products.includes('reservation') &&
            !products.includes('light-reservation') && (
              <GridItem
                xs={12}
                {...(notificationsV03Open ? { lg: 6, md: 12 } : { md: 6 })}
                style={{ height: '50vh' }}
              />
            )}
        </Container>
        {!!dateData && openModal && (
          <CloseShiftModal
            open={!!openModal}
            onClose={() => setOpenModal(null)}
            date={openModal ?? ''}
            onSubmit={submitDateData}
            dateData={dateData}
            onChange={(nV: DateType) => setDateData(nV)}
          />
        )}
      </Box>
      <NotificationV03List />
      {!!user?.isGastronautAdmin && !!restaurantId && (
        <AdminNotes restaurantId={restaurantId} />
      )}
    </Box>
  );
};

export default Dashboard;
