import React, { useMemo } from "react";
import Box from "Components/Atoms/Box";
import dayjs from "dayjs";
import "./styles.scss";
import { Date as DateType, TakeAwayShift } from "types/shifts";
import { ShiftActionType } from "Contexts/CalendarContext";
import Sidebar from "Components/Molecules/Sidebar";
import { blockToTime, getTime, noop } from "utils/helper";
import Button from "Components/Atoms/Button";
import {
  LockOpenOutlined,
  Warning,
  LockOutlined,
} from "Components/Atoms/Icons";
import Typography from "Components/Atoms/Typography";
import { useTranslation } from "react-i18next";

export type CurrentTakeAwayShiftCardProps = {
  restaurantId: string;
  date: DateType<TakeAwayShift>;
  shiftId: string;
  onShiftAction: (
    id: string,
    date: string,
    type: ShiftActionType,
    payload?: any
  ) => void;
  onClose: () => void;
};

const CurrentTakeAwayShiftCard: React.FC<CurrentTakeAwayShiftCardProps> = ({
  date,
  shiftId,
  restaurantId,
  onShiftAction,
  onClose,
}) => {
  const { t } = useTranslation("common");

  const currentShift = useMemo(
    () => date.shifts.find((shift) => shift.id === shiftId),
    [date, shiftId]
  );

  const statusHandler = () => {
    if (
      Date.now() > getTime(date.date, currentShift?.close, currentShift?.start)
    )
      return "Done";
    if (currentShift?.edited) return "Edited";
    if (currentShift?.closed) return "Closed";
    if (currentShift?.regular) return "Standard";
    else return "Special";
  };

  return (
    <Box className="current-guest-ordering-shift-card-container">
      <Sidebar
        id={shiftId}
        titleTranslation={null}
        title={
          currentShift?.name + " - " + dayjs(date.date).format("DD.MM.YYYY")
        }
        actions={
          <>
            {/* {currentShift?.closed ? (
                <Button
                  translation={'calendar'}
                  fullWidth
                  variant='outlined'
                  color='error'
                  endIcon={() => (
                    <LockOutlined
                      color='inherit'
                      style={{ marginLeft: 8, width: 20, height: 20 }}
                    />
                  )}
                  onClick={() =>
                    onShiftAction(
                      currentShift?.id ?? '',
                      date.date,
                      ShiftActionType.OPEN_RESERVATIONS
                    )
                  }
                  style={{ border: '1px solid var(--color-critical)' }}
                >
                  Re-open for the day
                </Button>
              ) : (
                <Button
                  fullWidth
                  translation={'calendar'}
                  variant='outlined'
                  color='error'
                  endIcon={() => (
                    <LockOpenOutlined
                      color='inherit'
                      style={{ marginLeft: 8, width: 20, height: 20 }}
                    />
                  )}
                  onClick={() =>
                    onShiftAction(
                      currentShift?.id ?? '',
                      date.date,
                      ShiftActionType.CLOSE_RESERVATIONS
                    )
                  }
                  style={{ border: '1px solid var(--color-critical)' }}
                >
                  Disable for the Day
                </Button>
              )} */}
            <Button
              fullWidth
              onClick={() =>
                onShiftAction(
                  currentShift?.id ?? "",
                  date.date,
                  ShiftActionType.EDIT_SHIFT
                )
              }
              color="secondary"
              variant="secondary"
              style={{ margin: "12px 0px" }}
            >
              Edit
            </Button>
            <Button
              fullWidth
              variant="outlined"
              color="error"
              endIcon={() => (
                <Warning
                  color="inherit"
                  style={{ marginLeft: 8, width: 20, height: 20 }}
                />
              )}
              onClick={() =>
                onShiftAction(
                  currentShift?.id ?? "",
                  date.date,
                  ShiftActionType.DELETE_SHIFT
                )
              }
              style={{ border: "1px solid var(--color-critical)" }}
              translation="calendar"
            >
              Delete
            </Button>
          </>
        }
        onClose={onClose}
        tabs={[
          {
            id: "0",
            label: "Details",
            translation: "settings",
          },
        ]}
        onTabChange={noop}
        currentTab={"0"}
        deductFromHeight={12}
      >
        <Box className="details-container">
          <div className="details-row">
            <Typography variant="text-3" translation="settings">
              Status
            </Typography>
            <Typography variant="text-3" color="subdued" translation="settings">
              {statusHandler()}
            </Typography>
          </div>
          <div className="details-row">
            <Typography variant="text-3" translation="settings">
              Date
            </Typography>
            <Typography variant="text-3" color="subdued">
              {dayjs(date.date).format("DD.MM.YYYY")}
            </Typography>
          </div>
          <div className="details-row">
            <Typography variant="text-3" translation="calendar">
              Start Time
            </Typography>
            <Typography variant="text-3" color="subdued">
              {blockToTime(currentShift?.start ?? 64)}
            </Typography>
          </div>
          <div className="details-row">
            <Typography variant="text-3" translation="calendar">
              Close Time
            </Typography>
            <Typography variant="text-3" color="subdued">
              {blockToTime(currentShift?.close ?? 64)}
            </Typography>
          </div>
          {!currentShift?.closed && (
            <>
              <div className="details-row">
                <Typography variant="text-3" translation="common">
                  Delivery Method
                </Typography>
                <Typography variant="text-3" color="subdued">
                  {currentShift
                    ? currentShift?.deliveryMethods
                        ?.map((x) => t(x))
                        .join(" & ")
                    : ""}
                </Typography>
              </div>
              <div className="details-row">
                <Typography variant="text-3" translation="calendar">
                  Advert
                </Typography>
                <Typography variant="text-3" color="subdued">
                  {currentShift?.advert?.active
                    ? currentShift?.advert?.translations.de.title
                    : "No Advert"}
                </Typography>
              </div>
            </>
          )}
        </Box>
      </Sidebar>
    </Box>
  );
};

export default CurrentTakeAwayShiftCard;
