import { useState, useMemo, useCallback } from 'react';
import './styles.scss';
import { Reservation, Space, Table as TableType } from 'types/reservations';
import Card from 'Components/Molecules/Card';
import Dropdown from 'Components/Molecules/Dropdown';
import { noop, findOverlaps, stitchShifts } from 'utils/helper';
import Table from './Components/Table';
import { ReservationActionTypes } from 'Contexts/ReservationContext';
import { CircularProgress } from '@material-ui/core';
import { ReservationShift } from 'types/shifts';

export type TimeTableProps = {
  reservations: Reservation[];
  handleReservationAction: (
    id: string,
    action: ReservationActionTypes,
    payload?: any
  ) => void;
  handleReservationClick: (id: string) => void;
  handleTableClick: (id: string) => void;
  shifts: ReservationShift[];
  spaces: Space[];
  tables: TableType[];
  occassions: { id: string; title: string }[];
  activeReservation: null | string;
  activeTable: null | string;
  loading: boolean;
  currentTime: number | null;
  overlayOn?: boolean;
};

export type TimeTable = {
  id: string;
  title: string;
  closed: boolean;
  start: number | null;
  close: number | null;
  tables: TableType[];
  reservations: Reservation[];
};

const TimeTable = ({
  reservations = [],
  handleReservationAction = noop,
  handleTableClick = noop,
  handleReservationClick = noop,
  shifts = [],
  spaces = [],
  tables = [],
  occassions = [],
  currentTime = null,
  activeReservation = null,
  activeTable = null,
  loading = false,
  overlayOn = false,
}: TimeTableProps) => {
  const [currentShift, setcurrentShift] = useState<null | string>(null);

  const [trackRect, settrackRect] = useState<DOMRect | null>(null);

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      settrackRect(node.getBoundingClientRect());
    }
  }, []);

  const timeTables: TimeTable[] = useMemo(() => {
    // const filteredShifts = shifts.filter(s => currentShift === null || s.id === currentShift);

    // const [start, close] = filteredShifts.reduce((acc:[number|null,number|null], cV) => {
    //     if(cV.start !== null && (acc[0] === null || acc[0] > cV.start)) {
    //         acc[0] = cV.start;
    //     }

    //     if(cV.close !== null && (acc[1] === null || acc[1] < cV.close)) {
    //         acc[1] = cV.close;
    //     }

    //     return acc;
    // }, [null, null]);

    const { startStop, filteredShifts } = stitchShifts(shifts, currentShift);

    let [start, close] = startStop;

    if (start === null || close === null) return [];

    const filteredTables = tables.sort(
      (a, b) =>
        parseInt(a.name) - parseInt(b.name) || a.name.localeCompare(b.name)
    );
    // .filter((t) =>
    //   filteredShifts.some(
    //     (s) =>
    //       s.spaces.includes(t.space) ||
    //       t.occassions.some((occ) => s.occassions.includes(occ)) ||

    //   )
    // );

    const spaceIds = filteredTables.reduce(
      (acc: string[], cV) => [...new Set([...acc, cV.space])],
      []
    );

    const filteredReservations = reservations.filter(
      (r) =>
        r.status !== 'failed' &&
        (!currentShift || findOverlaps(r, start || 0, close || 0)) &&
        typeof r.startTimeInBlocks === 'number' &&
        typeof r.endTimeInBlocks === 'number'
    );

    let newStart = Math.min(
      filteredReservations.sort(
        (a, b) => a.startTimeInBlocks - b.startTimeInBlocks
      )?.[0]?.startTimeInBlocks || 1000,
      start
    );

    let lastRes = filteredReservations.sort((a, b) => {
      let endA =
        a.endTimeInBlocks > a.startTimeInBlocks
          ? a.endTimeInBlocks
          : a.endTimeInBlocks + 96;

      let endB =
        a.endTimeInBlocks > a.startTimeInBlocks
          ? a.endTimeInBlocks
          : a.endTimeInBlocks + 96;

      return endB - endA;
    })?.[0];

    let newClose = close;

    if (lastRes && lastRes.endTimeInBlocks) {
      let endOfRes =
        lastRes.endTimeInBlocks > lastRes.startTimeInBlocks
          ? lastRes.endTimeInBlocks
          : lastRes.endTimeInBlocks + 96;

      if (endOfRes > newClose) {
        newClose = endOfRes;
      }
    }

    const tableIds = tables.map((t) => t.name);

    const arr: TimeTable[] = spaceIds.map((id) => ({
      id,
      title: spaces.find((s) => s.id === id)?.name || id,
      closed: false,
      start: newStart,
      close: newClose,
      tables: tables
        .filter((t) => t.space === id)
        .sort((a, b) => {
          if (Number(a.name) && Number(b.name)) {
            return Number(a.name) - Number(b.name);
          }

          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }),
      reservations: filteredReservations.filter(
        (r) => r.space === id || tableIds.some((t) => !!r.tables?.includes(t))
      ),
    }));

    return arr;
  }, [spaces, tables, reservations, shifts, currentShift]);

  return (
    <Card
      title="Time Table"
      titleTranslation="reservations"
      headerPadding="sm"
      className="timeTable"
      headerRight={
        <Dropdown<string | null>
          value={currentShift}
          onChange={setcurrentShift}
          disabled={shifts.length <= 1}
          textAlign="right"
          options={[
            { id: null, label: 'All Day' },
            ...shifts.map((s) => ({ id: s.id ?? null, label: s.name || '' })),
          ]}
        />
      }
      bodyProps={{
        padding: 0,
        id: 'timeTableCard',
        style: {
          maxHeight: overlayOn ? 'calc(100vh - 190px)' : 'calc(100vh - 130px)',
          overflow: 'scroll',
          padding: '16px 0px',
        },
      }}
    >
      <div className="timeTable-inner">
        <div className="track" id="trackRect" ref={measuredRef}>
          {timeTables.map((timeTable, index) => (
            <Table
              key={timeTable.id}
              index={index}
              data={timeTable}
              activeReservation={activeReservation}
              activeTable={activeTable}
              onTableClick={handleTableClick}
              trackRect={trackRect}
              occassions={occassions}
              handleReservationAction={handleReservationAction}
              onReservationClick={handleReservationClick}
              currentTime={currentTime}
            />
          ))}
        </div>
      </div>
    </Card>
  );
};

export default TimeTable;
