import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const VoucherIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.9998 8.33333C33.1498 7.75 31.1165 7.5 29.1665 7.5C25.9165 7.5 22.4165 8.16667 19.9998 10C17.5832 8.16667 14.0832 7.5 10.8332 7.5C7.58317 7.5 4.08317 8.16667 1.6665 10V34.4167C1.6665 34.8333 2.08317 35.25 2.49984 35.25C2.6665 35.25 2.74984 35.1667 2.9165 35.1667C5.1665 34.0833 8.4165 33.3333 10.8332 33.3333C14.0832 33.3333 17.5832 34 19.9998 35.8333C22.2498 34.4167 26.3332 33.3333 29.1665 33.3333C31.9165 33.3333 34.7498 33.8333 37.0832 35.0833C37.2498 35.1667 37.3332 35.1667 37.4998 35.1667C37.9165 35.1667 38.3332 34.75 38.3332 34.3333V10C37.3332 9.25 36.2498 8.75 34.9998 8.33333ZM34.9998 30.8333C33.1665 30.25 31.1665 30 29.1665 30C26.3332 30 22.2498 31.0833 19.9998 32.5V13.3333C22.2498 11.9167 26.3332 10.8333 29.1665 10.8333C31.1665 10.8333 33.1665 11.0833 34.9998 11.6667V30.8333Z"
        fill={props.fill ?? 'white'}
      />
    </SvgIcon>
  );
};

export default VoucherIcon;
