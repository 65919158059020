import React, { useContext, useEffect, useMemo, useState } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import {
  Date as DateType,
  ReservationShift,
  ShiftComment as ShiftCommentType,
  ShiftType,
} from 'types/shifts';
import Sidebar from '../../../../Components/Molecules/Sidebar';
import { Tab } from '../../../../Components/Atoms/Tabs';
import DetailsTab from './Components/DetailsTab';
import NotesTab from './Components/NotesTab';
import Button from '../../../../Components/Atoms/Button';
import IconButton from '../../../../Components/Atoms/IconButton';
import { Warning } from 'Components/Atoms/Icons';
import dayjs from 'dayjs';
import TotalReservationsResButton from '../../../ReservationBook/Components/TotalReservations/Components/TotalReservationsResButton';
import TotalReservationsTerraceButton from '../../../ReservationBook/Components/TotalReservations/Components/TotalReservationsTerraceButton';
import { blockToTime, timeHelper, getTime } from 'utils/helper';
import { FloorPlan } from 'types/reservations';
import { CalendarContext, ShiftActionType } from 'Contexts/CalendarContext';
import { db } from '../../../../utils/firebase';
import useIsLightReservations from 'CustomHooks/useIsLightReservations';
import { useRouter } from 'CustomHooks/useRouter';
import { Trans } from 'react-i18next';
import { RestaurantContext } from 'Contexts/RestaurantContext';
import useProtectedAction from 'utils/useProtectedAction';
import {
  ConfirmationNumber,
  DeleteForever,
  Delete,
  FileCopy,
} from '@material-ui/icons';
import ButtonWithOptions from 'Components/Molecules/ButtonWithOptions';
import useCollection from 'CustomHooks/useCollection';
import useRestaurant from 'CustomHooks/useRestaurant';
import { EventV02Document } from 'gastronaut-shared/types/documents/restaurants';

export type CurrentReservationShiftCardProps = {
  restaurantId: string;
  date: DateType;
  shiftId: string;
  onShiftAction: (
    id: string,
    date: string,
    type: ShiftActionType,
    payload?: any
  ) => void;
  onClose: () => void;
  settings?: {
    occassions: { id: string; title: string }[];
    spaces: { id: string; title: string }[];
    floorPlans?: FloorPlan[];
  };
};

const Actions: React.FC<
  CurrentReservationShiftCardProps & {
    currentShift?: ReservationShift;
    inPast: boolean;
    currentComment: ShiftCommentType | null;
    onEditComment: () => void;
    tab: string | number | null;
  }
> = ({
  currentShift,
  inPast,
  currentComment,
  onEditComment,
  tab,
  children,
  onShiftAction,
  date,
  ...props
}) => {
  const lightReservations = useIsLightReservations();
  const router = useRouter();
  const protectedAction = useProtectedAction();

  const { restaurantId } = useRestaurant();

  const { eventTemplates } = useContext(CalendarContext);

  const onGoToReservations = () =>
    router.push(
      `/${props.restaurantId}/reservations/${date.date}/${
        !lightReservations ? 'tablePlan' : 'reservationList'
      }`
    );

  const onBlockedReservationAction = () =>
    protectedAction(
      'chefWaiter',
      shiftIsBlocked
        ? `Schicht schließen ${date.date} - ${currentShift?.name ?? ''}`
        : `Schicht öffnen ${date.date} - ${currentShift?.name ?? ''}`,
      () => {
        onShiftAction(
          currentShift?.id ?? '',
          date.date,
          shiftIsBlocked
            ? ShiftActionType.OPEN_RESERVATIONS
            : ShiftActionType.CLOSE_RESERVATIONS
        );
      },
      'calendar.blockReservations.write'
    );

  const shiftIsBlocked =
    (currentShift?.type === ShiftType.RESERVATION &&
      !!currentShift?.overwrite?.blockReservations) ||
    false;

  if (currentShift?.commentOnly || tab === 1) {
    return (
      <div className="actions-container">
        {currentComment ? (
          <Button
            fullWidth
            variant={!!currentShift?.commentOnly ? 'outlined' : 'secondary'}
            style={{
              marginTop: '8px',
              height: '42px',
            }}
            onClick={onEditComment}
            translation="reservations"
          >
            Edit Comment
          </Button>
        ) : (
          <Button
            fullWidth
            variant={!!currentShift?.commentOnly ? 'outlined' : 'secondary'}
            style={{
              marginTop: '8px',
              height: '42px',
            }}
            onClick={onEditComment}
            translation="reservations"
          >
            Add comment
          </Button>
        )}
        <Button
          fullWidth
          className="delete-button"
          style={{
            color: 'var(--color-critical)',
            borderColor: 'var(--color-critical)',
            height: '42px',
            marginBottom: 0,
            marginLeft: 4,
          }}
          variant="outlined"
          typographyProps={{
            style: { color: 'var(--color-critical)' },
          }}
          onClick={() =>
            protectedAction(
              'chefWaiter',
              `Schicht löschen ${date.date} - ${currentShift?.name ?? ''}`,
              () => {
                onShiftAction(
                  currentShift?.id ?? '',
                  date.date,
                  ShiftActionType.DELETE_SHIFT
                );
              },
              'calendar.deleteShift.write'
            )
          }
          endIcon={(p) => <Warning {...p} />}
        >
          <Trans i18nKey="calendar_Delete">
            {currentShift?.disabled ? 'Delete Completly' : 'Delete'}
          </Trans>
        </Button>
      </div>
    );
  }

  if (inPast) {
    return (
      <div className="actions-container">
        <Button
          fullWidth
          variant="outlined"
          style={{
            marginTop: '8px',
            height: '42px',
          }}
          onClick={onGoToReservations}
        >
          Go to Reservations
        </Button>
        <Button
          fullWidth
          variant="secondary"
          style={{
            marginTop: 8,
            height: '42px',
          }}
          onClick={() =>
            protectedAction(
              'chefWaiter',
              `Schicht duplizieren ${date.date} - ${currentShift?.name ?? ''}`,
              () => {
                onShiftAction(
                  currentShift?.id ?? '',
                  date.date,
                  ShiftActionType.DUPLICATE_SHIFT
                );
              },
              'calendar.newShift.write'
            )
          }
        >
          Duplizieren
        </Button>
      </div>
    );
  }

  if (currentShift?.closed) {
    return (
      <div className="actions-container">
        <Button
          fullWidth
          variant="secondary"
          style={{
            marginTop: '8px',
            height: '42px',
          }}
          onClick={() => {
            protectedAction(
              'chefWaiter',
              `Schicht bearbeitet ${date.date} - ${currentShift?.name ?? ''}`,
              () => {
                onShiftAction(
                  currentShift?.id ?? '',
                  date.date,
                  ShiftActionType.EDIT_SHIFT
                );
              },
              'calendar.editShift.write'
            );
          }}
        >
          Edit
        </Button>
        <Button
          fullWidth
          className="delete-button"
          style={{
            color: 'var(--color-critical)',
            borderColor: 'var(--color-critical)',
            marginTop: '8px',
          }}
          variant="outlined"
          typographyProps={{
            style: { color: 'var(--color-critical)' },
          }}
          onClick={() =>
            protectedAction(
              'chefWaiter',
              `Schicht löschen ${date.date} - ${currentShift?.name ?? ''}`,
              () => {
                onShiftAction(
                  currentShift?.id ?? '',
                  date.date,
                  ShiftActionType.DELETE_SHIFT
                );
              },
              'calendar.deleteShift.write'
            )
          }
        >
          <Trans i18nKey="calendar_Delete">
            {currentShift?.disabled ? 'Delete Completly' : 'Delete'}
          </Trans>
          <IconButton size="x-small" style={{ color: 'var(--color-critical)' }}>
            <Warning />
          </IconButton>
        </Button>
        {!!currentShift?.disabled && (
          <Button
            fullWidth
            variant="secondary"
            style={{
              marginTop: '8px',
              height: '42px',
            }}
            onClick={() =>
              protectedAction(
                'chefWaiter',
                `Schicht wiederherstellen ${date.date} - ${
                  currentShift?.name ?? ''
                }`,
                () => {
                  onShiftAction(
                    currentShift?.id ?? '',
                    date.date,
                    ShiftActionType.RESTORE_SHIFT
                  );
                },
                'calendar.editShift.write'
              )
            }
          >
            Wiederherstellen
          </Button>
        )}
      </div>
    );
  }

  return (
    <div className="actions-container">
      <TotalReservationsResButton
        fullWidth
        closed={shiftIsBlocked}
        onAction={onBlockedReservationAction}
        margin="0px 0px 8px 0px"
      />
      {!!currentShift?.additional?.eventV02 ? (
        <Button
          fullWidth
          variant="outlined"
          color="secondary"
          style={{
            marginTop: '8px',
            height: '42px',
          }}
          endIcon={(p) => <ConfirmationNumber {...p} />}
          onClick={() => {
            protectedAction(
              'chefWaiter',
              `Ticketeinstellungen bearbeitet ${date.date} - ${
                currentShift?.name ?? ''
              }`,
              () => {
                onShiftAction(
                  currentShift?.id ?? '',
                  date.date,
                  ShiftActionType.EDIT_EVENT
                );
              },
              'calendar.editShift.write'
            );
          }}
        >
          Ticket Settings
        </Button>
      ) : (
        <>
          {!!eventTemplates.length ? (
            <ButtonWithOptions
              fullWidth
              id="addEvent"
              variant="outlined"
              color="secondary"
              style={{
                marginTop: '8px',
                height: '42px',
              }}
              endIcon={(p) => <ConfirmationNumber {...p} />}
              options={[
                ...(eventTemplates.slice(0, 10).map((event) => ({
                  onClick: () => {
                    protectedAction(
                      'chefWaiter',
                      '',
                      () => {
                        onShiftAction(
                          currentShift?.id ?? '',
                          date.date,
                          ShiftActionType.ADD_EVENT,
                          { ...event, id: 'newEvent' }
                        );
                      },
                      'calendar.newShift.write'
                    );
                  },
                  style: { width: 242 },
                  children: event.title?.de ?? event.title?.en ?? '',
                })) ?? []),
                {
                  onClick: () => {
                    protectedAction(
                      'chefWaiter',
                      '',
                      () => {
                        onShiftAction(
                          currentShift?.id ?? '',
                          date.date,
                          ShiftActionType.ADD_EVENT
                        );
                      },
                      'calendar.newShift.write'
                    );
                  },
                  variant: 'primary',
                  translation: 'calendar',
                  children: 'Start from Scratch',
                },
              ]}
            >
              Add Event
            </ButtonWithOptions>
          ) : (
            <Button
              fullWidth
              id="addEvent"
              variant="outlined"
              color="secondary"
              style={{
                marginTop: '8px',
                height: '42px',
              }}
              endIcon={(p) => <ConfirmationNumber {...p} />}
              onClick={() => {
                protectedAction(
                  'chefWaiter',
                  `Event hinzugefügt ${date.date} - ${
                    currentShift?.name ?? ''
                  }`,
                  () => {
                    onShiftAction(
                      currentShift?.id ?? '',
                      date.date,
                      ShiftActionType.ADD_EVENT
                    );
                  },
                  'calendar.editShift.write'
                );
              }}
            >
              Add Event
            </Button>
          )}
        </>
      )}
      <Box flex style={{ marginTop: 12 }}>
        <Button
          fullWidth
          variant="secondary"
          style={{
            height: '42px',
            marginBottom: 0,
            marginRight: 4,
          }}
          onClick={() => {
            protectedAction(
              'chefWaiter',
              `Schicht bearbeitet ${date.date} - ${currentShift?.name ?? ''}`,
              () => {
                onShiftAction(
                  currentShift?.id ?? '',
                  date.date,
                  ShiftActionType.EDIT_SHIFT
                );
              },
              'calendar.editShift.write'
            );
          }}
        >
          Edit
        </Button>
        <Button
          fullWidth
          variant="outlined"
          translation="calendar"
          style={{
            height: '42px',
            width: '50px',
            padding: `8px 12px`,
            color: 'var(--color-secondary)',
            borderColor: 'var(--color-secondary)',
            marginBottom: 0,
            marginLeft: 4,
          }}
          endIcon={(p) => <FileCopy {...p} style={{ marginLeft: 0 }} />}
          onClick={() =>
            protectedAction(
              'chefWaiter',
              `Schicht dupliziert ${date.date} - ${currentShift?.name ?? ''}`,
              () => {
                onShiftAction(
                  currentShift?.id ?? '',
                  date.date,
                  ShiftActionType.DUPLICATE_SHIFT
                );
              },
              'calendar.editShift.write'
            )
          }
        ></Button>
        <Button
          fullWidth
          className="delete-button"
          style={{
            color: 'var(--color-critical)',
            borderColor: 'var(--color-critical)',
            height: '42px',
            width: '50px',
            padding: `8px 12px`,
            marginBottom: 0,
            marginLeft: 4,
          }}
          variant="outlined"
          typographyProps={{
            style: { color: 'var(--color-critical)' },
          }}
          onClick={() =>
            protectedAction(
              'chefWaiter',
              `Schicht löschen ${date.date} - ${currentShift?.name ?? ''}`,
              () => {
                onShiftAction(
                  currentShift?.id ?? '',
                  date.date,
                  ShiftActionType.DELETE_SHIFT
                );
              },
              'calendar.deleteShift.write'
            )
          }
          endIcon={(p) =>
            currentShift?.disabled ? (
              <DeleteForever {...p} style={{ marginLeft: 0 }} />
            ) : (
              <Delete {...p} style={{ marginLeft: 0 }} />
            )
          }
        >
          {/* <Trans i18nKey="calendar_Delete">
            {currentShift?.disabled ? 'Delete Completly' : 'Delete'}
          </Trans> */}
        </Button>
      </Box>
      {!!currentShift?.disabled && (
        <Button
          fullWidth
          variant="secondary"
          style={{
            marginTop: 8,
            height: '42px',
          }}
          onClick={() =>
            protectedAction(
              'chefWaiter',
              `Schicht wiederhergestellt ${date.date} - ${
                currentShift?.name ?? ''
              }`,
              () => {
                onShiftAction(
                  currentShift?.id ?? '',
                  date.date,
                  ShiftActionType.RESTORE_SHIFT
                );
              },
              'calendar.editShift.write'
            )
          }
        >
          Wiederherstellen
        </Button>
      )}
    </div>
  );
};

const CurrentReservationShiftCard: React.FC<
  CurrentReservationShiftCardProps
> = (props) => {
  const {
    restaurantId,
    date,
    shiftId,
    onClose,
    settings = { occassions: [], spaces: [] },
  } = props;

  const [tab, setTab] = useState<string | number | null>(0);
  const [currentComment, setCurrentComment] = useState<ShiftCommentType | null>(
    null
  );
  const [loading, setloading] = useState(false);

  const [editCommentModal, setEditCommentModal] = useState(false);

  const currentShift = useMemo(
    () => date.shifts.find((shift) => shift.id === shiftId),
    [date, shiftId]
  );

  const lightReservations = useIsLightReservations();

  const tabs = useMemo(() => {
    const arr: Tab[] = [
      {
        id: 0,
        label: 'Details',
        translation: 'settings',
      },
    ];

    if (!currentShift?.commentOnly) {
      arr.push({
        id: 1,
        label: 'Notes',
        translation: 'settings',
        disabled: lightReservations,
      });
    }

    return arr;
  }, [tab, lightReservations, currentShift]);

  // function inPast() {
  //   return (
  //     Date.now() > getTime(date.date, currentShift?.close, currentShift?.start)
  //   );
  // }

  const inPast = useMemo(
    () =>
      Date.now() > getTime(date.date, currentShift?.close, currentShift?.start),
    [date, currentShift]
  );

  const reservationsGroups = useMemo(() => {
    console.log(date, date?.shiftStatistics?.[shiftId]);
    if (!shiftId || !date?.shiftStatistics?.[shiftId]) return '-/-';

    let statistics = date.shiftStatistics[shiftId];

    let groups = Object.keys(statistics).map((k) => statistics[k]);

    // console.log(groups);

    let guests = groups.reduce((acc, cV) => acc + cV, 0);

    return `${guests}/${groups.length}`;
  }, [shiftId, date]);

  const [listener, setlistener] = useState<any[]>([]);

  useEffect(() => {
    if (listener.length) {
      listener.forEach((x) => x());
    }

    const ref = db
      .collection(`restaurants/${restaurantId}/shiftComments`)
      .doc(`${date.date}-${shiftId}`);
    setloading(true);
    let unsubsribe = ref.onSnapshot((doc) => {
      setCurrentComment((doc.data() as ShiftCommentType) ?? null);
      setloading(false);
    });

    setlistener([unsubsribe]);

    return () => {
      if (listener.length) {
        listener.forEach((x) => x());
      }
    };
  }, [date, shiftId]);

  return (
    <Box className="current-reservation-shift-card-container">
      <Sidebar
        id={shiftId}
        titleTranslation={null}
        title={
          currentShift?.name + ' - ' + dayjs(date.date).format('DD.MM.YYYY')
        }
        onClose={onClose}
        actions={
          <Actions
            {...props}
            currentComment={currentComment}
            currentShift={currentShift}
            inPast={inPast}
            onEditComment={() => setEditCommentModal(true)}
            tab={tab}
          />
        }
        tabs={tabs}
        onTabChange={setTab}
        currentTab={tab as string}
        deductFromHeight={12}
      >
        {(lightReservations || tab === 0) && (
          <DetailsTab
            lightReservations={lightReservations}
            shiftDetails={currentShift}
            date={date}
            occassions={settings.occassions}
            spaces={settings.spaces}
            reservationsGroups={reservationsGroups}
            currentShiftComment={currentComment}
            restaurantId={restaurantId}
            shiftId={shiftId}
            modalOpen={editCommentModal}
            setModalOpen={setEditCommentModal}
          />
        )}
        {!lightReservations && tab === 1 && (
          <NotesTab
            currentShiftComment={currentComment}
            restaurantId={restaurantId}
            date={date}
            shiftId={shiftId}
            shiftDetails={currentShift}
            modalOpen={editCommentModal}
            setModalOpen={setEditCommentModal}
          />
        )}
      </Sidebar>
    </Box>
  );
};

export default CurrentReservationShiftCard;
