import React, { useEffect, useRef, useState } from "react";
import { db } from "utils/firebase";

type useUnavailableType = {
  (restaurantId: string | null, date: string): [
    string[],
    React.Dispatch<React.SetStateAction<string[]>>
  ];
};

const useUnavailable: useUnavailableType = (restaurantId, date) => {
  const [unavailable, setunavailable] = useState<string[]>([]);

  const unavailableRef = useRef<string[]>([]);

  const [docExists, setDocExists] = useState<boolean>(false);

  const ref = db
    .collection(`/restaurants/${restaurantId}/mealAvailability`)
    .doc(date);

  useEffect(() => {
    setDocExists(false);

    if (restaurantId && date) {
      ref.get().then((doc) => {
        if (!doc.exists) {
          setunavailable([]);
          unavailableRef.current = [];
        } else {
          setDocExists(true);
          const { unavailable = [] }: any = doc.data();
          setunavailable(unavailable);
          unavailableRef.current = unavailable;
        }
      });
    }
  }, [date, restaurantId]);

  useEffect(() => {
    if (
      JSON.stringify(unavailableRef.current) !== JSON.stringify(unavailable)
    ) {
      if (docExists) {
        ref.update({ unavailable });
      } else if (!!unavailable.length) {
        ref.set({ unavailable });
      }
    }
  }, [unavailable]);

  return [unavailable, setunavailable];
};

export default useUnavailable;
