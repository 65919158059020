import React, { useState } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import useCollection from 'CustomHooks/useCollection';
import useRestaurant from 'CustomHooks/useRestaurant';
import { NotificationV03 as NotificationType } from 'gastronaut-shared/types/helper/notification';
import NotificationV03 from 'Components/Molecules/NotificationV03';
import Typography from 'Components/Atoms/Typography';
import IconButton from 'Components/Atoms/IconButton';
import { Close, NotificationImportant } from '@material-ui/icons';
import ReservationOptimization from 'Components/Atoms/ReservationOptimization';

export type NotificationV03ListProps = {};

const Type: React.FC<{
  active?: boolean;
  onClick: () => void;
  type: string;
  number?: number;
}> = ({ type, active, onClick, number }) => {
  const title = React.useMemo(() => {
    switch (type) {
      case 'all':
        return 'Alle';
      case 'weather':
        return 'Wetter';
      case 'addOnOrder':
        return 'Add-Ons';
      case 'reservation':
        return 'Resas';
      case 'comment':
        return 'Kommentar';
      case 'rating':
        return 'Bewertung';
      case 'mailbox':
        return 'Mailbox';
      case 'other':
        return 'Sonstiges';
      case 'optimizations':
        return 'Optimierung';

      default:
        return type.toLocaleUpperCase();
    }
  }, [type]);

  if (!number) return <> </>;

  return (
    <Box
      style={{
        borderRadius: 20,
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
        background: active ? 'var(--color-grey-5)' : 'var(--color-grey-3)',
        width: 'fit-content',
        minWidth: 88,
      }}
      onClick={onClick}
    >
      {type !== 'all' ? (
        <img
          src={`/notificationsV03/icons/${type}.png`}
          width="24px"
          height="24px"
          alt={type}
          style={{ marginTop: 4 }}
        />
      ) : (
        <NotificationImportant width="24px" height="24px" />
      )}
      <Typography
        variant="text-5"
        component="p"
        textAlign="center"
        style={{ whiteSpace: 'nowrap', color: '#121212' }}
      >
        {title} ({number ?? 0})
      </Typography>
    </Box>
  );
};

function levels(arr: string[]) {
  let newArr = Array.from(new Array(arr.length), () => 0);

  arr.forEach((x, i) => {
    let prev = arr[i - 1];

    if (prev === x) {
      newArr[i] = newArr[i - 1] + 1;
    }
  });

  return newArr;
}

function stacks(levels: number[], openStack: [number, number]) {
  let newArr = Array.from(new Array(levels.length), () => 1);

  levels.forEach((x, i) => {
    let level = x;

    if (openStack[0] <= i && i <= openStack[1]) {
      level = 1;
    } else if (level && levels[i + 1] !== 1) {
      newArr[i] = 0;
    } else if (levels[i + 1] === 1) {
      let x = 1;

      while (x < 100 && (levels[x + i] ?? 0) > levels[x + i - 1]) {
        x++;
      }

      newArr[i] = x;
    } else {
      newArr[i] = 1;
    }
  });

  return newArr;
}

const NotificationV03List: React.FC<NotificationV03ListProps> = ({}) => {
  const {
    restaurantId,
    filteredNotifications,
    notificationsV03Open,
    setnotificationsV03Open,
    notificationCategories,
  } = useRestaurant();

  const [filter, setfilter] = React.useState<null | string[]>(null);

  const filteredFilteredNotifications = React.useMemo(() => {
    if (!filter || !filter.length) return filteredNotifications;
    return filteredNotifications.filter((notification) =>
      filter.includes(notification.icon ?? 'other')
    );
  }, [filteredNotifications, filter]);

  const allCategories = Object.keys(notificationCategories);

  const [openStack, setOpenStack] = useState<[number, number]>([-1, -1]);

  const levelArr = React.useMemo(
    () =>
      stacks(
        levels(filteredFilteredNotifications.map((x) => x.icon ?? 'other')),
        openStack
      ),
    [filteredFilteredNotifications, openStack]
  );

  console.log({
    levelArr,
  });

  if (!notificationsV03Open) return <></>;

  return (
    <Box
      className="notificationV03List"
      style={{
        minWidth: 360,
        maxWidth: 360,
        position: 'sticky',
        top: 0,
        height: '100vh',
        cursor: 'pointer',
        // right: 0,
        // bottom: 0,
        backgroundColor: 'var(--color-grey-5)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        background
        style={{
          backgroundColor: 'var(--color-grey-2)',
          borderBottomLeftRadius: 20,
          borderBottomRightRadius: 20,
          padding: 20,
          paddingBottom: 20,
          position: 'sticky',
          top: 0,
          zIndex: 20,
          color: '#000',
        }}
      >
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 10,
            color: '#000',
          }}
        >
          <Typography color="inherit" variant="h4">
            Benachrichtigungen
          </Typography>
          <IconButton
            size="small"
            onClick={() => setnotificationsV03Open(false)}
          >
            <Close htmlColor="#000" />
          </IconButton>
        </Box>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            overflowX: 'scroll',
            gap: 10,
          }}
        >
          {Object.entries(notificationCategories).map(([type, active]) => (
            <Type
              type={type}
              key={type}
              active={filter?.includes(type)}
              onClick={() =>
                setfilter((x) =>
                  x?.includes(type)
                    ? x.filter((y) => y !== type)
                    : [...(x ?? []), type]
                )
              }
              number={active.length}
            />
          ))}
          {allCategories?.length !== filter?.length && (
            <Type
              type="all"
              active={!filter?.length}
              onClick={() => setfilter(null)}
              number={filteredNotifications.length}
            />
          )}
        </Box>
      </Box>
      <Box
        style={{
          flexGrow: 10,
          overflow: 'scroll',
          padding: 10,
          paddingRight: 10,
          transform: 'translateY(-10px)',
        }}
      >
        <div style={{ height: 10 }} />
        {filteredFilteredNotifications.map((notification, i) => (
          <NotificationV03
            key={notification.id}
            notification={notification}
            level={levelArr[i]}
            open={openStack[0] === i}
            inStack={
              openStack[0] > -1 ? openStack[0] <= i && i <= openStack[1] : null
            }
            closeStack={() => setOpenStack([-1, -1])}
            openStack={() =>
              levelArr[i] > 1
                ? setOpenStack([i, i + levelArr[i] - 1])
                : setOpenStack([-1, -1])
            }
          />
        ))}
      </Box>
    </Box>
  );
};

export default NotificationV03List;
