import { CheckCircle } from '@material-ui/icons';
import Box from 'Components/Atoms/Box';
import Button from 'Components/Atoms/Button';
import Checkbox from 'Components/Atoms/Checkbox';
import SwitchBox from 'Components/Atoms/Switch';
import TextField from 'Components/Atoms/TextField';

import Typography from 'Components/Atoms/Typography';
import DateDropdown from 'Components/Organisms/DateDropdown';
import { ReservationContext } from 'Contexts/ReservationContext';
import useAvailableTimes from 'CustomHooks/useGetAvailableTimes';
import usePinAuth from 'CustomHooks/usePinAuth';
import useRestaurant from 'CustomHooks/useRestaurant';
import React, { useContext, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { EditReservation, TimeSlot } from 'types/reservations';
import { noop } from 'utils/helper';
import GuestsDropdown from '../../GuestsDropdown';
import ReservationLength from '../../ReservationLength';
import TableDropdown from '../../TableDropdown';
import TimeDropdown from '../../TimeDropdown';
import OccassionDropdown from './OccassionDropdown';
import TableStrDropdown from './TableStrDropdown';

type FirstStepProps = {
  reservation: EditReservation;
  onReservationChange: React.Dispatch<
    React.SetStateAction<EditReservation | null>
  >;
  isNew?: boolean;
  settings?: {
    hideResLength: boolean;
    hideOccassion: boolean;
  };
  occassions: { id: string; title: string }[];
  restaurantId: string;
  uid: string;
  lightReservation?: boolean;
  currentTable?: string;
  setCurrentTable?: React.Dispatch<React.SetStateAction<string>>;
};

type reservationFields =
  | 'date'
  | 'occassion'
  | 'guests'
  | 'reservationLength'
  | 'timeSlot'
  | 'tables'
  | 'tableStr'
  | 'sendConfirmationEmail';

type newValue =
  | string
  | string[]
  | number
  | undefined
  | (TimeSlot & { protectedTime?: boolean })
  | boolean;

// @TODO ERRORS LOADING AND MESSAGE

// @TODO Time Warnings

const FirstStep = ({
  reservation,
  onReservationChange = noop,
  isNew = false,
  settings,
  restaurantId,
  occassions,
  uid,
  lightReservation = false,
  currentTable,
  setCurrentTable,
}: FirstStepProps) => {
  const { availableTimes, reservationLength, message, waitinglist } =
    useAvailableTimes(restaurantId, reservation, onReservationChange, uid);

  const { authenticate } = usePinAuth();

  const {
    tableStrs: tableStrOptions,
    setStorageTableStrs,
    setShowTimesInEdit,
    showTimesInEditMode,
    hasWaitingList,
  } = useContext(ReservationContext);

  const [addMode, setAddMode] = useState<boolean>(false);

  const handleChange = (
    newValue: newValue,
    type: reservationFields | 'hostComment'
  ) => {
    const newReservation: EditReservation = {
      ...reservation,
      changedTablePerHandBefore: false,
    };

    // console.log({
    //   newReservation,
    // });

    if (type === 'hostComment') {
      if (newReservation.guest) {
        newReservation.guest.hostComment = newValue as string;
      } else {
        newReservation.guest = {
          name: 'Walkin ' + (newReservation?.tables?.join(', ') ?? ''),
          hostComment: newValue as string,
          phone: '',
          email: '',
        };
      }

      return onReservationChange(newReservation);
    } else {
      newReservation[type] = newValue as Exclude<
        keyof EditReservation,
        newValue
      >;
    }

    console.log('old resa', reservation);
    console.log('new resa', newReservation);

    if (
      type === 'timeSlot' &&
      typeof newValue === 'object' &&
      !Array.isArray(newValue) &&
      !newReservation?.tables?.length
    ) {
      newReservation.tables = newValue?.combinations?.[0]?.tables || [];
      newReservation.space = newValue?.combinations?.[0]?.space;
    }

    if (
      (newValue as any)?.protectedTime &&
      (newValue as any)?.waitinglist &&
      !newReservation.addToWaitinglist
    ) {
      newReservation.addToWaitinglist = { time: (newValue as any)?.time ?? '' };
    }

    if (type === 'timeSlot' && newReservation.addToWaitinglist) {
      if (!(newValue as TimeSlot).waitinglist) {
        newReservation.addToWaitinglist = null;
      } else {
        newReservation.addToWaitinglist.time = (newValue as TimeSlot).time;
      }
    }

    if (type === 'tables' && Array.isArray(newValue)) {
      newReservation.space = reservation?.timeSlot?.combinations.find(
        (c) =>
          JSON.stringify(c.tables.sort()) === JSON.stringify(newValue.sort())
      )?.space;

      newReservation.tableStr = newValue.toString();
    }

    if (type === 'reservationLength' && newReservation.timeSlot) {
      console.log('here!');
      if (newReservation.walkin)
        newReservation.reservationLength = newValue as number;
      newReservation.timeSlot.reservationLength = newValue as number;
    }
    console.log('very new', newReservation);

    if (type === 'tableStr') {
      newReservation.tableStr = newValue?.toString();
      if (setCurrentTable) setCurrentTable(newValue?.toString() ?? '');
      // closeAddMode()
    }

    if (type === 'sendConfirmationEmail') {
      newReservation.sendConfirmationEmail = !!newValue;

      if (newReservation.guest) {
        newReservation.guest.sendEmail = !!newValue;
      }
    }
    console.log({ newReservation });
    onReservationChange(newReservation);
  };

  const openAddMode = () => setAddMode(true);
  const closeAddMode = () => setAddMode(false);

  console.log('tableStrs', { tableStrOptions });

  return (
    <Box className="new-reservation-sidebar ">
      <Box flex className="flex-column" style={{ textAlign: 'center' }}>
        {(!isNew || (isNew && !reservation.walkin)) && (
          <>
            <DateDropdown
              value={reservation.date}
              onChange={(newDate) => handleChange(newDate, 'date')}
            />
            {!reservation.waitinglistEntry && (
              <Typography textAlign="center" variant="text-4" color="subdued">
                Tab to open
              </Typography>
            )}
          </>
        )}
      </Box>

      {!!reservation.waitinglistEntry && (
        <>
          <Typography
            textAlign="center"
            variant="text-4"
            block
            translation="reservations"
          >
            Make Sure to contact the Guest. Currently we do not contact them
            automatically
          </Typography>
          <Typography
            textAlign="center"
            variant="text-3"
            color="secondary"
            block
            href={`tel:${reservation.waitinglistEntry.guest.phone}`}
          >
            {reservation.waitinglistEntry.guest.phone}
          </Typography>
        </>
      )}

      <Box className="first-step-field">
        <OccassionDropdown
          isHidden={settings?.hideOccassion || occassions.length === 1}
          occassions={occassions}
          value={reservation.occassion}
          onChange={(newValue) => handleChange(newValue, 'occassion')}
        />
      </Box>

      <Box className="first-step-field">
        <Typography variant="text-3" translation="reservations">
          <Trans i18nKey="reservations_Number of Guests">
            Number of Guests
          </Trans>
          <br />{' '}
          {!!reservation.waitinglistEntry && (
            <small>
              <Trans i18nKey="reservations_Requested">Angefragt</Trans>:{' '}
              {reservation.waitinglistEntry.guests}
            </small>
          )}
        </Typography>
        <GuestsDropdown
          value={reservation.guests}
          onChange={(newValue) => handleChange(newValue, 'guests')}
        />
      </Box>

      <Box className="first-step-field">
        <TimeDropdown
          value={reservation.timeSlot}
          waitinglistEntry={reservation.waitinglistEntry}
          onChange={(newValue) => handleChange(newValue, 'timeSlot')}
          timeSlots={availableTimes}
          lightReservation={lightReservation}
          timeSlotError={message}
          date={reservation.date}
          waitinglist={waitinglist}
        />
      </Box>

      <Box className="first-step-field">
        <Typography variant="text-3" translation="reservations">
          Reservation Length
        </Typography>
        <ReservationLength
          startBlock={reservation.timeSlot?.block}
          value={reservation.reservationLength ?? reservationLength}
          onChange={(newValue) => handleChange(newValue, 'reservationLength')}
          // hidden={true /*settings?.hideResLength || false */}
        />
      </Box>

      {isNew && hasWaitingList && (
        <SwitchBox
          checked={!!reservation.addToWaitinglist}
          label="Place on Waitinglist"
          translation="reservations"
          onChange={(_, checked) =>
            authenticate(
              checked ? 'none' : 'reservations.blockedTime.write',
              () => {
                onReservationChange((old) =>
                  !!old
                    ? {
                        ...old,
                        addToWaitinglist: checked
                          ? { time: reservation?.timeSlot?.time || '' }
                          : null,
                        tables: [],
                      }
                    : null
                );
              }
            )
          }
        />
      )}

      {!lightReservation && (
        <Box className="first-step-field">
          <Typography
            variant="text-3"
            translation="reservations"
            style={{ opacity: !reservation.addToWaitinglist ? 1 : 0.6 }}
          >
            Tables
          </Typography>
          <TableDropdown
            value={reservation.tables}
            disabled={!!reservation.addToWaitinglist}
            combinations={reservation?.timeSlot?.combinations || null}
            onChange={(newValue) => handleChange(newValue, 'tables')}
          />
        </Box>
      )}
      {lightReservation && (
        <Box className="second-step-field">
          {addMode ? (
            <TextField
              value={reservation.tableStr || currentTable}
              onChange={(e) => {
                if (setCurrentTable) setCurrentTable(e.target.value);
                handleChange(e.target.value, 'tableStr');
              }}
              fullWidth
              label="Tables"
              labelTranslation="reservations"
            />
          ) : (
            <>
              <Typography variant="text-3" translation="reservations">
                Tables
              </Typography>
              <TableStrDropdown
                value={reservation.tableStr || ''}
                options={tableStrOptions}
                onChange={(val) => handleChange(val, 'tableStr')}
                openAddMode={openAddMode}
              />
            </>
          )}
        </Box>
      )}

      {reservation.walkin && (
        <TextField
          value={reservation.guest?.hostComment || ''}
          onChange={(e) => handleChange(e.target.value, 'hostComment')}
          fullWidth
          style={{ marginTop: 10 }}
          label="Comment (Host)"
          labelTranslation="reservations"
        />
      )}

      {!reservation.new && reservation.guest?.email && (
        <SwitchBox
          checked={!!reservation.sendConfirmationEmail}
          onChange={(_, sendConfirmationEmail) =>
            handleChange(sendConfirmationEmail, 'sendConfirmationEmail')
          }
          label="Bestätigungsemail senden"
          translation="reservations"
        />
      )}
      <SwitchBox
        checked={showTimesInEditMode}
        label="Zeiten an Tischen anzeigen"
        color="primary"
        onChange={(_, checked) => setShowTimesInEdit(checked)}
      />
    </Box>
  );
};

export default FirstStep;
