import React, { useMemo } from 'react';
import './styles.scss';
import Box from 'Components/Atoms/Box';
import Typography from 'Components/Atoms/Typography';
import { Date as DateType, ReservationShift, ShiftType } from 'types/shifts';
import IconButton from 'Components/Atoms/IconButton';
import { Lock, LockOpenOutlined } from 'Components/Atoms/Icons';
import { classHelper } from 'utils/helper';

interface MobileShiftCardProps {
  dateData: DateType;
  onChange: (nV: DateType) => void;
  shift: ReservationShift;
}

const MobileShiftCard = ({
  onChange,
  dateData,
  shift,
}: MobileShiftCardProps) => {
  console.log('datedata', { dateData });

  const handleChange = (
    type: 'newMaxGuest' | 'open' | 'close',
    shiftId: string,
    value?: number
  ) => {
    const newShifts = dateData.shifts.map((s) => {
      if (s.id === shiftId && s.type === ShiftType.RESERVATION) {
        if (type === 'newMaxGuest') {
          return !!value
            ? { ...s, overwrite: { ...s.overwrite, maxGuests: value } }
            : s;
        } else {
          return {
            ...s,
            overwrite: { ...s.overwrite, blockReservations: type === 'close' },
          };
        }
      } else return s;
    });
    onChange({ ...dateData, shifts: newShifts });
  };

  const shiftReservations = dateData.shiftStatistics?.[shift.id] ?? {};
  const guestNb = Object.keys(shiftReservations).reduce(
    (acc, cV) => acc + shiftReservations[cV],
    0
  );
  const groupsNb = Object.keys(shiftReservations).length;

  // console.log("SHIFT*", shift);

  return (
    <Box className="mobile-shift-card-container">
      <Box className="mobile-shift-card-header">
        <Typography variant="text-2" translation="settings" weight="bold">
          Shift Name
        </Typography>
        <Typography
          variant="text-2"
          translation="settings"
          weight="bold"
          color="subdued"
        >
          {shift.name}
        </Typography>
      </Box>
      <Box className="mobile-shift-card-main">
        <Box className="mobile-shift-card-main-left">
          <Typography variant="text-2" translation="settings" weight="bold">
            Current guests
          </Typography>
          {shift.closed ? (
            <Typography variant="text-3" color="subdued">
              - / -
            </Typography>
          ) : (
            <Typography
              variant="text-3"
              color="subdued"
              translationKey="reservations_guestGroups"
            >
              {{ guestNb }} Guests / {{ groupsNb }} Groups
            </Typography>
          )}
        </Box>
        <Box className="mobile-shift-card-main-right">
          <Typography variant="text-2" translation="settings" weight="bold">
            Max guests
          </Typography>
          <Box className="mobile-shift-card-main-input">
            <input
              name="maxGuest"
              value={
                shift?.overwrite?.maxGuests ||
                shift?.additional?.maxGuests ||
                ''
              }
              disabled={shift.closed}
              onChange={(e) =>
                handleChange('newMaxGuest', shift.id, +e.target.value)
              }
            />
          </Box>
        </Box>
      </Box>

      {!shift.closed && (
        <Box
          className={classHelper([
            'mobile-shift-card-footer',
            !shift.overwrite?.blockReservations ? 'is-closed' : 'is-open',
          ])}
        >
          <IconButton
            // color={shift.overwrite?.blockReservations ? "error" : "primary"}
            size="small"
            style={{ margin: '5px 0px' }}
            disabled={shift.closed}
            onClick={() =>
              handleChange(
                shift.overwrite?.blockReservations ? 'open' : 'close',
                shift.id
              )
            }
          >
            {!shift.overwrite?.blockReservations ? (
              <Lock />
            ) : (
              <LockOpenOutlined />
            )}
            {!shift.overwrite?.blockReservations ? (
              <Typography
                variant="h5"
                translation="dashboard"
                style={{ marginLeft: 8 }}
              >
                Close Shift
              </Typography>
            ) : (
              <Typography
                variant="h5"
                translation="dashboard"
                color={shift.closed ? 'inherit' : 'textPrimary'}
                style={{ marginLeft: 8 }}
              >
                Open Shift
              </Typography>
            )}
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default MobileShiftCard;
