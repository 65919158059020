import { ButtonBase } from '@material-ui/core';
import React from 'react';
import Tooltip from 'Components/Atoms/Tooltip';
import { PathType } from '../..';
import Badge from 'Components/Atoms/Badge';
import { StylesProvider } from '@material-ui/core/styles';

const Path = ({
  path,
  active,
  onClick,
}: {
  path: PathType;
  active: boolean;
  onClick: (fn: (rId: string) => string) => void;
}) => {
  return (
    <Tooltip content={path.name}>
      <Badge number={path?.notifications || 0}>
        <ButtonBase
          onClick={path.linkTo ? () => onClick(path.linkTo) : path.onClick}
          className={`path ${active ? 'active' : ''}`}
        >
          {path.icon}
        </ButtonBase>
      </Badge>
    </Tooltip>
  );
};

export default Path;
