import React, { useContext, useEffect, useState } from 'react';
import './styles.scss';
import { Reservation, Guest } from 'types/reservations';
import TabCard from 'Components/Molecules/TabCard';
import ReservationDetails from './Components/ReservationDetails';
import { asyncVoid, classHelper, noop } from 'utils/helper';
import ReservationButtons from './Components/ReservationButtons';
import ReservationContact from './Components/ReservationContact';
import ReservationHistory from './Components/ReservationHistory';
import IconButton from 'Components/Atoms/IconButton';
import { Close } from 'Components/Atoms/Icons';
import {
  ReservationActionTypes,
  ReservationContext,
} from 'Contexts/ReservationContext';
import PermPhoneMsgRounded from '@material-ui/icons/PermPhoneMsgRounded';
import ReservationInsights from '../ReservationInsights';
import { triggerCustomEvent } from 'CustomHooks/useCustomEvent';
import useAuth from 'CustomHooks/useAuth';
import PinReservation from 'Components/Atoms/Icons/PinReservation';

export type Change = {
  after: any;
  before: any;
  key: string; // 'tables'|'createdAt'|'excludeFromSlots'|'time'|'guests'
  title: string;
};

export type History = {
  changes: Change[];
  updateNote?: null | {
    note: string;
  };
  updatedBy: {
    name: string;
    uid: string;
  };
  id: string | number;
};

export type ReservationSideBarProps = {
  reservation: Reservation | null;
  occassions?: { title: string; id: string }[];
  onReservationAction: (
    id: string,
    type: ReservationActionTypes,
    payload?: any
  ) => void;
  guest: null | Guest;
  loadGuest: () => Promise<void>; // Trigger this when tab changes to contact
  onGuestAction: (id: string, type: string, payload?: any) => void;
  loadHistory: VoidFunction; // Trigger this when tab changes to history
  history: null | History[]; // loading or not loaded jet when null
  onClose: () => void;
  sidebarFontSize?: string;
};

/*
    onReservationAction: 
    - type: 'attr', payload: string[]
    X type: 'excludeFromSlots', payload: boolean
    X type: 'relocate'
    X type: 'cancel'
    X type: 'markAsDone'
    X type: 'unseat'
    X type: 'reseat',
    X type: 'hostComment', payload: string

    onGuestAction:
    - type: 'name', payload: string
    - type: 'email', payload: string
    - type: 'anniversary', payload: string
    - type: 'birthday', payload: string
    - type: 'company', payload: string

*/

const tabs = [
  {
    id: 0,
    label: 'Details',
    translation: 'reservations',
  },
  {
    id: 1,
    label: 'Contact',
    translation: 'reservations',
  },
  {
    id: 2,
    label: 'History',
    translation: 'reservations',
  },
];

const ReservationSideBar = ({
  reservation,
  occassions = [],
  onReservationAction = noop,
  guest,
  loadGuest = asyncVoid,
  onGuestAction = noop,
  loadHistory = noop,
  history,
  onClose = noop,
  sidebarFontSize,
}: ReservationSideBarProps) => {
  const [tab, setTab] = useState<string | null | number>(0);

  const [hasOverflow, setHasOverflow] = useState<{
    bottom: boolean;
    top: boolean;
  }>({ top: false, bottom: false });

  useEffect(() => {
    if (tab === 0 && !guest) {
      loadGuest();
    } else if (tab === 1 && !guest) {
      loadGuest();
    } else if (tab === 2 && !history) {
      loadHistory();
    }
  }, [tab, reservation]);

  useEffect(() => {
    setTab(0);
  }, [reservation?.id]);

  useEffect(() => {});

  const { isGastronautAdmin } = useAuth();

  const { Shuffle } = useContext(ReservationContext);

  const handleScrolling = (
    event: React.UIEvent<HTMLDivElement | HTMLLabelElement | HTMLFormElement>
  ) => {
    if (
      event.currentTarget.scrollHeight - event.currentTarget.scrollTop <=
      event.currentTarget.clientHeight + 1
    ) {
      setHasOverflow({ top: true, bottom: false });
    } else if (event.currentTarget.scrollTop === 0) {
      setHasOverflow({ top: false, bottom: true });
    } else if (
      event.currentTarget.scrollHeight - event.currentTarget.scrollTop >
        event.currentTarget.clientHeight &&
      event.currentTarget.scrollTop > 0
    ) {
      setHasOverflow({ top: true, bottom: true });
    }
  };

  if (!reservation) {
    return <></>;
  }

  return (
    <>
      <TabCard
        tabs={tabs}
        id="reservation-side-bar-tabs"
        value={tab}
        onChange={setTab}
        titleCarousel={reservation?.guest?.name?.length > 20}
        title={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: isGastronautAdmin ? 'pointer' : '',
            }}
            onClick={() => triggerCustomEvent('insightOpened')}
          >
            {reservation?.guest?.name || 'Reservation'}
            {reservation.source === 'phone' && (
              <PermPhoneMsgRounded
                fontSize="inherit"
                style={{ marginLeft: 4 }}
              />
            )}
          </div>
        }
        titleProps={{
          style: {
            maxWidth: 180,
          },
        }}
        bodyProps={{
          style: {
            padding: '0px !important',
            position: 'relative',
            height: 'calc(100% - 113px)',
          },
          className: 'space-between flex-column',
        }}
        style={{
          height: '100%',
          zIndex: 100,
          fontSize: sidebarFontSize ? `${sidebarFontSize}px` : undefined,
        }}
        className={classHelper(['sidebar', sidebarFontSize && 'customSidebar'])}
        headerRight={
          <>
            <IconButton
              size="small"
              color={reservation.fixed ? 'secondary' : 'subdued'}
              onClick={() =>
                onReservationAction(
                  reservation.id,
                  ReservationActionTypes.TOGGLE_PIN
                )
              }
              style={{ marginRight: 8 }}
            >
              <PinReservation />
            </IconButton>
            <IconButton size="small" color="subdued" onClick={onClose}>
              <Close />
            </IconButton>
          </>
        }
      >
        {hasOverflow.top && <div className="topOverflowGradient"></div>}
        {tab === 0 && (
          <ReservationDetails
            {...{
              reservation,
              occassions,
              onReservationAction,
              setHasOverflow,
              handleScrolling,
            }}
          />
        )}
        {tab === 1 && (
          <ReservationContact
            {...{
              guest,
              occassions,
              reservation,
              onGuestAction,
              setHasOverflow,
              handleScrolling,
            }}
          />
        )}
        {tab === 2 && (
          <ReservationHistory
            {...{
              history,
              setHasOverflow,
              handleScrolling,
              reservation,
              reservationId: reservation.id,
            }}
          />
        )}
        {tab !== 2 && hasOverflow.bottom && (
          <div
            className="bottomOverflowGradient"
            style={
              tab === 2 ||
              ['failed', 'done'].includes(reservation?.currentStatus || '')
                ? { height: 150 }
                : reservation.currentStatus === 'seated'
                ? { height: 350 }
                : {}
            }
          ></div>
        )}
        {tab !== 2 && !Shuffle.state && (
          <ReservationButtons
            status={reservation.currentStatus}
            onReservationAction={onReservationAction}
            id={reservation.id}
            tab={tab}
            guestId={reservation.guest.id}
            noShowFee={reservation.noShowFee}
            canReceiveEmail={!!reservation.guest.email && !reservation.ticketId}
            creditCardCustomerId={reservation.guest.creditCardCustomerId}
            fixed={reservation.fixed}
            guests={reservation.guests}
            customData={reservation.customData ?? {}}
          />
        )}
      </TabCard>
      <ReservationInsights id={reservation.id} />
    </>
  );
};

export default ReservationSideBar;
