import Box from "Components/Atoms/Box";
import Typography from "Components/Atoms/Typography";
import React from "react";

const TablesMatching: React.FC<{
  matchingTables: {
    tables: string[];
    combinations: string[];
    guestMap: Record<number, number>;
    hasTables: boolean;
    missingSizes: string[];
    largestGroup: number;
    largestGroupIsTooSmall: boolean;
  };
  closed?: boolean;
}> = ({ matchingTables, closed = false }) => {
  if (!matchingTables.hasTables) {
    return (
      <Box>
        <Typography variant="text-3" color="error">
          Keine passenden Tische gefunden
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      flex
      style={{
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "4px 6px",
        border: "thin solid var(--color-border)",
        borderRadius: 4,
      }}
    >
      <Typography variant="text-3">
        Anzahl passende Tische: {matchingTables.tables.length}
      </Typography>
      <Typography variant="text-3">
        Anzahl passende Kombinationen: {matchingTables.combinations.length}
      </Typography>
      {!closed && !!matchingTables.missingSizes.length && (
        <Typography variant="text-3" color="error">
          Kein Passender Tisch für: {matchingTables.missingSizes.join(", ")}{" "}
          Personen
        </Typography>
      )}
      {!closed && (
        <Typography variant="text-3">
          Größte mögliche Gruppe: {matchingTables.largestGroup} Personen
        </Typography>
      )}
      {!closed && matchingTables.largestGroupIsTooSmall && (
        <Typography variant="text-3" color="error">
          Größte mögliche Gruppe ist kleiner als die größte durch Gäste
          reservierbare Gruppen-Größe
        </Typography>
      )}
    </Box>
  );
};

export default TablesMatching;
