import Box from "Components/Atoms/Box";
import { ReservationContext } from "Contexts/ReservationContext";
import { Day as DayType } from "CustomHooks/useCalendar";
import React, { useContext, useEffect, useState } from "react";
import { noop } from "utils/helper";
import Day from "../Day";

export type WeekProps = {
  days: DayType[];
  shiftDetector?: (date: Date) => boolean;
  disabledDetector?: (date: Date) => boolean;
  currentDate?: string | null;
  onDateChange?: (newDate: string, endDate?: string) => void;
  width?: number | string;
  minDate?: string;
  endDate?: string;
  dateRange?: boolean;
};

const Week = ({
  days = [],
  shiftDetector = () => false,
  disabledDetector = () => false,
  currentDate,
  onDateChange,
  width = 232,
  minDate,
  endDate,
  dateRange = false,
}: WeekProps) => {
  const isActive = (date: string) => {
    if (dateRange && currentDate && endDate) {
      return date >= currentDate && date <= endDate;
    } else {
      return currentDate === date;
    }
  };

  // console.log("val", currentDate);

  const getClassName = (date: string) => {
    if (dateRange && currentDate && endDate) {
      if (date === currentDate) {
        return "first-day";
      } else if (date === endDate) {
        return "last-day";
      } else if (date > currentDate && date < endDate) {
        return "day-in-between";
      } else {
        return undefined;
      }
    }
    return undefined;
  };

  return (
    <Box flex className="space-between" style={{ width, padding: "4px 8px" }}>
      {days.map((d) => (
        <Day
          key={d.date}
          wDay={d.wDay}
          active={isActive(d.date)}
          date={d.date}
          disabled={
            d.disabled ||
            (minDate && d.date < minDate) ||
            disabledDetector(new Date(d.date))
          }
          shift={shiftDetector(new Date(d.date))}
          onClick={
            !(minDate && d.date < minDate) || disabledDetector(new Date(d.date))
              ? onDateChange
              : undefined
          }
          className={getClassName(d.date)}
        />
      ))}
    </Box>
  );
};

export default Week;
