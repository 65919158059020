import React from "react";
import {
  default as MaterialSelect,
  SelectProps,
} from "@material-ui/core/Select";
import InputBase from "Components/Atoms/InputBase";
import "./styles.scss";
import MenuItem from "@material-ui/core/MenuItem";
import { Translations } from "utils/types";
import Typography from "Components/Atoms/Typography";
import { FormControl, InputLabel } from "@material-ui/core";

export type Option =
  | string
  | number
  | null
  | {
      id: string | number | null;
      label?: string;
      translation?: null | Translations;
      disabled?: boolean;
      hide?: boolean;
    };

const Select = ({
  options = [],
  menuClassName = "",
  children,
  label,
  margins = 8,
  translation = null,
  ...props
}: SelectProps & {
  options: Option[];
  menuClassName?: string;
  translation?: null | Translations;
} & {
  label?: string | null;
  margins?: string | number;
}) => {
  return (
    <>
      {label ? (
        <FormControl
          variant="filled"
          className="selectWithLabel"
          fullWidth
          style={{ margin: margins }}
        >
          <InputLabel style={{ fontSize: "15px" }}>{label}</InputLabel>
          <MaterialSelect
            {...props}
            input={<InputBase className="selectInput" />}
            MenuProps={{ className: `select-menu ${menuClassName}` }}
            onChange={props.onChange}
            // onClick={e => console.log(e)}
          >
            {options.map((option) => {
              if (typeof option === "object" && option !== null) {
                return (
                  <MenuItem
                    className="select-MenuItem"
                    key={option.id}
                    value={option.id ?? ""}
                    disabled={option.disabled || false}
                  >
                    <Typography
                      variant="text-3"
                      translation={option?.translation || translation}
                    >
                      {option?.label || option.id}
                    </Typography>
                  </MenuItem>
                );
              } else {
                return (
                  <MenuItem
                    className="select-MenuItem"
                    key={option}
                    value={option ?? ""}
                  >
                    <Typography variant="text-3" translation={translation}>
                      {option}
                    </Typography>
                  </MenuItem>
                );
              }
            })}
          </MaterialSelect>
        </FormControl>
      ) : (
        <MaterialSelect
          {...props}
          input={<InputBase className="selectInput" />}
          MenuProps={{ className: `select-menu ${menuClassName}` }}
        >
          {options.map((option) => {
            if (typeof option === "object" && option !== null) {
              return (
                <MenuItem
                  className="select-MenuItem menuItem"
                  key={option.id}
                  value={option.id ?? ""}
                  disabled={option.disabled || false}
                >
                  <Typography
                    variant="text-3"
                    translation={option?.translation || null}
                  >
                    {option?.label || option.id}
                  </Typography>
                </MenuItem>
              );
            } else {
              return (
                <MenuItem
                  className="select-MenuItem menuItem"
                  key={option}
                  value={option ?? ""}
                >
                  <Typography variant="text-3" translation={null}>
                    {option}
                  </Typography>
                </MenuItem>
              );
            }
          })}
        </MaterialSelect>
      )}
    </>
  );
};

export default Select;
