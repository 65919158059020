import React, { useEffect, useMemo, useState } from "react";
import Box from "Components/Atoms/Box";
import "./styles.scss";
import { TimeSlot } from "types/reservations";
import Typography from "Components/Atoms/Typography";
import { noop } from "utils/helper";
import { ArrowDropDown, ReportProblemRounded } from "Components/Atoms/Icons";
import Popover from "Components/Atoms/Popover";
import TimeSlots from "./Components/TimeSlots";
import { WaitingListEntryDocument } from "gastronaut-shared/types/documents";
import { Trans } from "react-i18next";
import usePinAuth from "CustomHooks/usePinAuth";

export type TimeDropdownProps = {
  date?: string;
  value: null | TimeSlot;
  timeSlots: null | TimeSlot[];
  onChange: (nV: TimeSlot) => void;
  timeSlotError?: string | null;
  lightReservation?: boolean;
  style?: React.CSSProperties;
  waitinglistEntry?: WaitingListEntryDocument;
  waitinglist?: null | {
    offerWaitinglist: boolean;
    slots?: string[];
  }; // only when it's a new Reservation
  type?: "waitinglist" | "reservation";
};

const TimeDropdown = ({
  value,
  timeSlots,
  onChange = noop,
  timeSlotError,
  lightReservation = false,
  date,
  style,
  waitinglistEntry,
  waitinglist,
  type = "reservation",
}: TimeDropdownProps) => {
  const [anchorEl, setanchorEl] = useState<null | HTMLElement>(null);

  const valueNotInSlots =
    value && !timeSlots?.find((x) => x.block === value.block);

  const maxGuestsReached = !!timeSlots?.find((t) => t.block === value?.block)
    ?.maxGuestsReached;

  const hasError =
    valueNotInSlots ||
    (value && !value.combinations.length && !lightReservation) ||
    maxGuestsReached;

  useEffect(() => {
    if (timeSlots?.length && !value && waitinglistEntry) {
      let timeSlot =
        timeSlots.find((x) => x.time === waitinglistEntry.time) ||
        timeSlots.find(
          (x) =>
            waitinglistEntry.flexibleTime &&
            x.time >= waitinglistEntry.flexibleTime.earliest &&
            x.time <= waitinglistEntry.flexibleTime.latest
        );

      if (timeSlot) onChange(timeSlot);
    }
  }, [timeSlots]);

  return (
    <Box>
      <Typography
        block
        variant="text-3"
        translation="reservations"
        style={{ marginBottom: 8 }}
      >
        <Trans i18nKey="reservations_Time">Time</Trans>
        <br />{" "}
        {!!waitinglistEntry && (
          <small>
            <Trans i18nKey="reservations_Requested">Angefragt</Trans>:{" "}
            {waitinglistEntry.time}
            {!!waitinglistEntry.flexibleTime
              ? ` (${waitinglistEntry.flexibleTime.earliest} - ${waitinglistEntry.flexibleTime.latest})`
              : ""}
          </small>
        )}
      </Typography>
      <Box
        outline
        background
        className="clickable"
        flex
        padding="sm"
        onClick={(e) => setanchorEl((x) => (!x ? e.currentTarget : null))}
        style={style}
      >
        <Box flex className="space-between">
          <Typography variant="text-3" color="subdued" translation={null}>
            {value && `${value.time}`}
          </Typography>
          <Typography variant="text-3" color="subdued" translation={null}>
            {!!value?.waitinglist && (
              <span
                style={{
                  display: "inline-block",
                  height: 10,
                  width: 10,
                  borderRadius: 5,
                  background: "var(--color-secondary)",
                  marginRight: 10,
                  marginTop: 2,
                }}
              />
            )}
            {value && `${value.blockedSlots} / ${value.totalSlots}`}
          </Typography>
        </Box>
        {hasError && (
          <ReportProblemRounded className="color-error" style={{ margin: 4 }} />
        )}
        <ArrowDropDown style={{ fill: "var(--color-text-subdued)" }} />
      </Box>
      {!lightReservation &&
        value &&
        !value.combinations.length &&
        !valueNotInSlots &&
        !value?.waitinglist && (
          <Typography
            variant="text-4"
            className="color-error"
            style={{ marginTop: 4 }}
            translation="reservations"
          >
            No Tables available
          </Typography>
        )}
      {!value?.waitinglist && !!maxGuestsReached && (
        <Typography
          variant="text-4"
          className="color-error"
          style={{ marginTop: 4 }}
          translation="reservations"
        >
          Max Guests reached
        </Typography>
      )}
      {!value?.waitinglist && valueNotInSlots && (
        <Typography
          variant="text-4"
          className="color-error"
          style={{ marginTop: 4 }}
          translation="reservations"
        >
          Time is not available.
        </Typography>
      )}
      {!!value?.waitinglist && type === "reservation" && (
        <Typography
          variant="text-4"
          style={{ marginTop: 4 }}
          translation="reservations"
        >
          <span
            style={{
              display: "inline-block",
              height: 10,
              width: 10,
              borderRadius: 5,
              background: "var(--color-secondary)",
              marginRight: 10,
              marginTop: 2,
            }}
          />
          <Trans i18nKey="reservations_Spot on Waitinglist available">
            Spot on Waitinglist available
          </Trans>
        </Typography>
      )}
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setanchorEl(null)}
        placement="bottom"
        padding={0}
        style={{ maxHeight: 200, overflow: "scroll", display: "block" }}
      >
        <TimeSlots
          timeSlotError={timeSlotError}
          lightReservation={lightReservation}
          waitinglist={waitinglist}
          value={value}
          date={date}
          timeSlots={timeSlots}
          onChange={(t) => {
            onChange(t);
            setanchorEl(null);
          }}
        />
      </Popover>
    </Box>
  );
};

export default TimeDropdown;
