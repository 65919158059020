import { Box } from "@material-ui/core";
import { capitalizeString } from "App/ReservationBook/Components/ShiftMessage";
import Typography from "Components/Atoms/Typography";
import React, { useEffect, useState } from "react";
import {
  PieChart,
  BarChart,
  Bar,
  Pie,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
  Cell,
  PieLabel,
} from "recharts";
import { Occassion } from "types/reservations";
import { db } from "utils/firebase";

interface GraphsProps {
  restaurantId: string;
  reservationStatistics: any;
}

const COLORS = ["#00C49F", "#FFBB28", "#8884d8", "#ee4266"];
const COLORS_2 = ["#10ccb9", "#ee4266"];

const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Th", "Fri", "Sat"];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const Graphs = ({ restaurantId, reservationStatistics }: GraphsProps) => {
  const [occassions, setOccassions] = useState<Occassion[]>();

  function formatGuestsData() {
    if (!reservationStatistics) {
      return [];
    } else {
      const keys = Object.keys(reservationStatistics?.guests);
      const data = keys.map((key) => ({
        numberOfGuests: key,
        "Number of reservations": reservationStatistics?.guests[key],
      }));

      return data;
    }
  }

  function formatOccassionsData() {
    if (!reservationStatistics) {
      return [];
    } else {
      const keys = Object.keys(reservationStatistics?.occassion);
      const data = keys.map((key) => ({
        name:
          occassions?.find((i: any) => i.id === key)?.title ??
          capitalizeString(key),
        numberOfReservations: reservationStatistics?.occassion[key],
      }));
      return data;
    }
  }

  function formatStatusData() {
    if (!reservationStatistics) {
      return [];
    } else {
      const keys = Object.keys(reservationStatistics?.status);
      const data = keys.map((key) => ({
        name: capitalizeString(key),
        value: reservationStatistics?.status[key],
        displayName: key.substr(0, 1).toUpperCase() + key.substr(1),
      }));
      return data;
    }
  }

  function formatWDayDataData() {
    if (!reservationStatistics) {
      return [];
    } else {
      const keys = Object.keys(reservationStatistics?.wDay);
      const data = keys.map((key) => ({
        name: daysOfWeek[key as unknown as number],
        "Number of reservations": reservationStatistics?.wDay[key],
      }));

      return data;
    }
  }

  function statusGraphColorHandler(name: string) {
    if (name === "Success") {
      return "#10ccb9";
    } else return "#ee4266";
  }

  async function getResData() {
    try {
      const res = await db
        .collection(`/restaurants/${restaurantId}/settings`)
        .doc("reservationsV02")
        .get();
      setOccassions(res.data()?.occassions);
      return res;
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    getResData();
  }, []);

  return (
    <>
      <Box className="graphs-container">
        <Box className="reservation-flow-graph-container">
          <Typography
            variant="text-2"
            color="textPrimary"
            translation="settings"
          >
            Reservations / Number of guests
          </Typography>
          <ResponsiveContainer width="100%" height={260}>
            <BarChart
              width={400}
              height={200}
              data={formatGuestsData()}
              margin={{ top: 15, right: 20, bottom: 5, left: -10 }}
            >
              <Bar dataKey="Number of reservations" fill="#545ad0" />
              <XAxis dataKey="numberOfGuests" />
              <YAxis dataKey="Number of reservations" />
              <Tooltip />
            </BarChart>
          </ResponsiveContainer>
        </Box>
        <Box className="reservation-types-graph-container">
          <Typography
            variant="text-2"
            color="textPrimary"
            translation="settings"
          >
            Reservations types
          </Typography>
          <ResponsiveContainer width={250} height={250}>
            <PieChart width={250} height={250}>
              <Pie
                dataKey="numberOfReservations"
                data={formatOccassionsData()}
                // fill="#8884d8"
              >
                {formatOccassionsData().map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </Box>
      </Box>
      <Box className="graphs-container">
        <Box className="reservation-types-graph-container">
          <Typography
            variant="text-2"
            color="textPrimary"
            translation="settings"
          >
            Reservations status
          </Typography>
          <ResponsiveContainer width={250} height={250}>
            <PieChart width={250} height={250}>
              <Pie
                dataKey="value"
                data={formatStatusData()}
                fill="#8884d8"
                label={renderCustomizedLabel}
                cx="50%"
                cy="50%"
                isAnimationActive={false}
                labelLine={false}
              >
                {formatStatusData().map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={statusGraphColorHandler(entry.name)}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </Box>
        <Box className="reservation-flow-graph-container">
          <Typography
            variant="text-2"
            color="textPrimary"
            translation="settings"
          >
            # Seatings / Working Day
          </Typography>
          <ResponsiveContainer width="100%" height={260}>
            <BarChart
              width={400}
              height={200}
              data={formatWDayDataData()}
              margin={{ top: 15, right: 20, bottom: 5, left: -10 }}
            >
              <Bar dataKey="Number of reservations" fill="#10ccb9" />
              <XAxis dataKey="name" />
              <YAxis dataKey="Number of reservations" />
              <Tooltip />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    </>
  );
};

export default Graphs;
