import Box from 'Components/Atoms/Box';
import Typography from 'Components/Atoms/Typography';
import React from 'react';
import { Reservation } from 'types/reservations';
import { toCurrencyString } from 'utils/helper';

const ExperienceVoucher: React.FC<Reservation['experienceVoucher']> = ({
  voucher,
}) => {
  const addOns =
    voucher?.addOns?.map((a, i) => ({
      label: a?.title ?? '',
      value: a?.options ?? [],
      id: a?.id ?? i,
    })) ?? [];

  return (
    <div>
      <Box style={{ padding: '16px 16px 0 16px' }}>
        <Typography
          className="colon"
          variant="text-3"
          translation="reservations"
        >
          Gutschein-Infos
        </Typography>
      </Box>
      {!!voucher.title && (
        <Box className="space-between reservation-field">
          <Typography variant="text-2" color="subdued" translation={null}>
            {voucher.title}
          </Typography>
        </Box>
      )}
      {!!voucher.session?.total && (
        <Box className="space-between reservation-field">
          <Typography variant="text-3" translation="reservations">
            Price paid
          </Typography>
          <Typography variant="text-2" color="subdued" translation={null}>
            {toCurrencyString(voucher.session.total * 100)}
          </Typography>
        </Box>
      )}
      {!!voucher.internal_note && (
        <Box className="reservation-field">
          <Typography variant="text-3" translation="reservations">
            Interne Bemerkung
          </Typography>
          <Typography variant="text-2" color="subdued" translation={null}>
            {voucher.internal_note}
          </Typography>
        </Box>
      )}
      <Box style={{ padding: '16px 16px 0 16px' }}>
        <Typography
          className="colon"
          variant="text-3"
          translation="reservations"
        >
          Add Ons
        </Typography>
      </Box>
      {addOns.map((o) => (
        <Box key={o.id} className="space-between reservation-field">
          <Typography variant="text-3" translation={null}>
            {o.label}
          </Typography>
          <Typography variant="text-2" color="subdued" translation={null}>
            {o?.value?.join(', ') ?? ''}
          </Typography>
        </Box>
      ))}
    </div>
  );
};

export default ExperienceVoucher;
