import { Severity } from 'Contexts/RestaurantContext';
import { WaitingListEntryDocument } from 'gastronaut-shared/types/documents';
import { RequestStatus } from 'gastronaut-shared/types/helper/reservations';
import React, { useMemo } from 'react';
import { EditReservation } from 'types/reservations';
import { FieldValue } from 'utils/firebase';
import useAuth from './useAuth';
import useCollection from './useCollection';
import useToast from './useToast';
import { useRouter } from './useRouter';

export enum WaitingListActionType {
  FIND_TABLE = 'findTable',
  CANCEL = 'cancel',
  ACCEPT = 'accept',
  HOST_COMMENT = 'hostComment',
  UN_CANCEL = 'unCancel',
}

const useWaitinglist = (
  restaurantId: string | null,
  date: string,
  seteditReservation: React.Dispatch<
    React.SetStateAction<EditReservation | null>
  >
) => {
  const [waitinglistEntryDocs] = useCollection<WaitingListEntryDocument>(
    'waitinglistEntries',
    {
      filter: [
        ['restaurant', '==', restaurantId],
        ['date', '==', date],
      ],
    }
  );

  const navigation = useRouter();

  console.log({ waitinglistEntryDocs });

  const { uid } = useAuth();

  const toast = useToast();

  const waitinglistEntries = useMemo(
    () =>
      waitinglistEntryDocs.data
        .sort((a, b) => (a.createdAt ?? 0) - (b.createdAt ?? 0))
        .map((entry, index) => ({ ...entry, index })),
    [waitinglistEntryDocs]
  );

  const handleWaitinglistAction = (
    id: string,
    type: WaitingListActionType,
    payload?: any
  ) => {
    const entry = waitinglistEntries.find((e) => e.id === id);

    if (!entry) return;

    const ref = waitinglistEntryDocs.ref.doc(id);

    const updateInfos = { updatedAt: Date.now(), updatedBy: uid };

    // if (!window.location?.href.includes('tablePlan')) {
    //   toast(
    //     'Bitte wechsle in den Tischplan um diese Funktion zu nutzen',
    //     Severity.INFO,
    //     'reservations'
    //   );
    //   return;
    // }

    switch (type) {
      case WaitingListActionType.ACCEPT: {
        // @TODO

        return;
      }
      case WaitingListActionType.UN_CANCEL: {
        ref
          .update({
            ...updateInfos,
            canceledAt: FieldValue.delete(),
            canceledBy: FieldValue.delete(),
            status: 'pending',
          })
          .then(() => {
            toast(
              'Wartelisten Eintrag wurde abgesagt',
              Severity.SUCCESS,
              'reservations',
              () => {
                ref.update({
                  ...updateInfos,
                  status: entry.status,
                });
              }
            );
          })
          .catch((err: any) => {
            toast(err.message, Severity.ERROR, 'reservations');
          });

        return;
      }
      case WaitingListActionType.CANCEL: {
        ref
          .update({
            ...updateInfos,
            canceledAt: updateInfos.updatedAt,
            canceledBy: uid,
            status: 'canceled',
          })
          .then(() => {
            toast(
              'Wartelisten Eintrag wurde abgesagt',
              Severity.SUCCESS,
              'reservations',
              () => {
                ref.update({
                  ...updateInfos,
                  canceledAt: FieldValue.delete(),
                  canceledBy: FieldValue.delete(),
                  status: entry.status,
                });
              }
            );
          })
          .catch((err: any) => {
            toast(err.message, Severity.ERROR, 'reservations');
          });

        return;
      }
      case WaitingListActionType.FIND_TABLE: {
        let { id, date, time, occassion, guests, guest } = entry;

        const isTimeTable = window.location.pathname.endsWith('timeTable');

        if (isTimeTable) {
          navigation.push('tablePlan');
        }

        seteditReservation({
          date,
          time,
          tables: [],
          tableStr: null,
          reservationId: id,
          occassion,
          guests,
          guest,
          walkin: false,
          timeSlot: null,
          waitinglistEntry: entry,
          sendConfirmationEmail: true,
          originalUrl: isTimeTable ? 'timeTable?tab=waitinglist' : 'tablePlan',
        });
        return;
      }
      case WaitingListActionType.HOST_COMMENT: {
        ref
          .update({
            ...updateInfos,
            'guest.hostComment': payload,
          })
          .then(() => {
            toast(
              'Wartelisten Eintrag wurde abgesagt',
              Severity.SUCCESS,
              'reservations',
              () => {
                ref.update({
                  ...updateInfos,
                  'guest.hostComment': entry.guest.hostComment || '',
                });
              }
            );
          })
          .catch((err: any) => {
            toast(err.message, Severity.ERROR, 'reservations');
          });

        return;
      }
      default:
        break;
    }
  };

  return {
    waitinglistEntries,
    handleWaitinglistAction,
  };
};

export default useWaitinglist;
