import React from "react";

const DocAttachmentIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M104 126H24C18.5 126 14 121.5 14 116V12C14 6.5 18.5 2 24 2H64.7C67.4 2 69.9 3 71.8 4.9L111.1 44.2C113 46.1 114 48.6 114 51.3V116C114 121.5 109.5 126 104 126ZM24 6C20.7 6 18 8.7 18 12V116C18 119.3 20.7 122 24 122H104C107.3 122 110 119.3 110 116V51.3C110 49.7 109.4 48.2 108.2 47.1L68.9 7.8C67.8 6.6 66.3 6 64.7 6H24Z"
        fill="#1D409D"
      />
      <path
        d="M78.7 96H70.4L65.8 78C65.6 77.4 65.3 76 64.9 74C64.5 72 64.2 70.6 64.2 70C64.1 70.8 63.9 72.1999 63.5 74.0999C63.1 75.9999 62.8 77.2999 62.6 78.0999L58 96H49.7L41 61.7H48.1L52.5 80.4C53.3 83.9 53.8 86.8 54.2 89.4C54.3 88.5 54.5 87.1 54.8 85.3C55.1 83.5 55.5 82 55.7 81L60.7 61.7999H67.6L72.6 81C72.8 81.9 73.1 83.2 73.4 84.9C73.7 86.6 74 88.2 74.2 89.4C74.4 88.2 74.6 86.7 75 84.8C75.4 82.9 75.7 81.5 75.9 80.4L80.3 61.7H87.4L78.7 96Z"
        fill="#1D409D"
      />
    </svg>
  );
};

export default DocAttachmentIcon;
