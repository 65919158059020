import React, { useContext, useEffect, useState } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import Popover from 'Components/Atoms/Popover';
import { ArrowDropDown } from 'Components/Atoms/Icons';
import SignatureDropdownOptions from './Components/SignatureDropdownOptions';
import Typography from 'Components/Atoms/Typography';
import NewSignatureModal from '../NewSignatureModal';
import { Signature, SignatureContext } from 'Contexts/SignatureContext';
import useRestaurant from 'CustomHooks/useRestaurant';

export type SignatureDropdownProps = {
  value?: string | null;
  onChange: (newValue: string) => void;
  style?: React.CSSProperties;
  dropdownOptionStyle?: React.CSSProperties;
  titleHasMargin?: boolean;
  global?: boolean;
};

const SignatureDropdown: React.FC<SignatureDropdownProps> = ({
  value = null,
  onChange,
  style,
  dropdownOptionStyle,
  titleHasMargin,
  global = false,
}) => {
  const { addSignature, addSignatureWithPincode, validateSignature } =
    useContext(SignatureContext);

  const { restaurantId } = useRestaurant();

  const [showPopOver, setShowPopOver] = useState(false);

  const [anchorEl, setanchorEl] = useState<null | HTMLElement>(null);
  const [addNewSignatureModal, setAddNewSignatureModal] =
    useState<boolean>(false);

  function closeOptions() {
    setanchorEl(null);
    setShowPopOver(false);
  }

  useEffect(() => {
    if (value === null) {
      let global = JSON.parse(
        window.localStorage.getItem(`${restaurantId}-globalSignature`) ?? '{}'
      );

      if (global?.value) {
        onChange(global.value);
      }
    }
  }, [value, restaurantId]);

  return (
    <Box style={{ marginTop: global ? 0 : 16 }}>
      <Typography
        variant="text-3"
        style={{
          marginBottom: titleHasMargin ? 8 : 0,
          display: titleHasMargin ? 'block' : 'auto',
        }}
      >
        Signature
      </Typography>
      <Box
        className="space-between"
        padding="sm"
        background
        onClick={(e) => {
          setanchorEl(e.currentTarget);
          setShowPopOver(true);
        }}
        outline
        style={{ minWidth: 15, color: 'var(--color-text-subdued)', ...style }}
      >
        {value ? value : '-'}
        <ArrowDropDown
          style={{
            fill: 'var(--color-text-subdued)',
            right: 5,
            top: 5,
          }}
        />
      </Box>
      <Popover
        anchorEl={anchorEl}
        open={showPopOver}
        onClose={() => {
          setanchorEl(null);
          setShowPopOver(false);
        }}
        placement="bottom"
        padding={0}
      >
        <SignatureDropdownOptions
          {...{
            value,
            onChange,
            closeOptions,
            setAddNewSignatureModal,
            global,
          }}
          style={dropdownOptionStyle}
        />
      </Popover>
      <NewSignatureModal
        open={addNewSignatureModal}
        onClose={() => setAddNewSignatureModal(false)}
        onAddSignature={(s) => {
          addSignature(s);
          onChange(s.signature);
        }}
        onAddSignatureWithPincode={(s: Signature) => {
          setAddNewSignatureModal(true);
          addSignatureWithPincode(s);
          onChange(s.signature);
        }}
        validateSignature={validateSignature}
      />
    </Box>
  );
};

export default SignatureDropdown;
