import React, { useEffect, useState } from 'react';
import './styles.scss';
import Modal, { Action } from 'Components/Molecules/Modal';
import { noop } from 'utils/helper';
import { Translations } from 'utils/types';
import Typography from 'Components/Atoms/Typography';
import TextField from 'Components/Atoms/TextField';
import Checkbox from 'Components/Atoms/Checkbox';
import Select from 'Components/Atoms/Select';
import SignatureDropdown from '../SignatureDropdown';
import useCollection from 'CustomHooks/useCollection';
import { TextHelperTemplateDocument } from 'gastronaut-shared/types/documents';
import Box from 'Components/Atoms/Box';
import Button from 'Components/Atoms/Button';
import axios from 'axios';
import useAuth from 'CustomHooks/useAuth';
import server from 'utils/server';

export type TextHelperState = {
  groupId: string;
  onSubmit: (result: string) => void | Promise<void>;
  stopAwayClick?: boolean;
  html?: boolean;
  customData?: any;
};

export type TextHelperProps = {
  state: TextHelperState;
  onClose: VoidFunction;
  restaurantId: string;
};

const TextHelper = ({ state, onClose, restaurantId }: TextHelperProps) => {
  const [userInput, setUserInput] = useState<string>('');
  const [result, setresult] = useState('');
  const [error, seterror] = useState<string | null>(null);
  const [currentTemplate, setCurrentTemplate] = useState<
    (TextHelperTemplateDocument & { id: string }) | null
  >(null);
  const [loading, setloading] = useState(false);

  const { uid } = useAuth();

  const handleClose = () => {
    seterror(null);
    setresult('');
    setUserInput('');
    setloading(false);
    onClose();
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.stopPropagation();
    e.preventDefault();

    console.log(e);

    if (!state || !result) return;

    let resultString = result.trim();

    if (state.html) {
      resultString = resultString
        .split(`\n\n`)
        .map((x) => `<p>${x}</p>`)
        .join('')
        .replace(/\n/gm, '<br/>');
    }

    state.onSubmit(resultString);
    handleClose();
  };

  const ACTIONS: Action[] = [
    {
      id: 'cancel',
      label: 'Cancel',
      onClick: handleClose,
    },
    {
      id: 'submit',
      label: 'Save',
      variant: 'primary',
      onClick: handleSubmit,
      disabled: !result,
    },
  ];

  const [templates] = useCollection<
    TextHelperTemplateDocument & { id: string }
  >(`/textHelperTemplates`, {
    filter: [['groups', 'array-contains', state.groupId]],
  });

  useEffect(() => {
    if (templates.data.length) {
      if (
        !currentTemplate ||
        !templates.data.find((x) => x.id === currentTemplate.id)
      ) {
        setCurrentTemplate(templates.data[0]);
        setUserInput('');
        setresult('');
      }
    } else {
      setCurrentTemplate(null);
      setUserInput('');
      setresult('');
    }
  }, [currentTemplate, templates]);

  const handleApiCall = async () => {
    // setresult(currentTemplate?.exampleAnswer ?? "");

    // return;

    if (loading || !currentTemplate) return;
    setloading(true);

    try {
      const { data } = await server.post(
        `/v03/other/textHelperTemplates/${restaurantId}`,
        {
          userInput,
          template: currentTemplate?.id,
          userId: uid,
          checkboxes: {},
        }
      );

      setresult(data.answer);
      seterror('');
    } catch (error: any) {
      console.error(error);
      seterror(error?.response?.data?.message ?? error.message);
    }

    setloading(false);
  };

  console.log({ currentTemplate, templates, userInput, state });

  return (
    <Modal
      open={!!state}
      onClose={() => {
        console.log('closed');
        handleClose();
      }}
      stopAwayClick={state?.stopAwayClick}
      onSubmit={handleSubmit}
      dontClose
      maxWidth="sm"
      fullWidth
      actions={ACTIONS}
      title={currentTemplate?.title ?? 'Let SIA help you with your Texts'}
      severity="info"
      className="textHelper-state-wrapper-container"
    >
      <Typography variant="text-3" block style={{ marginBottom: 4 }}>
        Lass uns dir dabei helfen, deine Texte zu schreiben. Schreibe ein paar
        Stichpunkte, klicke auf Absenden und sehe die Magie von künstlicher
        Intelligenz.
      </Typography>
      <Typography variant="text-2" style={{ marginBottom: 8 }}>
        {currentTemplate?.description || ''}
      </Typography>

      <Box
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          marginBottom: 8,
        }}
      >
        <TextField
          multiline
          variant="outlined"
          value={userInput}
          fullWidth
          placeholder={currentTemplate?.exampleInput}
          helperText={
            !!userInput && !!currentTemplate?.exampleInput
              ? 'Text Beispiel: ' + currentTemplate.exampleInput
              : ''
          }
          onChange={(e) => {
            console.log('xxx', e.target.value);
            setUserInput(e.target.value);
            seterror('');
          }}
        />
        <Button
          onClick={handleApiCall}
          loading={loading}
          style={{ marginLeft: 8 }}
        >
          Absenden
        </Button>
      </Box>
      <Box>
        <Typography variant="text-3" weight="bold" block>
          {!result && !!currentTemplate?.exampleAnswer
            ? 'Beispiel Ergebnis:'
            : 'Ergebnis:'}
        </Typography>
        <Typography
          variant="text-3"
          dangerouslySetInnerHTML={{
            __html: (result || currentTemplate?.exampleAnswer || '')
              .trim()
              .replace(/\n/gm, '<br/>'),
          }}
        ></Typography>
      </Box>
      {/* {!!state?.inputFieldLabel && (
        <>
          {state.inputOptions ? (
            <Select
              options={state.inputOptions}
              value={textFieldValue}
              onChange={(e) => settextFieldValue(e.target.value as string)}
              style={{ marginTop: 8 }}
              margins={8}
              label={state.inputFieldLabel}
            />
          ) : (
            <TextField
              fullWidth
              style={{ marginTop: 8 }}
              label={state.inputFieldLabel}
              labelTranslation={state?.inputFieldLabelTranslation || "common"}
              value={textFieldValue}
              onChange={(e) => settextFieldValue(e.target.value)}
            />
          )}
        </>
      )}
      {!!state?.secondaryAction && (
        <Checkbox
          checked={secondaryAction}
          onChange={(_, secAction) => setsecondaryAction(secAction)}
          label={state.secondaryAction.label}
          translation={state.secondaryAction.labelTranslation}
        />
      )} */}

      {!!error && (
        <Typography color="error" variant="text-3" translation="reservations">
          {error}
        </Typography>
      )}
    </Modal>
  );
};

export default TextHelper;
