import React from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import Typography from 'Components/Atoms/Typography';
import { Button } from '@material-ui/core';
import useLocalStorageState from 'CustomHooks/useLocalStorageState';
import Divider from 'Components/Atoms/Divider';

export type RichAdProps = {
  title: string;
  description: string;
  image: string;
  imageStyle?: React.CSSProperties;
  id: string;
  button: {
    text: string;
    onClick: () => void;
  };
};

const RichAd: React.FC<RichAdProps> = ({
  title,
  description,
  image,
  imageStyle,
  id,
  button,
}) => {
  const [messageTS, setShowMessage] = useLocalStorageState(
    `show-richadts-${id}`,
    0
  );

  const showMessage = !messageTS || messageTS < Date.now();

  if (!showMessage) return <Divider margin="lg" />;

  return (
    <Box className="richAd">
      <Box>
        <Typography className="text title" variant="h3">
          {title}
        </Typography>
        <Typography
          className="text description"
          variant="text-3"
          component="p"
          dangerouslySetInnerHTML={{ __html: description }}
        ></Typography>
      </Box>
      <div
        style={{
          minWidth: Number(imageStyle?.width ?? 130),
          width: imageStyle?.width ?? 130,
        }}
      />
      <img
        src={image}
        alt="Werbung"
        style={{ position: 'absolute', ...imageStyle }}
      />
      <Box className="buttons">
        <Button
          variant="contained"
          onClick={button.onClick}
          disableElevation
          color="primary"
        >
          {button.text}
        </Button>
        <Button
          variant="contained"
          disableElevation
          color="default"
          style={{
            background: 'var(--color-paper)',
            color: 'var(--color-text-subdued)',
          }}
          onClick={() => setShowMessage(Date.now() + 3 * 24 * 60 * 60000)}
        >
          Nicht mehr anzeigen
        </Button>
      </Box>
    </Box>
  );
};

export default RichAd;
