import React, { useEffect, useRef, useState } from "react";
import Popover, { PopoverProps } from "Components/Atoms/Popover";
import "./styles.scss";
import { Modify } from "utils/types";

export type TooltipProps = Modify<
  {
    children?: React.ReactNode;
    content?: React.ReactNode;
    timeout?: number;
    handleClick?: boolean;
    className?: string;
    style?: React.CSSProperties;
    width?: string;
  },
  PopoverProps
>;

const Tooltip = ({
  children,
  content,
  timeout = 1000,
  handleClick = false,
  className,
  style,
  width,
  ...props
}: TooltipProps) => {
  const [open, setopen] = useState<any>(null);

  const anchorEl = useRef<any>(null);

  const timeoutRef = useRef<any>(null);

  const handleMouseEnter = (e: null | React.SyntheticEvent = null) => {
    if (!anchorEl.current) return;

    if (e !== null) {
      timeoutRef.current = setTimeout(() => {
        setopen(anchorEl?.current?.childNodes[0]);
      }, timeout);
    } else {
      setopen(anchorEl?.current?.childNodes[0]);
    }
  };

  const handleMouseLeave = () => {
    setopen(false);
    clearTimeout(timeoutRef.current);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  });

  return (
    <>
      <div
        className={className}
        style={{ cursor: "help", ...style }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        ref={anchorEl}
        onClick={handleClick ? () => handleMouseEnter(null) : undefined}
      >
        {children}
      </div>
      <Popover
        width={width ?? "auto"}
        anchorEl={open}
        open={!!open}
        onClose={handleMouseLeave}
        placement="top"
        {...props}
      >
        {content}
      </Popover>
    </>
  );
};

export default Tooltip;
