import { ThemeContext } from 'Contexts/ThemeContext';
import React, { useContext, useMemo } from 'react';
import { Backgrounds, Background, FloorPlan } from 'types/reservations';

const TEST: Backgrounds = {
  restaurant: {
    spaceId: 'restaurant',
    width: 1115,
    height: 711,
    factor: 10,
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/schillingroofbarhd.appspot.com/o/Tests%2FBackground.png?alt=media&token=d57bfdba-6be1-46fd-b9b2-923cffa90d58',
  },
};

const useBackground = (
  floorPlan: FloorPlan | null,
  space: string | null,
  editBackground?: Background
) => {
  const { darkMode } = useContext(ThemeContext);

  const background = useMemo(() => {
    let background =
      editBackground ?? floorPlan?.backgrounds?.[space ?? ''] ?? null;

    if (!background?.imageUrl) return null;

    if (darkMode && background.imageUrlDM) {
      background.imageUrl = background.imageUrlDM;
    } else if (darkMode && background.invert === undefined) {
      background.invert = 1;
    } else if (!darkMode) {
      background.invert = null as any;
    }

    return background;
  }, [floorPlan, space, editBackground, darkMode]);

  return background;
};

export default useBackground;
