import { Modify, Translation } from "utils/types";
import { Meal } from "./menu";

export enum OrderMethod {
  PICKUP = "pickup",
  DELIVERY = "delivery",
}

export type TakeAwayAdvert = {
  active: boolean;
  image?: string | null;
  translations: Record<
    string,
    { title: string; description: string; additionalDescription?: string }
  >;
};

export type TakeAwayShift = {
  id: string;
  type: "regular" | "special";
  dates?: string[];
  startAt?: string;
  endAt?: string | null;
  deliveryMethods: OrderMethod[];
  wDays: number[];
  onlySelectedMeals?: boolean;
  name: string;
  menu?: string | null;
  mealsAvailable?: string[];
  close: string;
  start: string;
  advert?: null | TakeAwayAdvert;
  overwrite?: boolean;
  deleted?: boolean;
  updatedAt?: number;
};

// export type TakeAwaySpecialShift = {
//   dates: string[];
//   closed: boolean;
//   close: string;
//   start: string;
//   id: string;
//   overwrite: boolean;
//   advert: null | object;
//   onlySelectedMeals?: boolean;
//   mealsAvailable?: string[];
// };

export type NewOrderState = {
  orderTime: null | string;
  orderMethod: OrderMethod;
  customer: {
    name: string;
    email: string;
    phone: string;
    comment?: string;
    company?: string;
    persons?: string | number | null;
  };
  address?: {
    street: string;
    city: string;
    zipCode: string;
    adressZusatz: string;
    call?: boolean;
  };
  cart: TakeAwayCartItem[];
  deliveryFee?: number;
  voucherId?: string;
  couponId?: string;
};

export type TakeAwayOption = {
  id: string;
  category: string;
  isRequired: boolean;
  translations: Record<string, { title: string; description: string }>;
  shortName: string;
  position: number;
  multiple: boolean;
  addToPrice: boolean;
  options: {
    id: string;
    position?: number;
    price: number;
    shortName: string;
    translations: Record<string, { title: string; description?: string }>;
  }[];
};

export enum OrderStatus {
  FAILED = "failed",
  PENDING = "pending",
  SEEN = "seen",
  DONE = "done",
  PREPERATIONDONE = "preperationDone",
  INPREPARATION = "inPreparation",
}

export enum PaymentMethod {
  CASH = "cash",
  SOFORT = "sofort",
  SEPA = "sepa_debit",
  CARD = "card",
}

export type TakeAwayOrder = {
  id: string;
  status: OrderStatus;
  date: string;
  billNumber: string;
  orderNumber: number;
  time: string;
  orderTime: string;
  paymentMethod: PaymentMethod;
  sendAt: number;
  orderMethod: OrderMethod;
  customer: {
    name: string;
    email: string;
    phone: string;
    comment?: string;
    company?: string;
    persons?: string | number | null;
  };
  address?: {
    street: string;
    city: string;
    zipCode: string;
    adressZusatz: string;
    call?: boolean;
  };
  cart: TakeAwayCartItem[];
  paid: boolean;
  total: number;
  tip: number;
  deliveryFee: number;
  couponValue: number;
  couponId: null | string;
  createdAt: number;
  deliveryDuration?: number;
  emailStatus?: string;
  canceled?: boolean;
  coordinates?: { lat: number; lng: number };
  letter?: string;
};

export type TakeAwayOrderSnippet = {
  createdAt: number;
  id: string;
  name: string;
  orderMethod: OrderMethod;
  paymentMethod: PaymentMethod;
  status: OrderStatus;
  time: string;
  tip: number;
  total: number;
  date: string;
};

export type TakeAwayCartItem = Modify<
  Meal,
  {
    id: string;
    shortName: string;
    categoryName: string;
    options?: { id: string; value: string | string[] }[];
    pickupOptions?: null | TakeAwayOption[];
    amount: number;
    price: number;
    canceled?: boolean;
  }
>;

export type TakeAwayAnalytics = {
  dates: Record<string, TakeAwayOrderSnippet[]>;
  lastOrders: TakeAwayOrderSnippet[];
  listener?: Function[];
  loading: boolean;
  error?: null | string;
};

export type Action =
  | "addressChange"
  | "dateChange"
  | "edit"
  | "printInvoice"
  | "printHospitality"
  | "printReceipt"
  | "cancelOrder"
  | "status";

export type EmergencyStopState = {
  stopped: boolean;
  toggleStopped: () => void;
};

export type PrinterState = {
  outstanding: number;
  printOutstanding: () => void;
};
