import Box from 'Components/Atoms/Box';
import Button from 'Components/Atoms/Button';
import Typography from 'Components/Atoms/Typography';
import { useRouter } from 'CustomHooks/useRouter';
import { TicketDocument } from 'gastronaut-shared/types/documents';
import React, { useMemo, useState } from 'react';
import { Reservation } from 'types/reservations';
import { timeStampToDateAndHour, toCurrencyString } from 'utils/helper';
import { ExtendedEvent } from '..';
import { Print } from '@material-ui/icons';

type Infos = Partial<{
  turnover: string;
  groups: number;
  tickets: number;
  maxTickets: number;
  pending: number;
  pendingGroups: number;
  options: Record<string, number>;
  addOns: Record<string, number>;
  rules: string;
}>;

const Ticket: React.FC<{
  ticket: TicketDocument & { reservation: Reservation | null };
}> = ({ ticket }) => {
  const { date, time } = timeStampToDateAndHour(ticket.createdAt ?? Date.now());

  const router = useRouter();

  const { status, color } = useMemo(() => {
    switch (ticket.status) {
      case 'missing_payments':
        if (
          ticket.createdAt &&
          ticket.createdAt > Date.now() - 24 * 60 * 60000
        ) {
          return { status: 'K. Zahlung', color: 'var(--color-secondary)' };
        }
        return { status: 'Abgebrochen', color: 'var(--color-critical)' };
      case 'failed':
        return { status: 'Abgebrochen', color: 'var(--color-critical)' };
      case 'processing':
        return { status: 'Bearbeitung', color: 'var(--color-secondary)' };
      case 'succedded':
        return { status: 'Erfolgreich', color: 'var(--color-text)' };
      default:
        return { status: ticket.status, color: 'var(--color-text)' };
    }
  }, [ticket]);

  const tables = ticket?.reservation?.tables?.join(', ') ?? '';

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: 12,
        cursor: ticket.reservation ? 'pointer' : 'not-allowed',
      }}
      onClick={() =>
        ticket.reservation &&
        router.replace(
          `/${ticket.restaurant}/reservations/${ticket.date}/tablePlan?reservationId=${ticket.reservation.id}`
        )
      }
    >
      <div style={{ flex: 10, color }}>
        <Typography variant="h5" color="inherit">
          {ticket?.guest?.name ?? ''}
        </Typography>
        <Typography variant="text-3" component="p" color="subdued">
          {ticket.customId} • {ticket.guests} Personen
        </Typography>
      </div>
      <div
        style={{
          marginLeft: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          color,
        }}
      >
        <Typography variant="text-4" color="inherit">
          {date}, {time}
        </Typography>
        <Typography variant="text-4" color="inherit">
          {toCurrencyString(ticket.amount, '€', false, 1)}, {status}
        </Typography>
      </div>
    </div>
  );
};

const CurrentEvent: React.FC<{
  event: ExtendedEvent;
  loading?: boolean;
  date: string;
  error?: string | null;
  restaurantId: string;
}> = ({ event, error, loading, date, restaurantId }) => {
  const [tab, settab] = useState(0);

  const detailedFields = useMemo(() => {
    let infos: Infos = {
      groups: 0,
      tickets: 0,
      turnover: '0,00€',
    };

    let ticketsMissingPayments = event.tickets.filter(
      (t) =>
        t.eventId === event?.id &&
        (t.createdAt ?? Date.now()) >= Date.now() - 24 * 60 * 60000 &&
        t.status === 'missing_payments'
    );
    let tickets = event.tickets.filter(
      (t) =>
        t.eventId === event?.id &&
        (t.status === 'succedded' || t.status === 'processing')
    );
    // let failed = ticketData.data.filter(t => t.status === 'failed').reduce((acc, cV) => acc + cV.guests, 0);

    if (tickets.length) {
      let ticketCount = tickets.reduce((acc, cV) => acc + cV.guests, 0);
      let turnover = tickets.reduce((acc, cV) => acc + cV.amount, 0);
      infos.turnover = toCurrencyString(turnover, '€', false, 1);

      if (event.payAtRestaurant) {
        infos.turnover += `(Noch nicht kassiert)`;
      }

      infos.tickets = ticketCount;
      infos.groups = tickets.length;
    }

    if (ticketsMissingPayments.length) {
      infos.pending = ticketsMissingPayments.reduce(
        (acc, cV) => acc + cV.guests,
        0
      );
      infos.pendingGroups = ticketsMissingPayments.length;
    }

    if ((event?.options?.length ?? 0) > 1) {
      let optionMap = tickets.reduce((acc, cV) => {
        let x = { ...acc };

        Object.entries(cV.options ?? {}).forEach(([id, val]) => {
          if (val !== 0) {
            x[id] = (x[id] ?? 0) + val;
          }
        });

        return x;
      }, {} as Record<string, number>);

      infos.options = Object.entries(optionMap)
        .sort((a, b) => b[1] - a[1])
        .reduce((acc, [id, val]) => {
          let opt = event?.options?.find((x) => x.id === id);

          return {
            ...acc,
            [opt?.translation?.['de']?.title ?? opt?.internalName ?? id]: val,
          };
        }, {});
    }

    let addOnMap = tickets.reduce((acc, cV) => {
      let x = { ...acc };

      Object.entries(cV.optionalAddOns ?? {}).forEach(([id, val]) => {
        if (val !== 0) {
          x[id] = (x[id] ?? 0) + val;
        }
      });

      return x;
    }, {} as Record<string, number>);

    infos.addOns = Object.entries(addOnMap)
      .sort((a, b) => b[1] - a[1])
      .reduce((acc, [id, val]) => {
        let opt = event?.optionalAddOns?.find((x) => x.id === id);

        return {
          ...acc,
          [opt?.translation?.['de']?.title ?? opt?.internalName ?? id]: val,
        };
      }, {});

    if (event.maxGuests) {
      infos.maxTickets = event.maxGuests;
    }

    let rules: string[] = [];

    if (event.rules?.minGuests) {
      if (event.rules.maxGuests) {
        rules.push(
          `${event.rules.minGuests} - ${event.rules.maxGuests} Pax pro Gruppe`
        );
      } else {
        rules.push(`min. ${event.rules.minGuests} Pax pro Gruppe`);
      }
    } else if (event.rules?.maxGuests) {
      rules.push(`max. ${event.rules.minGuests} Pax pro Gruppe`);
    }

    if (event.rules?.daysTill) {
      rules.push(`Spätestens ${event.rules.daysTill} Tage vorher buchbar`);
    }

    if (rules.length) {
      infos.rules = rules.join(', ');
    }

    return [
      {
        label: 'Umsatz:',
        value: infos.turnover,
        align: 'right',
        show: true,
      },
      {
        label: 'Tickets:',
        sameLine: true,
        value: [
          `${infos.tickets} Tickets (${infos.groups} Gruppen)`,
          infos.pending ? `${infos.pending} ausstehde Zahlungen` : '',
          infos.maxTickets ? `max ${infos.maxTickets} Tickets verfügbar` : '',
        ],
        show: true,
        align: 'right',
      },
      {
        label: 'Optionen:',
        value: Object.entries(infos.options ?? {}).map(([label, value]) => ({
          label,
          value,
        })),
        align: 'right',
        show: infos.options,
      },
      {
        label: 'AddOns:',
        value: Object.entries(infos.addOns ?? {}).map(([label, value]) => ({
          label,
          value,
        })),
        align: 'right',
        show: infos.addOns,
      },
      {
        label: 'Einschränkungen:',
        value: infos.rules,
        align: 'right',
        show: infos.rules,
      },
    ].filter((x) => !!x.show && x.value?.length);
  }, [event]);

  return (
    <>
      <Box className="tabs">
        <Button
          className={tab === 0 ? 'active' : ''}
          onClick={() => settab(0)}
          variant="transparent"
        >
          Info
        </Button>
        <Button
          className={tab === 1 ? 'active' : ''}
          onClick={() => settab(1)}
          variant="transparent"
        >
          Tickets
        </Button>
      </Box>
      {tab === 0 && (
        <div>
          {detailedFields.map((f, i) => (
            <Box
              key={i}
              className={`space-between reservation-field ${
                Array.isArray(f.value) || String(f?.value ?? '').length > 30
                  ? 'flex-start'
                  : ''
              } ${Array.isArray(f.value) && !f.sameLine ? 'flex-column' : ''}`}
            >
              <Typography variant="text-3" translation="reservations">
                {f.label}
              </Typography>
              {!Array.isArray(f.value) && (
                <Typography
                  variant={'text-3'}
                  color="subdued"
                  translation={f.label === 'Status' ? 'reservations' : null}
                  textAlign={f.align as any}
                >
                  {f.value}
                </Typography>
              )}
              {Array.isArray(f.value) && (
                <div style={{ width: '100%' }}>
                  {f.value.map((v, i) =>
                    typeof v === 'string' ? (
                      <Typography
                        key={i}
                        variant={'text-3'}
                        component="p"
                        style={{
                          width: '100%',
                          display: v.length ? 'block' : 'none',
                        }}
                        color="subdued"
                        textAlign={f.align as any}
                      >
                        {v}
                      </Typography>
                    ) : (
                      <div
                        key={i}
                        className="space-between flex-start"
                        style={{ marginBottom: 2 }}
                      >
                        <Typography
                          variant="text-3"
                          textAlign="left"
                          color="subdued"
                        >
                          {v.label}
                        </Typography>
                        <Typography
                          variant="text-3"
                          color="subdued"
                          textAlign="right"
                        >
                          {v.value}
                        </Typography>
                      </div>
                    )
                  )}
                </div>
              )}
            </Box>
          ))}
          <Button
            href={`https://europe-west3-schillingroofbarhd.cloudfunctions.net/printCSVForTickets?restaurantId=${restaurantId}&date=${date}`}
            startIcon={(p) => <Print {...p} />}
            fullWidth
            target="_blank"
            style={{ marginTop: 12 }}
          >
            Als CSV herunterladen
          </Button>
        </div>
      )}
      {tab === 1 && (
        <div>
          {event.tickets.map((t) => (
            <Ticket ticket={t} key={t.id} />
          ))}
        </div>
      )}
    </>
  );
};

export default CurrentEvent;
