import { ReservationContext } from 'Contexts/ReservationContext'
import React, { useContext } from 'react'

const useReservationSettings = () => {

    const { settings, reservationSettings } = useContext(ReservationContext);

    return { ...settings, ...reservationSettings }
}

export default useReservationSettings
