import React, { useEffect, useState } from 'react';

let interval:NodeJS.Timeout; 

const useInterval = (fn?:VoidFunction,options?:{  ms?:number, dependencies?:any[] }) => {

  const { ms = 30000, dependencies = [] } = options ?? {};

  const [int,set] = useState(0);

  const update = () => {
    set(u => u + 1);
  }

  useEffect(() => {
    if(interval) {
        clearInterval(interval);
    }

    interval = setInterval(() => {
        fn?.();
        update();
    }, ms);

  }, dependencies);

  

  return [int, update] as [number,VoidFunction];
}

export default useInterval