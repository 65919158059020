import React from 'react';
import './styles.scss';
import Box from 'Components/Atoms/Box';
import ReactDOM from 'react-dom';
import { PinAuth } from 'Contexts/RestaurantContext';
import Pin from './Pin';

type PinAlertProps = {
  isOpen: boolean;
  onSubmit?: (signature: string, timeout?: number) => void;
  onClose?: () => void;
  onDenial?: () => void;
  disallowAddSignature?: boolean;
  signatures?: string[];
};

const PinAlert = ({
  isOpen,
  onSubmit,
  onClose,
  onDenial,
  disallowAddSignature = false,
  signatures = [],
}: PinAlertProps) => {
  if (!isOpen) return <></>;

  return ReactDOM.createPortal(
    <div className="pin-alert-modal-container">
      <Pin
        onSubmit={onSubmit}
        onClose={onClose}
        onDenial={onDenial}
        disallowAddSignature={disallowAddSignature}
        signatures={signatures}
      />
      <div className="pin-alert-modal-footer"></div>
    </div>,
    document.getElementById('portal') as Element
  );
};

export default PinAlert;
