import React from "react";

const PlateIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_9507_29017)">
      <path
        d="M11.3503 3.30469C7.32643 3.30469 4.06445 6.56666 4.06445 10.5905C4.06445 14.6144 7.32643 17.8763 11.3503 17.8763C15.3741 17.8763 18.6361 14.6144 18.6361 10.5905C18.6361 6.56666 15.3741 3.30469 11.3503 3.30469ZM11.3502 16.1188C8.30195 16.1188 5.82196 13.6388 5.82196 10.5906C5.82196 7.54227 8.30195 5.06228 11.3502 5.06228C14.3985 5.06228 16.8785 7.54227 16.8785 10.5906C16.8785 13.6388 14.3986 16.1188 11.3502 16.1188Z"
        fill="currentColor"
      />
      <path
        d="M11.3526 6.54297C9.12137 6.54297 7.30615 8.35819 7.30615 10.5894C7.30615 12.8206 9.12137 14.6358 11.3526 14.6358C13.5838 14.6358 15.399 12.8206 15.399 10.5894C15.399 8.35819 13.5838 6.54297 11.3526 6.54297Z"
        fill="currentColor"
      />
      <path
        d="M21.5417 11.201V2.34746C21.5417 2.26204 21.4872 2.18611 21.4062 2.15883C21.3252 2.13159 21.2359 2.15913 21.1842 2.22719C20.0796 3.68396 19.4817 5.46201 19.4817 7.2902V10.5291C19.4817 10.9006 19.7828 11.2017 20.1543 11.2017H20.4347C20.1583 14.0567 19.9757 18.0497 19.9757 18.5172C19.9757 19.2538 20.429 19.8509 20.9883 19.8509C21.5475 19.8509 22.0009 19.2537 22.0009 18.5172C22.0009 18.0496 21.8182 14.056 21.5417 11.201Z"
        fill="currentColor"
      />
      <path
        d="M2.93979 9.44567C3.50019 9.10622 3.87475 8.49073 3.87475 7.78768L3.59717 3.24928C3.58656 3.07629 3.44317 2.94141 3.26984 2.94141C3.08236 2.94141 2.933 3.09846 2.94233 3.28559L3.12284 6.92543C3.12284 7.13004 2.95694 7.29586 2.75241 7.29586C2.54783 7.29586 2.38197 7.12996 2.38197 6.92543L2.29178 3.28709C2.28701 3.0948 2.12979 2.94145 1.93742 2.94145C1.74505 2.94145 1.58787 3.09485 1.58305 3.28709L1.49286 6.92543C1.49286 7.13004 1.32696 7.29586 1.12243 7.29586C0.917855 7.29586 0.751996 7.12996 0.751996 6.92543L0.932508 3.28559C0.941789 3.09837 0.792344 2.94141 0.605 2.94141C0.431664 2.94141 0.288277 3.07629 0.277707 3.24928L0 7.78772C0 8.49078 0.374516 9.10622 0.934957 9.44571C1.29968 9.66662 1.503 10.0796 1.456 10.5035C1.13962 13.3548 0.924773 18.0098 0.924773 18.5169C0.924773 19.2535 1.37814 19.8506 1.93738 19.8506C2.49661 19.8506 2.94998 19.2534 2.94998 18.5169C2.94998 18.0098 2.73509 13.3548 2.41875 10.5035C2.3717 10.0798 2.57525 9.66649 2.93979 9.44567Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_9507_29017">
        <rect stroke="currentColor" width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PlateIcon;
