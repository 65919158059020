import React from 'react';
import Box from 'Components/Atoms/Box';
import Typography from 'Components/Atoms/Typography';
import { toCurrencyString } from 'utils/helper';

export type StatusProps = {
  status: string;
  quantity: number;
};

const Status = ({ status, quantity }: StatusProps) => {
  return (
    <Box
      flex
      style={{
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        cursor: 'pointer',
      }}
      width="auto"
    >
      <Typography
        variant="text-3"
        translation="common"
        weight="regular"
        textAlign="right"
        color="subdued"
      >
        {status}
      </Typography>
      <Typography
        variant="text-3"
        translation="common"
        weight="regular"
        textAlign="right"
      >
        : {status === 'Umsatz' ? toCurrencyString(quantity * 100) : quantity}
      </Typography>
    </Box>
  );
};
export default Status;
