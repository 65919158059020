import React from "react";
import ReactDOM from "react-dom";
import "./styles.scss";

import { Close } from "Components/Atoms/Icons";
import Typography from "Components/Atoms/Typography";
import { Size, Translations } from "utils/types";

type MobileModalType = "fullscreen" | "small";

interface MobileModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  subtitle?: string;
  titleTranslation?: Translations;
  subtitleTranslation?: Translations;
  children?: React.ReactNode;
  type?: MobileModalType;
  padding?: Size;
}

const MobileModal = ({
  isOpen,
  onClose,
  title,
  subtitle,
  titleTranslation,
  subtitleTranslation,
  children,
  type = "fullscreen",
  padding = "md",
}: MobileModalProps) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    type === "fullscreen" ? (
      <div className="mobile-modal-container">
        <div className="mobile-modal-header">
          <div className="mobile-modal-header-left">
            <Typography
              variant="h5"
              color="textPrimary"
              translation={titleTranslation}
            >
              {title}
            </Typography>
            <div style={{ width: 8 }} />
            <Typography
              variant="h5"
              color="subdued"
              translation={subtitleTranslation}
            >
              {subtitle}
            </Typography>
          </div>
          <Close onClick={onClose} />
        </div>
        <div
          className="mobile-modal-main"
          style={{
            padding:
              padding === "sm"
                ? 8
                : padding === "md"
                ? 16
                : padding === "lg"
                ? 24
                : padding,
          }}
        >
          {children}
        </div>
        <div className="mobile-modal-footer"></div>
      </div>
    ) : (
      <div className="mobile-modal-container-small">
        <div className="mobile-modal-backdrop" />
        <div className="mobile-modal-header-small">
          <Typography
            variant="h5"
            color="textPrimary"
            translation={titleTranslation}
          >
            {title}
          </Typography>
          <Close onClick={onClose} />
        </div>
        <div className="mobile-modal-main-small">{children}</div>
        <div className="mobile-modal-footer-small"></div>
      </div>
    ),
    document.getElementById("portal") as Element
  );
};

export default MobileModal;
