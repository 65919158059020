import React, { useContext, useEffect, useMemo, useState } from "react";
import Box from "Components/Atoms/Box";
import "./styles.scss";
import TabCard from "Components/Molecules/TabCard";
import ShiftComment from "../ShiftComment";
import {
  ReservationShift,
  ShiftComment as ShiftCommentType,
} from "types/shifts";
import { ReservationContext } from "Contexts/ReservationContext";
import { blockToTime, timeHelper, timeToBlock } from "utils/helper";
import { AccessTime, Category } from "@material-ui/icons";
import Typography from "Components/Atoms/Typography";
import Divider from "Components/Atoms/Divider";

export type ShiftCommentTabsProps = {
  shiftOptions: {
    id: string | null;
    label: string;
    shift?: ReservationShift;
    comment?: ShiftCommentType;
  }[];
  restaurantId: string;
  date: string;
  small?: boolean;
  style?: React.CSSProperties;
};

const ShiftDetails = ({
  shift,
  showName = false,
}: {
  shift: ReservationShift;
  showName?: boolean;
}) => {
  const { occassions, floorPlanProps } = useContext(ReservationContext);

  const occAndSpaces = useMemo(() => {
    let occ = shift.occassions.map(
      (o) => occassions?.find((ox) => o === ox.id)?.title ?? o
    );

    let spaces = shift.spaces.map(
      (sp) =>
        floorPlanProps.floorPlan?.spaces.find((sx) => sp === sx.id)?.name ?? sp
    );

    let arr = [...occ, ...spaces];

    if (occassions && occassions.length === 1 && spaces.length === 0) {
      return "";
    }

    let sep = shift.matchBoth ? " & " : " oder ";

    return arr.reduce((acc, cV, i) => {
      let sep =
        i !== 0
          ? i < arr.length - 1
            ? ", "
            : shift.matchBoth
            ? " & "
            : " oder "
          : "";

      return `${acc}${sep}${cV}`;
    }, "");
  }, [occassions, floorPlanProps, shift]);

  const from = blockToTime(shift.start);

  const till = blockToTime(shift.close);

  return (
    <>
      <Box flex className="flex-start" style={{ padding: 16, paddingTop: 0 }}>
        {showName && (
          <Typography variant="text-3" weight="bold" style={{ marginRight: 8 }}>
            {shift.name}:
          </Typography>
        )}
        <Typography variant="text-3">
          {shift.closed
            ? "Closed"
            : shift.overwrite?.blockReservations
            ? "Blockiert für Reservierungen"
            : "Open"}
        </Typography>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            width: "auto",
            marginLeft: "8px",
          }}
        >
          <AccessTime fontSize="small" style={{ marginRight: "4px" }} />
          <Typography variant="text-3">
            {!shift.allDay ? (
              <>
                {from} - {till}
              </>
            ) : (
              "All Day"
            )}
          </Typography>
        </Box>
        {!!occAndSpaces && (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              width: "auto",
              marginLeft: "8px",
            }}
          >
            <Category fontSize="small" style={{ marginRight: "4px" }} />
            <Typography variant="text-3">{occAndSpaces}</Typography>
          </Box>
        )}
      </Box>
      <Divider
        style={{ transform: "translateX(-16px)", width: "calc(100% + 32px)" }}
      />
    </>
  );
};

const ShiftCommentTabs: React.FC<ShiftCommentTabsProps> = ({
  shiftOptions,
  restaurantId,
  date,
  small = false,
  style,
}) => {
  const [currentShiftId, setCurrentShift] = useState<any>(null);

  useEffect(() => {
    let fShifts = shiftOptions.filter((s) => !!s.id).map((s) => s.id);
    if (!currentShiftId || !fShifts.includes(currentShiftId)) {
      if (fShifts.length) {
        setCurrentShift(fShifts[0]);
      } else {
        setCurrentShift(null);
      }
    }
  }, [shiftOptions, currentShiftId]);

  const currentShift = useMemo(
    () => shiftOptions?.find((s) => s.id === currentShiftId) ?? null,
    [shiftOptions, date, restaurantId, currentShiftId]
  );

  if (!shiftOptions.filter((s) => !!s.id).length) {
    return <></>;
  }

  return (
    <TabCard
      id="shiftReservationCard"
      value={currentShiftId}
      onChange={setCurrentShift}
      tabs={shiftOptions.filter((s) => !!s.id)}
      title="Shift Comments"
      badge={shiftOptions.filter((s) => !!s.id).length}
      titleTranslation="reservations"
      closable={small}
      style={style}
      hideTabsWhenOnlyOne
    >
      {!!currentShift?.shift && (
        <ShiftDetails
          shift={currentShift.shift}
          showName={shiftOptions.length === 1}
        />
      )}
      {!!currentShiftId && (
        <ShiftComment
          shiftId={currentShiftId}
          comment={currentShift?.comment}
          small={small}
          date={date}
          restaurantId={restaurantId}
        />
      )}
    </TabCard>
  );
};

export default ShiftCommentTabs;
