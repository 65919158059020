import axios from 'axios';
export * from './TakeAway';

const DEVELOPMENT = document.location.host.includes('localhost') && false;
const liveServer =
  process.env.REACT_APP_CURRENT === 'gastronaut-demo'
    ? 'https://api-demo.gastronaut.ai'
    : 'https://api.gastronaut.ai';

const server = axios.create({
  baseURL: DEVELOPMENT ? 'http://localhost:8000' : liveServer,
  timeout: 15000,
  headers: { 'X-Custom-Header': 'dashboard' },
});

export default server;
