import React, { useMemo } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import Typography from '../../../../Components/Atoms/Typography';
import {
  Create,
  Done,
  Lock,
  NotInterested,
  Star,
  Comment,
  LocalActivity,
  Moped,
  ShoppingBasket,
  PartlyLocked,
} from 'Components/Atoms/Icons';
import { CalendarShift } from '../Week';
import { blockToTime, getTime } from 'utils/helper';
import { ActiveShift } from 'CustomHooks/useCalendarDates';
import { AnyShift } from 'gastronaut-shared/types/helper/shifts';
import { checkActiveShift } from 'Contexts/CalendarContext';

interface ReservationShiftProps {
  shift: CalendarShift;
  top?: number;
  selected: boolean;
  setSelected: (val: string) => void;
  width: number;
  isOverlapping?: boolean;
  activeShift?: ActiveShift<AnyShift> | undefined;
}

const ReservationShift = ({
  shift,
  top = 0,
  selected = false,
  setSelected,
  width,
  isOverlapping = false,
  activeShift = undefined,
}: ReservationShiftProps) => {
  const isShiftDone =
    Date.now() > getTime(shift.date, shift.close, shift.start);

  // function reservationsHandler() {
  //   let sum = 0;
  //   shift.reservations.forEach((res) => (sum += res));
  //   return `${sum} / ${shift.reservations.length}`;
  // }

  const shiftLength =
    shift.close !== null && shift.start !== null
      ? Math.max(shift.close - shift.start, 1)
      : 24 * 4;

  const shiftStart = shift.start ? blockToTime(shift.start, false) : '';
  let shiftClose = shift.close ? blockToTime(shift.close, false) : '';

  if (shiftClose === '00:00') {
    shiftClose = '24:00';
  }

  const { border, background, opacity, commentOnly, color } = useMemo(() => {
    const active =
      selected || checkActiveShift(activeShift, shift.id, shift.date);

    const commentOnly = !!shift.commentOnly;

    let borderColor = active
      ? 'var(--color-text)'
      : isOverlapping
      ? 'var(--color-bg)'
      : shift.color ?? 'var(--color-primary)';

    let background = commentOnly
      ? 'var(--color-bg)'
      : shift.color ?? 'var(--color-primary)';

    let color = commentOnly ? 'var(--color-text)' : 'var(--color-primary-5)';

    return {
      border: `${
        isOverlapping && !active ? '2px' : '3px'
      } solid ${borderColor}`,
      background,
      opacity: shift.disabled || !shift.active ? 0.5 : 1,
      commentOnly,
      color,
    };
  }, [shift, activeShift, selected]);

  // console.log({ shiftLength });

  const shortShift = shiftLength < 13;

  return (
    <Box
      className="shift-container"
      style={{
        width,
        background,
        border,
        opacity,
        color,
        height: shiftLength * 8 - 3 - Math.max(90 - top, 0),
      }}
      /* @ts-ignore */
      onClick={setSelected}
    >
      <Box className="upper-container">
        <Box
          flex
          className="title-container"
          style={{ flexDirection: isOverlapping ? 'column' : 'row' }}
        >
          <Typography
            className="primary-text"
            variant="text-4"
            color="inherit"
            translation={null}
            style={{
              fontWeight: 'bolder',
              maxHeight: '2rem',
              overflow: 'hidden',
            }}
          >
            {shift.name}
          </Typography>
        </Box>
        <Typography
          className="secondary-text"
          variant="text-4"
          color="inherit"
          style={{
            opacity: 0.6,
            marginBottom: 4,
            whiteSpace: shortShift ? 'nowrap' : 'normal',
          }}
        >
          {shiftStart} - {shiftClose}
        </Typography>
        <div
          className="icon-container"
          style={{ opacity: 0.6, display: isOverlapping ? 'block' : 'flex' }}
        >
          {commentOnly && (
            <Comment
              width={10}
              height={10}
              style={{ display: isOverlapping ? 'block' : 'inline-block' }}
            />
          )}
          {!shift.regular && !commentOnly && (
            <Star
              width={10}
              height={10}
              style={{ display: isOverlapping ? 'block' : 'inline-block' }}
            />
          )}
          {shift.edited && (
            <Create
              width={10}
              height={10}
              style={{ display: isOverlapping ? 'block' : 'inline-block' }}
            />
          )}
          {!!shift.closed && (
            <NotInterested
              width={10}
              height={10}
              style={{ display: isOverlapping ? 'block' : 'inline-block' }}
            />
          )}
          {!!shift?.overwrite?.blockReservations && (
            <Lock
              width={10}
              height={10}
              style={{
                display: isOverlapping ? 'block' : 'inline-block',
                minWidth: 14,
              }}
            />
          )}
          {!!shift?.overwrite?.closedSpaces?.length &&
            !shift?.overwrite?.blockReservations &&
            !shift.closed && (
              <PartlyLocked
                width={10}
                height={10}
                style={{
                  display: isOverlapping ? 'block' : 'inline-block',
                  minWidth: 14,
                }}
              />
            )}
          {isShiftDone && <Done width={10} height={10} />}
          {!!shift?.additional?.eventV02?.active && (
            <LocalActivity
              width={10}
              height={10}
              style={{
                display: isOverlapping ? 'block' : 'inline-block',
                minWidth: 14,
              }}
            />
          )}
          {shift?.deliveryMethods?.includes('delivery') && (
            <Moped
              width={14}
              height={14}
              style={{
                display: isOverlapping ? 'block' : 'inline-block',
                minWidth: 14,
              }}
            />
          )}
          {shift?.deliveryMethods?.includes('pickup') && (
            <ShoppingBasket
              width={14}
              height={14}
              style={{
                display: isOverlapping ? 'block' : 'inline-block',
                fontSize: 14,
              }}
            />
          )}
          {!!shift?.advert?.active && (
            <LocalActivity
              width={10}
              height={10}
              style={{
                display: isOverlapping ? 'block' : 'inline-block',
                minWidth: 14,
              }}
            />
          )}
        </div>
      </Box>
      <Box className="lower-container">
        {/* <Typography
          className="primary-text"
          color="inherit"
          variant="text-4"
          textAlign="right"
        >
          {reservationsHandler()}
        </Typography>
        <Typography
          className="secondary-text"
          color="inherit"
          variant="text-4"
          textAlign="right"
          style={{ opacity: 0.6 }}
        >
          Ø {shift.avg}
        </Typography> */}
      </Box>
    </Box>
  );
};

export default ReservationShift;
