import { createContext, useState, useEffect } from "react";
import {
  ThemeProvider,
  createMuiTheme,
  StylesProvider,
} from "@material-ui/core/styles";
import colors from "utils/colors";

import "styles/general.scss";
import "styles/colors.scss";
import "styles/typography.scss";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";

type ThemeContextValue = {
  darkMode: boolean;
  setdarkMode: React.Dispatch<React.SetStateAction<boolean>>;
  changeFontSize: (dir: "remove" | "add") => () => void;
};

export const ThemeContext = createContext<ThemeContextValue>({
  darkMode: false,
  setdarkMode: () => {},
  changeFontSize: () => () => {},
});

function darkmodeEnabled() {
  let preferesDarkMode = true;

  if (window.matchMedia) {
    preferesDarkMode = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
  }

  if (window.localStorage && window.localStorage.getItem("darkMode") !== null) {
    preferesDarkMode = window.localStorage.getItem("darkMode") !== "false";
  }

  return preferesDarkMode;
}

const palette: { darkMode: PaletteOptions; lightMode: PaletteOptions } = {
  darkMode: {
    type: "dark",
    primary: {
      main: colors.primary,
      contrastText: colors.onPrimary,
    },
    secondary: {
      main: colors.secondary,
      contrastText: colors.onSecondary,
    },
    text: {
      primary: colors.dark.text,
      secondary: colors.dark.subdued,
      disabled: colors.dark.disabled,
      hint: colors.dark.border,
    },
    divider: colors.dark.border,
    error: {
      main: colors.error,
    },
    success: {
      main: colors.success,
    },
  },
  lightMode: {
    type: "light",
    primary: {
      main: colors.primary,
      contrastText: colors.onPrimary,
    },
    secondary: {
      main: colors.secondary,
      contrastText: colors.onSecondary,
    },
    text: {
      primary: colors.light.text,
      secondary: colors.light.subdued,
      disabled: colors.light.disabled,
      hint: colors.light.border,
    },
    divider: colors.light.border,
    error: {
      main: colors.error,
    },
    success: {
      main: colors.success,
    },
  },
};

const ThemeContextProvider = (props: any) => {
  const [darkMode, setdarkMode] = useState<boolean>(
    props.darkMode ?? darkmodeEnabled()
  );

  useEffect(() => {
    if (window.localStorage) {
      window.localStorage.setItem("darkMode", String(darkMode));
    }

    if (props.darkMode ?? darkMode) {
      document.getElementsByTagName("body")[0].classList.add("darkMode");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("darkMode");
    }
  }, [darkMode, props.darkMode]);

  const theme = createMuiTheme({
    palette: props.darkMode ?? darkMode ? palette.darkMode : palette.lightMode,
    spacing: (factor) => `${0.5 * factor}rem`,
    typography: {
      fontFamily: `"CircularStd", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif`,
      h1: {
        fontSize: "3rem",
        fontWeight: "bold",
        letterSpacing: "-0.25px",
        lineHeight: "110%",
      },
      h2: {
        fontSize: "2rem",
        fontWeight: "bold",
        letterSpacing: "-0.25px",
        lineHeight: "110%",
      },
      h3: {
        fontSize: "1.5rem",
        fontWeight: "bold",
        letterSpacing: "0px",
        lineHeight: "110%",
      },
      h4: {
        fontSize: "1.25rem",
        fontWeight: "bold",
        letterSpacing: "0px",
        lineHeight: "120%",
      },
      h5: {
        fontSize: "1rem",
        fontWeight: "bold",
        letterSpacing: "0px",
        lineHeight: "120%",
      },
      h6: {
        fontSize: "0.75rem",
        fontWeight: "bold",
        letterSpacing: "0px",
        lineHeight: "120%",
      },
      body1: {
        fontSize: "1.25rem",
        fontWeight: "normal",
        letterSpacing: "0px",
        lineHeight: "130%",
      },
      body2: {
        fontSize: "1rem",
        fontWeight: "normal",
        letterSpacing: "0px",
        lineHeight: "140%",
      },
      caption: {
        // text 6
        fontSize: "0.625rem",
        fontWeight: "bolder",
        letterSpacing: "0px",
        lineHeight: "140%",
      },
      subtitle1: {
        // text 4
        fontSize: "0.875rem",
        fontWeight: "normal",
        letterSpacing: "0px",
        lineHeight: "140%",
      },
      subtitle2: {
        // text 5
        fontSize: "0.75rem",
        fontWeight: "normal",
        letterSpacing: "0px",
        lineHeight: "140%",
      },
    },
    zIndex: {
      modal: 20000,
    },
  });

  const [fontSize, setFontSize] = useState(
    localStorage.getItem("fontSize") ?? null
  );

  const changeFontSize = (dir: "remove" | "add") => () => {
    let currentSize = fontSize ? Number(fontSize) : 16;

    if (dir === "remove") {
      currentSize = Math.max(currentSize - 1, 12);
    } else {
      currentSize = Math.min(currentSize + 1, 20);
    }

    localStorage.setItem("fontSize", String(currentSize));
    setFontSize(String(currentSize));
  };

  useEffect(() => {
    if (fontSize) {
      document.documentElement.style.fontSize = fontSize + "px";
    } else {
      document.documentElement.style.fontSize = "";
    }
  }, [fontSize]);

  return (
    <ThemeContext.Provider
      value={{
        darkMode: props.darkMode ?? darkMode,
        setdarkMode,
        changeFontSize,
      }}
    >
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
