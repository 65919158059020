import { Error } from '@material-ui/icons';
import { sample } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { db } from 'utils/firebase';

window.AudioContext =
  (window as any).AudioContext || (window as any).webkitAudioContext;
const audioContext = new AudioContext();

const visualizeAudio = (url: string, sampleSize = 40) =>
  fetch(url)
    .then((response) => response.arrayBuffer())
    .then((arrayBuffer) => audioContext.decodeAudioData(arrayBuffer))
    .then((audioBuffer) => normalizeData(filterData(audioBuffer, sampleSize)));

function exampleAudio(sampleSize = 40) {
  return Array.from(new Array(sampleSize), () => 0);
}

const filterData = (audioBuffer: AudioBuffer, sampleSize = 40) => {
  const rawData = audioBuffer.getChannelData(0); // We only need to work with one channel of data
  const samples = sampleSize; // Number of samples we want to have in our final data set
  const blockSize = Math.floor(rawData.length / samples); // the number of samples in each subdivision
  const filteredData = [];
  for (let i = 0; i < samples; i++) {
    let blockStart = blockSize * i; // the location of the first sample in the block
    let sum = 0;
    for (let j = 0; j < blockSize; j++) {
      sum = sum + Math.abs(rawData[blockStart + j]); // find the sum of all the samples in the block
    }
    filteredData.push(sum / blockSize); // divide the sum by the block size to get the average
  }
  return filteredData;
};

const normalizeData = (filteredData: number[]) => {
  const multiplier = Math.pow(Math.max(...filteredData), -1);
  return filteredData.map((n) => n * multiplier);
};

const AudioWave: React.FC<{
  url: string;
  style?: React.CSSProperties;
  height?: number;
  progress?: number;
  callSid?: string;
  sampleSize?: number;
  preLoadedAudioWave?: number[];
}> = ({
  url,
  style = {},
  height = 30,
  progress = 0,
  sampleSize = 40,
  callSid,
  preLoadedAudioWave,
}) => {
  const [wave, setwave] = useState<number[]>(exampleAudio(sampleSize));
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(false);

  useEffect(() => {
    setloading(true);
    if (!!preLoadedAudioWave) {
      setwave(preLoadedAudioWave);
    } else {
      visualizeAudio(url, sampleSize)
        .then((array) => {
          setwave(array);
          seterror(false);
          if (callSid) {
            db.collection(`calls`)
              .doc(callSid)
              .update({ 'mailbox.audioWave': array });
          }
        })
        .catch((err) => {
          console.error(err.message);
          setwave(exampleAudio());
          seterror(true);
        })
        .finally(() => {
          setloading(false);
        });
    }
  }, [url, sampleSize]);

  let unit = 100 / sampleSize;

  return (
    <div
      style={{
        flexGrow: 1,
        height,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        maxWidth: 420,
        gap: 66 / (sampleSize * 2 - 2) + '%',
        ...style,
      }}
    >
      {error && <Error color="error" />}
      {wave.map((x, i) => (
        <span
          key={i}
          id={String(i)}
          style={{
            height: x * (height - 10) + 10,
            width: unit + '%',
            borderRadius: 3,
            background: error ? 'var(--color-critical)' : '#6369D1',
            opacity: progress * sampleSize > i ? 0.7 : 0.5,
          }}
        />
      ))}
    </div>
  );
};

export default AudioWave;
