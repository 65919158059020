import React, { useEffect, useState } from "react";
import "./styles.scss";
import Modal, { Action } from "Components/Molecules/Modal";
import ShiftComment, { useShiftComment } from "../ShiftComment";
import Box from "../../../../Components/Atoms/Box";
import { Date as DateType } from "../../../../types/shifts";

export type ShiftCommentModalProps = {
  isOpen: boolean;
  onClose: () => void;
  shiftId: string;
  date: string;
  restaurantId: string;
};

const ShiftCommentModal = ({
  isOpen = true,
  onClose,
  shiftId,
  date,
  restaurantId,
}: ShiftCommentModalProps) => {
  const { onSave } = useShiftComment(restaurantId, shiftId, date);
  const ACTIONS: Action[] = [
    {
      id: "cancel",
      label: "Cancel",
      onClick: onClose,
    },
    {
      id: "submit",
      label: "Save Changes",
      variant: "primary",
      onClick: () => document.getElementById(`${shiftId}-${date}`)?.click(),
    },
  ];

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      actions={ACTIONS}
      maxWidth="sm"
      title="Shift Comment + Attachments"
      titleTranslation="common"
    >
      <Box className="shift-comment-modal-container">
        <ShiftComment
          shiftId={shiftId}
          date={date}
          restaurantId={restaurantId}
        />
      </Box>
    </Modal>
  );
};

export default ShiftCommentModal;
