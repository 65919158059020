import SiaModal from "Components/Organisms/SiaModal";
import useCalls, { ExtendedCall } from "CustomHooks/useCalls";
import useDocument from "CustomHooks/useDocument";
import useSiaRules, { CurrentRule } from "CustomHooks/useSiaRules";
import { SiaSettings, SiaShift } from "gastronaut-shared/types/helper/sia";
import React, { createContext, useState } from "react";
import { dateHelper } from "utils/helper";
import useRestaurant from "../CustomHooks/useRestaurant";

type Context = {
  openSiaModal: (className?: string) => void;
  hasSia: boolean;
  calls: ExtendedCall[];
  activeCalls: ExtendedCall[];
  hasMore: boolean;
  loading: boolean;
  error?: string;
  unseenCalls: number;
  nextPage: () => void;
  currentCall: ExtendedCall | null;
  onChangeCurrentCall: (
    id: string | null,
    page?: "info" | "qr-code" | "conversation"
  ) => void;
  currentCallPage: "info" | "qr-code" | "conversation";
  setcurrentCallPage: React.Dispatch<
    React.SetStateAction<"info" | "qr-code" | "conversation">
  >;
  siaRules: {
    siaShifts: SiaShift[];
    siaSettings: SiaSettings | null;
    currentRule?: CurrentRule;
    loading: boolean;
  };
  callStatistics: null | {
    actionRequired: number;
    calls: number;
    reservations: number;
  };
};

export const SiaContext = createContext<Context>({
  openSiaModal: () => {},
  hasSia: false,
  hasMore: false,
  loading: false,
  nextPage: () => {},
  calls: [],
  activeCalls: [],
  currentCall: null,
  onChangeCurrentCall: () => {},
  setcurrentCallPage: () => {},
  currentCallPage: "info",
  unseenCalls: 0,
  siaRules: {
    siaShifts: [],
    siaSettings: null,
    currentRule: {},
    loading: true,
  },
  callStatistics: null,
});

const SiaContextProvider: React.FC<{}> = ({ children }) => {
  const { restaurantId, hasSia } = useRestaurant();
  const [open, setopen] = useState(false);
  const [siaModalClass, setSiaModalClass] = useState("");

  const openSiaModal = (className = "") => {
    if (hasSia) {
      setopen(true);
      setSiaModalClass(className);
    }
  };

  const onClose = () => setopen(false);

  const {
    calls,
    loading,
    nextPage,
    hasMore,
    error,
    currentCall,
    onChangeCurrentCall,
    currentCallPage,
    setcurrentCallPage,
    activeCalls,
    unseenCalls,
  } = useCalls(restaurantId, hasSia);

  const siaRules = useSiaRules(restaurantId);

  const [callStatistics] = useDocument<{
    statistics: { actionRequired: number; calls: number; reservations: number };
  }>("callStatistics", `${restaurantId}-${dateHelper()}`);

  return (
    <SiaContext.Provider
      value={{
        openSiaModal,
        hasSia,
        calls,
        loading,
        nextPage,
        hasMore,
        error,
        currentCall,
        onChangeCurrentCall,
        setcurrentCallPage,
        currentCallPage,
        siaRules,
        callStatistics: callStatistics.data?.statistics ?? null,
        activeCalls,
        unseenCalls,
      }}
    >
      {hasSia && open && (
        <SiaModal onClose={onClose} className={siaModalClass} />
      )}
      {children}
    </SiaContext.Provider>
  );
};

export default SiaContextProvider;
