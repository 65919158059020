import { IconButton } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import ReservationIcon from "Components/Atoms/Icons/ReservationIcon";
// import IconButton from 'Components/Atoms/IconButton';
import Typography from "Components/Atoms/Typography";
import { CallWithId } from "CustomHooks/useCalls";
import useSiaContext from "CustomHooks/useSiaContext";
import { SiaRule } from "CustomHooks/useSiaRules";
import { ConnectTo } from "gastronaut-shared/types/helper/sia";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Slider from "./Slider";

const ActiveCallCard: React.FC<{ call: CallWithId; onClick: () => void }> = ({
  call,
  onClick,
}) => {
  const history = useHistory();

  return (
    <div className="currentRuleCard" onClick={onClick}>
      <div>
        <Typography block color="inherit" variant="text-4" className="mg-bt-sm">
          Aktueller Anruf
        </Typography>
        <Typography block color="inherit" variant="h4">
          {call?.guest?.name ?? call.callerId}
        </Typography>
      </div>
      <div className="flex center">
        {true && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              history.push(
                `${call.restaurantId}/reservations/${call.date}/tablePlan?newReservation=1`
              );
            }}
          >
            <ReservationIcon style={{ color: "#fff" }} />
          </IconButton>
        )}
      </div>
    </div>
  );
};

function ruleToString(
  siaRule: SiaRule,
  phoneBook: Record<string, { name: string; number: string }> = {}
) {
  switch (siaRule.connectTo) {
    case ConnectTo.ASSISTANT:
      return "Telefonassistent SIA";
    case ConnectTo.REDIRECT:
      return (
        "Weiterleiten an " + phoneBook?.[siaRule.value]?.name ?? siaRule.value
      );
    case ConnectTo.TEXT_MESSAGE:
      return "Ansage";
    case ConnectTo.VOICE_MAIL:
      return "Ansage mit Mailbox";
    case ConnectTo.VOICE_MESSAGE:
      return "Ansage durch Aufnahme";
    default:
      return "";
  }
}

const CurrentRuleCard: React.FC<{
  hasActiveCalls: boolean;
  onOpenSettings: () => void;
}> = ({ hasActiveCalls, onOpenSettings }) => {
  const { siaRules } = useSiaContext();

  const siaIsActive =
    siaRules.currentRule?.incoming?.connectTo === ConnectTo.ASSISTANT;

  return (
    <div className="currentRuleCard">
      <div>
        <Typography block color="inherit" variant="text-4" className="mg-bt-sm">
          Aktuelle Regel
        </Typography>
        <Typography block color="inherit" variant="h4">
          {!!siaRules.currentRule?.incoming &&
            ruleToString(
              siaRules.currentRule?.incoming,
              siaRules.siaSettings?.phoneBook
            )}
        </Typography>
        {siaIsActive && (
          <Typography
            block
            color="inherit"
            variant="text-4"
            className="mg-bt-sms"
          >
            Bei Nr. 2:{" "}
            {!!siaRules.currentRule?.contactPerson &&
              ruleToString(
                siaRules?.currentRule?.contactPerson,
                siaRules.siaSettings?.phoneBook
              )}
          </Typography>
        )}
      </div>
      <div className="flex center">
        {false && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onOpenSettings();
            }}
          >
            <Edit style={{ color: "#fff" }} />
          </IconButton>
        )}
      </div>
    </div>
  );
};

const SiaModalCard: React.FC<{ onOpenSettings: () => void }> = ({
  onOpenSettings,
}) => {
  const { activeCalls, onChangeCurrentCall } = useSiaContext();

  return (
    <div className="siaModalCard">
      <Slider
        slides={[
          ...activeCalls.map((call) => (
            <ActiveCallCard
              call={call}
              onClick={() => onChangeCurrentCall(call.id)}
            />
          )),
          <CurrentRuleCard
            onOpenSettings={onOpenSettings}
            hasActiveCalls={activeCalls.length > 0}
          />,
        ]}
      />
    </div>
  );
};

export default SiaModalCard;
