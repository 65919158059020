import Box from "Components/Atoms/Box";
import Typography from "Components/Atoms/Typography";
import React from "react";
import { Reservation } from "types/reservations";
import { toCurrencyString, toEuropeanDate } from "utils/helper";

const NoShowFee: React.FC<Reservation["noShowFee"]> = ({
  description = "",
  ts,
  amount = 0,
}) => {
  return (
    <div>
      <Box style={{ padding: "16px 16px 0 16px" }}>
        <Typography
          className="colon"
          variant="text-3"
          translation="reservations"
        >
          No Show Gebühr
        </Typography>
      </Box>
      <Box className="space-between" style={{ padding: "8px 16px 0 16px" }}>
        <Typography variant="text-3" translation="reservations">
          Price paid
        </Typography>
        <Typography variant="text-2" color="subdued" translation={null}>
          {toCurrencyString(amount)}
        </Typography>
      </Box>
      <Box className="space-between" style={{ padding: "8px 16px 0 16px" }}>
        <Typography variant="text-3" translation="reservations">
          Erstellt am:
        </Typography>
        <Typography variant="text-2" color="subdued" translation={null}>
          {new Date(ts).toLocaleString("de-DE")}
        </Typography>
      </Box>
      <Box
        style={{
          padding: "8px 16px 16px 16px",
          borderBottom: "1px solid var(--color-border)",
        }}
      >
        <Typography
          className="colon"
          variant="text-3"
          translation="reservations"
          component="p"
        >
          Beschreibung
        </Typography>
        <Typography variant="text-2" color="subdued" translation={null}>
          {description}
        </Typography>
      </Box>
    </div>
  );
};

export default NoShowFee;
