import { Translation } from "utils/types";
import { Meal } from "./menu";
import { Guest } from "./reservations";

export interface CartItem {
  id: string;
  category?: string;
  amount: number;
  options?: null | { id: string; value: string[] | string }[];
  comment?: string;
  meal?: Meal;
}

export enum EventTypes {
  DINNER_TICKET = "dinner-ticket",
  EXPERIENCE = "experience",
  MINIMUM_CONSUMPTION = "minimum-consumption",
  PRE_ORDER = "pre-order",
}

export declare enum PaymentStatus {
  open = "open",
  canceled = "canceled",
  pending = "pending",
  authorized = "authorized",
  expired = "expired",
  failed = "failed",
  paid = "paid",
}

export enum PaidVia {
  STRIPE = "stripe",
  MOLLIE = "mollie",
  CASH = "cash",
}

export interface Event {
  id: string;
  active: boolean;
  title: Translation<string>;
  description: Translation<string>;
  type: EventTypes;
  amount?: number;
  img?: string;
  blockTableFor?: {
    value: number;
    unit: "minutes" | "hours" | "days";
  }; // shows how long the table is blocked until it was paid
  minInAdvance?: {
    value: number;
    unit: "minutes" | "hours" | "days";
  }; // shows how long this ticket can be booked in advance
  confirmation_email?: {
    translations: Translation<{
      title: string;
      description: string;
      button?: string;
    }>;
    image?: string;
    button?: string;
  };
  reminder_email?: {
    translations: Translation<{
      title: string;
      description: string;
      button?: string;
    }>;
    image?: string;
    button?: string;
    sendBefore: {
      value: number;
      unit: "minutes" | "hours" | "days";
    }; // how long before the event should this emal be send
  };
  minimumConsumption?: number | null;
  preOrder?: {
    menu?: null | string;
    enforce: boolean;
    oneDishEach?: boolean;
  }; // will be added later
  optional?: boolean; // optional ticket?
  rules?: {
    minGuests?: number;
    maxGuests?: number;
    daysTill?: number;
    excludeRegular?: boolean;
    excludeVip?: boolean;
  };
  maxGuests?: number; // maximum number of guests that can buy this ticket
  options?: {
    id: string;
    translation: Translation<{ title: string; description: string }>;
    internalName: string;
    amount?: number;
    tables?: string[];
    img?: string;
    type: "perGroup" | "perPerson";
    limited?: number;
  }[];
  optionalAddOns?: {
    id: string;
    translation: Translation<{ title: string; description: string }>;
    internalName: string;
    amount?: number;
    standalonePrice?: number;
    tables?: string[];
    img?: string;
    type: "perGroup" | "perPerson";
    limited?: number;
  }[];
}

export interface Ticket {
  id?: string;
  eventId: string;
  customId: string;
  event: Event;
  date: string;
  restaurant: string;
  reservation: string;
  amount: number;
  guests?: number;
  paid: boolean;
  payments: {
    invitationId?: string;
    amount: number;
    guest?: Partial<Guest>;
    paidVia: PaidVia;
    paymentId?: string;
    createdAt?: number;
    cart?: CartItem[];
    status: PaymentStatus;
    options?: Record<string, number>;
    optionalAddOns?: Record<string, number>;
    paymentLink?: string;
    testmode: boolean;
  }[];
  invitations?: {
    id: string;
    name: string;
    email: string;
  }[];
  status: "missing_payments" | "processing" | "failed" | "succedded";
  options?: Record<string, number>;
  optionalAddOns?: Record<string, number>;
  createdAt?: number;
}
