import { PaymentMethod, PaymentStatus, RefundStatus } from '@mollie/api-client';
import {
  Amount,
  HistoricPaymentMethod,
} from '@mollie/api-client/dist/types/src/data/global';
import { PaidVia } from 'gastronaut-shared/types/documents/restaurants';
import { Translation } from 'App/Experiences/types/addOn';

export enum ERROR_CODES {
  VOUCHER_NOT_PAID = 'VOUCHER_NOT_PAID',
  VOUCHER_EXPIRED = 'VOUCHER_EXPIRED',
  VOUCHER_NOT_VALID = 'VOUCHER_NOT_VALID',
  VOUCHER_NOT_ENOUGH_REMAINING_VALUE = 'VOUCHER_NOT_ENOUGH_REMAINING_VALUE',
  VOUCHER_ALREADY_REDEEMED = 'VOUCHER_ALREADY_REDEEMED',
  VOUCHER_CURRENCY_MISMATCH = 'VOUCHER_CURRENCY_MISMATCH',
  VOUCHER_NOT_FOUND = 'VOUCHER_NOT_FOUND',
  PAYMENT_NOT_FOUND = 'PAYMENT_NOT_FOUND',
  PAYMENT_ALREADY_REFUNDED = 'PAYMENT_ALREADY_REFUNDED',
  PAYMENT_IS_NOT_PAID = 'PAYMENT_IS_NOT_PAID',
  PAYMENT_NOT_ENOUGH_REMAINING_VALUE = 'PAYMENT_NOT_ENOUGH_REMAINING_VALUE',
  ID_IS_INVALID = 'ID_IS_INVALID',
  AMOUNT_IS_INVALID = 'AMOUNT_IS_INVALID',
}

export type Expiration =
  | { type: 'fixed'; date: string }
  | {
      type: 'relative';
      days?: number;
      months?: number;
      years?: number;
    }
  | { type: 'gesetzlich_vorgeschrieben'; date?: Date };

export type Address = {
  line1: string;
  line2: string;
  postal_code: string;
  city: string;
  country?: string; // DE
};

export type Shipping = {
  id: string;
  active: boolean;
  position?: number;
  amount: Amount;
  vatRate: string; // 19.00
  needsAddress: boolean;
  title: string;
  description: string;
  translations?: Translation<{
    title?: string;
    description?: string;
  }>[];
  image?: string;
  default?: boolean;
  internalNote?: string;
  stati?: string[];
  hasFulfillment?: boolean;
}; // ???

type Purpose = {
  type: 'ticket' | 'experience' | 'reservation';
  id: string;
  date?: string;
}; /// ???

export type Guest = {
  id?: string;
  name: string;
  company?: string;
  email: string;
  phone: string;
  address?: Address;
  billingAddress?: Address;
};

interface CreateParametersBase {
  restaurantId: string;
  code?: string;
  amount: Amount;

  message: string;

  note: string;
  design?: string;

  isPresent?: boolean;
  giftee?: Guest; // @TODO

  guest: Guest;

  shippingId?: string;

  templateId: string;

  options?: unknown;

  createdBy: string;
}

export type CreateMollieParameters = CreateParametersBase & {
  type: 'mollie';
  method?: PaymentMethod | PaymentMethod[];
  redirectUrl: string;
  phoneNumber: string;
};

export type CreateCashParameters = CreateParametersBase & {
  type: 'cash';
  reference: string;
  uid: string;
  media?: string;
};

export type CreateParameters = CreateMollieParameters | CreateCashParameters;

export type GetCheckoutParameters = {
  restaurantId: string;
  amount: Amount;
  shippingId?: string;
  templateId: string;
  options?: unknown;
  type: 'mollie' | 'cash';
};

export type MollieVoucherMetadata = Omit<CreateMollieParameters, 'type'> & {
  shipping?: Shipping;
  template: VoucherTemplate;
  type: 'voucherV02';
  service_fee?: number;
  redeems?: Redeem[];
  expiresAt: string;
};

export interface GetParameters {
  restaurantId: string;
  id: string;
  nullWhenNotFound?: boolean;
}

export interface RedeemParameters {
  restaurantId: string;
  id: string;
  amount: Amount;
  description: string;
  createdBy: string;
}

export interface RefundParameters {
  restaurantId: string;
  id: string;
  amount: Amount;
  paymentId: string;
  description: string;
  refundServiceFee?: boolean;
  createdBy: string;
  testmode: boolean;
}

export interface VoucherData {
  restaurantId: string;
  code: string;

  amount: Amount;

  message: string;

  refunds?: Refund[];
  payments: Payment[];
  redeems?: Redeem[];

  // guest facing
  note: string;

  design?: string;
  templateId: string;

  template?: {
    title: string;
    description: string;
    image: string;
    // @TODO AddOnOrders
  };

  oldId?: string;

  guest: Guest;
  shipping?: Shipping;
  purpose?: Purpose;

  isPresent?: boolean;
  giftee?: Guest; // @TODO

  alternativeCode?: string;

  createdAt: number;
  expiresAt: string;
  updatedAt?: number;

  currency: string; // EUR

  createdBy: string;
  updatedBy?: string;

  isCanceled?: boolean;

  isPaid?: boolean;
  isValid?: boolean;

  paidAmount: Amount;
  remainingAmount: Amount;
  refundedAmount: Amount;

  testmode: boolean;
}

export type Refund = {
  id: string;

  voucherId: string;

  amount: Amount;
  status: RefundStatus;
  createdAt: string;
  description: string;
  paymentId: string;
  createdBy: string;
  type: 'cash' | 'mollie';

  // mollie
  refundId?: string;

  testmode: boolean;
};

export type Payment = {
  id?: string;

  voucherId: string;
  restaurantId: string;

  hidden?: boolean;
  email?: string;

  amount: Amount;

  gastronautFee?: Amount;

  feePaidByGuest?: Amount;

  description: string;

  type: 'cash' | 'mollie' | 'stripe';

  method: PaymentMethod | HistoricPaymentMethod | 'cash' | 'other';

  // mollie
  paymentId?: string;

  // cash
  reference?: string;
  media?: string;

  // stripe
  sessionId?: string;

  status: PaymentStatus;
  testmode: boolean;

  createdAt: string;
  createdBy: string;
};

export enum RedeemStatus {
  pending = 'pending',
  success = 'success',
  failed = 'failed',
}

export type Redeem = {
  id: string;

  type: 'ticket' | 'experience' | 'addOnOrder' | 'local' | 'shipping' | 'other';

  voucherId: string;
  restaurantId: string;

  cancelation?: {
    reason: string;
    createdAt: string;
    createdBy: string;
  };

  amount: Amount;

  description: string;

  media?: string;

  guest?: {
    id?: string;
    name?: string;
    email?: string;
    phone?: string;
  };

  vatRate?: string;

  items?: {
    name: string;
    quantity: number;
    totalAmount: {
      currency: string;
      value: string;
    };
    unitPrice: {
      currency: string;
      value: string;
    };
    vatAmount: number;
    vatRate: string;
    type?: 'regular' | 'delivery_fee' | 'fee' | 'tip';
    sku?: string;
  }[];

  purpose?: Purpose;

  status: RedeemStatus;
  testmode: boolean;

  createdAt: string;
  createdBy: string;
};

export enum VoucherTemplateType {
  free = 'free',
  fixed = 'fixed',
  experience = 'experience',
}

export type FreeVoucher = {
  type: VoucherTemplateType.free;
  minAmount: Amount;
  maxAmount: Amount;
  step?: number;
};

export type FixedVoucher = {
  type: VoucherTemplateType.fixed;
  amount: Amount;
};

export type ExperienceVoucher = {
  type: VoucherTemplateType.experience;
  amount: Amount;
  options: unknown[];

  availableAddOns?: {
    id: string;
  }[];
};

export type VoucherTemplate = {
  id?: string;

  active: boolean;

  purpose?: Purpose;

  shippingOptions: Shipping[];

  position?: number;

  title: string;
  description: string;
  image: string | null;

  translations?: Translation<{
    title?: string;
    description?: string;
  }>[];

  designs?: {
    id: string;
    image: string;
  }[];

  background_image?: string;

  askForTip?: boolean;

  vatRate?: string; // 19

  chargeGuestServiceFee?: boolean;

  expiration?: Expiration;

  createdAt: number;
  createdBy: string;

  updatedAt?: number;
  updatedBy?: string;
} & (FreeVoucher | FixedVoucher | ExperienceVoucher);

export type OldVoucher = {
  id: string;
  code: string;
  voucherNumber: string;
  customer: {
    id: string;
    name: string;
    email: string;
    phone?: string;
    address?: {
      line1: string;
      line2: string;
      postal_code: string;
      city: string;
      country: string;
    };
  };
  createdAt: number;
  createdBy: string;
  linkedCashPayments: {
    id: string;
    createdBy: string;
    uid: string;
    createdAt: number;
    comment?: string;
    billNumber: string;
    valid: boolean;
    value: number;
  }[];
  linkedPayableItems: {
    id: string;
    createdAt: number;
    billNumber: string;
    product: 'takeAway' | 'merchandise' | 'orderInHouse';
    valid: boolean;
    value: number;
  }[];
  livemode: boolean;
  remainingValue: number; // in euro cents
  sessionId?: string;
  paymentIntent?: string;
  status: 'processing' | 'canceled' | 'none' | 'succeeded';
  paymentId: string;
  paidVia?: PaidVia;
  type: string;
  valid: boolean;
  value: number;
  design?: string;
  paymentMethod: string;
  billNumber?: string;
  comment?: string;
  message?: string;
  paidAmount: number;
};

export enum VoucherActions {
  redeem = 'redeem',
  cancelRedeem = 'cancelRedeem',
  refundPayment = 'refundPayment',
  invalidate = 'invalidate',
  revalidate = 'revalidate',
  addPayment = 'addPayment',
  downloadPdf = 'downloadPdf',
  sendPerEmail = 'sendPerEmail',
  editRedeem = 'editRedeem',
  //   dettachPurpose = 'detachPurpose',
  //   attachPurpose = 'attachPurpose',
  //   editInfos = 'editInfos',
}

export type VoucherActionParameters =
  | {
      action: VoucherActions.redeem;
      parameters: {
        code: string;
        amount: Amount;
        description: string;
        vatRate: string;
        media?: string;
        createdBy: string;
        createdAt?: string;
        maxAmount?: Amount;
        items?: Redeem['items'];
      };
    }
  | {
      action: VoucherActions.invalidate | VoucherActions.revalidate;
      parameters: {
        createdBy: string;
        code: string;
        reason: string;
      };
    }
  | {
      action: VoucherActions.cancelRedeem;
      parameters: {
        code: string;
        redeemId: string;
        reason: string;
        createdBy: string;
      };
    }
  | {
      action: VoucherActions.refundPayment;
      parameters: {
        code: string;
        paymentId?: string;
        amount: Amount;
        description: string;
        refundServiceFee?: boolean;
        createdBy: string;
        testmode: boolean;
        maxAmount?: Amount;
      };
    }
  | {
      action: VoucherActions.addPayment;
      parameters: Payment;
    }
  | {
      action: VoucherActions.downloadPdf;
      parameters: {
        design?: string;
        voucherId: string;
      };
    }
  | {
      action: VoucherActions.sendPerEmail;
      parameters: {
        email: string;
      };
    }
  | {
      action: VoucherActions.editRedeem;
      parameters: {
        code: string;
        redeemId: string;
        amount: Amount;
        description: string;
        vatRate: string;
        media?: string;
        updatedBy: string;
        items?: Redeem['items'];
      };
    };

export type NewTemplateParams =
  | {
      creationType: 'scratch';
      type: VoucherTemplateType;
      parameters: {
        step?: number;
      };
    }
  | {
      creationType: 'example';
      type: VoucherTemplateType;
      parameters: {
        exampleId: string;
      };
    }
  | {
      creationType: 'ai';
      type: VoucherTemplateType;
      parameters: {
        prompt: string;
      };
    };

export type Fulfillment = {
  voucherId: string;
  restaurantId: string;
  shipping: Shipping;
  guest: Guest;
  status: string;
  createdAt: string;
  createdBy: string;
  testmode: boolean;
};
