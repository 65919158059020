import React, { useState, useContext } from "react";
import Modal from "Components/Molecules/Modal";
import TextField from "Components/Atoms/TextField";
import { ImageContext } from "Contexts/ImageContext";

type NewFolderModalProps = {
  open: boolean;
  onClose: () => void;
};
const NewFolderModal = ({ open, onClose }: NewFolderModalProps) => {
  const [newFolder, setnewFolder] = useState<null | string>(null);
  const { onAddFolder } = useContext(ImageContext);

  return (
    <Modal
      open={open}
      title="Add a new Folder"
      onClose={onClose}
      actions={[
        {
          id: "cancel",
          label: "Cancel",
          onClick: () => onClose(),
        },
        {
          id: "submit",
          label: "Submit",
          onClick: () => {
            if (newFolder) {
              onAddFolder(newFolder);
              onClose();
            }
          },
          variant: "primary",
        },
      ]}
    >
      <TextField
        fullWidth
        id="newFolderName"
        label="Add New Folder"
        labelTranslation="common"
        value={newFolder}
        style={{ margin: "8px" }}
        name="customAttr"
        onChange={(e) => setnewFolder(e.target.value)}
      />
    </Modal>
  );
};

export default NewFolderModal;
