import React, { useMemo } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import { Reservation } from 'types/reservations';
import useDocument from 'CustomHooks/useDocument';
import { EventTypes, Ticket } from 'types/event';
import Typography from 'Components/Atoms/Typography';
import { toCurrencyString } from 'utils/helper';

export type TicketInformationV02Props = {
  reservation: Reservation;
};

const TicketInformationV02: React.FC<TicketInformationV02Props> = ({
  reservation,
}) => {
  const [ticket] = useDocument<Ticket>(
    `/ticketsV02`,
    reservation.ticketId ?? ''
  );

  console.log({ ticket });

  const optionCards = useMemo(() => {
    let options: { id: string; label: string; value: number }[] = [];

    if (!ticket.data) return options;

    if (ticket.data.event.type !== EventTypes.DINNER_TICKET) {
      return options;
    }

    const nameObject = [
      ...(ticket.data.event?.options ?? []),
      ...(ticket.data.event?.optionalAddOns ?? []),
    ].reduce((acc, cV) => {
      acc[cV.id] = cV.internalName;
      return acc;
    }, {} as Record<string, string>);

    Object.entries(ticket.data.options ?? {}).forEach(([key, value]) => {
      if (value > 0) {
        options.push({
          id: key,
          label: nameObject[key] || key,
          value,
        });
      }
    });

    Object.entries(ticket.data.optionalAddOns ?? {}).forEach(([key, value]) => {
      if (value > 0) {
        options.push({
          id: key,
          label: nameObject[key] || key,
          value,
        });
      }
    });

    return options;
  }, [ticket, reservation]);

  if (!ticket.data) {
    return <></>;
  }

  const amount = ticket.data.amount;

  const paymentStatus = ticket.data.payments?.[0]?.status ?? null;

  return (
    <div>
      <Box style={{ padding: '16px 16px 0 16px' }}>
        <Typography
          className="colon"
          variant="text-3"
          translation="reservations"
        >
          Ticket-Infos
        </Typography>
      </Box>
      <Box className="space-between" style={{ padding: '8px 16px 0 16px' }}>
        <Typography variant="text-3" translation="reservations">
          Event Name
        </Typography>
        <Typography variant="text-2" color="subdued" translation={null}>
          {ticket.data.event.title?.de ?? ''}
        </Typography>
      </Box>
      <Box className="space-between" style={{ padding: '8px 16px 0px 16px' }}>
        <Typography variant="text-3" translation="reservations">
          Price paid
        </Typography>
        <Typography variant="text-2" color="subdued" translation={null}>
          {toCurrencyString(amount, '€', false, 1)}
        </Typography>
      </Box>
      <Box className="space-between reservation-field">
        <Typography variant="text-3" translation="reservations">
          Payment Status
        </Typography>
        <Typography
          variant="text-2"
          translation="reservations"
          color={
            paymentStatus !== 'pending' && paymentStatus !== 'paid'
              ? 'error'
              : 'subdued'
          }
        >
          {paymentStatus ?? ''}
        </Typography>
      </Box>
      {!!optionCards.length && (
        <Box style={{ padding: '16px 16px 0 16px' }}>
          <Typography
            className="colon"
            variant="text-3"
            translation="reservations"
          >
            Options
          </Typography>
        </Box>
      )}
      {optionCards.map((o) => (
        <Box key={o.id} className="space-between reservation-field">
          <Typography variant="text-3" translation={null}>
            {o.label}
          </Typography>
          <Typography variant="text-2" color="subdued" translation={null}>
            {o.value}
          </Typography>
        </Box>
      ))}
    </div>
  );
};

export default TicketInformationV02;
