import React, { useState } from 'react';
import '../styles.scss';
import { ContextAction, ListConfigHeader } from '../index';
import TableRow from '@material-ui/core/TableRow';
import MaterialTableCell from '@material-ui/core/TableCell';
import Typography from '../../../Atoms/Typography';
import MaterialIconButton from '../../../Atoms/IconButton';
import MaterialCheckbox from '../../../Atoms/Checkbox';
import Tooltip from '../../../Atoms/Tooltip';
import { DragHandle } from '@material-ui/icons';

const TableCell = React.memo(MaterialTableCell);
const Checkbox = React.memo(MaterialCheckbox);
const IconButton = React.memo(MaterialIconButton);
interface TableRowProps<DataType> {
  headers: ListConfigHeader<DataType>[];
  data: DataType;
  filteredContextActions?: ContextAction<DataType>[];
  selected?: boolean;
  selectable?: boolean;
  onSelect?: (id: string) => void;
  id: string;
  dragHandleProps?: any;
  rowHeight: number;
  draggable?: boolean;
  extraCell?: boolean;
  useFallback?: boolean;
  primaryCheckboxes?: boolean;
  onRowClick?: (id: string) => void;
  style?: React.CSSProperties;
}

const GRow = React.memo(
  React.forwardRef(
    (
      {
        headers,
        id,
        data,
        filteredContextActions,
        selectable,
        selected,
        onSelect,
        rowHeight,
        dragHandleProps = {},
        draggable = false,
        extraCell = false,
        useFallback = false,
        primaryCheckboxes = false,
        onRowClick,
        style,
        ...props
      }: TableRowProps<any>,
      ref: React.ForwardedRef<HTMLTableRowElement>
    ) => {
      // function selectHandler(event: React.ChangeEvent<HTMLInputElement>) {
      //   if (setSelectedIds) {
      //     if (event.target.checked) {
      //       setSelectedIds(prev => [...prev, (data as any).id]);
      //     } else {
      //       setSelectedIds(prev => prev.filter(x => x !== (data as any).id));
      //     }
      //   }
      // }

      const [activeFields, setactiveFields] = useState<string[]>([]);

      return (
        <TableRow
          style={{
            height: rowHeight,
            cursor: onRowClick ? 'pointer' : undefined,
            ...style,
            backgroundColor: 'transparent',
          }}
          ref={ref}
          id={id}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onRowClick?.(id);
          }}
          {...props}
          key={id}
        >
          {draggable && (
            <td className="dragHandle" {...dragHandleProps}>
              <IconButton size="small" color="subdued" style={{ padding: 0 }}>
                <DragHandle />
              </IconButton>
            </td>
          )}
          {selectable && (
            <TableCell height={rowHeight} color="inherit" key={id}>
              <Checkbox
                color={primaryCheckboxes ? 'primary' : 'secondary'}
                checked={selected}
                onChange={() => onSelect?.(data.id)}
              />
            </TableCell>
          )}
          {/*{headers.length >= 6 && */}
          {/*filteredContextActions && (*/}
          {/*  <TableCell className="context-action-cell">*/}
          {/*    {filteredContextActions?.map(*/}
          {/*      (action: ContextAction<any>, index) =>*/}
          {/*        action.showCondition &&*/}
          {/*        (action.render ? (*/}
          {/*          <Tooltip*/}
          {/*            content={*/}
          {/*              <Typography*/}
          {/*                variant="text-3"*/}
          {/*                translation={action.tooltipContentTranslation}*/}
          {/*              >*/}
          {/*                {action.tooltipContent ?? "Action"}*/}
          {/*              </Typography>*/}
          {/*            }*/}
          {/*            key={index}*/}
          {/*          >*/}
          {/*            {action.render(data)}*/}
          {/*          </Tooltip>*/}
          {/*        ) : (*/}
          {/*          <Tooltip*/}
          {/*            content={*/}
          {/*              <Typography*/}
          {/*                variant="text-3"*/}
          {/*                translation={action.tooltipContentTranslation}*/}
          {/*              >*/}
          {/*                {action.tooltipContent ?? "Action"}*/}
          {/*              </Typography>*/}
          {/*            }*/}
          {/*            key={index}*/}
          {/*          >*/}
          {/*            <div className="action-container">*/}
          {/*              <IconButton*/}
          {/*                onClick={() => action.onClick(data)}*/}
          {/*                size="small"*/}
          {/*                style={{ color: action.iconColor }}*/}
          {/*              >*/}
          {/*                {action.icon}*/}
          {/*              </IconButton>*/}
          {/*            </div>*/}
          {/*          </Tooltip>*/}
          {/*        ))*/}
          {/*    )}*/}
          {/*  </TableCell>*/}
          {/*)}*/}
          {headers.map((hk, index) =>
            hk.displayFunction ? (
              <TableCell
                className="table-cell-container"
                key={hk.field}
                height={rowHeight}
                color="inherit"
                style={{
                  ...(hk.style ?? {}),
                  backgroundColor: style?.backgroundColor,
                  maxWidth: hk.maxWidth,
                }}
                onClick={() => setactiveFields((cV) => [...cV, hk.field])}
              >
                <Typography variant="text-3" color="textPrimary">
                  {!useFallback ||
                  activeFields.includes(hk.field) ||
                  !hk?.displayFallback
                    ? hk.displayFunction(data, index)
                    : hk?.displayFallback?.(data) ?? data[hk.field] ?? ''}
                </Typography>
              </TableCell>
            ) : (
              <TableCell
                className="table-cell-container"
                key={hk.field}
                height={rowHeight}
                color="inherit"
                style={{
                  backgroundColor: style?.backgroundColor,
                  ...(hk.style ?? {}),
                  maxWidth: hk.maxWidth,
                }}
              >
                <Typography
                  variant="text-3"
                  color="textPrimary"
                  translation={null}
                >
                  {data[hk.field]}
                </Typography>
              </TableCell>
            )
          )}
          {filteredContextActions && filteredContextActions.length !== 0 && (
            <TableCell
              className="context-action-cell"
              style={{
                touchAction: 'none',
                zIndex: 2,
                height: 'auto',
                justifyContent: 'flex-start',
                gap: 4,
                minHeight: rowHeight + 8,
                backgroundColor: style?.backgroundColor,
              }}
              height={rowHeight}
            >
              {filteredContextActions?.map(
                (action: ContextAction<any>, index) =>
                  action.showCondition &&
                  (action.render ? (
                    <Tooltip
                      content={
                        <Typography
                          variant="text-3"
                          translation={action.tooltipContentTranslation}
                        >
                          {action.tooltipContent ?? 'Action'}
                        </Typography>
                      }
                      key={index}
                    >
                      {action.render(data)}
                    </Tooltip>
                  ) : (
                    <Tooltip
                      content={
                        <Typography
                          variant="text-3"
                          translation={action.tooltipContentTranslation}
                        >
                          {action.tooltipContent ?? 'Action'}
                        </Typography>
                      }
                      key={index}
                    >
                      <div className="action-container">
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            action.onClick(data);
                          }}
                          size="small"
                          style={{
                            color: (typeof action.iconColor === 'function'
                              ? action.iconColor(data)
                              : action.iconColor) as any,
                          }}
                        >
                          {action.icon}
                        </IconButton>
                      </div>
                    </Tooltip>
                  ))
              )}
            </TableCell>
          )}
          {extraCell && <TableCell height={rowHeight} />}
        </TableRow>
      );
    }
  )
);

export default GRow;
