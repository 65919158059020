import React from "react";
import Typography from "Components/Atoms/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import { noop } from "utils/helper";
import { Modify } from "utils/types";
import { Tab as TabType } from "..";
import IconButton from "../../IconButton";
import { Close } from "@material-ui/icons";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "../styles.scss";

const getColor = (active = false, disabled = false, variant = "1") => {
  if (variant === "2" && !disabled) {
    return "textPrimary";
  } else if (active) {
    return "primary";
  } else if (disabled) {
    return "disabled";
  } else {
    return "subdued";
  }
};

type TabProbs = {
  variant: "1" | "2";
  active: boolean;
  onClick: () => void;
  onCloseClick?: (id: string) => void;
};

const Tab = ({
  id = "",
  variant = "1",
  active = false,
  disabled = false,
  onClick = noop,
  onCloseClick = noop,
  translation = "common",
  label = "",
  badge = 0,
  cancelable = false,
  ...props
}: Modify<TabType, TabProbs>) => {
  const color = getColor(active, disabled, variant);
  const isMobile = useMediaQuery("(max-width:450px)");

  return (
    <ButtonBase
      {...props}
      className={`tab variant-${variant} ${active ? "active" : ""}`}
      disabled={disabled}
      onClick={onClick}
      id={String(id)}
    >
      <span className="tab-inner">
        <Typography
          variant={isMobile ? "text-2" : "text-3"}
          color={color}
          translation={translation}
        >
          {label}
        </Typography>
      </span>
      {!!badge && <span className="badge">{badge}</span>}
      {!!cancelable && (
        <IconButton size="x-small" className="x-icon">
          <Close
            onClick={(event) => {
              event.stopPropagation();
              onCloseClick(String(id));
            }}
          />
        </IconButton>
      )}
      {active && <span className="active-slider" />}
    </ButtonBase>
  );
};

export default Tab;
