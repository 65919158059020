import React from "react";
import Box from "Components/Atoms/Box";
import Typography from "Components/Atoms/Typography";
import { Event } from "types/calendar";
import Checkbox from "Components/Atoms/Checkbox";
import TextField from "Components/Atoms/TextField";
import useRestaurant from "CustomHooks/useRestaurant";

type EventRulesProps = {
  rules: Event["rules"];
  setEventState: React.Dispatch<React.SetStateAction<Event | null>>;
  eventState: Event | null;
};

const EventRules = ({
  rules = {},
  setEventState,
  eventState,
}: EventRulesProps) => {
  const { reservationSettings } = useRestaurant();

  const occassions = reservationSettings?.occassions ?? [];

  const handleChecks = (
    check:
      | "minGuests"
      | "maxGuests"
      | "daysTill"
      | "excludeRegular"
      | "excludeVip"
  ) => {
    const rulesArr = Object.entries({ ...rules }).map((e) => e[0]);

    if (rules?.[check]) {
      let newRules: Event["rules"] = { ...(rules ?? {}) };

      let toDelete = rulesArr.includes(check) ? check : null;

      if (toDelete) {
        delete newRules[toDelete];
      }

      setEventState((st) => (st ? { ...st, rules: newRules } : null));
    } else {
      if (check === "excludeRegular" || check == "excludeVip") {
        setEventState((st) =>
          st ? { ...st, rules: { ...rules, [check]: true } } : null
        );
      } else {
        setEventState((st) =>
          st ? { ...st, rules: { ...rules, [check]: 1 } } : null
        );
      }
    }
  };

  const handleTextChange = (
    rule: "minGuests" | "maxGuests" | "daysTill",
    num: number
  ) => {
    setEventState((st) =>
      st ? { ...st, rules: { ...rules, [rule]: num } } : null
    );
  };

  const handleExcludeOccassions = (id: string) =>
    setEventState((st) => {
      if (!st) return null;

      let excludeOccassions = st?.rules?.excludeOccassions;

      if (!excludeOccassions) {
        excludeOccassions = [id];
      } else if (excludeOccassions.includes(id)) {
        excludeOccassions = excludeOccassions.filter((r) => id !== r);
      } else {
        excludeOccassions = [...excludeOccassions, id];
      }

      return { ...st, rules: { ...(st?.rules ?? {}), excludeOccassions } };
    });

  return (
    <Box className="mg-bt-lg" style={{ marginTop: 24 }}>
      <Typography variant="h5" className="mg-bt-xs" translation="calendar">
        Add some rules if needed
      </Typography>
      <Typography
        block
        style={{ marginBottom: 16 }}
        color="subdued"
        variant="text-3"
        translation="calendar"
      >
        You can select and set one or some restrictions or obligations to your
        event
      </Typography>
      <Box style={{ maxWidth: "30%" }}>
        <Box flex className="minGuests mg-bt-sm">
          <Checkbox
            style={{ width: "auto" }}
            checked={typeof rules.minGuests === "number"}
            noLabel
            onClick={() => handleChecks("minGuests")}
          />
          <TextField
            inputProps={{ min: 1 }}
            label="Minimum Guests"
            labelTranslation="calendar"
            value={rules.minGuests || ""}
            style={{ minWidth: 428 }}
            disabled={typeof rules.minGuests !== "number"}
            type="number"
            onChange={(e) =>
              handleTextChange("minGuests", Number(e.currentTarget.value))
            }
          />
        </Box>
        <Box flex className="maxGuests mg-bt-sm">
          <Checkbox
            style={{ width: "auto" }}
            checked={typeof rules.maxGuests === "number"}
            noLabel
            onClick={() => handleChecks("maxGuests")}
          />
          <TextField
            inputProps={{ min: 1 }}
            label="Maximum Guests"
            labelTranslation="calendar"
            value={rules.maxGuests || ""}
            style={{ minWidth: 428 }}
            disabled={typeof rules.maxGuests !== "number"}
            type="number"
            onChange={(e) =>
              handleTextChange("maxGuests", Number(e.currentTarget.value))
            }
          />
        </Box>
        {/* <Box flex className="daysTill mg-bt-sm">
          <Checkbox
            checked={!rules.daysTill ? false : true}
            noLabel
            onClick={() => handleChecks("daysTill")}
          />
          <TextField
            inputProps={{ min: 1 }}
            label="Days till"
            value={rules.daysTill || ""}
            style={{ minWidth: 428 }}
            disabled={rules.daysTill ? false : true}
            type="number"
            onChange={(e) =>
              handleTextChange("daysTill", Number(e.currentTarget.value))
            }
            labelTranslation="calendar"
          />
        </Box> */}
        {occassions.length > 1 && (
          <Box className="occassions mg-bt-sm">
            <Typography
              variant="h5"
              className="mg-bt-xs"
              translation="settings"
            >
              Exclude Occassions
            </Typography>
            <Box>
              {occassions.map((occ) => (
                <Checkbox
                  checked={rules?.excludeOccassions?.includes(occ.id)}
                  label={occ.title}
                  key={occ.id}
                  onClick={() => handleExcludeOccassions(occ.id)}
                />
              ))}
            </Box>
          </Box>
        )}
        {false && (
          <>
            <Box className="excludeRegular mg-bt-sm">
              <Checkbox
                checked={rules?.excludeRegular}
                label="Exclude Regular"
                onClick={() => handleChecks("excludeRegular")}
              />
            </Box>
            <Box className="excludeVip mg-bt-sm">
              <Checkbox
                checked={rules?.excludeVip}
                label="Exclude VIP"
                onClick={() => handleChecks("excludeVip")}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default EventRules;
