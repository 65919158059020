import React, {
  ReactNode,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import './styles.scss';
import MaterialTable from '@material-ui/core/Table';
import MaterialTableBody from '@material-ui/core/TableBody';
import MaterialTableCell from '@material-ui/core/TableCell';
import MaterialTableContainer from '@material-ui/core/TableContainer';
import MaterialTableHead from '@material-ui/core/TableHead';
import MaterialTableRow from '@material-ui/core/TableRow';
import Typography from '../../Atoms/Typography';
import GRow from './Components/GRow';
import Box from '../../Atoms/Box';
import Select from '../../Atoms/Select';
import Checkbox from '../../Atoms/Checkbox';
import IconButton from '../../Atoms/IconButton';
import { StylesProvider } from '@material-ui/core/styles';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import {
  DragDropContext,
  Draggable as DraggableComponent,
  DragStart,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import NewRow from './Components/NewRow';
import { Translations } from 'utils/types';
import { classHelper, noop } from 'utils/helper';
import useThrottle from 'CustomHooks/useThrottle';
import { TableSortLabel } from '@material-ui/core';
import useAlert from 'CustomHooks/useAlert';
import useToast from 'CustomHooks/useToast';
import { Severity } from 'Contexts/RestaurantContext';

const Draggable = React.memo(DraggableComponent);
const Table = React.memo(MaterialTable);
const TableBody = React.memo(MaterialTableBody);
const TableCell = React.memo(MaterialTableCell);
const TableContainer = React.memo(MaterialTableContainer);
const TableHead = React.memo(MaterialTableHead);
const TableRow = React.memo(MaterialTableRow);

type Order = 'asc' | 'desc';

export interface ListConfigHeader<DataType> {
  headerName: string | (() => string);
  headerTranslation?: Translations;
  field: string;
  displayFunction?: (data: DataType, index: number) => any;
  displayFallback?: (data: DataType) => any;
  editable?: boolean;
  width?: number | string;
  maxWidth?: number | string;
  colspan?: number;
  translation?: Translations;
  displayHeader?: () => any;
  sortableBy?: boolean;
  style?: React.CSSProperties;
  summaryFn?:
    | 'sum'
    | 'avg'
    | 'count'
    | 'max'
    | 'min'
    | 'countTruthy'
    | ((data: any[]) => string);
}

export interface ContextAction<DataType> {
  render?: (data: DataType) => ReactNode;
  text?: ReactNode;
  icon?: ReactNode;
  iconColor?: string | ((data: DataType) => string);
  onClick: (data: DataType) => void;
  showCondition?: (data: DataType) => boolean;
  tooltipContent?: string;
  tooltipContentTranslation?: Translations;
}

export interface SelectableAction<DataType> {
  render?: (data: string[]) => ReactNode;
}

export interface GTableProps<DataType extends { id?: number | string }> {
  headers: ListConfigHeader<DataType>[];
  data: DataType[];
  contextActions?: ContextAction<DataType>[];
  rowHeight?: number;
  pagination?: boolean;
  paginationSizeOptions?: string[];
  selectable?: boolean;
  selectableActions?: SelectableAction<DataType>[];
  selectedItems?: string[];
  setSelectedItems?: (arr: string[]) => void;
  draggable?: boolean;
  onSort?: (arr: string[]) => void;
  sortedArray?: string[];
  enableAddRows?: boolean;
  onAddNewRow?: (data: Partial<DataType>) => void;
  emptySentence?: string;
  startingPage?: number;
  setStartingPage?: (nV: number) => void;
  useFallback?: boolean;
  primaryCheckboxes?: boolean;
  serversidePagination?: {
    total: number;
    perPage: number;
    setPerPage: React.Dispatch<React.SetStateAction<number>>;
    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
  };
  sortable?: boolean;
  borders?: boolean;
  onRowClick?: (id: string) => void;
  styleFunction?: (data: DataType) => React.CSSProperties | undefined;
  summary?: boolean;
  summaryTop?: boolean;
}

function reorder<T = any>(list: T[], startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

const GTable = ({
  headers,
  data = [],
  contextActions,
  pagination = false,
  selectable = false,
  selectableActions,
  selectedItems = [],
  setSelectedItems,
  paginationSizeOptions = ['25', '50', '100'],
  rowHeight = 52,
  draggable = false,
  onSort,
  sortedArray = data.map((item) => item.id),
  enableAddRows,
  onAddNewRow,
  emptySentence,
  startingPage = 1,
  setStartingPage = noop,
  useFallback = false,
  primaryCheckboxes = false,
  serversidePagination,
  sortable = false,
  borders = false,
  onRowClick,
  styleFunction,
  summary,
  summaryTop,
}: GTableProps<any>) => {
  // const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [pageSize, setPageSize] = useState<string>(paginationSizeOptions[0]);
  const [currentPage, setCurrentPage] = useState<number>(startingPage);

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<string>('');
  const alert = useAlert();
  const toast = useToast();

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination || !sortedArray || !onSort) {
        return;
      }

      let offset = parseInt(pageSize) * (currentPage - 1);

      const row = document.getElementById(result.draggableId);

      if (row) {
        row.classList.remove('dragged');
        row.style.width = `auto`;
        row.style.height = '';
        row.style.display = '';
        row.style.justifyContent = '';
        row.style.alignItems = '';
      }

      onSort(
        reorder<string>(
          sortedArray ?? data.map((item) => item.id),
          result.source.index + offset,
          result.destination.index + offset
        )
      );
      setOrderBy('');
    },
    [currentPage, pageSize, onSort, sortedArray, data]
  );

  const handleDragStart = useCallback((initial: DragStart) => {
    console.log(initial.draggableId);

    const row = document.getElementById(initial.draggableId);

    if (row) {
      row.classList.add('dragged');
      row.style.width = `${row.offsetWidth}px`;
      row.style.height = `${row.offsetHeight}px`;
      row.style.display = 'flex';
      row.style.justifyContent = 'space-between';
      row.style.alignItems = 'center';
    }
  }, []);

  const throttledData = useThrottle<any[]>(data, 500);

  let showingData = useMemo(() => {
    if (!pagination && !sortable) return throttledData;
    const indexOfLastItem = pagination
      ? currentPage * parseInt(pageSize)
      : 10000;
    const indexOfFirstItem = pagination
      ? indexOfLastItem - parseInt(pageSize)
      : 0;

    if (sortable) {
      let x = stableSort(throttledData, getComparator(order, orderBy)).slice(
        indexOfFirstItem,
        indexOfLastItem
      );
      return x;
    }

    return throttledData
      .slice(indexOfFirstItem, indexOfLastItem)
      .sort(
        (a, b) =>
          (sortedArray?.indexOf?.(String(a.id)) ?? 0) -
          (sortedArray?.indexOf?.(String(b.id)) ?? 0)
      );
  }, [
    throttledData,
    pageSize,
    currentPage,
    pagination,
    sortedArray,
    order,
    orderBy,
  ]);

  const selectAllHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (setSelectedItems) {
        if (event.target.checked) {
          setSelectedItems(data.map((d: any) => d.id));
        } else {
          setSelectedItems([]);
        }
      }
    },
    [throttledData, setSelectedItems]
  );

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if ((orderBy as any).includes('.')) {
      let [x, y] = (orderBy as any).split('.');

      let A = (a as any)[x][y] ?? 0;
      let B = (b as any)[x][y] ?? 0;

      if (B < A) {
        return -1;
      }
      if (B > A) {
        return 1;
      }
      return 0;
    }

    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    console.log('orderBy', orderBy);
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // This method is created for cross-browser compatibility
  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
  ) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  const alertPermanentChange = (id: string) => {
    console.log('inside alert');
    console.log('id', id);
    alert({
      title: 'Change the order permanently',
      description:
        'Are you sure that you want to sort by this field? Changes will be applied permanently.',
      titleTranslation: 'common',
      descriptionTranslation: 'common',
      onSubmit: () => {
        let newOrder = order;

        if (orderBy === id) {
          newOrder = newOrder === 'asc' ? 'desc' : 'asc';
        }

        const newArray = stableSort(data, getComparator(order, orderBy)).map(
          (i) => i.id as string
        );

        if (onSort) onSort(newArray);
        setOrder(newOrder);
        setOrderBy(id);
      },
    });
  };

  useEffect(() => setCurrentPage(startingPage), [startingPage]);

  const summarize = useCallback(
    (
      field: string,
      fn?:
        | 'sum'
        | 'avg'
        | 'count'
        | 'max'
        | 'min'
        | 'countTruthy'
        | ((data: any[]) => string)
    ) => {
      if (!fn) return '';

      let values = data
        .map((d) =>
          field.includes('.')
            ? d?.[field.split('.')[0]]?.[field.split('.')[1]] ?? 0
            : d[field] ?? 0
        )
        .flat();

      if (typeof fn === 'function') return fn(values);

      switch (fn) {
        case 'sum':
          return values.reduce((a, b) => a + b, 0);
        case 'avg':
          return (
            (values.reduce((a, b) => a + b, 0) / values.length).toFixed(2) +
            ' Ø'
          );
        case 'count':
          return values.length;
        case 'countTruthy':
          return values.filter(Boolean).length;
        case 'max':
          return 'Max: ' + Math.max(...values);
        case 'min':
          return 'Min: ' + Math.min(...values);
        default:
          return '';
      }
    },
    [data]
  );

  const classNames = classHelper(['table-container', borders && 'borders']);

  return (
    <StylesProvider injectFirst>
      <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <TableContainer className={classNames}>
          <div className="table-title-container"></div>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <Table ref={provided.innerRef}>
                <TableHead style={{ position: 'relative' }}>
                  <TableRow style={{ position: 'sticky', top: 0 }}>
                    {draggable && <th className="dragHandle" />}
                    {selectable && (
                      <TableCell width={50}>
                        <Checkbox
                          color={primaryCheckboxes ? 'primary' : 'secondary'}
                          checked={
                            selectedItems?.length === throttledData.length
                          }
                          indeterminate={
                            !!selectedItems?.length &&
                            selectedItems?.length !== throttledData.length
                          }
                          onChange={selectAllHandler}
                        />
                      </TableCell>
                    )}
                    {headers.map((header) =>
                      !sortable ? (
                        <TableCell
                          key={header.field}
                          width={header?.width ?? header?.maxWidth}
                          className="header-cell"
                          colSpan={header?.colspan}
                          style={{
                            backgroundColor: 'var(--color-paper)',
                            ...(header?.style ?? {}),
                            minWidth: header?.width ?? header?.maxWidth,
                            maxWidth: header?.maxWidth,
                          }}
                        >
                          <Typography
                            variant="text-3"
                            weight="bold"
                            translation={
                              header?.headerTranslation ||
                              header.translation ||
                              'common'
                            }
                          >
                            {header.displayHeader
                              ? header.displayHeader()
                              : header.headerName}
                          </Typography>
                        </TableCell>
                      ) : !draggable ? (
                        <TableCell
                          key={header.field}
                          width={header?.width ?? header?.maxWidth}
                          style={{
                            backgroundColor: 'var(--color-paper)',
                            ...(header?.style ?? {}),
                            minWidth: header?.width ?? header?.maxWidth,
                            maxWidth: header?.maxWidth,
                          }}
                          colSpan={header?.colspan}
                          sortDirection={
                            orderBy === header.field ? order : false
                          }
                        >
                          <TableSortLabel
                            active={
                              header?.sortableBy
                                ? orderBy === header.field
                                : false
                            }
                            direction={orderBy === header.field ? order : 'asc'}
                            onClick={
                              header?.sortableBy
                                ? createSortHandler(header.field)
                                : () =>
                                    toast(
                                      'Table is not sortable by this field',
                                      Severity.WARNING,
                                      'common'
                                    )
                            }
                          >
                            <Typography
                              variant="text-3"
                              weight="bold"
                              translation={
                                header?.headerTranslation ||
                                header.translation ||
                                'common'
                              }
                            >
                              {header.displayHeader
                                ? header.displayHeader()
                                : header.headerName}
                            </Typography>
                          </TableSortLabel>
                        </TableCell>
                      ) : (
                        <TableCell
                          key={header.field}
                          width={header?.width ?? header?.maxWidth}
                          colSpan={header?.colspan}
                          sortDirection={
                            orderBy === header.field ? order : false
                          }
                          style={{
                            backgroundColor: 'var(--color-paper)',
                            ...(header?.style ?? {}),
                            minWidth: header?.width ?? header?.maxWidth,
                            maxWidth: header?.maxWidth,
                          }}
                        >
                          <TableSortLabel
                            active={
                              header?.sortableBy
                                ? orderBy === header.field
                                : false
                            }
                            direction={orderBy === header.field ? order : 'asc'}
                            onClick={
                              header?.sortableBy
                                ? () => alertPermanentChange(header.field)
                                : () =>
                                    toast(
                                      'Table is not sortable by this field',
                                      Severity.WARNING,
                                      'common'
                                    )
                            }
                          >
                            <Typography
                              variant="text-3"
                              weight="bold"
                              translation={
                                header?.headerTranslation ||
                                header.translation ||
                                'common'
                              }
                            >
                              {header.displayHeader
                                ? header.displayHeader()
                                : header.headerName}
                            </Typography>
                          </TableSortLabel>
                        </TableCell>
                      )
                    )}
                    {
                      // headers.length < 6 &&
                      (contextActions || enableAddRows) && (
                        <TableCell
                          className="context-actions-header header-cell"
                          height="55px"
                          width="100px"
                          style={
                            headers.length > 6
                              ? {
                                  background: 'var(--color-paper)',
                                  textAlign: 'left',
                                }
                              : { textAlign: 'left' }
                          }
                        >
                          <Typography variant="text-3" weight="bold">
                            Actions
                          </Typography>
                        </TableCell>
                      )
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!showingData.length && (
                    <TableCell colSpan={5}>
                      <Typography
                        translation="settings"
                        variant="text-3"
                        color="subdued"
                        block
                        style={{
                          padding: 8,
                          width: '100%',
                          textAlign: 'center',
                        }}
                      >
                        {emptySentence || 'No Data To Show'}
                      </Typography>
                    </TableCell>
                  )}
                  {!!showingData.length && summaryTop && (
                    <TableRow>
                      {draggable && <th className="dragHandle" />}
                      {selectable && <TableCell width={50}></TableCell>}
                      {headers.map((header) => (
                        <TableCell
                          key={header.field}
                          width={header?.width ?? header?.maxWidth}
                          className="summary-cell"
                          colSpan={header?.colspan}
                          style={{
                            backgroundColor: 'var(--color-paper)',
                            ...(header?.style ?? {}),
                            minWidth: header?.width ?? header?.maxWidth,
                            maxWidth: header?.maxWidth,
                          }}
                        >
                          <Typography
                            variant="text-3"
                            style={{ whiteSpace: 'nowrap' }}
                            translation={
                              header?.headerTranslation ||
                              header.translation ||
                              'common'
                            }
                          >
                            {summarize(header.field, header.summaryFn)}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  )}
                  {draggable
                    ? showingData.map((row, i) => {
                        const filteredContextActions = contextActions?.filter(
                          (action) => action.showCondition?.(row) ?? true
                        );
                        return (
                          <Draggable
                            key={row.id}
                            draggableId={String(row.id)}
                            index={i}
                          >
                            {(provided, snapshot) => (
                              <GRow
                                id={row.id as string}
                                key={i}
                                useFallback={useFallback}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                dragHandleProps={provided.dragHandleProps}
                                headers={headers}
                                data={row}
                                filteredContextActions={
                                  filteredContextActions ?? []
                                }
                                selectable={selectable}
                                selected={selectedItems?.includes(
                                  (row as any).id
                                )}
                                onSelect={
                                  setSelectedItems
                                    ? (id) =>
                                        setSelectedItems?.(
                                          selectedItems.includes(id)
                                            ? selectedItems.filter(
                                                (i) => i !== id
                                              )
                                            : [...selectedItems, id]
                                        )
                                    : undefined
                                }
                                rowHeight={rowHeight}
                                draggable={draggable}
                                primaryCheckboxes={primaryCheckboxes}
                              />
                            )}
                          </Draggable>
                        );
                      })
                    : showingData.map((row, i) => {
                        const filteredContextActions = contextActions?.filter(
                          (action) => action.showCondition?.(row) ?? true
                        );
                        return (
                          <GRow
                            key={i}
                            useFallback={useFallback}
                            id={row.id as string}
                            headers={headers}
                            data={row}
                            filteredContextActions={
                              filteredContextActions ?? []
                            }
                            selectable={selectable}
                            selected={selectedItems?.includes((row as any).id)}
                            onSelect={(id) =>
                              setSelectedItems?.(
                                selectedItems.includes(id)
                                  ? selectedItems.filter((i) => i !== id)
                                  : [...selectedItems, id]
                              )
                            }
                            rowHeight={rowHeight}
                            draggable={draggable}
                            extraCell={!contextActions && enableAddRows}
                            onRowClick={onRowClick}
                            style={styleFunction?.(row)}
                          />
                        );
                      })}
                  {provided.placeholder}
                  {!!showingData.length && summary && (
                    <TableRow>
                      {draggable && <th className="dragHandle" />}
                      {selectable && <TableCell width={50}></TableCell>}
                      {headers.map((header) => (
                        <TableCell
                          key={header.field}
                          width={header?.width ?? header?.maxWidth}
                          className="summary-cell"
                          colSpan={header?.colspan}
                          style={{
                            backgroundColor: 'var(--color-paper)',
                            ...(header?.style ?? {}),
                            minWidth: header?.width ?? header?.maxWidth,
                            maxWidth: header?.maxWidth,
                          }}
                        >
                          <Typography
                            variant="text-3"
                            style={{ whiteSpace: 'nowrap' }}
                            translation={
                              header?.headerTranslation ||
                              header.translation ||
                              'common'
                            }
                          >
                            {summarize(header.field, header.summaryFn)}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  )}
                  {enableAddRows && (
                    <NewRow
                      headers={headers}
                      selectable={selectable}
                      draggable={draggable}
                      rowHeight={rowHeight}
                      onAddNewRow={onAddNewRow}
                    />
                  )}
                </TableBody>
              </Table>
            )}
          </Droppable>
          {pagination && (
            <Box flex className="pagination">
              <Typography
                variant="text-3"
                color="subdued"
                className="rpp-text colon"
              >
                Rows per page
              </Typography>
              <Select
                className="select-page-size"
                options={paginationSizeOptions}
                value={pageSize}
                onChange={(e) => {
                  setPageSize(e.target.value as string);
                  setCurrentPage(1);
                }}
              />
              <Typography variant="text-3" style={{ margin: '0px 22px' }}>
                {(currentPage - 1) * parseInt(pageSize) + 1}
                {' - '}
                {parseInt(pageSize) * currentPage <= throttledData.length
                  ? parseInt(pageSize) * currentPage
                  : throttledData.length}{' '}
                of {throttledData.length}
              </Typography>
              <IconButton
                color={currentPage === 1 ? 'subdued' : 'textPrimary'}
                disabled={currentPage === 1}
                style={{ marginRight: '12px' }}
                size="small"
                onClick={() => {
                  setCurrentPage((prev) => prev - 1);
                  setStartingPage(currentPage - 1);
                }}
              >
                <ChevronLeft />
              </IconButton>
              <IconButton
                size="small"
                disabled={
                  parseInt(pageSize) * currentPage >= throttledData.length
                }
                color={
                  parseInt(pageSize) * currentPage >= throttledData.length
                    ? 'subdued'
                    : 'textPrimary'
                }
                onClick={() => {
                  setCurrentPage((prev) => prev + 1);
                  setStartingPage(currentPage + 1);
                }}
              >
                <ChevronRight />
              </IconButton>
            </Box>
          )}{' '}
          {serversidePagination && (
            <Box flex className="pagination">
              <Typography
                variant="text-3"
                color="subdued"
                className="rpp-text colon"
              >
                Rows per page
              </Typography>
              <Select
                className="select-page-size"
                options={paginationSizeOptions}
                value={serversidePagination?.perPage}
                onChange={(e) => {
                  serversidePagination?.setPerPage(e.target.value as number);
                }}
              />
              <Typography variant="text-3" style={{ margin: '0px 22px' }}>
                {(serversidePagination?.page - 1) *
                  serversidePagination?.perPage +
                  1}
                {' - '}
                {serversidePagination?.perPage * serversidePagination.page <=
                serversidePagination?.total
                  ? serversidePagination?.perPage * serversidePagination?.page
                  : serversidePagination.total}{' '}
                of {serversidePagination.total}
              </Typography>
              <IconButton
                color={
                  serversidePagination.page === 1 ? 'subdued' : 'textPrimary'
                }
                disabled={serversidePagination.page === 1}
                style={{ marginRight: '12px' }}
                size="small"
                onClick={() => {
                  serversidePagination.setPage((prev) => prev - 1);
                }}
              >
                <ChevronLeft />
              </IconButton>
              <IconButton
                size="small"
                disabled={
                  serversidePagination.perPage * serversidePagination.page >=
                  serversidePagination.total
                }
                color={
                  serversidePagination.perPage * serversidePagination.page >=
                  serversidePagination.total
                    ? 'subdued'
                    : 'textPrimary'
                }
                onClick={() => {
                  serversidePagination.setPage((prev) => prev + 1);
                }}
              >
                <ChevronRight />
              </IconButton>
            </Box>
          )}
        </TableContainer>
      </DragDropContext>
    </StylesProvider>
  );
};

export default GTable;
