import server from '../index';
import { AccountingData } from 'App/TakeAway/Components/AccountingReport'

export default async (restaurantId:string, date:string, type?:string) => {
    try {
        // @TODO add type
        const response = await server.get(`/v02/menues/takeAway/${restaurantId}/accounting/${date}`);

        const data:AccountingData = response.data;

        return data;

    } catch (error:any) {
        if(error.isAxiosError) {
            throw new Error(error?.response?.data?.message || error.message);
        } else {
            throw error
        }
    }
}