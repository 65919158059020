import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/messaging';
//import * as dotenv from "dotenv";
//dotenv.config();

export let isOnDemoMode = process.env?.REACT_APP_CURRENT === 'gastronaut-demo';

const DEMO_CONFIG = {
  apiKey: 'AIzaSyCQwuMEnjtA2u-lFMSJLO8ti32btA7MUDg',
  authDomain: 'gastronaut-demo.firebaseapp.com',
  databaseURL: 'https://gastronaut-demo.firebaseio.com',
  projectId: 'gastronaut-demo',
  storageBucket: 'gastronaut-demo.appspot.com',
  messagingSenderId: '756524492770',
  appId: '1:756524492770:web:155ab426f0ac1034767bbb',
  measurementId: 'G-MRR0K04350',
};

const PRODUCTION_CONFIG = {
  apiKey: 'AIzaSyB7uzWXthIb7ErFGGia_aJUz4EPmvIpSZY',
  authDomain: 'schillingroofbarhd.firebaseapp.com',
  databaseURL: 'https://schillingroofbarhd.firebaseio.com',
  projectId: 'schillingroofbarhd',
  storageBucket: 'schillingroofbarhd.appspot.com',
  messagingSenderId: '313577808081',
  appId: '1:313577808081:web:693fae11afa38a00e3a5f0',
  measurementId: 'G-7YFWFP94KR',
};

const config = isOnDemoMode ? DEMO_CONFIG : PRODUCTION_CONFIG;

if (!firebase.apps || !firebase.apps.length) {
  firebase.initializeApp(config);
}

firebase.analytics();

export const db = firebase.firestore();

export const rtDB = firebase.database();

// export const messaging = window.Notification ? firebase.messaging() : null;

export const FieldValue = firebase.firestore.FieldValue;

export const storageRef = firebase.storage().ref();

export const functions = firebase.app().functions('europe-west3');

export default firebase;
