import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const StatisticsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" fill="none" />
      <path
        d="M12 22.6667H9.33331V13.3333H12V22.6667ZM17.3333 22.6667H14.6666V9.33333H17.3333V22.6667ZM22.6666 22.6667H20V17.3333H22.6666V22.6667ZM26 25.4667H5.99998V6.66667H26V25.4667ZM26 4H5.99998C4.53331 4 3.33331 5.2 3.33331 6.66667V25.3333C3.33331 26.8 4.53331 28 5.99998 28H26C27.4666 28 28.6666 26.8 28.6666 25.3333V6.66667C28.6666 5.2 27.4666 4 26 4Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default StatisticsIcon;
