import { useState, useEffect, useMemo } from "react";
import { CurrentStatus, Reservation } from "types/reservations";
import firebase, { db } from "utils/firebase";
import { dateHelper, timeToBlock } from "utils/helper";

export const getReservationStatus = (res: Reservation, date: null | string) => {
  if (res.done || res.started) {
    return CurrentStatus.DONE;
  } else if (res.status === "failed") {
    return CurrentStatus.FAILED;
  } else if (date !== null) {
    if (new Date(res.date).getTime > new Date(date).getTime) {
      return CurrentStatus.NORMAL;
    } else if (new Date(res.date).getTime < new Date(date).getTime) {
      return CurrentStatus.DONE;
    }
  }

  return CurrentStatus.NORMAL;
};

const useSearchForCustomId = (
  restaurantId: string,
  search: string,
  date: string = dateHelper()
) => {
  // Apply a one second delay before to search
  const [debouncedSearch, setdebouncedSearch] = useState(search);

  useEffect(() => {
    const handler = setTimeout(() => {
      setdebouncedSearch(search);
    }, 1000);
    return () => clearTimeout(handler);
  }, [search]);

  //Search
  const [reservationsWithSameCustomId, setReservations] = useState<
    Reservation[]
  >([]);
  const [loading, setloading] = useState<boolean>(true);

  useEffect(() => {
    if (debouncedSearch.length !== 4) {
      setReservations([]);
      setloading(false);
      return;
    }

    if (restaurantId) {
      setloading(true);
      setReservations([]);

      const ref = db
        .collection("requestsV03")
        .where("restaurant", "==", restaurantId)
        .where("customId", "==", debouncedSearch.toUpperCase())
        .orderBy("createdAt", "desc")
        .limit(10);

      ref
        .get()
        .then((docs) => {
          let arr: Reservation[] = [];

          docs.forEach((doc) => {
            let data = { ...(doc.data() as Reservation), id: doc.id };

            if (data.date === date) return;

            arr.push(data as Reservation);
          });

          let res: Reservation[] = arr
            .sort(
              (a, b) =>
                (a?.createdAt || 1000000000) - (b?.createdAt || 1000000000)
            )
            .map((res, position) => {
              let startTimeInBlocks =
                res.startTimeInBlocks ?? timeToBlock(res.time);
              let endTimeInBlocks =
                res.endTimeInBlocks ??
                startTimeInBlocks + (res?.reservationLength ?? 0);

              return {
                ...res,
                position,
                startTimeInBlocks,
                endTimeInBlocks,
                currentStatus: CurrentStatus.NORMAL,
              };
            });

          setReservations(res);
          setloading(false);
        })
        .catch((err: Error) => {
          console.error(err);
          setloading(false);
        });
    } else {
      setReservations([]);
      setloading(false);
    }
  }, [restaurantId, debouncedSearch]);

  return { reservationsWithSameCustomId, loadingSameCustomId: loading };
};

export default useSearchForCustomId;
