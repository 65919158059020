import React, { useState } from "react";
import "./styles.scss";
import Modal from "Components/Molecules/Modal";
import Box from "../../../../Components/Atoms/Box";

import PushImg from "./push-img.svg";
import Typography from "../../../../Components/Atoms/Typography";
import GastronautIcon from "../../../../Components/Atoms/GastronautIcon";

function isTabletWithoutStandalone() {
  // console.log("TESTING");

  return (
    !window.matchMedia("(display-mode: standalone)").matches &&
    !window.matchMedia("(hover: hover)").matches &&
    !window.location.pathname.includes("entranceTicket")
  );
}

const AddToHomeModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(isTabletWithoutStandalone);

  const onClose = () => setIsOpen(false);
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title="Add to home screen"
      titleTranslation="common"
    >
      <Box className="add-to-home-modal-container">
        <GastronautIcon size={84} />
        <Typography
          variant="h3"
          style={{ marginTop: "8px" }}
          textAlign="center"
          translationKey="common_saveToHomeHead"
        >
          Save our App to your Home screen
        </Typography>
        <Typography
          variant="text-2"
          color="subdued"
          textAlign="center"
          style={{ marginTop: "20px" }}
          translationKey="common_saveToHomeBody"
        >
          You can save the Gastronaut App to your Homescreen by clicking the
          share button and pressing "Save to Home Screen"
        </Typography>
        <Box flex className="last-row-container">
          <Typography variant="text-3" translation="common">
            Tap
          </Typography>
          <img className="tap-img" src={PushImg} />
          <Typography variant="text-3" translation="common">
            on your tablet
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddToHomeModal;
