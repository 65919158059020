import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./styles.scss";

interface LoadingScreenProps {
  loading: boolean;
  position?: "fixed" | "absolute";
  backgroundColor?: string;
}

const LoadingScreen = ({
  loading = false,
  position = "absolute",
  backgroundColor,
}: LoadingScreenProps) => {
  if (!loading) {
    return <></>;
  }

  return (
    <div className="loadingScreen" style={{ position, backgroundColor }}>
      <CircularProgress color="primary" />
    </div>
  );
};

export default LoadingScreen;
