import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const TicketIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.6668 16.6667V10C36.6668 8.15 35.1668 6.66667 33.3335 6.66667H6.66683C4.8335 6.66667 3.35016 8.15 3.35016 10V16.6667C5.1835 16.6667 6.66683 18.1667 6.66683 20C6.66683 21.8333 5.1835 23.3333 3.3335 23.3333V30C3.3335 31.8333 4.8335 33.3333 6.66683 33.3333H33.3335C35.1668 33.3333 36.6668 31.8333 36.6668 30V23.3333C34.8335 23.3333 33.3335 21.8333 33.3335 20C33.3335 18.1667 34.8335 16.6667 36.6668 16.6667ZM21.6668 29.1667H18.3335V25.8333H21.6668V29.1667ZM21.6668 21.6667H18.3335V18.3333H21.6668V21.6667ZM21.6668 14.1667H18.3335V10.8333H21.6668V14.1667Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default TicketIcon;
