import React, { useState } from "react";
import { Attachment } from "types/shifts";
import Box from "Components/Atoms/Box";
import "./styles.scss";
import {
  PDFAttachmentIcon,
  DocAttachmentIcon,
  ImageAttachmentIcon,
  OtherAttachmentIcon,
  Delete,
  CloudUpload,
} from "Components/Atoms/Icons";
import Typography from "Components/Atoms/Typography";
import IconButton from "Components/Atoms/IconButton";
import LoadingScreen from "Components/Atoms/LoadingScreen";
import Button from "Components/Atoms/Button";
import UploadFile from "App/Settings/Components/UploadFile";
import { classHelper } from "../../../../../../utils/helper";
import { Trans } from "react-i18next";

export type AttachmentProps = {
  backgroundColor?: string;
  attachments: Attachment[];
  onAttachmentUpload?: (files: FileList) => Promise<void>;
  onAttachmentDelete?: (documentName: string) => Promise<void>;
  className?: string;
  single?: boolean;
};

const Icon: React.FC<{ type: string }> = ({ type }) => {
  if (type.includes("pdf")) {
    return <PDFAttachmentIcon className="icon" />;
  } else if (type.startsWith("image")) {
    return <ImageAttachmentIcon className="icon" />;
  } else if (type.includes("msword") || type.includes("wordprocessingml")) {
    return <DocAttachmentIcon className="icon" />;
  } else {
    return <OtherAttachmentIcon className="icon" />;
  }
};

const AttachmentLink: React.FC<{
  attachment: Attachment;
  onAttachmentDelete?: (documentName: string) => Promise<void>;
}> = ({ onAttachmentDelete, attachment }) => {
  const [loading, setloading] = useState(false);

  const onDelete: React.MouseEventHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!window.confirm("Are you sure?")) return;

    setloading(true);
    await onAttachmentDelete?.(attachment.documentName);
    setloading(false);
  };

  return (
    <Box
      flex
      outline
      component="a"
      className="attachment"
      padding="sm"
      elevation={0}
      href={attachment.url}
      target="_blank"
    >
      <LoadingScreen position="absolute" loading={loading} />
      <Box flex>
        <Icon type={attachment.type} />
        <Typography variant="text-3">{attachment.documentName}</Typography>
      </Box>
      {!!onAttachmentDelete && (
        <IconButton size="small" color="disabled" onClick={onDelete}>
          <Delete />
        </IconButton>
      )}
    </Box>
  );
};

const Attachments: React.FC<AttachmentProps> = ({
  attachments,
  onAttachmentDelete,
  onAttachmentUpload,
  className,
  backgroundColor,
  single = false,
}) => {
  const handleFileUpload: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files = [] } = e.target;

    if (!files?.length) {
      return;
    }
    onAttachmentUpload?.(files as FileList);
  };

  return (
    <Box className={classHelper(["attachments", className])}>
      <Box className="rail">
        <Box flex className="railInner">
          {attachments.map((attachment, i) => (
            <AttachmentLink
              key={i}
              attachment={attachment}
              onAttachmentDelete={onAttachmentDelete}
            />
          ))}
          {!attachments.length && (
            <Box padding="sm">
              <Typography variant="text-3">No Attachment Uploaded</Typography>
            </Box>
          )}
        </Box>
      </Box>

      {!!onAttachmentUpload && (!single || !attachments.length) && (
        <Box
          className="uploadAttachment"
          style={{ background: backgroundColor }}
        >
          <Button
            onClick={(e) =>
              e.currentTarget?.getElementsByTagName("input")?.[0]?.click?.()
            }
            startIcon={(p) => <CloudUpload {...p} />}
          >
            <Trans i18nKey="common">Upload</Trans>
            <input
              type="file"
              onChange={handleFileUpload}
              name="attachmentUpload"
              hidden
              multiple={!single}
            />
          </Button>
          {/* <UploadFile name="attachmentUpload" label="Upload" multiple onUpload={onAttachmentUpload} /> */}
        </Box>
      )}
    </Box>
  );
};

export default Attachments;
