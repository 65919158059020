import React from "react";

const SwitchReservation: React.FC<{ switchReservation: boolean }> = ({
  switchReservation,
}) => {
  return (
    <img
      src={
        switchReservation ? "/success-switchResa.svg" : "/block-switchResa.svg"
      }
      alt="Switch Reservation"
      height="20px"
      width="20px"
    />
  );
};

export default SwitchReservation;
