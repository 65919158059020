import React from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import Typography from 'Components/Atoms/Typography';
import useDateString from 'CustomHooks/useDateString';

export type GuestCardProps = {
  id: string;
  name: string;
  lastReservation: string;
  active: boolean;
  onClick: (id: string) => void;
  showPhone?: boolean;
  phone: string;
};

const GuestCard = ({
  name,
  lastReservation,
  active,
  id,
  onClick,
  showPhone = false,
  phone,
}: GuestCardProps) => {
  const dateStr = useDateString(lastReservation);
  return (
    <Box
      padding="md"
      className={['flex-column', 'guestCard', active && 'active'].join(' ')}
      onClick={() => onClick(id)}
    >
      <Box flex>
        <Typography variant="text-3" weight="medium">
          {name}
        </Typography>
      </Box>
      {!showPhone ? (
        <Box flex style={{ marginTop: '8px' }}>
          <Box>
            <Typography variant="text-3" color="subdued" translation="common">
              Last Booking
            </Typography>
          </Box>
          <Box flex style={{ justifyContent: 'flex-end' }}>
            <Typography variant="text-3" color="subdued">
              {dateStr ? dateStr : '-'}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box style={{ marginTop: '8px' }}>
          <Box>
            <Typography variant="text-3" color="subdued" translation="common">
              {phone}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default GuestCard;
