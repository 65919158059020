import React, { useEffect, useMemo, useState } from "react";
import Box from "Components/Atoms/Box";
import Typography from "Components/Atoms/Typography";
import Button from "Components/Atoms/Button";
import {
  Add,
  Delete,
  Edit,
  ArrowUpward,
  ArrowDownward,
} from "Components/Atoms/Icons";
import { Event, EventAddOn, EventOption } from "types/calendar";
import GTable, {
  ContextAction,
  ListConfigHeader,
} from "Components/Organisms/GTable";
import EditOptionModal from "./EditOptionModal";
import EditAddonModal from "./EditAddonModal";
import RadioButtonGroup from "Components/Atoms/RadioButtonGroup";

type EventDinerProps = {
  options?: EventOption[];
  optionalAddOns?: EventAddOn[];
  setEventState: React.Dispatch<React.SetStateAction<Event | null>>;
  eventState: Event | null;
};

const EventDiner = ({
  options,
  optionalAddOns,
  setEventState,
  eventState,
}: EventDinerProps) => {
  const [openEditOptions, setOpenEditOptions] = useState<
    | { isOpen: boolean; optId: string | null; type: "options" | "addons" }
    | false
  >(false);

  // OPTIONS HEADER
  const headersOptions: ListConfigHeader<EventOption>[] = [
    {
      field: "image",
      headerName: "Image",
      headerTranslation: "common",
      displayFunction: (data) => {
        if (data.id !== "newOpt" && data.id !== "newAddon" && data.img) {
          return (
            <img
              alt=""
              style={{
                width: 48,
                height: 48,
                objectFit: "cover",
              }}
              src={data.img}
            />
          );
        }
      },
    },
    {
      field: "title",
      headerName: "Title: DE / EN",
      width: 180,
      displayFunction: (data) => {
        if (data.id !== "newOpt" && data.id !== "newAddon") {
          return (
            <Typography color="subdued" variant="text-3">
              {data.translation.de.title}
            </Typography>
          );
        }
      },
    },
    {
      field: "description",
      width: 260,
      headerName: "Description",
      displayFunction: (data) => {
        if (data.id !== "newOpt" && data.id !== "newAddon") {
          return (
            <Typography color="subdued" variant="text-3">
              {data.translation.de.description}
            </Typography>
          );
        }
      },
    },
    {
      field: "amount",
      headerName: "Price",
      displayFunction: (data) => {
        if (data.id !== "newOpt" && data.id !== "newAddon") {
          return (
            <Typography color="subdued" variant="text-3">
              {data.amount ? data.amount.toFixed(2).replace(".", ",") : "-"}€
            </Typography>
          );
        }
      },
    },
    {
      field: "limited",
      headerName: "Limited",
      displayFunction: (data) => {
        if (data.id !== "newOpt" && data.id !== "newAddon") {
          return (
            <Typography color="subdued" variant="text-3">
              {data.limited || "∞"}
            </Typography>
          );
        }
      },
    },
    {
      field: "vatRate",
      headerName: "VAT",
      displayFunction: (data) => {
        if (data.id !== "newOpt" && data.id !== "newAddon") {
          return (
            <Typography color="subdued" variant="text-3">
              {data.vatRate || "0,00"}%
            </Typography>
          );
        }
      },
    },
  ];

  // ADDONS HEADER
  const headersOptionalAddons: ListConfigHeader<EventAddOn>[] = [
    {
      field: "image",
      headerName: "Image",
      headerTranslation: "common",
      displayFunction: (data) => {
        if (data.id !== "newOpt" && data.id !== "newAddon" && data.img) {
          return (
            <img
              alt=""
              style={{ width: 48, height: 48, objectFit: "cover" }}
              src={data.img}
            />
          );
        }
      },
    },
    {
      field: "title",
      headerName: "Title: DE / EN",
      width: 180,
      displayFunction: (data) => {
        if (data.id !== "newOpt" && data.id !== "newAddon") {
          return (
            <Typography color="subdued" variant="text-3">
              {data.translation.de.title}
            </Typography>
          );
        }
      },
    },
    {
      field: "description",
      headerName: "Description",
      width: 260,
      displayFunction: (data) => {
        if (data.id !== "newOpt" && data.id !== "newAddon") {
          return (
            <Typography color="subdued" variant="text-3">
              {data.translation.de.description}
            </Typography>
          );
        }
      },
    },
    {
      field: "amount",
      headerName: "Price",
      displayFunction: (data) => {
        if (data.id !== "newOpt" && data.id !== "newAddon") {
          return (
            <Typography color="subdued" variant="text-3">
              {data?.amount?.toFixed(2).replace(".", ",") || "-"}€
            </Typography>
          );
        }
      },
    },
    {
      field: "limited",
      headerName: "Limited",
      displayFunction: (data) => {
        if (data.id !== "newOpt" && data.id !== "newAddon") {
          return (
            <Typography color="subdued" variant="text-3">
              {data.limited || "∞"}
            </Typography>
          );
        }
      },
    },
    {
      field: "vatRate",
      headerName: "VAT",
      displayFunction: (data) => {
        if (data.id !== "newOpt" && data.id !== "newAddon") {
          return (
            <Typography color="subdued" variant="text-3">
              {data.vatRate ?? "0,00"}%
            </Typography>
          );
        }
      },
    },
  ];

  const handleEdit = (optionId: string, type: "options" | "addons") => {
    setOpenEditOptions({ isOpen: true, optId: optionId, type: type });
  };

  const handleAdd = (type: "options" | "addons") => {
    let newOptions: EventOption[];
    let newAddons: EventAddOn[];

    if (type === "options") {
      if (options) {
        newOptions = [
          ...options,
          {
            id: "newOpt",
            vatRate: "7.00",
            internalName: "",
            translation: {
              de: { title: "", description: "" },
              en: { title: "", description: "" },
            },
            type: "perPerson",
          },
        ];
      } else {
        newOptions = [
          {
            id: "newOpt",
            internalName: "",
            translation: {
              de: { title: "", description: "" },
              en: { title: "", description: "" },
            },
            vatRate: "19.00",
            type: "perPerson",
          },
        ];
      }

      setOpenEditOptions({ isOpen: true, optId: "newOpt", type: type });
      eventState &&
        setEventState({
          ...eventState,
          options: newOptions,
        });
    } else {
      if (optionalAddOns) {
        newAddons = [
          ...optionalAddOns,
          {
            id: "newAddon",
            internalName: "",
            translation: {
              de: { title: "", description: "" },
              en: { title: "", description: "" },
            },
            type: "perPerson",
            vatRate: "19.00",
          },
        ];
      } else {
        newAddons = [
          {
            id: "newAddon",
            internalName: "",
            translation: {
              de: { title: "", description: "" },
              en: { title: "", description: "" },
            },
            vatRate: "19.00",
            type: "perPerson",
          },
        ];
      }

      setOpenEditOptions({ isOpen: true, optId: "newAddon", type: type });
      eventState &&
        setEventState({
          ...eventState,
          optionalAddOns: newAddons,
        });
    }
  };

  const handleDelete = (id: string, type: "options" | "addons") => {
    if (!eventState) return;
    let newOptions = eventState.options && [...eventState.options];
    let newAddons = eventState.optionalAddOns && [...eventState.optionalAddOns];

    if (type === "options") {
      newOptions = newOptions?.filter((o) => o.id !== id);

      setEventState({ ...eventState, options: newOptions });
    } else {
      newAddons = newAddons?.filter((o) => o.id !== id);

      setEventState({ ...eventState, optionalAddOns: newAddons });
    }
  };

  const sortedOptions = useMemo(
    () =>
      options
        ?.sort((a, b) => (a.pos ?? 1000) - (b.pos ?? 1000))
        ?.map((s, i) => ({ ...s, pos: s.pos ?? i }))
        ?.sort((a, b) => a.pos - b.pos) ?? [],
    [options]
  );


  const handleSort = (id: string, dir: "up" | "down") => {
    let change = dir === "up" ? -1 : 1;
    let newPos = Math.min(
      Math.max(sortedOptions.findIndex((o) => o.id === id) + change, 0),
      sortedOptions.length
    );

    setEventState((e) =>
      !!e
        ? {
            ...e,
            options:
              e.options
                ?.map((s, i) => ({
                  ...s,
                  pos: s.id === id ? newPos : s.pos ?? i,
                }))
                ?.sort(
                  (a, b) =>
                    a.pos * 10 +
                    (a.id === id ? change : 0) -
                    (b.pos * 10 + (b.id === id ? change : 0))
                )
                ?.map((s, i) => ({
                  ...s,
                  pos: i,
                })) ?? [],
          }
        : null
    );
  };

  const sortedAddons = useMemo(
    () =>
      optionalAddOns
        ?.sort((a, b) => (a.pos ?? 1000) - (b.pos ?? 1000))
        ?.map((s, i) => ({ ...s, pos: s.pos ?? i }))
        ?.sort((a, b) => a.pos - b.pos) ?? [],
    [optionalAddOns]
  );

  const handleSortAddons = (id: string, dir: "up" | "down") => {
    let change = dir === "up" ? -1 : 1;
    let newPos = Math.min(
      Math.max(sortedAddons.findIndex((o) => o.id === id) + change, 0),
      sortedOptions.length
    );

    setEventState((e) =>
      !!e
        ? {
            ...e,
            optionalAddOns:
              e.optionalAddOns
                ?.map((s, i) => ({
                  ...s,
                  pos: s.id === id ? newPos : s.pos ?? i,
                }))
                ?.sort(
                  (a, b) =>
                    a.pos * 10 +
                    (a.id === id ? change : 0) -
                    (b.pos * 10 + (b.id === id ? change : 0))
                )
                ?.map((s, i) => ({
                  ...s,
                  pos: i,
                })) ?? [],
          }
        : null
    );
  };

  //OPTIONS ACTIONS
  const contextActions: ContextAction<EventOption>[] = [
    {
      icon: <Edit color="inherit" />,
      iconColor: "var(--color-text-subdued)",
      onClick: (data) => {
        handleEdit(data.id, "options");
      },
      showCondition: (data) => true,
      tooltipContent: "Edit",
      tooltipContentTranslation: "settings",
    },
    {
      icon: <Delete color="inherit" />,
      iconColor: "var(--color-text-subdued)",
      onClick: (data) => handleDelete(data.id, "options"),
      showCondition: (data) => true,
      tooltipContent: "Delete",
      tooltipContentTranslation: "settings",
    },
    {
      icon: <ArrowUpward color="inherit" />,
      iconColor: (data) =>
        (data?.pos ?? 0) > 0
          ? "var(--color-text-subdued)"
          : "var(--color-disabled)",
      onClick: (data) => handleSort(data.id, "up"),
      showCondition: (data) => sortedOptions.length > 1,
      tooltipContent: "Up",
      tooltipContentTranslation: "settings",
    },
    {
      icon: <ArrowDownward color="inherit" />,
      iconColor: (data) =>
        (data?.pos ?? 0) !== sortedOptions.length - 1
          ? "var(--color-text-subdued)"
          : "var(--color-disabled)",
      onClick: (data) => handleSort(data.id, "down"),
      showCondition: (data) => sortedOptions.length > 1,
      tooltipContent: "Down",
      tooltipContentTranslation: "settings",
    },
  ];

  // ADDONS ACTIONS
  const contextActionsAddons: ContextAction<EventOption>[] = [
    {
      icon: <Edit color="inherit" />,
      iconColor: "var(--color-text-subdued)",
      onClick: (data) => {
        handleEdit(data.id, "addons");
      },
      showCondition: (data) => true,
      tooltipContent: "Edit",
      tooltipContentTranslation: "settings",
    },
    {
      icon: <Delete color="inherit" />,
      iconColor: "var(--color-text-subdued)",
      onClick: (data) => handleDelete(data.id, "addons"),
      showCondition: (data) => true,
      tooltipContent: "Delete",
      tooltipContentTranslation: "settings",
    },
    {
      icon: <ArrowUpward color="inherit" />,
      iconColor: (data) =>
        (data?.pos ?? 0) > 0
          ? "var(--color-text-subdued)"
          : "var(--color-disabled)",
      onClick: (data) => handleSortAddons(data.id, "up"),
      showCondition: () => sortedAddons.length > 1,
      tooltipContent: "Up",
      tooltipContentTranslation: "settings",
    },
    {
      icon: <ArrowDownward color="inherit" />,
      iconColor: (data) =>
        (data?.pos ?? 0) !== sortedAddons.length - 1
          ? "var(--color-text-subdued)"
          : "var(--color-disabled)",
      onClick: (data) => handleSortAddons(data.id, "down"),
      showCondition: () => sortedAddons.length > 1,
      tooltipContent: "Down",
      tooltipContentTranslation: "settings",
    },
  ];

  if (!eventState) {
    return <></>;
  }

  return (
    <Box>
      <>
        <Box flex className="mg-bt-md space-between" style={{ marginTop: 24 }}>
          <Box width="auto">
            <Typography
              variant="h5"
              className="mg-bt-xs"
              translation="calendar"
            >
              Add Options
            </Typography>
            <Typography color="subdued" variant="text-3" translation="calendar">
              Add or edit your event options if needed. At least one option is
              needed
            </Typography>
          </Box>

          <Button
            style={{ width: "20%" }}
            endIcon={() => <Add style={{ marginLeft: 8 }} />}
            onClick={() => handleAdd("options")}
            translation="calendar"
          >
            New option
          </Button>
        </Box>

        <RadioButtonGroup
          style={{ display: "flex" }}
          value={eventState.optionPer ?? "perPerson"}
          options={[
            {
              label: "One Option Per Person",
              value: "perPerson",
              translation: "calendar",
            },
            {
              label: "One Option Per Group",
              value: "perGroup",
              translation: "calendar",
            },
          ]}
          onChange={(e: any) => {
            setEventState({
              ...eventState,
              optionPer: e.target.value,
            });
          }}
          isRow
        />

        <GTable
          headers={headersOptions}
          data={sortedOptions || []}
          contextActions={contextActions}
          borders
        />
      </>

      {/* ADDONS */}
      <Box className="mg-bt-lg">
        <Box className="mg-bt-md space-between" flex style={{ marginTop: 24 }}>
          <Box width="auto">
            <Typography
              variant="h5"
              className="mg-bt-xs"
              translation="calendar"
            >
              Add optional Addons
            </Typography>
            <Typography color="subdued" variant="text-3" translation="calendar">
              Add or edit special add-ons if needed
            </Typography>
          </Box>

          <Button
            style={{ width: "20%" }}
            endIcon={() => <Add style={{ marginLeft: 8 }} />}
            onClick={() => handleAdd("addons")}
            translation="calendar"
          >
            New addon
          </Button>
        </Box>

        <GTable
          headers={headersOptionalAddons}
          data={sortedAddons || []}
          contextActions={contextActionsAddons}
          borders
        />
      </Box>

      {/* MODALS */}
      <EditOptionModal
        setEventState={setEventState}
        eventState={eventState}
        openEditOptions={openEditOptions}
        setOpenEditOptions={setOpenEditOptions}
      />
      <EditAddonModal
        setEventState={setEventState}
        eventState={eventState}
        openEditOptions={openEditOptions}
        setOpenEditOptions={setOpenEditOptions}
      />
    </Box>
  );
};

export default EventDiner;
