import React, { useState, useRef, useEffect } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import Popover from 'Components/Atoms/Popover';
import IconButton from 'Components/Atoms/IconButton';
import { ArrowLeft, ArrowRight } from 'Components/Atoms/Icons';
import { classHelper, dateHelper, daysInFuture, noop } from 'utils/helper';
import Button from 'Components/Atoms/Button';
import useDateString from 'CustomHooks/useDateString';
import Calendar from 'Components/Molecules/Calendar';
import { Size } from 'utils/types';

export type DateDropdownProps = {
  disabled?: boolean;
  value: string;
  onChange?: (newDate: string) => void;
  shiftDetector?: (date: Date) => boolean;
  disabledDetector?: (date: Date) => boolean;
  onMonthChange?: (month: string) => void;
  minDate?: string;
  style?: React.CSSProperties;
  className?: string;
  padding?: Size;
  type?: 'day' | 'week' | 'month';
};
const DateDropdown = ({
  disabled,
  type = 'day',
  value: date,
  onChange = noop,
  onMonthChange = noop,
  disabledDetector,
  shiftDetector,
  minDate,
  style,
  padding = 'sm',
  className,
}: DateDropdownProps) => {
  const [open, setopen] = useState<null | HTMLElement | EventTarget>(null);
  const month = useRef<string | null>(date ? date.slice(0, 7) : null);

  const handleOpen = (e: React.SyntheticEvent) =>
    setopen(!disabled ? e.currentTarget.parentElement : null);

  const handleClose = () => setopen(null);

  const isToday = date === dateHelper();

  const next = () => {
    if (type === 'day') {
      onChange(daysInFuture(1, date));
    } else if (type === 'week') {
      onChange(daysInFuture(7, date));
    } else if (type === 'month') {
      onChange(changeMonth(new Date(date)));
    }
  };
  const prev = () => {
    let x = date;

    if (type === 'day') {
      x = daysInFuture(-1, date);
    } else if (type === 'week') {
      x = daysInFuture(-(7 - new Date(date).getDay() + 1), date);
    } else if (type === 'month') {
      x = changeMonth(new Date(date), -1);
    }

    if (!minDate || x >= minDate) {
      onChange(x);
    }
  };

  const today = () => onChange(dateHelper());

  const dateStr = useDateString(date, type);

  useEffect(() => {
    if (!month.current && date) {
      month.current = date.slice(0, 7);
    } else if (!!month.current && date && !date.startsWith(month.current)) {
      month.current = date.slice(0, 7);
      onMonthChange(date.slice(0, 7));
    }
  }, [date]);

  return (
    <>
      <Box
        inlineFlex
        className={classHelper(['space-between', className])}
        padding={padding}
        width="auto"
        style={style}
      >
        <IconButton size="small" onClick={prev}>
          <ArrowLeft />
        </IconButton>
        <Box
          style={{
            width: type === 'week' ? '11rem' : '9rem',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            variant="transparent"
            typographyProps={{ variant: 'h5' }}
            style={{ padding: '4px 8px', width: 'inherit' }}
            onClick={handleOpen}
          >
            {dateStr}
          </Button>
          <IconButton size="small" onClick={today}>
            <svg height="12px" width="12px" viewBox="0 0 24 24">
              <circle
                cx={12}
                cy={12}
                r={6}
                stroke="var(--color-primary)"
                fill={isToday ? 'var(--color-primary)' : 'transparent'}
                strokeWidth={2}
              />
            </svg>
          </IconButton>
        </Box>
        <IconButton size="small" onClick={next}>
          <ArrowRight />
        </IconButton>
      </Box>
      <Popover
        anchorEl={open}
        open={!!open && !disabled}
        onClose={handleClose}
        placement="bottom"
        width="auto"
        className="date-dropdown-wrapper"
        style={{ zIndex: 10000 }}
      >
        <Calendar
          value={date}
          onChange={onChange}
          disabledDetector={disabledDetector}
          shiftDetector={shiftDetector}
          minDate={minDate}
        />
      </Popover>
    </>
  );
};

export default DateDropdown;

function changeMonth(date: Date, add = 1) {
  date.setMonth(date.getMonth() + add);

  return dateHelper(date);
}
