import { Event } from 'gastronaut-shared/types/documents/restaurants';
import {
  AnyShift,
  Date as DateType,
  ShiftType,
} from 'gastronaut-shared/types/helper/shifts';
import React, { useMemo } from 'react';
import { daysInFuture } from 'utils/helper';
import useCollection from './useCollection';

export function flattenObject<Type = any>(
  obj: Record<string, Type>,
  delimiter: string = '.'
) {
  const result: { [key: string]: Type } = {};

  function flatten(obj: Record<string, any>, parentKey: string = '') {
    Object.keys(obj ?? {}).forEach((key) => {
      const value = obj[key];
      const newKey = parentKey ? `${parentKey}${delimiter}${key}` : key;

      if (typeof value === 'object' && !Array.isArray(value)) {
        flatten(value, newKey);
      } else {
        result[newKey] = value;
      }
    });
  }

  flatten(obj);
  return result;
}

function compareShifts(before: AnyShift, after: AnyShift) {
  let changes: string[] = [];

  const beforeFlattend = flattenObject(before);
  const afterFlattend = flattenObject(after);

  Object.keys({ ...beforeFlattend, ...afterFlattend }).forEach((key) => {
    if (
      JSON.stringify(beforeFlattend[key]) !== JSON.stringify(afterFlattend[key])
    ) {
      changes.push(key);
    }
  });

  return changes;
}

const useRecentShifts = (
  restaurantId: string | null,
  type: ShiftType = ShiftType.RESERVATION,
  dates: DateType<AnyShift>[]
) => {
  const [shifts] = useCollection<AnyShift>(
    `restaurants/${restaurantId ?? ''}/shiftsV02`,
    { filter: [['type', '==', type]] }
  );

  const filteredShifts = useMemo(() => {
    if (!shifts.data.length) return [];

    let allShifts = shifts.data
      .sort((a, b) => {
        if ((a.endAt ?? '2030-01-01') < (b.endAt ?? '2030-01-01')) {
          return 1;
        } else if ((a.endAt ?? '2030-01-01') > (b.endAt ?? '2030-01-01')) {
          return 0;
        }

        const aDate = new Date(a.createdAt ?? a.updatedAt ?? 0)
          .toISOString()
          .slice(0, 7);
        const bDate = new Date(b.createdAt ?? b.updatedAt ?? 0)
          .toISOString()
          .slice(0, 7);

        if (aDate !== bDate) {
          return bDate.localeCompare(aDate);
        }

        return (a.regular ? 0 : 1) - (b.regular ? 0 : 1);
      })
      .map(
        ({
          startAt,
          endAt,
          dates,
          disabled,
          replacesShift,
          updatedAt,
          updatedBy,
          edited,
          ...s
        }) => ({ ...s, startAt: '' })
      );

    let skipIndexes: number[] = [];
    let specialCount = 0;

    let templates: AnyShift[] = [];

    for (let index = 0; index < allShifts.length; index++) {
      if (skipIndexes.includes(index)) continue;
      const shift = allShifts[index];
      const similarShifts: AnyShift[] = [];

      if (!shift.regular) {
        specialCount += 1;

        // if (specialCount > 10) {
        //   continue;
        // }
      }

      allShifts.forEach((s, i) => {
        if (i <= index || skipIndexes.includes(i)) return;
        let changes = compareShifts(shift, s).filter(
          (x) => !['name', 'id', 'color'].includes(x)
        );

        if (!changes.length) {
          skipIndexes.push(i);
          similarShifts.push(s);
        } else if (shift.name === s.name) {
          skipIndexes.push(i);
        }
      });

      templates.push({
        ...shift,
        name: `${shift.name}${
          similarShifts.length > 1 ? ` + ${similarShifts.length} mehr` : ''
        } ${
          shift.closed ? '(Geschlossen)' : !shift.regular ? '(Einmalig)' : ''
        }`,
      });
    }

    return templates;
  }, [type, shifts, restaurantId]);

  const recentShifts = useMemo(
    () =>
      dates.reduce((acc, cV) => {
        cV.shifts.forEach((s) => {
          if (!acc.find((s1) => s.id === s1.id)) {
            acc.unshift(s as AnyShift);
          }
        });
        return acc;
      }, filteredShifts),
    [dates, filteredShifts]
  );

  const eventTemplates = useMemo(() => {
    return filteredShifts.reduce((acc, cV) => {
      let event = cV?.additional?.eventV02;

      if (
        !!event &&
        !acc.find(
          (x) =>
            x.id === event?.id ||
            (!!x.title?.['de'] && x.title?.['de'] === event?.title?.['de'])
        )
      ) {
        return [...acc, event];
      }

      return acc;
    }, [] as Event[]);
  }, [filteredShifts]);

  return {
    recentShifts,
    eventTemplates,
  };
};

export default useRecentShifts;
