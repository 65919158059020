import { Close, RemoveRedEye } from '@material-ui/icons';
import Box from 'Components/Atoms/Box';
import IconButton from 'Components/Atoms/IconButton';
import { slateToHtml } from 'Components/Atoms/RichTextField';
import Typography from 'Components/Atoms/Typography';
import { ReservationContext } from 'Contexts/ReservationContext';
import React, { useContext, useMemo, useState } from 'react';

function normalizeHtml(html: string) {
  return html
    .replace(
      /\<h1\>/gm,
      '<strong style="font-size:110%;margin-bottom: 4px; display:inline-block;" >'
    )
    .replace(/\<\/h1\>/gm, '</strong>')
    .replace(
      /\<h2\>/gm,
      '<strong style="font-size:105%;margin-bottom: 4px; display:inline-block;">'
    )
    .replace(/\<\/h2\>/gm, '</strong>');
}

const ShiftComments = () => {
  const { shiftComments, shifts } = useContext(ReservationContext);

  const comments = useMemo(() => {
    return shiftComments
      .map((c) => {
        return {
          id: c.shiftId,
          title: shifts?.find((s) => s.id === c.shiftId)?.name || 'Shift',
          message: normalizeHtml(slateToHtml(c.slateDescendants)),
        };
      })
      .filter(
        (x) =>
          !!x.message
            .replace(/<[^>]p>/gm, ' ')
            .replace(/<[^>]*>/gm, '')
            .replace(/&gt;/gm, '')
            .trim()
      );
  }, [shiftComments, shifts]);

  const [hiddenComments, setHiddenComments] = useState<string[]>([]);

  return (
    <>
      {comments?.map((c) => (
        <Box key={c.id} style={{ padding: `8px 12px` }}>
          <Box
            style={{
              borderRadius: 4,
              padding: 4,
              border: '1px solid var(--color-border)',
              position: 'relative',
              backgroundColor: hiddenComments.includes(c.id)
                ? ''
                : 'var(--color-text)',
              color: hiddenComments.includes(c.id) ? '' : 'var(--color-paper)',
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setHiddenComments((x) =>
                x.includes(c.id) ? x.filter((x) => x !== c.id) : [...x, c.id]
              );
            }}
          >
            <IconButton
              size="x-small"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setHiddenComments((x) =>
                  x.includes(c.id) ? x.filter((x) => x !== c.id) : [...x, c.id]
                );
              }}
              style={{
                top: 4,
                right: 4,
                opacity: 0.4,
                position: 'absolute',
                color: hiddenComments.includes(c.id)
                  ? ''
                  : 'var(--color-paper)',
              }}
            >
              {!hiddenComments.includes(c.id) ? (
                <Close color="inherit" />
              ) : (
                <RemoveRedEye />
              )}
            </IconButton>
            <Typography variant="text-3" weight="bold" color="inherit" block>
              {c.title}
            </Typography>
            {!hiddenComments.includes(c.id) && (
              <Typography
                variant="text-3"
                block
                color="inherit"
                dangerouslySetInnerHTML={{
                  __html: c.message,
                }}
              />
            )}
          </Box>
        </Box>
      ))}
    </>
  );
};

export default ShiftComments;
