import { Amount } from '@mollie/api-client/dist/types/src/data/global';
import Box from 'Components/Atoms/Box';
import Button from 'Components/Atoms/Button';
import InputAdornmentAtom from 'Components/Atoms/InputAdornment';
import TextField from 'Components/Atoms/TextField';
import Typography from 'Components/Atoms/Typography';
import React from 'react';

function createSteps(maxAmount?: Amount) {
  let max = parseFloat(maxAmount?.value ?? '100');

  let steps = [10, 20, 50, 100];

  if (steps.includes(parseInt(max.toFixed(0)))) {
    return steps;
  } else {
    steps.push(max);
  }

  return steps.sort((a, b) => a - b).slice(0, 4);
}

const AmountField: React.FC<{
  value: Amount;
  onChange: (value: Amount, blur?: boolean) => void;
  maxAmount?: Amount;
  hideSteps?: boolean;
  className?: string;
  label?: string;
  disabled?: boolean;
}> = ({
  value,
  onChange,
  maxAmount,
  label = 'Amount',
  hideSteps = false,
  className,
  disabled = false,
}) => {
  const steps = createSteps(maxAmount);

  return (
    <>
      <TextField
        label={label}
        onChange={(e) =>
          onChange(
            {
              value: e.target.value,
              currency: 'EUR',
            },
            false
          )
        }
        onBlur={(e) =>
          onChange(
            {
              value: parseFloat(e.target.value.replace(',', '.')).toFixed(2),
              currency: 'EUR',
            },
            true
          )
        }
        disabled={disabled}
        value={value?.value?.replace('.', ',')}
        fullWidth
        style={{ marginBottom: 8 }}
        className={className}
        InputProps={{
          endAdornment: (
            <InputAdornmentAtom position="end">
              <Typography color="subdued">€</Typography>
            </InputAdornmentAtom>
          ),
        }}
      />
      {!hideSteps && (
        <Box style={{ display: 'flex', gap: 8 }}>
          {steps.map((step) => (
            <Button
              key={step}
              disabled={maxAmount && parseFloat(maxAmount.value) < step}
              onClick={() => onChange({ ...value, value: step.toFixed(2) })}
            >
              {step.toFixed(2).replace('.', ',') + '€'}
            </Button>
          ))}
        </Box>
      )}
    </>
  );
};

export default AmountField;
