import React, { useEffect, useRef, useState, useContext } from "react";
import firebase, { db } from "utils/firebase";
import {
  TakeAwayOrder,
  TakeAwayAnalytics,
  TakeAwayOrderSnippet,
} from "types/takeAway";
import axios from "axios";
import { RestaurantContext } from "Contexts/RestaurantContext";
import server from "utils/server";
import { calcItemPrice, stringifyOrderOptions } from "utils/helper";

type useOrdersType = {
  (restaurantId: string | null, date: string): {
    orders: TakeAwayOrder[];
    handleOrderAction: (
      id: string,
      action: string,
      payload?: any
    ) => Promise<void>;
    analytics: TakeAwayAnalytics;
  };
};

const getOrderCoordinates = async (
  order: TakeAwayOrder,
  ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
) => {
  if (order.orderMethod === "pickup" || !order.address || order.coordinates) {
    return order;
  }

  try {
    let config: any = {
      method: "get",
      url: `https://api.gastronaut.ai/v02/routes/geo-coding/${order.address.street.replace(
        "/",
        ""
      )} ${order.address.zipCode.replace("/", "")} ${order.address.city.replace(
        "/",
        ""
      )}`,
      headers: {},
    };

    const { data } = await axios(config);

    if (!data.features || !data.features.length) {
      return order;
    }

    let [lng, lat] = data.features[0].geometry.coordinates;

    ref.update({ coordinates: { lat, lng } });

    return { ...order, coordinates: { lat, lng } };
  } catch (error) {
    return order;
  }
};

const useOrders: useOrdersType = (restaurantId, date) => {
  const { alert } = useContext(RestaurantContext);
  const [orders, setorders] = useState<TakeAwayOrder[]>([]);
  const [loading, setloading] = useState<boolean>(true);
  const [listener, setlistener] = useState<Function[]>([]);
  const [error, seterror] = useState<null | string>(null);

  const [months, setmonths] = useState<string[]>([date.slice(0, 7)]);
  const [analytics, setanalytics] = useState<TakeAwayAnalytics>({
    dates: {},
    lastOrders: [],
    listener: [],
    loading: true,
    error: null,
  });

  const collectionRef = db.collection(
    `/restaurants/${restaurantId}/takeAwayOrders`
  );

  useEffect(() => {
    if (listener && listener.length) {
      listener.forEach((unsubsribe) => unsubsribe());
    }

    if (!months.find((m) => date.startsWith(m))) {
      setmonths((m) => [...m, date.slice(0, 7)]);
    }

    if (date && restaurantId) {
      const ref = collectionRef.where("date", "==", date);

      setloading(true);
      setorders([]);
      seterror(null);

      let unsubscribe = ref.onSnapshot(
        async (querySnapshots: firebase.firestore.QuerySnapshot) => {
          let arr: Promise<TakeAwayOrder>[] = [];

          querySnapshots.forEach((doc) => {
            let data: any = doc.data();
            arr.push(getOrderCoordinates({ ...data, id: doc.id }, doc.ref));
          });

          let orderArr = await Promise.all(arr);

          setorders(orderArr);
          setloading(false);
        },
        (err: Error) => {
          seterror(err.message);
          setloading(false);
        }
      );

      setlistener([unsubscribe]);
    }
    return () => {
      if (listener && listener.length) {
        listener.forEach((unsubsribe) => unsubsribe());
      }
    };
  }, [db, date, restaurantId]);

  const handleOrderAction = async (id: string, type: string, payload?: any) => {
    const ref = collectionRef.doc(id);

    switch (type) {
      case "addressChange": {
        await ref.update(payload);
        break;
      }
      case "dateChange": {
        await ref.update({ date: payload });
        break;
      }
      case "edit": {
        const order = orders.find((o) => o.id === id);

        if (!order) return;

        const cart = Array.from(order.cart);

        let deduct = 0;

        cart.forEach((item, index) => {
          if (item.canceled) return;

          let optionStr = !!item.options?.length
            ? stringifyOrderOptions(item)
            : "";

          let itemId = optionStr ? `${item.id}-${optionStr}` : item.id;

          if (payload[itemId] === undefined) return;

          let amount = payload[itemId];

          let canceled = item.amount - amount;

          if (canceled === 0) return;

          deduct += calcItemPrice(item, canceled);

          if (amount === 0) {
            cart[index] = { ...item, canceled: true };
            return;
          }

          if (canceled > 0) {
            cart.push({ ...item, canceled: true, amount: canceled });
            cart[index] = { ...item, amount };
            return;
          }
        });

        await ref.update({ cart, total: order.total - deduct });

        break;
      }
      case "printInvoice": {
        window.open(
          `https://api.gastronaut.ai/v02/invoice/${restaurantId}/${id}.pdf`,
          "_blank"
        );
        break;
      }
      case "printHospitality": {
        window.open(
          `https://api.gastronaut.ai/v02/invoice/${restaurantId}/${id}.pdf?hospilityReceipt=true`,
          "_blank"
        );
        break;
      }
      case "printReceipt": {
        server.get(`/v02/menues/takeAway/${restaurantId}/getBon/${id}`);
        break;
      }
      case "cancelOrder": {
        const order = orders.find((o) => o.id === id);

        console.log({ order });

        if (!order) return;

        alert({
          title: "Are you sure you want to cancel this Order?",
          titleTranslation: "takeAway",
          description: "This can not be undone.",
          onSubmit: () => {
            const canceled = true;

            const storno = order.total;

            const cart = order.cart.map((c) => ({ ...c, canceled }));

            ref.update({ canceled, storno, cart });
          },
        });

        break;
      }
      case "status": {
        ref.update({ status: payload });
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (months.length) {
      let firstMonth = months[0];
      let lastMonth = months[months.length - 1];

      const ref = db
        .collection(`restaurants/${restaurantId}/takeAwayAnalytics`)
        .where("__name__", ">=", firstMonth)
        .where("__name__", "<=", lastMonth);

      if (!!analytics?.listener?.length) {
        analytics.listener.forEach((unsubscribe) => unsubscribe());
      }

      setanalytics((a) => ({ ...a, loading: true, error: null }));

      let unsubscribe = ref.onSnapshot(
        (responses) => {
          let dates: Record<string, TakeAwayOrderSnippet[]> = {};

          responses.forEach((doc) => {
            let data = doc.data();
            dates = { ...dates, ...data };
          });

          let lastOrders = Object.keys(dates)
            .reduce((acc: TakeAwayOrderSnippet[], date) => {
              let arr = dates[date];

              if (!Array.isArray(arr)) return acc;

              return [...acc, ...arr.map((a) => ({ ...a, date }))];
            }, [])
            .filter((o) => o.date >= date)
            .sort((a, b) => b.createdAt - a.createdAt)
            .slice(0, 20);

          setanalytics((a) => ({
            ...a,
            dates,
            loading: false,
            error: null,
            lastOrders,
          }));
        },
        (error) => {
          setanalytics((a) => ({ ...a, loading: false, error: error.message }));
        }
      );

      setanalytics((a) => ({ ...a, listener: [unsubscribe] }));
    }

    return () => {
      if (!!analytics?.listener?.length) {
        analytics.listener.forEach((unsubscribe) => unsubscribe());
      }
    };
  }, [months]);

  return {
    orders,
    handleOrderAction,
    ordersLoading: loading,
    ordersError: error,
    analytics,
  };
};

export default useOrders;
