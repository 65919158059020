import Box from "Components/Atoms/Box";
import TextField from "Components/Atoms/TextField";
import Typography from "Components/Atoms/Typography";
import Modal, { Action } from "Components/Molecules/Modal";
import { AuthContext } from "Contexts/AuthContext";
import { RestaurantContext, Severity } from "Contexts/RestaurantContext";
import React, { useContext, useState } from "react";

type PasswordModalProps = {
  open: boolean;
  onClose: () => void;
};

const PasswordModal: React.FC<PasswordModalProps> = ({ open, onClose }) => {
  const { handleChangePassword } = useContext(AuthContext);
  const { newToast } = useContext(RestaurantContext);

  const [oldPwd, setOldPwd] = useState<string>("");
  const [newPwd, setNewPwd] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (!oldPwd) {
      setError("Please enter your actual password");
      return;
    }
    if (!newPwd) {
      setError("Please choose a new password");
      return;
    }
    setLoading(true);
    const result = await handleChangePassword(oldPwd, newPwd);
    if (result.success) {
      newToast(result.message, Severity.SUCCESS, "auth");
      setLoading(false);
      onClose();
    } else {
      setError(result.message);
      setLoading(false);
    }
  };

  const actions: Action[] = [
    {
      id: "cancel",
      label: "Cancel",
      onClick: onClose,
    },
    {
      id: "submit",
      label: "Save",
      onClick: handleSubmit,
      variant: "primary",
      loading,
    },
  ];

  return (
    <Modal
      {...{ actions, open, onClose }}
      title="Change password"
      titleTranslation="auth"
      maxWidth="sm"
      dontClose
      dontCloseAfterSubmit
      width={450}
    >
      <Box>
        <TextField
          fullWidth
          type="password"
          label="Enter your actual password"
          labelTranslation="auth"
          value={oldPwd}
          onChange={(e) => setOldPwd(e.target.value)}
          required
          error={error.includes("actual password")}
          errorMessage={error.includes("actual password") ? error : ""}
        />
        <TextField
          fullWidth
          type="password"
          label="Enter your new password"
          labelTranslation="auth"
          value={newPwd}
          onChange={(e) => setNewPwd(e.target.value)}
          required
          error={error.includes("new password")}
          errorMessage={error.includes("new password") ? error : ""}
        />
        {!!error &&
          !error.includes("new password") &&
          !error.includes("actual password") && (
            <Typography variant="text-3" color="error" translation={"auth"}>
              {error}
            </Typography>
          )}
      </Box>
    </Modal>
  );
};

export default PasswordModal;
