import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const WarningBar = (props: SvgIconProps) => {
  return (
    <SvgIcon
      className="warningIcon"
      width="10"
      height="100"
      viewBox="0 0 10 100"
      fill="none"
      {...props}
    >
      <path
        d="M0 0.000823975L0 179.487H13.0666L13.0666 0.000823975H0Z"
        fill="#FFCE2B"
      />
      <path
        d="M0 78.6857L13.0666 86.8371V92.3931L0 84.2416L0 78.6857Z"
        fill="black"
      />
      <path
        d="M0 66.2276L13.0666 74.3791V79.9351L0 71.7836L0 66.2276Z"
        fill="black"
      />
      <path
        d="M0 53.7695L13.0666 61.921V67.4932L0 59.3417L0 53.7695Z"
        fill="black"
      />
      <path
        d="M0 41.3167L13.0666 49.46V55.0322L0 46.8808L0 41.3167Z"
        fill="black"
      />
      <path
        d="M0 28.8587L13.0666 37.0102V42.5742L0 34.4228L0 28.8587Z"
        fill="black"
      />
      <path
        d="M0 16.4007L13.0666 24.5521V30.1162L0 21.9648L0 16.4007Z"
        fill="black"
      />
      <path
        d="M0 3.94142L13.0666 12.0929V17.665L0 9.5136L0 3.94142Z"
        fill="black"
      />
      <path
        d="M4.72079 -0.000174046H13.0669V5.20703L4.72079 -0.000174046Z"
        fill="black"
      />
    </SvgIcon>
  );
};

export default WarningBar;
