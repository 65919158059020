import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const NotFixedReservationIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="28" height="28" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.3998 26.21V19.045C14.9198 19.145 15.4548 19.2 15.9998 19.2C16.5448 19.2 17.0798 19.145 17.5998 19.045V26.21C17.5998 26.46 17.5398 26.705 17.4308 26.9255L16.7153 28.3565C16.4203 28.946 15.5791 28.946 15.2843 28.3565L14.5688 26.9255C14.4576 26.7033 14.3998 26.4583 14.3998 26.21ZM8.7998 10.4C8.7998 6.42345 12.0233 3.19995 15.9998 3.19995C19.9748 3.19995 23.1998 6.42346 23.1998 10.4C23.1998 14.3765 19.9763 17.6 15.9998 17.6C12.0233 17.6 8.7998 14.3765 8.7998 10.4ZM12.7998 10.355C12.7998 8.59096 14.2358 7.15496 15.9998 7.15496C16.4398 7.15496 16.7998 6.70716 16.7998 6.30996C16.7998 5.91276 16.442 5.55496 15.9998 5.55496C13.3528 5.55496 11.1998 7.70796 11.1998 10.355C11.1998 10.84 11.5576 11.155 11.9998 11.155C12.442 11.155 12.7998 10.7972 12.7998 10.355Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default NotFixedReservationIcon;
