import React from 'react';

const PaidIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width ?? props.height ?? '20'}
    height={props.height ?? '20'}
    viewBox="0 0 140 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M110.833 81.6667V35C110.833 28.5834 105.583 23.3334 99.1667 23.3334H17.5C11.0834 23.3334 5.83337 28.5834 5.83337 35V81.6667C5.83337 88.0834 11.0834 93.3334 17.5 93.3334H99.1667C105.583 93.3334 110.833 88.0834 110.833 81.6667ZM99.1667 81.6667H17.5V35H99.1667V81.6667ZM58.3334 40.8334C48.65 40.8334 40.8334 48.65 40.8334 58.3334C40.8334 68.0167 48.65 75.8334 58.3334 75.8334C68.0167 75.8334 75.8334 68.0167 75.8334 58.3334C75.8334 48.65 68.0167 40.8334 58.3334 40.8334ZM134.167 40.8334V105C134.167 111.417 128.917 116.667 122.5 116.667H23.3334C23.3334 110.833 23.3334 111.417 23.3334 105H122.5V40.8334C128.917 40.8334 128.333 40.8334 134.167 40.8334Z"
      fill="currentColor"
    />
  </svg>
);

export default PaidIcon;
