import React from 'react';

const FindTable = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="28px"
      height="28px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.28554 3.85259C3.31656 3.39003 3.83353 3.22182 4.2385 3.25378H19.7649C20.1647 3.22519 20.6765 3.38834 20.7127 3.84417C20.7666 4.35309 20.7573 4.86543 20.748 5.3777V5.37773C20.7428 5.66799 20.7375 5.95823 20.7438 6.2478C21.2349 6.24949 21.9742 6.30667 21.9707 6.94585C22.0057 8.79889 22.0007 10.6536 21.9957 12.5084V12.5084C21.992 13.8525 21.9884 15.1967 22 16.5402C21.9939 16.6116 21.9923 16.6867 21.9908 16.7629V16.763C21.9854 17.0294 21.9796 17.3104 21.776 17.504C21.5941 17.7005 21.326 17.712 21.0642 17.7233C20.9539 17.7281 20.8448 17.7328 20.7438 17.7512C20.7377 18.0383 20.743 18.3259 20.7483 18.6133C20.758 19.1392 20.7677 19.6648 20.7076 20.1868C20.6524 20.6225 20.1613 20.7722 19.7753 20.747C16.323 20.7481 12.8708 20.7477 9.41858 20.7473C7.69246 20.7471 5.96634 20.747 4.24022 20.747C3.8456 20.7739 3.34069 20.6258 3.29071 20.1784C3.23301 19.6596 3.24247 19.1381 3.25193 18.6168C3.25716 18.3286 3.26238 18.0404 3.25625 17.7529C2.76684 17.7529 2.03274 17.6957 2.02585 17.0616C1.99518 15.225 1.99972 13.3873 2.00427 11.5495C2.00764 10.1859 2.01101 8.82224 2 7.45887C2.0046 7.39191 2.00571 7.32219 2.00684 7.25147C2.01122 6.97752 2.01584 6.68852 2.22402 6.48665C2.41012 6.30266 2.67234 6.29014 2.92967 6.27785H2.92967C3.04168 6.27249 3.15277 6.26719 3.25625 6.2478C3.26253 5.95687 3.25735 5.66527 3.25216 5.37356V5.37356C3.24314 4.86586 3.2341 4.35787 3.28554 3.85259ZM15 10.9998C15 11.7398 14.79 12.4298 14.43 13.0198L17 15.5898L15.59 16.9998L13.02 14.4298C12.43 14.7798 11.74 14.9998 11 14.9998C8.79 14.9998 7 13.2098 7 10.9998C7 8.7898 8.79 6.9998 11 6.9998C13.21 6.9998 15 8.7898 15 10.9998ZM9 10.9998C9 12.0998 9.9 12.9998 11 12.9998C12.1 12.9998 13 12.0998 13 10.9998C13 9.8998 12.1 8.9998 11 8.9998C9.9 8.9998 9 9.8998 9 10.9998Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FindTable;
