import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const PartlyLocked = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M19.5997 7.16004H18.7197V5.40003C18.7197 2.97121 16.7484 1 14.3196 1C12.1562 1 10.3558 2.564 9.98822 4.62229C9.68281 4.55513 9.36554 4.51972 9.04005 4.51972C6.61123 4.51972 4.64002 6.49094 4.64002 8.91975V10.6798H3.76001C2.79201 10.6798 2 11.4718 2 12.4398V21.2398C2 22.2078 2.79201 22.9998 3.76001 22.9998H14.3201C15.2881 22.9998 16.0801 22.2078 16.0801 21.2398V19.4801H19.5997C20.5677 19.4801 21.3597 18.6881 21.3597 17.7201V8.92005C21.3597 7.95205 20.5677 7.16004 19.5997 7.16004ZM16.0801 17.7201V12.4398C16.0801 11.4718 15.2881 10.6798 14.3201 10.6798H13.4401V8.92005H19.5997V17.7201H16.0801ZM13.0737 7.16004H17.0476V5.40003C17.0476 3.89522 15.8244 2.67201 14.3196 2.67201C12.8362 2.67201 11.6265 3.86065 11.5924 5.33595C12.2372 5.79615 12.7527 6.42595 13.0737 7.16004ZM11.1235 7.16004H9.0396C8.07159 7.16004 7.27959 7.95205 7.27959 8.92005V10.6798H6.31203V8.91975C6.31203 7.41494 7.53524 6.19174 9.04005 6.19174C9.87472 6.19174 10.6228 6.56806 11.1235 7.16004ZM9.0396 10.6798V8.92005H11.7681V10.6798H9.0396ZM7.28003 16.8398C7.28003 17.8078 8.07204 18.5998 9.04005 18.5998C10.0081 18.5998 10.8001 17.8078 10.8001 16.8398C10.8001 15.8718 10.0081 15.0798 9.04005 15.0798C8.07204 15.0798 7.28003 15.8718 7.28003 16.8398Z"
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
};

export default PartlyLocked;
