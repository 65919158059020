import React, { useState } from "react";
import Box from "Components/Atoms/Box";
import "./styles.scss";
import Typography from "Components/Atoms/Typography";
import { ArrowDropDown } from "Components/Atoms/Icons";
import Popover from "Components/Atoms/Popover";
import OccassionsAndSpacesPopover from "./Components/OccAndSpacesPopover";

type State = {
  spaces: string[];
  occassions: string[];
  matchBoth?: boolean; // matchBoth = TRUE => and
};

const joinArrays = (...args: (string[] | undefined)[]) =>
  args.reduce((acc: string[], cV) => [...acc, ...(cV ?? [])], []);

export type OccassionsAndSpacesProps = {
  state: State;
  onChange: (newState: State) => void;
  onAddOccassion?: () => void;
  occassions: { id: string; title: string }[];
  spaces: { id: string; title: string }[];
  light?: boolean;
  style?: React.CSSProperties;
};

const OccassionsAndSpaces: React.FC<OccassionsAndSpacesProps> = ({
  state,
  occassions,
  spaces,
  onChange,
  light = false,
  style,
  onAddOccassion,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const stateOccassions = state.occassions;
  const stateSpaces = state.spaces;

  const text = joinArrays(
    stateSpaces?.map((s) => spaces.find((s1) => s1.id === s)?.title || s),
    stateOccassions.map((o) => occassions.find((o1) => o1.id === o)?.title || o)
  ).reduce((acc, cV, i, arr) => {
    if (i === 0) {
      return cV;
    } else if (arr.length - 1 === i) {
      return `${acc} ${state.matchBoth ? "&" : "or"} ${cV}`;
    } else {
      return `${acc}, ${cV}`;
    }
  }, "");

  const isLight: any = light
    ? {
        dataLabel: "Only with Tableplan",
        translation: "reservations",
      }
    : {};

  return (
    <>
      <Box
        outline
        background
        padding="sm"
        flex
        {...isLight}
        className={`space-between clickable ${
          light && "not-available na-paper na-secondary"
        }`}
        style={style}
        onClick={(e) => !light && setAnchorEl(e.currentTarget)}
      >
        <Box className="space-between" style={{ width: "100%" }}>
          <Typography
            translation="common"
            variant="text-3"
            style={{ width: "fit-content" }}
            color="subdued"
          >
            {text}
          </Typography>
          <ArrowDropDown style={{ fill: "var(--color-text-subdued)" }} />
        </Box>
      </Box>
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        placement="bottom"
        padding={12}
      >
        <OccassionsAndSpacesPopover
          {...{
            state,
            onChange,
            occassions,
            spaces,
            stateOccassions,
            stateSpaces,
            onAddOccassion,
          }}
        />
      </Popover>
    </>
  );
};

export default OccassionsAndSpaces;
