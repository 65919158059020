import React from "react";
import { AppBar, Dialog, Slide, Toolbar, IconButton } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import { Close } from "Components/Atoms/Icons";
import Typography from "../Typography";
import Button from "../Button";
import { Translations } from "utils/types";
import Box from "../Box";

import "./styles.scss";

export type FullScreenModalProps = {
  open: boolean;
  onClose: VoidFunction;
  onSubmit?: VoidFunction;
  title: string;
  titleTranslation?: Translations;
  children: React.ReactNode;
  fixedNavBar?: boolean;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenModal: React.FC<FullScreenModalProps> = ({
  open,
  onClose,
  onSubmit,
  title,
  titleTranslation,
  children,
  fixedNavBar,
}) => {
  return (
    <Dialog
      className="fullscreen-modal"
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <Box style={{ position: fixedNavBar ? "fixed" : "relative", zIndex: 10 }}>
        <AppBar
          className="fullscreen-modal-app-bar"
          color="transparent"
          elevation={0}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <Typography
              variant="h4"
              className="fullscreen-modal-title"
              translation={titleTranslation}
            >
              {title}
            </Typography>
            {!!onSubmit && (
              <Button variant="outlined" onClick={onSubmit}>
                Save
              </Button>
            )}
          </Toolbar>
        </AppBar>
      </Box>
      <div style={{ marginTop: "64px" }}>{children}</div>
    </Dialog>
  );
};

export default FullScreenModal;
