import React, { CSSProperties } from "react";
import Box from "Components/Atoms/Box";
import "./styles.scss";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  useMediaQuery,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Translations } from "utils/types";

import { StylesProvider } from "@material-ui/core/styles";
import Typography from "../Typography";

export type RadioButtonGroupProps = {
  value: string | number | null;
  options: {
    value: string | number | null;
    label: string;
    description?: string;
    desctriptionTranslation?: Translations;
    disabled?: boolean;
    translation?: Translations;
  }[];
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: number | string | null
  ) => void;
  name?: string;
  style?: React.CSSProperties;
  className?: string;
  multiRowLabel?: boolean;
  isRow?: boolean;
};

const RadioButtonGroup = ({
  options = [],
  value,
  onChange,
  name,
  className,
  multiRowLabel = false,
  isRow = false,
  ...props
}: RadioButtonGroupProps) => {
  const { t } = useTranslation([
    ...new Set(options.map((o) => o.translation || "common")),
  ]);

  const isMobile = useMediaQuery("(max-width: 450px)");

  if (isMobile)
    return (
      <Box>
        <RadioGroup
          className="radio-button-group"
          {...props}
          aria-label={name}
          name={name}
          value={value}
          onChange={(e, value) =>
            !!onChange && onChange(e, value === "null" ? null : value)
          }
        >
          {options.map((opt) => (
            <Box
              key={"m-radiobutton-" + opt.label}
              className="mobile-res-filter-checkboxes mobile-res-radiogroup"
              style={{ padding: 9 }}
            >
              <FormControlLabel
                key={opt.value}
                value={opt.value}
                control={<Radio color="primary" />}
                disabled={opt.disabled}
                label={
                  multiRowLabel ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography
                        variant="h5"
                        color="textPrimary"
                        translation={opt.translation}
                      >
                        {opt.label}
                      </Typography>
                      <Typography
                        variant="text-3"
                        color="subdued"
                        translation={
                          opt.desctriptionTranslation || opt.translation
                        }
                      >
                        {opt.description || ""}
                      </Typography>
                    </div>
                  ) : opt.translation !== null ? (
                    t(opt.label)
                  ) : (
                    opt.label
                  )
                }
              />
            </Box>
          ))}
        </RadioGroup>
      </Box>
    );

  return (
    <Box>
      <RadioGroup
        className="radio-button-group"
        {...props}
        aria-label={name}
        name={name}
        value={value}
        onChange={onChange}
        style={{ display: "flex", flexDirection: isRow ? "row" : "column" }}
      >
        {options.map((opt) => (
          <FormControlLabel
            key={opt.value}
            value={opt.value}
            control={<Radio color="primary" />}
            disabled={opt.disabled}
            label={
              multiRowLabel ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography
                    variant="h5"
                    color="textPrimary"
                    translation={opt.translation}
                  >
                    {opt.label}
                  </Typography>
                  <Typography
                    variant="text-3"
                    color="subdued"
                    translation={opt.desctriptionTranslation || opt.translation}
                  >
                    {opt.description || ""}
                  </Typography>
                </div>
              ) : opt.translation !== null ? (
                t(opt.label)
              ) : (
                opt.label
              )
            }
          />
        ))}
      </RadioGroup>
    </Box>
  );
};

export default RadioButtonGroup;
