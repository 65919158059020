import React, { useEffect, useState } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import FullScreenModal from 'Components/Atoms/FullScreenModal';
import Button from 'Components/Atoms/Button';
import { ReservationShift, ReservationLength } from 'types/shifts';
import { FloorPlan } from 'types/reservations';
import SlotSettings, { SlotState } from 'App/Settings/Components/SlotSettings';
import ReservationLengthRules from '../ReservationLengthRules';

export type DetailedShiftSettingsProps = {
  activeShift: {
    shift: Partial<ReservationShift>;
    date: string;
    type: 'new' | 'edit' | 'duplication' | 'close';
  };
  setactiveShift: React.Dispatch<
    React.SetStateAction<{
      shift: Partial<ReservationShift>;
      date: string;
      type: 'new' | 'edit' | 'duplication' | 'close';
    } | null>
  >;
  settings: {
    occassions: { id: string; title: string }[];
    spaces: { id: string; title: string }[];
    floorPlans: FloorPlan[];
  };
};

const DEFAULT_SLOT_STATE = {
  slotsPerBlock: {
    rules: [],
    standard: 20,
  },
  blockedAt: [],
  start: 0,
  close: 96,
  service: 88,
};

const DetailedShiftSettings: React.FC<DetailedShiftSettingsProps> = ({
  activeShift,
  setactiveShift,
  settings,
}) => {
  const [open, setopen] = useState(false);
  const [slotstate, setslotstate] = useState<null | SlotState>(null);
  const [reservationLength, setreservationLength] =
    useState<null | ReservationLength>(null);

  // console.log({ activeShift, slotstate });

  useEffect(() => {
    if (!activeShift.shift.closed) {
      setslotstate({
        slotsPerBlock:
          activeShift.shift.additional?.slotsPerBlock ??
          DEFAULT_SLOT_STATE.slotsPerBlock,
        blockedAt:
          activeShift.shift.additional?.blockedAt ??
          DEFAULT_SLOT_STATE.blockedAt,
        start: activeShift.shift.start ?? DEFAULT_SLOT_STATE.start,
        close: activeShift.shift.close ?? DEFAULT_SLOT_STATE.close,
        service: activeShift.shift.service ?? DEFAULT_SLOT_STATE.service,
        maxGuests: activeShift.shift.additional?.maxGuests,
        countAllGuests: activeShift.shift.additional?.countAllGuests,
      });
      setreservationLength(
        activeShift.shift.additional?.reservationLength ?? {
          rules: [],
          standard: 8,
        }
      );
    }
  }, [open]);

  const handleSubmit = () => {
    setactiveShift((aS: any) => ({
      ...aS,
      shift: {
        ...aS.shift,
        additional: {
          ...aS.shift.additional,
          slotsPerBlock: {
            ...(aS.shift.additional?.slotsPerBlock ?? {
              standard: 20,
              rules: [],
            }),
            ...slotstate?.slotsPerBlock,
            rules:
              slotstate?.slotsPerBlock?.rules?.map((r) => ({
                ...r,
                slots: r.slots ?? r.number,
              })) ??
              aS.shift.additional.slotsPerBlock?.rules ??
              [],
          },
          blockedAt: slotstate?.blockedAt,
          reservationLength: reservationLength ?? { rules: [], standard: 8 },
          maxGuests: slotstate?.maxGuests || undefined,
          countAllGuests: slotstate?.countAllGuests,
        },
      },
    }));
    setopen(false);
  };

  return (
    <>
      <Button variant="secondary" onClick={() => setopen(true)}>
        Block Settings
      </Button>
      <FullScreenModal
        open={open}
        onClose={() => setopen(false)}
        title="Block Settings"
        onSubmit={handleSubmit}
      >
        {slotstate && (
          <SlotSettings state={slotstate} onChange={(st) => setslotstate(st)} />
        )}
        {reservationLength && (
          <ReservationLengthRules
            reservationLength={reservationLength}
            onChange={(st) => setreservationLength(st)}
            occassions={settings.occassions}
            currentShift={activeShift?.shift}
          />
        )}
      </FullScreenModal>
    </>
  );
};

export default DetailedShiftSettings;
