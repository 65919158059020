import Box from 'Components/Atoms/Box';
import Button from 'Components/Atoms/Button';
import Typography from 'Components/Atoms/Typography';
import useRestaurant from 'CustomHooks/useRestaurant';
import { useRouter } from 'CustomHooks/useRouter';
import { TicketDocument } from 'gastronaut-shared/types/documents';
import { Event } from 'gastronaut-shared/types/documents/restaurants';
import React, { useMemo, useState } from 'react';
import { Reservation } from 'types/reservations';
import { timeStampToDateAndHour, toCurrencyString } from 'utils/helper';
import { ExtendedEvent } from '..';
import { AddOnOrder } from 'App/Experiences/types/addOnOrder';

type Infos = Partial<{
  turnover: string;
  groups: number;
  tickets: number;
  maxTickets: number;
  pending: number;
  pendingGroups: number;
  options: Record<string, number>;
  addOns: Record<string, number>;
  rules: string;
}>;

const Order: React.FC<{
  order: AddOnOrder & { text: string };
}> = ({ order }) => {
  const { date, time } = timeStampToDateAndHour(order.createdAt ?? Date.now());

  const router = useRouter();

  const { status, color } = useMemo(() => {
    switch (order.status) {
      case 'missing_payments':
        if (order.createdAt && order.createdAt > Date.now() - 24 * 60 * 60000) {
          return { status: 'K. Zahlung', color: 'var(--color-secondary)' };
        }
        return { status: 'Abgebrochen', color: 'var(--color-critical)' };
      case 'failed':
        return { status: 'Abgebrochen', color: 'var(--color-critical)' };
      case 'processing':
        return { status: 'Bearbeitung', color: 'var(--color-secondary)' };
      case 'succedded':
        return { status: 'Erfolgreich', color: 'var(--color-text)' };
      default:
        return { status: order.status, color: 'var(--color-text)' };
    }
  }, [order]);

  const tables = order?.reservation?.tables?.join(', ') ?? '';

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: 12,
        cursor: order.reservation ? 'pointer' : 'not-allowed',
      }}
      onClick={() =>
        order.reservation &&
        router.replace(
          `/${order.restaurantId}/reservations/${order.reservation.date}/tablePlan?reservationId=${order.reservationId}`
        )
      }
    >
      <div style={{ flex: 10, color }}>
        <Typography variant="h5" color="inherit">
          {order?.reservation?.guest?.name ?? ''}
        </Typography>
        <Typography variant="text-3" component="p" color="subdued">
          {order.customId} • {order?.reservation?.guests} Personen
        </Typography>
        <Typography variant="text-3" component="p" color="subdued">
          {order.text}
        </Typography>
      </div>
      <div
        style={{
          marginLeft: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          color,
        }}
      >
        <Typography variant="text-4" color="inherit">
          {date}, {time}
        </Typography>
        <Typography variant="text-4" color="inherit">
          {toCurrencyString(order.total, '€', false, 1)}, {status}
        </Typography>
      </div>
    </div>
  );
};

const CurrentAddOn: React.FC<{
  addOn: {
    id: string;
    title: string;
    description: string;
    total: number;
    count: number;
    orders: (AddOnOrder & { text: string })[];
    choices: Record<string, number>;
  };
}> = ({ addOn }) => {
  const [tab, settab] = useState(0);

  return (
    <>
      <Box className="tabs">
        <Button
          className={tab === 0 ? 'active' : ''}
          onClick={() => settab(0)}
          variant="transparent"
        >
          Info
        </Button>
        <Button
          className={tab === 1 ? 'active' : ''}
          onClick={() => settab(1)}
          variant="transparent"
        >
          Tickets
        </Button>
      </Box>
      {tab === 0 && (
        <div>
          <Box className={`space-between reservation-field`}>
            <Typography variant="text-3" translation="reservations">
              Count
            </Typography>
            <Typography variant="text-3" translation="reservations">
              {addOn.count}
            </Typography>
          </Box>
          <Box className={`space-between reservation-field`}>
            <Typography variant="text-3" translation="reservations">
              Revenue
            </Typography>
            <Typography variant="text-3" translation="reservations">
              {toCurrencyString(addOn.total * 100)}
            </Typography>
          </Box>

          {!!Object.keys(addOn.choices).length && (
            <Box className="reservation-field no-border">
              <Typography
                variant="text-3"
                translation="reservations"
                weight="bold"
              >
                Optionen
              </Typography>
            </Box>
          )}
          {Object.entries(addOn.choices).map(([key, value]) => (
            <Box key={key} className={`space-between reservation-field`}>
              <Typography variant="text-3" translation="reservations">
                {key}
              </Typography>
              <Typography variant="text-3" translation="reservations">
                {value}
              </Typography>
            </Box>
          ))}
        </div>
      )}
      {tab === 1 && (
        <div>
          {addOn.orders.map((o) => (
            <Order order={o} key={o.id} />
          ))}
        </div>
      )}
    </>
  );
};

export default CurrentAddOn;
