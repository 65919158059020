import Modal from "Components/Molecules/Modal";
import React, { useContext, useEffect, useState } from "react";
import Typography from "Components/Atoms/Typography";
import Box from "Components/Atoms/Box";
import SwitchBox from "Components/Atoms/Switch";
import Translations from "App/Settings/Components/Translations";
import { HappyHoursOffer, OffersShift } from "types/shifts";
import ImageField from "Components/Organisms/ImageField";
import { db } from "utils/firebase";
import { RestaurantContext } from "Contexts/RestaurantContext";
import { Category, Meal } from "types/menu";
import AutoComplete from "Components/Atoms/AutoComplete";
import TextField from "Components/Atoms/TextField";
import IconButton from "Components/Atoms/IconButton";
import { Delete, SearchOutlined } from "Components/Atoms/Icons";
import MenuItem from "Components/Atoms/MenuItem";
import { noop } from "utils/helper";
import { InputAdornment } from "@material-ui/core";
import Button from "Components/Atoms/Button";

type OfferSettingsProps = {
  open: boolean;
  onClose: () => void;
  shift: Partial<OffersShift>;
  onSubmit: (state: Partial<OffersShift>) => void;
};

const OFFER_REDUCTION_TYPES = [
  { id: "percentage", label: "Percentage" },
  { id: "amount", label: "Discount Amount" },
  { id: "newPrice", label: "New Price" },
];

const OfferSettings: React.FC<OfferSettingsProps> = ({
  open,
  onClose,
  shift,
  onSubmit,
}) => {
  const { restaurantId } = useContext(RestaurantContext);
  const [state, setState] = useState<Partial<OffersShift>>({});
  const [error, setError] = useState<string>("");

  const [categories, setCategories] = useState<{ id: string; title: any }[]>(
    []
  );
  const [meals, setMeals] = useState<{ id: string; title: any }[]>([]);

  useEffect(() => setState(shift), [shift]);

  const getMealsAndCategories = async () => {
    try {
      const mealsRef = await db
        .collection(`restaurants/${restaurantId}/meals`)
        .doc("all")
        .get();

      if (!mealsRef.exists) {
        return;
      }

      setMeals(
        (mealsRef.data() ?? []).meals.map((m: Meal) => ({
          id: "meal-" + m.id,
          title: m.shortName,
        }))
      );

      const categoriesRef = await db
        .collection(`restaurants/${restaurantId}/mealCategories`)
        .doc("all")
        .get();

      if (!categoriesRef.exists) {
        return;
      }

      setCategories(
        (categoriesRef.data() ?? []).categories.map((c: Category) => ({
          id: "cat-" + c.id,
          title: c.shortName,
        }))
      );
    } catch (error) {
      console.log("Error on get meals and cat", error);
    }
  };

  useEffect(() => {
    getMealsAndCategories();
  }, [restaurantId]);

  const onAddAd = (checked: boolean) => {
    setState((st) => ({
      ...st,
      ad: checked
        ? { translations: { de: { title: "", description: "" } } }
        : undefined,
    }));
  };

  const handleNewOffer = (item: { id: string; title: string }) => {
    if (error.includes("item")) {
      setError("");
    }
    let [type, id] = item.id.split("-");
    if (!id) return;
    let newOffers = [...(state?.offers ?? [])];
    newOffers.push({
      id,
      name: item.title,
      type: type === "cat" ? "category" : "meal",
      reductionType: "percentage",
      value: 0,
    });
    setState((st) => ({ ...st, offers: newOffers }));
  };

  const modifyOffer = (id: string, payload: Partial<HappyHoursOffer>) => {
    let offers = (state?.offers ?? []).map((o) =>
      o.id === id ? { ...o, ...payload } : o
    );
    setState((st) => ({ ...st, offers }));
  };

  const deleteOffer = (id: string) => {
    let offers = (state?.offers ?? []).filter((o) => o.id !== id);
    setState((st) => ({ ...st, offers }));
  };

  const handleImageChange = (val: string) => {
    if (error.includes("image")) {
      setError("");
    }
    let newState = { ...state };
    if (!newState.ad) {
      newState.ad = { translations: { de: { title: "", description: "" } } };
    }
    if (newState.ad?.imageUrl) {
      newState.ad.imageUrl = val;
    } else {
      newState.ad.imageId = val;
    }
    setState(newState);
  };

  const handleSubmit = () => {
    if (!state.offers?.length) {
      setError("Please choose at least one item on which apply this offer.");
      return;
    }
    if (state.ad) {
      if (!state.ad.translations.de.title) {
        setError("Please choose title for your advert.");
        return;
      }
      if (!state.ad.imageId && !state.ad.imageUrl) {
        setError("Please select an image for your advert.");
        return;
      }
    }
    onSubmit(state);
    onClose();
  };

  console.log(error);

  return (
    <Modal
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}
      title="Settings"
      bottomPart={
        <Box className="offer-shift-setting-bottom-part">
          <Typography variant="text-3" color="error" translation="calendar">
            {error}
          </Typography>
          <Button variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </Box>
      }
      className="offerShiftSettings"
      bodyProps={{ className: "offerSettingsInnerBox" }}
      dontClose={true}
      dontCloseAfterSubmit={true}
    >
      <Typography variant="h5" translation="calendar">
        Meals and Categories
      </Typography>
      <Typography variant="text-3" color="subdued" translation="calendar">
        Search for the Meals and / or Categories on which you would like to
        apply offers
      </Typography>
      <Box flex className="searchBar">
        <SearchOutlined style={{ width: 20, height: 20 }} />
        <AutoComplete
          id="offers-meals-cat-search"
          label="Search"
          options={[
            ...categories.sort((a, b) => (a.title > b.title ? 1 : -1)),
            ...meals.sort((a, b) => (a.title > b.title ? 1 : -1)),
          ].filter(
            (item) =>
              !(state?.offers ?? []).find((i) => i.id === item.id.split("-")[1])
          )}
          onChange={(nV) => handleNewOffer(nV as { title: string; id: string })}
          style={{ width: "100%" }}
          clearOnBlur
        />
      </Box>

      <Box className="allOffers">
        {state?.offers?.map((offer) => (
          <Box className="offerRow">
            <Box flex style={{ paddingLeft: 15 }}>
              <Typography variant="text-2" color="subdued">
                {offer.name}
              </Typography>
            </Box>
            <Box flex className="rightPart">
              <TextField
                className="reductionType"
                value={offer.reductionType}
                select
                onChange={(e) =>
                  modifyOffer(offer.id ?? "", {
                    reductionType: e.target.value as
                      | "percentage"
                      | "newPrice"
                      | "amount",
                  })
                }
                paper
                style={{ color: "var(--color-text-subdued" }}
              >
                {OFFER_REDUCTION_TYPES.map((o) => (
                  <MenuItem value={o.id} translation="calendar">
                    {o.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                style={{ color: "var(--color-text-subdued" }}
                paper
                value={offer.value}
                onChange={(e) =>
                  modifyOffer(offer.id ?? "", { value: +e.target.value })
                }
                className="reductionType"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {offer.reductionType === "percentage" ? "%" : "€"}
                    </InputAdornment>
                  ),
                }}
              />
              <IconButton
                color="subdued"
                size="small"
                style={{ marginBottom: 8 }}
                onClick={() => deleteOffer(offer.id ?? "")}
              >
                <Delete />
              </IconButton>
            </Box>
          </Box>
        ))}
      </Box>
      <Box flex className="advertBannerTitle">
        <div>
          <Typography variant="h5" translation="calendar">
            Advert Banner
          </Typography>
          <Typography variant="text-3" color="subdued" translation="calendar">
            Set up the banner that will appear on the Digital Menu
          </Typography>
        </div>
        <SwitchBox
          checked={!!state?.ad}
          onChange={(e, checked) => onAddAd(checked)}
          label=""
          color="primary"
        />
      </Box>
      <Box flex className={!state?.ad ? "disabledPart" : ""}>
        <Translations
          state={
            state.ad?.translations ?? { de: { title: "", description: "" } }
          }
          keys={[
            {
              id: "title",
              label: "Title",
              required: true,
            },
            {
              id: "description",
              label: "Description",
              richText: true,
            },
          ]}
          onChange={(nS) => {
            if (nS.de.title && error.includes("title")) {
              setError("");
            }
            setState((st) => ({
              ...st,
              ad: {
                ...st.ad,
                translations: nS as Record<
                  string,
                  { title: string; description: string }
                >,
              },
            }));
          }}
          disabled={!state?.ad}
        />
        <ImageField
          value={state?.ad?.imageId ?? state?.ad?.imageUrl ?? ""}
          label=""
          onChange={(val) => handleImageChange(val as string)}
          type={state?.ad?.imageUrl ? "url" : "id"}
          disabled={!state?.ad}
        />
      </Box>
    </Modal>
  );
};

export default OfferSettings;
