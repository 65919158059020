import { RestaurantContext } from "Contexts/RestaurantContext";
import React, { useContext } from "react";

const useTextHelper = () => {
  const { initTextHelper, textHelperState } = useContext(RestaurantContext);

  return {
    initTextHelper,
    isActive: !!textHelperState,
  };
};

export default useTextHelper;
