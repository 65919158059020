import React, { useCallback, useContext } from 'react';
import './styles.scss';
import Box from 'Components/Atoms/Box';
import { CalendarShift } from '../Week';
import Typography from 'Components/Atoms/Typography';
import { blockToTime, getTime } from 'utils/helper';
import {
  Comment,
  Create,
  Done,
  LocalActivity,
  Lock,
  NotInterested,
  ShoppingBasket,
  Star,
} from '@material-ui/icons';
import Sidebar from '../../../../Components/Molecules/Sidebar';
import { Moped } from 'Components/Atoms/Icons';
import { RestaurantContext } from 'Contexts/RestaurantContext';

interface MultipleShiftsSidebarProps {
  shifts: CalendarShift[];
  onClose: () => void;
  handleShiftClick: (date: string, shiftId: string) => void;
}

export function isShiftDoneHandler(shift: CalendarShift): boolean {
  const isShiftDone =
    Date.now() > getTime(shift.date, shift.close, shift.start);
  return isShiftDone;
}

const MultipleShiftsSidebar = ({
  shifts,
  onClose,
  handleShiftClick,
}: MultipleShiftsSidebarProps) => {
  const { reservationSettings } = useContext(RestaurantContext);

  const occassions = reservationSettings?.occassions ?? [];

  const getOccassionString = useCallback(
    (occassionIds: string[]) => {
      if (!occassionIds.length) return 'Keine Anlässe';

      return occassionIds
        .map((id) => occassions.find((o) => o.id === id)?.title ?? id)
        .join(', ');
    },
    [occassions]
  );

  return (
    <Box className="multiple-shifts-sidebar-wrapper">
      <Sidebar
        id="multiple-shifts-sidebar"
        title={`Multiple Shifts / ${shifts[0].date}`}
        titleTranslation={null}
        onClose={onClose}
        deductFromHeight={12}
      >
        <Box className="multiple-shifts-card-wrapper">
          <Box className="shifts-list-container">
            {shifts.map((shift) => (
              <Box
                className="shift-list-item"
                onClick={() => {
                  onClose();
                  handleShiftClick(shift.date, shift.id);
                }}
                style={{ opacity: shift.disabled ? 0.5 : 1 }}
              >
                <Typography variant="text-3">
                  {getOccassionString(shift.occassions ?? [])}
                </Typography>
                <Box className="shift-items">
                  <div
                    className="shift-list-item-circle"
                    style={{ background: shift.color }}
                  />
                  <Typography variant="text-4" color="subdued">
                    {blockToTime(shift.start)} - {blockToTime(shift.close)}
                  </Typography>
                  <Typography variant="text-3" weight="bold">
                    {shift.name}
                  </Typography>
                  {isShiftDoneHandler(shift) && (
                    <Done
                      width={10}
                      height={10}
                      style={{ display: 'block', maxWidth: 14 }}
                    />
                  )}
                  {shift?.commentOnly && (
                    <Comment
                      width={10}
                      height={10}
                      style={{ display: 'block', maxWidth: 14 }}
                    />
                  )}
                  {!shift.regular && !shift?.commentOnly && (
                    <Star
                      width={10}
                      height={10}
                      style={{ display: 'block', maxWidth: 14 }}
                    />
                  )}
                  {shift.edited && (
                    <Create
                      width={10}
                      height={10}
                      style={{ display: 'block', maxWidth: 14 }}
                    />
                  )}
                  {!!shift.closed && (
                    <NotInterested
                      width={10}
                      height={10}
                      style={{ display: 'block', maxWidth: 14 }}
                    />
                  )}
                  {!!shift?.overwrite?.blockReservations && (
                    <Lock
                      width={10}
                      height={10}
                      style={{
                        display: 'block',
                        maxWidth: 14,
                      }}
                    />
                  )}
                  {!!shift?.overwrite?.closedSpaces &&
                    !shift?.overwrite?.blockReservations &&
                    !shift.closed && (
                      <Lock
                        width={10}
                        height={10}
                        style={{
                          display: 'block',
                          maxWidth: 14,
                        }}
                      />
                    )}
                  {!!shift?.additional?.eventV02?.active && (
                    <LocalActivity
                      width={10}
                      height={10}
                      style={{
                        display: 'block',
                        maxWidth: 14,
                      }}
                    />
                  )}
                  {shift?.deliveryMethods?.includes('delivery') && (
                    <Moped
                      width={14}
                      height={14}
                      style={{
                        display: 'block',
                        maxWidth: 14,
                      }}
                    />
                  )}
                  {shift?.deliveryMethods?.includes('pickup') && (
                    <ShoppingBasket
                      width={14}
                      height={14}
                      style={{
                        display: 'block',
                        fontSize: 14,
                        maxWidth: 14,
                      }}
                    />
                  )}
                  {!!shift?.advert?.active && (
                    <LocalActivity
                      width={10}
                      height={10}
                      style={{
                        display: 'block',
                        minWidth: 14,
                        maxWidth: 14,
                      }}
                    />
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Sidebar>
    </Box>
  );
};

export default MultipleShiftsSidebar;
