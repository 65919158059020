import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

const TableSwitch = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="14" viewBox="0 0 16 14" fill="none" {...props}>
      <path
        d="M1.02843 0.482068C1.05325 0.11202 1.46683 -0.0225427 1.7908 0.00302429C5.93073 0.00302429 10.072 0.00302429 14.212 0.00302429C14.5318 -0.0198514 14.9412 0.110674 14.9702 0.47534C15.0377 1.11317 14.9812 1.75772 14.995 2.39824C15.3879 2.39959 15.9793 2.44534 15.9766 2.95668C16.0248 5.51337 15.9779 8.0741 16 10.6321C15.9779 10.8892 16.0303 11.204 15.8208 11.4032C15.614 11.6266 15.268 11.5512 14.995 11.601C14.9812 12.2509 15.0405 12.9036 14.9661 13.5495C14.9219 13.898 14.529 14.0177 14.2202 13.9976C10.0775 13.9989 5.93486 13.9976 1.79218 13.9976C1.47648 14.0191 1.07255 13.9007 1.03257 13.5427C0.960882 12.8982 1.01878 12.2482 1.005 11.6023C0.613476 11.6023 0.0261936 11.5566 0.0206792 11.0493C-0.0220573 8.4899 0.020679 5.92783 0 3.36709C0.0179218 3.10604 -0.030329 2.79251 0.179218 2.58932C0.3929 2.37806 0.732035 2.44938 1.005 2.39824C1.01878 1.76042 0.96364 1.11855 1.02843 0.482068ZM11.9387 2.50186C11.5706 2.76291 11.2576 3.0872 10.9199 3.3819C9.01603 2.13181 6.26159 2.23677 4.54937 3.76136C3.52369 4.61718 2.9695 5.92648 2.98742 7.23578C3.5361 7.23578 4.08478 7.23443 4.63347 7.23847C4.64587 6.28576 5.03877 5.32768 5.83284 4.74367C6.91504 3.88247 8.5156 3.81923 9.726 4.45975C9.30829 4.83921 8.84094 5.18773 8.55971 5.68292C8.77201 5.78923 8.97743 5.94801 9.23247 5.92379C10.3078 5.92379 11.3845 5.93725 12.4598 5.9063C12.486 4.83249 12.5011 3.75598 12.4625 2.68352C12.4708 2.43054 12.1082 2.34173 11.9387 2.50186ZM10.8468 6.76346C10.8661 7.75788 10.4125 8.76576 9.56057 9.3363C8.47699 10.1262 6.95089 10.1491 5.77219 9.54622C6.12924 9.15195 6.69033 8.88013 6.86817 8.36476C6.7441 7.97856 6.23953 8.10236 5.92659 8.07141C4.94916 8.11447 3.96347 7.99471 2.99431 8.1535C3.02326 9.16137 2.98053 10.1719 3.01086 11.1798C2.9888 11.4072 3.18042 11.6615 3.43271 11.5674C3.86972 11.3251 4.19507 10.9389 4.57143 10.6214C6.36498 11.7786 8.8892 11.7867 10.6166 10.503C11.8215 9.65656 12.5618 8.21809 12.4901 6.76481C11.9428 6.76481 11.3941 6.76615 10.8468 6.76346Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default TableSwitch;
