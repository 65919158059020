import { useState, useEffect, useMemo } from 'react';
import { TypeElement } from 'typescript';
import firebase, { db } from 'utils/firebase';

type WhereFilterOp =
  | '<'
  | '<='
  | '=='
  | '!='
  | '>='
  | '>'
  | 'array-contains'
  | 'in'
  | 'array-contains-any'
  | 'not-in';

type Triplet = [string, WhereFilterOp, any];

interface Options {
  filter?: Triplet[];
  sort?: [string, string] | [];
  limit?: null | number;
  keepDataWhileLoading?: boolean;
}

type Type<T = any> = [
  {
    ref: any;
    data: (T & { id: string })[];
    loading: boolean;
    error: null | string;
  },
  React.Dispatch<React.SetStateAction<(T & { id: string })[]>>
];

function useCollection<T = any>(
  collectionPath: string = '',
  options: Options = { filter: [], sort: [], limit: null }
): Type<T> {
  const { filter = [], sort = [], limit = null } = options;

  const [data, setdata] = useState<(T & { id: string })[]>([]);
  const [loading, setloading] = useState<boolean>(true);
  const [listener, setlistener] = useState<Function[]>([]);
  const [error, seterror] = useState<null | string>(null);

  const stringifiedOptions = useMemo(() => JSON.stringify(options), [options]);

  let ref: any =
    collectionPath && !collectionPath.includes('//')
      ? db.collection(collectionPath)
      : null;

  useEffect(() => {
    if (listener && listener.length) {
      listener.forEach((unsubsribe) => unsubsribe());
    }

    if (ref) {
      setloading(true);
      if (!options.keepDataWhileLoading) {
        setdata([]);
      }
      seterror(null);

      filter.forEach((rule: Triplet) => {
        // eslint-disable-next-line
        ref = ref?.where(...rule);
      });

      if (sort.length) {
        ref = ref?.orderBy(...sort);
      }

      if (limit) {
        ref = ref?.limit(limit);
      }

      let unsubscribe = ref?.onSnapshot(
        (querySnapshots: firebase.firestore.QuerySnapshot<T>) => {
          let arr: (T & { id: string })[] = [];

          querySnapshots.forEach((doc) => {
            let data = { ...doc.data(), id: doc.id };

            arr.push(data);
          });

          setdata(arr);
          setloading(false);
        },
        (err: Error) => {
          console.error(err, err.message);
          seterror(err.message);
          setloading(false);
        }
      );

      setlistener([unsubscribe]);
    }
    return () => {
      if (listener && listener.length) {
        listener.forEach((unsubsribe) => unsubsribe());
      }
    };
  }, [db, collectionPath, stringifiedOptions]);

  return [{ ref, data, loading, error }, setdata];
}

export default useCollection;
