import React from 'react';
import MenuItem from 'Components/Atoms/MenuItem';
import LockedProductIcon from 'Components/Atoms/Icons/LockedProduct';
import { Trans } from 'react-i18next';

export type ListProps = {
  value?: number | null;
  onChange: (newValue: number) => void;
  onKeyboardOpen: VoidFunction;
  maxGuests?: number | null;
};

const List = ({ value, onChange, onKeyboardOpen, maxGuests }: ListProps) => {
  const OPTIONS = Array.from(new Array(maxGuests ?? 10), (_, i) => i + 1).map(
    (id) => ({
      id,
      label: `${id} ${id > 1 ? 'Guests' : 'Guest'}`,
    })
  );

  return (
    <div>
      {OPTIONS.map((opt) => (
        <MenuItem
          key={opt.id}
          value={opt.id}
          selected={opt.id === value}
          onClick={() => onChange(opt.id)}
          iconRight={
            maxGuests && opt.id > maxGuests ? (
              <LockedProductIcon noStroke width="20" height="20" />
            ) : undefined
          }
        >
          {opt.label}
        </MenuItem>
      ))}
      <MenuItem
        onClick={() => onKeyboardOpen()}
        iconRight={
          maxGuests ? (
            <LockedProductIcon
              style={{ color: 'var(--color-secondary)' }}
              noStroke
              width="20"
              height="20"
            />
          ) : undefined
        }
      >
        Mehr als {maxGuests ?? 10} Gäste
      </MenuItem>
    </div>
  );
};

export default List;
