import { useEffect, useMemo, useState } from "react";
import Typography from "Components/Atoms/Typography";
import Box from "Components/Atoms/Box";
import React from "react";
import { Column } from "../index";

type HeaderProps = {
  columns: Column[];
  ContentWidth: string[] | any;
};

const Header = ({ columns = [], ContentWidth }: HeaderProps) => {
  const findLongest = (words: any) =>
    Math.max(...(words?.map((el: string | any[]) => el?.length ?? 0) ?? 1));
  let output: any = {};
  const outputWidth = useMemo(() => {
    for (let [key, value] of Object.entries(ContentWidth)) {
      if (value) output[key] = findLongest(value) * 9;
    }
    return output;
  }, [output]);
  return (
    <Box flex padding="md">
      {columns.map((item) => (
        <Box
          flex
          style={{
            justifyContent:
              item.align === "right"
                ? "flex-end"
                : item.align === "left"
                ? "flex-start"
                : "center",
            width: "inherit",
          }}
        >
          <Typography
            variant="text-2"
            weight="medium"
            textAlign={item.align}
            style={{
              minWidth: item.minWidth ? item.minWidth : output[item.id],
              width: output[item.id] === 0 ? 50 : "inherit",
            }}
          >
            {item.label}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default Header;
