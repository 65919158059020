import Divider from 'Components/Atoms/Divider';
import Typography from 'Components/Atoms/Typography';
import { CallWithId, ExtendedCall } from 'CustomHooks/useCalls';
import useLanguage from 'CustomHooks/useLanguage';
import useObserver from 'CustomHooks/useObserver';
import useSiaContext from 'CustomHooks/useSiaContext';
import React, { useMemo } from 'react';
import { db } from 'utils/firebase';
import { classHelper } from 'utils/helper';

export const NextPage = () => {
  const { nextPage } = useSiaContext();

  const observer = useObserver<HTMLDivElement>(
    (seen) => {
      nextPage();
    },
    true,
    {
      root: document.getElementById('callList'),
      rootMargin: '0px',
      threshold: 0,
    }
  );

  return <div style={{ height: 10 }} ref={observer.ref}></div>;
};

const CallListItem: React.FC<{
  call: ExtendedCall;
  active?: boolean;
  onClick?: () => void;
  noStatus?: boolean;
}> = ({ call, active = false, onClick, noStatus }) => {
  const { onChangeCurrentCall } = useSiaContext();

  const observer = useObserver<HTMLDivElement>((seen) => {
    console.log({
      seen,
      callSeen: call.seen,
      callerId: call.callerId,
      callSid: call.callSid,
    });
    if (seen && call.unseenCalls.length) {
      call.unseenCalls.forEach((c) => {
        const ref = db.collection('calls').doc(c);
        ref.update({ seen: Date.now(), updatedAt: Date.now() });
      });
    }
  }, true);

  const { wasSeen: seen, lastEvent, statusText, dateString } = call;

  const status = useMemo(() => {
    if (!call.endedAt) {
      return 'new';
    } else if (lastEvent ? !lastEvent.success : true) {
      return 'error';
    } else if (!seen) {
      return 'new';
    } else if (call.successfulReservation) {
      return 'reservation';
    } else {
      return '';
    }
  }, [call]);

  return (
    <div
      ref={observer.ref}
      className={classHelper([
        'callListItem',
        !noStatus && status,
        active && 'active',
      ])}
      onClick={() => {
        if (onClick) onClick();
        onChangeCurrentCall(call.id);
      }}
    >
      <div className="status"></div>
      <div>
        <Typography variant="h5">
          {call.name} {call.count > 1 ? `(${call.count})` : ''}
        </Typography>
        <Typography variant="text-4">{statusText}</Typography>
      </div>
      <div>
        <Typography variant="text-4" style={{ whiteSpace: 'nowrap' }}>
          {dateString}
        </Typography>
      </div>
    </div>
  );
};

export default CallListItem;
