import { RestaurantContext } from "Contexts/RestaurantContext";
import React, { useState, useEffect, useContext } from "react";
import { db } from "utils/firebase";
import { dateHelper } from "utils/helper";

function removeUndefinedValues<T = any>(obj: Record<any, any>) {
  let newObj: Record<any, any> = {};

  Object.keys(obj).forEach((key) => {
    if (
      typeof obj[key] === "object" &&
      !!obj[key] &&
      !Array.isArray(obj[key])
    ) {
      newObj[key] = removeUndefinedValues(obj[key]);
    } else if (obj[key] !== undefined) {
      newObj[key] = obj[key];
    }
  });

  return newObj as T;
}

export type EmergencyClose = {
  date: string;
  active: boolean;
  deliveryOptions?: ("takeAway" | "delivery" | "guestOrdering" | "pickup")[]; // all when undefined
  until?: number;
};

const close = (
  restaurantId: string,
  deliveryOptions?: ("takeAway" | "delivery" | "guestOrdering" | "pickup")[],
  until?: number
) => {
  const date = dateHelper();

  const ref = db.collection(`restaurants/${restaurantId}/emergencyCloses`);

  const emergencyStop: EmergencyClose = {
    date,
    active: true,
    deliveryOptions,
    until,
  };

  ref.add(removeUndefinedValues(emergencyStop));
};

const reopen = async (
  restaurantId: string,
  emergencyStop: EmergencyClose & { id: string }
) => {
  const ref = db
    .collection(`restaurants/${restaurantId}/emergencyCloses`)
    .doc(emergencyStop.id);

  await ref.delete();

  return;
};

const useEmergencyStop = (restaurantId: string | null) => {
  const { alert } = useContext(RestaurantContext);
  const [stopped, setstopped] = useState(false);
  const [emergencyStop, setemergencyStop] = useState<
    (EmergencyClose & { id: string }) | null
  >(null);
  const [listener, setlistener] = useState<Function[]>([]);
  const [loading, setloading] = useState<boolean>(false);

  useEffect(() => {
    if (listener && listener.length) {
      listener.forEach((unsubsribe) => unsubsribe());
    }

    setloading(true);
    setemergencyStop(null);

    if (restaurantId) {
      const specialDayRef = db.collection(
        `restaurants/${restaurantId}/emergencyCloses`
      );

      const today = dateHelper();

      const ref = specialDayRef.where("date", "==", today);

      let unsubscribe = ref.onSnapshot((querySnapshot) => {
        let isClosed = false;

        querySnapshot.forEach((doc) => {
          let data = doc.data() as EmergencyClose;

          if (data.active && (!data.until || data.until > Date.now())) {
            isClosed = true;
            setemergencyStop({ ...data, id: doc.id });
          }
        });

        setstopped(isClosed);
        setloading(false);
      });

      setlistener([unsubscribe]);

      return () => {
        if (listener && listener.length) {
          listener.forEach((unsubsribe) => unsubsribe());
        }
      };
    }
  }, [restaurantId]);

  const toggleStopped = () => {
    if (restaurantId) {
      if (emergencyStop) {
        reopen(restaurantId, emergencyStop);
      } else {
        alert({
          title: "Are you sure you want to close the Shop for the day?",
          titleTranslation: "takeAway",
          description: "No more Orders can be made",
          descriptionTranslation: "takeAway",
          onSubmit: () => close(restaurantId),
        });
      }
    }
  };

  return loading
    ? null
    : {
        stopped,
        toggleStopped,
      };
};

export default useEmergencyStop;
