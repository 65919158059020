import React, { useState, useMemo, useRef, useEffect } from "react";
import Box from "Components/Atoms/Box";
import "./styles.scss";
import {
  createBlocksForUtilization,
  reduceToStartAndClose,
} from "utils/helper";
import { RichShift, ReservationSnippet } from "utils/types";
import Card from "Components/Molecules/Card";
import Dropdown from "Components/Molecules/Dropdown";
import Block from "./Components/Block";
import Typography from "Components/Atoms/Typography";
import EmptyState from "./Components/EmptyState";
import Divider from "Components/Atoms/Divider";
import IconButton from "Components/Atoms/IconButton";
import {
  ArrowBackOutlined,
  ArrowForwardOutlined,
} from "Components/Atoms/Icons";
import useScroller from "CustomHooks/useScroller";

export type UtilizationByTimeProps = {
  shifts: RichShift[];
  actualShift?: string | null;
  otherReservations?: ReservationSnippet[];
};

const UtilizationByTime = ({
  shifts = [],
  actualShift,
  otherReservations = [],
}: UtilizationByTimeProps) => {
  const [currentShift, setcurrentShift] = useState<null | string>(null);
  const ref = useRef<HTMLDivElement>(null);
  const refBlocks = useRef<HTMLDivElement>(null);
  const [intervalArrowHeight, setIntervalArrowHeight] = useState<
    null | number | undefined | any
  >(null);
  const [intervalWidth, setIntervalWidth] = useState<
    null | number | undefined | any
  >(null);

  useEffect(() => {
    setcurrentShift(actualShift || null);
  }, [actualShift]);

  const reservations = useMemo(() => {
    let fromShifts = shifts
      .filter((s) => currentShift === null || currentShift === s.id)
      .reduce(
        (acc: ReservationSnippet[], cV) => [...acc, ...cV.reservations],
        []
      );

    if (currentShift === null) {
      return [...fromShifts, ...otherReservations];
    }

    return fromShifts;
  }, [currentShift, shifts, otherReservations]);

  console.log(reservations.reduce((acc, cV) => acc + cV.guests, 0));

  const blocks = useMemo(() => {
    let [start, close] = reduceToStartAndClose(
      shifts.filter((s) => currentShift === null || currentShift === s.id)
    );

    if (start === null || close === null) {
      return [];
    }

    return createBlocksForUtilization(start, close, reservations);
  }, [currentShift, shifts, reservations]);

  const hasReservations = !!reservations.length;

  const isOpen = !!blocks.length;

  const arrowrefLeft = useRef<HTMLDivElement>(null);
  const arrowrefRight = useRef<HTMLDivElement>(null);

  const [scrollInterval, scrollWidth, changeArrowDisplay, handleScroll] =
    useScroller({ ref, arrowrefLeft, arrowrefRight });

  let groupArray: any = [];
  blocks.map((item) => {
    groupArray.push(
      item.reservations.reduce((acc: number, cV) => acc + cV.guests, 0)
    );
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setIntervalArrowHeight(ref.current?.clientHeight);
      setIntervalWidth(refBlocks.current?.offsetWidth);
    }, 1);
    return () => {
      clearInterval(interval);
    };
  }, [ref?.current?.clientHeight, refBlocks.current?.offsetWidth]);

  return (
    <Card
      title="Utilization by time"
      titleTranslation="dashboard"
      headerPadding="sm"
      headerRight={
        <Dropdown
          value={currentShift}
          onChange={setcurrentShift}
          translation="common"
          disabled={!shifts.length}
          textAlign="right"
          options={[
            { id: null, label: "All Day" },
            ...shifts.map((s) => ({ id: s.id, label: s.name })),
          ]}
        />
      }
      bodyProps={{
        style: { paddingRight: 0 },
      }}
    >
      <Box
        className="flex"
        style={{ flexDirection: "row" }}
        onMouseMoveCapture={changeArrowDisplay}
        onScrollCapture={handleScroll}
      >
        {!((!isOpen && !hasReservations) || (!!isOpen && !hasReservations)) && (
          <div
            ref={arrowrefLeft}
            className="LeftArrow"
            style={{
              width: "37px",
              minHeight: ref.current?.clientHeight
                ? intervalArrowHeight - 20
                : 200,
              height: "auto",
              position: "absolute",
            }}
          >
            <IconButton
              onClick={() => scrollInterval(-400)}
              aria-label="Left Arrow"
            >
              <ArrowBackOutlined style={{ color: "#fff" }} />
            </IconButton>
          </div>
        )}

        <div
          ref={ref}
          className="scrollWrapper"
          style={!hasReservations ? { overflowX: "hidden" } : {}}
        >
          <Box
            flex
            className="Card_Body_Wrapper"
            ref={refBlocks}
            style={{ minHeight: "240px", maxHeight: "240px" }}
          >
            {hasReservations &&
              isOpen &&
              blocks.map((block) => (
                <Block
                  key={block.block}
                  block={block}
                  maxvalue={Math.max(...groupArray)}
                  clientHeight={intervalArrowHeight}
                />
              ))}

            {!isOpen && !hasReservations && (
              <Typography
                variant="text-3"
                block
                textAlign="center"
                className="restaurantClosed"
              >
                Restaurant is closed
              </Typography>
            )}
            {!!isOpen && !hasReservations && (
              <Typography
                variant="text-3"
                block
                textAlign="center"
                className="restaurantClosed"
              >
                Currently there are no Reservations to show
              </Typography>
            )}
            {!hasReservations && (
              <EmptyState
                style={{
                  width: "1026px",
                  height: 90,
                  justifyContent: "flex-start",
                }}
              />
            )}
          </Box>
          <Divider
            style={{
              marginBottom: 20,
              marginTop: -20,
              width:
                (!!isOpen && !hasReservations) || (!!isOpen && !hasReservations)
                  ? "auto"
                  : refBlocks.current?.offsetWidth,
            }}
          />
        </div>
        {!((!isOpen && !hasReservations) || (!!isOpen && !hasReservations)) && (
          <div
            ref={arrowrefRight}
            className="RightArrow"
            style={{
              width: "37px",
              minHeight: ref.current?.clientHeight
                ? intervalArrowHeight - 20
                : 200,
              height: "auto",
              position: "absolute",
            }}
          >
            <IconButton
              onClick={() => scrollInterval(400)}
              aria-label="Right Arrow"
            >
              <ArrowForwardOutlined style={{ color: "#fff" }} />
            </IconButton>
          </div>
        )}
      </Box>
    </Card>
  );
};

export default UtilizationByTime;
