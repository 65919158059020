import React, { useContext, useEffect } from "react";
import TimeTable from "./TimeTable";
import { RouteComponentProps } from "react-router-dom";
import { Modify } from "utils/types";
import { ReservationContext } from "Contexts/ReservationContext";
import { AuthContext } from "Contexts/AuthContext";

export type TimeTableContainerProps = Modify<
  RouteComponentProps,
  {
    match: { params: { date: string; restaurantId: string } };
  }
>;

const TimeTableContainer: React.FC<TimeTableContainerProps> = ({
  history,
  match,
}) => {
  const { date, restaurantId } = match.params;

  const { setrestaurantId } = useContext(AuthContext);
  const { setdate } = useContext(ReservationContext);

  const onDateChange = (newDate: string) =>
    history.push(`/${restaurantId}/reservations/${newDate}/timeTable`);

  useEffect(() => {
    setdate(match.params.date);
    setrestaurantId(restaurantId);
  }, [match.params]);

  return <TimeTable {...{ date, restaurantId, onDateChange }} />;
};

export default TimeTableContainer;
