import React, { useContext, useState } from "react";
import Box from "Components/Atoms/Box";
import "./styles.scss";
import SettingsDropdownOptions from "./Components/SettingsDropdownOptions";
import IconDropdown from "Components/Molecules/IconDropdown";
import { ThemeContext } from "Contexts/ThemeContext";
import { LanguageContext } from "Contexts/LanguageContext";
import { UserData } from "utils/types";
import { AuthContext } from "Contexts/AuthContext";
import { useHistory } from "react-router";
import { useMediaQuery } from "@material-ui/core";
import MobileModal from "App/Mobile/Components/MobileModal";

export type SettingsDropdownProps = {
  user: UserData;
  onClick?: VoidFunction;
};

const SettingsDropdown: React.FC<SettingsDropdownProps> = ({
  user,
  onClick,
}) => {
  const history = useHistory();
  const isMobile = useMediaQuery("(max-width:450px)");

  const { darkMode, setdarkMode } = useContext(ThemeContext);
  const { language, changeLanguage, LANGUAGES } = useContext(LanguageContext);
  const { signOutUser, restaurantId } = useContext(AuthContext);

  return (
    <Box flex onClick={() => onClick && onClick()}>
      <IconDropdown
        icon={null}
        avatar={user.photoURL}
        name={user.displayName || "Gastronaut"}
        avatarProps={{ alt: user.displayName || "User" }}
        popoverProps={{
          padding: 0,
          width: "fit-content",
          style: { minWidth: 200, marginTop: 8 },
        }}
      >
        {!isMobile && (
          <SettingsDropdownOptions
            {...{
              user,
              darkMode,
              language,
              setdarkMode,
              onLanguageChange: changeLanguage,
              languages: LANGUAGES,
              onLogout: signOutUser,
              goToSettings: () =>
                history?.push(restaurantId ? `/${restaurantId}/settings` : "/"),
            }}
          />
        )}
      </IconDropdown>
    </Box>
  );
};

export default SettingsDropdown;
