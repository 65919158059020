import React, { useContext, useEffect, useMemo, useState } from 'react';
import Box from 'Components/Atoms/Box';
import FullScreenModal from 'Components/Atoms/FullScreenModal';
import Typography from 'Components/Atoms/Typography';
import IconButton from 'Components/Atoms/IconButton';
import { Event } from 'gastronaut-shared/types/documents/restaurants';
import { ReservationShift } from 'types/shifts';
import { Delete, Edit } from 'Components/Atoms/Icons';
import EventDetails from './Components/EventDetails';
import { LanguageContext } from 'Contexts/LanguageContext';
import useToast from 'CustomHooks/useToast';
import { Severity } from 'Contexts/RestaurantContext';
import { EventTypes } from 'gastronaut-shared/types/documents/restaurants/event';
import { Trans } from 'react-i18next';

import './styles.scss';
import useAlert from 'CustomHooks/useAlert';
import Button from 'Components/Atoms/Button';
import { toCurrencyString } from 'utils/helper';
import { ActiveShift } from 'CustomHooks/useCalendarDates';
import { CalendarContext } from 'Contexts/CalendarContext';
import { Menu } from '@material-ui/core';
import MenuItem from 'Components/Atoms/MenuItem';

export type EventSettingsProps = {
  activeShift: ActiveShift<ReservationShift>;
  designV02?: boolean;
  setactiveShift: React.Dispatch<
    React.SetStateAction<ActiveShift<ReservationShift> | null>
  >;
  eventOnly?: boolean;
  handleSubmit?: () => Promise<void>;
};

export function EventDescription(event?: Event | null, language = 'de') {
  const arr: string[] = [];

  if (!!event) {
    // @ts-ignore
    if (event.edited) {
      arr.push(language === 'de' ? 'Bearbeitet' : 'Edited');
    }

    if (event.payAtRestaurant) {
      arr.push(
        language === 'de' ? 'Bezahlung im Restaurant' : 'Pay at Restaurant'
      );
    }

    if (event.optional) {
      arr.push(language === 'de' ? 'Optionales Ticket' : 'Optional Ticket');
      arr.push(language === 'de' ? 'Optionales Ticket' : 'Optional Ticket');
    }

    if (event.maxGuests) {
      arr.push(
        language === 'de'
          ? `Max ${event.maxGuests} Tickets pro Schicht`
          : `Max ${event.maxGuests} Tickets per Shift`
      );
    }

    if (event.rules?.minGuests) {
      arr.push(
        language === 'de'
          ? `Gruppen ab ${event.rules.minGuests}${
              event.rules.maxGuests ? ` - ${event.rules.maxGuests}` : ''
            } Personen`
          : `Groups from ${event.rules.minGuests}${
              event.rules.maxGuests ? ` - ${event.rules.maxGuests}` : ''
            } Persons`
      );
    } else if (event.rules?.maxGuests) {
      arr.push(
        language === 'de'
          ? `Gruppen bis ${event.rules.maxGuests} Personen`
          : `Groups up to ${event.rules.maxGuests} Persons`
      );
    }

    if (arr.length <= 1) {
      let options = event.options?.length ?? 0;

      let price = event.amount;
      let optionPrice =
        event?.options?.sort((a, b) => (a?.amount ?? 0) - (b?.amount ?? 0))?.[0]
          ?.amount ?? 0;

      if (optionPrice) {
        arr.push(
          language === 'de'
            ? `ab ${toCurrencyString(price + optionPrice, '€', false, 1)}`
            : `from ${toCurrencyString(price + optionPrice, '€', false, 1)}`
        );
      } else {
        arr.push(
          language === 'de'
            ? `${toCurrencyString(price + optionPrice, '€', false, 1)}`
            : `${toCurrencyString(price + optionPrice, '€', false, 1)}`
        );
      }

      if (options > 1)
        arr.push(
          language === 'de' ? `${options} Optionen` : `${options} Options`
        );

      let addOns = event.optionalAddOns?.length ?? 0;

      if (addOns > 1)
        arr.push(language === 'de' ? `${addOns} AddOns` : `${addOns} AddOns`);
    }
  }

  return arr.join(' • ');
}

const DEFAULT_EVENT_STATE: Event = {
  id: 'newEvent',
  active: false,
  amount: 0,
  title: { de: '', en: '' },
  description: { de: '', en: '' },
  type: EventTypes.DINNER_TICKET,
  blockTableFor: {
    value: 1,
    unit: 'hours',
  },
  minInAdvance: {
    value: 3,
    unit: 'hours',
  },
  options: [
    {
      id: 'default',
      vatRate: '7.00',
      internalName: 'default-opt',
      translation: {
        de: { title: 'Default option', description: '' },
        en: { title: '', description: '' },
      },
      type: 'perPerson',
    },
  ],
  confirmation_email: {
    active: false,
    translations: {
      en: {
        title: '',
        description: '',
        button: '',
      },
      de: {
        title: '',
        description: '',
        button: '',
      },
    },
  },
  reminder_email: {
    active: false,
    sendBefore: {
      value: 3,
      unit: 'hours',
    },
    translations: {
      en: {
        title: '',
        description: '',
        button: '',
      },
      de: {
        title: '',
        description: '',
        button: '',
      },
    },
  },
};

const OldDesign: React.FC<{
  activeShift: ActiveShift<ReservationShift>;
  onOpen: () => void;
  onDelete: () => void;
}> = ({ activeShift, onOpen, onDelete }) => {
  return (
    <Box flex style={{ flexDirection: 'column', marginTop: 16 }}>
      <Typography
        block
        color="subdued"
        variant="text-4"
        style={{ alignSelf: 'flex-start' }}
        translation="calendar"
      >
        Event / Tickets
      </Typography>
      <Box flex fullSize className="space-between">
        <Typography
          block
          color="textPrimary"
          variant="text-3"
          translation="calendar"
        >
          {!activeShift?.shift.additional?.eventV02?.title && (
            <Trans i18nKey="calendar_No event added">No event added</Trans>
          )}
          {activeShift?.shift.additional?.eventV02?.title?.de || ''}
        </Typography>
        <Box style={{ width: 'auto' }}>
          <IconButton color="subdued" size="x-small" onClick={onOpen}>
            <Edit />
          </IconButton>
          {!!activeShift?.shift?.additional?.eventV02 && (
            <IconButton color="subdued" size="x-small" onClick={onDelete}>
              <Delete />
            </IconButton>
          )}
        </Box>
      </Box>
      {activeShift?.shift.additional?.eventV02?.title &&
        !activeShift?.shift.additional?.eventV02?.active && (
          <Typography
            block
            color="error"
            variant="text-5"
            translation="calendar"
            style={{ justifySelf: 'flex-start', alignSelf: 'flex-start' }}
          >
            Event is deactivated
          </Typography>
        )}
    </Box>
  );
};

const NewDesign: React.FC<{
  activeShift: ActiveShift<ReservationShift>;
  onOpen: (event?: Event) => void;
  onDelete: () => void;
}> = ({ activeShift, onOpen, onDelete }) => {
  const { language } = useContext(LanguageContext);

  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  const { eventTemplates } = useContext(CalendarContext);

  const description = useMemo(
    () => EventDescription(activeShift?.shift?.additional?.eventV02, language),
    [language, activeShift]
  );

  return (
    <Box style={{ marginTop: 16 }}>
      {!activeShift?.shift?.additional?.eventV02 && (
        <>
          <Button
            variant="secondary"
            translation="calendar"
            onClick={(e) =>
              eventTemplates.length > 0
                ? setAnchorEl(e.currentTarget)
                : onOpen()
            }
          >
            Add Event
          </Button>
          <Menu
            id={`AddEvent`}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <Typography
              component="p"
              variant="text-4"
              style={{ padding: '0 14px' }}
              translation="calendar"
            >
              Choose from Template
            </Typography>
            {eventTemplates.map((e) => (
              <MenuItem
                key={e.id}
                onClick={() => {
                  onOpen({ ...e, id: 'newEvent' });
                }}
              >
                {e.title?.['de'] ?? e.title?.['en'] ?? ''}
              </MenuItem>
            ))}
            <Box style={{ padding: '8px 12px 0px 12px' }}>
              <Button
                variant="secondary"
                translation="calendar"
                fullWidth
                onClick={() => onOpen()}
              >
                Add Event
              </Button>
            </Box>
          </Menu>
        </>
      )}
      {!!activeShift?.shift?.additional?.eventV02 && (
        <Box
          style={{
            padding: 10,
            borderRadius: 4,
            background: 'var(--color-btn-default)',
          }}
        >
          <Typography
            block
            color="textPrimary"
            variant="h5"
            style={{ marginBottom: 3 }}
          >
            {activeShift?.shift.additional?.eventV02?.title?.de ||
              'Kein Event Name'}
          </Typography>
          <Typography
            component="p"
            color="subdued"
            variant="text-3"
            style={{
              justifySelf: 'flex-start',
              alignSelf: 'flex-start',
              marginBottom: 6,
            }}
          >
            {description}
          </Typography>
          {!activeShift?.shift.additional?.eventV02?.active && (
            <Typography
              component="p"
              color="error"
              variant="text-3"
              translation="calendar"
              style={{
                justifySelf: 'flex-start',
                alignSelf: 'flex-start',
                marginBottom: 6,
              }}
            >
              Event is deactivated
            </Typography>
          )}

          <Box flex style={{ justifyContent: 'flex-start' }}>
            <Button
              onClick={() => onOpen()}
              style={{
                marginRight: 8,
                background: 'var(--color-grey-5)',
                padding: '4px 10px',
                fontSize: '14px',
                lineHeight: '19.6px',
                color: 'var(--color-grey-9)',
              }}
              endIcon={(p) => <Edit {...p} />}
              translation="calendar"
            >
              Edit
            </Button>
            <Button
              onClick={onDelete}
              color="error"
              variant="primary"
              style={{
                marginRight: 8,
                background: 'var(--color-critical',
                padding: '4px 10px',
                fontSize: '14px',
                lineHeight: '19.6px',
                color: '#ffffff',
              }}
              endIcon={(p) => <Delete {...p} />}
              translation="calendar"
            >
              Delete
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const EventSettings: React.FC<EventSettingsProps> = ({
  activeShift,
  setactiveShift,
  designV02,
  eventOnly = false,
  handleSubmit: handleShiftSubmit,
}) => {
  const toast = useToast();
  const alert = useAlert();

  const [open, setOpen] = useState(eventOnly);
  const [eventState, setEventState] = useState<null | Event>(null);
  const [loading, setloading] = useState(false);

  // console.log("activeShift", activeShift, "eventState", eventState);

  useEffect(() => {
    if (!activeShift?.shift.closed) {
      if (activeShift?.shift.additional?.eventV02) {
        setEventState(activeShift?.shift.additional.eventV02);
      } else {
        setEventState((x) => x ?? DEFAULT_EVENT_STATE);
      }
    }
  }, [open]);

  const handleSubmit = () => {
    if (!eventState?.title || eventState?.title.de === '') {
      return toast(
        'A title in the German tab is required, please provide one to save.',
        Severity.WARNING,
        'calendar'
      );
    }

    if (
      eventState.type === EventTypes.DINNER_TICKET &&
      (!eventState?.options || eventState?.options.length === 0)
    ) {
      return toast(
        'At least one option is needed to save.',
        Severity.WARNING,
        'calendar'
      );
    }

    if (eventOnly) setloading(true);

    setactiveShift((aS: any) => ({
      ...aS,
      shift: {
        ...aS.shift,
        additional: {
          ...aS.shift.additional,
          eventV02: {
            ...eventState,
            options:
              eventState.options?.map((x) => ({
                ...x,
                id: x.id === 'newOpt' ? x.internalName : x.id,
              })) ?? [],
            ...(!aS.allUpcomingShifts || !aS.shift.regular
              ? { edited: true }
              : {}),
          },
        },
      },
    }));

    if (!eventOnly) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (loading && handleShiftSubmit && activeShift) {
      handleShiftSubmit().finally(() => {
        setOpen(false);
      });
    }
    setloading(false);
  }, [activeShift]);

  const removeEvent = () => {
    alert({
      title: 'Delete Event',
      description: 'Are you sure you want to delete this event?',
      onSubmit: () => {
        setactiveShift((aS: any) => ({
          ...aS,
          shift: {
            ...aS.shift,
            additional: {
              ...aS.shift.additional,
              eventV02: null,
            },
          },
        }));
      },
    });
  };

  if (!activeShift) return <></>;

  return (
    <>
      {!designV02 ? (
        <OldDesign
          activeShift={activeShift}
          onOpen={() => setOpen(true)}
          onDelete={removeEvent}
        />
      ) : (
        <NewDesign
          activeShift={activeShift}
          onOpen={(event) => {
            setEventState(event ?? null);
            setOpen(true);
          }}
          onDelete={removeEvent}
        />
      )}

      <FullScreenModal
        open={open}
        onClose={() => {
          if (eventOnly) {
            setactiveShift(null);
          }
          setOpen(false);
        }}
        title="Event Settings"
        titleTranslation="calendar"
        onSubmit={handleSubmit}
        fixedNavBar
      >
        <EventDetails
          isNew={!activeShift?.shift?.additional?.eventV02?.id}
          eventState={eventState}
          setEventState={setEventState}
        />
      </FullScreenModal>
    </>
  );
};

export default EventSettings;
