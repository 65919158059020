import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  useLayoutEffect,
} from "react";
import Card from "Components/Molecules/Card";
import { RichShift, ReservationSnippet } from "utils/types";
import Dropdown from "Components/Molecules/Dropdown";
import "./styles.scss";
import { createBlocks, reduceToStartAndClose } from "utils/helper";
import Divider from "Components/Atoms/Divider";
import Block from "./Components/Block";
import Box from "Components/Atoms/Box";
import EmptyState from "./Components/EmptyState";
import Typography from "Components/Atoms/Typography";
import IconButton from "Components/Atoms/IconButton";
import {
  ArrowBackOutlined,
  ArrowForwardOutlined,
} from "Components/Atoms/Icons";
import useScroller from "CustomHooks/useScroller";
import { ReservationShift } from "types/shifts";

export type GroupsPerIntervalProps = {
  shifts: RichShift[];
  style?: React.CSSProperties;
  actualShift?: string | null;
  otherReservations?: ReservationSnippet[];
};

const GroupsPerInterval = ({
  shifts = [],
  otherReservations = [],
  actualShift,
  style,
}: GroupsPerIntervalProps) => {
  const [currentShift, setcurrentShift] = useState<null | string>(null);
  const ref = useRef<HTMLDivElement>(null);
  const refBlocks = useRef<HTMLDivElement>(null);
  const [intervalArrowHeight, setIntervalArrowHeight] = useState<
    null | number | undefined | any
  >(null);
  const [intervalWidth, setIntervalWidth] = useState<
    null | number | undefined | any
  >(null);

  useEffect(() => {
    setcurrentShift(actualShift || null);
  }, [actualShift]);

  const reservations = useMemo(() => {
    let fromShifts = shifts
      .filter((s) => currentShift === null || currentShift === s.id)
      .reduce(
        (acc: ReservationSnippet[], cV) => [...acc, ...cV.reservations],
        []
      );

    if (currentShift === null) {
      return [...fromShifts, ...otherReservations];
    }

    return fromShifts;
  }, [currentShift, shifts, otherReservations]);

  const blocks = useMemo(() => {
    let [start, close] = reduceToStartAndClose(
      shifts.filter((s) => currentShift === null || currentShift === s.id)
    );

    if (start === null || close === null) {
      return [];
    }

    return createBlocks(start, close, reservations);
  }, [currentShift, shifts, reservations]);

  const hasReservations = !!reservations.length;

  const isOpen = !!blocks.length;

  const arrowrefLeft = useRef<HTMLDivElement>(null);
  const arrowrefRight = useRef<HTMLDivElement>(null);
  const [scrollInterval, scrollWidth, changeArrowDisplay, handleScroll] =
    useScroller({ ref, arrowrefLeft, arrowrefRight });

  useEffect(() => {
    const interval = setInterval(() => {
      setIntervalArrowHeight(ref.current?.clientHeight);
      setIntervalWidth(refBlocks.current?.offsetWidth);
    }, 1);

    return () => {
      clearInterval(interval);
    };
  }, [ref?.current?.clientHeight, refBlocks.current?.offsetWidth]);

  return (
    <Card
      title="Groups per interval (15 minutes)"
      titleTranslation="dashboard"
      headerPadding="sm"
      headerRight={
        <Dropdown
          value={currentShift}
          onChange={setcurrentShift}
          translation="common"
          disabled={!shifts.length}
          textAlign="right"
          options={[
            { id: null, label: "All Day" },
            ...shifts.map((s) => ({ id: s.id ?? null, label: s.name || "" })),
          ]}
        />
      }
      style={style}
      bodyProps={{ style: { paddingRight: 0 } }}
    >
      <Box
        className="flex"
        style={
          !hasReservations
            ? { overflowX: "hidden", flexDirection: "row" }
            : { flexDirection: "row" }
        }
        onMouseMoveCapture={changeArrowDisplay}
        onScrollCapture={handleScroll}
      >
        {!((!isOpen && !hasReservations) || (!!isOpen && !hasReservations)) && (
          <div
            ref={arrowrefLeft}
            className="LeftArrow"
            style={{
              width: "37px",
              minHeight: ref.current?.clientHeight
                ? intervalArrowHeight - 20
                : 200,
              height: "auto",
              position: "absolute",
            }}
          >
            <IconButton
              onClick={() => scrollInterval(-400)}
              aria-label="Left Arrow"
            >
              <ArrowBackOutlined style={{ color: "#fff" }} />
            </IconButton>
          </div>
        )}

        <div
          ref={ref}
          style={{
            flexWrap: "wrap",
            overflowX: !hasReservations ? "hidden" : "scroll",
            scrollBehavior: "smooth",
          }}
        >
          <Box
            className="flex"
            id="blockHeight"
            ref={refBlocks}
            style={{
              alignItems: "flex-end",
              alignSelf: "flex-start",
              marginRight: 16,
              minHeight: 200,
              position: "relative",
              minWidth: 200,
            }}
          >
            {hasReservations &&
              isOpen &&
              blocks.map((block) => <Block key={block.block} block={block} />)}
            {!isOpen && !hasReservations && (
              <Typography
                variant="text-3"
                block
                textAlign="center"
                style={{ top: 32, position: "absolute", width: "100%" }}
              >
                Restaurant is closed
              </Typography>
            )}
            {!!isOpen && !hasReservations && (
              <Typography
                variant="text-3"
                block
                textAlign="center"
                style={{ top: 32, position: "absolute", width: "100%" }}
              >
                Currently there are no Reservations to show
              </Typography>
            )}
            {!hasReservations && (
              <EmptyState
                style={{
                  width: "1026px",
                  height: 90,
                  justifyContent: "flex-start",
                }}
              />
            )}
          </Box>

          <Divider
            style={{
              marginBottom: 20,
              marginTop: -20,
              width: refBlocks.current?.offsetWidth,
            }}
          />
        </div>
        {!((!isOpen && !hasReservations) || (!!isOpen && !hasReservations)) && (
          <div
            className="RightArrow"
            ref={arrowrefRight}
            style={{
              width: "37px",
              minHeight: ref.current?.clientHeight
                ? intervalArrowHeight - 20
                : 200,
              height: "auto",
              position: "absolute",
            }}
          >
            <IconButton
              onClick={() => scrollInterval(400)}
              aria-label="Right Arrow"
            >
              <ArrowForwardOutlined style={{ color: "#fff" }} />
            </IconButton>
          </div>
        )}
      </Box>
    </Card>
  );
};

export default GroupsPerInterval;
