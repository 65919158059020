import { useState, useEffect, useLayoutEffect, useRef } from "react";
import { isConstructorDeclaration } from "typescript";

const useScroller = ({ ref, arrowrefLeft, arrowrefRight }: any) => {
  const [scrollWidth, setScrollWidth] = useState<any>(null);

  const [scrollArrowLeft, setScrollArrowLeft] = useState<any>(null);

  let scrollArrowLeftButton = 0;
  const scrollInterval = (scrollOffset: number) => {
    if (ref.current !== null) {
      ref.current.scrollLeft = ref.current.scrollLeft + scrollOffset;
      scrollArrowLeftButton = ref.current.scrollLeft;
    }
  };
  let scrollBarWidth = 0;
  if (arrowrefRight.current !== null && arrowrefLeft.current !== null) {
    scrollBarWidth = ref.current.scrollWidth - ref.current.clientWidth;
  }

  function changeArrowDisplay(e: any) {
    if (arrowrefRight.current !== null && arrowrefLeft.current !== null) {
      if (
        ref.current.scrollLeft > 0 &&
        ref.current.scrollLeft !== scrollBarWidth
      ) {
        arrowrefLeft.current.style.display = "flex";
        arrowrefRight.current.style.display = "flex";
      } else if (ref.current.scrollLeft === 0) {
        arrowrefRight.current.style.display = "flex";
        arrowrefLeft.current.style.display = "none";
      } else {
        arrowrefRight.current.style.display = "none";
        arrowrefLeft.current.style.display = "flex";
      }
    }
  }

  const handleScroll = (e: any) => {
    if (arrowrefRight.current !== null && arrowrefLeft.current !== null) {
      arrowrefLeft.current.style.display = "none";
      arrowrefRight.current.style.display = "none";
    }
  };

  useLayoutEffect(() => {
    setScrollWidth(ref.current?.scrollWidth);
  });

  return [scrollInterval, scrollWidth, changeArrowDisplay, handleScroll];
};
export default useScroller;
