import React, { useEffect, useState } from "react";
import { db } from "utils/firebase";
import useCollection from "./useCollection";

export interface NotificationV02 {
  createdAt: number;
  id: string;
  seen: boolean;
  restaurant: string;
  product: "reservation" | "takeAway" | "user" | "voucher" | "system" | "ticket";
  text: Record<string, { title: string; body?: string }>;
  data?: unknown;
  // actions?:Action[],
  link?: string;
}

enum ActionType {}

interface Action {
  id: string;
  type: ActionType;
  payload?: any;
  text: Record<string, string>;
}

type UseNotificationsType = {
  (restaurantId: string): {
    notifications: NotificationV02[];
    notificationsLoading: boolean;
    markNotificationAsSeen: (id: string) => Promise<void>;
    getAllNotifications: () => Promise<NotificationV02[] | void>;
    markAllNotificationsAsSeen: () => Promise<void>;
    deleteNotification: (id: string) => Promise<void>;
  };
};

const useNotifications: UseNotificationsType = (restaurantId) => {
  // const [notifications, setNotifications] = useState<NotificationV02[]>([]);

  const [n] = useCollection(`notificationsV02`, {
    filter: [["restaurant", "==", restaurantId]],
    sort: ["createdAt", "desc"],
    limit: 20,
  });

  const getAllNotifications = async () => {
    try {
      const ref = n.ref
        .where("restaurant", "==", restaurantId)
        .sort("createdAt", "desc")
        .limit(100);
      const notificationsDocs = await ref.get();
      const data: NotificationV02[] = [];
      notificationsDocs.forEach((doc: any) =>
        data.push({ ...(doc.data() as NotificationV02) })
      );
      return data;
    } catch (e) {
      console.error("Error while getting notifications: ", e);
    }
  };

  const markNotificationAsSeen = async (id: string) => {
    try {
      await n.ref.doc(id).update({ seen: true });
    } catch (e) {
      console.error("Failed to mark notification as seen: ", e);
    }
  };

  const markAllNotificationsAsSeen = async () => {
    try {
      const promises = n.data.map(
        async (p) => await n.ref.doc(p.id).update({ seen: true })
      );
      await Promise.all(promises);
    } catch (e) {
      console.error("Error while marking notifications seen: ", e);
    }
  };

  const deleteNotification = async (id: string) => {
    try {
      await n.ref.doc(id).delete();
    } catch (e) {
      console.error("Error while deleting notification: ", e);
    }
  };

  return {
    notifications: n.data,
    notificationsLoading: n.loading,
    markNotificationAsSeen,
    getAllNotifications,
    markAllNotificationsAsSeen,
    deleteNotification,
  };
};

export default useNotifications;
