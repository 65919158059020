import React, { useState } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import Popover from 'Components/Atoms/Popover';
import OptionsList from './Components/OptionsList';
import Chip from '@material-ui/core/Chip';
import { noop } from 'utils/helper';
import { ArrowDropDown } from 'Components/Atoms/Icons';
import Typography from 'Components/Atoms/Typography';
import useReservationSettings from 'CustomHooks/useReservationSettings';
import { useMediaQuery } from '@material-ui/core';

export type AttributeDropdownProps = {
  value: string[];
  onChange: (newValue: string[]) => void;
  style?: React.CSSProperties;
  guestBook?: boolean;
};

const AttributeDropdown = ({
  value,
  onChange = noop,
  style,
  guestBook,
}: AttributeDropdownProps) => {
  const [anchorEl, setanchorEl] = useState<null | HTMLElement>(null);

  const { customAttr = [] } = useReservationSettings();

  const isMobile = useMediaQuery('(max-width:450px)');

  return (
    <>
      <Box
        outline
        background
        flex
        onClick={(e: any) => setanchorEl(e.currentTarget)}
        style={{
          position: 'relative',
          flexWrap: 'wrap',
          minHeight: 40,
          paddingRight: 27,
          paddingTop: 3,
          paddingLeft: 3,
          paddingBottom: 3,
          ...style,
          // maxWidth: isMobile ? "auto" : 248,
        }} //had to do like this for the padding or the 27px one wouldn't work
      >
        {guestBook && !value.length && (
          <Typography
            variant="text-3"
            style={{ color: 'var(--color-text-subdued)' }}
          >
            Attribute
          </Typography>
        )}
        {!!value?.length &&
          value.map((val, i) => (
            <Chip
              key={i}
              color="primary"
              style={{ margin: 3, padding: '4px 8px', height: 28 }}
              label={<Typography variant="text-3">{val}</Typography>}
            />
          ))}
        <ArrowDropDown
          style={{
            fill: 'var(--color-text-subdued)',
            position: 'absolute',
            right: 5,
            top: guestBook ? 12 : 7,
          }}
        />
      </Box>
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setanchorEl(null)}
        placement="bottom"
        padding={0}
        style={{ width: '100%' }}
      >
        <OptionsList
          value={value}
          onChange={onChange}
          customAttr={customAttr}
        />
      </Popover>
    </>
  );
};

export default AttributeDropdown;
