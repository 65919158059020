import React, { useEffect, useRef, useState } from 'react';
import useMeasure from 'react-use-measure';
import { useDrag } from 'react-use-gesture';
import { useSprings, animated } from '@react-spring/web';

function clamp(number: number, lower: number, upper: number) {
  if (number < lower) {
    return lower;
  } else if (number > upper) {
    return upper;
  } else {
    return number;
  }
}

const Dots: React.FC<{
  dots: number;
  position: number;
  onClick?: (n: number) => void;
}> = ({ dots = 2, position = 1, onClick }) => {
  const width = (dots + dots - 1) * 20;

  if (dots === 1) {
    return <></>;
  }

  return (
    <svg
      width={width / 2 + 'px'}
      height="10px"
      viewBox={'0 0 ' + width + ' 20'}
      xmlns="http://www.w3.org/2000/svg"
      className="dots"
    >
      {Array.from(new Array(dots), (_, i) => i).map((i) => (
        <circle
          key={i}
          cx={i * 40 + 10}
          cy="10"
          r="10"
          fill="white"
          opacity="0.5"
          onClick={onClick ? () => onClick(i) : undefined}
          style={{ zIndex: 2 }}
        ></circle>
      ))}

      <circle
        cx={position * 40 + 10}
        cy="10"
        r="10"
        fill="white"
        style={{ transition: 'cx ease 100ms' }}
      ></circle>
    </svg>
  );
};

const Slider: React.FC<{ slides: React.ReactElement[] }> = ({
  slides = [],
}) => {
  const index = useRef(0);
  const [current, setcurrent] = useState(0);
  const [ref, { width }] = useMeasure();
  const [props, api] = useSprings(
    slides.length,
    (i) => ({
      x: i * width,
      scale: width === 0 ? 0 : 1,
      display: 'block',
    }),
    [width, slides]
  );
  const bind = useDrag(
    ({ active, movement: [mx], direction: [xDir], distance, cancel }) => {
      if (active && distance > width / 2) {
        const x = clamp(
          index.current + (xDir > 0 ? -1 : 1),
          0,
          slides.length - 1
        );
        index.current = x;

        setcurrent(x);
        cancel();
      }
      api.start((i) => {
        if (i < index.current - 1 || i > index.current + 1)
          return { display: 'none' };
        const x = (i - index.current) * width + (active ? mx : 0);
        const scale =
          active && slides.length !== 0 ? 1 - distance / width / 10 : 1;
        return { x, scale, display: 'block' };
      });
    },
    {}
  );

  useEffect(() => {
    changeSlide(0);
  }, [slides.length]);

  const changeSlide = (n: number) => {
    index.current = n;
    setcurrent(n);
    api.start((i) => {
      if (i < n - 1 || i > n + 1) return { display: 'none' };
      const x = (i - n) * width;
      const scale = 1;
      return { x, scale, display: 'block' };
    });
  };

  return (
    <div ref={ref} className="slider-wrapper">
      {props.map(({ x, display, scale }, i) => (
        <animated.div
          className={'slider-page'}
          {...(props.length > 1 ? { ...bind() } : {})}
          key={i}
          style={slides.length ? { display, x } : {}}
        >
          <animated.div style={{ scale }}>{slides[i]}</animated.div>
        </animated.div>
      ))}
      <Dots dots={slides.length} position={current} onClick={changeSlide} />
    </div>
  );
};

export default Slider;
