import React, { useContext, useMemo, useState, useCallback } from 'react';
import {
  CurrentStatus,
  Reservation,
  ReservationStatus,
} from 'types/reservations';
import {
  blockToTime,
  dateHelper,
  timeToBlock,
  toCurrencyString,
} from 'utils/helper';
import { useEffect } from 'react';
import useSearchForCustomId from 'CustomHooks/useSearchForCustomId';
import {
  ReservationActionTypes,
  ReservationContext,
} from 'Contexts/ReservationContext';
import { ReservationShift } from 'gastronaut-shared/types/helper/shifts';
import useLocalStorageState from 'CustomHooks/useLocalStorageState';
import useRestaurant from 'CustomHooks/useRestaurant';
import { RequestStatus } from 'gastronaut-shared/types/helper/reservations';
import useCollection from 'CustomHooks/useCollection';
import { title } from 'process';

const useShowPendingTakeAwayOrders = (restaurantId: string, date: string) => {
  const [orders] = useCollection(`restaurants/${restaurantId}/takeAwayOrders`, {
    filter: [
      ['date', '==', date],
      ['status', '==', 'pending'],
    ],
  });

  return orders;
};

export type Filters = {
  occassions: string[];
  spaces: string[];
  sort: 'time' | 'createdAt' | 'tables';
  showWalkIns?: 'both' | 'hide' | 'only';
  groupBy?: 'occassion' | 'space' | 'time' | 'table' | 'none';
  columns?: string[];
  hideEmptyTables?: boolean;
  mode?: 'addons' | 'tickets' | 'normal';
};

type UseReservationListProps = {
  reservations: Reservation[];
  restaurantId: string;
  date: string;
  loading?: boolean;
  occassions: { id: string; title: string }[];
  spaces: { id: string; name: string }[];
  currentShift?: ReservationShift | null;
  handleAction: (
    id: string,
    type: ReservationActionTypes,
    payload?: any
  ) => void;
  warnings?: string[];
  active: string | null;
  setActive: (id: string | null) => void;
  allReservations?: Reservation[];
};

const tabs = [
  { id: 0, label: 'Current', translation: 'reservations' },
  { id: 1, label: 'Finished', translation: 'reservations' },
  { id: 2, label: 'Canceled', translation: 'reservations' },
];

const waitinglist_tabs = [
  { id: 0, label: 'Waiting', translation: 'reservations' },
  { id: 1, label: 'Finished', translation: 'reservations' },
  { id: 2, label: 'Canceled', translation: 'reservations' },
];

const useReservationList = ({
  reservations,
  date,
  loading = false,
  occassions,
  spaces,
  handleAction,
  warnings = [],
  active,
  restaurantId,
  setActive,
  currentShift = null,
  allReservations,
}: UseReservationListProps) => {
  const { reservationSettings } = useRestaurant();
  const {
    waitinglistEntries,
    currentWaitinglistEntry,
    setcurrentWaitinglistEntry,
    setcurrentReservation,
    floorPlanProps,
    hasWaitingList: hasWaitingListEnabled,
    showWarnings,
    warnings: allWarnings,
    shifts,
  } = useContext(ReservationContext);

  const [filters, setFilters] = useLocalStorageState<Filters>('resaFilter', {
    occassions: [],
    spaces: [],
    sort: 'time',
    groupBy: 'space',
  });

  const [filtersOptions, setfiltersOptions] = useState<{
    occassions: { id: string; title: string }[];
    spaces: { id: string; name: string }[];
    sort: { value: string; label: string }[];
  }>({
    occassions: [...occassions],
    spaces: [...spaces],
    sort: [
      { value: 'time', label: 'By starting time' },
      { value: 'createdAt', label: 'By creation time' },
      { value: 'tables', label: 'By Tables' },
    ],
  });

  useEffect(() => {
    setFilters((f) => ({
      ...f,
      occassions: occassions.map((o) => o.id),
      spaces: f.spaces.length
        ? f.spaces.filter((s) => spaces.find((x) => x.id === s))
        : [],
      sort: f.sort ?? 'time',
    }));

    setfiltersOptions((fO) => ({
      ...fO,
      occassions: [...occassions],
      spaces: [...spaces],
    }));
  }, [occassions, spaces]);

  const [search, setSearch] = useState<string | null>(null);

  const [showAll, setshowAll] = useState(true);

  useEffect(() => {
    setSearch(null);
  }, [date]);

  const pendingTakeAwayOrders = useShowPendingTakeAwayOrders(
    restaurantId,
    date
  );

  // console.log({ pendingTakeAwayOrders, restaurantId, date });

  const [value, setvalue] = useState<string | number | null>(0);

  const { reservationsWithSameCustomId, loadingSameCustomId } =
    useSearchForCustomId(restaurantId, search || '');

  const groups = useMemo(() => {
    console.log({ floorPlanProps, filters });
    if (filters.groupBy === 'occassion') {
      return occassions.map((o) => ({
        id: o.id,
        title: o.title,
      }));
    } else if (filters.groupBy === 'space') {
      return floorPlanProps?.floorPlan?.spaces?.map((s) => ({
        id: s.id,
        title: s.name,
      }));
    } else if (filters.groupBy === 'time') {
      return shifts
        ?.filter?.((x) => x.active)
        ?.sort((a, b) => a.start - b.start)
        ?.map((s) => ({
          id: s.id,
          title: `${s.name} (${blockToTime(s.start)} - ${blockToTime(
            s.close
          )})`,
        }));
    } else if (filters.groupBy === 'table') {
      return floorPlanProps?.floorPlan?.tables
        ?.map((t) => ({
          id: t.name,
          title: t.name,
        }))
        .sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
    } else {
      return [{ title: 'All', id: 'all' }];
    }
  }, [filters, occassions, floorPlanProps]);

  const timeGroups = useCallback(
    (res: Reservation) => {
      return (
        shifts?.find(
          (s) =>
            s.active &&
            (!s.occassions.length || s.occassions.includes(res.occassion)) &&
            s.start <= res.startTimeInBlocks &&
            s.close >= res.startTimeInBlocks
        )?.id ?? ''
      );
    },
    [groups, shifts]
  );

  const filteredReservations = useMemo(() => {
    let resas = reservations;

    if (showWarnings) {
      let warningIds = allWarnings.map((w) => w.id);

      resas = reservations
        .filter((r) => warningIds.includes(r.id))
        .sort((a, b) =>
          filters.sort === 'createdAt'
            ? (a.createdAt ?? 0) - (b.createdAt ?? 0)
            : a.startTimeInBlocks - b.startTimeInBlocks
        );
    }

    const allOccassions = filtersOptions.occassions.map((o) => o.id),
      allSpaces = filtersOptions.spaces.map((o) => o.id);

    console.log(filters, filtersOptions, allOccassions, allSpaces);

    return resas
      .map((r) => {
        let space =
          floorPlanProps?.floorPlan?.tables?.find(
            (x) =>
              (r?.tables?.includes(x.name) || r?.tables?.includes(x.id)) &&
              !!x.space
          )?.space ?? r.space;

        return {
          ...r,
          space,
          group: (filters.groupBy === 'occassion'
            ? r.occassion || '__other__'
            : filters.groupBy === 'space'
            ? space || '__other__'
            : filters.groupBy === 'time'
            ? timeGroups(r) || '__other__'
            : filters.groupBy === 'table'
            ? r.tables?.join(', ') || '__other__'
            : 'all') as string,
        } as Reservation;
      })
      .filter((r) => {
        if (filters.showWalkIns === 'hide' && r.walkIn) {
          return false;
        } else if (filters.showWalkIns === 'only' && !r.walkIn) {
          return false;
        }
        let matchOccassion =
          filters.occassions.includes(r.occassion) ||
          (filters.occassions.length === filtersOptions.occassions.length &&
            !allOccassions.includes(r.occassion));

        if (!matchOccassion) return false;
        let matchSpace =
          !filters.spaces.length ||
          filters.spaces.includes(r.space ?? '') ||
          (r.space && !allSpaces.includes(r.space ?? ''));
        if (!matchSpace) return false;
        return matchOccassion && matchSpace;
      })
      .sort((a, b) =>
        filters.sort === 'createdAt'
          ? (a.createdAt ?? 0) - (b.createdAt ?? 0)
          : a.startTimeInBlocks - b.startTimeInBlocks
      );
  }, [
    reservations,
    filters,
    filtersOptions,
    showWarnings,
    allWarnings,
    floorPlanProps,
  ]);

  const sortedReservations = useMemo(() => {
    let resas = reservations;

    let filteredReservations: Reservation[] = [];

    const allOccassions = filtersOptions.occassions.map((o) => o.id),
      allSpaces = filtersOptions.spaces.map((o) => o.id);

    if (!search) {
      if (showWarnings) {
        let warningIds = allWarnings.map((w) => w.id);

        resas = reservations
          .filter((r) => warningIds.includes(r.id))
          .sort((a, b) =>
            filters.sort === 'createdAt'
              ? (a.createdAt ?? 0) - (b.createdAt ?? 0)
              : a.startTimeInBlocks - b.startTimeInBlocks
          );
      }

      filteredReservations = resas.filter((r) => {
        if (filters.showWalkIns === 'hide' && r.walkIn) {
          return false;
        } else if (filters.showWalkIns === 'only' && !r.walkIn) {
          return false;
        }

        let tab =
          (value === 0 &&
            !['done', 'failed'].includes(r.currentStatus || '')) ||
          (value === 1 && r.currentStatus === 'done') ||
          (value === 2 && r.currentStatus === 'failed');
        if (!tab) return false;
        let matchOccassion =
          filters.occassions.includes(r.occassion) ||
          (filters.occassions.length === filtersOptions.occassions.length &&
            !allOccassions.includes(r.occassion));

        if (!matchOccassion) return false;
        let matchSpace =
          !filters.spaces.length ||
          filters.spaces.includes(r.space ?? '') ||
          (r.space && !allSpaces.includes(r.space ?? ''));
        if (!matchSpace) return false;

        return tab && matchOccassion && matchSpace;
      });
    } else {
      let lowerCaseSearch = search.toLowerCase();

      filteredReservations = (allReservations || resas).filter(
        (res) =>
          res.guest.name.toLowerCase().includes(lowerCaseSearch) ||
          (res.guest.company ?? '').toLowerCase().includes(lowerCaseSearch) ||
          res.customId?.toLowerCase().includes(lowerCaseSearch)
      );
    }

    return filteredReservations
      .sort((a, b) => {
        if (filters.sort === 'time') {
          return a.time && b.time
            ? timeToBlock(a.time) - timeToBlock(b.time)
            : 0;
        } else if (filters.sort === 'tables') {
          let aTables =
              a.tables
                ?.map((x) => Number(x.replace(/\D/gm, '')))
                .sort((a, b) => a - b)?.[0] || -1,
            bTables =
              b.tables
                ?.map((x) => Number(x.replace(/\D/gm, '')))
                .sort((a, b) => a - b)?.[0] || -1;

          return (
            aTables - bTables ||
            (a.time && b.time
              ? timeToBlock(a.time) - timeToBlock(b.time)
              : a.createdAt && b.createdAt
              ? a.createdAt - b.createdAt
              : 0)
          );
        } else {
          return a.createdAt && b.createdAt ? a.createdAt - b.createdAt : 0;
        }
      })
      .map((r) => {
        let space =
          floorPlanProps?.floorPlan?.tables?.find(
            (x) => r?.tables?.includes(x.name) && !!x.space
          )?.space ?? r.space;

        return {
          ...r,
          space,
          group: (filters.groupBy === 'occassion'
            ? r.occassion || '__other__'
            : filters.groupBy === 'space'
            ? space || '__other__'
            : filters.groupBy === 'time'
            ? timeGroups(r) || '__other__'
            : filters.groupBy === 'table'
            ? r.tables?.join(', ') || '__other__'
            : 'all') as string,
        } as Reservation;
      });
  }, [
    reservations,
    filters,
    search,
    value,
    allWarnings,
    showWarnings,
    floorPlanProps,
  ]);

  const currentShiftIsLastShift = useMemo(() => {
    if (!currentShift) return false;
    return !shifts?.some(
      (s) =>
        s.active &&
        !s.closed &&
        s.start > currentShift.start &&
        s.start <= currentShift.close
    );
  }, [currentShift, shifts]);

  const totalPax = useMemo(() => {
    return filteredReservations
      .filter(
        (r) =>
          !currentShift ||
          r.startTimeInBlocks >= currentShift.start ||
          r.startTimeInBlocks <=
            (currentShiftIsLastShift
              ? currentShift.close
              : currentShift.service ?? currentShift.close)
      )
      .reduce(
        (acc, res) =>
          acc +
          (res.status !== ReservationStatus.FAILED &&
          (showAll || typeof res.started !== 'number')
            ? res.guests
            : 0),
        0
      );
  }, [filteredReservations, showAll, currentShift]);

  const totalGroups = useMemo(() => {
    return filteredReservations
      .filter(
        (r) =>
          !currentShift ||
          r.startTimeInBlocks >= currentShift.start ||
          r.startTimeInBlocks <=
            (currentShiftIsLastShift
              ? currentShift.close
              : currentShift.service ?? currentShift.close)
      )
      .filter(
        (r) =>
          r.status !== ReservationStatus.FAILED &&
          (showAll || typeof r.started !== 'number')
      ).length;
  }, [filteredReservations, showAll, currentShift]);

  const totalCancelations = useMemo(() => {
    return filteredReservations
      .filter(
        (r) =>
          !currentShift ||
          r.startTimeInBlocks >= currentShift.start ||
          r.startTimeInBlocks <=
            (currentShiftIsLastShift
              ? currentShift.close
              : currentShift.service ?? currentShift.close)
      )
      .reduce((acc, res) => {
        return res.status === ReservationStatus.FAILED
          ? acc + res.guests
          : acc + 0;
      }, 0);
  }, [filteredReservations, currentShift]);

  useEffect(() => {
    if (active) {
      let el = document.getElementById(active);

      if (el !== null) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [active]);

  // console.log(showAll);

  useEffect(() => {
    if (date < dateHelper(Date.now() - 4 * 60 * 60000)) {
      setvalue(1);
    } else {
      setvalue(0);
    }
  }, [date]);

  const [tab, settab] = useState<'reservations' | 'waitinglist'>(
    window?.location?.search?.includes('waitinglistId') ||
      window?.location?.search?.includes('tab=waitinglist')
      ? 'waitinglist'
      : 'reservations'
  );

  useEffect(() => {
    if (
      tab === 'reservations' &&
      window?.location?.search?.includes('tab=waitinglist')
    ) {
      window.history.replaceState({}, '', window.location.pathname);
    } else if (tab === 'waitinglist') {
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?tab=waitinglist`
      );
    }
  }, [tab]);

  const hasWaitingList = hasWaitingListEnabled || !!waitinglistEntries?.length;

  const filteredWaitingList = useMemo(() => {
    if (tab !== 'waitinglist')
      return waitinglistEntries.filter(
        (entry) =>
          entry.status === RequestStatus.PENDING ||
          entry.status === RequestStatus.WAITING
      );

    if (value === 0) {
      return waitinglistEntries.filter(
        (entry) =>
          entry.status === RequestStatus.PENDING ||
          entry.status === RequestStatus.WAITING
      );
    } else if (value === 1) {
      return waitinglistEntries.filter(
        (entry) => entry.status === RequestStatus.SUCCESS
      );
    } else {
      return waitinglistEntries.filter(
        (entry) =>
          !(
            entry.status === RequestStatus.PENDING ||
            entry.status === RequestStatus.WAITING ||
            entry.status === RequestStatus.SUCCESS
          )
      );
    }
  }, [waitinglistEntries, value, tab]);

  const waitinglistCount = useMemo(
    () =>
      waitinglistEntries.filter(
        (entry) =>
          entry.status === RequestStatus.PENDING ||
          entry.status === RequestStatus.WAITING
      ).length,
    [waitinglistEntries]
  );

  return {
    filteredReservations,
    totalPax,
    totalGroups,
    totalCancelations,
    tab,
    settab,
    hasWaitingList,
    waitinglistCount,
    filteredWaitingList,
    showAll,
    setshowAll,
    search,
    setSearch,
    value,
    setvalue,
    pendingTakeAwayOrders,
    showWarnings,
    warnings,
    active,
    setActive,
    currentWaitinglistEntry,
    setcurrentWaitinglistEntry,
    currentReservation: currentWaitinglistEntry,
    setcurrentReservation,
    floorPlanProps,
    filters,
    setFilters,
    filtersOptions,
    setfiltersOptions,
    tabs,
    waitinglist_tabs,
    reservationSettings,
    reservationsWithSameCustomId,
    loadingSameCustomId,
    sortedReservations,
    loading,
    groups,
  };
};

export default useReservationList;
