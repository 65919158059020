import React, { useState, useMemo, useContext } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import NavBar from 'Components/Organisms/NavBar';
import DateDropdown from 'Components/Organisms/DateDropdown';
import FeatureNavigation from 'Components/Molecules/FeatureNavigation';
import { paths } from 'App/ReservationBook/shared';
import {
  ReservationActionTypes,
  ReservationContext,
} from 'Contexts/ReservationContext';
import { AuthContext } from 'Contexts/AuthContext';
import Dropdown from 'Components/Molecules/Dropdown';
import { Add } from 'Components/Atoms/Icons';
import Button from 'Components/Atoms/Button';
import { useHistory } from 'react-router';
import TimeTableComponent from '../../Components/TimeTable';
import { classHelper, dateHelper, timeToBlock } from 'utils/helper';
import useReservationSideBar from 'CustomHooks/useReservationSideBar';
import useTableSideBar from 'CustomHooks/useTableSideBar';
import ReservationSideBar from 'App/ReservationBook/Components/ReservationSideBar';
import TableSideBar from 'App/ReservationBook/Components/TableSideBar';
import LeftSideBar from 'App/ReservationBook/Components/LeftSideBar';
import IconButton from 'Components/Atoms/IconButton';
import ShuffleModeIcon from 'Components/Atoms/Icons/ShuffleModeIcon';
import { useTheme } from '@material-ui/core';
import { ThemeContext } from 'Contexts/ThemeContext';
import ShuffleButton from 'App/ReservationBook/Components/ShuffleButton';
import WaitinglistSidebar from 'App/ReservationBook/Components/WaitinglistSidebar';
import { RequestStatus } from 'gastronaut-shared/types/helper/reservations';

export type TimeTableProps = {
  date: string;
  restaurantId: string;
  onDateChange: (newDate: string) => void;
};

/*
    CHECKLIST:
    [ ] - Check on multiple browser
    [ ] - Check with touch
    [x] - Add Reservation Panel
    [x] - Add Table Panel
    [ ] - Add Loading State
*/

const TimeTable: React.FC<TimeTableProps> = ({
  restaurantId,
  date,
  onDateChange,
}) => {
  const { uid } = useContext(AuthContext);

  const {
    editReservation,
    reservations,
    currentTable,
    setcurrentTable,
    currentReservation,
    setcurrentReservation,
    currentShift,
    setCurrentShift,
    handleAction,
    handleNewReservation,
    floorPlanProps,
    shifts,
    currentTime,
    occassions = [],
    filteredReservations,
    reservationLoading,
    warnings,
    Shuffle,
    currentWaitinglistEntry,
    setcurrentWaitinglistEntry,
    hasPendingLargeGroupRequests,
  } = useContext(ReservationContext);

  const { darkMode } = useContext(ThemeContext);

  const history = useHistory();

  const handleCurrentReservation = (id: string | null) => {
    setcurrentReservation(id);
    setcurrentTable(null);
  };

  const shiftOptions = useMemo(() => {
    return [
      { id: null, label: 'All day' },
      ...(shifts
        ?.sort((a, b) => (a?.start || 0) - (b?.start || 0))
        .map((s) => ({ id: s.id, label: s.name || '' })) || []),
    ];
  }, [shifts]);

  const classNames = classHelper([
    'timeTable-container',
    (currentTable || currentReservation || currentWaitinglistEntry) &&
      'sidebar-open',
  ]);

  const reservationProps = useReservationSideBar(
    currentReservation,
    reservations
  );
  const tableProps = useTableSideBar(
    currentTable,
    floorPlanProps.floorPlan?.tables || [],
    reservations,
    currentShift,
    shifts,
    date,
    restaurantId
  );

  const inPast = date < dateHelper();

  const pathsWithWaitinglist = useMemo(() => {
    return paths
      .filter((p) => !Shuffle.state || !p.disableOnShuffle)
      .map((p) => {
        if (p.id === 'largeGroups') {
          return {
            ...p,
            notifications: hasPendingLargeGroupRequests,
          };
        }

        return p;
      });
  }, [paths, hasPendingLargeGroupRequests, Shuffle]);

  return (
    <Box background className="timeTable-screen">
      <NavBar
        title="Time Table"
        mainProduct={{
          label: 'Reservations',
          link: (rId) => `/${rId}/reservations/${date}/tablePlan`,
        }}
        reservationBook
        right={
          !Shuffle.state && (
            <Button
              variant="primary"
              translation="reservations"
              onClick={() => {
                history?.push(
                  `/${restaurantId}/reservations/${date}/tablePlan`
                );
                handleNewReservation(date);
              }}
              endIcon={(p) => <Add {...p} />}
            >
              New Reservation
            </Button>
          )
        }
        hideChildren={!!editReservation}
      >
        {!Shuffle.state && (
          <>
            <DateDropdown value={date} onChange={onDateChange} padding={0} />
            <Dropdown
              translation="common"
              style={{ opacity: 0, userSelect: 'none', cursor: 'unset' }}
              disabled
              value={currentShift}
              onChange={setCurrentShift}
              options={shiftOptions}
            />
          </>
        )}
      </NavBar>
      <FeatureNavigation
        date={date}
        paths={pathsWithWaitinglist}
        current="timeTable"
        className="featureNavigation"
      >
        <ShuffleButton date={date} />
      </FeatureNavigation>
      <Box className={classNames}>
        {!editReservation && (
          <LeftSideBar
            restaurantId={restaurantId}
            date={date}
            reservations={filteredReservations}
            occassions={occassions}
            spaces={floorPlanProps.floorPlan?.spaces || []}
            handleAction={(
              id: string,
              type: ReservationActionTypes,
              payload?: any
            ) => {
              if (type === 'relocate')
                history?.push(
                  `/${restaurantId}/reservations/${date}/tablePlan`
                );
              handleAction(id, type, payload);
            }}
            warnings={warnings.map((w) => w.id)}
            active={currentReservation}
            setActive={handleCurrentReservation}
            loading={reservationLoading}
            currentShift={null}
          />
        )}
        <Box className="timeTable-Inner" padding="md">
          <TimeTableComponent
            reservations={reservations}
            handleReservationAction={handleAction}
            shifts={shifts || []}
            spaces={floorPlanProps.floorPlan?.spaces || []}
            tables={floorPlanProps.floorPlan?.tables || []}
            occassions={occassions}
            currentTime={currentTime ? timeToBlock(currentTime) : null}
            activeReservation={currentReservation}
            activeTable={currentTable}
            handleTableClick={setcurrentTable}
            handleReservationClick={setcurrentReservation}
            loading={floorPlanProps.loading}
            overlayOn={!!Shuffle.state}
          />
          {!!Shuffle.state && (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                position: 'fixed',
                bottom: 0,
                alignItems: 'center',
                padding: 20,
                zIndex: 1000,
                background: darkMode
                  ? 'linear-gradient(180deg, rgba(5, 20, 18, 0) 0%, rgba(5, 20, 18, 0.697917) 29.17%, #051412 100%)'
                  : 'linear-gradient(180deg, rgb(255 255 255 / 0%) 0%, rgb(255 255 255 / 70%) 29.17%, #ffffff 100%)',
                width: 'calc(100% - 340px)',
              }}
            >
              <Button
                variant="default"
                onClick={Shuffle.closeShuffle}
                style={{ marginRight: 16 }}
              >
                Abbrechen
              </Button>
              <Button
                variant="primary"
                loading={Shuffle?.state?.loading}
                onClick={
                  !!Shuffle.state?.reservations?.length
                    ? Shuffle.submitShuffle
                    : Shuffle.requestShuffle
                }
              >
                {!!Shuffle.state?.reservations?.length
                  ? 'Änderung speichern'
                  : 'Neu Anordnen'}
              </Button>
            </Box>
          )}
        </Box>
        {currentReservation && (
          <ReservationSideBar
            {...reservationProps}
            occassions={occassions}
            onReservationAction={(
              id: string,
              type: ReservationActionTypes,
              payload?: any
            ) => {
              if (type === 'relocate') {
                history?.push(
                  `/${restaurantId}/reservations/${date}/tablePlan`
                );
              }

              handleAction(id, type, payload);
            }}
            onClose={() => setcurrentReservation(null)}
          />
        )}
        {currentTable && !currentReservation && (
          <TableSideBar
            {...tableProps}
            onCloseSideBar={() => setcurrentTable(null)}
            onWalkin={(tables: string[], space?: string) => {
              history?.push(`/${restaurantId}/reservations/${date}/tablePlan`);
              handleNewReservation(
                date,
                true,
                tables,
                occassions?.[0]?.id,
                space
              );
            }}
            currentTime={currentTime}
            onReservationAction={handleAction}
            setcurrentReservation={setcurrentReservation}
          />
        )}
        {currentWaitinglistEntry && !editReservation && !currentReservation && (
          <WaitinglistSidebar
            occassions={occassions}
            id={currentWaitinglistEntry}
            onClose={() => setcurrentWaitinglistEntry(null)}
          />
        )}
      </Box>
    </Box>
  );
};

export default TimeTable;
