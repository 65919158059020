import React from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import { AddOnPurchase } from '../AddOnDetails';
import axios from 'axios';
import { dateHelper } from 'utils/helper';
import TabCard from 'Components/Molecules/TabCard';
import PurchaseTable from '../PurchaseTable';

export type PurchaseCardProps = {
  restaurantId: string;
  date?: string;
  style?: React.CSSProperties;
};

const PurchaseCard: React.FC<PurchaseCardProps> = ({
  restaurantId,
  date = dateHelper(),
  style,
}) => {
  const [purchases, setpurchases] = React.useState<AddOnPurchase[] | null>(
    null
  );

  React.useEffect(() => {
    setpurchases(null);

    axios
      .get<{ purchases: AddOnPurchase[] }>(
        `https://europe-west3-schillingroofbarhd.cloudfunctions.net/experienceAPI/purchases/${restaurantId}/date/${date}`
      )
      .then(({ data }) => {
        const { purchases } = data;
        setpurchases(purchases.filter((x) => x.status !== 'failed'));
      })
      .catch((err) => {
        setpurchases([]);
      });
  }, [restaurantId, date]);

  if (!purchases?.length) return <></>;

  return (
    <Box style={style}>
      <TabCard
        id="addOns"
        title="Add On Bestellungen "
        badge={purchases.length}
        titleTranslation="reservations"
        closable
        hideTabsWhenOnlyOne
      >
        <PurchaseTable
          data={purchases}
          total={purchases.reduce(
            (acc, cV) => acc + cV.total - (cV.refundedAmount ?? 0),
            0
          )}
          addOns={purchases.reduce((acc, cV) => acc + cV.addOns.length, 0)}
        />
      </TabCard>
    </Box>
  );
};

export default PurchaseCard;
