import React, { useState } from "react";
import Box from "Components/Atoms/Box";
import "./styles.scss";
import { TakeAwayAdvert } from "types/takeAway";
import { noop } from "utils/helper";
import Button from "Components/Atoms/Button";
import ImageField from "Components/Organisms/ImageField";
import Modal from "Components/Molecules/Modal";
import SwitchBox from "Components/Atoms/Switch";
import Translations from "../Translations";

export type AdvertSettingsProps = {
  advert?: null | TakeAwayAdvert;
  open: boolean;
  onClose: () => void;
  onSubmit: (advert: TakeAwayAdvert | null) => void;
};

const AdvertSettings: React.FC<AdvertSettingsProps> = ({
  open,
  advert,
  onClose,
  onSubmit,
}) => {
  !advert &&
    (advert = {
      active: false,
      image: null,
      translations: {
        de: {
          title: "",
          description: "",
        },
        en: {
          title: "",
          description: "",
        },
      },
    });

  const [newAdvert, setnewAdvert] = useState<TakeAwayAdvert>(advert);

  const handleTranslationsChange = (newState: any) => {
    setnewAdvert({ ...newAdvert, translations: newState });
  };

  return (
    <Modal
      maxWidth="md"
      open={open}
      title="Advert Settings"
      bottomPart={
        <Box flex style={{ flexDirection: "row-reverse" }}>
          <Button
            variant="primary"
            onClick={() => {
              onSubmit(newAdvert);
              onClose();
            }}
            style={{ marginLeft: 16 }}
          >
            Create
          </Button>
          <Button
            onClick={() => {
              setnewAdvert({ ...newAdvert, ...advert });
              onClose();
            }}
          >
            Cancel
          </Button>
        </Box>
      }
      onClose={() => {
        setnewAdvert({ ...newAdvert, ...advert });
        onClose();
      }}
    >
      <Box>
        <SwitchBox
          checked={newAdvert.active}
          label="Show an Advert"
          onChange={() =>
            setnewAdvert({
              ...newAdvert,
              active: newAdvert.active === true ? false : true,
            })
          }
          color="primary"
        />

        <Box
          flex
          fullSize
          className="space-between"
          style={{ flexWrap: "wrap" }}
        >
          <div style={{ maxWidth: "90%" }}>
            <Translations
              state={newAdvert?.translations}
              title=""
              titleTranslation="settings"
              onChange={handleTranslationsChange}
              onBlur={noop}
              keys={[
                {
                  id: "title",
                  required: false,
                  label: "Name",
                  labelTranslation: "settings",
                },
                {
                  id: "description",
                  required: false,
                  label: "Button",
                  labelTranslation: "settings",
                },
                {
                  id: "additionalDescription",
                  richText: true,
                  required: false,
                  label: "Description",
                  labelTranslation: "settings",
                },
              ]}
              defaultLanguage="de"
              addLanguage={true}
              useGoogleTranslate={false}
            />
          </div>

          <div style={{ maxWidth: "90%", marginLeft: 24 }}>
            <ImageField
              label="Image"
              value={newAdvert?.image || null}
              type="url"
              previewType="img"
              onChange={(val) =>
                setnewAdvert({
                  ...newAdvert,
                  image: typeof val === "string" ? val : null,
                })
              }
            />
          </div>
        </Box>
      </Box>
    </Modal>
  );
};

export default AdvertSettings;
