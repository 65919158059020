import LanguageContextProvider from 'Contexts/LanguageContext';
import AuthContextProvider from 'Contexts/AuthContext';
import ThemeContextProvider from 'Contexts/ThemeContext';
import ReservationContextProvider from 'Contexts/ReservationContext';
import TakeAwayContextProvider from 'Contexts/TakeAwayContext';
import Router from 'RouterV02';
import RestaurantContextProvider from 'Contexts/RestaurantContext';
import ImageContextProvider from 'Contexts/ImageContext';
import IntercomContextProvider from 'Contexts/IntercomContext';
import TicketContextProvider from 'Contexts/TicketContext';
import useLocalStorageState from 'CustomHooks/useLocalStorageState';
import SiaContextProvider from 'Contexts/SiaContext';
import GuestStatisticsContextProvider from 'Contexts/GuestStatisticsContext';
import { FacebookProvider } from 'react-facebook';
import ChangelogContextProvider from 'Contexts/ChangelogContext';
import VoucherContextProvider from 'Contexts/VoucherContext';

const VERSION = JSON.stringify(require('../package.json').version);

function App() {
  const [restaurantId, setrestaurantId] = useLocalStorageState<null | string>(
    'restaurantId',
    null
  );

  let currentVersion = 'unknown';
  try {
    currentVersion = VERSION.slice(1, VERSION.length - 1);
  } catch (error) {
    console.log('Cannot get version of application.');
  }

  return (
    <div className="App">
      <FacebookProvider appId="510873877209537">
        <ThemeContextProvider {...{ restaurantId, setrestaurantId }}>
          <LanguageContextProvider>
            <AuthContextProvider
              {...{ restaurantId, setrestaurantId, currentVersion }}
            >
              <RestaurantContextProvider {...{ restaurantId, setrestaurantId }}>
                <ReservationContextProvider
                  {...{ restaurantId, setrestaurantId }}
                >
                  <SiaContextProvider>
                    <TakeAwayContextProvider
                      {...{ restaurantId, setrestaurantId }}
                    >
                      {/* <IntercomContextProvider> */}
                      <ImageContextProvider
                        {...{ restaurantId, setrestaurantId }}
                      >
                        <TicketContextProvider {...{ restaurantId }}>
                          <GuestStatisticsContextProvider {...{ restaurantId }}>
                            <ChangelogContextProvider
                              {...{ restaurantId, currentVersion }}
                            >
                              <VoucherContextProvider {...{ restaurantId }}>
                                <Router />
                              </VoucherContextProvider>
                            </ChangelogContextProvider>
                          </GuestStatisticsContextProvider>
                        </TicketContextProvider>
                      </ImageContextProvider>
                      {/* </IntercomContextProvider> */}
                    </TakeAwayContextProvider>
                  </SiaContextProvider>
                </ReservationContextProvider>
              </RestaurantContextProvider>
            </AuthContextProvider>
          </LanguageContextProvider>
        </ThemeContextProvider>
      </FacebookProvider>
    </div>
  );
}

export default App;
