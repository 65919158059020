import React, { useContext, useEffect } from 'react';
import TablePlan from './TablePlan';
import { RouteComponentProps } from 'react-router-dom';
import { Modify } from 'utils/types';
import { ReservationContext } from 'Contexts/ReservationContext';
import { AuthContext } from 'Contexts/AuthContext';

export type TablePlanContainerProps = Modify<
  RouteComponentProps,
  {
    match: { params: { date: string; restaurantId: string } };
  }
>;

const TablePlanContainer: React.FC<TablePlanContainerProps> = ({
  history,
  match,
}) => {
  const { date, restaurantId } = match.params;

  const { setrestaurantId } = useContext(AuthContext);
  const { setdate } = useContext(ReservationContext);

  const onDateChange = (newDate: string) =>
    history.push(`/${restaurantId}/reservations/${newDate}/tablePlan`);

  useEffect(() => {
    setdate(match.params.date);
    setrestaurantId(restaurantId);
  }, [match.params, setdate]);

  useEffect(() => {
    let el = document.getElementsByTagName('body')[0];

    el.classList.add('hideOverflow');
    el.classList.add('tableplanscreen');
    return () => {
      el.classList.remove('hideOverflow');
      el.classList.remove('tableplanscreen');
    };
  }, []);

  return <TablePlan {...{ date, restaurantId, onDateChange }} />;
};

export default TablePlanContainer;
