import CalendarContextProvider from 'Contexts/CalendarContext';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'Contexts/AuthContext';
import { RouteComponentProps } from 'react-router-dom';
import { Modify } from 'utils/types';
import Calendar from './CalendarScreen';
import { useIntercom } from 'react-use-intercom';

export type ReservationCalendarContainerProps = Modify<
  RouteComponentProps,
  {
    match: { params: { date: string; restaurantId: string } };
  }
>;

const ReservationCalendarContainer: React.FC<
  ReservationCalendarContainerProps
> = ({ history, match }) => {
  const { date, restaurantId } = match.params;

  const [, setdate] = useState(date);

  const onDateChange = (newDate: string) => {
    history.push(`/${restaurantId}/calendar/${newDate}`);
  };

  const { setrestaurantId } = useContext(AuthContext);

  useEffect(() => {
    setrestaurantId(restaurantId);
    setdate(date);
  }, [match.params]);

  return (
    <CalendarContextProvider
      restaurantId={restaurantId}
      onDateChange={onDateChange}
      date={date}
    >
      <Calendar restaurantId={restaurantId} />
    </CalendarContextProvider>
  );
};

export default ReservationCalendarContainer;
