import React from "react";
import {
  default as MaterialInputBase,
  InputBaseProps as MaterialInputBaseProps,
} from "@material-ui/core/InputBase";

import { Modify } from "utils/types";

export type InputBaseProps = Modify<
  { onFocus: React.FocusEvent<any>; onBlur: React.FocusEvent<any> },
  MaterialInputBaseProps
>;

const InputBase = ({ onFocus, onBlur, ...props }: InputBaseProps) => {
  return <MaterialInputBase inputProps={{ onFocus, onBlur }} {...props} />;
};

export default InputBase;
