import React, { useState, useMemo } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import { Combination } from 'types/reservations';
import { noop } from 'utils/helper';
import Typography from 'Components/Atoms/Typography';
import { ArrowDropDown, WarningRounded } from 'Components/Atoms/Icons';
import Popover from 'Components/Atoms/Popover';
import TableDropdownCombinations from './Components/TableDropdownCombinations';
import MenuItem from 'Components/Atoms/MenuItem';

export type TableDropdownProps = {
  value: null | string[];
  combinations: null | Combination[];
  loading?: boolean;
  onChange: (nV: string[]) => void;
  style?: React.CSSProperties;
  disabled?: boolean;
};

const TableDropdown = ({
  value = null,
  combinations = null,
  onChange = noop,
  loading = false,
  style,
  disabled = false,
}: TableDropdownProps) => {
  const error = !combinations ? 'First Select a Time Slot' : null;
  const [anchorEl, setanchorEl] = useState<null | HTMLElement>(null);

  const currentCombination = useMemo(() => {
    if (!value?.length) return null;

    return (
      combinations?.find(
        (c) =>
          c.tables.every((x) => value.includes(x)) &&
          value.every((x) => c.tables.includes(x))
      ) ?? null
    );
  }, [value, combinations]);

  const formatedValue = useMemo(() => {
    if (!value?.length) {
      return '';
    }

    let string = value.sort().join(', ');

    if (currentCombination?.alias?.length) {
      string += ` (${currentCombination.alias.join(', ')})`;
    }

    return string;
  }, [value, currentCombination]);

  const handleChange = (nV: string[]) => {
    onChange(nV);
    setanchorEl(null);
  };

  return (
    <>
      <Box
        outline
        background
        padding="sm"
        flex
        className="space-between clickable"
        onClick={(e) =>
          !disabled && setanchorEl((x) => (!x ? e.currentTarget : null))
        }
        style={{ ...style, opacity: !disabled ? 1 : 0.6 }}
      >
        <Box flex className="space-between">
          <Typography
            variant="text-3"
            color="subdued"
            style={{ width: '100%' }}
            translation={null}
          >
            {!disabled ? formatedValue : 'No Tables'}
          </Typography>
          {!currentCombination && (
            <WarningRounded style={{ color: '#CCC910' }} />
          )}
        </Box>
        <ArrowDropDown style={{ fill: 'var(--color-text-subdued)' }} />
      </Box>
      {!disabled && !currentCombination && (
        <Box style={{ marginTop: 4 }}>
          <Typography
            variant="text-3"
            color="subdued"
            style={{ width: 100 }}
            translation="reservations"
          >
            {!!value?.length
              ? 'These Tables might be blocked'
              : 'No Tables selected'}
          </Typography>
        </Box>
      )}
      {!!currentCombination?.expression && (
        <Box style={{ marginTop: 4 }}>
          <Typography
            variant="text-3"
            color="error"
            style={{ width: 100 }}
            translation="reservations"
          >
            {currentCombination.expression}
          </Typography>
        </Box>
      )}
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setanchorEl(null)}
        placement="bottom"
        padding={0}
        style={{ maxHeight: 200, overflow: 'scroll', display: 'block' }}
      >
        {loading && <MenuItem>Loading</MenuItem>}
        {!value?.length && !combinations && !loading && (
          <MenuItem style={{ color: 'var(--color-critical)' }}>
            {error}
          </MenuItem>
        )}
        {!value?.length && combinations?.length === 0 && !loading && (
          <MenuItem style={{ color: 'var(--color-critical)' }}>
            No available tables
          </MenuItem>
        )}
        {combinations && (
          <TableDropdownCombinations
            value={value}
            combinations={combinations}
            onChange={handleChange}
          />
        )}
      </Popover>
    </>
  );
};

export default TableDropdown;
