import { useEffect, useRef, useState } from 'react';

export default function useObserver<T extends Element>(
  callback: (bool: boolean) => void,
  onlyWhenTrue = false,
  options: IntersectionObserverInit = {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  }
) {
  const elementRef = useRef<null | T>(null);
  const [isVisible, setIsVisible] = useState(false);

  const callbackFunction: IntersectionObserverCallback = (entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);

    if (callback && (!onlyWhenTrue || entry.isIntersecting)) {
      callback(entry.isIntersecting);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, {
      root: null,
      rootMargin: '0px',
      threshold: 1,
      ...options,
    });
    let el = elementRef.current;

    if (el) observer.observe(el);

    return () => {
      if (!!el) {
        observer.unobserve(el);
      }
    };
  }, [elementRef, options, callback]);

  return {
    ref: elementRef,
    isVisible,
  };
}
