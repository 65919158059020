import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const Rating1 = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="98"
      height="60"
      viewBox="0 0 98 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 8C0 3.58172 3.58172 0 8 0H90C94.4183 0 98 3.58172 98 8V52C98 56.4183 94.4183 60 90 60H8C3.58172 60 0 56.4183 0 52V8ZM30 43.175L40.375 49.45C42.275 50.6 44.6 48.9 44.1 46.75L41.35 34.95L50.525 27C52.2 25.55 51.3 22.8 49.1 22.625L37.025 21.6L32.3 10.45C31.45 8.42502 28.55 8.42502 27.7 10.45L22.975 21.575L10.9 22.6C8.7 22.775 7.8 25.525 9.475 26.975L18.65 34.925L15.9 46.725C15.4 48.875 17.725 50.575 19.625 49.425L30 43.175ZM79.5103 48V11.6364H73.2425L64.2049 17.4425V23.4794L72.7099 18.0462H72.9229V48H79.5103Z"
        fill="#FFA800"
      />
    </SvgIcon>
  );
};

export default Rating1;
