import Box from "Components/Atoms/Box";
import Typography from "Components/Atoms/Typography";
import React, { useMemo } from "react";
import { Block as BlockType } from "utils/types";
import Group from "./Group";

type BlockProps = {
  block: BlockType;
};

const timeString = ({ block, time }: BlockType) => {
  if (block % 2) {
    return " ";
  } else if (!(block % 4)) {
    return time;
  } else {
    return ":30";
  }
};

const Block = ({ block }: BlockProps) => {
  const totalGuests = useMemo(
    () => block.reservations.reduce((acc: number, cV) => acc + cV.guests, 0),
    [block]
  );

  return (
    <Box
      flex
      className="flex-column"
      style={{ justifyContent: "flex-end", minWidth: 26, width: 26 }}
    >
      {!!totalGuests && (
        <Typography
          variant="text-3"
          textAlign="center"
          style={{ marginBottom: 2 }}
        >
          {totalGuests}
        </Typography>
      )}
      {block.reservations
        .sort((a, b) => a.guests - b.guests)
        .filter((r) => r.guests)
        .map((res) => (
          <Group key={res.id + Math.random()} res={res} />
        ))}
      <Typography
        variant="text-4"
        color="subdued"
        textAlign="left"
        style={{ marginTop: 8, height: 16, width: "100%" }}
        translation={null}
      >
        {timeString(block)}
      </Typography>
    </Box>
  );
};

export default Block;
