import React from 'react';
import LargeGroupList from './LargeGroupList';
import { Modify } from 'utils/types';
import { RouteComponentProps } from 'react-router-dom';

export type LargeGroupListContainerProps = Modify<
  RouteComponentProps,
  {
    match: { params: { restaurantId: string } };
  }
>;

const LargeGroupListContainer = ({ match }: LargeGroupListContainerProps) => {
  return <LargeGroupList restaurantId={match.params.restaurantId} />;
};

export default LargeGroupListContainer;
