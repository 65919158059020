import React, { useState, useEffect, useMemo } from 'react';
import Box from 'Components/Atoms/Box';
import { ReservationShift } from 'types/shifts';
import { FloorPlan } from 'types/reservations';
import Sidebar from 'Components/Molecules/Sidebar';
import Button from 'Components/Atoms/Button';
import { Close, CheckCircle, ArrowBackIos } from 'Components/Atoms/Icons';
import { ActiveShift } from 'CustomHooks/useCalendarDates';
import { Menu } from '../../../../types/menu';
import TitleWithIcon from 'Components/Molecules/TitleWithIcon';
import './styles.scss';
import {
  Assignment,
  ConfirmationNumber,
  Alarm,
  HourglassEmpty,
  PlaylistAddCheck,
  Today,
  Note,
  Comment,
  HourglassFull,
} from '@material-ui/icons';
import AdditionalSettings from './Components/AdditionalSettings';
import Typography from 'Components/Atoms/Typography';
import Select, { Option } from 'Components/Atoms/Select';
import WeekDays from 'App/Settings/Components/Weekdays';
import DateRange from 'App/Settings/Components/DateRange';
import { blockToTime, dateHelper, toEuropeanDate } from 'utils/helper';
import ShiftColor from 'App/Settings/Components/ShiftColor';
import TextField from 'Components/Atoms/TextField';
import produce from 'immer';
import useRestaurant from 'CustomHooks/useRestaurant';
import useIsLightReservations from 'CustomHooks/useIsLightReservations';
import OccassionsAndSpaces from 'App/Settings/Components/OccassionsAndSpaces';
import DetailedShiftSettings from '../DetailedShiftSettings';
import EventSettings from '../EventSettings';
import { ShiftType } from 'gastronaut-shared/types/helper/shifts';
import TablesMatching from './Components/TablesMatching';
import { useMediaQuery } from '@material-ui/core';
import { Event } from 'types/event';
import SwitchBox from 'Components/Atoms/Switch';
import useAlert from 'CustomHooks/useAlert';

const TIMES = Array.from(Array(4 * 28 + 1), (_, i) => ({
  id: i,
  value: i,
  label: blockToTime(i),
}));

const ADDITIONAL_DEFAULT = {
  blockedAt: [],
  slotsPerBlock: {
    rules: [],
    standard: 20,
  },
  reservationLength: {
    rules: [],
    standard: 8,
  },
  floorPlan: null,
  placeAt: null,
};

const PLACE_AT_OPTIONS: Option[] = [
  { id: 'null', label: 'Indoor and Outdoor', translation: 'calendar' },
  { id: 'indoor', label: 'Indoor only', translation: 'calendar' },
  { id: 'outodoor', label: 'Outdoor only', translation: 'calendar' },
];

export type NewEditReservationShiftProps = {
  activeShift: ActiveShift<ReservationShift>;
  setactiveShift: React.Dispatch<
    React.SetStateAction<ActiveShift<ReservationShift>>
  >;
  onClose: VoidFunction;
  onSubmit: () => Promise<void>;
  spaces: { id: string; title: string }[];
  settings?: {
    occassions: { id: string; title: string }[];
    spaces: { id: string; title: string }[];
    floorPlans: FloorPlan[];
    maxGuestsOnWebsite: number;
    menues: Menu[];
  };
};

export type Type = 'open' | 'closed' | 'note';

const NewEditReservationShift: React.FC<NewEditReservationShiftProps> = ({
  activeShift,
  setactiveShift,
  onClose,
  onSubmit,
  spaces = [],
  settings,
}) => {
  const [loading, setloading] = useState(false);
  const [hasNoName, sethasNoName] = useState<boolean>(false);

  const hasTimeError: () => string = () => {
    if (
      activeShift?.shift.start === undefined ||
      activeShift?.shift.close === undefined ||
      activeShift?.shift.service === undefined
    ) {
      return 'All times have to be set';
    } else if (activeShift?.shift.start > activeShift?.shift.close) {
      return 'Start of Shift has to be before end of Shift';
    } else if (activeShift?.shift.service > activeShift?.shift.close) {
      return 'Last Reservation has to be before end of Shift';
    } else if (activeShift?.shift.start > activeShift?.shift.service) {
      return 'Last Reservation has to be after start of Shift';
    }

    return '';
  };

  const handleSubmit = async () => {
    if (hasTimeError()) return;
    if (!activeShift?.shift.name) {
      sethasNoName(true);
      return;
    }
    if (hasNoName) return;
    setloading(true);
    await onSubmit();
    setloading(false);
  };

  const newShift = activeShift?.type === 'new';
  const onlyThisDay = !activeShift?.allUpcomingShifts;

  const handleTypeChange = (newType: Type | 'blocked') => {
    if (
      !activeShift?.shift ||
      newType === type ||
      (newType === 'blocked' &&
        !!activeShift.shift.overwrite?.blockReservations)
    )
      return;

    let shift = { ...activeShift.shift };

    if (type === 'open' && newType === 'blocked') {
      delete shift.additional;
      if (shift.message?.de?.includes('geschlossen')) delete shift.message;
    } else if (type === 'closed') {
      shift.closed = false;
    } else if (type === 'note') {
      shift.commentOnly = false;
    }

    if (newType === 'open') {
      shift.active = true;

      if (shift.allDay) {
        shift.start = 72;
        shift.close = 96;
        shift.service = 88;
      }

      if (shift.overwrite?.blockReservations) {
        delete shift.overwrite.blockReservations;
      }

      shift.allDay = false;
      shift.additional = {
        blockedAt: [],
        floorPlan: null,
        placeAt: null,
        reservationLength: {
          rules: [],
          standard: 8,
          avoidDoubleSeating: false,
        },
        slotsPerBlock: {
          rules: [],
          standard: 20,
        },
        menu: null,
      };
      delete shift.message;
    } else if (newType === 'closed') {
      shift.closed = true;
      shift.message = {
        de: 'Restaurant ist geschlossen',
        en: 'Restaurant is closed',
      };
      shift.additional = {
        blockedAt: [],
        floorPlan: null,
        placeAt: null,
        reservationLength: {
          rules: [],
          standard: 8,
          avoidDoubleSeating: false,
        },
        slotsPerBlock: {
          rules: [],
          standard: 20,
        },
        menu: null,
      };
    } else if (newType === 'note') {
      shift.commentOnly = true;
      delete shift.occassions;
      delete shift.spaces;
    } else if (newType === 'blocked') {
      if (type !== 'open') {
        shift.active = true;

        if (shift.allDay) {
          shift.start = 72;
          shift.close = 96;
          shift.service = 88;
        }

        shift.allDay = false;
        shift.additional = {
          blockedAt: [],
          floorPlan: null,
          placeAt: null,
          reservationLength: {
            rules: [],
            standard: 8,
            avoidDoubleSeating: false,
          },
          slotsPerBlock: {
            rules: [],
            standard: 20,
          },
          menu: null,
        };
        delete shift.message;
      }

      shift.overwrite = {
        ...(shift.overwrite ?? {}),
        blockReservations: true,
      };
    }

    setactiveShift((aS) => (!!aS ? { ...aS, shift } : null));
  };

  const type: Type = useMemo(() => {
    if (!activeShift?.shift) return 'open';

    let shift = activeShift.shift;

    if (shift.commentOnly) return 'note';

    if (shift.closed) return 'closed';

    return 'open';
  }, [activeShift]);

  const [withEndDate, setwithEndDate] = useState(
    activeShift?.shift.endAt ?? false
  );

  const handleTimeChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    c: React.ReactNode
  ) => {
    let { name = '', value } = e.target;
    if (name || typeof value === 'number') {
      setactiveShift((aS) =>
        !!aS
          ? {
              ...aS,
              shift: {
                ...aS.shift,
                [name]: value,
                ...((aS.shift.commentOnly || aS.shift.closed) &&
                name === 'close'
                  ? { service: value as number }
                  : {}),
              },
            }
          : null
      );
    }
  };

  const handleNameChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    let name = e.target.value;
    if (!name) {
      sethasNoName(true);
    } else {
      sethasNoName(false);
    }
    setactiveShift((aS) =>
      !!aS ? { ...aS, shift: { ...aS.shift, name } } : null
    );
  };

  const { products, apps } = useRestaurant();
  const [language, setlanguage] = useState<'de' | 'en'>('de');

  const light = useIsLightReservations();

  const floorPlans = settings?.floorPlans
    .filter((fP) => fP.active && !(fP as any)?.deleted)
    .map((fP) => ({
      id: fP.id,
      label: fP.name,
    }));

  const menues = settings?.menues.map((m) => ({
    id: m.id,
    label: m.shortName,
  }));

  const currMenu = !activeShift?.shift?.additional?.menu
    ? settings?.menues?.find((m) => m.standard)?.id
    : settings?.menues?.find(
        (m) => m.id === activeShift?.shift?.additional?.menu
      )?.id;

  const currentFloorPlan = useMemo(() => {
    const id =
      activeShift?.shift?.additional?.floorPlan ??
      settings?.floorPlans?.find((fP) => fP.standard)?.id ??
      null;

    if (!id) return null;

    return settings?.floorPlans?.find((fP) => fP.id === id);
  }, [activeShift, settings?.floorPlans]);

  useEffect(() => {
    if (currentFloorPlan && activeShift?.shift?.spaces) {
      let existingSpaces = activeShift.shift.spaces.filter(
        (x) => !!currentFloorPlan.spaces.find((s) => s.id === x)
      );

      if (existingSpaces.length !== activeShift.shift.spaces.length) {
        setactiveShift((aS) =>
          !!aS
            ? { ...aS, shift: { ...aS.shift, spaces: existingSpaces } }
            : null
        );
      }
    }
  }, [currentFloorPlan, activeShift?.shift?.spaces]);

  const tablesMatching = useMemo(() => {
    if (
      activeShift?.shift &&
      !activeShift?.shift?.commentOnly &&
      currentFloorPlan
    ) {
      let {
        spaces = [],
        occassions = [],
        matchBoth = false,
      } = activeShift.shift;
      let { placeAt = null } = activeShift.shift?.additional ?? {};

      let availableSpaces = currentFloorPlan.spaces
        .filter(
          (s) =>
            !placeAt ||
            (placeAt === 'indoor' && !s.outdoor) ||
            (placeAt === 'outdoor' && s.outdoor)
        )
        .map((s) => s.id);

      let allTables = currentFloorPlan.tables.filter((t) => {
        if (!availableSpaces.includes(t.space)) return false;

        let spaceMatch = spaces.includes(t.space);
        let occassionMatch =
          !t.occassions.length ||
          occassions.some((o) => t.occassions.includes(o));

        return (
          (!matchBoth && (spaceMatch || occassionMatch)) ||
          (matchBoth && spaceMatch && occassionMatch)
        );
      });

      let tableIds = allTables.map((t) => t.name);

      let combinations = currentFloorPlan.tableCombinations.filter((t) =>
        t.tables.every((t) => tableIds.includes(t))
      );

      let tables = allTables?.filter((t) => !!t.occassions.length);

      let guestMap = Array.from(
        new Array(settings?.maxGuestsOnWebsite ?? 12),
        (_, i) => i + 1
      ).reduce(
        (acc, cV) => ({ ...acc, [cV]: 0 }),
        {} as Record<number, number>
      );

      let largestGroup = 0;

      combinations.forEach((c) => {
        Array.from(
          new Array(Math.max(c.max - c.min, 0) + 1),
          (_, i) => i + c.min
        ).forEach((g) => {
          guestMap[g] = (guestMap?.[g] ?? 0) + 1;

          if (largestGroup < g) {
            largestGroup = g;
          }
        });
      });

      tables.forEach((c) => {
        Array.from(
          new Array(Math.max(c.max - c.min, 0) + 1),
          (_, i) => i + c.min
        ).forEach((g) => {
          guestMap[g] = (guestMap?.[g] ?? 0) + 1;

          if (largestGroup < g) {
            largestGroup = g;
          }
        });
      });

      return {
        tables: tables.map((t) => t.name),
        combinations: combinations.map(
          (c) => c.primaryString ?? c.tables.join(', ')
        ),
        guestMap,
        hasTables: !!tables.length || !!combinations.length,
        largestGroup,
        largestGroupIsTooSmall:
          (settings?.maxGuestsOnWebsite ?? 12) > largestGroup,
        missingSizes: Object.entries(guestMap)
          .filter((x) => x[1] === 0 && Number(x[0]) < largestGroup)
          .map((x) => x[0]),
      };
    }

    return null;
  }, [
    currentFloorPlan,
    activeShift?.shift?.spaces,
    activeShift?.shift?.occassions,
    activeShift?.shift?.additional?.placeAt,
  ]);

  const alert = useAlert();

  const handleFloorPlanChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    c: React.ReactNode
  ) => {
    let { value } = e.target;

    if (value === settings?.floorPlans?.find((f) => f.standard)?.id) {
      value = '';
    }

    setactiveShift(
      (prev) => {
        if (!prev) return null;
        return {
          ...prev,
          shift: {
            ...prev.shift,
            additional: {
              ...(prev.shift.additional ?? ADDITIONAL_DEFAULT),
              floorPlan: String(value) || null,
            },
          },
        };
      }
      // produce((draft) => {
      //   if (!draft) return;

      //   if (!draft.shift.additional) {
      //     draft.shift.additional = ADDITIONAL_DEFAULT;
      //   }

      //   draft.shift.additional.floorPlan = String(value) || null;
      // })
    );
  };

  const handlePlaceAtChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    c: React.ReactNode
  ) => {
    let value: any = e.target.value;

    if (value === 'null') {
      value = null;
    }

    setactiveShift(
      produce((draft) => {
        if (!draft) return;

        if (!draft.shift.additional) {
          draft.shift.additional = ADDITIONAL_DEFAULT;
        }

        draft.shift.additional.placeAt = value || null;
      })
    );
  };

  const handleMenuChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    let { value } = e.target;

    setactiveShift(
      produce((draft) => {
        if (!draft) return;

        if (!draft.shift.additional) {
          draft.shift.additional = ADDITIONAL_DEFAULT;
        }

        draft.shift.additional.menu = String(value);
      })
    );
  };

  const handleMessageChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const { name, value } = e.target;
    setactiveShift((aS: any) =>
      !!aS
        ? {
            ...aS,
            shift: {
              ...aS.shift,
              [name]: {
                ...(aS.shift?.[name] || { de: '', en: '' }),
                [language]: value,
              },
            },
          }
        : null
    );
  };

  const smaller = useMediaQuery('(max-width:1300px)');

  // console.log('TESTING2', activeShift);

  return (
    <Box style={{ height: '100%' }}>
      <Sidebar
        id="new-reservation-shift"
        title={
          activeShift?.type === 'new'
            ? 'New Reservation Shift'
            : `Edit ${activeShift?.shift.name}`
        }
        titleTranslation="calendar"
        onClose={onClose}
        actions={
          <Button
            fullWidth
            endIcon={(p) => (
              <CheckCircle {...p} color="primary" style={{ marginLeft: 8 }} />
            )}
            onClick={handleSubmit}
            loading={loading}
            disabled={!!hasTimeError()}
            variant="outlined"
            style={{ backgroundColor: 'var(--color-bg)' }}
          >
            Save
          </Button>
        }
      >
        <Box flex padding={0} className="mg-bt-md">
          <TextField
            value={activeShift?.shift.name}
            name="name"
            label="Name"
            onChange={handleNameChange}
            fullWidth
            autoFocus={activeShift?.type !== 'edit'}
            style={{ marginRight: 8 }}
            required
            errorMessage={hasNoName ? 'Name is mandatory' : undefined}
          />
          <ShiftColor
            value={activeShift?.shift.color ?? '#ffffff'}
            onChange={(color) =>
              setactiveShift((aS) =>
                !!aS ? { ...aS, shift: { ...aS.shift, color } } : null
              )
            }
            horizontalPosition={{ left: '-200px' }}
          />
        </Box>
        <TitleWithIcon
          title="Type of Shift"
          descriptionTranslationKey="calendar_typeOfShiftBody"
          description="Um was für eine Schicht hält es sich?"
          icon={(p) => <Assignment {...p} />}
          hide={activeShift?.type === 'close'}
        >
          <Select
            name="typeOfShift"
            options={
              [
                {
                  id: 'open',
                  label: 'Open for Reservations',
                  translation: 'calendar',
                  hide: false,
                },
                {
                  id: 'closed',
                  label: 'Closed for Reservations',
                  translation: 'calendar',
                  hide: false,
                },
                {
                  id: 'blocked',
                  label: 'Closed for Guests',
                  translation: 'calendar',
                  hide:
                    !newShift &&
                    !activeShift?.shift?.overwrite?.blockReservations,
                },
                {
                  id: 'note',
                  label: 'Team Note',
                  translation: 'calendar',
                  hide: !newShift && type !== 'note',
                },
              ].filter((x) => !x.hide) as any
            }
            value={
              activeShift?.shift?.overwrite?.blockReservations
                ? 'blocked'
                : type
            }
            disabled={!newShift && type === 'note'}
            onChange={(e) => handleTypeChange(e.target.value as Type)}
          />

          {type === 'closed' && (
            <Typography
              variant="text-4"
              color="error"
              translationKey="calendar_closedDescription"
              block
            >
              Closed for Reservations means that both waitstuff as well as
              Guests cannot make a reservation for this occassion and time
              period.
            </Typography>
          )}
          {type === 'note' && (
            <>
              <Typography
                variant="text-4"
                color="subdued"
                translationKey="calendar_noteDescription"
                block
              >
                Team Notes do not effect the reservations as well as delivery
                settings, but help you to notify your team or leave notes like a
                banquett order.
              </Typography>
              <Typography
                variant="text-4"
                color="subdued"
                translation="calendar"
                style={{ marginBottom: 4 }}
                block
              >
                Type can not be changed afterwards for Notes
              </Typography>
            </>
          )}
        </TitleWithIcon>

        {(!onlyThisDay || !activeShift?.shift?.regular) &&
          activeShift &&
          type !== 'closed' && (
            <TitleWithIcon
              title="Weekly or One-time"
              descriptionTranslationKey="calendar_weeklyBody"
              description="Wann und wie oft wiederholt sich diese Schicht. Ist sie einmalig oder wöchentlich?"
              icon={(p) => <Today {...p} />}
            >
              <Select
                name="regular"
                options={[
                  {
                    id: 'once',
                    label: 'One-time',
                    translation: 'calendar',
                  },
                  {
                    id: 'weekly',
                    label: 'Weekly',
                    translation: 'calendar',
                  },
                ]}
                style={{ width: 160, marginBottom: 12 }}
                value={activeShift.shift.regular ? 'weekly' : 'once'}
                onChange={(e) => {
                  let regular = e.target.value === 'weekly';

                  if (
                    !regular &&
                    activeShift.shift.regular &&
                    activeShift.allUpcomingShifts &&
                    !newShift
                  ) {
                    alert({
                      title: 'Nicht möglich',
                      description:
                        'Eine Wiederholte Schicht kann nicht in eine einmalige Schicht umgewandelt werden.',
                      onSubmit: () => {},
                      actions: [
                        {
                          id: 'ok',
                          label: 'Ok',
                          variant: 'primary',
                        },
                      ],
                    });

                    return;
                  }

                  // console.log('TESTING', activeShift);

                  setactiveShift((s) =>
                    !!s
                      ? {
                          ...s,
                          shift: {
                            ...s?.shift,
                            regular,
                            ...(!regular
                              ? {
                                  weekDays: undefined,
                                  dates: [
                                    s.shift.startAt || s.date || dateHelper(),
                                  ],
                                  startAt:
                                    s.shift.startAt || s.date || dateHelper(),
                                  endAt: undefined,
                                }
                              : {
                                  startAt: dateHelper(),
                                  weekDays: [0, 1, 2, 3, 4, 5, 6],
                                  dates: undefined,
                                }),
                          },
                        }
                      : null
                  );
                }}
              />

              {!!activeShift.shift.regular ? (
                <>
                  <WeekDays
                    weekDays={activeShift.shift.weekDays || []}
                    onChange={(weekDays) =>
                      setactiveShift((aS) =>
                        !!aS
                          ? { ...aS, shift: { ...aS.shift, weekDays } }
                          : null
                      )
                    }
                  />
                  <Box>
                    <Typography
                      variant="text-4"
                      component="label"
                      style={{ display: 'block', marginBottom: 2 }}
                      color="subdued"
                    >
                      Choose a start and end date
                    </Typography>
                    <Select
                      name="regular"
                      options={[
                        {
                          id: 'direct',
                          label: `Ab dem ${toEuropeanDate(
                            activeShift.shift.startAt ?? dateHelper()
                          )} ohne Enddatum`,
                          translation: 'calendar',
                        },
                        {
                          id: 'startAndEndDate',
                          label: 'Date Range',
                          translation: 'calendar',
                        },
                      ]}
                      value={withEndDate ? 'startAndEndDate' : 'direct'}
                      style={{ marginBottom: 12 }}
                      onChange={(e) => {
                        setwithEndDate(e.target.value === 'startAndEndDate');
                        if (e.target.value === 'direct') {
                          setactiveShift((aS) =>
                            !!aS
                              ? {
                                  ...aS,
                                  shift: {
                                    ...aS.shift,
                                    endAt: null,
                                    startAt: aS.shift.startAt ?? dateHelper(),
                                  },
                                }
                              : null
                          );
                        }
                      }}
                    />
                    {!!withEndDate && (
                      <DateRange
                        state={activeShift.shift}
                        onChange={(state: any) =>
                          setactiveShift((aS) =>
                            !!aS
                              ? {
                                  ...aS,
                                  shift: { ...aS.shift, ...state },
                                  date: state.startAt,
                                }
                              : null
                          )
                        }
                      />
                    )}
                  </Box>
                </>
              ) : (
                <DateRange
                  onlyOneDay
                  state={activeShift.shift}
                  date={activeShift.date}
                  onChange={(state: any) =>
                    setactiveShift((aS) =>
                      !!aS ? { ...aS, shift: { ...aS.shift, ...state } } : null
                    )
                  }
                />
              )}
            </TitleWithIcon>
          )}

        {activeShift && type === 'closed' && (
          <TitleWithIcon
            title="One or multiple Days"
            descriptionTranslationKey="calendar_multipleBody"
            description="Soll dein Restaurant einen oder mehrere Tage geschlossen sein?"
            icon={(p) => <Today {...p} />}
          >
            <Select
              name="regular"
              options={[
                {
                  id: 'one',
                  label: 'One Day',
                  translation: 'calendar',
                },
                {
                  id: 'multiple',
                  label: 'Multiple Days',
                  translation: 'calendar',
                },
              ]}
              style={{ width: 160, marginBottom: 12 }}
              value={activeShift.shift.regular ? 'multiple' : 'one'}
              onChange={(e) => {
                let regular = e.target.value === 'multiple';

                console.log({
                  onlyThisDay,
                  regular,
                  x: !activeShift?.shift?.regular,
                  type,
                });

                setactiveShift((s) =>
                  !!s
                    ? {
                        ...s,
                        shift: {
                          ...s?.shift,
                          regular,
                          ...(regular
                            ? { weekDays: [0, 1, 2, 3, 4, 5, 6] }
                            : { weekDays: undefined }),
                        },
                      }
                    : null
                );
              }}
            />

            {!!activeShift.shift.regular ? (
              <>
                <WeekDays
                  weekDays={activeShift.shift.weekDays || []}
                  onChange={(weekDays) =>
                    setactiveShift((aS) =>
                      !!aS ? { ...aS, shift: { ...aS.shift, weekDays } } : null
                    )
                  }
                />
                <Box>
                  <DateRange
                    state={activeShift.shift}
                    onChange={(state) =>
                      setactiveShift((aS) =>
                        !!aS
                          ? { ...aS, shift: { ...aS.shift, ...state } }
                          : null
                      )
                    }
                  />
                </Box>
              </>
            ) : (
              <DateRange
                onlyOneDay
                state={activeShift.shift}
                date={activeShift.date}
                onChange={(state: any) =>
                  setactiveShift((aS) =>
                    !!aS ? { ...aS, shift: { ...aS.shift, ...state } } : null
                  )
                }
              />
            )}
          </TitleWithIcon>
        )}

        <TitleWithIcon
          title="Time frame"
          descriptionTranslationKey="calendar_timeFrameBody"
          description="Soll diese Schicht sofort starten oder an einem bestimmten Datum Anfangen oder Enden?"
          icon={(p) => <HourglassEmpty {...p} />}
          hide={activeShift?.type === 'close'}
        >
          {type !== 'open' && (
            <Select
              name="regular"
              options={[
                {
                  id: 'timeWindow',
                  label: 'Time Window',
                  translation: 'calendar',
                },
                {
                  id: 'allDay',
                  label: 'All Day',
                  translation: 'calendar',
                },
              ]}
              style={{ width: 160, marginBottom: 12 }}
              value={!!activeShift?.shift?.allDay ? 'allDay' : 'timeWindow'}
              onChange={(e) => {
                let allDay = e.target.value === 'allDay';

                setactiveShift((s) =>
                  !!s
                    ? {
                        ...s,
                        shift: {
                          ...s?.shift,
                          allDay,
                          ...(allDay
                            ? { start: 0, close: 96, service: 96 }
                            : { start: 72, close: 96, service: 88 }),
                        },
                      }
                    : null
                );
              }}
            />
          )}

          {!activeShift?.shift?.allDay && (
            <>
              <Typography
                variant="text-4"
                color="subdued"
                style={{ marginBottom: 4 }}
                block
                translation="calendar"
              >
                Start of Shift
              </Typography>
              <Box background outline elevation={0} className="mg-bt-md">
                <Select
                  name="start"
                  options={TIMES.filter((t) => t.value < 96)}
                  value={activeShift?.shift.start}
                  onChange={handleTimeChange}
                />
              </Box>
              {!activeShift?.shift?.commentOnly && !activeShift?.shift?.closed && (
                <>
                  <Typography
                    variant="text-4"
                    color="subdued"
                    style={{ marginBottom: 4 }}
                    block
                    translation="calendar"
                  >
                    Last Reservation
                  </Typography>
                  <Box background outline elevation={0} className="mg-bt-md">
                    <Select
                      name="service"
                      options={TIMES.filter(
                        (t) => t.value > (activeShift?.shift?.start ?? 0)
                      )}
                      value={activeShift?.shift?.service}
                      onChange={handleTimeChange}
                    />
                  </Box>
                </>
              )}
              <Typography
                variant="text-4"
                color="subdued"
                style={{ marginBottom: 4 }}
                block
                translation="calendar"
              >
                End of Shift
              </Typography>
              <Box
                background
                outline
                elevation={0}
                className={!!hasTimeError() ? 'mg-bt-sm' : 'mg-bt-md'}
              >
                <Select
                  name="close"
                  options={TIMES.filter(
                    (t) => t.value > (activeShift?.shift?.start ?? 0)
                  )}
                  value={activeShift?.shift?.close}
                  onChange={handleTimeChange}
                />
              </Box>
            </>
          )}
        </TitleWithIcon>

        <TitleWithIcon
          title="For which occasions/spaces does this shift apply?"
          descriptionTranslationKey="calendar_occassionSpaceBody"
          description="Wähle aus Anlässen und Bereichen auf die diese Regeln zutreffen sollen."
          icon={(p) => <PlaylistAddCheck {...p} />}
          hide={type === 'note'}
        >
          <Box flex>
            <Box style={{ marginRight: 4, flex: 1 }}>
              <Typography
                variant="text-4"
                color="subdued"
                style={{ marginBottom: 4 }}
                block
              >
                Floor Plan
              </Typography>
              <Box
                background
                outline
                elevation={0}
                className={`mg-bt-md ${
                  light && 'not-available na-paper na-secondary'
                }`}
                style={{ maxWidth: smaller ? 155 : 215 }}
                dataLabel="Only with Tableplan"
                translation="reservations"
              >
                <Select
                  name="floorPlan"
                  options={floorPlans ?? []}
                  value={currentFloorPlan?.id ?? null}
                  onChange={handleFloorPlanChange}
                />
              </Box>
            </Box>
            <Box style={{ flex: 1 }}>
              <Typography
                variant="text-4"
                color="subdued"
                style={{ marginBottom: 4 }}
                block
              >
                Indoor / Outdoor
              </Typography>
              <Box
                background
                outline
                elevation={0}
                style={{ maxWidth: smaller ? 180 : 230 }}
                className={`mg-bt-md ${
                  light && 'not-available na-paper na-secondary'
                }`}
                dataLabel="Only with Tableplan"
                translation="reservations"
              >
                <Select
                  name="placeAt"
                  options={PLACE_AT_OPTIONS}
                  value={String(
                    activeShift?.shift?.additional?.placeAt ?? null
                  )}
                  onChange={handlePlaceAtChange}
                />
              </Box>
            </Box>
          </Box>

          <Typography
            variant="text-4"
            color="subdued"
            style={{ marginBottom: 4 }}
            block
          >
            Occassions and Spaces
          </Typography>
          <OccassionsAndSpaces
            light={light}
            occassions={settings?.occassions ?? []}
            spaces={
              currentFloorPlan?.spaces?.map((s) => ({
                id: s.id,
                title: s.name,
              })) ?? []
            }
            state={{
              spaces: activeShift?.shift.spaces || [],
              occassions: activeShift?.shift.occassions || [],
              matchBoth: activeShift?.shift.matchBoth || false,
            }}
            style={{
              marginBottom: 12,
            }}
            onChange={(state: any) =>
              setactiveShift((aS) =>
                !!aS ? { ...aS, shift: { ...aS.shift, ...state } } : null
              )
            }
          />
          {!!tablesMatching && (
            <TablesMatching
              matchingTables={tablesMatching}
              closed={type === 'closed'}
            />
          )}
        </TitleWithIcon>

        <TitleWithIcon
          title="Ticket Sales"
          descriptionTranslationKey="calendar_ticketSalesBody"
          description="Willst du Tickets verkaufen über diese Schicht? Du kannst Vergangene Veranstaltungen als Ausgangspunkt benutzen oder eine neue Veranstaltung erstellen"
          icon={(p) => <ConfirmationNumber {...p} />}
          hide={type !== 'open'}
        >
          {!!settings && !!activeShift && (
            <EventSettings
              {...{ activeShift, setactiveShift, settings }}
              designV02
              eventOnly={activeShift?.eventOnly}
              handleSubmit={handleSubmit}
            />
          )}
        </TitleWithIcon>

        <TitleWithIcon
          title="Availability and Reservation Length"
          descriptionTranslationKey="calendar_availabilityBody"
          description="Wähle aus wann gäste kommen und wie lange Sie bleiben dürfen."
          icon={(p) => <Alarm {...p} />}
          hide={type !== 'open'}
        >
          {!!activeShift && !!settings && (
            <DetailedShiftSettings
              {...{ activeShift, setactiveShift, settings }}
            />
          )}
        </TitleWithIcon>

        <TitleWithIcon
          title="To Add / Edit a Note"
          descriptionTranslationKey="calendar_noteExplainer"
          description="Um eine Notiz hinzuzufügen oder zu editieren. Speicher die Änderungen und wähle die Schicht aus. Dort kannst du nun einen Kommentar hinterlassen"
          icon={(p) => <Comment {...p} />}
          hide={type !== 'note'}
        ></TitleWithIcon>

        <TitleWithIcon
          title="Message for Guests"
          descriptionTranslation="calendar"
          description="Show an additional message when a guest catches this shift"
          icon={(p) => <Comment {...p} />}
          hide={type !== 'closed'}
        >
          <Box
            flex
            style={{ flexWrap: 'wrap' }}
            padding={0}
            className="mg-bt-md"
          >
            <Button
              variant={language === 'de' ? 'primary' : 'default'}
              onClick={() => setlanguage('de')}
              style={{ marginRight: 8, marginBottom: 4 }}
            >
              German
            </Button>
            <Button
              variant={language === 'en' ? 'primary' : 'default'}
              onClick={() => setlanguage('en')}
              style={{ marginBottom: 4 }}
            >
              English
            </Button>
          </Box>

          <TextField
            name="message"
            label="Message for Guests"
            labelTranslation="calendar"
            value={activeShift?.shift.message?.[language] || ''}
            multiline
            onChange={handleMessageChange}
            rows={3}
            fullWidth
          />
        </TitleWithIcon>

        <TitleWithIcon
          title="Minimum time difference to shift"
          titleTranslation="calendar"
          descriptionTranslation="calendar"
          description="From this point on, no new reservations will be accepted for the selected day."
          icon={(p) => <HourglassFull {...p} />}
          hide={type === 'closed'}
        >
          <SwitchBox
            checked={!!activeShift?.shift?.lastReservableTime}
            onChange={() => {
              if (!!activeShift?.shift?.lastReservableTime) {
                setactiveShift((aS) =>
                  !!aS
                    ? {
                        ...aS,
                        shift: { ...aS.shift, lastReservableTime: null },
                      }
                    : null
                );
              } else {
                setactiveShift((aS) =>
                  !!aS
                    ? {
                        ...aS,
                        shift: {
                          ...aS.shift,
                          lastReservableTime: { time: '00:00' },
                        },
                      }
                    : null
                );
              }
            }}
            label="Last Reservable Time"
            translation="calendar"
          />

          {!!activeShift?.shift?.lastReservableTime && (
            <>
              <Box>
                <Typography
                  variant="text-4"
                  color="subdued"
                  style={{ marginBottom: 4 }}
                  block
                  translation="calendar"
                >
                  Days in Advance
                </Typography>
                <Box background outline elevation={0} className="mg-bt-md">
                  <Select
                    name="days"
                    options={[
                      {
                        id: '0',
                        label: 'Same Day',
                        translation: 'calendar',
                      },
                      {
                        id: '1',
                        label: 'One Day in Advance',
                        translation: 'calendar',
                      },
                      {
                        id: '2',
                        label: '2 Days in Advance',
                        translation: 'calendar',
                      },
                      {
                        id: '3',
                        label: '3 Days in Advance',
                        translation: 'calendar',
                      },
                      {
                        id: '4',
                        label: '4 Days in Advance',
                        translation: 'calendar',
                      },
                      {
                        id: '5',
                        label: '5 Days in Advance',
                        translation: 'calendar',
                      },
                      {
                        id: '6',
                        label: '6 Days in Advance',
                        translation: 'calendar',
                      },
                      {
                        id: '7',
                        label: '7 Days in Advance',
                        translation: 'calendar',
                      },
                    ]}
                    value={activeShift?.shift?.lastReservableTime?.days ?? 0}
                    onChange={(e) =>
                      setactiveShift((aS) =>
                        !!aS
                          ? {
                              ...aS,
                              shift: {
                                ...aS.shift,
                                lastReservableTime: aS.shift.lastReservableTime
                                  ? {
                                      ...aS.shift.lastReservableTime,
                                      days: parseInt(e.target.value as any),
                                    }
                                  : null,
                              },
                            }
                          : null
                      )
                    }
                  />
                </Box>
              </Box>
              <Box>
                <Typography
                  variant="text-4"
                  color="subdued"
                  style={{ marginBottom: 4 }}
                  block
                  translation="calendar"
                >
                  Time
                </Typography>
                <Box background outline elevation={0} className="mg-bt-md">
                  <Select
                    name="time"
                    options={Array.from(new Array(24), (_, i) => i).map((x) =>
                      blockToTime(x * 4)
                    )}
                    value={
                      activeShift?.shift?.lastReservableTime?.time ?? '00:00'
                    }
                    onChange={(e) =>
                      setactiveShift((aS) =>
                        !!aS
                          ? {
                              ...aS,
                              shift: {
                                ...aS.shift,
                                lastReservableTime: aS.shift.lastReservableTime
                                  ? {
                                      ...aS.shift.lastReservableTime,
                                      time: e.target.value as any,
                                    }
                                  : null,
                              },
                            }
                          : null
                      )
                    }
                  />
                </Box>
              </Box>
            </>
          )}
        </TitleWithIcon>

        {type === 'open' && (
          <AdditionalSettings>
            {/* {apps.includes('digital_menu') && (
              <Box style={{ marginBottom: 12 }}>
                <Typography
                  variant="h6"
                  style={{
                    marginBottom: 2,
                    fontSize: '14px',
                    lineHeight: '120%',
                  }}
                  translation="calendar"
                >
                  Standard Menu
                </Typography>
                <Typography
                  variant="text-3"
                  component="p"
                  color="subdued"
                  style={{ marginBottom: 10 }}
                  translation="calendar"
                >
                  
                </Typography>

                <Select
                  name="menu"
                  options={menues ?? []}
                  value={currMenu}
                  onChange={handleMenuChange}
                />
              </Box>
            )} */}

            <Box style={{ marginBottom: 12 }}>
              <Typography
                variant="h6"
                style={{
                  marginBottom: 2,
                  fontSize: '14px',
                  lineHeight: '120%',
                }}
                translation="calendar"
              >
                Message for Guests
              </Typography>
              <Typography
                variant="text-3"
                color="subdued"
                component="p"
                style={{ marginBottom: 10 }}
                translation="calendar"
              >
                Show an additional message when a guest catches this shift
              </Typography>

              <Box
                flex
                style={{ flexWrap: 'wrap' }}
                padding={0}
                className="mg-bt-md"
              >
                <Button
                  variant={language === 'de' ? 'primary' : 'default'}
                  onClick={() => setlanguage('de')}
                  style={{ marginRight: 8, marginBottom: 4 }}
                >
                  German
                </Button>
                <Button
                  variant={language === 'en' ? 'primary' : 'default'}
                  onClick={() => setlanguage('en')}
                  style={{ marginBottom: 4 }}
                >
                  English
                </Button>
              </Box>

              <TextField
                name="message"
                label="Message for Guests"
                labelTranslation="calendar"
                value={activeShift?.shift.message?.[language] || ''}
                multiline
                onChange={handleMessageChange}
                rows={3}
                fullWidth
              />
            </Box>

            {type === 'open' && newShift && false && (
              <Box style={{ marginBottom: 12 }}>
                <Typography
                  variant="h6"
                  style={{
                    marginBottom: 2,
                    fontSize: '14px',
                    lineHeight: '120%',
                  }}
                  translation="calendar"
                >
                  Block Reservations by Default (Use with Care)
                </Typography>
                <Typography
                  variant="text-3"
                  component="p"
                  color="subdued"
                  style={{ marginBottom: 10 }}
                  translation="calendar"
                >
                  This setting allows you to have a shift that is by default
                  blocked for Reservations from outside but can be turned on
                  when needed.
                </Typography>

                <Select
                  name="blockReservations"
                  options={[
                    {
                      id: 'open',
                      label: 'Open for Reservations',
                      translation: 'calendar',
                    },
                    {
                      id: 'blocked',
                      label: 'Blocked for Reservations',
                      translation: 'calendar',
                    },
                  ]}
                  value={
                    activeShift?.shift?.overwrite?.blockReservations
                      ? 'blocked'
                      : 'open'
                  }
                  onChange={(e) => {
                    let blockReservations = e.target.value === 'blocked';

                    setactiveShift((s) =>
                      !!s
                        ? {
                            ...s,
                            shift: {
                              ...(s?.shift ?? {}),
                              overwrite: {
                                ...(s?.shift?.overwrite ?? {}),
                                blockReservations,
                              },
                            },
                          }
                        : null
                    );
                  }}
                />
              </Box>
            )}
          </AdditionalSettings>
        )}
      </Sidebar>
    </Box>
  );
};

export default NewEditReservationShift;
