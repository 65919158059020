import React from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import useDocument from 'CustomHooks/useDocument';
import { MollieCredentialsDocument } from 'gastronaut-shared/types/documents/restaurants/credentials';
import { Error } from '@material-ui/icons';
import Typography from 'Components/Atoms/Typography';
import useRestaurant from 'CustomHooks/useRestaurant';
import { FieldValue, db } from 'utils/firebase';
import Mollie from 'App/Apps/Components/Settings/Components/Mollie';
import useAuth from 'CustomHooks/useAuth';
import useCollection from 'CustomHooks/useCollection';

export type MollieProps = {
  restaurantId: string;
  hasActiveAddOn?: boolean;
};

const MissingMollie: React.FC<MollieProps> = ({
  restaurantId,
  hasActiveAddOn,
}) => {
  const { products } = useRestaurant();
  const { isAdmin } = useAuth();

  const [addOns] = useCollection(
    !hasActiveAddOn === undefined ? `/restaurants/${restaurantId}/addOns` : '',
    { limit: 1, filter: [['active', '==', true]] }
  );

  const hasAddOns = hasActiveAddOn ?? addOns?.data?.length > 0;

  const [credentials] = useDocument<MollieCredentialsDocument>(
    `restaurants/${restaurantId}/credentials`,
    'mollie'
  );
  const noCredentials = !credentials?.data && !credentials.loading;

  const canReceivePayments = !!credentials?.data?.canReceivePayments;

  if (
    credentials.loading ||
    credentials.error ||
    canReceivePayments ||
    !hasAddOns
  )
    return <></>;

  return (
    <Box
      className={hasActiveAddOn ? 'missing-mollie' : 'missing-mollie smaller'}
    >
      <Error htmlColor="inherit" />
      <Box>
        <Typography variant="h4" className="mg-bt-sm">
          {noCredentials
            ? 'Mollie wurde noch nicht verbunden.'
            : 'Mollie fehlen Informationen'}
        </Typography>
        <Typography variant="text-2" className="mg-bt-md" component="p">
          {noCredentials
            ? 'Um Add Ons anzubieten muss Mollie verbunden werden. Dies dauert nur wenige Minuten. Start durch klick auf den Button.'
            : 'Aktuell können keine Add Ons verkauft werden.  Da einige Informationen fehlen bei Mollie fehlen.'}
        </Typography>
        {!!isAdmin && (
          <Mollie buttonVariant="outlined" restaurantId={restaurantId} />
        )}
      </Box>
    </Box>
  );
};

export const useMissingMollie = (
  hasActiveAddOn: boolean,
  restaurantId: string
) => {
  const { apps, products } = useRestaurant();
  React.useEffect(() => {
    if (
      hasActiveAddOn &&
      restaurantId &&
      (!apps.includes('mollie') || !products.includes('addOns'))
    ) {
      db.collection('restaurantData')
        .doc(restaurantId)
        .update({
          apps: FieldValue.arrayUnion('mollie'),
          productsOwned: FieldValue.arrayUnion('addOns'),
        });
    }

    if (hasActiveAddOn) {
      window.localStorage.setItem(
        'show-richadts-addons-1',
        JSON.stringify({ value: Date.now() * 2 })
      );
    }
  }, [apps, hasActiveAddOn, products]);
};

export default MissingMollie;
