import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

const UserIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" fill="#6369D1" />
      <path
        d="M27.3333 8.00008C23.8533 8.93341 19.7733 9.33342 16 9.33342C12.2267 9.33342 8.14667 8.93341 4.66667 8.00008L4 10.6667C6.48 11.3334 9.33333 11.7734 12 12.0001V29.3334H14.6667V21.3334H17.3333V29.3334H20V12.0001C22.6667 11.7734 25.52 11.3334 28 10.6667L27.3333 8.00008ZM16 8.00008C17.4667 8.00008 18.6667 6.80008 18.6667 5.33341C18.6667 3.86675 17.4667 2.66675 16 2.66675C14.5333 2.66675 13.3333 3.86675 13.3333 5.33341C13.3333 6.80008 14.5333 8.00008 16 8.00008Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default UserIcon;
