import React, { useMemo } from "react";
import Box from "Components/Atoms/Box";
import "./styles.scss";
import { noop } from "utils/helper";
import IconDropdown from "Components/Molecules/IconDropdown";
import { ArrowDropDown } from "Components/Atoms/Icons";
import Typography from "Components/Atoms/Typography";
import GastronautIcon from "Components/Atoms/GastronautIcon";
import DropdownOptions from "./Components/DropdownOptions";
import { Restaurant } from "utils/types";
import useRestaurant from "CustomHooks/useRestaurant";
import { useMediaQuery } from "@material-ui/core";

// export type RightsPerRestaurant = {
//   admin: boolean;
//   id: string;
//   title: string;
//   role: string;
// };

export type SelectRestaurantProps = {
  restaurantId: string | null;
  restaurants: Restaurant[];
  onSettingsClick: (restaurantId: string) => void;
  onRestaurantChange: (restaurantId: string) => void;
};

const SelectRestaurant = ({
  restaurantId,
  restaurants = [],
  onSettingsClick = noop,
  onRestaurantChange = noop,
}: SelectRestaurantProps) => {
  const isMobile = useMediaQuery("(max-width:450px)");

  const { restaurantName } = useRestaurant();

  const selectedRestaurant = useMemo(
    () => restaurants.find((r) => r.id === restaurantId),
    [restaurants, restaurantId]
  );

  // console.log(selectedRestaurant, restaurants, restaurantId);

  const multipleRestaurants = restaurants.length > 1;

  const restaurantTitle = selectedRestaurant?.title || restaurantName || "";

  const titleVariant = restaurantTitle.length < 19 ? "h3" : "h4";

  return (
    <Box className="select-restaurant">
      <Box flex>
        <GastronautIcon size={48} />
        <Typography style={{ marginLeft: 16, minWidth: 210 }} variant={titleVariant}>
          {restaurantTitle}
        </Typography>
      </Box>
      {multipleRestaurants && !isMobile && (
        <IconDropdown
          width="300px"
          top="12px"
          left="-278px"
          className="dropdown-items"
          size="medium"
          noArrow
          icon={<ArrowDropDown />}
          children={
            <DropdownOptions
              activeOption={selectedRestaurant?.id}
              restaurants={restaurants}
              goToSettings={onSettingsClick}
              switchRestaurant={onRestaurantChange}
            />
          }
        />
      )}
    </Box>
  );
};

export default SelectRestaurant;
