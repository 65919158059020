import { Category, Highlight, Meal, SubCategory } from 'types/menu';
import { dateHelper, timeHelper } from 'utils/helper';
import server from '../index';

export default async (restaurantId:string, orderTime:string) => {
    try {

        let date:string = dateHelper();
        let time:string = timeHelper();

        if(orderTime !== 'now') {
            let [d, t] = orderTime.split('T');
            date = d;
            time = t;
        }

        const response = await server.get(`/v02/menues/takeAway/${restaurantId}/takeAwayAvailability/${date}/${time}`);

        const data:{ deliveryMethods:('delivery'|'pickup')[], meals:Meal[], categories:Category[], subCategories:SubCategory[], highlights:Highlight[] } = response.data;
        
        return data;

    } catch (error:any) {
        if(error.isAxiosError) {
            throw new Error(error?.response?.data?.message || error.message);
        } else {
            throw error
        }
    }
}