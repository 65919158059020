import React from "react";
import { noop } from "utils/helper";
import MenuItem from "Components/Atoms/MenuItem";

export type SettingsDropdownLanguagesProps = {
  language: string;
  onLanguageChange: (lang: string) => void;
  languages: { id: string; label: string }[];
};

const SettingsDropdownLanguages = ({
  language,
  languages,
  onLanguageChange = noop,
}: SettingsDropdownLanguagesProps) => {
  return (
    <>
      {languages.map((l) => (
        <MenuItem
          key={l.id}
          selected={language === l.id}
          onClick={() => onLanguageChange(l.id)}
        >
          {l.label}
        </MenuItem>
      ))}
    </>
  );
};

export default SettingsDropdownLanguages;
