import React, { useMemo, useState } from "react";
import "./styles.scss";
import Box from "Components/Atoms/Box";
import IconDropdown from "Components/Molecules/IconDropdown";
import { ArrowDropDown } from "Components/Atoms/Icons";
import Typography from "Components/Atoms/Typography";
import GastronautIcon from "Components/Atoms/GastronautIcon";
import useRestaurant from "CustomHooks/useRestaurant";
import MobileModal from "App/Mobile/Components/MobileModal";
import DropdownOptions from "App/Dashboard/Components/SelectRestaurant/Components/DropdownOptions";
import { Restaurant } from "utils/types";
import { noop } from "utils/helper";

export type MobileSelectRestaurantProps = {
  restaurantId: string | null;
  restaurants: Restaurant[];
  onSettingsClick: (restaurantId: string) => void;
  onRestaurantChange: (restaurantId: string) => void;
};

const MobileSelectRestaurant = ({
  restaurantId,
  restaurants = [],
  onSettingsClick = noop,
  onRestaurantChange = noop,
}: MobileSelectRestaurantProps) => {
  const [isMobileModalOpen, setIsMobileModalOpen] = useState<boolean>(false);
  const { restaurantName } = useRestaurant();

  const selectedRestaurant = useMemo(
    () => restaurants.find((r) => r.id === restaurantId),
    [restaurants, restaurantId]
  );

  const multipleRestaurants = restaurants.length > 1;
  const restaurantTitle = selectedRestaurant?.title || restaurantName || "";

  return (
    <Box className="mobile-select-restaurant">
      <Box flex>
        <GastronautIcon size={24} />
        <Typography
          style={{
            marginLeft: 8,
            maxWidth: "180px",
            // maxHeight: "32px",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
          variant="h4"
        >
          {restaurantTitle}
        </Typography>
      </Box>
      {multipleRestaurants && (
        <IconDropdown
          width="300px"
          top="12px"
          left="-278px"
          size="small"
          noArrow
          icon={<ArrowDropDown onClick={() => setIsMobileModalOpen(true)} />}
          children={<div />}
        />
      )}
      <MobileModal
        isOpen={isMobileModalOpen}
        onClose={() => setIsMobileModalOpen(false)}
        title="Settings"
        titleTranslation="settings"
      >
        <DropdownOptions
          activeOption={selectedRestaurant?.id}
          restaurants={restaurants}
          goToSettings={onSettingsClick}
          switchRestaurant={onRestaurantChange}
        />
      </MobileModal>
    </Box>
  );
};

export default MobileSelectRestaurant;
