let colors = {
  primary: "#10ccb9",
  onPrimary: "#011311",
  secondary: "#6369d1",
  onSecondary: "#ffffff",
  error: "#CC1F1B",
  success: "#49b800",
  dark: {
    text: "#ffffff",
    paper: "#1a202c",
    bg: "#011311",
    disabled: "#718096",
    subdued: "#a0aec0",
    border: "#4a5568",
  },
  light: {
    text: "#011311",
    paper: "#ffffff",
    bg: "#f7fafc",
    disabled: "#a0aec0",
    subdued: "#718096",
    border: "#e2e8f0",
  },
};

export default colors