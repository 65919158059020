import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { db } from 'utils/firebase';
import { noop } from '../utils/helper';
import useCollection from 'CustomHooks/useCollection';
import { Ticket } from 'gastronaut-shared/types/documents/restaurants/event';
import { ReservationContext } from './ReservationContext';
import { Reservation } from 'gastronaut-shared/types/helper/reservations';

interface ContextProps {
  restaurantId: null | string;
  children?: React.ReactNode;
  storyBook?: boolean;
}

type TicketContextType = {
  tickets: (Ticket & { reservation: Reservation | null })[];
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  sort: string;
  setSort: Dispatch<SetStateAction<Tab>>;
  isLoading: boolean;
  error: string | null;
  lastTickets: null | {
    ref: any;
    data: Ticket[];
    loading: boolean;
    error: string | null;
  };
};

type Tab = 'All' | 'Successful' | 'Failed';

export const TicketContext = createContext<TicketContextType>({
  tickets: [],
  search: '',
  setSearch: noop,
  sort: 'all',
  setSort: noop,
  isLoading: false,
  error: null,
  lastTickets: null,
});

async function loadAssociatedReservations(
  reservations: Record<string, Reservation | null>,
  tickets: Ticket[]
) {
  try {
    let reservationIds = Object.keys(reservations);

    let promises = await Promise.all(
      tickets.map(async (t) => {
        if (!t.reservation) return null;

        if (reservationIds.includes(t.reservation)) {
          return reservations[t.reservation];
        }

        let doc = await db.collection(`requestsV03`).doc(t.reservation).get();

        if (!doc.exists) return null;

        return doc.data() as Reservation;
      })
    );

    let obj: Record<string, Reservation | null> = {};

    for (let index = 0; index < tickets.length; index++) {
      const data = promises[index];
      const id = tickets?.[index].id || '';

      obj[id] = data;
    }

    return obj;
  } catch (e) {
    console.error(e);
    return {};
  }
}

const TicketContextProvider = ({
  restaurantId,
  children,
  storyBook,
}: ContextProps) => {
  const { date, tickets } = useContext(ReservationContext);
  const [sort, setSort] = useState<Tab>('All');
  const [search, setSearch] = useState<string>('');

  const [lastTickets] = useCollection<Ticket>(`ticketsV02`, {
    filter: [['restaurant', '==', restaurantId]],
    limit: 3,
    sort: ['createdAt', 'desc'],
  });

  const [reservations, setreservations] = useState<
    Record<string, Reservation | null>
  >({});

  useEffect(() => {
    if (tickets.data) {
      loadAssociatedReservations(reservations, tickets.data).then((obj) =>
        setreservations(obj)
      );
    } else {
      setreservations({});
    }
  }, [tickets.data, loadAssociatedReservations, setreservations]);

  const filteredTickets = useMemo(() => {
    const richTickets = tickets.data.map(
      (t) =>
        ({ ...t, reservation: reservations[t.id ?? ''] } as Ticket & {
          reservation: Reservation | null;
        })
    );

    if (search) {
      let smallerCaseSearch = search.toLocaleLowerCase();
      return richTickets.filter(
        (r) =>
          r.customId.toLocaleLowerCase().includes(smallerCaseSearch) ||
          r.reservation?.guest?.name
            .toLocaleLowerCase()
            .includes(smallerCaseSearch)
      );
    } else if (sort === 'All') {
      return richTickets;
    } else if (sort === 'Successful') {
      return richTickets.filter(
        (ticket) =>
          ticket.status === 'succedded' || ticket.status === 'processing'
      );
    } else if (sort === 'Failed') {
      return richTickets.filter(
        (ticket) =>
          ticket.status === 'failed' || ticket.status === 'missing_payments'
      );
    }
    return [];
  }, [tickets, sort, reservations, search]);

  // console.log('ticketsV02: ', tickets);
  // console.log("events:", events);

  return (
    <TicketContext.Provider
      value={{
        tickets: filteredTickets,
        isLoading: tickets.loading,
        error: tickets.error,
        search,
        setSearch,
        sort,
        setSort,
        lastTickets: lastTickets,
      }}
    >
      {children}
    </TicketContext.Provider>
  );
};

export default TicketContextProvider;
