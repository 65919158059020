import { SvgIcon, SvgIconProps } from "@material-ui/core";
import { ThemeContext } from "Contexts/ThemeContext";
import React, { useContext } from "react";

const EmptyStateIcon = (props: SvgIconProps) => {
  const { darkMode } = useContext(ThemeContext);

  const color1 = darkMode ? "#1A202C" : "#F7FAFC";
  const color2 = darkMode ? "#718096" : "#E2E8F0";
  const color3 = darkMode ? "#4A5568" : "#CBD5E0";

  return (
    <SvgIcon
      width="216"
      height="72"
      viewBox="0 0 216 72"
      fill="none"
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="215"
        height="71"
        rx="2.5"
        fill={color1}
        stroke={color2}
      />
      <rect x="24" y="16" width="67" height="12" rx="6" fill={color2} />
      <rect x="24" y="44" width="168" height="12" rx="6" fill={color3} />
    </SvgIcon>
  );
};

export default EmptyStateIcon;
