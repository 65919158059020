import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Reservation, Guest, ReservationStatus } from 'types/reservations';
import { asyncVoid, noop } from 'utils/helper';
import TabCard from 'Components/Molecules/TabCard';
import TextField from 'Components/Atoms/TextField';
import Status from './components/Status';
import SimpleTable, { Column } from 'Components/Molecules/SimpleTable';
import useGuestEntry from './components/useGuestEntry';
import Contact from './components/Contact';
import { useHistory } from 'react-router';
import useIsLightReservations from 'CustomHooks/useIsLightReservations';
import FeedbackTable from 'App/Feedback/Components/FeedbackTable';
import useCollection from 'CustomHooks/useCollection';
import Typography from 'Components/Atoms/Typography';
import Box from 'Components/Atoms/Box';
import useAlert from 'CustomHooks/useAlert';
import { FixedTable } from '../FixedTableModal';

export type GuestEntryProps = {
  data: Guest;
  restaurantId: string;
  onBlur: (
    id: string,
    key: string,
    newValue: string | string[] | FixedTable
  ) => void;
  reservations: Reservation[];
  otherReservations: Reservation[];
  handleDelete: (guestId: string) => Promise<void>;
  transferReservation: (id: string) => Promise<void>;
};

const reservation_keys_to_keep = ['date', 'time', 'guests', 'status'];

const stringifyDate = (date: string) => {
  let [yyyy, mm, dd] = date?.split('-');

  return `${dd}.${mm}.${yyyy}`;
};

const toCurrency = (num?: number) =>
  !!num ? num.toFixed(2).replace('.', ',') + '€' : '-';

const toStatus = (status: ReservationStatus, done = false, noShow = false) => {
  if (noShow) {
    return 'No Show';
  } else if (status === ReservationStatus.FAILED) {
    return 'Storniert';
  } else if (!done) {
    return 'Anstehend';
  } else {
    return 'Erfolgreich';
  }
};

const formattedReservations = (array: Reservation[]) =>
  array.map((r) => ({
    id: r.id,
    dateStr: stringifyDate(r.date),
    name: r.guest.name,
    date: r.date,
    time: r.time,
    guests: r.guests,
    paidAmount: toCurrency(r.paidAmount),
    status: toStatus(r.status, !!r.done, !!r.noShow),
  }));

const formattedPayments = (array: Guest['previousPayments']) =>
  array?.map((r) => ({
    id: r.reservationId,
    date: stringifyDate(r.date),
    paidAmount: toCurrency(r.paidAmount),
  })) ?? [];

const ReservationColumns: Column[] = [
  {
    id: 'dateStr',
    label: 'Date',
    align: 'left',
    minWidth: 100,
  },
  {
    id: 'time',
    label: 'Time',
    align: 'center',
    minWidth: 100,
  },
  {
    id: 'guests',
    label: 'Guests',
    align: 'center',
    minWidth: 100,
  },
  {
    id: 'paidAmount',
    label: 'Umsatz',
    align: 'center',
  },
  {
    id: 'status',
    label: 'Status',
    align: 'right',
    labelTranslation: 'reservations',
    minWidth: 100,
  },
];

const ReservationColumnsWithName: Column[] = [
  {
    id: 'name',
    label: 'Name',
    align: 'left',
  },
  {
    id: 'dateStr',
    label: 'Date',
    align: 'left',
    minWidth: 100,
  },
  {
    id: 'time',
    label: 'Time',
    align: 'center',
    minWidth: 100,
  },
  {
    id: 'guests',
    label: 'Guests',
    align: 'center',
    minWidth: 100,
  },
  {
    id: 'status',
    label: 'Status',
    align: 'right',
    labelTranslation: 'reservations',
    minWidth: 100,
  },
];

const PaymentColumns: Column[] = [
  {
    id: 'date',
    label: 'Date',
    align: 'left',
    minWidth: 100,
  },
  {
    id: 'paidAmount',
    label: 'Umsatz',
    align: 'center',
  },
];

const GuestEntry = ({
  data,
  reservations,
  otherReservations,
  restaurantId,
  onBlur = noop,
  handleDelete = asyncVoid,
  transferReservation,
}: GuestEntryProps) => {
  const [state, setstate] = useState<Guest | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [customAttribute, setCustomAttribute] = useState<string>('');

  const lightReservations = useIsLightReservations();

  const history = useHistory();

  const {
    tabs,
    oldfilter,
    newCustomFilter,
    FilterArray,
    StatusArray,
    handleChange,
    handleNewFilter,
    handleFilterSubmit,
    handleClose,
    handleFilterClick,
  } = useGuestEntry({
    state,
    setstate,
    data,
    reservations,
    setOpenModal,
    setCustomAttribute,
    onBlur,
  });

  console.log({
    data,
    state,
  });

  useEffect(() => {
    if (data) {
      setstate(data);

      if (!data.guestComment && data.hostComment) {
        onBlur(data?.id || 'noId', 'guestComment', data.hostComment);
      }
    } else {
      setstate(null);
    }
  }, [data]);

  const ReservationRows = formattedReservations(reservations);

  const OtherReservationRows = formattedReservations(otherReservations);

  const [ratings] = useCollection('ratings', {
    filter: [
      ['guest.id', '==', state?.id?.replace(`-${restaurantId}`, '') || 'noId'],
      ['restaurant', '==', restaurantId],
    ],
  });

  const alert = useAlert();

  return (
    <TabCard
      id="shiftReservationCard"
      value={null}
      onChange={noop}
      tabs={tabs}
      title={state?.name}
      headerRight={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: 8,
          }}
        >
          {StatusArray.map((newStatus: string) => (
            <Status
              status={newStatus}
              key={newStatus}
              quantity={
                newStatus === 'Confirmed'
                  ? reservations.filter(
                      (item: { status: string }) => item.status === 'success'
                    ).length
                  : newStatus === 'Canceled'
                  ? reservations.filter(
                      (item: { status: string }) => item.status === 'failed'
                    ).length
                  : newStatus === 'No-Shows'
                  ? reservations.filter(
                      (item: { status: string }) => item.status === 'undefined'
                    ).length
                  : state?.previousPayments?.reduce((acc, cV) => {
                      return acc + cV.paidAmount;
                    }, 0) ?? 0
              }
            />
          ))}
        </div>
      }
    >
      {(value) => {
        if (value === 0) {
          return (
            <Contact
              {...{
                onBlur,
                state,
                handleChange,
                FilterArray,
                oldfilter,
                handleFilterClick,
                newCustomFilter,
                handleClose,
                handleNewFilter,
                openModal,
                setOpenModal,
                handleFilterSubmit,
                customAttribute,
                setCustomAttribute,
                handleDelete,
              }}
            />
          );
        } else if (value === 1) {
          return (
            <TextField
              fullWidth
              rows={10}
              className="commentInput"
              id="Comments"
              onBlur={(e) =>
                onBlur(state?.id || 'noId', e.target.name, e.target.value)
              }
              label="Comments"
              labelTranslation="common"
              value={state?.guestComment || ''}
              multiline
              name="guestComment"
              onChange={handleChange}
            />
          );
        } else if (value === 2) {
          return (
            <>
              <SimpleTable
                columns={ReservationColumns}
                rows={ReservationRows}
                hideBorders
                onRowClick={(id) => {
                  let date = ReservationRows.find((r) => r.id === id)?.date;
                  if (date)
                    history.push(
                      `/${restaurantId}/reservations/${date}/${
                        lightReservations ? 'reservationList' : 'tablePlan'
                      }?reservationId=${id}`
                    );
                }}
              />
              {!!OtherReservationRows.length && (
                <Box style={{ marginTop: 20 }}>
                  <Typography variant="h5">Ähnliche Reservierungen</Typography>
                  <Typography variant="text-3" color="subdued">
                    Reservierungen mit gleicher Telfonnummer oder Email
                  </Typography>
                  <SimpleTable
                    columns={ReservationColumnsWithName}
                    rows={OtherReservationRows}
                    hideBorders
                    onRowClick={(id) => {
                      alert({
                        title: 'Reservierung umziehen',
                        maxWidth: 'md',
                        description:
                          'Möchtest du die Reservierung zu diesem Gasteintrag umziehen?',
                        onSubmit: () => {},
                        actions: [
                          {
                            id: 'show',
                            label: 'Reservierung anzeigen',
                            onClick: () => {
                              let date = ReservationRows.find(
                                (r) => r.id === id
                              )?.date;
                              if (date)
                                history.push(
                                  `/${restaurantId}/reservations/${date}/${
                                    lightReservations
                                      ? 'reservationList'
                                      : 'tablePlan'
                                  }?reservationId=${id}`
                                );
                            },
                          },
                          {
                            id: 'transfer',
                            label: 'Reservierung umziehen',
                            onClick: () => transferReservation(id),
                            variant: 'primary',
                          },
                        ],
                      });
                    }}
                  />
                </Box>
              )}
            </>
          );
        } else if (value === 3) {
          return (
            <SimpleTable
              columns={ReservationColumns}
              rows={ReservationRows}
              hideBorders
              onRowClick={(id) => {
                let date = ReservationRows.find((r) => r.id === id)?.date;
                if (date)
                  history.push(
                    `/${restaurantId}/reservations/${date}/${
                      lightReservations ? 'reservationList' : 'tablePlan'
                    }?reservationId=${id}`
                  );
              }}
            />
          );
        } else if (value === 4) {
          return (
            <SimpleTable
              columns={PaymentColumns}
              rows={formattedPayments(state?.previousPayments || [])}
              hideBorders
              onRowClick={(id) => {
                let date = ReservationRows.find((r) => r.id === id)?.date;
                if (date)
                  history.push(
                    `/${restaurantId}/reservations/${date}/${
                      lightReservations ? 'reservationList' : 'tablePlan'
                    }?reservationId=${id}`
                  );
              }}
            />
          );
        } else if (value === 5) {
          return (
            <FeedbackTable
              hasMore={false}
              onDone={async (id) => {}}
              reviews={ratings.data}
              loading={ratings.loading}
              onLoadMore={async () => {}}
              maxHeight="calc(100vh - 300px)"
            />
          );
        } else {
          return <></>;
        }
      }}
    </TabCard>
  );
};

export default GuestEntry;
