import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const ShuffleModeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
      <g clip-path="url(#clip0_14933_35027)">
        <path
          d="M8.75031 6.53325L11.667 8.16659L10.0336 5.24992L11.667 2.33325L8.75031 3.96659L5.83365 2.33325L7.46698 5.24992L5.83365 8.16659L8.75031 6.53325ZM22.7503 17.9666L19.8336 16.3333L21.467 19.2499L19.8336 22.1666L22.7503 20.5333L25.667 22.1666L24.0336 19.2499L25.667 16.3333L22.7503 17.9666ZM25.667 2.33325L22.7503 3.96659L19.8336 2.33325L21.467 5.24992L19.8336 8.16659L22.7503 6.53325L25.667 8.16659L24.0336 5.24992L25.667 2.33325ZM16.7653 8.50492C16.3103 8.04992 15.5753 8.04992 15.1203 8.50492L1.50531 22.1199C1.05031 22.5749 1.05031 23.3099 1.50531 23.7649L4.23531 26.4949C4.69031 26.9499 5.42531 26.9499 5.88031 26.4949L19.4836 12.8916C19.9386 12.4366 19.9386 11.7016 19.4836 11.2466L16.7653 8.50492ZM15.5636 14.9099L13.0903 12.4366L15.937 9.58992L18.4103 12.0633L15.5636 14.9099Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_14933_35027">
          <rect width="28" height="28" fill="currentColor" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default ShuffleModeIcon;
