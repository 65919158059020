import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const SpreadHorizontal = (props: SvgIconProps) => {
  return (
    <SvgIcon width="36" height="36" viewBox="0 0 36 36" fill="none">
      <rect width="36" height="36" rx="18" fill="#EDF2F7" />
      <path d="M8 8.625V27.375" stroke="#011311" stroke-linecap="round" />
      <path d="M28 8.625V27.375" stroke="#011311" stroke-linecap="round" />
      <path
        d="M22.2168 8H13.7831C13.4504 8 13.1807 8.27982 13.1807 8.625V27.375C13.1807 27.7202 13.4504 28 13.7831 28H22.2168C22.5495 28 22.8192 27.7202 22.8192 27.375V8.625C22.8192 8.27982 22.5495 8 22.2168 8Z"
        fill="#011311"
      />
    </SvgIcon>
  );
};

export default SpreadHorizontal;
