import React, { useEffect } from "react";

type Options = {
  altKeyPressed?: boolean;
  ctrlKeyPressed?: boolean;
  deps?: any[];
  key?: string;
};

const DEFAULT_OPTIONS: Options = {
  altKeyPressed: false,
  ctrlKeyPressed: false,
  deps: [],
};

const useKeyPressListener = (
  onPress: (e: KeyboardEvent) => void,
  options = DEFAULT_OPTIONS
) => {
  const {
    deps = [],
    altKeyPressed = false,
    ctrlKeyPressed = false,
    key,
  } = { ...DEFAULT_OPTIONS, ...options };

  useEffect(() => {
    function keyPressListener(e: KeyboardEvent) {
      console.log("xxx", e.metaKey, e.key);
      if (altKeyPressed && !e.altKey) return;
      if (ctrlKeyPressed && !e.ctrlKey) return;
      if (key && e.key !== key) return;
      onPress(e);
    }

    // document.removeEventListener("onkeydown", keyPressListener as any);
    document.addEventListener("keydown", keyPressListener as any);

    console.log("xxx", keyPressListener);

    return () => {
      document.removeEventListener("keydown", keyPressListener as any);
    };
  }, deps);
};

export default useKeyPressListener;
