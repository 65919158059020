import React, { useMemo } from "react";
import "./styles.scss";
import Modal from "Components/Molecules/Modal";
import { noop, toEuropeanDate, wDayHelper } from "utils/helper";
import Typography from "Components/Atoms/Typography";
import { Date as DateType, ReservationShift, ShiftType } from "types/shifts";
import GTable, { ContextAction, ListConfigHeader } from "../GTable";
import EditableTableCell from "../GTable/Components/EditableTableCell";
import { Lock, LockOpenOutlined } from "Components/Atoms/Icons";
import IconButton from "Components/Atoms/IconButton";

export type CloseShiftModalProps = {
  open: boolean;
  onClose: () => void;
  date: string;
  onSubmit: () => void;
  dateData: DateType;
  onChange: (nV: DateType) => void;
};

const CloseShiftModal: React.FC<CloseShiftModalProps> = ({
  open = false,
  onClose = noop,
  date = "",
  onSubmit = noop,
  dateData,
  onChange,
}: CloseShiftModalProps) => {
  const dateString = useMemo(() => {
    const day = wDayHelper(new Date(date).getDay(), true, "de");
    return day + " " + toEuropeanDate(date);
  }, [date]);

  const handleChange = (
    type: "newMaxGuest" | "open" | "close",
    shiftId: string,
    value?: number
  ) => {
    const newShifts = dateData.shifts.map((s) => {
      if (s.id === shiftId && s.type === ShiftType.RESERVATION) {
        if (type === "newMaxGuest") {
          return !!value || value === 0
            ? { ...s, overwrite: { ...s.overwrite, maxGuests: value } }
            : s;
        } else {
          return {
            ...s,
            overwrite: { ...s.overwrite, blockReservations: type === "close" },
          };
        }
      } else return s;
    });
    onChange({ ...dateData, shifts: newShifts });
  };

  const headers: ListConfigHeader<ReservationShift>[] = [
    {
      field: "name",
      headerName: "Shift Name",
      headerTranslation: "reservations",
      displayFunction: (data) => {
        return (
          <Typography variant="text-3" color="textPrimary">
            {data.name ?? data.id}
          </Typography>
        );
      },
    },
    {
      field: "guests",
      headerName: "Guests (Current)",
      headerTranslation: "reservations",
      displayFunction: (data) => {
        const shiftReservations = dateData.shiftStatistics?.[data.id] ?? {};
        const guestNb = Object.keys(shiftReservations).reduce(
          (acc, cV) => acc + shiftReservations[cV],
          0
        );
        const groupsNb = Object.keys(shiftReservations).length;
        return data.closed ? (
          <Typography variant="text-3" color="textPrimary">
            - / -
          </Typography>
        ) : (
          <Typography
            variant="text-3"
            color="textPrimary"
            translationKey="reservations_guestGroups"
          >
            {{ guestNb }} Guests / {{ groupsNb }} Groups
          </Typography>
        );
      },
    },
    {
      field: "maxGuests",
      headerName: "Max Guests",
      headerTranslation: "reservations",
      displayFunction: (data) => {
        return (
          <EditableTableCell
            name="maxGuest"
            disabled={data.closed || !data.additional}
            style={
              !(data.closed || !data.additional)
                ? {
                    border: "2px solid var(--color-primary)",
                    borderRadius: 4,
                    padding: 4,
                  }
                : {}
            }
            value={
              data.overwrite?.maxGuests ?? data.additional?.maxGuests ?? "-"
            }
            onChange={(e) =>
              handleChange("newMaxGuest", data.id, +e.target.value)
            }
          />
        );
      },
    },
  ];

  const contextActions: ContextAction<ReservationShift>[] = [
    {
      render: (data) => {
        return (
          <>
            <IconButton
              color="primary"
              size="small"
              style={{ margin: "5px 0px" }}
              disabled={data.closed}
              onClick={() =>
                handleChange(
                  data.overwrite?.blockReservations ? "open" : "close",
                  data.id
                )
              }
            >
              {data.overwrite?.blockReservations ||
              (data.overwrite?.maxGuests ?? data.additional?.maxGuests) ===
                0 ? (
                <Lock />
              ) : (
                <LockOpenOutlined />
              )}
            </IconButton>
            {!data.closed &&
              (data.overwrite?.maxGuests ?? data.additional?.maxGuests) ===
                0 && (
                <Typography variant="text-5">max. Anzahl ist 0</Typography>
              )}
          </>
        );
      },
      onClick: (data) => console.log(data),
      showCondition: (data) => true,
      tooltipContent: "Open / Close",
      tooltipContentTranslation: "reservations",
    },
  ];

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Close / Open Reservations"
      titleTranslation="reservations"
      actions={[
        {
          id: "cancel",
          label: "Cancel",
          onClick: onClose,
        },
        {
          id: "save",
          label: "Save",
          onClick: onSubmit,
          variant: "primary",
        },
      ]}
      maxWidth="md"
    >
      <Typography
        variant="text-3"
        block
        style={{ marginBottom: 14 }}
        translationKey="reservations_closeShift"
      >
        Close the Shifts for {{ dateString }}. Either Close the Shift or reduce
        the max Guests.
      </Typography>
      <GTable
        headers={headers}
        data={dateData.shifts.filter((s) => !s.commentOnly)}
        contextActions={contextActions}
      />
    </Modal>
  );
};

export default CloseShiftModal;
