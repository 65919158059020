import React, { useEffect, useMemo, useState } from 'react';
import Box from 'Components/Atoms/Box';
import Typography from 'Components/Atoms/Typography';
import Button from 'Components/Atoms/Button';
import { Event, EventAddOn } from 'types/calendar';
import Translations from 'App/Settings/Components/Translations';
import Modal from 'Components/Molecules/Modal';
import ImageField from 'Components/Organisms/ImageField';
import TextField from 'Components/Atoms/TextField';
import { Translation } from 'utils/types';
import IconButton from 'Components/Atoms/IconButton';
import { Close } from 'Components/Atoms/Icons';
import Toast from 'Components/Molecules/Toast';
import useToast from 'CustomHooks/useToast';
import { Severity } from 'Contexts/RestaurantContext';
import InputAdornmentAtom from 'Components/Atoms/InputAdornment';
import RadioButtonGroup from 'Components/Atoms/RadioButtonGroup';

type EditAddonModalProps = {
  openEditOptions:
    | false
    | {
        isOpen: boolean;
        optId: string | null;
        type: 'options' | 'addons';
      };
  setOpenEditOptions: React.Dispatch<
    React.SetStateAction<
      | false
      | {
          isOpen: boolean;
          optId: string | null;
          type: 'options' | 'addons';
        }
    >
  >;
  setEventState: React.Dispatch<React.SetStateAction<Event | null>>;
  eventState: Event | null;
};

const EditAddonModal = ({
  openEditOptions,
  setOpenEditOptions,
  setEventState,
  eventState,
}: EditAddonModalProps) => {
  const toast = useToast();

  const originalAddon = useMemo(
    () =>
      eventState &&
      eventState.optionalAddOns &&
      openEditOptions &&
      [...eventState.optionalAddOns].filter(
        (o) => o.id === openEditOptions.optId
      )[0],
    [openEditOptions]
  );

  const modifyAddon = (toEditAddon: EventAddOn) => {
    if (eventState?.optionalAddOns && openEditOptions) {
      const filteredAddons = [...eventState?.optionalAddOns].filter(
        (o) => o.id !== openEditOptions.optId
      );

      setEventState({
        ...eventState,
        optionalAddOns: [...filteredAddons, toEditAddon],
      });
    }
  };

  const handleCancel = () => {
    if (eventState && eventState?.optionalAddOns) {
      if (originalAddon && originalAddon.id !== 'newAddon') {
        let editedAddons: EventAddOn[] = [...eventState.optionalAddOns].filter(
          (o) => o.id !== originalAddon.id
        );

        setEventState({
          ...eventState,
          optionalAddOns: [...editedAddons, originalAddon],
        });
      } else {
        let deleteNewAddon: EventAddOn[] = eventState.optionalAddOns.filter(
          (o) => o.id !== 'newAddon'
        );

        setEventState({ ...eventState, optionalAddOns: deleteNewAddon });
      }
      setOpenEditOptions(false);
    }
  };

  const handleSave = () => {
    if (eventState && eventState?.optionalAddOns) {
      const eventStateAddons = [...eventState.optionalAddOns];
      const newAddon: any =
        originalAddon &&
        eventStateAddons.find((o) => o.id === originalAddon.id);

      const specialCharacters = new RegExp(
        /[`~!@#$%^&*()_|+\=?.;:'"<>\{\}\[\]\\\/]/gi
      );

      if (newAddon) {
        if (!newAddon.internalName) {
          return toast(
            'An internal name is required, please provide one to save your option.',
            Severity.WARNING,
            'common'
          );
        }

        const newId = newAddon.internalName
          ?.toLocaleLowerCase()
          .replaceAll(' ', '-')
          .replaceAll(specialCharacters, '');

        const toSaveAddon = { ...newAddon, id: newId };

        modifyAddon(toSaveAddon);
      }
      setOpenEditOptions(false);
    }
  };

  const handleNameChange = (name: string) => {
    if (eventState?.optionalAddOns && openEditOptions) {
      let toEditAddon: any = {
        ...eventState.optionalAddOns.find(
          (o) => o.id === openEditOptions.optId
        ),
      };

      toEditAddon.internalName = name;

      modifyAddon(toEditAddon);
    }
  };

  const handleVATChange = (rate: string) => {
    if (eventState?.optionalAddOns && openEditOptions) {
      let toEditAddon: any = {
        ...eventState.optionalAddOns.find(
          (o) => o.id === openEditOptions.optId
        ),
      };

      toEditAddon.vatRate = rate;
      modifyAddon(toEditAddon);
    }
  };

  const handleAddonTitleAndDescriptionChange = (
    e: Record<string, Translation<string>>
  ) => {
    const newText: Record<
      string,
      {
        title: string;
        description: string;
      }
    > = Object.entries(e).reduce(
      (acc, [key, value]) => ({ ...acc, [key]: value }),
      {}
    );

    if (eventState && eventState.optionalAddOns && openEditOptions) {
      let toEditAddon: any = {
        ...eventState.optionalAddOns.find(
          (o) => o.id === openEditOptions.optId
        ),
      };

      toEditAddon.translation = newText;

      modifyAddon(toEditAddon);
    }
  };

  const handleAddonImage = (e: string | (string | null)[] | null) => {
    if (eventState?.optionalAddOns && openEditOptions) {
      let toEditAddon: any = {
        ...eventState.optionalAddOns.find(
          (o) => o.id === openEditOptions.optId
        ),
      };

      if (typeof e === 'string') toEditAddon.img = e;

      modifyAddon(toEditAddon);
    }
  };

  const handleAddonPrice = (e: number) => {
    if (eventState?.optionalAddOns && openEditOptions) {
      let toEditAddon: any = {
        ...eventState.optionalAddOns.find(
          (o) => o.id === openEditOptions.optId
        ),
      };

      toEditAddon.amount = e;

      modifyAddon(toEditAddon);
    }
  };

  const handleAddonLimited = (e: number) => {
    if (eventState?.optionalAddOns && openEditOptions) {
      let toEditAddon: any = {
        ...eventState.optionalAddOns.find(
          (o) => o.id === openEditOptions.optId
        ),
      };

      toEditAddon.limited = e;

      modifyAddon(toEditAddon);
    }
  };

  const handleAddonType = (type: EventAddOn['type']) => {
    if (eventState?.optionalAddOns && openEditOptions) {
      let toEditAddon: any = {
        ...eventState.optionalAddOns.find(
          (o) => o.id === openEditOptions.optId
        ),
      };
      toEditAddon.type = type;

      modifyAddon(toEditAddon);
    }
  };

  const optionsVatRatesOptions = [
    {
      value: '7.00',
      label: '7% MwSt',
    },
    {
      value: '19.00',
      label: '19% MwSt',
    },
    {
      value: '0.00',
      label: '0% MwSt',
    },
    {
      value: '10.00',
      label: '10% MwSt (Österreich)',
    },
    {
      value: '20.00',
      label: '20% MwSt (Österreich)',
    },
  ];

  if (!eventState) {
    return <></>;
  }

  return (
    <Modal
      title="Optional AddOns"
      maxWidth="xl"
      open={!!openEditOptions && openEditOptions.type === 'addons'}
      headerRight={
        <Box flex style={{ justifyContent: 'flex-end', width: 'auto' }}>
          <Button
            variant="outlined"
            onClick={handleSave}
            style={{ marginRight: 16 }}
            typographyProps={{ variant: 'text-3' }}
          >
            Save
          </Button>
          <IconButton size="small" onClick={handleCancel}>
            <Close color="inherit" />
          </IconButton>
        </Box>
      }
    >
      {openEditOptions &&
        openEditOptions.type === 'addons' &&
        eventState.optionalAddOns &&
        eventState.optionalAddOns.map((o) => {
          if (o.id === openEditOptions.optId) {
            return (
              <>
                <Box className="mg-bt-md">
                  <Typography variant="h5" translation="calendar">
                    Edit or add an optional add on to this event
                  </Typography>
                  <Typography
                    color="subdued"
                    variant="text-3"
                    translation="calendar"
                  >
                    You have to choose a name for your add on, for you to
                    recognize it easily (18 characters max). Then you can modify
                    its details.
                  </Typography>
                </Box>

                <Box className="mg-bt-md" style={{ width: '45%' }}>
                  <TextField
                    fullWidth
                    required
                    label="Choose an internal name"
                    value={o.internalName || ''}
                    onChange={(e) => handleNameChange(e.currentTarget.value)}
                  />
                </Box>

                <Box flex className="mg-bt-md">
                  <Box className="max-column-width">
                    <Translations
                      borders
                      state={{
                        de: {
                          title: o.translation.de.title || '',
                          description: o.translation.de.description || '',
                        },
                        en: {
                          title: o.translation.en.title || '',
                          description: o.translation.en.description || '',
                        },
                      }}
                      onChange={handleAddonTitleAndDescriptionChange}
                      keys={[
                        {
                          id: 'title',
                          label: 'Title',
                          required: true,
                        },
                        {
                          id: 'description',
                          label: 'Description',
                          required: true,
                          multiline: true,
                        },
                      ]}
                      addLanguage={false}
                    />
                  </Box>
                  <ImageField
                    value={o?.img || ''}
                    onChange={handleAddonImage}
                    label={''}
                    style={{ marginLeft: 16 }}
                    type="url"
                  />
                </Box>

                <Box
                  className="mg-bt-md"
                  fullSize
                  style={{
                    justifyContent: 'space-between',
                    marginTop: 24,
                    flexWrap: 'nowrap',
                  }}
                >
                  <Typography
                    block
                    variant="text-3"
                    style={{ marginBottom: 8 }}
                    translation="calendar"
                  >
                    Price and quantity
                  </Typography>
                  <Box flex className="mg-bt-md">
                    <TextField
                      fullWidth
                      type="number"
                      label="Price"
                      labelTranslation="calendar"
                      value={o?.amount || ''}
                      onChange={(e) =>
                        handleAddonPrice(Number(e.currentTarget.value))
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornmentAtom position="end">
                            <Typography color="subdued">€</Typography>
                          </InputAdornmentAtom>
                        ),
                      }}
                    />
                    <Box width="15%" />
                    <TextField
                      fullWidth
                      size="small"
                      type="number"
                      label="Specify limited quantity"
                      labelTranslation="calendar"
                      value={o?.limited || ''}
                      onChange={(e) =>
                        handleAddonLimited(Number(e.currentTarget.value))
                      }
                    />
                  </Box>
                </Box>
                <RadioButtonGroup
                  style={{ display: 'flex' }}
                  value={o.vatRate}
                  options={optionsVatRatesOptions}
                  onChange={(e: any) => {
                    handleVATChange(e.target.value);
                  }}
                  isRow
                />
                <Box className="mg-bt-md">
                  <Typography
                    block
                    variant="text-3"
                    style={{ marginBottom: 8 }}
                    translation="calendar"
                  >
                    Select the option type
                  </Typography>
                  <Box>
                    <Button
                      variant={o.type === 'perPerson' ? 'primary' : 'default'}
                      onClick={() => handleAddonType('perPerson')}
                      translation="calendar"
                    >
                      Per Person
                    </Button>
                    <Button
                      style={{ marginLeft: 16 }}
                      variant={o.type === 'perGroup' ? 'primary' : 'default'}
                      onClick={() => handleAddonType('perGroup')}
                      translation="calendar"
                    >
                      Per Group
                    </Button>
                  </Box>
                </Box>
              </>
            );
          }
        })}
    </Modal>
  );
};

export default EditAddonModal;
