import React from "react";

const LightReservationsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M34.9998 16.6667C33.1665 16.6667 31.6665 18.1667 31.6665 20V25H8.33317V20C8.33317 18.1667 6.84984 16.6667 4.99984 16.6667C3.14984 16.6667 1.6665 18.1667 1.6665 20V28.3333C1.6665 30.1667 3.1665 31.6667 4.99984 31.6667H34.9998C36.8332 31.6667 38.3332 30.1667 38.3332 28.3333V20C38.3332 18.1667 36.8332 16.6667 34.9998 16.6667ZM29.9998 8.33334H9.99984C8.1665 8.33334 6.6665 9.83334 6.6665 11.6667V15.25C8.59984 15.9333 9.99984 17.7833 9.99984 19.9333V23.3333H29.9998V19.95C29.9998 17.7833 31.3998 15.95 33.3332 15.2667V11.6667C33.3332 9.83334 31.8332 8.33334 29.9998 8.33334Z"
      fill="currentColor"
    />
  </svg>
);

export default LightReservationsIcon;
