import React, { useState, useEffect } from "react";
import firebase, { db } from "utils/firebase";
import { dateHelper } from "utils/helper";

const useWaitingTimes = (restaurantId: string | null) => {
  const [waitingTimes, setwaitingTimes] = useState<{
    delivery: string | null;
    pickup: string | null;
  }>({ delivery: null, pickup: null });
  const [loading, setloading] = useState<boolean>(true);
  const [listener, setlistener] = useState<Function[]>([]);
  const [error, seterror] = useState<null | string>(null);

  useEffect(() => {
    if (listener && listener.length) {
      listener.forEach((unsubsribe) => unsubsribe());
    }

    setloading(true);
    seterror(null);

    if (restaurantId) {
      const ref = db
        .collection(`restaurants/${restaurantId}/settings`)
        .doc("takeAway");

      let unsubscribe = ref.onSnapshot(
        async (doc) => {
          if (!doc.exists) {
            setwaitingTimes({ delivery: null, pickup: null });
          } else {
            let data: any = doc.data();

            // console.log({ data });

            if (!data?.currentWaitingTimes) {
              setwaitingTimes({ delivery: null, pickup: null });
            } else {
              setwaitingTimes(data.currentWaitingTimes);
            }
          }

          setloading(false);
        },
        (err: Error) => {
          seterror(err.message);
          setloading(false);
        }
      );

      setlistener([unsubscribe]);
    }

    return () => {
      if (listener && listener.length) {
        listener.forEach((unsubsribe) => unsubsribe());
      }
    };
  }, [db, restaurantId]);

  const handleWaitingTimesChange: (
    key: "takeAway" | "pickup",
    newValue: string
  ) => void = (key, newValue) => {
    try {
      const currentWaitingTimes = { ...waitingTimes, [key]: newValue };

      db.collection(`restaurants/${restaurantId}/settingDrafts`)
        .doc("takeAway")
        .update({ currentWaitingTimes });
      db.collection(`restaurants/${restaurantId}/settings`)
        .doc("takeAway")
        .update({ currentWaitingTimes });
    } catch (error) {
      console.error(error);
    }
  };

  return { waitingTimes, handleWaitingTimesChange };
};

export default useWaitingTimes;
