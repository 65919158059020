import React, { useState } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import NavBar from 'Components/Organisms/NavBar';
import Container from 'Components/Atoms/Container';
import Button from 'Components/Atoms/Button';
import SearchBar from 'Components/Molecules/SearchBar';
import Card from 'Components/Molecules/Card';
import NoGuestSelected from 'App/GuestBook/Components/NoGuestSelected';
import GuestEntry from 'App/GuestBook/Components/GuestEntry';
import GuestCard from 'App/GuestBook/Components/GuestCard';
import useGuestBook from 'CustomHooks/useGuestBook';
import { CircularProgress } from '@material-ui/core';
import Typography from 'Components/Atoms/Typography';
import FeatureNavigation from 'Components/Molecules/FeatureNavigation';
import { PlusOne, Search } from 'Components/Atoms/Icons';
import StatisticsIcon from 'Components/Atoms/Icons/StatisticsIcon';
import NewGuestModal from 'App/GuestBook/Components/NewGuestModal';

export const paths = [
  {
    id: 'guestbook',
    linkTo: (rId: string) => `/${rId}/guestbook`,
    name: 'Guestbook',
    icon: <Search />,
    authenticate: 'dashboard.guestBook.read',
  },
  {
    id: 'statistics',
    linkTo: (rId: string) => `/${rId}/statistics`,
    name: 'Statistics',
    icon: <StatisticsIcon />,
    authenticate: 'dashboard.statistics.read',
  },
];

type Tab = 'name' | 'last_visit_date DESC' | 'visits DESC' | 'relevance';

function toTitle(tab: Tab) {
  switch (tab) {
    case 'name':
      return 'Alphabetisch';
    case 'last_visit_date DESC':
      return 'Letzter Besuch';
    case 'visits DESC':
      return 'Besuche';
    default:
      return 'Relevance';
  }
}
export type GuestBookProps = {
  restaurantId: string;
  uid: string;
  date: string;
};

const tabs: Tab[] = ['name', 'last_visit_date DESC', 'visits DESC'];

const GuestBook = ({ restaurantId, uid, date }: GuestBookProps) => {
  const handleTabChange = (newTab: Tab | 'relevance') => () =>
    setsort(newTab === 'relevance' ? null : newTab);
  const [perPage, setperPage] = useState<number>(20);

  const {
    search,
    setsearch,
    selectedGuest,
    onGuestSelect,
    sort,
    setsort,
    searchResults,
    guestLoading,
    searchLoading,
    guestError,
    searchError,
    hasMore,
    deleteAGuest,
    submitNewGuest,
    transferReservation,
  } = useGuestBook(restaurantId, 0, perPage, uid);

  console.log({
    searchResults,
  });

  const handleScrolling = (
    event: React.UIEvent<HTMLDivElement | HTMLLabelElement | HTMLFormElement>
  ) => {
    if (
      event.currentTarget.scrollHeight - event.currentTarget.scrollTop ===
        event.currentTarget.clientHeight &&
      hasMore
    ) {
      setperPage((p) => p + 10);
    }
  };

  const [newGuestModal, setnewGuestModal] = useState(false);

  const openNewGuestModal = () => {
    setnewGuestModal(true);
  };

  return (
    <Box background id="guest-book-screen">
      <NavBar title="Guest Book" className="fixed-nav" />
      <FeatureNavigation
        date={date}
        paths={paths}
        current="guestbook"
        className="featureNavigation"
      />
      <Container variant="feature">
        <Box xs={12} flex className="space-between fixed-header">
          <Box flex width="auto" style={{ flexGrow: 10 }}>
            {tabs.map((t) => (
              <Button
                key={t}
                onClick={handleTabChange(t)}
                variant={
                  sort === t || (t === 'relevance' && !sort)
                    ? 'primary'
                    : 'default'
                }
                style={{ marginRight: 16 }}
              >
                {toTitle(t)}
              </Button>
            ))}
          </Box>

          <SearchBar
            value={search}
            onChange={setsearch}
            width={260}
            outline={false}
            boxStyle={{
              backgroundColor: 'var(--color-btn-default)',
              height: 40,
              borderRadius: '4px',
            }}
          />
          <Button
            onClick={openNewGuestModal}
            startIcon={(p) => <PlusOne {...p} />}
            translation="dashboard"
            style={{ marginLeft: 16 }}
          >
            Add Guest
          </Button>
          <NewGuestModal
            {...{
              open: newGuestModal,
              onSubmit: async (guest) => {
                await submitNewGuest(guest);
                setnewGuestModal(false);
              },
              onClose: () => setnewGuestModal(false),
            }}
          />
        </Box>

        <Box
          xs={12}
          flex
          className="space-between"
          style={{
            marginTop: 100,
            alignItems: 'flex-start',
            height: 'calc(100vh - 150px)',
          }}
        >
          <Card
            title="All Guests"
            style={{ width: '40%', marginRight: 24, height: '100%' }}
            bodyProps={{
              padding: 0,
              style: { height: 'calc(100% - 52px)', overflow: 'scroll' },
              onScroll: (e) => handleScrolling(e),
            }}
          >
            {searchLoading && (
              <Box className="center" style={{ marginTop: 12 }}>
                <CircularProgress />
              </Box>
            )}
            {searchError && (
              <Box className="center" style={{ marginTop: 12 }}>
                <Typography variant="text-3" color="error" block>
                  {searchError}
                </Typography>
              </Box>
            )}
            {searchResults.map((res) => (
              <GuestCard
                key={res.id + res.name}
                {...res}
                active={selectedGuest?.data?.id === res.id}
                showPhone={!!search?.match(/\d{3,}/gm)}
                onClick={(id: string) => onGuestSelect(id)}
              />
            ))}
            {!searchLoading && !searchError && !searchResults.length && (
              <Box className="center" style={{ padding: 12 }}>
                <Typography variant="text-2" color="subdued" block>
                  No Result
                </Typography>
              </Box>
            )}
          </Card>
          <Box className="right-pannel">
            {!!selectedGuest ? (
              <GuestEntry
                {...selectedGuest}
                restaurantId={restaurantId}
                handleDelete={deleteAGuest}
                transferReservation={transferReservation}
              />
            ) : (
              <NoGuestSelected {...{ guestError, guestLoading }} />
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default GuestBook;
