import React from 'react';
import {
  NotInterested,
  Cake,
  CheckCircle,
  FilterHdr,
  Image,
  LocalDining,
  Star,
  Comment,
  Ticket,
  Blacklist,
  Extension,
} from 'Components/Atoms/Icons';
import Box from 'Components/Atoms/Box';
import Rating1 from 'Components/Atoms/Icons/FeedbackIcons/Rating1';
import Rating2 from 'Components/Atoms/Icons/FeedbackIcons/Rating2';
import Rating3 from 'Components/Atoms/Icons/FeedbackIcons/Rating3';
import Rating4 from 'Components/Atoms/Icons/FeedbackIcons/Rating4';
import Rating5 from 'Components/Atoms/Icons/FeedbackIcons/Rating5';
import Loyalty from '@material-ui/icons/Loyalty';
import CreditCard from '@material-ui/icons/CreditCard';
import EventBusy from '@material-ui/icons/EventBusy';
import { Reservation } from 'gastronaut-shared/types/helper/reservations';
import PinReservation from 'Components/Atoms/Icons/PinReservation';
import { useReservationContext } from 'Contexts/ReservationContext';

const ATTRIBUTES = [
  'specialOccassion',
  'regular',
  'vip',
  'preOrdered',
  'window',
  'terrace',
  'confirmedByGuest',
  'blacklist',
  'ticket',
  'comment',
];

export type AttrIconsProps = {
  attr: string[];
  customAttr?: string[];
  coloredLogo: boolean;
  fontSize?: number | string;
  alignRight?: boolean;
  feedbackRating?: number;
  creditCardCustomerId?: string;
  noShowFee?: Reservation['noShowFee'];
  noShow?: boolean;
  fixed?: boolean;
  addOns?: boolean;
  emojicons?: string[];
  done?: boolean;
};

const filterAttr = (...props: string[][]) =>
  props
    .flat()
    .filter(
      (a) =>
        !!a &&
        !ATTRIBUTES.includes(a) &&
        !a.match(regexpUnicodeModified)?.length
    );

const regexpUnicodeModified =
  /\ud83c\udff4(\udb40[\udc61-\udc7a])+\udb40\udc7f|(\ud83c[\udde6-\uddff]){2}|([\#\*0-9]\ufe0f?\u20e3)|(\u00a9|\u00ae|[\u203c\u2049\u20e3\u2122\u2139\u2194-\u2199\u21a9\u21aa\u231a\u231b\u2328\u23cf\u23e9-\u23fa\u24c2\u25aa\u25ab\u25b6\u25c0\u25fb-\u25fe\u2600-\u2604\u260e\u2611\u2614\u2615\u2618\u261d\u2620\u2622\u2623\u2626\u262a\u262e\u262f\u2638-\u263a\u2640\u2642\u2648-\u2653\u265f\u2660\u2663\u2665\u2666\u2668\u267b\u267e\u267f\u2692-\u2697\u2699\u269b\u269c\u26a0\u26a1\u26a7\u26aa\u26ab\u26b0\u26b1\u26bd\u26be\u26c4\u26c5\u26c8\u26ce\u26cf\u26d1\u26d3\u26d4\u26e9\u26ea\u26f0-\u26f5\u26f7-\u26fa\u26fd\u2702\u2705\u2708-\u270d\u270f\u2712\u2714\u2716\u271d\u2721\u2728\u2733\u2734\u2744\u2747\u274c\u274e\u2753-\u2755\u2757\u2763\u2764\u2795-\u2797\u27a1\u27b0\u27bf\u2934\u2935\u2b05-\u2b07\u2b1b\u2b1c\u2b50\u2b55\u3030\u303d\u3297\u3299]|\ud83c[\udc04\udccf\udd70\udd71\udd7e\udd7f\udd8e\udd91-\udd9a\udde6-\uddff\ude01\ude02\ude1a\ude2f\ude32-\ude3a\ude50\ude51\udf00-\udf21\udf24-\udf93\udf96\udf97\udf99-\udf9b\udf9e-\udff0\udff3-\udff5\udff7-\udfff]|\ud83d[\udc00-\udcfd\udcff-\udd3d\udd49-\udd4e\udd50-\udd67\udd6f\udd70\udd73-\udd7a\udd87\udd8a-\udd8d\udd90\udd95\udd96\udda4\udda5\udda8\uddb1\uddb2\uddbc\uddc2-\uddc4\uddd1-\uddd3\udddc-\uddde\udde1\udde3\udde8\uddef\uddf3\uddfa-\ude4f\ude80-\udec5\udecb-\uded2\uded5-\uded7\udedc-\udee5\udee9\udeeb\udeec\udef0\udef3-\udefc\udfe0-\udfeb\udff0]|\ud83e[\udd0c-\udd3a\udd3c-\udd45\udd47-\ude7c\ude80-\ude88\ude90-\udebd\udebf-\udec5\udece-\udedb\udee0-\udee8\udef0-\udef8])((\ud83c[\udffb-\udfff])?(\ud83e[\uddb0-\uddb3])?(\ufe0f?\u200d([\u2000-\u3300]|[\ud83c-\ud83e][\ud000-\udfff])\ufe0f?)?)*/g;

const icons = (...props: string[][]) => {
  let attr = props.flat();

  return attr
    .map((x) => x.match(regexpUnicodeModified)?.[0])
    .filter((x) => !!x);
};

const AttrIcons = ({
  attr,
  coloredLogo,
  fontSize = '0.9rem',
  alignRight = false,
  feedbackRating,
  customAttr = [],
  creditCardCustomerId,
  noShowFee,
  noShow,
  fixed,
  addOns,
  done,
}: AttrIconsProps) => {
  const emojicons = icons(attr, customAttr);

  return (
    <Box
      style={{
        width: alignRight ? 'auto' : 'fit-content',
        alignItems: 'center',
        justifyContent: alignRight ? 'flex-end' : 'flex-start',
      }}
      flex
    >
      {attr.includes('specialOccassion') && (
        <Cake
          className="smallIcon"
          style={
            coloredLogo
              ? { color: 'var(--color-secondary)' }
              : { color: 'white' }
          }
        />
      )}
      {(attr.includes('regular') || attr.includes('vip')) && (
        <Star
          className="smallIcon"
          style={
            coloredLogo
              ? { color: 'var(--color-warning)', fontSize }
              : { color: 'white', fontSize }
          }
        />
      )}
      {attr.includes('preOrdered') && (
        <LocalDining
          className="smallIcon"
          style={
            coloredLogo
              ? { color: 'var(--color-text)', fontSize }
              : { color: 'white', fontSize }
          }
        />
      )}
      {attr.includes('window') && (
        <Image
          className="smallIcon"
          style={
            coloredLogo
              ? { color: 'var(--color-tertiary)', fontSize }
              : { color: 'white', fontSize }
          }
        />
      )}
      {attr.includes('terrace') && (
        <FilterHdr
          className="smallIcon"
          style={
            coloredLogo
              ? { color: 'var(--color-text)', fontSize }
              : { color: 'white', fontSize }
          }
        />
      )}
      {attr.includes('confirmedByGuest') && (
        <CheckCircle
          className="smallIcon"
          style={
            coloredLogo
              ? { color: 'var(--color-success)', fontSize, height: fontSize }
              : { color: 'white', fontSize, height: fontSize }
          }
        />
      )}
      {attr.includes('blacklist') && (
        <Blacklist
          width={fontSize}
          height={fontSize}
          className="smallIcon"
          style={
            coloredLogo
              ? { color: 'var(--color-critical)', fontSize }
              : { color: 'white', fontSize }
          }
        />
      )}
      {attr.includes('ticket') && (
        <Ticket
          width={fontSize}
          height={fontSize}
          className="smallIcon"
          style={
            coloredLogo
              ? { color: 'var(--color-tertiary)', fontSize }
              : { color: 'white', fontSize }
          }
        />
      )}
      {attr.includes('comment') && (
        <Comment
          className="smallIcon"
          style={
            coloredLogo
              ? { color: 'var(--color-secondary)', fontSize }
              : { color: 'white', fontSize }
          }
        />
      )}
      {!!filterAttr(attr, customAttr).length && (
        <Loyalty
          width={fontSize}
          height={fontSize}
          className="smallIcon"
          style={
            coloredLogo
              ? {
                  color: 'var(--color-tertiary)',
                  fontSize,
                  transform: 'scale(1.1)',
                  marginRight: 2,
                }
              : {
                  color: 'white',
                  fontSize,
                  transform: 'scale(1.1)',
                  marginRight: 2,
                }
          }
        />
      )}
      {!!done && feedbackRating && feedbackRating === 1 && <Rating1 />}
      {!!done && feedbackRating && feedbackRating === 2 && <Rating2 />}
      {!!done && feedbackRating && feedbackRating === 3 && <Rating3 />}
      {!!done && feedbackRating && feedbackRating === 4 && <Rating4 />}
      {!!done && feedbackRating && feedbackRating === 5 && <Rating5 />}
      {creditCardCustomerId && (
        <CreditCard
          style={
            coloredLogo
              ? {
                  color: !!noShowFee
                    ? 'var(--color-secondary)'
                    : 'var(--color-warning)',
                  fontSize: '1rem',
                }
              : { color: 'white', fontSize: '1rem' }
          }
        />
      )}
      {noShow && (
        <EventBusy
          style={
            coloredLogo
              ? { color: 'var(--color-critical)', fontSize: '1rem' }
              : { color: 'white', fontSize: '1rem' }
          }
        />
      )}
      {!!fixed && (
        <PinReservation
          style={
            coloredLogo
              ? { color: 'var(--color-secondary)', width: 14, height: 14 }
              : { color: 'white', width: 14, height: 14 }
          }
        />
      )}
      {addOns && (
        <Extension
          style={
            coloredLogo
              ? { color: 'var(--color-secondary)', width: 14, height: 14 }
              : { color: 'white', width: 14, height: 14 }
          }
        />
      )}
      {emojicons.map((emoji, index) => (
        <span key={index}>{emoji}</span>
      ))}
    </Box>
  );
};

export default AttrIcons;
