import React, {
  useRef,
  useEffect,
  useState,
  useMemo,
  useContext,
  useCallback,
} from "react";
import Box from "Components/Atoms/Box";
import "./styles.scss";
import { AnyShift, Date as DayType } from "types/shifts";
import { blockToTime, dateHelper, getUnique, timeToBlock } from "utils/helper";
import DayHeader from "../DayHeader";
import Typography from "Components/Atoms/Typography";
import Shift from "../ReservationShift";
import dayjs from "dayjs";
import CurrentTimeIndicator from "./Components/CurrentTimeIndicator";
import { useResizeDetector } from "react-resize-detector/build/withPolyfill";
import { CalendarContext } from "Contexts/CalendarContext";
import MultipleShiftsCard from "../MultipleShiftsCard";
import { ActiveShift } from "CustomHooks/useCalendarDates";
import useShiftSeperator from "CustomHooks/useShiftSeperator";

export type Week2Props = {
  days: DayType<AnyShift>[];
  currentShift: null | { date: string; shiftId: string };
  handleShiftClick: (date: string, shiftId: string) => void;
  activeShift?: ActiveShift<AnyShift> | undefined;
};

export type CalendarShift = AnyShift & { date: string };

const hoursPerDay = Array.from(Array(24), (_, index) =>
  blockToTime(index * 4 + 16)
);

const shiftByOne = (n: number) => (n === 0 ? 6 : n - 1);

const useCreateShiftWithMouse = (dayWidth: number) => {
  const { handleNewShift, currentShift, ActiveShiftProps } =
    useContext(CalendarContext);

  function pauseEvent(e: any) {
    if (e.stopPropagation) e.stopPropagation();
    if (e.preventDefault) e.preventDefault();
    e.cancelBubble = true;
    e.returnValue = false;
    return false;
  }

  const fn = useMemo(() => {
    if (!!currentShift || !!ActiveShiftProps?.activeShift) {
      return {
        td: (date: string, time: string) => ({}),
        table: () => ({
          onMouseDown: pauseEvent,
          onMouseMove: pauseEvent,
          onMouseUp: pauseEvent,
        }),
      };
    }
    return {
      td: (date: string, time: string) => ({
        onMouseDown: (e: any) => {
          pauseEvent(e);
          (window as any).dragStart = `${date}T${time}`;

          const dragEl = document.getElementById("dragEl");

          if (!dragEl) return;

          dragEl.style.left =
            dayWidth * (shiftByOne(new Date(date).getDay()) - 1) + 50 + "px";

          dragEl.style.transform = `translateX(${dayWidth}px)`;
          dragEl.style.display = "none";
        },
        onMouseMove: (e: any) => {
          pauseEvent(e);
          let dragStart = (window as any).dragStart;

          if (!dragStart) {
            // console.countReset('onMouseMove');
            return;
          }

          let [prevDate, prevTime] = dragStart.split("T");

          let dragEl = document.getElementById("dragEl");

          if (!dragEl) return;

          if (date === prevDate && prevTime !== time) {
            if (prevTime < time) {
              const top = 87 + (timeToBlock(prevTime) - 16) * 8;
              const height = 87 + (timeToBlock(time) - 16) * 8 - top;

              dragEl.style.height = height + "px";
              dragEl.style.top = top + "px";
              dragEl.style.display = "block";
            } else {
              const top = 87 + (timeToBlock(time) - 16) * 8;
              const height = 87 + (timeToBlock(prevTime) - 16) * 8 - top;

              dragEl.style.height = height + "px";
              dragEl.style.top = top + "px";
              dragEl.style.display = "block";
            }

            (window as any).dragEnd = `${date}T${time}`;
          } else {
            dragEl.style.height = "0px";
            dragEl.style.display = "none";
            (window as any).dragEnd = null;
          }
        },
        // onTouchStart: () => {
        //   console.log('onTouchStart', date, time);
        //   console.count('onTouchStart');
        // },
        // onTouchMove: () => {
        //   console.log('onTouchMove', date, time);
        //   console.count('onTouchMove');
        // },
        // onTouchEnd: () => {
        //   console.log('onTouchEnd', date, time);
        //   console.count('onTouchEnd');
        // },
      }),
      table: () => ({
        onMouseUp: () => {
          let start = (window as any).dragStart;
          let end = (window as any).dragEnd;
          (window as any).dragStart = null;
          (window as any).dragEnd = null;

          if (start && end) {
            let [date, x1] = start.split("T");
            let [endDate, x2] = end.split("T");

            if (date === endDate) {
              let from = x1 < x2 ? x1 : x2,
                till = x1 > x2 ? x1 : x2;

              handleNewShift(date, from, till);
            }
          }

          let dragEl = document.getElementById("dragEl");
          if (!dragEl) return;

          dragEl.style.height = "0px";
          dragEl.style.display = "none";
        },
      }),
    };
  }, [currentShift, ActiveShiftProps, dayWidth]);

  return fn;
};

const Week = ({
  days,
  currentShift,
  handleShiftClick,
  activeShift,
}: Week2Props) => {
  const ref = useRef<HTMLTableDataCellElement | null>(null);
  const [dayWidth, setdayWidth] = useState(96);
  const currentTime = dayjs();

  const {
    setCurrentShift,
    setCurrentMultipleShifts,
    selectedMultiCard,
    setSelectedMultiCard,
  } = useContext(CalendarContext);

  const timeIndicator = {
    top: 84 + currentTime.hour() * 32 + currentTime.minute() * 0.53 - 4 * 32,
    left: dayWidth * shiftByOne(currentTime.day()) + 41,
  };

  const resize = useResizeDetector();

  const date = dateHelper();

  const shifts = useMemo(() => {
    return days
      .reduce(
        (acc: CalendarShift[], cV) => [
          ...acc,
          ...cV.shifts
            .filter((s) => !s.allDay && !(s.start === 0 && s.close === 96))
            .map((s) => ({ ...s, date: cV.date } as CalendarShift)),
        ],
        []
      )
      .sort((a, b) => a.start - b.start);
  }, [days]);

  useEffect(() => {
    const el: any = document.getElementsByClassName("td-reference")[0];
    setdayWidth(!!el ? el.offsetWidth : 96);
  }, [ref.current, resize.width, currentShift]);

  // let currentShiftId = `${currentShift?.shiftId}-${currentShift?.date}`;

  const currentWeek = !!days.find((d) => d.date === date);

  const listeners = useCreateShiftWithMouse(dayWidth);

  const seperatedShifts = useShiftSeperator(shifts, dayWidth);

  const shiftZIndex = useCallback(
    (id: string, date: string) => {
      if (activeShift) {
        return activeShift?.shift?.id === id ? 100 : 15;
      } else if (currentShift) {
        return currentShift?.shiftId === id && currentShift?.date === date
          ? 100
          : 15;
      }

      return 15;
    },
    [activeShift, currentShift]
  );

  return (
    <Box className="calendar-week">
      <table ref={resize.ref} {...listeners.table()}>
        <thead>
          <th className="blank"></th>
          {days.map((day) => (
            <th key={day.date} style={{ maxWidth: dayWidth }}>
              <DayHeader
                width={dayWidth}
                activeShift={activeShift}
                {...day}
                today={date === day.date}
                onShiftClick={(id) => handleShiftClick(day.date, id)}
                hasDeleted={days.some((d) => d?.deleted?.length)}
                selected={
                  day.date === currentShift?.date
                    ? currentShift?.shiftId
                    : undefined
                }
              />
            </th>
          ))}
        </thead>
        {hoursPerDay.map((hr, i) => (
          <tr key={i}>
            <th className="time-header">
              {
                <Typography variant="text-4" color="subdued" translation={null}>
                  {hr}
                </Typography>
              }
            </th>
            {days.map((day) => (
              <td
                {...listeners.td(day.date, hr)}
                key={`${i}-${day.date}`}
                className="td-reference"
                ref={(r) => {
                  ref.current = r;
                }}
              />
            ))}
          </tr>
        ))}
        {seperatedShifts.map(
          ({ multiple, position, shift, otherShifts = [] }) => (
            <div
              key={shift.id + "-" + shift.date}
              className="shift-wrapper"
              id={shift.id}
              style={{
                top: Math.max(position.top, 90),
                left: position.left,
                zIndex: shiftZIndex(shift.id, shift.date),
                padding: 0,
              }}
            >
              {!multiple ? (
                <Shift
                  shift={shift}
                  activeShift={activeShift}
                  top={position.top}
                  selected={
                    (currentShift?.shiftId === shift.id &&
                      currentShift?.date === shift.date) ||
                    (activeShift?.shift?.id === shift.id &&
                      activeShift?.date === shift.date)
                  }
                  setSelected={() => {
                    handleShiftClick(shift.date, shift.id);
                    setCurrentMultipleShifts(null);
                    setSelectedMultiCard(null);
                  }}
                  width={position.width}
                  isOverlapping={position.width < dayWidth}
                />
              ) : (
                <MultipleShiftsCard
                  id={shift.date}
                  shift={shift}
                  overlappingShifts={otherShifts}
                  top={position.top}
                  selected={selectedMultiCard === shift.date}
                  setSelected={(s) => {
                    handleShiftClick(s.date, s.id);
                    setCurrentMultipleShifts(null);
                  }}
                  onShiftPreviewClick={({ date, id }) => {
                    handleShiftClick(date, id);
                    setCurrentMultipleShifts(null);
                    setSelectedMultiCard(null);
                  }}
                  width={dayWidth - 2}
                  handleCardClick={() => {
                    // ActiveShiftProps?.setactiveShift(null);
                    setSelectedMultiCard(shift.date);
                    setCurrentMultipleShifts(otherShifts);
                    setCurrentShift(null);
                  }}
                />
              )}
            </div>
          )
        )}

        <div
          id="dragEl"
          style={{
            zIndex: 101,
            display: "none",
            background: "rgb(16, 204, 185)",
            width: dayWidth,
            touchAction: "none",
          }}
          onMouseEnter={(e) => e.preventDefault()}
          className="shift-wrapper"
        />

        {currentWeek && timeIndicator.top > 150 && (
          <CurrentTimeIndicator
            width={dayWidth + 2}
            top={timeIndicator.top}
            left={timeIndicator.left}
          />
        )}
      </table>
    </Box>
  );
};

export default Week;
