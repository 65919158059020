import React from "react";
import Box from "Components/Atoms/Box";

export type OverlapProps = {
  position: {
    top: number;
    left: number;
    width: number;
    height: number;
    opacity?: number;
  };
};

const Overlap = ({ position }: OverlapProps) => {
  return (
    <Box
      className="overlap"
      style={{ ...position, backgroundImage: `url("/warning.svg")` }}
    ></Box>
  );
};

export default Overlap;
