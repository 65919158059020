import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from 'Contexts/AuthContext';
import { RestaurantContext } from 'Contexts/RestaurantContext';
import Dashboard from './Dashboard';
import { loadForecast } from 'api/Dashboard';
import { Forecast, Restaurant, User, UserData } from 'utils/types';
import { ReservationContext } from 'Contexts/ReservationContext';
import { dateHelper } from 'utils/helper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MobileHomepage from 'App/Mobile/Screens/Homepage/MobileHomepage';
import MobileNavBar from 'Components/Organisms/MobileNavBar';
import BottomNavBar from 'App/Mobile/Components/BottomNavBar';
import Box from 'Components/Atoms/Box';
import Intercom from '@intercom/messenger-js-sdk';

export const IntercomWrapper: React.FC<{
  data: UserData;
  uid: string;
}> = ({ data, uid }) => {
  Intercom({
    app_id: 'fec3kyqq',
    user_id: uid, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
    name: data.displayName || '', // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
    email: data.email || '', // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
    // created_at: data., // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
  });

  console.log('Intercom initialized');

  return <></>;
};

export const IntercomWrapperRestaurant: React.FC<{
  restaurant: { name: string; email: string; id: string };
  user: { name: string; email: string; id: string };
}> = ({ restaurant, user }) => {
  const name = [restaurant.name, user.name].filter(Boolean).join(' - ');

  Intercom({
    app_id: 'fec3kyqq',
    user_id: restaurant.id + '_' + user.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
    name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
    email: restaurant.email || '', // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
    // created_at: data., // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
  });

  console.log('Intercom initialized');

  return <></>;
};

const DashboardContainer = () => {
  const { user } = useContext(AuthContext);

  const { restaurantId } = useContext(RestaurantContext);

  const { date, setdate } = useContext(ReservationContext);

  const [update, setupdate] = useState(0);

  const [forecast, setforecast] = useState<Forecast | null>(null);

  useEffect(() => {
    let isMounted = true;
    const getForecast = async () => {
      if (isMounted) {
        let answer: Forecast | null = null;
        if (!!restaurantId) {
          answer = await loadForecast(restaurantId).catch(() => null);
        }

        console.log({ restaurantId, answer });

        setforecast(answer);
      }
    };

    getForecast();

    return () => {
      isMounted = false;
    };
  }, [restaurantId, update]);

  useEffect(() => {
    if (date !== dateHelper()) setdate(dateHelper());
  }, [restaurantId, date, setdate]);

  const isMobile = useMediaQuery('(max-width: 450px)');

  if (isMobile)
    return (
      <Box>
        <MobileNavBar user={user?.data || null} />
        <MobileHomepage
          forecast={forecast}
          update={() => setupdate((x) => x + 1)}
        />
        <BottomNavBar />
      </Box>
    );

  return (
    <>
      <Dashboard
        user={user?.data || null}
        forecast={forecast}
        update={() => setupdate((x) => x + 1)}
      />
    </>
  );
};

export default DashboardContainer;
