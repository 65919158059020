import { IconButton } from '@material-ui/core';
import {
  CheckCircle,
  Close,
  MoreVert,
  Notes,
  Pause,
  PhoneMissed,
  PlayArrow,
} from '@material-ui/icons';
import Box from 'Components/Atoms/Box';
import MenuItem from 'Components/Atoms/MenuItem';
import Popover from 'Components/Atoms/Popover';
import Typography from 'Components/Atoms/Typography';
import { Severity } from 'Contexts/RestaurantContext';
import useRestaurant from 'CustomHooks/useRestaurant';
import useToast from 'CustomHooks/useToast';
import React, { useEffect, useState } from 'react';
import { db } from 'utils/firebase';
import { toEuropeanDate } from 'utils/helper';
import { CallCard } from '../SiaCurrentDay';
import AudioWave from './AudioWave';

function padNumber(num: number) {
  return num < 10 ? '0' + num : String(num);
}

export function durationToMin(dur: number) {
  let min = Math.floor(dur / 60000);
  let sec = Math.round((dur % 60000) / 1000);

  return padNumber(min) + ':' + padNumber(sec);
}

const CallCardComponent: React.FC<
  CallCard & {
    onDashboard?: boolean;
    example?: boolean;
    style?: React.CSSProperties;
  }
> = ({ data, type, onDashboard, example, style = {}, ...meta }) => {
  const [playing, setplaying] = useState(false);
  const [transcriptAnchor, settranscriptAnchor] = useState<any>();
  const [moreAnchor, setmoreAnchor] = useState<any>();
  const [progress, setprogress] = useState(0);

  const { reservationSettings } = useRestaurant();
  const toast = useToast();

  const handlePlayed = (ms = 60000) => {
    if (!data?.mailbox?.played) {
      setTimeout(
        () =>
          db
            .collection(`calls`)
            .doc(meta.id)
            .update({ 'mailbox.played': true }),
        ms
      );
    }
  };

  const handleTakenCareOf = (takenCareOf = true) => {
    if (type === 'mailbox') {
      db.collection(`calls`)
        .doc(meta.id)
        .update({ hasMailbox: false, takenCareOf });
      toast(
        'Die Mailbox wurde aus dieser Anssicht entfernt. Ist aber weiterhin in SIA verfügbar',
        Severity.INFO,
        'common',
        () => {
          db.collection(`calls`)
            .doc(meta.id)
            .update({ hasMailbox: true, takenCareOf: false });
        }
      );
    } else {
      db.collection(`calls`).doc(meta.id).update({ takenCareOf });
    }
  };

  useEffect(() => {
    if (type === 'mailbox' && data.mailbox?.recordingUrl) {
      let audioElement = document.createElement('audio');
      audioElement.src = data.mailbox?.recordingUrl;
      audioElement.loop = false;
      document.body.appendChild(audioElement);

      audioElement.addEventListener('timeupdate', (e: any) => {
        if (e.srcElement.duration === e.srcElement.currentTime) {
          (document as any).playing = false;
          setplaying(false);
          setprogress(0);
        } else {
          setprogress(e.srcElement.currentTime / e.srcElement.duration);
        }
      });

      audioElement.addEventListener(
        'canplay',
        (...x) => ((document as any).canPlay = true)
      );

      async function fn() {
        // if (interval) {
        //   clearInterval(interval);
        //   interval = null;
        // }
        if (!(document as any).canPlay) return;
        if ((document as any).playing) {
          audioElement.pause();

          (document as any).playing = false;
          setplaying(false);
        } else {
          try {
            await audioElement.play();

            (document as any).playing = true;
            // interval = setInterval(() => setprogress((x) => x + 1), 1000);
            setplaying(true);
            handlePlayed((data.mailbox?.duration ?? 60) * 1000);
          } catch (error) {
            console.error(error);
          }
        }
      }

      document.getElementById(`play-${meta.id}`)?.addEventListener('click', fn);
      return () => {
        document
          .getElementById(`play-${meta.id}`)
          ?.removeEventListener('click', fn);

        audioElement.remove();
      };
    }
  }, [data.mailbox?.recordingUrl]);

  return (
    <Box
      className={!example ? 'mg-bt-lg' : 'mg-bt-sm'}
      style={
        example
          ? {
              padding: 12,
              borderRadius: 8,
              background: 'rgba(99, 105, 209, 0.08)',
              marginBottom: 0,
              ...style,
            }
          : style
      }
    >
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 4,
        }}
      >
        <Typography
          variant={example ? 'text-3' : 'text-2'}
          weight="medium"
          color="secondary"
          style={{ width: '100%' }}
        >
          {meta.caller}
          {example && ' - Beispiel - Nicht anrufen'}
        </Typography>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: 8,
          }}
        >
          {type !== 'reservation' && (
            <IconButton
              size="small"
              onClick={() => !example && handleTakenCareOf(!meta.takenCareOf)}
            >
              <CheckCircle color={meta.takenCareOf ? 'primary' : 'disabled'} />
            </IconButton>
          )}
          <IconButton size="small" onClick={(e) => setmoreAnchor(e.target)}>
            <MoreVert />
          </IconButton>
          {/* {type === 'mailbox' && onDashboard && (
            <IconButton size="small" onClick={() => handleRemove()}>
              <Close />
            </IconButton>
          )} */}
          <Popover
            open={!!moreAnchor}
            onClose={() => setmoreAnchor(null)}
            anchorEl={moreAnchor}
            style={{ width: 200 }}
          >
            <a
              target="__blank"
              href={`tel:${data.callerId}`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <MenuItem>Anrufen</MenuItem>
            </a>
            {type === 'mailbox' && !!data.mailbox && (
              <a
                onClick={() => {
                  navigator.clipboard.writeText(
                    data?.mailbox?.optimizedTranscription ||
                      data?.mailbox?.transcription ||
                      'Nichts erkannt'
                  );
                  toast('Nachricht kopiert', Severity.SUCCESS, 'common');
                }}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <MenuItem>Nachricht kopieren</MenuItem>
              </a>
            )}
          </Popover>
        </Box>
      </Box>

      {type === 'mailbox' && !!data.mailbox && (
        <Box>
          <Typography
            variant={example ? 'text-3' : 'text-2'}
            weight="medium"
            style={{ marginBottom: 8 }}
            component="p"
          >
            "
            {data.mailbox.optimizedTranscription ||
              data.mailbox.transcription ||
              'Nichts erkannt'}
            "
          </Typography>

          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              gap: 8,
              alignItems: 'center',
              marginBottom: 8,
            }}
          >
            <div
              className="center"
              id={`play-${meta.id}`}
              style={{
                width: 40,
                height: 40,
                background: data.mailbox?.played
                  ? 'var(--color-secondary)'
                  : 'var(--color-primary)',
                borderRadius: 20,
                cursor: 'pointer',
              }}
            >
              {!playing ? (
                <PlayArrow htmlColor="#ffffff" />
              ) : (
                <Pause htmlColor="#ffffff" />
              )}
            </div>
            <AudioWave
              url={
                (data.mailbox as any).twilioRecordingUrl ??
                data.mailbox.recordingUrl
              }
              callSid={data.callSid}
              preLoadedAudioWave={(data.mailbox as any)?.audioWave}
              progress={progress}
            />
            <Typography variant="text-3">
              {durationToMin(data.mailbox.duration * 1000)}
            </Typography>
            <IconButton
              size="small"
              onClick={(e) => settranscriptAnchor(e.target)}
            >
              <Notes />
            </IconButton>
            <Popover
              open={!!transcriptAnchor && !!data.mailbox.transcription}
              anchorEl={transcriptAnchor}
              onClose={() => settranscriptAnchor(null)}
              style={{ maxWidth: '240px' }}
            >
              <Typography variant="text-3" weight="bold">
                Transkription:
              </Typography>
              <Typography variant="text-3" color="subdued">
                {data.mailbox.transcription}
              </Typography>
            </Popover>
          </Box>
        </Box>
      )}

      {type === 'reservation' && (
        <Box
          style={{
            padding: '8px 10px',
            boxShadow:
              '0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2)',
            borderRadius: 6,
            background: 'var(--color-paper)',
            marginBottom: 4,
          }}
        >
          <Typography
            variant="text-4"
            color="secondary"
            style={{ width: '100%' }}
          >
            Erfolgreiche Reservierung
          </Typography>
          <Typography variant="h4" style={{ width: '100%' }}>
            {data.guest.name} am {toEuropeanDate(data.date)} um {data.time}
          </Typography>
          <Typography
            variant="text-3"
            color="subdued"
            style={{ width: '100%', margin: '4px 0px' }}
          >
            {(data as any).occassion
              ? reservationSettings?.occassions?.find(
                  (o) => o.id === (data as any).occassion
                )?.title ?? (data as any).occassion
              : ''}{' '}
            - {data.guests} PAX
          </Typography>
        </Box>
      )}

      {type === 'redirectFailed' && (
        <Box
          style={{
            padding: '8px 10px',
            boxShadow:
              '0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2)',
            borderRadius: 6,
            background: 'var(--color-paper)',
            marginBottom: 4,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            textDecoration: 'none',
          }}
          href={`tel:${data.callerId}`}
        >
          <Box>
            <Typography
              variant="text-4"
              color="error"
              style={{ width: '100%' }}
            >
              Verpasste Weiterleitung
            </Typography>
            <Typography variant="h4" style={{ width: '100%' }}>
              {data.guest?.name ?? 'Unbekannt'}
            </Typography>
            <Typography
              variant="text-3"
              color="subdued"
              style={{ width: '100%', margin: '4px 0px' }}
            >
              {data.callerId}
            </Typography>
          </Box>
          <PhoneMissed
            color="error"
            fontSize="large"
            style={{ marginRight: 12 }}
          />
        </Box>
      )}

      <Box>
        <Typography variant="text-3">
          {meta.ts} - {durationToMin(meta.duration)}
        </Typography>
      </Box>
    </Box>
  );
};

export default CallCardComponent;
