import Typography, {
  TypographyProps,
  Colors,
} from 'Components/Atoms/Typography';
import ButtonBase, { ButtonBaseProps } from '@material-ui/core/ButtonBase';
import React, { useContext } from 'react';
import { classHelper } from 'utils/helper';
import './styles.scss';
import { StylesProvider } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router';
import { Translations, Modify } from 'utils/types';
import { RestaurantContext } from 'Contexts/RestaurantContext';
import { TEST_TRANSLATIONS } from 'Contexts/LanguageContext';

type IconFunction = {
  (params: object): React.ReactNode;
};

export type ButtonVariants =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'transparent'
  | 'outlined';

type LinkTo = {
  (rId: string): string;
};

export type ButtonProps = Modify<
  ButtonBaseProps,
  {
    className?: string;
    variant?: ButtonVariants;
    endIcon?: null | IconFunction;
    linkTo?: LinkTo | string;
    startIcon?: null | IconFunction;
    disabled?: boolean;
    onClick?: React.ReactEventHandler;
    fullWidth?: boolean;
    children?: React.ReactNode;
    translation?: Translations;
    flat?: boolean;
    loading?: boolean;
    typographyProps?: TypographyProps;
    color?: Colors;
    href?: string;
    target?: string;
  }
>;

const iconParams = {
  fontSize: 'inherit',
  color: 'inherit',
};

const Button = ({
  variant = 'default',
  linkTo,
  children,
  fullWidth = false,
  startIcon = null,
  endIcon = null,
  className = '',
  disabled = false,
  flat = false,
  loading = false,
  typographyProps = {},
  color = 'textPrimary',
  translation = 'common',
  style,
  ...props
}: ButtonProps) => {
  const classNames = classHelper([
    'btn',
    `btn-${variant}`,
    startIcon || endIcon ? 'btn-with-icon' : '',
    fullWidth ? 'btn-full-width' : '',
    flat ? 'btn-flat' : '',
    className,
    color && !disabled ? `color-${color}` : '',
    loading ? 'btn-loading' : '',
    disabled && !loading ? 'btn-disabled' : '',
  ]);

  const history = useHistory();

  const { restaurantId } = useContext(RestaurantContext);

  const onClick = linkTo
    ? () =>
        history?.push(
          typeof linkTo === 'string' ? linkTo : linkTo(restaurantId || '')
        )
    : props.onClick;

  return (
    <ButtonBase
      {...props}
      style={{
        padding: '0.5rem 1rem',
        borderRadius: '0.25rem',
        ...(style ?? {}),
      }}
      className={classNames}
      disabled={disabled}
      onClick={onClick}
    >
      <span className="btn-inner">
        {!!startIcon &&
          startIcon({ ...iconParams, className: 'btn-start-icon' })}
        <Typography
          variant="button"
          color={disabled ? 'disabled' : color}
          {...typographyProps}
          translation={translation}
        >
          {children}
        </Typography>
        {!!endIcon && endIcon({ ...iconParams, className: 'btn-end-icon' })}
      </span>
      {loading && <CircularProgress color="inherit" size={16} />}
    </ButtonBase>
  );
};

export default Button;
