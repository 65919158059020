import React, { useContext, useMemo } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import { WaitingListEntryDocument } from 'gastronaut-shared/types/documents';
import Typography from 'Components/Atoms/Typography';
import {
  CancelOutlined,
  CheckOutlined,
  PermPhoneMsgRounded,
} from '@material-ui/icons';
import { blockToTime, dateHelper } from 'utils/helper';
import useRestaurant from 'CustomHooks/useRestaurant';
import { RequestStatus } from 'gastronaut-shared/types/helper/reservations';
import { ReservationContext } from 'Contexts/ReservationContext';
import IconButton from 'Components/Atoms/IconButton';
import { WaitingListActionType } from 'CustomHooks/useWaitinglist';
import FindTable from 'Components/Atoms/Icons/FindTable';

export type WaitinglistCardProps = {
  entry: WaitingListEntryDocument & { index: number };
  active?: boolean;
  onClick?: () => void;
  hideOccassion: boolean;
  openEntryDetails?: (
    entry: WaitingListEntryDocument & { index: number }
  ) => void;
  mobile?: boolean;
};

const ActionButtons: React.FC<{
  status: WaitingListEntryDocument['status'];
  inPast: boolean;
  id: string;
}> = ({ status, id, inPast }) => {
  const { handleWaitinglistAction } = useContext(ReservationContext);

  if (
    status === RequestStatus.CANCELED ||
    status === RequestStatus.FAILED ||
    status === RequestStatus.SUCCESS ||
    inPast
  ) {
    return (
      <Box flex style={{ justifyContent: 'flex-end', margin: '4px 0px' }}></Box>
    );
  } else if (status === RequestStatus.PENDING) {
    return (
      <Box flex style={{ justifyContent: 'flex-end', margin: '4px 0px' }}>
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            handleWaitinglistAction(id, WaitingListActionType.CANCEL);
          }}
          style={{ marginRight: 8 }}
        >
          <CancelOutlined className="text-subdued" />
        </IconButton>
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            handleWaitinglistAction(id, WaitingListActionType.FIND_TABLE);
          }}
        >
          <FindTable
            className="text-subdued"
            style={{ width: 28, height: 28 }}
          />
        </IconButton>
      </Box>
    );
  } else if (status === RequestStatus.WAITING) {
    return (
      <Box flex style={{ justifyContent: 'flex-end', margin: '4px 0px' }}>
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            handleWaitinglistAction(id, WaitingListActionType.CANCEL);
          }}
          style={{ marginRight: 12 }}
        >
          <CancelOutlined className="text-subdued" />
        </IconButton>
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            handleWaitinglistAction(id, WaitingListActionType.ACCEPT);
          }}
        >
          <CheckOutlined className="text-subdued" />
        </IconButton>
      </Box>
    );
  }

  return <></>;
};

const WaitinglistCard: React.FC<WaitinglistCardProps> = ({
  entry,
  active,
  onClick,
  openEntryDetails,
  hideOccassion,
  mobile,
}) => {
  const { guest, time, guests, occassion, id, status } = entry;

  const { reservationSettings } = useRestaurant();

  const occassionString =
    reservationSettings?.occassions?.find((o) => o.id === occassion)?.title ||
    occassion;

  const nameStr = `${guest.name} ${
    guest.company ? `(${guest.company})` : ''
  }`.slice(0, 24);

  return (
    <Box
      underline
      id={id}
      elevation={0}
      className={`waitinglist-card waitinglist-${status} ${
        active ? 'active' : ''
      }`}
      onClick={onClick}
    >
      <Box className="space-between">
        <Typography
          variant="text-4"
          block
          className={'text-subdued'}
          style={{ marginTop: 4 }}
        >
          {/* isAnotherDay ? toEuropeanDate(date) + ' - ' : '' */}
          {time}
          {entry.flexibleTime
            ? ` (${entry.flexibleTime.earliest} - ${entry.flexibleTime.latest})`
            : ''}{' '}
          • {guests} Pax
        </Typography>
      </Box>
      <Box className="space-between" style={{ minHeight: 36 }}>
        <Typography
          variant="text-3"
          weight="bold"
          block
          style={{
            marginTop: 3,
            whiteSpace: nameStr.length < 20 ? 'nowrap' : 'normal',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {nameStr} {nameStr.length === 24 ? '... ' : ' '}
          {entry.source === 'phone' && (
            <PermPhoneMsgRounded fontSize="inherit" style={{ marginLeft: 4 }} />
          )}
        </Typography>
        {!mobile && (
          <ActionButtons
            {...{ status, id, inPast: entry.date < dateHelper() }}
          />
        )}
      </Box>

      {reservationSettings?.showCommentInResaCard && (
        <Box style={{ marginBottom: 4 }}>
          {!!entry.guest.comment && (
            <Box>
              <Typography variant="text-4" block weight="bold">
                Kommentar
              </Typography>
              <Typography variant="text-4" block>
                {entry.guest.comment}
              </Typography>
            </Box>
          )}
          {!!entry.guest.hostComment && (
            <Box>
              <Typography variant="text-4" block weight="bold">
                Service Kommentar
              </Typography>
              <Typography variant="text-4" block>
                {entry.guest.hostComment}
              </Typography>
            </Box>
          )}
        </Box>
      )}

      <Box className="space-between flex-end">
        <Box style={{ paddingBottom: 4 }} flex>
          {!hideOccassion && (
            <Typography
              variant="text-4"
              className={'text-subdued'}
              style={{ marginRight: 3 }}
            >
              {occassionString.slice(0, 25)} •
            </Typography>
          )}
          <Typography
            variant="text-4"
            className={'text-subdued'}
            translation="reservations"
          >
            Anfrage
          </Typography>
        </Box>

        <Typography
          variant="text-5"
          style={{
            color: 'var(--color-tertiary)',
          }}
        >
          {entry.index !== undefined && entry.index + 1 + '.'}
        </Typography>
      </Box>
    </Box>
  );
};

export default WaitinglistCard;
