import { History } from 'App/ReservationBook/Components/ReservationSideBar';
import React, { useEffect, useState } from 'react';
import { Guest, Reservation } from 'types/reservations';
import { FieldValue, db } from 'utils/firebase';
import useAuth from './useAuth';

const useReservationSideBar = (
  currentReservation: string | null,
  reservations: Reservation[]
) => {
  const [reservation, setreservation] = useState<null | Reservation>(null);
  const [guest, setguest] = useState<null | Guest>(null);
  const [history, sethistory] = useState<null | History[]>(null);

  const { uid } = useAuth();

  useEffect(() => {
    if (currentReservation) {
      let res = reservations.find((r) => r.id === currentReservation);
      setreservation(res || null);

      if (guest?.guestId !== res?.guest.id) {
        setguest(null);
        sethistory(null);
      }
    } else {
      sethistory(null);
      setreservation(null);
      setguest(null);
    }

    return () => {
      sethistory(null);
      setreservation(null);
    };
  }, [currentReservation, reservations]);

  const loadGuest = async () => {
    if (reservation?.guest?.id && !guest) {
      try {
        const ref = db
          .collection('relationships')
          .doc(
            `${reservation.guest.id.replace(
              `-${reservation.restaurant}`,
              ''
            )}-${reservation.restaurant}`
          );

        const doc = await ref.get();

        if (!!doc.exists) {
          let data = doc.data() as Guest;

          if (
            !!data.guestComment?.trim() &&
            !reservation?.guest?.guestComment?.trim()
          ) {
            const updatedAt = Date.now();

            db.collection('requestsV03')
              .doc(reservation.id)
              .update({
                'guest.guestComment': data.guestComment,
                updatedAt,
                updatedBy: 'system',
                updateNote: {
                  updatedAt,
                  note: 'Gast wurde synchronisiert',
                },
              });

            setreservation((r) =>
              !!r
                ? {
                    ...r,
                    guest: { ...r.guest, guestComment: data.guestComment },
                  }
                : null
            );
          }
          setguest(data);
          return;
        }
      } catch (error: any) {
        console.error(error.message);
      }
    }

    setguest(null);
    return;
  };

  const loadHistory = async () => {
    if (reservation) {
      try {
        const ref = db.collection(`requestsV03/${reservation.id}/history`);

        const docs = await ref.get();

        let arr: History[] = [];

        if (reservation.fromWaitinglist) {
          arr.push({
            changes: [
              {
                title: 'Eintrag auf Warteliste',
                key: 'createdAt',
                after: reservation.createdAt,
                before: null,
              },
            ],
            id: reservation.createdAt ?? '',
            updatedBy: {
              uid: reservation.createdBy ?? '',
              name:
                reservation.createdBy && reservation.createdBy.length < 8
                  ? reservation.createdBy
                  : 'Service',
            },
          });
        }

        if (
          !arr.length &&
          (!docs.size || reservation.walkIn || reservation.source === 'phone')
        ) {
          arr.push({
            changes: [
              {
                title: 'Erstellt',
                key: 'createdAt',
                after: reservation.createdAt,
                before: null,
              },
            ],
            id: reservation.createdAt ?? '',
            updatedBy: {
              uid: reservation.createdBy ?? '',
              name:
                reservation.createdBy && reservation.createdBy.length < 8
                  ? reservation.createdBy
                  : 'Service',
            },
          });
        }

        docs.forEach((doc) => {
          let data = { ...doc.data(), id: doc.id };
          arr.push(data as History);
        });

        sethistory(arr);
      } catch (error: any) {
        console.error(error.message);
      }
    } else {
      sethistory(null);
    }

    return;
  };

  const onGuestAction = (guestId: string, type: string, payload?: any) => {
    if (!reservation) return;

    console.log('onGuestAction');

    let GUEST_ID = guestId.replace(`-${reservation.restaurant}`, '');

    const guestRef = db
      .collection('relationships')
      .doc(`${GUEST_ID}-${reservation.restaurant}`);
    const reservationRef = db.collection('requestsV03').doc(reservation.id);

    let id = type.split('.')[1] ?? type;

    let update = {
      [type]: payload,
      updatedAt: Date.now(),
      updatedBy: uid,
    };

    let phoneNumbers = guest?.phoneNumbers ?? [];

    if (type === 'guest.phoneNumbersAdd') {
      delete update[type];

      update['guest.phoneNumbers'] = FieldValue.arrayUnion('');

      let updateNote: { updatedAt: number; note: string } = {
        updatedAt: Date.now(),
        note: 'Neue Alternative Telefonnummer hinzugefügt',
      };

      phoneNumbers.push('');

      update.updateNote = updateNote;

      guestRef.update({ ['phoneNumbers']: FieldValue.arrayUnion('') });
    } else if (type.includes('guest.phoneNumbers[')) {
      let index = parseInt(type.split('[')[1].split(']')[0]);

      delete update[type];

      if (!!payload) {
        update['guest.phoneNumbers'] =
          guest?.phoneNumbers?.map((x, i) => (i == index ? payload : x)) ?? [];

        phoneNumbers[index] = payload;

        let updateNote: { updatedAt: number; note: string } = {
          updatedAt: Date.now(),
          note: 'Alternative Telefonnummer bearbeitet',
        };

        update.updateNote = updateNote;

        guestRef.update({
          ['phoneNumbers']:
            guest?.phoneNumbers?.map((x, i) => (i == index ? payload : x)) ??
            [],
        });
      } else {
        update['guest.phoneNumbers'] =
          guest?.phoneNumbers?.filter((_, i) => i !== index) ?? [];

        let updateNote: { updatedAt: number; note: string } = {
          updatedAt: Date.now(),
          note: 'Alternative Telefonnummer entfernt',
        };

        phoneNumbers = phoneNumbers.filter((_, i) => i !== index);

        update.updateNote = updateNote;

        guestRef.update({
          ['phoneNumbers']:
            guest?.phoneNumbers?.filter((_, i) => i !== index) ?? [],
        });
      }
    }

    if (type === 'guest.sendEmail') {
      let updateNote: { updatedAt: number; note: string } = {
        updatedAt: Date.now(),
        note: !!payload
          ? 'Sende Emails an Gast eingeschaltet'
          : 'Sende Emails an Gast ausgeschaltet',
      };

      update.updateNote = updateNote;
    }

    reservationRef.update(update);

    console.log('onGuestAction', {
      id,
      payload,
      GUEST_ID,
      type,
    });

    if (
      GUEST_ID &&
      type !== 'guest.sendEmail' &&
      !type.includes('phoneNumbers')
    ) {
      guestRef.update({ [id]: payload }).then((x) =>
        console.log('onGuestAction', 'Guest updated', {
          id,
          payload,
          GUEST_ID,
          type,
          x,
        })
      );
    }
    setguest((g) => (!!g ? { ...g, [id]: payload, phoneNumbers } : null));
  };

  return {
    reservation,
    guest,
    loadGuest, // Trigger this when tab changes to contact
    onGuestAction,
    loadHistory, // Trigger this when tab changes to history
    history, // loading or not loaded jet when null
  };
};

export default useReservationSideBar;
