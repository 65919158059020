import React, { useCallback, useMemo, useState } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import Modal from 'Components/Molecules/Modal';
import { IconButton } from '@material-ui/core';
import { PrintRounded } from 'Components/Atoms/Icons';
import Typography from 'Components/Atoms/Typography';
import RadioButtonGroup from 'Components/Atoms/RadioButtonGroup';
import Divider from 'Components/Atoms/Divider';
import useRestaurant from 'CustomHooks/useRestaurant';
import Checkbox from 'Components/Atoms/Checkbox';
import TextField from 'Components/Atoms/TextField';
import usePinAuth from 'CustomHooks/usePinAuth';
import { getCalendarWeek, getCalendarWeekArray } from 'CustomHooks/useCalendar';

export type ReservationPrintModalProps = {
  restaurantId: string;
  date: string;
  lastReservations?: boolean;
};

const ReservationPrintModal: React.FC<ReservationPrintModalProps> = ({
  restaurantId,
  date,
  lastReservations,
}) => {
  const [state, setstate] = useState<null | {
    groupBySpace: boolean;
    sort?: 'time' | 'table';
    occassions?: string[];
    from?: string;
    till?: string;
    week?: boolean;
  }>(null);

  const { reservationSettings } = useRestaurant();

  const printHref = useCallback(
    (date: string, inline?: boolean) => {
      let url = `https://europe-west3-schillingroofbarhd.cloudfunctions.net/apiEndpoint/printReservations/${restaurantId}/${date}.pdf`;

      if (!state) return url;

      if (state.groupBySpace) {
        url += `?groupBySpace=1`;
      }

      if (state.sort && state.sort !== 'time') {
        if (url.includes('?')) {
          url += `&sort=${state.sort}`;
        } else {
          url += `?sort=${state.sort}`;
        }
      }

      state?.occassions?.forEach((occassion) => {
        if (url.includes('?')) {
          url += `&occassions=${occassion}`;
        } else {
          url += `?occassions=${occassion}`;
        }
      });

      if (state.from) {
        if (url.includes('?')) {
          url += `&from=${state.from}`;
        } else {
          url += `?from=${state.from}`;
        }
      }

      if (state.till) {
        if (url.includes('?')) {
          url += `&till=${state.till}`;
        } else {
          url += `?till=${state.till}`;
        }
      }

      if (lastReservations) {
        url += `&lastReservations=1`;
      }

      if (inline) {
        url += `&inline=1`;
      }

      return url;
    },
    [restaurantId, state, lastReservations]
  );

  const { authenticate } = usePinAuth();

  const printWholeWeek = () => {
    const dates = getCalendarWeekArray(
      getCalendarWeek(new Date(date)),
      Number(date.slice(0, 4))
    );

    const urls = dates.map((d) => printHref(d));

    urls.forEach((url) => {
      window.open(url, '_blank');
    });
  };

  const handleClick = () => {
    if (state?.week) {
      printWholeWeek();
    } else {
      window.open(printHref(date, true), '_blank');
    }
    setstate(null);
  };

  return (
    <>
      <IconButton
        size="small"
        onClick={() =>
          authenticate('dashboard.printReservationList.write', () => {
            setstate({
              groupBySpace: false,
              occassions:
                reservationSettings?.occassions?.map((o) => o.id) ?? [],
            });
          })
        }
        aria-label="Print"
      >
        <PrintRounded />
      </IconButton>
      <Modal
        open={!!state}
        onClose={() => setstate(null)}
        maxWidth="sm"
        fullWidth
        title="Print Reservations"
        titleTranslation="reservations"
        actions={[
          {
            id: 'cancel',
            label: 'Cancel',
            onClick: () => setstate(null),
          },
          {
            id: 'print',
            label: 'Print',
            variant: 'primary',
            // href: printHref(date),
            // target: '__blank',
            onClick: () => handleClick(),
            translation: 'reservations',
          },
        ]}
      >
        <Typography
          variant="text-3"
          translation="reservations"
          block
          className="mg-bt-sm"
        >
          Sort By
        </Typography>
        <RadioButtonGroup
          style={{ display: 'flex' }}
          value={state?.sort ?? 'time'}
          isRow
          options={[
            {
              label: 'Sort By Time',
              translation: 'reservations',
              value: 'time',
            },
            {
              label: 'Sort By Tables',
              translation: 'reservations',
              value: 'table',
            },
          ]}
          onChange={(e: any) => {
            setstate((x) => (!x ? null : { ...x, sort: e.target.value }));
          }}
        />
        <Divider />
        {!lastReservations && (
          <Checkbox
            checked={state?.week}
            style={{ marginLeft: -11 }}
            onChange={() =>
              setstate((st) => (!st ? null : { ...st, week: !st.week }))
            }
            label="Exportiere die ganze Woche?"
            translation="reservations"
          />
        )}
        <Divider />
        <Checkbox
          checked={state?.groupBySpace}
          style={{ marginLeft: -11 }}
          onChange={() =>
            setstate((st) =>
              !st ? null : { ...st, groupBySpace: !st.groupBySpace }
            )
          }
          label="Group By Spaces?"
          translation="reservations"
        />
        {!!reservationSettings?.occassions.length && (
          <>
            <Divider style={{ marginBottom: 12 }} />
            <Typography
              variant="text-3"
              block
              className="mg-bt-sm"
              translation="reservations"
            >
              Occassions (if none are selected all will be shown)
            </Typography>
            {reservationSettings.occassions.map((o) => (
              <Checkbox
                key={o.id}
                label={o.title}
                style={{ marginLeft: -11 }}
                checked={!!state?.occassions?.includes(o.id)}
                onChange={(e: any) =>
                  setstate((x) =>
                    !x
                      ? null
                      : {
                          ...x,
                          occassions: (x.occassions ?? []).includes(o.id)
                            ? x.occassions?.filter((x) => x !== o.id)
                            : [...(x.occassions ?? []), o.id],
                        }
                  )
                }
              />
            ))}
          </>
        )}
        <Divider style={{ marginBottom: 12 }} />
        <Typography
          variant="text-3"
          translation="reservations"
          block
          className="mg-bt-sm"
        >
          Filter the Reservations by Time
        </Typography>
        <Box flex style={{ gap: 8 }}>
          <TextField
            fullWidth
            value={state?.from ?? ''}
            label="Von"
            type="time"
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              setstate((st) => (!st ? null : { ...st, from: e.target.value }))
            }
          />
          <TextField
            fullWidth
            label="Bis"
            value={state?.till ?? ''}
            type="time"
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              setstate((st) => (!st ? null : { ...st, till: e.target.value }))
            }
          />
        </Box>
      </Modal>
    </>
  );
};

export default ReservationPrintModal;
