import Typography from 'Components/Atoms/Typography';
import Box from 'Components/Atoms/Box';
import React from 'react';
import { ReservationSnippet } from 'utils/types';
import { attrHelper, dateHelper, toEuropeanDate } from 'utils/helper';
import Divider from 'Components/Atoms/Divider';
import { useRouter } from 'CustomHooks/useRouter';
import { useHistory } from 'react-router-dom';

type ShiftReservationProps = {
  reservation: ReservationSnippet;
  clickable?: boolean;
  restaurantId?: string;
  lightReservation?: boolean;
  hideDivider?: boolean;
  noPadding?: boolean;
};

const ShiftReservation = ({
  reservation,
  clickable,
  restaurantId,
  lightReservation,
  hideDivider = false,
  noPadding = false,
}: ShiftReservationProps) => {
  const history = useHistory();

  return (
    <>
      <Box
        padding={noPadding ? undefined : 'md'}
        onClick={() =>
          clickable &&
          history.push(
            `/${restaurantId}/reservations/${
              reservation?.date ?? dateHelper()
            }/tablePlan?reservationId=${reservation.id}`
          )
        }
      >
        <Box flex className="mg-bt-sm">
          <Typography
            variant="text-4"
            color="subdued"
            translation={null}
            style={{ minWidth: 60, display: 'block' }}
          >
            {reservation.time}
          </Typography>
          <Box flex className="space-between">
            <Typography variant="text-4" color="secondary">
              {reservation.name}
            </Typography>
            <Typography variant="text-4" color="subdued" translation={null}>
              {reservation.guests} PAX
              {lightReservation
                ? reservation.tables?.[0]
                  ? ` • ${reservation.tables[0]}`
                  : ''
                : ` • ${reservation.tables.join(', ')}`}
            </Typography>
          </Box>
        </Box>
        {!!reservation.comment && (
          <Box flex className="mg-bt-sm flex-start">
            <Typography
              variant="text-4"
              color="subdued"
              translation="reservations"
              style={{ minWidth: 60, display: 'block' }}
            >
              Guest
            </Typography>
            <Typography variant="text-3" component="p">
              {reservation.comment}
            </Typography>
          </Box>
        )}

        {!!reservation.hostComment && (
          <Box flex className="mg-bt-sm flex-start">
            <Typography
              variant="text-4"
              color="subdued"
              translation="reservations"
              style={{ minWidth: 60, display: 'block' }}
            >
              Waiter
            </Typography>
            <Typography variant="text-3" component="p">
              {reservation.hostComment}
            </Typography>
          </Box>
        )}
        {!!reservation.date && (
          <Box flex className="mg-bt-sm flex-start">
            <Typography
              variant="text-4"
              color="subdued"
              translation="reservations"
              style={{ minWidth: 60, display: 'block' }}
            >
              Date
            </Typography>
            <Typography variant="text-3" component="p">
              {toEuropeanDate(reservation.date)}
            </Typography>
          </Box>
        )}
        <Box flex>
          {reservation.attr.map((attr, i) => (
            <span key={i} className="pill">
              <Typography variant="text-3">{attrHelper(attr)}</Typography>
            </span>
          ))}
        </Box>
      </Box>
      {!hideDivider && <Divider style={{ margin: '0 -16px' }} />}
    </>
  );
};

export default ShiftReservation;
