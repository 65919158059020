import Box from "Components/Atoms/Box";
import Typography from "Components/Atoms/Typography";
import React from "react";
import { ReservationSnippet } from "utils/types";

type ReservationTooltipProps = {
  res: ReservationSnippet;
};

const ReservationTooltip = ({ res }: ReservationTooltipProps) => {
  return (
    <Box padding="sm">
      <Typography block variant="text-4" color="subdued" translation={null}>
        {res.time}
      </Typography>
      <Typography block variant="text-4" color="secondary" translation={null}>
        {res.name}
      </Typography>
      <Typography block variant="text-4" color="subdued" translation={null}>
        {res.guests} PAX • {res.tables.join(", ")}
      </Typography>
    </Box>
  );
};

export default ReservationTooltip;
