import {
  InsertChartOutlined,
  BarChart,
  People,
  ViewCarousel,
  Theaters,
  Timelapse,
  List,
  TableChart,
} from 'Components/Atoms/Icons';
import LightReservationsIcon from 'Components/Atoms/Icons/LightReservations';
import { dateHelper } from 'utils/helper';

export const paths = [
  {
    id: 'tablePlan',
    linkTo: (rId: string, date?: string) =>
      `/${rId}/reservations/${date || dateHelper()}/tablePlan`,
    name: 'Table Plan',
    icon: <ViewCarousel />,
  },
  {
    id: 'timeTable',
    linkTo: (rId: string, date?: string) =>
      `/${rId}/reservations/${date || dateHelper()}/timeTable`,
    name: 'Time Table',
    icon: <BarChart style={{ transform: 'rotate(90deg)' }} />,
  },
  {
    id: 'guestbook',
    linkTo: (rId: string, date?: string) =>
      `/${rId}/reservations/${date || dateHelper()}/guestbook`,
    name: 'Gästebuch',
    icon: <People />,
    disableOnShuffle: true,
    authenticate: 'dashboard.guestBook.read',
  },
  {
    id: 'list',
    linkTo: (rId: string, date?: string) =>
      `/${rId}/reservations/${date || dateHelper()}/list`,
    name: 'Reservierungen',
    icon: <TableChart />,
    disableOnShuffle: true,
  },
  {
    id: 'statistics',
    linkTo: (rId: string, date?: string) =>
      `/${rId}/reservations/${date || dateHelper()}/statistics`,
    name: 'Statistics',
    icon: <InsertChartOutlined />,
    disableOnShuffle: true,
  },

  {
    id: 'largeGroups',
    linkTo: (rId: string) => `/${rId}/reservations/largeGroups`,
    name: 'Große Gruppen & Letzte Reservierungen',
    icon: <Timelapse />,
    disableOnShuffle: false,
  },
  // {
  //   id: "waitingList",
  //   linkTo: (rId:string,date?:string) => `/${rId}/reservations/${date || dateHelper()}/waitingList`,
  //   name: "Waiting List",
  //   icon: <AccessAlarm />
  // },
];

export const lightPaths = [
  {
    id: 'reservations',
    linkTo: (rId: string, date?: string) =>
      `/${rId}/reservations/${date || dateHelper()}/reservationList`,
    name: 'Reservations',
    icon: <LightReservationsIcon />,
  },
  {
    id: 'guestbook',
    linkTo: (rId: string, date?: string) =>
      `/${rId}/reservations/${date || dateHelper()}/guestbook`,
    name: 'Gästebuch',
    icon: <People />,
    authenticate: 'dashboard.guestBook.read',
  },
  {
    id: 'statistics',
    linkTo: (rId: string, date?: string) =>
      `/${rId}/reservations/${date || dateHelper()}/statistics`,
    name: 'Statistics',
    icon: <InsertChartOutlined />,
  },
];
