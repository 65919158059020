import { RestaurantContext } from 'Contexts/RestaurantContext'
import React, { useContext } from 'react'

const useAlert = () => {
    const { alert } = useContext(RestaurantContext);

    return alert
}

export default useAlert
