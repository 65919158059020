import React from "react";
import Grid, { GridProps } from "@material-ui/core/Grid";
import { Modify } from "utils/types";

export type GridItemProps = Modify<GridProps, {}>;

const GridItem = ({ children, ...props }: GridItemProps) => {
  return (
    <Grid {...props} item>
      {children}
    </Grid>
  );
};

export default GridItem;
