import { useEffect, useMemo, useState } from 'react';
import { EditReservation, TimeSlot } from 'types/reservations';
import server, { apiendpoint } from 'utils/server';

type ErrorObject = { occassion?: string; guests?: string; date?: string };

const useAvailableTimes = (
  restaurantId = '',
  data: EditReservation,
  setdata: React.Dispatch<React.SetStateAction<EditReservation | null>>,
  uid = ''
) => {
  const {
    occassion = '',
    guests = 0,
    date = '',
    time,
    reservationId: id = '',
    reservationLength: resLength = null,
    walkin,
    new: isNew = false,
  } = data;

  const oldTimeSlot = data.timeSlot;

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<ErrorObject | null>(null);
  const [availableTimes, setavailableTimes] = useState<TimeSlot[] | null>(null);
  const [message, setmessage] = useState('');
  const [waitinglist, setwaitinglist] = useState<null | {
    offerWaitinglist: boolean;
    slots?: string[];
  }>(null);

  const getAvailableTimes = async (
    occassion: string,
    guests: number,
    date: string,
    resLength: null | number,
    isNew: boolean
  ) => {
    try {
      let errorObj: ErrorObject = {};

      if (!occassion) {
        errorObj.occassion = 'Anlass fehlt';
      }

      if (!guests) {
        errorObj.guests = 'Anzahl an Gästen fehlt';
      }

      if (!date) {
        errorObj.date = 'Datum fehlt';
      }

      setErrors(errorObj);

      if (Object.keys(errorObj).length) {
        return null;
      }

      setLoading(true);

      let url = `/v03/reservations/${restaurantId}/times/${date}/${occassion}`;

      const { data } = await Promise.race([
        server.post(url, {
          guests,
          date,
          id,
          uid,
          resLength: !walkin ? resLength : null,
          type: 'WAITER',
        }),
      ]);

      setLoading(false);
      setavailableTimes(data.availableTimes);

      let waitinglist =
        isNew && !!data?.waitinglist?.offerWaitinglist
          ? data.waitinglist
          : null;

      setwaitinglist(waitinglist);

      let timeSlot =
        data.availableTimes.find(
          (t: TimeSlot) => t.time === oldTimeSlot?.time ?? time
        ) || null;

      // console.log('TESTING4', timeSlot, oldTimeSlot, data);

      if (!timeSlot && oldTimeSlot && walkin) {
        timeSlot = {
          ...oldTimeSlot,
          combinations: [],
          blockedSlots: 0,
          totalSlots: 0,
          maxGuestsReached: false,
          reservationLength: resLength || oldTimeSlot.reservationLength || 4,
        };
      }

      if (timeSlot) {
        const noAvailability =
          timeSlot.blockedSlots >= timeSlot.totalSlots ||
          timeSlot.maxGuestsReached ||
          !timeSlot.combinations.length;
        let hasWaitinglist = !!noAvailability && !!waitinglist;

        if (hasWaitinglist) {
          timeSlot.waitinglist = hasWaitinglist;
        }
      }

      setdata((d) =>
        d !== null
          ? {
              ...d,
              timeSlot,
              tables:
                walkin || d.keepTables
                  ? d.tables
                  : timeSlot?.combinations?.find(
                      (t: any) =>
                        JSON.stringify(t.tables.sort()) ===
                        JSON.stringify(d?.tables?.sort() ?? [])
                    )?.tables ??
                    timeSlot?.combinations?.[0]?.tables ??
                    d.tables,
            }
          : null
      );

      if (data.availableTimes.length) {
      }

      if (data.message && typeof data.message === 'object') {
        setmessage(data.message.de);
      } else {
        setmessage('');
      }
    } catch (error: any) {
      setLoading(false);

      if (error.response) {
        setmessage(
          error?.response?.data?.message ?? 'Es ist ein Fehler aufgetreten'
        );
      } else {
        setmessage('Problem mit Internetverbindung');
      }

      setavailableTimes([]);
    }
  };

  console.log({ data });

  const loadWalkinReservationLength = async () => {
    try {
      const { data } = await server.get(
        `/v03/reservations/${restaurantId}/walkin/${occassion}?guests=${guests}`
      );

      setdata((d) =>
        !!d
          ? {
              ...d,
              reservationLength: data.resLength || 4,
            }
          : null
      );
    } catch (error) {
      console.log(error);
    }
  };

  const reservationLength = useMemo(() => {
    console.log({
      resLength,
      tS: data.timeSlot,
      x: availableTimes?.[0]?.reservationLength ?? null,
    });

    if (resLength) return resLength;

    if (data.timeSlot) {
      return data.timeSlot.reservationLength;
    }

    if (!availableTimes) return null;

    return availableTimes?.[0]?.reservationLength ?? null;
  }, [availableTimes, resLength, data.timeSlot]);

  useEffect(() => {
    if (walkin && !resLength) loadWalkinReservationLength();
    getAvailableTimes(occassion, guests, date, resLength, isNew);
  }, [restaurantId, occassion, guests, date, id, uid, resLength, walkin]);

  console.log({ walkin, resLength });

  return {
    loading,
    errors,
    setErrors,
    availableTimes,
    reservationLength,
    message,
    waitinglist,
  };
};

export default useAvailableTimes;
