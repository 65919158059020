import React from 'react';
import { useDrop as useReactDrop, useDrag as useReactDrag } from 'react-dnd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const USE_DROP = true;

export function useDrop<IItem = any>(
  onDrop: (item: IItem) => void | Promise<void>,
  accept: string[],
  times: { id: string; from: number; till: number }[]
) {
  if (!USE_DROP)
    return {
      isOver: null,
      canDrop: null,
      drop: () => {},
    };

  const [{ isOver, canDrop }, drop] = useReactDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => {
      let item = monitor.getItem() as {
        id: string;
        from: number;
        till: number;
      };

      let av =
        !!item &&
        !times.some(
          (time) =>
            time.id === item.id ||
            (time.from <= item.from && time.till >= item.till) ||
            (time.from >= item.from && time.till <= item.till) ||
            (time.from <= item.from && time.till >= item.from) ||
            (time.from <= item.till && time.till >= item.till)
        );

      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop() && av,
      };
    },
  });

  return {
    isOver,
    canDrop,
    drop,
  };
}

export function useDrag<IItem = any>(
  item: IItem,
  type: string,
  disabled = false
) {
  // if (!USE_DROP || disabled)
  //   return {
  //     opacity: 1,
  //     drag: null,
  //   };

  const [{ opacity }, drag, preview] = useReactDrag(
    () => ({
      type,
      item,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [item, type]
  );

  return {
    opacity,
    drag: disabled ? null : drag,
    preview: disabled ? null : preview,
  };
}

const DragAndDropProvider: React.FC<{}> = ({ children }) => {
  // if (!USE_DROP) return <>{children}</>;

  return <DndProvider backend={HTML5Backend}>{children}</DndProvider>;
};

export default DragAndDropProvider;
