import ReservationCard from '../ReservationCard';
import Overlap from '../Overlap';
import Blinker from '../Blinker';
import { reservations } from 'App/ReservationBook/TestData';
import Typography from 'Components/Atoms/Typography';
import { table } from 'node:console';
import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useLayoutEffect,
} from 'react';
import { Reservation, Table as TableType } from 'types/reservations';
import { classHelper, noop, timeString, timeToBlock } from 'utils/helper';
import { TimeTable } from '../..';
import { Container } from '@material-ui/core';
import CurrentTime from '../CurrentTime';
import { ReservationActionTypes } from 'Contexts/ReservationContext';

type Blinker = {
  height: number;
  width: number;
  top: number;
  left: number;
  id: string;
};

const TableRow = ({
  table,
  children,
  isActive,
  onClick,
}: {
  table: TableType;
  children: React.ReactNode;
  isActive: boolean;
  onClick: VoidFunction;
}) => {
  const onDragOver = (e: any) => {
    e.preventDefault();

    let track = document.getElementById('trackRect');

    if (!track) return;

    track.setAttribute('currentTable', table.name);
    track.setAttribute('currentSpace', table.space);

    let res = document.getElementById(track.getAttribute('draggedRes') || '');

    if (!res) return;

    let container = document
      .getElementById('trackRect')
      ?.getBoundingClientRect();

    let rect = e.currentTarget.getBoundingClientRect();

    if (!container || !rect) return;

    res.style.top = String(rect.top - container.top + 1) + 'px';
  };

  const classNames = classHelper([
    isActive && 'active',
    !table.occassions.length && 'blockedTable',
  ]);

  return (
    <tr
      id={`table-${table.name}`}
      className={classNames}
      onClick={onClick}
      onDragOver={onDragOver}
    >
      <th
        style={{
          paddingLeft: 16,
          whiteSpace: 'nowrap',
          maxWidth: 86,
          overflow: 'hidden',
        }}
      >
        <Typography
          variant="text-4"
          color="subdued"
          weight="bold"
          className="mg-r-sm"
        >
          {table.name}
        </Typography>
        <Typography variant="text-4" color="subdued">
          {table.max} Pax
        </Typography>
      </th>
      {children}
    </tr>
  );
};

type TableProps = {
  data: TimeTable;
  index: number;
  activeReservation: null | string;
  activeTable: null | string;
  onTableClick: (id: string) => void;
  onReservationClick: (id: string) => void;
  trackRect: null | DOMRect;
  occassions: { id: string; title: string }[];
  handleReservationAction: (
    id: string,
    action: ReservationActionTypes,
    payload?: any
  ) => void;
  currentTime: number | null;
};

const Table = ({
  data,
  index,
  activeReservation,
  activeTable,
  onTableClick = noop,
  trackRect = null,
  occassions = [],
  handleReservationAction,
  currentTime,
  onReservationClick,
}: TableProps) => {
  const blocks = useMemo(() => {
    if (data.closed) return [];

    let [start, close] = [data.start, data.close];

    if (start === null || close === null || start >= close || isNaN(start)) {
      return [];
    } else {
      return Array.from(Array(Math.max(close - start, 0)), (_, i) => ({
        block: i + (start || 0),
        timeString: timeString({ block: i + (start || 0) }),
      }));
    }
  }, [data]);

  const { reservationCards, overlaps } = useMemo(() => {
    let reservationCards: {
      id: string;
      position: {
        top: number;
        left: number;
        width: number;
        height: number;
      };
      reservation: Reservation;
    }[] = [];

    let overlaps: {
      top: number;
      left: number;
      width: number;
      height: number;
    }[] = [];

    if (!trackRect || !blocks.length) return { reservationCards, overlaps };

    const accurateTrackRect =
      document.getElementById('trackRect')?.getBoundingClientRect() ||
      trackRect;

    reservationCards = data.reservations
      .map((res) => {
        return {
          ...res,
          positions: (res?.tables ?? []).map((t) => {
            let tableRect =
              document.getElementById(`table-${t}`)?.getBoundingClientRect() ||
              null;

            let top = (tableRect?.top || 0) - accurateTrackRect.top + 1;

            let start = res.startTimeInBlocks || timeToBlock(res.time);

            let left = (start - blocks[0].block) * 60 + 88;

            let reservationLength =
              !res || res.reservationLength < 0
                ? res.endTimeInBlocks - res.startTimeInBlocks
                : res.reservationLength;

            return {
              id: `${res.id}__${t}`,
              top,
              left,
              width: 60 * (reservationLength || 4) - 1,
              height: 23,
            };
          }),
        };
      })
      .reduce(
        (
          acc: {
            id: string;
            position: {
              top: number;
              left: number;
              width: number;
              height: number;
            };
            reservation: Reservation;
          }[],
          { positions, ...reservation }
        ) => {
          return [
            ...acc,
            ...positions.map(({ id, ...position }) => ({
              id,
              position,
              reservation,
            })),
          ];
        },
        []
      );

    overlaps = reservationCards.reduce((acc: any[], cV, i, arr) => {
      let matches = arr
        .map((r, i2) => {
          if (i2 <= i) return false;

          if (r.position.top !== cV.position.top) return false;

          let a1 = r.position.left,
            a2 = r.position.left + r.position.width,
            b1 = cV.position.left,
            b2 = cV.position.left + cV.position.width;

          if (a1 === b1 && a2 === b2) {
            return {
              ...cV.position,
              opacity: 0.5,
            };
          }

          if (a2 > b1 && a1 < b1) {
            return {
              ...cV.position,
              left: b1,
              width: a2 - b1,
            };
          } else if (b2 > a1 && b1 < a1) {
            return {
              ...cV.position,
              left: a1,
              width: b2 - a1,
            };
          }

          return false;
        })
        .filter((o) => !!o);

      return [...acc, ...matches];
    }, []);
    return { reservationCards, overlaps };
  }, [trackRect, data.reservations, blocks]);

  const [blinkers, setblinkers] = useState<Blinker[]>([]);

  const handleDragStart = (e: any) => {
    let track = document.getElementById('trackRect');

    if (!track) return;

    let trackRect = track.getBoundingClientRect();

    let rect = e.currentTarget.getBoundingClientRect();

    let tables = document.querySelectorAll('#trackRect table');

    let arr: Blinker[] = new Array();

    tables.forEach((node, i) => {
      let { height, top, left, width } = node.getBoundingClientRect();

      let id = i;

      arr.push({
        id: id + 'left',
        left: left + 70 - trackRect.left,
        top: top - trackRect.top,
        height,
        width: rect.left - left - 70,
      });

      arr.push({
        id: id + 'right',
        left: rect.left + rect.width - trackRect.left,
        top: top - trackRect.top,
        height,
        width: left + width - (rect.left + rect.width),
      });
    });

    setblinkers((x) => arr);

    e.dataTransfer.setData('text', e.currentTarget.id);
    track.setAttribute('draggedRes', e.currentTarget.id);

    let img = document.createElement('img');
    img.style.cursor = 'grabbing';
    img.style.pointerEvents = 'none';
    img.style.width = '1px';
    img.style.height = '1px';
    img.src =
      'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
    e.dataTransfer.setDragImage(img, 1, 1);
  };

  const onDrop = (e: any) => {
    let track = document.getElementById('trackRect');

    setblinkers([]);

    if (!track) return;

    let draggedRes = track.getAttribute('draggedRes') || '';
    let newTable = track.getAttribute('currentTable');
    let space = track.getAttribute('currentSpace');

    track.removeAttribute('draggedRes');
    track.removeAttribute('currenTable');
    track.removeAttribute('currentSpace');

    let [resId, oldTable] = draggedRes.split('__');

    let { tables, space: oldSpace } =
      data.reservations.find((r) => r.id === resId) || {};

    if (!tables) return;

    let newTables = [
      ...new Set(tables.map((t) => (t === oldTable ? newTable : t))),
    ];

    if (newTables.length > 1) {
      let spaces = data.tables
        .filter((t) => newTables.includes(t.name))
        .reduce((acc: string[], cV) => [...new Set([...acc, cV.space])], []);

      if (spaces.length > 1) {
        // @TODO Show warning Message

        return;
      }
    }
    handleReservationAction(resId, ReservationActionTypes.CHANGE_TABLE, {
      tables: newTables,
      space: space || oldSpace,
    });
  };

  const showCurrentTime =
    (!!data.start || data.start === 0) &&
    !!data.close &&
    currentTime !== null &&
    currentTime >= data.start &&
    currentTime <= data.close;

  return (
    <div
      style={{ marginBottom: 10 }}
      onDrop={onDrop}
      onDragOver={(e) => e.preventDefault()}
    >
      <div className="header-Container" onDragOver={(e) => e.preventDefault()}>
        <Typography variant="h5" className="mg-bt-sm" style={{ left: 15 }}>
          {data.title}
        </Typography>
      </div>
      <table className="time__table" id={'tables-' + String(index)}>
        <tbody>
          <tr className="times" onDragOver={(e) => e.preventDefault()}>
            <th></th>
            {blocks.map((b) => (
              <td key={b.block}>
                <Typography variant="text-4" color="subdued" translation={null}>
                  {b.timeString}
                </Typography>
              </td>
            ))}
          </tr>
          {data.tables.map((table) => (
            <TableRow
              key={table.id}
              table={table}
              isActive={activeTable === table.id}
              onClick={() => onTableClick(table.id)}
            >
              {blocks.map((b) => (
                <td key={b.block} />
              ))}
            </TableRow>
          ))}
        </tbody>
      </table>
      {reservationCards.map((r) => (
        <ReservationCard
          key={r.id}
          id={r.id}
          reservation={r.reservation}
          position={r.position}
          occassions={occassions}
          selected={activeReservation === r.reservation.id}
          onClick={(e) => {
            e.stopPropagation();
            onReservationClick(r.reservation.id);
          }}
          onDragStart={handleDragStart}
        />
      ))}
      {overlaps.map((o, i) => (
        <Overlap key={i} position={o} />
      ))}
      {blinkers.map(({ id, ...position }) => (
        <Blinker key={id} position={position} />
      ))}

      {(!!data.start || data.start === 0) && showCurrentTime && (
        <CurrentTime
          currentTime={currentTime}
          start={data.start}
          associatedTableId={'tables-' + String(index)}
          trackRect={trackRect}
          id={data.id + '-currentTime'}
          index={index}
        />
      )}
    </div>
  );
};

export default Table;
