import React, { useContext, useState } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import { NotificationV03 as NotificationType } from 'gastronaut-shared/types/helper/notification';
import Typography from 'Components/Atoms/Typography';
import { LanguageContext } from 'Contexts/LanguageContext';
import CardComponent from './Components/Cards';
import { useRouter } from 'CustomHooks/useRouter';
import { classHelper } from 'utils/helper';
import Button from 'Components/Atoms/Button';
import { db } from 'utils/firebase';
import axios from 'axios';
import { AuthContext } from 'Contexts/AuthContext';
import { CircularProgress, IconButton, InputBase } from '@material-ui/core';
import ReservationOptimization from 'Components/Atoms/ReservationOptimization';
import { Send } from '@material-ui/icons';

export type NotificationV03Props = {
  notification: NotificationType & { id: string };
  level?: number;
  openStack?: () => void;
  closeStack?: () => void;
  open?: boolean;
  inStack?: boolean | null;
};

const NotificationV03: React.FC<NotificationV03Props> = ({
  notification,
  level = 0,
  openStack = () => {},
  closeStack = () => {},
  open = false,
  inStack = null,
}) => {
  const { dayjs } = useContext(LanguageContext);

  const { uid } = useContext(AuthContext);

  const [actionOverwrite, setaction] = useState<{
    id: string;
    message: string;
    status: string;
  } | null>(null);

  const [actionLoading, setactionLoading] = useState('');

  const [value, setvalue] = useState('');

  const router = useRouter();

  const handleAction = async (action: NotificationType['actions'][0]) => {
    if (action.status === 'error' || action.status === 'success') return;

    let message = '',
      status = '';

    try {
      if (actionLoading === action.id) return;
      setactionLoading(action.id);

      if (action.type === 'textInput') {
        const URL = action.apiCall.url.startsWith('http')
          ? action.apiCall.url
          : 'https://europe-west3-schillingroofbarhd.cloudfunctions.net/apiEndpoint/notificationsV03' +
            action.apiCall.url;

        const { data } = await axios.post(URL, {
          notificationId: notification.id,
          actionId: action.id,
          restaurantId: notification.restaurantId,
          userId: uid,
          ...action.apiCall.body,
          value,
        });

        (message = data.msg), (status = data.status);

        setaction({
          id: action.id,
          message,
          status,
        });
      } else if (action.type === 'link') {
        if (!action.link.startsWith('tel:')) {
          router.push(action.link);
        } else {
          window.location.href = action.link;
        }
      } else if (action.type === 'apiCall') {
        const URL = action.apiCall.url.startsWith('http')
          ? action.apiCall.url
          : 'https://europe-west3-schillingroofbarhd.cloudfunctions.net/apiEndpoint/notificationsV03' +
            action.apiCall.url;

        if (action.apiCall.method === 'GET') {
          const { data } = await axios.get(URL, {
            notificationId: notification.id,
            actionId: action.id,
            restaurantId: notification.restaurantId,
            userId: uid,
            ...action.apiCall.body,
          });

          (message = data.msg), (status = data.status);
        } else if (action.apiCall.method === 'POST') {
          const { data } = await axios.post(URL, {
            notificationId: notification.id,
            actionId: action.id,
            restaurantId: notification.restaurantId,
            userId: uid,
            ...action.apiCall.body,
          });

          (message = data.msg), (status = data.status);
        } else if (action.apiCall.method === 'PUT') {
          const { data } = await axios.put(URL, {
            notificationId: notification.id,
            actionId: action.id,
            restaurantId: notification.restaurantId,
            userId: uid,
            ...action.apiCall.body,
          });

          (message = data.msg), (status = data.status);
        } else if (action.apiCall.method === 'DELETE') {
          const { data } = await axios.delete(URL, {
            notificationId: notification.id,
            actionId: action.id,
            restaurantId: notification.restaurantId,
            userId: uid,
            ...action.apiCall.body,
          });

          (message = data.msg), (status = data.status);
        }

        setaction({
          id: action.id,
          message,
          status,
        });
      }
    } finally {
      setactionLoading('');
    }
  };

  const [small, setsmall] = useState(
    !['optimizations', 'mailbox'].includes(notification.icon ?? '')
  );

  if (level === 0) return <></>;

  return (
    <div
      style={{
        opacity: !(inStack ?? true) ? 0.5 : 1,
        marginBottom: level > 2 ? 0 : 20,
      }}
    >
      {open && (
        <Box flex style={{ justifyContent: 'space-between' }}>
          <Typography variant="text-2" weight="medium">
            Mehrere Benachrichtigungen
          </Typography>
          <Button onClick={closeStack} variant="transparent">
            Weniger
          </Button>
        </Box>
      )}
      <Box
        style={{
          zIndex: level,
          position: 'relative',
        }}
        className={'notificationV03Card' + (level > 1 ? ' elevated' : '')}
      >
        <Box onClick={!inStack ? openStack : undefined} className="inner">
          <Box className="header">
            <img
              src={`/notificationsV03/icons/${notification.icon}.png`}
              width="24px"
              height="24px"
              alt={notification?.title}
              style={{ marginTop: 4 }}
            />
            <Typography className="title" variant="text-2" weight="medium">
              {notification.title}
            </Typography>
            <Typography variant="text-2" weight="medium" color="subdued">
              {dayjs(notification.createdAt)
                .fromNow()
                .replace('Minuten', 'min')
                .replace(' Stunden', 'h')
                .replace('vor ein paar Sekunden', 'gerade')
                .replace('einem', '1')}
            </Typography>
          </Box>
          {(inStack || !small) && (
            <>
              <Typography variant="text-2" className="message" weight="medium">
                {notification.message}
              </Typography>
              {notification.cards.map((card, i) => (
                <CardComponent
                  id={notification.id}
                  card={card}
                  key={i}
                  notification={notification}
                />
              ))}
              {notification.actions.length === 1 &&
              notification.actions[0].type === 'textInput' ? (
                <Box
                  className={classHelper([
                    'actionText',
                    notification.actions[0].status,
                  ])}
                  style={{
                    display: 'none',
                  }}
                >
                  <InputBase
                    style={{ marginLeft: 8, flex: 1, color: '#000000' }}
                    placeholder={
                      (notification.actions[0] as any).placeholder ||
                      'Schreibe eine Antwort'
                    }
                    value={value}
                    onChange={(e) => setvalue(e.target.value)}
                    inputProps={{
                      'aria-label':
                        (notification.actions[0] as any).placeholder ||
                        'Schreibe eine Antwort',
                    }}
                    endAdornment={
                      <IconButton
                        type="submit"
                        onClick={() => handleAction(notification.actions[0])}
                        style={{ padding: 10 }}
                        color="secondary"
                        aria-label="search"
                      >
                        <Send />
                      </IconButton>
                    }
                  />
                </Box>
              ) : (
                <Box className="actions">
                  {notification.actions.map((action, i) => (
                    <Box
                      key={i}
                      className={classHelper([
                        'action-card',
                        action.clicked && 'clicked',
                        actionOverwrite?.id === action.id
                          ? actionOverwrite.status
                          : action.status,
                        actionLoading === action.id && 'loading',
                      ])}
                      onClick={() => handleAction(action)}
                    >
                      {actionLoading !== action.id ? (
                        <>
                          {!(action as any)?.icon?.startsWith(
                            'optimizeReservation_'
                          ) ? (
                            <img
                              src={`/notificationsV03/actionIcons/${
                                (action as any)?.icon ?? action.id
                              }.png`}
                              width="32px"
                              height="32px"
                              alt={action.label}
                            />
                          ) : (
                            <ReservationOptimization
                              showNoScore
                              scoreDiff={parseFloat(
                                (action as any).icon.replace(
                                  'optimizeReservation_',
                                  ''
                                )
                              )}
                            />
                          )}
                          <Typography
                            variant="text-2"
                            weight="medium"
                            textAlign="center"
                          >
                            {action.label}
                          </Typography>
                        </>
                      ) : (
                        <CircularProgress />
                      )}
                    </Box>
                  ))}
                </Box>
              )}
            </>
          )}
          {!(inStack || !small) && (
            <Button
              variant="transparent"
              onClick={(e) => {
                if (inStack ?? true) e.stopPropagation();
                setsmall(false);
              }}
              style={{ fontSize: '80%' }}
            >
              Mehr anzeigen
            </Button>
          )}
        </Box>
        <Button
          className="hideBtn"
          variant="transparent"
          onClick={() =>
            db
              .collection('notificationsV03')
              .doc(notification.id)
              .update({ status: 'done' })
          }
        >
          Ausblenden
        </Button>
      </Box>
      {level > 1 && (
        <div onClick={openStack}>
          {Array.from(new Array(Math.min(level - 1, 3)), (_, i) => i + 1).map(
            (i) => (
              <div
                key={i}
                style={{
                  background: 'var(--color-grey-2, #edf2f7)',
                  width: `calc(100% - ${i * 40}px)`,
                  borderBottomLeftRadius: 20,
                  borderBottomRightRadius: 20,
                  height: 18,
                  zIndex: level - i,
                  boxShadow:
                    level - i > 0
                      ? '0px 0px 2px 0px rgba(0, 0, 0, 0.2), 0px 2px 10px 0px rgba(0, 0, 0, 0.1)'
                      : '',
                  transform: `translateY(-${i * i * i}px)`,
                  margin: `0 auto`,
                  position: 'relative',
                }}
              ></div>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default NotificationV03;
