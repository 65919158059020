import ListItem from "@material-ui/core/ListItem";
import { ArrowDropDown, ArrowDropUp } from "Components/Atoms/Icons";
import Button, { ButtonProps } from "Components/Atoms/Button";
import React, { useMemo, useState } from "react";
import {
  noop,
  textAlignToJustifyContent,
  textAlignToPlacement,
} from "utils/helper";
import Popover from "Components/Atoms/Popover";
import { useTranslation } from "react-i18next";
import { Translations } from "utils/types";
import "./styles.scss";
import { Colors } from "Components/Atoms";

type Value = string | number | null;

type Index = number;

type Extractor<T = Value> = {
  (currentOption: any, index?: Index): T;
};

type Option<T = Value> = {
  (params: OptionElementType<T>): React.ReactNode;
};

type OnClick = {
  (): void;
};

export type OptionElementType<T = Value> = {
  onClick: OnClick;
  selected: boolean;
  id: T;
  label: Value;
  justifyContent: string;
};

function optionElementDefault<T>({
  onClick,
  selected,
  id,
  label,
  justifyContent,
}: OptionElementType<T>) {
  return (
    <ListItem
      key={String(id) || "null"}
      style={{ justifyContent }}
      onClick={onClick}
      selected={selected}
      button
    >
      {label}
    </ListItem>
  );
}

function idExtractorDefault<T = Value>(opt: T | { id: T }) {
  return typeof opt === "object" && opt !== null
    ? (opt as { id: T }).id
    : (opt as T);
}
const labelExtractorDefault = (opt: any) => {
  return String(typeof opt === "object" && opt !== null ? opt.label : opt);
};

interface Props<T = Value> {
  options: ({ id: T; label?: string } | T)[];
  value: T;
  style?: React.CSSProperties;
  disabled?: boolean;
  textAlign?: "right" | "center" | "left";
  idExtractor?: Extractor<T>;
  labelExtractor?: Extractor<string>;
  onChange: (newValue: T) => void;
  buttonProps?: ButtonProps;
  optionElement?: Option<T>;
  translation?: Translations;
  buttonColor?: Colors;
}

function Dropdown<T = Value>({
  options = [],
  value,
  textAlign = "left",
  translation = null,
  idExtractor = idExtractorDefault,
  labelExtractor = labelExtractorDefault,
  optionElement = optionElementDefault,
  buttonProps = {},
  disabled = false,
  onChange = noop,
  buttonColor = "subdued",
  style,
}: Props<T>) {
  const [open, setopen] = useState<null | HTMLElement | EventTarget>(null);

  const { t } = useTranslation(translation || undefined);

  const current = useMemo(
    () => options.find((opt, i) => idExtractor(opt, i) === value) || value,
    [idExtractor, value, options]
  );

  const currentLabel = labelExtractor(current);

  const handleOpen = (e: React.SyntheticEvent) =>
    setopen(!disabled ? e.currentTarget : null);

  const handleClose = () => setopen(null);

  const handleChange = (nV: T) => {
    setopen(null);
    onChange(nV);
  };

  return (
    <>
      <Button
        typographyProps={{ variant: "text-3" }}
        color={buttonColor}
        className="dropdown"
        disabled={disabled}
        style={style}
        variant="transparent"
        translation={translation}
        {...buttonProps}
        endIcon={(params) =>
          !!open ? <ArrowDropUp {...params} /> : <ArrowDropDown {...params} />
        }
        onClick={handleOpen}
      >
        {t(currentLabel)}
      </Button>
      <Popover
        anchorEl={open}
        open={!!open && !disabled}
        onClose={handleClose}
        placement={textAlignToPlacement(textAlign)}
        style={{ minWidth: "min-content" }}
      >
        {options.map((option) => {
          let id = idExtractor(option);
          let label = translation
            ? t(String(labelExtractor(option) ?? ""))
            : labelExtractor(option) ?? "";
          let selected = value === id;

          return optionElement({
            onClick: () => handleChange(id),
            id,
            label,
            selected,
            justifyContent: textAlignToJustifyContent(textAlign),
          });
        })}
      </Popover>
    </>
  );
}

// const Dropdown = ({
//   options = [],
//   value = '',
//   textAlign = 'left',
//   idExtractor = idExtractorDefault,
//   labelExtractor = labelExtractorDefault,
//   optionElement = optionElementDefault,
//   buttonProps = {},
//   disabled = false,
//   onChange = noop
// }: Props) => {
//   const [open, setopen] = useState<null | HTMLElement | EventTarget>(null);

//   const current = useMemo(
//     () => options.find((opt, i) => idExtractor(opt, i) === value) || value,
//     [idExtractor, value, options]
//   );

//   const currentLabel = labelExtractor(current);

//   const handleOpen = (e: React.SyntheticEvent) =>
//     setopen(!disabled ? e.currentTarget : null);

//   const handleClose = () => setopen(null);

//   const handleChange = (nV: Value) => {
//     setopen(null);
//     onChange(nV);
//   };

//   return (
//     <>
//       <Button
//         typographyProps={{ variant: 'text-3' }}
//         color={'subdued'}
//         disabled={disabled}
//         variant='transparent'
//         {...buttonProps}
//         endIcon={params =>
//           !!open ? <ArrowDropUp {...params} /> : <ArrowDropDown {...params} />
//         }
//         onClick={handleOpen}
//       >
//         {String(currentLabel)}
//       </Button>
//       <Popover
//         anchorEl={open}
//         open={!!open && !disabled}
//         onClose={handleClose}
//         placement={textAlignToPlacement(textAlign)}
//         width='auto'
//       >
//         {options.map(option => {
//           let id = idExtractor(option);
//           let label = labelExtractor(option);
//           let selected = value === id;

//           return optionElement({
//             onClick: () => handleChange(id),
//             id,
//             label,
//             selected,
//             justifyContent: textAlignToJustifyContent(textAlign)
//           });
//         })}
//       </Popover>
//     </>
//   );
// };

export default Dropdown;
