import React, { useEffect, useState } from "react";
import { ReservationShift, ShiftComment } from "types/shifts";
import firebase, { db } from "utils/firebase";
import { dateHelper } from "utils/helper";
import server from "utils/server";

const loadShift =
  (date: string, restaurantId: string) =>
  async (doc: firebase.firestore.QueryDocumentSnapshot) => {
    const comment = doc.data() as ShiftComment;

    const { data } = await server.get(`/v03/shifts/${restaurantId}/${date}`);

    const shifts = (await data.shifts) as ReservationShift[];

    const shift = shifts.find(
      (s) => s.active && s.id === comment.shiftId
    ) as ReservationShift;

    return {
      comment,
      shift,
    };
  };

const useShiftComments = (restaurantId: string | null) => {
  const [commentShifts, setCommentShifts] = useState<
    { comment?: ShiftComment; shift: ReservationShift }[]
  >([]);

  useEffect(() => {
    (async () => {
      if (!restaurantId) {
        setCommentShifts([]);
        return;
      }

      const today = dateHelper();

      const ref = db
        .collection(`restaurants/${restaurantId}/shiftComments`)
        .where("date", "==", today);

      const docs = await ref.get();

      if (docs.empty) {
        setCommentShifts([]);
        return;
      }

      const comments = docs.docs.map((d) => d.data() as ShiftComment);

      const { data } = await server.get(`/v03/shifts/${restaurantId}/${today}`);

      const shifts = (await data.shifts) as ReservationShift[];

      const arr = shifts
        .filter((s) => s.active && !s.disabled)
        .map((shift) => {
          let comment = comments.find((c) => c.shiftId === shift.id);

          if (!comment && shift.regular) {
            return {
              shift,
              comment,
              remove: true,
            };
          }

          return {
            shift,
            comment,
          };
        });

      setCommentShifts(arr.filter((x) => !x.remove));
    })();
  }, [restaurantId]);

  return commentShifts;
};

export default useShiftComments;
