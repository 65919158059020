import { useState, useEffect, useRef, useContext } from "react";
import { db } from "../utils/firebase";

const useAlarm = (sound: string, restaurantId: null | string) => {
  const [alarms, setalarms] = useState<string[]>([]);
  const [increment, setincrement] = useState(0);
  const [confirm, setconfirm] = useState(false);

  const audio = useRef<HTMLAudioElement | null>(null);
  const eventListener = useRef<VoidFunction | null>(null);

  useEffect(() => {
    if (!confirm && restaurantId) {
      db.collection(`restaurants/${restaurantId}/settings`)
        .doc("takeAway")
        .get()
        .then((doc) => {
          if (doc.exists) {
            // confirmationDialogHandler(
            //   {
            //     title: 'Bitte bestägigen um Lieferdienst Alarm anzustellen',
            //     maxWidth: 'xs'
            //   },
            //   () => setconfirm(true)
            // );
            setconfirm(true);
          }
        });
    }
  }, [restaurantId]);

  useEffect(() => {
    if (confirm && sessionStorage.getItem("disableAlarm") !== "true") {
      if (!audio.current && sound) {
        let audioElement = document.createElement("audio");
        audioElement.src = sound;
        audioElement.loop = true;
        document.body.appendChild(audioElement);

        // console.log(audioElement);

        eventListener.current = () => {
          audioElement.play();
          audioElement.pause();
        };

        document.body.addEventListener("touchstart", eventListener.current);

        audio.current = audioElement;
      }

      if (audio.current && alarms.length) {
        audio.current.play();
        setTimeout(() => {
          if (audio.current) {
            audio.current.pause();
          }
        }, 4000);
      }
    }

    return () => {
      if (eventListener.current) {
        document.body.removeEventListener("touchstart", eventListener.current);
      }
      if (audio.current) {
        audio.current.remove();
      }
    };
  }, [alarms, sound, increment, confirm]);

  useEffect(() => {
    let interval = setInterval(() => {
      setincrement((i) => i + 1);
    }, 10000);

    return () => {
      window.clearInterval(interval);
    };
  }, []);

  const playAlarmSound = (id: string) =>
    setalarms((a) => [...new Set([...a, id])]);

  // console.log({ alarms, confirm })

  const stopAlarmSound = (id: string) =>
    setalarms((a) => (id !== "all" ? a.filter((x) => x !== id) : []));

  return [playAlarmSound, stopAlarmSound];
};

export default useAlarm;
