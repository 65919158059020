import Box from 'Components/Atoms/Box';
import Divider from 'Components/Atoms/Divider';
import Typography from 'Components/Atoms/Typography';
import { ReservationActionTypes } from 'Contexts/ReservationContext';
import React, { useEffect, useMemo, useRef } from 'react';
import { Reservation } from 'types/reservations';
import { blockToTime, noop, timeHelper, timeToBlock } from 'utils/helper';
import SmallReservationCard from '../../SmallReservationCard';
import { TableInfo } from 'CustomHooks/useTableInfo';

export type TableScheduleProps = {
  start: number;
  close: number;
  reservations: Reservation[];
  currentTime: null | string;
  setHasOverflow: (value: { bottom: boolean; top: boolean }) => void;
  onReservationAction: (
    id: string,
    type: ReservationActionTypes,
    payload?: any
  ) => void;
  handleScrolling?: (
    event: React.UIEvent<HTMLDivElement | HTMLLabelElement | HTMLFormElement>
  ) => void;
  setcurrentReservation: (id: string | null) => void;
  tableInfo: (TableInfo[''][0] & {
    start: number;
    last: number;
    end?: number;
  })[];
};

const timeString = (block: number) => {
  if (block % 4) {
    return ' ';
  } else {
    return blockToTime(block);
  }
};

const TableSchedule: React.FC<TableScheduleProps> = ({
  start,
  close,
  reservations,
  currentTime,
  setHasOverflow = noop,
  handleScrolling = noop,
  setcurrentReservation = noop,
  onReservationAction,
  tableInfo,
}) => {
  const blocks = useMemo(() => {
    const startBlock = start;
    const closeBlock = close;
    let blockArray = [];
    for (let i = startBlock; i <= closeBlock; i += 2) {
      blockArray.push(i);
    }
    return blockArray;
  }, [start, close]);

  const currentTimeLine = useRef<null | HTMLDivElement>(null);

  const topCoordinate = (startString: string) => {
    const startResa = timeToBlock(startString);
    return 12 + (startResa - start) * 12;
  };

  useEffect(() => {
    if (currentTimeLine.current) {
      currentTimeLine.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [currentTimeLine]);

  useEffect(() => {
    const container = document.getElementById('scrollingSchedule');
    if (!!container) {
      const overFlow = container.clientHeight < container.scrollHeight;
      setHasOverflow({ bottom: overFlow, top: false });
    }
  }, [setHasOverflow]);

  const overlaps = useMemo(() => {
    let overlaps: {
      top: number;
      height: number;
    }[] = [];

    reservations
      .sort((a, b) => a.startTimeInBlocks - b.startTimeInBlocks)
      .forEach((r, i, arr) => {
        if (i > 0) {
          const previousResa = arr[i - 1];
          if (r.startTimeInBlocks < previousResa.endTimeInBlocks) {
            const overflowLength =
              previousResa.endTimeInBlocks - r.startTimeInBlocks;
            overlaps.push({
              top: topCoordinate(r.time),
              height: overflowLength * 12,
            });
          }
        }
      });

    return overlaps;
  }, [reservations]);

  return (
    <Box
      id="scrollingSchedule"
      style={{ maxHeight: 'calc(100% - 120px)' }}
      onScroll={(e) => handleScrolling(e)}
    >
      {blocks.map((time) => (
        <Box flex style={{ height: 24 }} key={time}>
          <Typography
            block
            variant="text-4"
            color="subdued"
            translation={null}
            style={{ width: 42 }}
          >
            {timeString(time)}
          </Typography>
          {currentTime && timeToBlock(currentTime) === time ? (
            <div className="flex fullSize" ref={currentTimeLine}>
              <div className="smallDot"></div>
              <Divider style={{ backgroundColor: 'var(--color-tertiary)' }} />
            </div>
          ) : currentTime && timeToBlock(currentTime) === time + 1 ? (
            <Box>
              <Divider />
              <div
                className="flex fullSize quarter-time-line"
                ref={currentTimeLine}
              >
                <div className="smallDot"></div>
                <Divider style={{ backgroundColor: 'var(--color-tertiary)' }} />
              </div>
            </Box>
          ) : (
            <Divider />
          )}
        </Box>
      ))}
      {tableInfo.map((i, x) => (
        <Box
          className="small-tableInfo-container"
          style={{
            top: 12 + (i.start - start) * 12,
            height: Math.max(
              !!i.open
                ? (timeToBlock(timeHelper()) - i.start) * 12
                : ((i.end ?? i.last) - i.start) * 12,
              20
            ),
            borderRadius: 4,
            backgroundColor: 'var(--color-grey-3)',
            padding: '4px 8px',
          }}
          key={x}
        >
          <span
            style={{
              display: 'inline-block',
              height: 12,
              width: 12,
              borderRadius: 6,
              backgroundColor: i.open
                ? 'var(--color-success)'
                : 'var(--color-secondary)',
            }}
          ></span>
          <small style={{ fontSize: 12, lineHeight: 12 }}>
            {i.balance.toFixed(2).replace('.', ',')} €
          </small>
        </Box>
      ))}
      {reservations
        .filter(
          (r) =>
            r.status !== 'failed' &&
            !r.blocked &&
            (r.endTimeInBlocks > start || r.startTimeInBlocks < close)
        )
        .map((resa) => (
          <Box
            onClick={() => setcurrentReservation(resa.id)}
            className="small-resa-container"
            style={{
              top: topCoordinate(resa.time),
            }}
            key={resa.id}
          >
            <SmallReservationCard
              reservation={resa}
              handleAction={onReservationAction}
            />
          </Box>
        ))}
      {overlaps.map((overlap, i) => (
        <Box
          key={'overlap-' + i}
          style={{
            top: overlap.top,
            height: overlap.height,
            backgroundImage: `url("/warning.svg")`,
          }}
          className="overlap"
        ></Box>
      ))}
    </Box>
  );
};

export default TableSchedule;
