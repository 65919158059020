import React from "react";

const TicketIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width ?? "20"}
    height={props.height ?? "20"}
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M60 74C49.96 74 41.28 68.32 36.96 60H60L64 52H34.32C34.12 50.68 34 49.36 34 48C34 46.64 34.12 45.32 34.32 44H60L64 36H36.96C41.28 27.68 50 22 60 22C66.44 22 72.36 24.36 76.92 28.28L84 21.2C77.64 15.48 69.2 12 60 12C44.32 12 31.04 22.04 26.08 36H12L8 44H24.24C24.08 45.32 24 46.64 24 48C24 49.36 24.08 50.68 24.24 52H12L8 60H26.08C31.04 73.96 44.32 84 60 84C69.24 84 77.64 80.52 84 74.8L76.88 67.72C72.36 71.64 66.48 74 60 74Z"
      fill="currentColor"
    />
  </svg>
);

export default TicketIcon;
