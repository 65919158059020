import React from "react";
import Box, { BoxProps } from "Components/Atoms/Box";
import "./styles.scss";
import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";
import { classHelper } from "utils/helper";

export type ScrollAreaProps = BoxProps & {};

const ScrollArea: React.FC<ScrollAreaProps> = ({
  children,
  className,
  style,
}) => {
  return (
    <ScrollAreaPrimitive.Root
      className={classHelper(["scroll-area", className])}
      style={style}
    >
      <ScrollAreaPrimitive.Viewport className="scroll-view">
        {children}
      </ScrollAreaPrimitive.Viewport>
      <ScrollAreaPrimitive.ScrollAreaScrollbar
        className="scroll-bar"
        orientation="vertical"
      >
        <ScrollAreaPrimitive.ScrollAreaThumb className="scroll-thumb" />
      </ScrollAreaPrimitive.ScrollAreaScrollbar>
      <ScrollAreaPrimitive.ScrollAreaScrollbar
        className="scroll-bar"
        orientation="horizontal"
      >
        <ScrollAreaPrimitive.ScrollAreaThumb className="scroll-thumb" />
      </ScrollAreaPrimitive.ScrollAreaScrollbar>
      <ScrollAreaPrimitive.Corner className="scroll-corner" />
    </ScrollAreaPrimitive.Root>
  );
};

export default ScrollArea;
