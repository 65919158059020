import { Descendant } from "slate";
import {
  Attachment,
  ReservationShift,
  TakeAwayShift,
  OffersShift,
  GuestOrderingShift,
} from "gastronaut-shared/types/helper/shifts";
import { ShiftV02Document } from "gastronaut-shared/types/documents/restaurants/shiftsV02";

export * from "gastronaut-shared/types/helper/shifts";

export enum ShiftType {
  RESERVATION = "reservation",
  TAKEAWAY = "takeAway",
  GUEST_ORDERING = "guestOrdering",
  HAPPY_HOURS = "happyHours",
}

// export enum BlockType {
//   BLOCK = "block",
//   UNBLOCK = "unblock",
//   MAX_GUESTS = "max_guests",
//   CLOSE_TERRACE = "close_terrace",
//   OPEN_TERRACE = "open_terrace",
// }

// export type BlockOptions = {
//   shifts: null | string[] | { id: string; maxGuests?: number }[];
//   type: BlockType;
//   uid: string;
//   maxGuests?: number;
// };

export interface Shift extends ShiftV02Document {}

// export type SlotPerBlockRule = {
//   blocks: number[];
//   slots: number;
//   number?: number;
// };

// export type SlotPerBlock = {
//   rules: SlotPerBlockRule[];
//   standard: number;
// };

// export type ReservationLengthRule = {
//   index: number;
//   length: number;
//   minGuests?: number;
//   occassions?: string[];
//   vip?: boolean;
//   specialOccassion?: boolean;
// };

// export type ReservationLength = {
//   rules: ReservationLengthRule[];
//   standard: number;
//   x:ReservationShift
// };

// export interface GuestOrderingShift extends Shift {
//   type: ShiftType.GUEST_ORDERING;
//   menu: string | null;

//   closed?: boolean;
// }

// export type HappyHoursOffer = {
//   id?: string;
//   name: string;
//   type: "meal" | "category";
//   reductionType: "percentage" | "amount" | "newPrice";
//   value: number;
// };

// export interface OffersShift extends Shift {
//   type: ShiftType.HAPPY_HOURS;
//   offers?: HappyHoursOffer[];

//   ad?: {
//     translations: Record<string, { title: string; description: string }>;
//     imageId?: string;
//     imageUrl?: string;
//   } | null;
//   closed?: boolean;
// }

// export interface TakeAwayShift extends Shift {
//   type: ShiftType.TAKEAWAY;
//   menu: string | null;
//   deliveryMethods: OrderMethod[];
//   closed?: boolean;
//   advert?: null | TakeAwayAdvert;
//   message?: Record<string, string>;
// }

// export interface Attachment {
//   type: string;
//   size: number;
//   documentName: string;
//   url: string;
//   createdAt: number;
//   createdBy: string;
// }
export interface ShiftComment {
  date?: string;
  shiftId: string;
  slateDescendants: Descendant[];
  attachments: Attachment[];
  createdAt: number;
  createdBy: string;
  updatedAt?: number;
  updatedBy?: string;
}

// export interface Date<T extends Shift = AnyShift> {
//   shifts: T[];
//   date: string;
//   wDay: number;
//   holiday: null | string;
//   shiftStatistics?: Record<string, Record<string, number>>;
//   weather?: {
//     weather: {
//       icon: string;
//       code: number;
//       description: string;
//     };
//     low_temp: number;
//     max_temp: number;
//     temp: number;
//     valid_date: string;
//   };
// }
