import React, { useEffect } from "react";
import Box from "Components/Atoms/Box";
import "./styles.scss";
import { Toast as ToastType } from "Contexts/RestaurantContext";
import useImpulse from "CustomHooks/useImpulse";
import Toast from "Components/Molecules/Toast";
import ReactDOM from "react-dom";

export type ToastsProps = {
  toasts: ToastType[];
  onClose: (id: string | number) => void;
};

const Toasts: React.FC<ToastsProps> = ({ toasts, onClose }) => {
  let impulse = useImpulse(); // is incremented every 10 seconds

  useEffect(() => {
    toasts
      .filter((t) => t.id <= Date.now())
      .forEach((t) => {
        onClose(t.id);
      });
  }, [impulse]);

  return ReactDOM.createPortal(
    <Box
      width="280px"
      style={{
        position: "fixed",
        bottom: 0,
        maxHeight: 200,
        left: 48,
        zIndex: 20000,
      }}
    >
      {toasts.map((t) => (
        <Toast
          severity={t.severity}
          id={t.id}
          key={t.id}
          description={t.description}
          descriptionTranslation={t.descriptionTranslation}
          onRevert={t.onRevert}
          onClose={onClose}
          width={280}
        />
      ))}
    </Box>,
    document.getElementById("portal") as Element
  );
};

export default Toasts;
