import React, { useState } from "react";
import { Rating } from "types/feedback";
import Checkbox from "Components/Atoms/Checkbox";
import Typography from "Components/Atoms/Typography";
import { toEuropeanDate } from "utils/helper";
import RatingStars from "./RatingStars";

export type ReviewRowProps = {
  review: Rating;
  onDone: (id: string) => void;
};

const ReviewRow = ({ review, onDone }: ReviewRowProps) => {
  const {
    guest,
    date = "-",
    time = "-",
    tables = [],
    guests,
    comment = "",
    contact,
    rating,
    done = false,
    id,
  } = review;

  const [open, setOpen] = useState(false);
  return (
    <>
      <tr
        onClick={() => setOpen((o) => !o)}
        style={open ? { backgroundColor: "var(--color-bg)" } : {}}
      >
        <td style={{ textAlign: "center", padding: 9 }}>
          <Checkbox
            id={id}
            checked={done || rating > 3}
            onChange={(e) => onDone(id)}
            onClick={(e) => e.stopPropagation()}
            label={""}
            translation="common"
            color="primary"
            size="small"
            style={{ margin: "auto", width: "fit-content" }}
          />
        </td>
        <td>
          <Typography variant="text-3" color="subdued">
            {guest.name}
          </Typography>
        </td>
        <td>
          <Typography variant="text-3" color="subdued">
            {toEuropeanDate(date)}
          </Typography>
        </td>
        <td>
          <Typography variant="text-3" color="subdued" translation={null}>
            {time}
          </Typography>
        </td>
        <td>
          <Typography variant="text-3" color="subdued">
            {tables.join(", ")}
          </Typography>
        </td>
        <td>
          <Typography variant="text-3" color="subdued">
            {guests}
          </Typography>
        </td>
        <td style={{ minWidth: 170 }}>
          <RatingStars rating={rating} />
        </td>
        <td>
          <Typography variant="text-3" color="subdued">
            {contact ? "Yes" : "No"}
          </Typography>
        </td>
        <td
          rowSpan={open ? 2 : 1}
          className={open ? "bigColumn" : "bigColumn truncateWord"}
        >
          <Typography variant="text-3" color="subdued">
            {comment}
          </Typography>
        </td>
      </tr>
      {open && (
        <tr style={{ backgroundColor: "var(--color-bg)" }}>
          <td></td>
          <td colSpan={3}>
            <Typography
              variant="text-3"
              style={{ marginRight: 16 }}
              translation="feedback"
            >
              Email
            </Typography>
            <Typography variant="text-3" color="subdued">
              {guest.email}
            </Typography>
          </td>
          <td colSpan={3}>
            <Typography
              variant="text-3"
              style={{ marginRight: 16 }}
              translation="feedback"
            >
              Phone
            </Typography>
            <Typography variant="text-3" color="subdued">
              {guest.phone}
            </Typography>
          </td>
          <td></td>
        </tr>
      )}
    </>
  );
};

export default ReviewRow;
