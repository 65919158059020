import React, { useState } from 'react';
import Box from 'Components/Atoms/Box';
import { blockToTime } from 'utils/helper';
import { noop } from 'utils/helper';
import MenuItem from 'Components/Atoms/MenuItem';

const OPTIONS = Array.from(Array(48), (x, i) => ({
  id: i + 1,
  label: blockToTime(i + 1),
}));

export type ReservationLengthOptionsProps = {
  value?: null | number; // 1 = 15min, 2 = 30min, ...,
  onChange?: (nV: number) => void;
};

const ReservationLengthOptions = ({
  value,
  onChange = noop,
}: ReservationLengthOptionsProps) => {
  return (
    <Box>
      {OPTIONS.map((o) => (
        <MenuItem
          key={o.id}
          value={o.id}
          onClick={() => onChange(o.id)}
          selected={o.id === value}
        >
          {o.label}
        </MenuItem>
      ))}
    </Box>
  );
};

export default ReservationLengthOptions;
