import React, { useState } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import InputBase, { InputBaseProps } from 'Components/Atoms/InputBase';
import { ArrowForward, SearchOutlined } from 'Components/Atoms/Icons';
import IconButton from 'Components/Atoms/IconButton';
import { Modify, Size, Translations } from 'utils/types';
import { useTranslation } from 'react-i18next';
import Close from '@material-ui/icons/Close';

export type SearchBarProps = Modify<
  InputBaseProps,
  {
    value: string | null;
    onChange: (newValue: string) => void | any;
    placeHolder?: string;
    width: string | number;
    onFocus?: React.FocusEventHandler<any>;
    onBlur?: React.FocusEventHandler<any>;
    disabled?: boolean;
    className?: string;
    outline?: boolean;
    boxStyle?: {};
    translation?: Translations;
    padding?: number | Size;
    onSubmit?: () => void;
  }
>;

const SearchBar = ({
  value,
  onChange,
  placeHolder = 'Search...',
  width,
  onFocus,
  onBlur,
  outline = true,
  boxStyle = { height: '40px' },
  className,
  padding = 10,
  ...props
}: SearchBarProps) => {
  const handleChange = (e: any) => {
    const newValue = e.target.value;
    onChange(newValue);
  };

  const { t } = useTranslation(props.translation || 'common');

  return (
    <Box
      flex
      outline={outline}
      padding={padding}
      width={width}
      style={boxStyle}
      className={className}
    >
      <IconButton className="iconButtonSize">
        <SearchOutlined style={{ width: 16.5, height: 16.5 }} />
      </IconButton>
      <InputBase
        {...props}
        className="searchInput"
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={t(placeHolder)}
        onChange={handleChange}
        value={value}
      />
      {!props.onSubmit && !!value && (
        <IconButton onClick={() => onChange('')}>
          <Close style={{ width: 16.5, height: 16.5 }} />
        </IconButton>
      )}
      {!!props.onSubmit && (
        <IconButton onClick={props.onSubmit}>
          <ArrowForward style={{ width: 16.5, height: 16.5 }} />
        </IconButton>
      )}
    </Box>
  );
};
export default SearchBar;
