import IconButton from "Components/Atoms/IconButton";
import { Add, Delete, Edit, Remove } from "Components/Atoms/Icons";
import Typography from "Components/Atoms/Typography";
import React from "react";
import { ReservationLengthRule } from "types/shifts";
import { blockToTime } from "utils/helper";

type ReservationRuleRowProps = {
  rule: ReservationLengthRule;
  occassions: { id: string; title: string }[];
  onDeleteRuleSet?: (rule: ReservationLengthRule) => void;
  openModalState?: (rule: ReservationLengthRule) => void;
  modifySlotLength: (
    rule: ReservationLengthRule | number,
    incBy: number,
    id?: "minLength" | "standard"
  ) => void;
  name?: string;
  id?: "minLength" | "standard";
  minLength?: number;
};

const ReservationRuleRow = ({
  rule,
  occassions,
  onDeleteRuleSet,
  modifySlotLength,
  name,
  id,
  openModalState,
  minLength = 1,
}: ReservationRuleRowProps) => {
  return (
    <tr key={rule.index}>
      <td className="rulesets-cell">
        {name ? (
          <Typography variant="text-2" translation="common">
            {name}
          </Typography>
        ) : (
          <>
            {rule.vip && (
              <Typography
                variant="text-2"
                translation="settings"
                style={{ paddingRight: 8 }}
              >
                Regular Guest
              </Typography>
            )}
            {rule.specialOccassion && (
              <Typography
                variant="text-2"
                translation="settings"
                style={{ paddingRight: 8 }}
              >
                Special Occassion
              </Typography>
            )}
            {!!rule.minGuests && (
              <Typography
                variant="text-2"
                translationKey="settings_minGuest"
                style={{ paddingRight: 8 }}
              >
                At least {{ min: rule.minGuests }} Guests
              </Typography>
            )}
            {!!rule.occassions && !!rule.occassions.length && (
              <Typography variant="text-2">
                -{" "}
                {rule.occassions
                  .map(
                    (occ) => occassions?.find((o) => o.id === occ)?.title ?? occ
                  )
                  .join(", ")}
              </Typography>
            )}
            {!!rule.from && (
              <Typography variant="text-2" style={{ paddingRight: 8 }}>
                ab {blockToTime(rule.from)}
              </Typography>
            )}
            {!!rule.till && (
              <Typography variant="text-2" style={{ paddingRight: 8 }}>
                {rule.from === undefined ? "bis" : "-"} {blockToTime(rule.till)}
              </Typography>
            )}
          </>
        )}
      </td>
      <td className="actions-cell">
        {!!openModalState && (
          <IconButton
            size="small"
            color="subdued"
            style={{ marginRight: 14 }}
            onClick={() => openModalState(rule)}
          >
            <Edit />
          </IconButton>
        )}
        {!!onDeleteRuleSet && (
          <IconButton
            size="small"
            style={{ marginRight: 14 }}
            color="subdued"
            onClick={() => onDeleteRuleSet(rule)}
          >
            <Delete />
          </IconButton>
        )}
      </td>
      <td className="plus-minus-cell">
        <IconButton size="small" disabled={rule.length === minLength}>
          <Remove
            onClick={() =>
              rule.length > minLength &&
              modifySlotLength(name ? rule.index : rule, -1, id)
            }
          />
        </IconButton>
      </td>
      <td className="length-cell">{blockToTime(rule.length)}</td>
      <td className="plus-minus-cell">
        <IconButton size="small">
          <Add
            onClick={() => modifySlotLength(name ? rule.index : rule, 1, id)}
          />
        </IconButton>
      </td>
    </tr>
  );
};

export default ReservationRuleRow;
