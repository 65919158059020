import React, { createContext, useContext, useEffect, useState } from 'react';

import { db } from 'utils/firebase';
import { asyncVoid, noop } from '../utils/helper';
import { RestaurantContext, Severity } from './RestaurantContext';
import PincodeModal from '../Components/Organisms/PincodeModal';
import useCollection from 'CustomHooks/useCollection';

export type Signature = {
  signature: string;
  name: string;
  code?: string | null;
  createdAt: number;
  lastUsed?: number;
};

export type PinCodeModalState = {
  open: boolean;
  match?: string; // this is the hashed pin that needs to be matched,
  error?: null | string;
  onValidCode?: () => Promise<void> | void;
  type?: 'validate' | 'new' | 'edit';
  signatureModalData?: Signature;
};

type SignatureContextType = {
  signatures: Signature[];
  isLoading: boolean;
  error?: string | null;
  addSignature: (s: Signature) => Promise<void>;
  editSignature: (s: Signature) => Promise<void>;
  deleteSignature: (sId: string) => Promise<void>;
  pincodeModalData: PinCodeModalState;
  setPincodeModalData: (val: PinCodeModalState) => void;
  addSignatureWithPincode: (s: Signature) => void;
  validateSignature: (s: string) => boolean;
};

interface ContextProps {
  restaurantId: null | string;
  children?: React.ReactNode;
  storyBook?: boolean;
}

export const SignatureContext = createContext<SignatureContextType>({
  signatures: [],
  isLoading: false,
  error: null,
  addSignature: asyncVoid,
  editSignature: asyncVoid,
  deleteSignature: asyncVoid,
  pincodeModalData: { open: false },
  setPincodeModalData: noop,
  addSignatureWithPincode: noop,
  validateSignature: () => false,
});

const SignatureContextProvider = ({
  restaurantId,
  children,
  storyBook,
}: ContextProps) => {
  const { newToast } = useContext(RestaurantContext);

  const [signatures] = useCollection<Signature>(
    `restaurants/${restaurantId}/signatures`
  );

  const [error, seterror] = useState<string | null>(null);
  const [pincodeModalData, setPincodeModalData] = useState<PinCodeModalState>({
    open: false,
  });

  async function hashPincode(p: string) {
    const encoder = new TextEncoder();
    const data = encoder.encode(p);
    const hash = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hash)); // convert buffer to byte array
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, '0'))
      .join(''); // convert bytes to hex string
    return hashHex;
  }

  function validateSignature(signature: string): boolean {
    let x = signatures.data.find((s) => s.signature === signature);
    if (x) return true;
    return false;
  }

  const addSignature = async (signature: Signature) => {
    console.log('trying to add this signature:', signature);
    const hashedPin = signature.code
      ? await hashPincode(signature.code).then(
          (hashed) => {
            return hashed;
          },
          () => {
            console.error('error while hashing');
          }
        )
      : null;
    try {
      await db
        .collection(`restaurants/${restaurantId}/signatures`)
        .doc(signature.signature)
        .set({ ...signature, code: hashedPin });
      setPincodeModalData({ open: false });
      newToast('Signature added successfully.', Severity.SUCCESS, 'settings');
    } catch (error: any) {
      console.error(error);
      seterror(error.message);
      newToast('Signature cannot be added.', Severity.WARNING, 'settings');
    }
  };

  const editSignature = async (signature: Signature) => {
    const hashedPin = signature.code
      ? await hashPincode(signature.code).then(
          (hashed) => {
            return hashed;
          },
          () => {
            console.error('error while hashing');
          }
        )
      : null;
    try {
      await signatures.ref
        .doc(signature?.signature ?? 'ask???')
        .update({ ...signature, code: hashedPin });
      setPincodeModalData({ open: false });
      newToast('Signature edited successfully.', Severity.SUCCESS, 'settings');
    } catch (error: any) {
      console.error(error);
      seterror(error.message);
    }
  };

  function addSignatureWithPincode(currentData: Signature) {
    setPincodeModalData({
      type: 'new',
      open: true,
      signatureModalData: currentData,
    });
  }

  const deleteSignature = async (signatureId: string) => {
    try {
      await db
        .collection(`restaurants/${restaurantId}/signatures`)
        .doc(signatureId)
        .delete();
      newToast('Signature deleted successfully.', Severity.SUCCESS, 'settings');
    } catch (error: any) {
      console.error(error);
      seterror(error.message);
    }
  };

  // console.log(pincodeModalData);

  // console.log({ signatures });

  return (
    <SignatureContext.Provider
      value={{
        signatures: signatures.data,
        isLoading: signatures.loading,
        error,
        addSignature,
        deleteSignature,
        pincodeModalData,
        setPincodeModalData,
        addSignatureWithPincode,
        editSignature,
        validateSignature,
      }}
    >
      <PincodeModal
        open={pincodeModalData.open}
        type={pincodeModalData.type}
        // match={pincodeModalData.signatureModalData?.code}
        signatureModalData={pincodeModalData.signatureModalData as Signature}
        onClose={() =>
          setPincodeModalData({ open: false, signatureModalData: undefined })
        }
        hashPincode={hashPincode}
      />
      {children}
    </SignatureContext.Provider>
  );
};

export default SignatureContextProvider;
