import {
  AnyShift,
  GuestOrderingShift,
  OffersShift,
  ReservationShift,
  ShiftType,
} from "types/shifts";
import { dateHelper, daysInFuture } from "./helper";

const TEMPLATES: Record<string, Partial<AnyShift>> = {
  "template-1": {
    name: "",
    type: ShiftType.RESERVATION,
    color: "#10CCB9",
    regular: true,
    priority: 0,
    weekDays: [],
    startAt: "",

    start: 72,
    service: 92,
    close: 96,

    additional: {
      blockedAt: [],
      slotsPerBlock: {
        rules: [],
        standard: 20,
      },
      reservationLength: {
        rules: [],
        standard: 8,
      },
      floorPlan: null,
      placeAt: null,
    },
    spaces: [],
    occassions: [],
  },
  "template-2": {
    name: "",
    type: ShiftType.RESERVATION,
    color: "#10CCB9",
    regular: false,
    closed: true,
    dates: [],
    priority: 0,
    startAt: "",
    start: 0,
    service: 96,
    close: 96,
    allDay: true,
    spaces: [],
    message: {
      de: "Restaurant ist geschlossen",
      en: "Restaurant is closed",
    },
    occassions: [],
  },
  "template-3": {
    name: "",
    type: ShiftType.RESERVATION,
    color: "#10CCB9",
    regular: true,
    closed: true,
    dates: [],
    weekDays: [0, 1, 2, 3, 4, 5, 6],
    priority: 0,
    startAt: "",
    endAt: "",
    start: 0,
    service: 96,
    close: 96,
    allDay: true,
    message: {
      de: "Restaurant ist geschlossen",
      en: "Restaurant is closed",
    },
    spaces: [],
    occassions: [],
  },
  "template-4": {
    name: "",
    type: ShiftType.RESERVATION,
    color: "#10CCB9",
    regular: false,
    closed: true,
    dates: [],
    priority: 0,
    startAt: "",
    start: 0,
    service: 96,
    close: 96,
    allDay: true,
    message: {
      de: "Restaurant ist aufgrund einer privaten Veranstaltung geschlossen",
      en: "Restaurant is closed because of a private Event",
    },
    spaces: [],
    occassions: [],
  },
  "template-5": {
    name: "",
    type: ShiftType.GUEST_ORDERING,
    color: "#10CCB9",
    regular: true,
    priority: 0,
    weekDays: [],
    startAt: "",
    start: 72,
    service: 92,
    close: 96,
    menu: null,
  },
  "template-6": {
    name: "",
    type: ShiftType.HAPPY_HOURS,
    color: "#10CCB9",
    regular: true,
    priority: 0,
    weekDays: [],
    startAt: "",
    start: 72,
    service: 92,
    close: 96,
    offers: [],
  },
  "template-7": {
    name: "Geschlossen",
    type: ShiftType.TAKEAWAY,
    color: "#10CCB9",
    regular: true,
    priority: 0,
    weekDays: [0, 1, 2, 3, 4, 5, 6],
    startAt: dateHelper(),
    endAt: daysInFuture(7),
    start: 0,
    service: 92,
    close: 96,
    deliveryMethods: ["delivery", "pickup"],
    closed: true,
    allDay: true,
  },
};

export default TEMPLATES;
