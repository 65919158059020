import React, { useCallback, useContext, useMemo } from 'react';
import './styles.scss';
import { Modify } from 'utils/types';
import {
  CurrentStatus,
  Reservation,
  Table as TableType,
} from 'types/reservations';
import Table from '../NewTable';
import {
  blockToTime,
  classHelper,
  findOverlaps,
  timeToBlock,
} from 'utils/helper';
import { ReservationContext } from 'Contexts/ReservationContext';
import useRestaurant from 'CustomHooks/useRestaurant';
import { useTableInfo } from 'CustomHooks/useTableInfo';

const sizeHelper = (size: number) => {
  if (size < 80) {
    return 'xsmall';
  } else if (size < 110) {
    return 'small';
  } else if (size < 130) {
    return 'medium';
  }
  return '';
};

export type TableWrapperProps = {
  table: Modify<TableType, { reservations: Reservation[] }>;
  touchingX?: string[];
  touchingY?: string[];
  editMode: null | {
    start: number | null;
    end: number | null;
    id: string;
    guests?: number;
    reservation?: Reservation;
  };
  isSelected: boolean;
  onTableClick: (
    id: string,
    name: string,
    space: string,
    available?: boolean,
    switchReservation?: string | 'blocked'
  ) => void;
  size: number;
  active: boolean;
  hasAlert?: boolean;
  currentTime: string | null;
  switchReservation?: string | 'blocked';
};

const TableWrapper: React.FC<TableWrapperProps> = ({
  table,
  editMode,
  isSelected,
  onTableClick,
  size,
  active,
  currentTime,
  hasAlert,
  switchReservation,
  touchingX = [],
  touchingY = [],
}) => {
  const position = useMemo(() => {
    if (table.type === '8Long' || table.type === '8L') {
      let direction = Math.abs(table.coords.direction % 180);

      let SIZE = table.coords.size * size;

      if (direction < 20 || direction > 170) {
        return {
          top: table.coords.y * size + SIZE * 0.3,
          left: table.coords.x * size,
          width: SIZE,
          height: SIZE * 0.6,
        };
      } else if (direction > 70 && direction < 110) {
        return {
          top: table.coords.y * size,
          left: table.coords.x * size + SIZE * 0.3,
          width: SIZE * 0.6,
          height: SIZE,
        };
      } else {
        return {
          top: table.coords.y * size,
          left: table.coords.x * size,
          width: SIZE,
          height: SIZE,
        };
      }
    }

    return {
      top: table.coords.y * size,
      left: table.coords.x * size,
      width: table.coords.size * size,
      height: table.coords.size * size,
      borderRadius: '50%',
    };
  }, [table, size]);

  const { showTimesInEditMode, shift, hideLiveResas } =
    useContext(ReservationContext);

  const { experimentalFlags } = useRestaurant();

  const tableInfo = useTableInfo(table.name);

  const matchShift = useCallback(
    (reservation: Reservation) => {
      if (
        !shift ||
        (reservation.currentStatus === CurrentStatus.SEATED && !hideLiveResas)
      )
        return true;
      const time =
        reservation.startTimeInBlocks ?? timeToBlock(reservation.time);

      return time >= shift.start && time <= shift.close;
    },
    [shift, hideLiveResas]
  );

  // const { reservationSettings } = useRestaurant();

  const onlyReservationsOfActiveShift = true;

  const times = useMemo(() => {
    return table.reservations
      .filter(
        (r) =>
          !r.done &&
          !r.blocked &&
          r.status !== 'failed' &&
          editMode?.id !== r.id &&
          (!editMode || showTimesInEditMode) &&
          (!!experimentalFlags?.data?.showAllTimesInEditMode ||
            !editMode?.start ||
            Math.abs(editMode.start - timeToBlock(r.time)) <
              (editMode.reservation?.reservationLength || 6)) &&
          (!onlyReservationsOfActiveShift || !shift || matchShift(r))
      )
      .map((r) => {
        let isNew = !!r.createdAt && r.createdAt > Date.now() - 30 * 60000;

        let time = r.time;
        let color = isNew ? 'var(--color-tertiary)' : 'var(--color-primary)';
        let textColor = isNew ? 'var(--color-white)' : 'var(--color-onPrimary)';
        let guests = r.guests;

        if (editMode) {
          let before = timeToBlock(r.time) > (editMode?.start || 0);

          time = !before
            ? `bis ${blockToTime(timeToBlock(r.time) + r.reservationLength)}`
            : `ab ${r.time}`;

          color = 'var(--color-tertiary)';
          textColor = '#ffffff';
        }

        let from = timeToBlock(time);

        return {
          id: r.id,
          color,
          textColor,
          time,
          guests,
          status: editMode ? CurrentStatus.NORMAL : r.currentStatus,
          customStatus: r.customData?.customStatus as string,
          from,
          till: from + r.reservationLength,
          fixed: !!r.fixed,
          partlySeated: !!r.partlySeated,
        };
      });
  }, [table, editMode, showTimesInEditMode]);

  const blocked = useMemo(() => {
    return !!table.reservations.find((r) => r.blocked);
  }, [table]);

  const available = useMemo(() => {
    if (!editMode || editMode?.start === null) return false;

    return table.reservations.every((r) => {
      let overlaps = findOverlaps(r, editMode.start || 0, editMode.end || 0);
      let sameReservation = r.id === editMode.id;

      // console.log(overlaps, editMode);

      return !overlaps || sameReservation;
    });
  }, [table, editMode]);

  const timeline = useMemo(() => {
    if (currentTime === null || !!editMode || hideLiveResas) return null;

    let activeRes = table.reservations.find(
      (r) => typeof r.started === 'number' && !r.done
    );

    if (activeRes) {
      let total =
        (activeRes?.endTimeInBlocks || 0) - (activeRes?.startTimeInBlocks || 0);

      let current = Math.min(
        total,
        timeToBlock(currentTime) - (activeRes?.startTimeInBlocks || 0)
      );

      return {
        current,
        total,
      };
    } else {
      return null;
    }
  }, [table, currentTime, times]);

  const status = useMemo(() => {
    let status: CurrentStatus | 'nonbookable' = CurrentStatus.NORMAL;

    if (blocked) {
      status = 'nonbookable';
    } else if (times.length) {
      status = times[0].status || CurrentStatus.NORMAL;
    } else if (!table.occassions.length) {
      status = 'nonbookable';
    }

    if (status === 'nonbookable' || status === CurrentStatus.NORMAL) {
      if (tableInfo.some((x) => x.open && x.balance)) {
        status = CurrentStatus.HAS_ORDERED;
      }
    }

    return status;
  }, [times, table, blocked, tableInfo]);

  return (
    <div
      style={position}
      onClick={() =>
        onTableClick(
          table.id,
          table.name,
          table.space,
          available,
          switchReservation
        )
      }
      className={classHelper(['table-wrapper', sizeHelper(position.height)])}
    >
      <Table
        table={table}
        size={size}
        status={status}
        times={times}
        active={(active && !editMode) || isSelected}
        blocked={!!blocked}
        alert={hasAlert}
        timeline={timeline}
        style={{ position: 'relative', zIndex: 1 }}
        available={available && !isSelected}
        switchReservation={switchReservation}
        touchingX={touchingX}
        touchingY={touchingY}
      />
    </div>
  );
};

export default TableWrapper;
