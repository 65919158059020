import React, { useMemo } from "react";
import MobileModal from "App/Mobile/Components/MobileModal";
import Box from "Components/Atoms/Box";
import Button from "Components/Atoms/Button";
import Typography from "Components/Atoms/Typography";
import useStatistics from "CustomHooks/useStatistics";
import { Date as DateType, ReservationShift } from "types/shifts";
import MobileShiftCard from "../MobileShiftCard";
import { toEuropeanDate, wDayHelper } from "utils/helper";

import "./styles.scss";

interface MobileReservationModalProps {
  update?: () => void;
  dateToModify: string;
  isReservationModalOpen: boolean;
  closeReservationModal: () => void;
}

const MobileReservationModal = ({
  update,
  dateToModify,
  isReservationModalOpen,
  closeReservationModal,
}: MobileReservationModalProps) => {
  const { date, dateData, submitDateData, setDateData } = useStatistics(
    dateToModify,
    update
  );

  const dateString = useMemo(() => {
    const day = wDayHelper(new Date(date).getDay(), true);
    return day + " " + toEuropeanDate(date);
  }, [date]);

  return (
    <MobileModal
      title="Close / Open Reservations"
      titleTranslation="reservations"
      isOpen={isReservationModalOpen}
      onClose={closeReservationModal}
      padding={0}
    >
      <Box className="mobile-edit-reservation-modal-container" padding="md">
        <Box className="mobile-modal-date-row-container">
          <Typography variant="text-3" translation="settings" color="subdued">
            Date:
          </Typography>
          <Typography variant="text-3">{dateString}</Typography>
        </Box>
        <Box className="shift-cards-container">
          {dateData?.shifts?.map((shift) => (
            <MobileShiftCard
              dateData={dateData}
              onChange={(nV: DateType) => setDateData(nV)}
              shift={shift as ReservationShift}
            />
          ))}
        </Box>
      </Box>
      <Box style={{ height: "8rem" }} />
      <Box
        className="mobile-edit-reservation-modal-footer mobile-actions-btns-bottom-and-gradient"
        padding="md"
      >
        <Button
          color="textPrimary"
          variant="outlined"
          translation="settings"
          onClick={() => closeReservationModal()}
          style={{
            backgroundColor: "var(--color-btn-default)",
            padding: "12px 0",
          }}
        >
          Cancel
        </Button>
        <Button
          color="textPrimary"
          variant="primary"
          translation="settings"
          onClick={() => {
            submitDateData();
            closeReservationModal();
          }}
          style={{ padding: "12px 0" }}
        >
          Save
        </Button>
      </Box>
    </MobileModal>
  );
};

export default MobileReservationModal;
