import Typography, { TypographyProps } from "Components/Atoms/Typography";
import Box, { BoxProps } from "Components/Atoms/Box";
import React, { useState } from "react";
import Divider from "Components/Atoms/Divider";
import { Size, Translations, Modify } from "utils/types";
import ScrollArea from "Components/Atoms/ScrollArea";
import { IconButton } from "@material-ui/core";
import {
  ArrowDownward,
  ArrowDropDown,
  ArrowDropUp,
  ArrowUpward,
} from "@material-ui/icons";

const Show = ({ open = true, ...params }: any) => {
  if (!open) {
    return <></>;
  }
  return <>{params.children}</>;
};
export interface CardProps extends BoxProps {
  title?: string | React.ReactNode | any[];
  hideHeader?: boolean;
  titleTranslation?: Translations;
  titleProps?: TypographyProps;
  headerPadding?: Size;
  titleAlignment?: "left" | "center" | "right";
  headerRight?: null | React.ReactNode;
  lowerHeader?: null | React.ReactNode;
  className?: string;
  children?: React.ReactNode;
  boxProps?: BoxProps;
  subHeader?: null | React.ReactNode;
  bodyProps?: BoxProps;
  style?: React.CSSProperties;
  elevation?: number;
  bottomPart?: React.ReactNode;
  hideBorders?: boolean;
  bottomPartClassName?: string;
  experimentalScroll?: boolean;
  closable?: boolean;
  opened?: boolean;
  badge?: number;
  titleCarousel?: boolean;
}

const Card: React.FC<CardProps> = ({
  title = "",
  titleProps,
  hideHeader = false,
  titleTranslation,
  headerRight,
  subHeader,
  titleAlignment = "left",
  headerPadding = "md",
  className,
  children,
  bodyProps,
  bottomPartClassName,
  style,
  elevation = 0,
  bottomPart,
  hideBorders = false,
  experimentalScroll = false,
  closable = false,
  opened = false,
  badge,
  titleCarousel = false,
  ...props
}) => {
  const [open, setopen] = useState(opened);

  return (
    <Box
      {...props}
      outline={!hideBorders}
      className={className}
      elevation={elevation}
      style={style}
    >
      {!hideHeader && (
        <Box
          padding={headerPadding}
          flex
          className="space-between rounded"
          style={{ paddingBottom: !!subHeader ? 8 : "auto", paddingLeft: 16 }}
          onClick={closable ? () => setopen((x) => !x) : undefined}
        >
          <Typography
            variant="h5"
            className="flex-grow"
            badge={badge}
            {...titleProps}
            translation={titleTranslation}
            textAlign={titleAlignment}
            carousel={titleCarousel}
          >
            {title}
          </Typography>
          <>
            {headerRight}
            {closable && (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setopen((x) => !x);
                }}
              >
                {!open ? <ArrowDropDown /> : <ArrowDropUp />}
              </IconButton>
            )}
          </>
        </Box>
      )}
      <Show open={!closable || open}>
        {subHeader}
        <Divider />
        {(!experimentalScroll || !window.ResizeObserver) && (
          <Box padding="md" {...bodyProps}>
            {children}
          </Box>
        )}
        {experimentalScroll && !!window.ResizeObserver && (
          <ScrollArea {...bodyProps}>{children}</ScrollArea>
        )}

        {!!bottomPart && (
          <>
            <Divider />
            <Box padding="md" className={bottomPartClassName}>
              {bottomPart}
            </Box>
          </>
        )}
      </Show>
    </Box>
  );
};

export default Card;
