import React, { useState } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import useLocalStorageState from 'CustomHooks/useLocalStorageState';
import useDocument from 'CustomHooks/useDocument';
import { Notes } from '@material-ui/icons';
import Modal from 'Components/Molecules/Modal';
import TextField from 'Components/Atoms/TextField';
import RichTextField from 'Components/Atoms/RichTextField';

export type AdminNotesProps = {
  restaurantId: string;
};

const AdminNotes: React.FC<AdminNotesProps> = ({ restaurantId }) => {
  const [open, setopen] = useLocalStorageState('adminNotes', false);

  const [noteDoc, setnoteDoc] = useDocument<{
    notes: string;
    editedAt: number;
  }>(`/adminNotes`, restaurantId);

  if (!open) {
    return (
      <Box
        onClick={() => setopen(true)}
        style={{
          position: 'fixed',
          width: 'auto',
          bottom: 20,
          right: 20,
          zIndex: 1000,
          borderRadius: 20,
          height: 40,
          padding: 8,
          backgroundColor: noteDoc.data?.notes?.length
            ? 'var(--color-primary)'
            : 'var(--color-border)',
        }}
      >
        <Notes style={{ height: 24, width: 24 }} />
      </Box>
    );
  }

  return (
    <Modal
      title="Notizen"
      open={open}
      onClose={() => setopen(false)}
      maxWidth="sm"
      fullWidth
    >
      <TextField
        value={noteDoc.data?.notes || ''}
        onChange={(e) =>
          setnoteDoc({ notes: e.target.value, editedAt: Date.now() })
        }
        onBlur={(e) => {
          noteDoc.ref.set({
            notes: noteDoc?.data?.notes ?? '',
            editedAt: Date.now(),
          });
        }}
        multiline
        minRows={6}
        fullWidth
      />
    </Modal>
  );
};

export default AdminNotes;
