import React, { useContext } from "react";
import {
  default as MaterialIconButton,
  IconButtonProps as MaterialIconButtonProps,
} from "@material-ui/core/IconButton";
import "./styles.scss";
import { StylesProvider } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import { Modify } from "utils/types";
import { RestaurantContext } from "Contexts/RestaurantContext";
import { Colors } from "../Typography";

type LinkTo = {
  (rId: string): string;
};

export interface IconButtonType
  extends Omit<MaterialIconButtonProps, "size" | "color"> {
  size?: "x-small" | "small" | "medium" | "large";
  style?: React.CSSProperties;
  className?: string;
  linkTo?: LinkTo | string;
  color?: Colors;
  href?: string;
  target?: string;
}

const IconButton = ({
  size = "large",
  style = {},
  color = `textPrimary`,
  className = "",
  linkTo,
  ...props
}: IconButtonType) => {
  const history = useHistory();

  const { restaurantId } = useContext(RestaurantContext);

  const onClick = linkTo
    ? () =>
        history?.push(
          typeof linkTo === "string" ? linkTo : linkTo(restaurantId || "")
        )
    : props.onClick;

  return (
    <MaterialIconButton
      className={`icon-btn-${size} ` + `color-${color} ` + className}
      component={props.href ? "a" : "button"}
      style={style}
      {...props}
      onClick={onClick}
    />
  );
};

export default IconButton;
