import { RestaurantContext } from 'Contexts/RestaurantContext';
import React, { useState, useEffect, useMemo, useContext } from 'react';
import { EditReservation, FloorPlan } from 'types/reservations';
import { ReservationShift } from 'types/shifts';
import { dateHelper, timeHelper, timeToBlock } from 'utils/helper';
import server from 'utils/server';
import { shiftStartOfWeek } from './useCalendar';
import useImpulse from './useImpulse';
import useCustomEvent from 'CustomHooks/useCustomEvent';

const useFloorPlan = (
  restaurantId: string | null,
  date: string,
  editReservation: EditReservation | null,
  floorPlans: FloorPlan[],
  isLightReservation: boolean = false
) => {
  const [shifts, setShifts] = useState<null | ReservationShift[]>(null);

  const triggerShiftReload = useCustomEvent('triggerShiftReload', () => {
    let newDate = editReservation?.date || date;

    setcurrentDate(newDate);
    setcurrentRestaurant(restaurantId);

    console.log('triggerShiftReload');

    let oldShift = shifts?.find((s) => s.id === currentShift);

    setloading(true);
    server
      .get(`/v03/shifts/${restaurantId}/${newDate}`)
      .then((response) => {
        let shifts = response.data.shifts as ReservationShift[];

        let cShift =
          shifts.find((s) => s.id === currentShift) ??
          shifts.find((s) => s.name === oldShift?.name);

        if (!cShift && currentShift !== null) {
          setCurrentShift(null);
        }

        if (shifts.length === 0) {
          setfloorPlanId(null);
          setclosed({ message: 'Restaurant is closed', error: false });
        } else if (shifts.length === 1) {
          let shift = shifts[0];
          setfloorPlanId(shift.additional?.floorPlan || null);
          if (shift.closed) {
            setclosed({ message: shift?.name || null, error: false });
          } else {
            setclosed(false);
          }
        } else if (cShift) {
          setfloorPlanId(cShift.additional?.floorPlan || null);
          setclosed(false);
        } else {
          //Put back the standard floorPlan
          const shiftFloorPlans = shifts
            .map((s) => s.additional?.floorPlan || null)
            .filter((fP) => !!fP);
          if (!shiftFloorPlans.length) {
            setfloorPlanId(null);
          } else if (new Set(shiftFloorPlans).size === 1) {
            setfloorPlanId(shiftFloorPlans[0]);
          } else {
            //To handle if several floor plans are set up for the different shifts of that day
            setfloorPlanId(null);
          }
          setclosed(false);
        }

        setloading(false);

        setShifts(shifts);
      })
      .catch((error) => {
        console.error(error);

        if (error.response) {
          setclosed({
            message:
              error.response?.data?.message ||
              'Netzwerk Fehler (Internetverbindung)',
            error: false,
          });
        } else {
          setclosed({
            message: 'Netzwerk Fehler (Internetverbindung)',
            error: false,
          });
        }
        setCurrentShift(null);
        setloading(false);
        setShifts([]);
      });
  });

  const [closed, setclosed] = useState<
    false | { message: string | null; error: boolean }
  >(false);
  const [loading, setloading] = useState<boolean>(true);
  const [floorPlanId, setfloorPlanId] = useState<null | string>(null);

  const [currentDate, setcurrentDate] = useState<string | null>(null);
  const [currentRestaurant, setcurrentRestaurant] = useState<string | null>(
    null
  );

  const [currentShift, setCurrentShift] = useState<null | string>(null);

  const [space, setspace] = useState<string | null>('');

  useEffect(() => {
    let newDate = editReservation?.date || date;

    if (
      restaurantId &&
      (currentDate !== newDate || currentRestaurant !== restaurantId)
    ) {
      setcurrentDate(newDate);
      setcurrentRestaurant(restaurantId);

      setloading(true);
      server
        .get(`/v03/shifts/${restaurantId}/${newDate}`)
        .then((response) => {
          let shifts = response.data.shifts as ReservationShift[];

          let cShift = shifts.find((s) => s.id === currentShift);

          if (!cShift && currentShift !== null) {
            setCurrentShift(null);
          }

          if (shifts.length === 0) {
            setfloorPlanId(null);
            setclosed({ message: 'Restaurant is closed', error: false });
          } else if (shifts.length === 1) {
            let shift = shifts[0];
            setfloorPlanId(shift.additional?.floorPlan || null);
            if (shift.closed) {
              setclosed({ message: shift?.name || null, error: false });
            } else {
              setclosed(false);
            }
          } else if (cShift) {
            setfloorPlanId(cShift.additional?.floorPlan || null);
            setclosed(false);
          } else {
            //Put back the standard floorPlan
            const shiftFloorPlans = shifts
              .map((s) => s.additional?.floorPlan || null)
              .filter((fP) => !!fP);
            if (!shiftFloorPlans.length) {
              setfloorPlanId(null);
            } else if (new Set(shiftFloorPlans).size === 1) {
              setfloorPlanId(shiftFloorPlans[0]);
            } else {
              //To handle if several floor plans are set up for the different shifts of that day
              setfloorPlanId(null);
            }
            setclosed(false);
          }

          setloading(false);

          setShifts(shifts);
        })
        .catch((error) => {
          console.error(error);

          if (error.response) {
            setclosed({
              message:
                error.response?.data?.message ||
                'Netzwerk Fehler (Internetverbindung)',
              error: false,
            });
          } else {
            setclosed({
              message: 'Netzwerk Fehler (Internetverbindung)',
              error: false,
            });
          }
          setCurrentShift(null);
          setloading(false);
          setShifts([]);
        });
    }
  }, [date, editReservation, restaurantId, triggerShiftReload]);

  const onSpaceChange = (id: string) => setspace(id);

  useEffect(() => {
    if (currentShift && !!shifts?.length) {
      let current = shifts.find((s) => s.id === currentShift);

      if (current && !isLightReservation) {
        setfloorPlanId(current.additional?.floorPlan || null);
      }
    }
  }, [currentShift, shifts]);

  const floorPlan = useMemo(() => {
    if (!floorPlans.length || isLightReservation) {
      return null;
    } else if (floorPlanId !== null) {
      let floorPlan = floorPlans.find((f) => f.id === floorPlanId);

      if (floorPlan) {
        return floorPlan;
      }
    }

    if (currentDate === dateHelper()) {
      let currentTime = timeToBlock(timeHelper());

      let shift = shifts?.find(
        (s) => s.start <= currentTime && s.close >= currentTime && !s.closed
      );

      if (shift?.additional?.floorPlan) {
        let floorPlan = floorPlans.find(
          (f) => f.id === shift?.additional?.floorPlan
        );

        if (floorPlan) {
          return floorPlan;
        }
      }
    }

    return floorPlans.find((f) => f.standard) || null;
  }, [floorPlans, floorPlanId, isLightReservation]);

  useEffect(() => {
    if (floorPlan) {
      if (!space || !floorPlan.spaces.find((s) => s.id === space)) {
        setspace(floorPlan.spaces?.[0]?.id || null);
      }
    } else {
      setspace(null);
    }
  }, [floorPlan]);

  // console.log({
  //     space,
  //     floorPlan,
  //     shifts,
  //     closed,
  //     loading,
  //     currentShift,
  // });

  const impulse = useImpulse(900000);

  const endOfShift = useMemo(() => {
    let today = dateHelper();

    if (date < today) return true;

    if (!shifts?.length || date > today) return false;

    const lastShift = shifts
      .filter((s) => !s.closed && !!s.close)
      .sort((a, b) => b.close - a.close)?.[0];

    return lastShift?.close <= timeToBlock(timeHelper()) + 1;
  }, [shifts, impulse, date]);

  return {
    space,
    endOfShift,
    onSpaceChange,
    floorPlan,
    shifts,
    closed,
    loading,
    currentShift,
    setCurrentShift,
    setShifts,
  };
};

export default useFloorPlan;
