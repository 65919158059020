import Box from "Components/Atoms/Box";
import Button from "Components/Atoms/Button";
import IconButton from "Components/Atoms/IconButton";
import { ArrowBack, Delete } from "Components/Atoms/Icons";
import TextField from "Components/Atoms/TextField";
import Tooltip from "Components/Atoms/Tooltip";
import Typography from "Components/Atoms/Typography";
import { ImageContext } from "Contexts/ImageContext";
import React, { useContext, useEffect, useState } from "react";
import { Image } from "types/images";

const ImageDetails = () => {
  const [image, setimage] = useState<Image | null>(null);

  const { setcurrentImage, images, currentImage, editImage, deleteImage } =
    useContext(ImageContext);

  const onClose = () => {
    setcurrentImage(null);
  };

  useEffect(() => {
    setimage(images.data.find((i) => i.id === currentImage) || null);
  }, [currentImage, images.data]);

  const handleAltChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = e.target;
    if (image && image.id) {
      setimage({ ...image, alt: value });
    }
  };

  const handleSaveAlt = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = e.target;
    if (!!image) {
      editImage(image.id, "alt", value);
    }
  };

  const handleAddTag = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const target = e.target as HTMLTextAreaElement;
    if (e.key === "Enter" && !!image) {
      let newTags = image.tags ? [...image.tags, target.value] : [target.value];
      editImage(image.id, "tags", newTags);
      target.value = "";
    }
  };

  const handleDeleteTag = (tag: string) => {
    if (!!image) {
      let newTags = image.tags ? image.tags.filter((t) => t !== tag) : [];
      editImage(image.id, "tags", newTags);
    }
  };

  if (!image) return <></>;

  return (
    <Box id="image-details">
      {/* Header */}
      <Box
        flex
        padding="md"
        style={{ borderBottom: "1px solid var(--color-border)" }}
      >
        <IconButton onClick={onClose} size="small">
          <ArrowBack />
        </IconButton>
        <Typography variant="text-3" style={{ marginLeft: 16 }}>
          {image.originalName}
        </Typography>
      </Box>

      {/* Image Miniature */}
      <Box
        className="center"
        padding={24}
        style={{ borderBottom: "1px solid var(--color-border)" }}
      >
        <img src={image.url} alt={image.alt} className="image-miniature" />
      </Box>

      {/* TextFields */}
      <Box
        className="flex-column flex-start"
        style={{ padding: "24px", paddingBottom: "16px" }}
      >
        <Typography
          variant="text-4"
          weight="regular"
          style={{ marginBottom: 8 }}
        >
          Add Alt Text (for SEO)
        </Typography>
        <TextField
          variant="outlined"
          className="outlined-input"
          fullWidth
          name="alt"
          value={image.alt}
          onBlur={handleSaveAlt}
          onChange={handleAltChange}
        />

        <Typography
          variant="text-4"
          weight="regular"
          style={{ marginBottom: 8, marginTop: 16 }}
        >
          Tags
        </Typography>
        <TextField
          variant="outlined"
          className="outlined-input"
          fullWidth
          name="tag"
          onKeyDown={handleAddTag}
        />
        <Box flex style={{ flexWrap: "wrap" }}>
          {image.tags &&
            image.tags.map((tag) => (
              <Tooltip
                content={
                  <Typography variant="text-4">Click to delete</Typography>
                }
              >
                <Typography
                  variant="text-4"
                  className="image-tag"
                  onClick={() => handleDeleteTag(tag)}
                >
                  {tag}
                </Typography>
              </Tooltip>
            ))}
        </Box>
      </Box>

      <Box className="center" style={{ padding: "0px 16px 16px 16px" }}>
        <Button
          endIcon={() => <Delete style={{ marginLeft: 8 }} />}
          variant="outlined"
          color="error"
          style={{
            padding: "5px 16px",
            border: "1px solid var(--color-critical)",
          }}
          onClick={() => deleteImage(image.id, true)}
        >
          Delete Image
        </Button>
      </Box>
    </Box>
  );
};

export default ImageDetails;
