import Typography, { TypographyProps } from "Components/Atoms/Typography";
import React, { useContext } from "react";
import "./styles.scss";
import { useHistory } from "react-router";
import { Modify } from "utils/types";
import { RestaurantContext } from "Contexts/RestaurantContext";

type LinkTo = {
  (rId: string): string;
};

export type ButtonProps = Modify<
  TypographyProps,
  {
    className?: string;
    linkTo?: LinkTo | string;
    href?: string;
    disabled?: boolean;
    target?: string;
  }
>;

const Button = ({
  linkTo,
  children,
  className = "",
  disabled = false,
  href,
  target,
  ...props
}: ButtonProps) => {
  const history = useHistory();

  const { restaurantId } = useContext(RestaurantContext);

  const onClick = () => {
    if (!disabled && linkTo) {
      history?.push(
        typeof linkTo === "string" ? linkTo : linkTo(restaurantId || "")
      );
    }
  };

  return (
    <Typography
      color={disabled ? "disabled" : "primary"}
      {...props}
      href={href}
      target={target}
      className={"link " + className}
      onClick={onClick}
    >
      {children}
    </Typography>
  );
};

export default Button;
