import { AuthContext } from 'Contexts/AuthContext';
import { RestaurantContext } from 'Contexts/RestaurantContext';
import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { Guest } from 'types/reservations';
import server from 'utils/server';

const toGuestObj = (guest: any) => ({ ...guest._source, id: guest._id });

type Props = {
  search: null | string;
  page?: number;
  perPage?: number;
};

const useGuestAutoComplete = ({
  search = '',
  page = 0,
  perPage = 10,
}: Props) => {
  const { restaurantId, uid } = useContext(AuthContext);

  const [guests, setguests] = useState<Guest[]>([]);
  const [loading, setloading] = useState(true);
  const [error, seterror] = useState<false | string>(false);
  const [hasMore, sethasMore] = useState(false);

  useEffect(() => {
    if (!search || search.length < 3) {
      setguests([]);
      setloading(false);
      seterror(false);
      sethasMore(false);
    }

    setloading(true);

    (async () => {
      try {
        const url = `https://api.gastronaut.ai/v03/guests/searchV02/${restaurantId}/${page}/${perPage}/${
          search?.startsWith('0') ? search.slice(1) : search
        }?sort=name`;

        const { data } = await axios.get<{
          results: {
            id: string;
            restaurant: string;
            name: string;
            email: string;
            phone: string;
            visits: number;
            vip: boolean;
            regular: boolean;
            blacklist: boolean;
            last_visit_date: string;
          }[];
        }>(url, {
          headers: { uid },
        });

        const { results } = data;

        sethasMore(results.length === perPage);
        setguests(
          results.map((r) => ({
            id: r.id,
            email: r.email,
            name: r.name,
            phone: r.phone,
            attr: [
              r.vip ? 'vip' : '',
              r.blacklist ? 'blacklist' : '',
              r.regular ? 'regular' : '',
            ].filter(Boolean),
            lastReservation: r.last_visit_date,
          }))
        );
        setloading(false);
        seterror(false);
      } catch (error: any) {
        console.error(error.message);
        seterror(error.message);
        setloading(false);
        sethasMore(false);
      }
    })();
  }, [search, perPage]);

  useEffect(() => {
    if (!page) return;

    setloading(true);

    (async () => {
      try {
        const url = `https://api.gastronaut.ai/v03/guests/searchV02/${restaurantId}/${page}/${perPage}/${
          search?.startsWith('0') ? search.slice(1) : search
        }?sort=name`;

        const { data } = await axios.get<{
          results: {
            id: string;
            restaurant: string;
            name: string;
            email: string;
            phone: string;
            visits: number;
            vip: boolean;
            regular: boolean;
            blacklist: boolean;
            last_visit_date: string;
          }[];
        }>(url, {
          headers: { uid },
        });

        const { results } = data;

        sethasMore(results.length === perPage);
        setguests(
          results.map((r) => ({
            id: r.id,
            email: r.email,
            name: r.name,
            phone: r.phone,
            attr: [
              r.vip ? 'vip' : '',
              r.blacklist ? 'blacklist' : '',
              r.regular ? 'regular' : '',
            ].filter(Boolean),
            lastReservation: r.last_visit_date,
          }))
        );
        setloading(false);
        seterror(false);
        seterror(false);
      } catch (error: any) {
        console.error(error.message);
        seterror(error.message);
        setloading(false);
        sethasMore(false);
      }
    })();
  }, [page]);

  return {
    guests,
    loading,
    error,
    hasMore,
  };
};

export default useGuestAutoComplete;
