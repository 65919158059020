import React from "react";

const ImageAttachmentIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M104 126H24C18.5 126 14 121.5 14 116V12C14 6.5 18.5 2 24 2H64.7C67.4 2 69.9 3 71.8 4.9L111.1 44.2C113 46.1 114 48.6 114 51.3V116C114 121.5 109.5 126 104 126ZM24 6C20.7 6 18 8.7 18 12V116C18 119.3 20.7 122 24 122H104C107.3 122 110 119.3 110 116V51.3C110 49.7 109.4 48.2 108.2 47.1L68.9 7.8C67.8 6.6 66.3 6 64.7 6H24Z"
        fill="#61BEE2"
      />
      <path
        d="M62 96C60.7 96 59.4 95.6 58.3 94.8L37.3 78.5C36.6 77.9 35.6 77.9 34.8 78.5L17.3 92.5L14.8 89.4L32.3 75.4C34.5 73.7 37.5001 73.6 39.7001 75.3L60.8 91.5C61.5 92 62.5001 92.1 63.2001 91.5L87.6 73C89.6 71.5 92.3 71.4 94.4 72.7L113.1 84.3L111 87.7L92.3 76.1C91.6 75.7 90.7 75.7 90 76.2L65.6 94.7C64.6 95.6 63.3 96 62 96Z"
        fill="#61BEE2"
      />
      <path
        d="M53 58C45.8 58 40 52.2 40 45C40 37.8 45.8 32 53 32C60.2 32 66 37.8 66 45C66 52.2 60.2 58 53 58ZM53 36C48 36 44 40 44 45C44 50 48 54 53 54C58 54 62 50 62 45C62 40 58 36 53 36Z"
        fill="#61BEE2"
      />
    </svg>
  );
};

export default ImageAttachmentIcon;
