export type Translation<T> = {
  language: string;
  data: Partial<T>;
};

export type Price = {
  currency: string;
  amount: number;
  per?: 'guest' | 'reservation';
  vatRate?: number;
  oldPrice?: number | null;
};

export type Rule = {
  id: string;

  active: boolean;
  title: string;

  wDays: number[] | null;
  dates: string[] | null;

  earliestTime: string | null;
  latestTime: string | null;

  minGuests: number | null;
  maxGuests: number | null;

  occassions: string[] | null;

  isNew?: boolean;
};

export enum AddOnType {
  SIMPLE = 'simple',
  SELECT = 'select',
  MULTIPLE_SELECT = 'multiple_select',
  TABLE_SELECT = 'table_select',
}

export type TimeInAdvance = {
  hours?: number | null;
  days?: number | null;
  time?: string | null;
  sameRuleForSunday: boolean;
};

export interface AddOnBase {
  id?: string;
  restaurantId: string;
  version: number;
  active: boolean;

  position?: number;

  title: string;
  description: string;
  longDescription: string;
  internalNote?: string;

  translations: Translation<{
    title: string;
    description: string;
    longDescription: string;
    internalNote?: string;
  }>[];

  image: string | null;

  type: AddOnType;

  rules: Rule[];

  maxGuests: {
    value: number;
    unit: 'guests' | 'reservations';
    type: 'simultaneous' | 'day' | 'startTime';
  } | null;

  minTimeInAdvance: TimeInAdvance | null;

  dontPairWith: string[];

  todoListTemplates: {
    id: string;
    title: string;
    description: string;
    timeInAdvance: TimeInAdvance | null;
  }[];
}

export type AddOnSimple = {
  type: AddOnType.SIMPLE;
  data: {
    price: Price;
  };
};

export type AddOnSelect = {
  type: AddOnType.SELECT;
  data: {
    choices: {
      id: number;
      active: boolean;

      title: string;
      description: string;

      translations: Translation<{
        title: string;
        description: string;
      }>[];

      price: Price;
    }[];

    defaultChoice: number | null;
  };
};

export type AddOnMultipleSelect = {
  type: AddOnType.MULTIPLE_SELECT;
  data: {
    choices: {
      id: number;
      active: boolean;

      title: string;
      description: string;

      translations: Translation<{
        title: string;
        description: string;
      }>[];

      price: Price;
    }[];

    defaultChoice: number;

    minChoices: number | null;
    maxChoices: number | null;
    defaultChoices: number | null;
  };
};

export type AddOnTableSelect = {
  type: AddOnType.TABLE_SELECT;
  data: {
    where?: {
      type: 'space' | 'table' | 'attribute' | 'occassion';
      ids: string[];
      all?: boolean;
      excludeCombinations?: boolean;
    }[];
    price: Price;
  };
};

export type AddOn = AddOnBase &
  (AddOnSimple | AddOnSelect | AddOnMultipleSelect | AddOnTableSelect);

export interface AddOnCounter {
  date: string;
  wDay: number;
  addOnId: string;
  reservations: {
    id: string;
    guests: number;
    from: number;
    till: number;
    occassion: string;
    tables: string[];
  }[];
}

export interface AddOnStatistics {
  count: number;
  guestCount: number;
  revenue: number;
  choices: {
    [title: string]: {
      count: number;
      guestCount: number;
      revenue: number;
    };
  };
  ratingsSum: number;
  ratingsCount: number;
}

let addOn: AddOn = {
  id: '123',
  restaurantId: '123',
  version: 1,
  active: true,
  title: 'title',
  description: 'description',
  longDescription: 'longDescription',
  translations: [],
  image: null,
  type: AddOnType.SIMPLE,
  rules: [],
  maxGuests: {
    value: 10,
    unit: 'guests',
    type: 'simultaneous',
  },
  minTimeInAdvance: {
    hours: null,
    days: 1,
    time: '10:00',
    sameRuleForSunday: true,
  },
  data: {
    price: {
      currency: 'EUR',
      amount: 10,
      per: 'guest',
      vatRate: 19,
    },
  },
  dontPairWith: [],
  todoListTemplates: [],
};
