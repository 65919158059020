import React, { useContext, useMemo } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import { Day } from 'types/calendar';
import dayjs from 'dayjs';
import Typography from '../../../../Components/Atoms/Typography';
import {
  AnyShift,
  GuestOrderingShift,
  OffersShift,
  ReservationShift,
  ShiftType,
} from 'types/shifts';
import { shifts } from 'App/Calendar/TestData';
import { Lock, NotInterested, Star } from 'Components/Atoms/Icons';
import { noop } from 'lodash';
import { ActiveShift } from 'CustomHooks/useCalendarDates';
import {
  CalendarContext,
  checkActiveShift,
  ShiftActionType,
} from 'Contexts/CalendarContext';
import { Menu, MenuItem } from '@material-ui/core';
import useDocument from 'CustomHooks/useDocument';
import useRestaurant from 'CustomHooks/useRestaurant';
import { ArrowDropDown as DropdownIcon } from '@material-ui/icons';
import server from 'utils/server';
import { Severity } from 'Contexts/RestaurantContext';
import { dateHelper } from 'utils/helper';
import useAuth from 'CustomHooks/useAuth';

type Props = Day & {
  width: number;
  shifts?: AnyShift[];
  onShiftClick?: (id: string) => void;
  selected?: string;
  activeShift?: ActiveShift<AnyShift> | undefined;
  deletedV02?: { id: string; name: string }[];
  hasDeleted?: boolean;
};

const DayNumber: React.FC<{
  backgroundColor: string;
  dayNumber: string;
  deletedV02?: { id: string; name: string }[];
  date: string;
}> = ({ backgroundColor, dayNumber, deletedV02 = [], date }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [modal, setmodal] = React.useState<'recalc' | 'deleted'>('deleted');

  const handleClick =
    (modal: 'recalc' | 'deleted' = 'deleted') =>
    (event: any) => {
      event.preventDefault();
      if (date < dateHelper()) return;
      setAnchorEl(event.currentTarget);
      setmodal(modal);
    };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { onShiftAction, type, setdates } = useContext(CalendarContext);
  const { restaurantId, newToast } = useRestaurant();
  const { isGastronautAdmin } = useAuth();

  const recalculate = () => {
    if (!isGastronautAdmin) {
      alert('Bitte frag den Gastronaut Support');
      return;
    }

    server
      .get(`/v03/shifts/${restaurantId ?? ''}/${date}/${type}?recalculate=true`)
      .then(({ data }) => {
        newToast('Shift was Recalculated', Severity.SUCCESS);
        setdates((d) => d.map((x) => (x.date === date ? data : x)));
      })
      .catch((err) => {
        newToast(err.message, Severity.ERROR);
      });
  };

  return (
    <>
      <Box
        className="day-number center"
        style={{
          backgroundColor,
          cursor: deletedV02.length ? 'pointer' : 'initial',
        }}
        {...(deletedV02.length
          ? {
              onClick: handleClick('deleted'),
              onContextMenu: handleClick('recalc'),
            }
          : { onContextMenu: handleClick('recalc') })}
      >
        <Typography style={{ whiteSpace: 'nowrap' }}>
          {dayNumber}{' '}
          {deletedV02?.length ? (
            <DropdownIcon
              style={{
                display: 'inline-block',
                maxWidth: 18,
                maxHeight: 18,
                borderRadius: 6,
                marginBottom: -3,
                color: 'var(--color-onPrimary)',
              }}
            />
          ) : (
            <></>
          )}
        </Typography>
      </Box>
      <Menu
        id={`DayNumber-${dayNumber}`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl) && modal === 'deleted'}
        onClose={handleClose}
      >
        <Typography
          component="p"
          variant="text-4"
          style={{ padding: '0 14px' }}
        >
          Gelöschte Schichten wiederherstellen?
        </Typography>
        {deletedV02.map((d) => (
          <DeletedShift
            shiftId={d.id}
            key={d.id}
            name={d.name}
            onClick={() => {
              // console.log("type TESTING");
              onShiftAction(
                d.id,
                date,
                ShiftActionType.BRING_BACK_FROM_DELETED
              );
              handleClose();
            }}
          />
        ))}
      </Menu>
      <Menu
        id={`DayNumber-${dayNumber}-recalc`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl) && modal === 'recalc'}
        onClose={handleClose}
      >
        <Typography
          component="p"
          variant="text-4"
          style={{ padding: '0 14px' }}
        >
          Recalculate
        </Typography>
        <MenuItem
          onClick={() => {
            recalculate();
            // console.log('type TESTING 3');
          }}
        >
          Tag neu laden
        </MenuItem>
      </Menu>
    </>
  );
};

const DeletedShift: React.FC<{
  shiftId: string;
  name: string;
  onClick: () => void;
}> = ({ shiftId, name, onClick }) => {
  const { restaurantId } = useRestaurant();

  // const [shift] = useDocument<ReservationShift>(
  //   `restaurants/${restaurantId}/shiftsV02`,
  //   shiftId
  // );

  // if (shift.loading) {
  //   return <MenuItem>Loading...</MenuItem>;
  // }

  // if (!shift.data) {
  //   return <MenuItem>{shift.error ?? "Schicht nicht gefunden"}</MenuItem>;
  // }

  return (
    <MenuItem
      onClick={() => {
        onClick();
        // console.log('type TESTING 2');
      }}
    >
      {name || shiftId} wiederherstellen
    </MenuItem>
  );
};

const DayHeader: React.FC<Props> = ({
  date,
  wDay,
  deletedV02 = [],
  hasDeleted = false,
  today,
  holiday,
  width,
  shifts = [],
  onShiftClick = noop,
  selected,
  activeShift = undefined,
}) => {
  const dayOfWeek = dayjs(date).format('d');
  const dayNumber = dayjs(date).format('D');
  const textColor = today ? '#10ccb9' : '#718096';
  const backgroundColor = today ? '#10ccb9' : 'transparent';
  const holidayVariant = holiday && holiday.length < 10 ? 'text-4' : 'text-5';

  const filteredShifts = useMemo(
    () => shifts.filter((s) => s.allDay || (s.start === 0 && s.close === 96)),
    [shifts]
  );

  function dayOfWeekTranslator() {
    switch (dayOfWeek) {
      case '0':
        return 'Sun';
      case '1':
        return 'Mon';
      case '2':
        return 'Tue';
      case '3':
        return 'Wed';
      case '4':
        return 'Thu';
      case '5':
        return 'Fri';
      case '6':
        return 'Sat';
    }
  }

  //todo get parent width, minWidth 96
  return (
    <Box flex className="dayheader-container flex-column">
      <Box className="day-text center">
        <Typography variant="text-5" color={today ? 'primary' : 'subdued'}>
          {dayOfWeekTranslator()} {deletedV02?.length ? '*' : ''}
        </Typography>
      </Box>
      <DayNumber {...{ deletedV02, dayNumber, backgroundColor, date }} />
      <Box className="holiday center">
        {holiday && width >= 110 && <div className="circle"></div>}
        <Typography
          variant={holidayVariant}
          color="subdued"
          style={{ textAlign: 'center' }}
        >
          {holiday}
        </Typography>
      </Box>
      <Box className="bottom-container" />
      <Box className="shiftContainer">
        {filteredShifts.map((shift) => {
          const active =
            selected === shift.id ||
            checkActiveShift(activeShift, shift.id, date);

          const commentOnly = !!(shift as any)?.commentOnly;

          let borderColor = active
            ? 'var(--color-text)'
            : shift.color ?? 'var(--color-primary-default)';

          let background = commentOnly
            ? 'var(--color-bg)'
            : shift.color ?? 'var(--color-primary-default)';

          let color = commentOnly
            ? 'var(--color-text)'
            : 'var(--color-primary-5)';

          let border = `3px solid ${borderColor}`;

          let opacity = shift.disabled || !shift.active ? 0.5 : 1;

          return (
            <Box
              key={shift.id}
              className="shiftAllDay"
              style={{
                width: '100%',
                background,
                opacity,
                border,
                color,
              }}
              onClick={() => onShiftClick(shift.id)}
            >
              {!shift.regular &&
              shift.type === ShiftType.RESERVATION &&
              !shift.closed ? (
                <Star width={10} height={10} />
              ) : null}
              {shift.type === ShiftType.RESERVATION && shift.closed ? (
                <NotInterested width={10} height={10} />
              ) : null}
              <Typography
                className="primary-text"
                variant="text-4"
                color="inherit"
                style={{ fontWeight: 'bolder' }}
              >
                {shift.name}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default DayHeader;
