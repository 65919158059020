import React, { useState, useRef } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import { Guest } from 'types/reservations';
import TextField from 'Components/Atoms/TextField';
import Popover from 'Components/Atoms/Popover';
import Button from 'Components/Atoms/Button';
import { PersonAdd } from 'Components/Atoms/Icons';
import Typography from 'Components/Atoms/Typography';
import { noop } from 'utils/helper';
import useGuestAutoComplete from 'CustomHooks/useGuestAutoComplete';

export type GuestDropdownProps = {
  value: null | Guest;
  onChange: (guest: Guest) => void;
};

const EMPTY_GUEST: Guest = {
  name: '',
  guestId: '',
  phone: '',
  email: '',
  hostComment: '',
  comment: '',
  attr: [],
};

const GuestDropdown = ({ value, onChange = noop }: GuestDropdownProps) => {
  const { guests: searchResults } = useGuestAutoComplete({
    search: value?.name || '',
  });

  const [anchorEl, setanchorEl] = useState<null | HTMLElement>(null);

  const timeout = useRef<null | NodeJS.Timeout>(null);

  const handleFocus = (e: any) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
    setTimeout(() => {
      setanchorEl(e.target);
    }, 200);
  };

  const handleBlur = () => {
    if (!anchorEl) return;

    timeout.current = setTimeout(() => {
      setanchorEl(null);
    }, 400);
  };

  const handleNewGuest = () => {
    onChange({
      ...EMPTY_GUEST,
      phone: value?.phone ?? '',
      email: value?.email ?? '',
      company: value?.company ?? '',
      comment: value?.comment ?? '',
      name: value?.name ?? '',
      createNew: true,
    });
    handleBlur();
  };

  const handleChange = (e: any) => {
    if (!anchorEl) {
      setTimeout(() => {
        setanchorEl(e.target);
      }, 200);
    }
    const name = e.target.value;
    onChange(
      value ? { ...value, name, createNew: false } : { ...EMPTY_GUEST, name }
    );
  };

  const handleGuestClick = (id: string) => {
    let guest = searchResults.find((g) => id === g.id);
    onChange({ ...EMPTY_GUEST, ...(guest || {}) });
    handleBlur();
  };

  return (
    <Box className="GuestDropdown">
      <TextField
        value={value?.name || ''}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        fullWidth
        required
        label="Name of Guest"
        labelTranslation="reservations"
      />
      <Popover
        anchorEl={anchorEl}
        onClose={handleBlur}
        open={!!anchorEl}
        placement="left-start"
        padding={0}
      >
        <Box style={{ maxHeight: 300, overflow: 'scroll' }}>
          {searchResults.map((res) => (
            <Box
              key={res.id}
              padding="sm"
              underline
              onClick={() => handleGuestClick(res?.id || '')}
              className="guest"
            >
              <Typography block variant="text-3">
                {res.name}
              </Typography>
              <Typography block variant="text-4" color="subdued">
                {res.phone}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box padding="sm">
          <Button
            fullWidth
            endIcon={(p) => <PersonAdd {...p} />}
            onClick={handleNewGuest}
          >
            New Guest
          </Button>
        </Box>
      </Popover>
    </Box>
  );
};

export default GuestDropdown;
