import React, { useContext, useMemo } from "react";
import Box from "../../../../../Components/Atoms/Box";
import { AnyShift } from "../../../../../types/shifts";

import Typography from "Components/Atoms/Typography";
import { LanguageContext } from "Contexts/LanguageContext";
import { EventDescription } from "App/Calendar/Components/EventSettings";
import useCollection from "CustomHooks/useCollection";
import { TicketDocument } from "gastronaut-shared/types/documents";
import { Event } from "gastronaut-shared/types/documents/restaurants";

export const useEventCard = (
  event: Event | null | undefined,
  date: string,
  restaurantId: string
) => {
  const { language } = useContext(LanguageContext);

  const description = useMemo(
    () => EventDescription(event, language),
    [language, event]
  );

  const [ticketData] = useCollection<TicketDocument>(`ticketsV02`, {
    filter: [
      ["date", "==", date],
      ["restaurant", "==", restaurantId],
    ],
  });

  const ticketSummary = useMemo(() => {
    if (ticketData.loading) {
      return ["Loading Tickets..."];
    } else if (ticketData.error) {
      return [ticketData.error];
    } else if (!ticketData.data.filter((t) => t.eventId === event?.id).length) {
      return ["Keine Tickets verkauft"];
    } else {
      let arr: string[] = [];

      let ticketsMissingPayments = ticketData.data
        .filter(
          (t) =>
            t.eventId === event?.id &&
            (t.createdAt ?? Date.now()) >= Date.now() - 24 * 60 * 60000 &&
            t.status === "missing_payments"
        )
        .reduce((acc, cV) => acc + cV.guests, 0);
      let tickets = ticketData.data.filter(
        (t) =>
          t.eventId === event?.id &&
          (t.status === "succedded" || t.status === "processing")
      );
      // let failed = ticketData.data.filter(t => t.status === 'failed').reduce((acc, cV) => acc + cV.guests, 0);

      if (tickets.length) {
        let ticketCount = tickets.reduce((acc, cV) => acc + cV.guests, 0);
        let turnover = tickets.reduce((acc, cV) => acc + cV.amount, 0);
        arr.push(`Verkaufte Tickets: ${ticketCount} (${turnover}€)`);
      }

      if (ticketsMissingPayments) {
        arr.push(`Zahlung Ausstehend: ${ticketsMissingPayments}`);
      }

      if ((event?.options?.length ?? 0) > 1) {
        let optionMap = tickets.reduce((acc, cV) => {
          let x = { ...acc };

          Object.entries(cV.options ?? {}).forEach(([id, val]) => {
            if (val !== 0) {
              x[id] = (x[id] ?? 0) + val;
            }
          });

          return x;
        }, {} as Record<string, number>);

        let options = Object.entries(optionMap)
          .sort((a, b) => b[1] - a[1])
          .map(([id, val]) => {
            let opt = event?.options?.find((x) => x.id === id);

            return `${
              opt?.translation?.["de"]?.title ?? opt?.internalName ?? id
            }: ${val}`;
          });

        if (options.length) {
          arr.push("Optionen:", ...options);
        }
      }

      let addOnMap = tickets.reduce((acc, cV) => {
        let x = { ...acc };

        Object.entries(cV.optionalAddOns ?? {}).forEach(([id, val]) => {
          if (val !== 0) {
            x[id] = (x[id] ?? 0) + val;
          }
        });

        return x;
      }, {} as Record<string, number>);

      let addOns = Object.entries(addOnMap)
        .sort((a, b) => b[1] - a[1])
        .map(([id, val]) => {
          let opt = event?.optionalAddOns?.find((x) => x.id === id);

          return `${
            opt?.translation?.["de"]?.title ?? opt?.internalName ?? id
          }: ${val}`;
        });

      if (addOns.length) {
        arr.push("AddOns:", ...addOns);
      }

      return arr;
    }
  }, [ticketData]);

  return {
    description,
    ticketSummary,
    tickets: ticketData.data,
  };
};

const EventCard: React.FC<{
  shift: AnyShift;
  date: string;
  restaurantId: string;
}> = ({ shift, date, restaurantId }) => {
  const { description, ticketSummary } = useEventCard(
    shift?.additional?.eventV02,
    date,
    restaurantId
  );

  return (
    <Box
      style={{
        padding: 10,
        borderRadius: 4,
        background: "var(--color-btn-default)",
      }}
    >
      <Typography
        block
        color="textPrimary"
        variant="h5"
        style={{ marginBottom: 3 }}
      >
        {shift.additional?.eventV02?.title?.de || "Kein Event Name"}
      </Typography>
      <Typography
        component="p"
        color="subdued"
        variant="text-3"
        style={{
          justifySelf: "flex-start",
          alignSelf: "flex-start",
          marginBottom: 6,
        }}
      >
        {description}
      </Typography>
      {!shift.additional?.eventV02?.active && (
        <Typography
          component="p"
          color="error"
          variant="text-3"
          translation="calendar"
          style={{
            justifySelf: "flex-start",
            alignSelf: "flex-start",
            marginBottom: 6,
          }}
        >
          Event is deactivated
        </Typography>
      )}
      {ticketSummary.map((line, i) => (
        <Typography
          key={i}
          component="p"
          variant="text-3"
          style={{
            justifySelf: "flex-start",
            alignSelf: "flex-start",
            marginBottom: 2,
          }}
        >
          {line}
        </Typography>
      ))}
    </Box>
  );
};

export default EventCard;
