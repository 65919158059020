import Box from "Components/Atoms/Box";
import Button from "Components/Atoms/Button";
import React from "react";
import {
  LockOpenOutlined,
  Warning,
  LockOutlined,
} from "Components/Atoms/Icons";
import { OffersShift } from "types/shifts";
import { ShiftActionType } from "Contexts/CalendarContext";
import { Date as DateType } from "types/shifts";

type ActionButtonsProps = {
  currentShift: OffersShift;
  onShiftAction: (
    id: string,
    date: string,
    type: ShiftActionType,
    payload?: any
  ) => void;
  date: DateType<OffersShift>;
};

const ActionButtons: React.FC<ActionButtonsProps> = ({
  currentShift,
  onShiftAction,
  date,
}) => {
  return (
    <Box>
      {currentShift?.closed ? (
        <Button
          translation="calendar"
          fullWidth
          variant="outlined"
          color="error"
          endIcon={() => (
            <LockOutlined
              color="inherit"
              style={{ marginLeft: 8, width: 20, height: 20 }}
            />
          )}
          onClick={() =>
            onShiftAction(
              currentShift?.id ?? "",
              date.date,
              ShiftActionType.OPEN_RESERVATIONS
            )
          }
          style={{ border: "1px solid var(--color-critical)" }}
        >
          Re-open for the day
        </Button>
      ) : (
        <Button
          fullWidth
          translation="calendar"
          variant="outlined"
          color="error"
          endIcon={() => (
            <LockOpenOutlined
              color="inherit"
              style={{ marginLeft: 8, width: 20, height: 20 }}
            />
          )}
          onClick={() =>
            onShiftAction(
              currentShift?.id ?? "",
              date.date,
              ShiftActionType.CLOSE_RESERVATIONS
            )
          }
          style={{ border: "1px solid var(--color-critical)" }}
        >
          Disable for the Day
        </Button>
      )}
      <Button
        fullWidth
        onClick={() =>
          onShiftAction(
            currentShift?.id ?? "",
            date.date,
            ShiftActionType.EDIT_SHIFT
          )
        }
        color="secondary"
        variant="secondary"
        style={{ margin: "12px 0px" }}
      >
        Edit
      </Button>
      <Button
        fullWidth
        variant="outlined"
        color="error"
        endIcon={() => (
          <Warning
            color="inherit"
            style={{ marginLeft: 8, width: 20, height: 20 }}
          />
        )}
        onClick={() =>
          onShiftAction(
            currentShift?.id ?? "",
            date.date,
            ShiftActionType.DELETE_SHIFT
          )
        }
        style={{ border: "1px solid var(--color-critical)" }}
        translation="calendar"
      >
        Delete
      </Button>
    </Box>
  );
};

export default ActionButtons;
