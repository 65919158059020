import React, { useContext, useEffect, useState } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import { ReservationContext } from 'Contexts/ReservationContext';
import IconButton from 'Components/Atoms/IconButton';
import { dateHelper } from 'utils/helper';
import ShuffleModeIcon from 'Components/Atoms/Icons/ShuffleModeIcon';
import { Close, Error, Info, Settings, Warning } from '@material-ui/icons';
import Badge from 'Components/Atoms/Badge';
import Popover from 'Components/Atoms/Popover';
import { shuffle } from 'lodash';
import Typography from 'Components/Atoms/Typography';
import TextField from 'Components/Atoms/TextField';
import MenuItem from 'Components/Atoms/MenuItem';
import Checkbox from 'Components/Atoms/Checkbox';
import Button from 'Components/Atoms/Button';
import { useLayoutEffect } from '@react-spring/shared';
import useAuth from 'CustomHooks/useAuth';
import useRestaurant from 'CustomHooks/useRestaurant';

export type ShuffleButtonProps = {
  date: string;
};

const ShuffleButton: React.FC<ShuffleButtonProps> = ({ date }) => {
  const [settingsOpen, setSettingsOpen] = useState<HTMLElement | null>(null);
  const [warningsOpen, setWarningsOpen] = useState<HTMLElement | null>(null);

  const [infosOpen, setInfosOpen] = useState<HTMLElement | null>(null);

  const { apps } = useRestaurant();
  const { Shuffle, editReservation, reservationSettings } =
    useContext(ReservationContext);
  const { isGastronautAdmin } = useAuth();

  const inPast = date < dateHelper();

  useLayoutEffect(() => {
    if (Shuffle.state && !editReservation) {
      if (!window.localStorage.getItem('shuffleStateUsed')) {
        window.localStorage.setItem('shuffleStateUsed', 'true');
        setInfosOpen(document.getElementById('infosOpen'));
      }
    } else {
      setWarningsOpen(null);
      setInfosOpen(null);
      setSettingsOpen(null);

      if (editReservation) {
        Shuffle.set(null);
      }
    }
  }, [Shuffle.state, editReservation]);

  if (Shuffle.state) {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          position: 'absolute',
          bottom: -2,
          left: -2,
          zIndex: 10,
          padding: '6px 4px',
          borderRadius: 21,
          background: 'var(--color-secondary)',
          color: 'white',
          gap: 8,
          width: 42,
        }}
      >
        {!!Shuffle.state.scoreDiff && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyItems: 'center',
              marginTop: 4,
              marginBottom: -2,
            }}
          >
            <Typography
              textAlign="center"
              variant="text-4"
              weight="bold"
              style={{ marginBottom: 0 }}
            >
              {Shuffle.state.scoreDiff > 1 && '+'}
              {Math.round((Shuffle.state.scoreDiff - 1) * 100)}%
            </Typography>
            <Typography textAlign="center" variant="text-5">
              Platz
            </Typography>
          </div>
        )}
        <IconButton
          id="infosOpen"
          onClick={(e) => {
            setSettingsOpen(null);
            setInfosOpen((s) => (!s ? e.currentTarget : null));
            setWarningsOpen(null);
          }}
          size="medium"
        >
          <Info color="inherit" />
        </IconButton>
        <Popover
          open={!!Shuffle.state && !!infosOpen}
          anchorEl={infosOpen}
          width={300}
          placement="right"
          onClose={() => setInfosOpen(null)}
        >
          <Box padding="sm">
            <Typography variant="text-2" weight="bold">
              Anleitung
            </Typography>
            <Typography
              component="p"
              style={{ marginBottom: 8 }}
              variant="text-3"
              color="subdued"
            >
              Mit dem Shuffle Modus können Reservierungen vom System neu Tischen
              zugewiesen werden um Fehler aufzulösen und die Auslastung zu
              optimieren.
            </Typography>
            <Typography
              component="p"
              style={{ marginBottom: 8 }}
              variant="text-3"
              color="subdued"
            >
              Drücke "Neu Anordnen" (Rechts unten) um eine Vorschau zu erhalten.
              In der Vorsch kann nichts verschoben werden. Wenn das Ergebnis
              überzeugt kann die Änderung gespeichert werden.
            </Typography>
            <Typography
              component="p"
              style={{ marginBottom: 8 }}
              variant="text-3"
              color="subdued"
            >
              In der Seitenleist wird man über Warnungen hingewiesen falls es
              welche gibt.
            </Typography>
            <Typography
              component="p"
              style={{ marginBottom: 8 }}
              variant="text-3"
              color="subdued"
            >
              Zudem kann man über den Einstellungsknopf Reservierungen bevorzugt
              im Innen oder Außenbereich platzieren.
            </Typography>
          </Box>
        </Popover>
        {!!Shuffle.warnings && (
          <IconButton
            onClick={(e) => {
              setSettingsOpen(null);
              setInfosOpen(null);
              setWarningsOpen((s) => (!s ? e.currentTarget : null));
            }}
            size="medium"
          >
            <Badge
              color={Shuffle.hasErrors ? 'error' : 'secondary'}
              number={Shuffle.warnings}
            >
              <Warning color="inherit" />
            </Badge>
          </IconButton>
        )}
        <Popover
          open={!!Shuffle.state && !!warningsOpen}
          anchorEl={warningsOpen}
          width={300}
          placement="right"
          onClose={() => setWarningsOpen(null)}
        >
          <Box>
            <Typography variant="text-2" weight="bold">
              Warnungen
            </Typography>
            {!!Shuffle.state?.analysis?.noTables && (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  marginBottom: 4,
                  alignItems: 'center',
                  width: 'fit-content',
                }}
              >
                <Error
                  color="error"
                  style={{ marginRight: 4, fontSize: '1rem' }}
                />
                <Typography variant="text-3" color="subdued">
                  Reservierungen ohne Tische:{' '}
                  {Shuffle.state?.analysis?.noTables ?? 0}
                </Typography>
              </Box>
            )}
            {!!Shuffle.state?.analysis?.overlaps && (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  marginBottom: 4,
                  alignItems: 'center',
                }}
              >
                <Error
                  color="error"
                  style={{ marginRight: 4, fontSize: '1rem' }}
                />
                <Typography variant="text-3" color="subdued">
                  Überschneidende Reservierungen:{' '}
                  {Shuffle.state?.analysis?.overlaps ?? 0}
                </Typography>
              </Box>
            )}
            {!!Shuffle.state?.analysis?.unfitingTables && (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  marginBottom: 4,
                  alignItems: 'center',
                }}
              >
                <Warning
                  htmlColor="var(--color-warning)"
                  style={{ marginRight: 4, fontSize: '1rem' }}
                />
                <Typography variant="text-3" color="subdued">
                  Reservierungen mit nicht passenden Tischen:{' '}
                  {Shuffle.state?.analysis?.unfitingTables ?? 0}
                </Typography>
              </Box>
            )}
            {!!Shuffle.state?.useBlockedTablesResult && (
              <Box style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Warning
                  htmlColor="var(--color-warning)"
                  style={{ marginRight: 4, fontSize: '1rem' }}
                />
                <Typography variant="text-3" color="subdued">
                  Reservierungen mussten auf Tischen ohne Anlass platziert
                  werden und / oder auf Tischen wo die Mindestanzahl größer ist
                  als die Gastanzahl der Reservierung
                </Typography>
              </Box>
            )}
            {!!Shuffle.state.error && (
              <Box style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Error
                  color="error"
                  style={{ marginRight: 4, fontSize: '1rem' }}
                />
                <Typography variant="text-3" color="subdued">
                  {Shuffle.state.error}
                </Typography>
              </Box>
            )}
          </Box>
        </Popover>
        <IconButton
          onClick={(e) => {
            setWarningsOpen(null);
            setInfosOpen(null);
            setSettingsOpen((s) => (!s ? e.currentTarget : null));
          }}
          size="medium"
        >
          <Settings color="inherit" />
        </IconButton>
        <Popover
          open={!!settingsOpen}
          anchorEl={settingsOpen}
          width={300}
          placement="right"
          zIndex={10}
          onClose={() => {
            if (!(window as any).preffered) {
              setSettingsOpen(null);
            }
          }}
        >
          <Box padding="sm">
            <Typography
              variant="text-2"
              weight="bold"
              component="h4"
              style={{ marginBottom: 8 }}
            >
              Einstellungen
            </Typography>
            <TextField
              fullWidth
              label="Innen / Außenbereich"
              value={Shuffle.state.preffered ?? 'none'}
              select
              onMouseEnter={(e) => {
                e.preventDefault();
                e.stopPropagation();
                (window as any).preffered = true;
              }}
              onMouseLeave={(e) => {
                e.preventDefault();
                e.stopPropagation();
                (window as any).preffered = false;
              }}
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                Shuffle.set((x) =>
                  !!x
                    ? {
                        ...x,
                        preffered:
                          e.target.value !== 'none'
                            ? (e.target.value as any)
                            : null,
                      }
                    : null
                );
              }}
            >
              <MenuItem value="none">Innen- und Außenbereich</MenuItem>
              <MenuItem value="indoor">Innenbereich</MenuItem>
              <MenuItem value="outdoor">Außenbereich</MenuItem>
            </TextField>
            <Checkbox
              checked={!!(Shuffle.state.useBlockedTables ?? 2)}
              onChange={(e, checked) =>
                Shuffle.set((x) =>
                  !!x ? { ...x, useBlockedTables: checked ? 2 : 0 } : null
                )
              }
              style={{ marginBottom: 8 }}
              label="Nutze Tische ohne Anlass wenn nicht alle Reservierungen platziert werden können"
            />
            <Checkbox
              checked={!!Shuffle.state.ignoreDistance}
              onChange={(e, ignoreDistance) =>
                Shuffle.set((x) => (!!x ? { ...x, ignoreDistance } : null))
              }
              style={{ marginBottom: 8 }}
              label="Ignoriere Abstände zwischen Reservierungen"
            />
            <Button
              variant="primary"
              fullWidth
              onClick={Shuffle.requestShuffle}
            >
              Neu Anordnen
            </Button>
          </Box>
        </Popover>

        <IconButton
          disabled={inPast}
          onClick={Shuffle.closeShuffle}
          size="medium"
        >
          <Close color="inherit" />
        </IconButton>
      </Box>
    );
  } else if (
    !editReservation &&
    (isGastronautAdmin || apps.includes('shuffle_mode'))
  ) {
    return (
      <IconButton
        disabled={inPast}
        onClick={() => Shuffle.activateShuffle(date)}
      >
        <ShuffleModeIcon color={!inPast ? 'secondary' : 'disabled'} />
      </IconButton>
    );
  } else {
    return <></>;
  }
};

export default ShuffleButton;
