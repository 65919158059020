import server from "../index";
import { Guest, StatisticsDocument } from "../../types/guestbook-statistics";

export type GuestStatisticsResponse = {
  page: number;
  from: number;
  total: number;
  guests: Guest[];
  updatedAt: number;
};

type type = (
  restaurantId: string,
  uid: string,
  month: string,
  page: number,
  perPage: number
) => Promise<StatisticsDocument>;

const loadStatisticsTableData: type = async (
  restaurantId: string,
  uid: string,
  month: string,
  page: number,
  perPage: number
) => {
  try {
    const response = await server.get<GuestStatisticsResponse>(
      `/v03/guests/${restaurantId}/statistics?uid=${uid}`,
      { params: { month, page, perPage } }
    );
    const data = response.data;
    return data;
  } catch (error: any) {
    console.error(error);
    if (error.isAxiosError) {
      return { page: 0, from: 0, total: 0, guests: [], updatedAt: 0 };
    } else {
      throw error;
    }
  }
};

export default loadStatisticsTableData;
