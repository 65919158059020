import React from "react";

const PDFIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M104 126H24C18.486 126 14 121.514 14 116V12C14 6.486 18.486 2 24 2H64.687C67.358 2 69.87 3.041 71.757 4.929L111.071 44.243C112.96 46.132 114 48.642 114 51.313V116C114 121.514 109.514 126 104 126ZM24 6C20.691 6 18 8.691 18 12V116C18 119.309 20.691 122 24 122H104C107.309 122 110 119.309 110 116V51.313C110 49.734 109.359 48.188 108.243 47.071L68.929 7.757C67.796 6.624 66.289 6 64.687 6H24Z"
        fill="#FF402F"
      />
      <path
        d="M95.2099 80.32C95.1399 79.81 94.7299 79.17 94.2899 78.74C93.0299 77.5 90.2599 76.85 86.0399 76.79C83.1799 76.76 79.7399 77.01 76.1199 77.52C74.4999 76.59 72.8299 75.57 71.5199 74.34C67.9899 71.05 65.0499 66.48 63.2099 61.45C63.3299 60.98 63.4299 60.57 63.5299 60.15C63.5299 60.15 65.5099 48.87 64.9899 45.05C64.9199 44.53 64.8699 44.38 64.7299 43.97L64.5599 43.53C64.0199 42.28 62.9599 40.96 61.2999 41.03L60.3199 41H60.2999C58.4399 41 56.9399 41.95 56.5399 43.36C55.3399 47.8 56.5799 54.45 58.8299 63.05L58.2499 64.45C56.6399 68.39 54.6199 72.35 52.8399 75.84L52.6099 76.29C50.7299 79.96 49.0299 83.08 47.4799 85.72L45.8899 86.56C45.7699 86.62 43.0399 88.07 42.3999 88.45C36.9699 91.7 33.3699 95.38 32.7699 98.3C32.5799 99.24 32.7199 100.43 33.6899 100.98L35.2299 101.76C35.8999 102.09 36.6099 102.26 37.3299 102.26C41.1999 102.26 45.6899 97.44 51.8799 86.64C59.0199 84.32 67.1599 82.38 74.2899 81.32C79.7199 84.37 86.3999 86.5 90.6199 86.5C91.3699 86.5 92.0199 86.43 92.5399 86.29C93.3499 86.07 94.0299 85.61 94.4499 84.99C95.2699 83.76 95.4299 82.06 95.2099 80.32ZM36.4899 99.33C37.1899 97.4 39.9899 93.58 44.1199 90.2C44.3799 89.99 45.0199 89.39 45.5999 88.83C41.2799 95.72 38.3899 98.46 36.4899 99.33ZM60.9499 43C62.1899 43 62.8999 46.13 62.9599 49.07C63.0199 52.01 62.3299 54.07 61.4799 55.6C60.7699 53.34 60.4299 49.78 60.4299 47.45C60.4299 47.45 60.3799 43 60.9499 43ZM53.6499 83.14C54.5199 81.59 55.4199 79.95 56.3399 78.22C58.5899 73.97 60.0099 70.65 61.0599 67.92C63.1599 71.74 65.7799 74.99 68.8499 77.59C69.2399 77.91 69.6499 78.24 70.0699 78.57C63.8199 79.8 58.4099 81.31 53.6499 83.14ZM93.0799 82.79C92.6999 83.02 91.6099 83.16 90.9099 83.16C88.6499 83.16 85.8399 82.13 81.9099 80.44C83.4199 80.33 84.8099 80.27 86.0499 80.27C88.3199 80.27 88.9899 80.26 91.2199 80.83C93.4399 81.4 93.4699 82.55 93.0799 82.79Z"
        fill="#FF402F"
      />
    </svg>
  );
};

export default PDFIcon;
