import React from "react";
import Box from "Components/Atoms/Box";
import "./styles.scss";
import { ButtonProps } from "Components/Atoms";
import Button from "Components/Atoms/Button";
import { Menu } from "@material-ui/core";

export type ButtonWithOptionsProps = Omit<ButtonProps, "onClick" | "type"> & {
  options: ButtonProps[];
  closeAutomatically?: boolean;
  id: string;
};

const ButtonWithOptions: React.FC<ButtonWithOptionsProps> = ({
  options,
  closeAutomatically = false,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button {...props} onClick={handleClick} />
      <Menu
        id={`buttonWithOptions-${props.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div style={{ padding: "0px 8px" }}>
          {options.map((opt, i) => (
            <Button
              fullWidth
              {...(closeAutomatically
                ? { onTouchEnd: handleClose, onMouseUp: handleClose }
                : {})}
              {...opt}
              style={{
                marginBottom: i < options.length - 1 ? 8 : 0,
                ...(opt?.style ?? {}),
              }}
              key={i}
            />
          ))}
        </div>
      </Menu>
    </>
  );
};

export default ButtonWithOptions;
