import { ReservationContext } from 'Contexts/ReservationContext';
import useCollection from './useCollection';
import useDocument from './useDocument';
import { useContext } from 'react';
import { timeHelper, timeToBlock } from 'utils/helper';

export type TableInfo = {
  [tableName: string]: {
    firstBookDateTime: string;
    lastBookDateTime: string;
    closedAt?: string;
    balance: number;
    open: boolean;
  }[];
};

const useLoadTableInfo = (restaurantId: string | null, date: string) => {
  const [tableInfo] = useDocument<TableInfo>(
    `restaurants/${restaurantId}/tableInfos`,
    date
  );

  console.log({ tableInfo });

  return tableInfo?.data ?? {};
};

export default useLoadTableInfo;

export const useTableInfo = (table: string) => {
  const { tableInfo } = useContext(ReservationContext);

  const orders = tableInfo?.[table] ?? [];

  return orders.map((x) => ({
    ...x,
    start: timeToBlock(
      new Date(x.firstBookDateTime).toTimeString().split(' ')[0],
      -1,
      true
    ),
    last: timeToBlock(
      new Date(x.lastBookDateTime).toTimeString().split(' ')[0],
      -1,
      true
    ),
    end: x.closedAt
      ? timeToBlock(new Date(x.closedAt).toTimeString().split(' ')[0], -1, true)
      : undefined,
  }));
};
