import React from 'react';
import useLocalStorageState from './useLocalStorageState';

const useServerTime = () => {
  const [difference, setTime] = useLocalStorageState(
    'server_time_difference',
    0
  );

  React.useEffect(() => {
    const getServerTime = async () => {
      const response = await fetch(
        'https://gnaut.me/current_server_time'
      ).catch(() => ({ json: () => ({ serverTime: Date.now() }) }));
      const data = await response.json();

      const difference = data.serverTime - Date.now();

      console.log({ difference });

      if (Math.abs(difference) < 60000) {
        setTime(0);
      } else {
        setTime(difference);
      }
    };

    getServerTime();
  }, []);

  return difference;
};

export default useServerTime;
