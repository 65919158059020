import React from 'react';
import Box from 'Components/Atoms/Box';
import {
  CustomDataValue,
  CustomElement,
} from 'gastronaut-shared/types/helper/customElements';
import { Translation } from 'gastronaut-shared/types/helper';
import { googleServer } from 'utils/server';
import useLanguage from 'CustomHooks/useLanguage';
import TextField from 'Components/Atoms/TextField';
import SwitchBox from 'Components/Atoms/Switch';
import Select from 'Components/Atoms/Select';
import MenuItem from 'Components/Atoms/MenuItem';
import useAuth from 'CustomHooks/useAuth';
import useRestaurant from 'CustomHooks/useRestaurant';
import { db, FieldValue, storageRef } from 'utils/firebase';
import { Attachment } from 'gastronaut-shared/types/helper/shifts';
import Button from 'Components/Atoms/Button';
import { CloudUpload, Delete } from '@material-ui/icons';
import { Trans } from 'react-i18next';
import Typography from 'Components/Atoms/Typography';

export type CustomElementsProps = {
  id: string;
  readinessCheck?: (ready: boolean) => void;
  customData: Record<string, CustomDataValue>;
  onCustomDataUpdate: (
    partial: Record<string, CustomDataValue | null>,
    complete?: boolean
  ) => void;
  updateInDatabase?: boolean;
};

function toKey(internalName: string, internalOnly = false) {
  return internalOnly ? internalName + '_internal' : internalName;
}

function getText(
  language: string,
  texts: Translation<{
    title?: string;
    description?: string;
  }>,
  key: 'title' | 'description'
) {
  return texts[language]?.[key] ?? texts['en']?.[key] ?? texts['de']?.[key];
}

const CustomElements: React.FC<CustomElementsProps> = ({
  id,
  readinessCheck = () => {},
  customData,
  onCustomDataUpdate,
  updateInDatabase = false,
}) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [uploading, setUploading] = React.useState<boolean>(false);
  const [customElements, setCustomElements] = React.useState<CustomElement[]>(
    []
  );

  const { language } = useLanguage();

  const { uid } = useAuth();

  const { restaurantId } = useRestaurant();

  const onAttachmentUpload = async (key: string, files: FileList) => {
    let xKey = `${id}-${key}`;

    if (files.length > 1) {
      // @TODO nur ein Dokument erlauben
      alert('Nur ein Anhang erlaubt');
    }

    setUploading(true);

    for (const file of files) {
      let { size, name, type } = file;

      const reference = `/restaurants/${restaurantId}/attachments/${xKey}/${name}`;

      const fileRef = storageRef.child(reference);

      await fileRef.put(file);

      const url = await fileRef.getDownloadURL();

      onCustomDataUpdate({
        [key]: url,
      });

      await db
        .collection(`requestsV03`)
        .doc(id)
        .update({
          [`customData.${key}`]: url,
          updatedAt: Date.now(),
          updatedBy: uid ?? '',
        });

      onCustomDataUpdate({
        [key]: url,
      });
    }

    setUploading(false);

    // @Show Success Message ?

    return;
  };

  const onAttachmentDelete = async (key: string) => {
    onCustomDataUpdate({
      [key]: null as any,
    });

    await db
      .collection(`requestsV03`)
      .doc(id)
      .update({
        [`customData.${key}`]: FieldValue.delete(),
        updatedAt: Date.now(),
        updatedBy: uid ?? '',
      });
  };

  const handleFileUpload: (
    key: string
  ) => React.ChangeEventHandler<HTMLInputElement> = (key) => (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files = [] } = e.target;

    if (!files?.length) {
      return;
    }
    onAttachmentUpload?.(key, files as FileList);
  };

  React.useEffect(() => {
    setLoading(true);
    googleServer
      .get(
        `/v03/reservations/res/customElements/${id}${
          id.includes('?') ? '&' : '?'
        }uid=${uid}`
      )
      .then((reponse) => {
        let { customData = {}, customElements = [] } = reponse.data as {
          customData?: Record<string, CustomDataValue>;
          customElements?: CustomElement[];
        };

        if (!updateInDatabase) onCustomDataUpdate(customData, true);
        setCustomElements(customElements);

        setLoading(false);
      });
  }, [id]);

  React.useEffect(() => {
    readinessCheck(
      !customElements.some(
        (e) =>
          e.required &&
          !e.internalOnly &&
          !customData[e.internalName] &&
          customData[e.internalName] !== 0
      )
    );
  }, [customElements, customData]);

  const handleBlur = async (key: string, value = customData[key]) => {
    if (updateInDatabase) {
      let updatedAt = Date.now();
      await db
        .collection(`requestsV03`)
        .doc(id)
        .update({
          [`customData.${key}`]: value ?? null,
          updatedAt,
          updatedBy: uid ?? '',
          updateNote: {
            note: `${key.replace('_internal', '')}: ${String(value)}`,
            updatedAt,
          },
        });
    }
  };

  console.log({ customData });

  if (loading || !customElements.length) {
    return <></>;
  }

  return (
    <Box style={{ marginTop: 12 }}>
      {customElements.map((element, index) => (
        <Box key={index}>
          {element.type === 'text' && (
            <TextField
              label={getText(language, element.texts, 'title')}
              value={
                (customData[
                  toKey(element.internalName, element.internalOnly)
                ] as string) ?? ''
              }
              onChange={(e: any) =>
                onCustomDataUpdate({
                  [toKey(element.internalName, element.internalOnly)]:
                    e.target.value,
                })
              }
              onBlur={() =>
                handleBlur(toKey(element.internalName, element.internalOnly))
              }
              className="mg-bt-md"
              helperText={getText(language, element.texts, 'description')}
              fullWidth
              required={element.required}
            />
          )}

          {element.type === 'number' && (
            <TextField
              label={getText(language, element.texts, 'title')}
              value={
                (customData[
                  toKey(element.internalName, element.internalOnly)
                ] as number) ?? 0
              }
              onChange={(e: any) =>
                onCustomDataUpdate({
                  [toKey(element.internalName, element.internalOnly)]: Number(
                    e.target.value
                  ),
                })
              }
              onBlur={() =>
                handleBlur(toKey(element.internalName, element.internalOnly))
              }
              className="mg-bt-md"
              helperText={getText(language, element.texts, 'description')}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              required={element.required}
            />
          )}
          {element.type === 'currency' && (
            <TextField
              label={getText(language, element.texts, 'title')}
              value={(
                (customData[
                  toKey(element.internalName, element.internalOnly)
                ] as number) ?? 0
              )
                .toString()
                .replace('.', ',')}
              onChange={(e: any) =>
                onCustomDataUpdate({
                  [toKey(element.internalName, element.internalOnly)]:
                    e.target.value,
                })
              }
              onBlur={() =>
                handleBlur(toKey(element.internalName, element.internalOnly))
              }
              className="mg-bt-md"
              helperText={getText(language, element.texts, 'description')}
              fullWidth
              required={element.required}
            />
          )}

          {element.type === 'checkbox' && (
            <>
              <SwitchBox
                checked={
                  !!customData[
                    toKey(element.internalName, element.internalOnly)
                  ]
                }
                onChange={(e) => {
                  onCustomDataUpdate({
                    [toKey(element.internalName, element.internalOnly)]:
                      e.target.checked,
                  });
                  handleBlur(
                    toKey(element.internalName, element.internalOnly),
                    e.target.checked
                  );
                }}
                label={getText(language, element.texts, 'title')}
                className="mg-bt-sm"
              />
              {!!getText(language, element.texts, 'description') && (
                <>
                  <br />
                  <span style={{ fontSize: 12, color: 'gray' }}>
                    {getText(language, element.texts, 'description')}
                  </span>
                </>
              )}
            </>
          )}
          {element.type === 'select' && (
            <TextField
              label={getText(language, element.texts, 'title')}
              value={
                (customData[
                  toKey(element.internalName, element.internalOnly)
                ] as string) ?? ''
              }
              onChange={(e: any) =>
                onCustomDataUpdate({
                  [toKey(element.internalName, element.internalOnly)]:
                    e.target.value,
                })
              }
              onBlur={() =>
                handleBlur(toKey(element.internalName, element.internalOnly))
              }
              className="mg-bt-md"
              fullWidth
              required={element.required}
              helperText={getText(language, element.texts, 'description')}
              select
            >
              {element?.options?.map((option, index) => (
                <MenuItem key={index} value={option.internalName}>
                  {getText(language, option.texts, 'title')}
                </MenuItem>
              ))}
            </TextField>
          )}
          {element.type === 'attachment' && (
            <Box className="mg-bt-md">
              <Typography variant="text-3" block>
                {getText(language, element.texts, 'title')}
              </Typography>
              {!!customData[toKey(element.internalName, true)] ? (
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <a
                    href={
                      customData[toKey(element.internalName, true)] as string
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    Anhang
                  </a>
                  <Button
                    onClick={() =>
                      onAttachmentDelete(toKey(element.internalName, true))
                    }
                    startIcon={(p) => <Delete {...p} />}
                  ></Button>
                </Box>
              ) : (
                <Button
                  onClick={(e) =>
                    e.currentTarget
                      ?.getElementsByTagName('input')?.[0]
                      ?.click?.()
                  }
                  loading={uploading}
                  startIcon={(p) => <CloudUpload {...p} />}
                >
                  <Trans i18nKey="common">Upload</Trans>

                  <input
                    type="file"
                    onChange={handleFileUpload(
                      toKey(element.internalName, true)
                    )}
                    name="attachmentUpload"
                    hidden
                    multiple={false}
                  />
                </Button>
              )}
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default CustomElements;
