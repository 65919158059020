import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const ChairsAndTableIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" fill="#6369D1" />
      <path
        d="M3 10.5567C3.84721 10.3712 4.69441 10.1856 5.54162 10C5.9047 11.7993 6.27586 13.5905 6.65508 15.3898C8.47052 15.3898 10.294 15.3898 12.1175 15.3898C12.1337 18.0686 12.1417 20.7474 12.1095 23.4181C11.2139 23.4101 10.3263 23.4101 9.43876 23.3939C9.43876 21.6269 9.43876 19.8518 9.43876 18.0848C8.54314 18.0848 7.64753 18.0848 6.75191 18.0848C6.74384 19.8599 6.74384 21.635 6.74384 23.4101C5.84822 23.4101 4.95261 23.4101 4.05699 23.4181C4.18609 19.0933 4.0812 14.7686 3 10.5567Z"
        fill="white"
      />
      <path
        d="M26.7775 10C27.6248 10.1856 28.472 10.3712 29.3111 10.5567C28.2218 14.7686 28.1653 19.1014 28.246 23.4262C27.3504 23.4181 26.4629 23.402 25.5753 23.3939C25.5753 21.6188 25.5753 19.8518 25.5673 18.0848C24.6716 18.0767 23.776 18.0767 22.8885 18.0767C22.8804 19.8518 22.8804 21.6269 22.8804 23.402C21.9929 23.4101 21.0972 23.4181 20.2097 23.4262C20.1774 20.7474 20.1855 18.0686 20.2016 15.3898C22.0251 15.3898 23.8486 15.3898 25.6721 15.3898C26.0433 13.5905 26.4145 11.7993 26.7775 10Z"
        fill="white"
      />
      <rect
        x="15.0537"
        y="12.2275"
        width="2.28571"
        height="11.4286"
        fill="white"
      />
      <line
        x1="10.1465"
        y1="10.9775"
        x2="22.0765"
        y2="10.9775"
        stroke="white"
        stroke-width="2.5"
        stroke-linecap="round"
      />
    </SvgIcon>
  );
};

export default ChairsAndTableIcon;
