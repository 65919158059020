import React, { useMemo, useRef } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import useCollection from 'CustomHooks/useCollection';
import useRestaurant from 'CustomHooks/useRestaurant';
import {
  dateHelper,
  daysFromToday,
  toEuropeanDate,
  wDayHelper,
} from 'utils/helper';
import TabCard from 'Components/Molecules/TabCard';
import CallCardComponent from 'App/Dashboard/Screens/SiaCurrentDay/Components/CallCard';
import { CallCard } from 'App/Dashboard/Screens/SiaCurrentDay/SiaCurrentDay';
import GridItem from 'Components/Atoms/GridItem';

export type MailboxProps = {};

const Mailbox: React.FC<MailboxProps> = ({}) => {
  const { restaurantId } = useRestaurant();

  const oneWeekAgo = useRef(Date.now() - 7 * 24 * 60000 * 60);

  const [callsWithMailbox] = useCollection('calls', {
    filter: [
      ['restaurantId', '==', restaurantId],
      ['hasMailbox', '==', true],
      ['createdAt', '>=', oneWeekAgo.current],
    ],
    sort: ['createdAt', 'desc'],
  });

  const callCards = useMemo(
    () =>
      callsWithMailbox.data.map((call) => {
        let caller = call.guest?.name
          ? `${call.guest.name} (${call.callerId})`
          : call.callerId;

        let duration = (call.endedAt ?? call.createdAt) - call.createdAt;

        let dateObj = new Date(call.createdAt);

        const dateDiff = daysFromToday(dateObj);

        let time = dateObj.toLocaleTimeString('de-DE').slice(0, 5);
        let ts = '';

        if (dateDiff === 0) {
          ts = 'Heute, ' + time;
        } else if (dateDiff === -1) {
          ts = 'Gestern, ' + time;
        } else if (dateDiff > -7) {
          ts = wDayHelper(dateObj.getDay(), true, 'de') + ', ' + time;
        } else {
          ts = toEuropeanDate(dateHelper(dateObj)) + ', ' + time;
        }

        return {
          id: call.callSid,
          ts,
          type: 'mailbox',
          data: call,
          caller,
          duration,
          takenCareOf: call.takenCareOf,
        } as CallCard;
      }),
    [callsWithMailbox]
  );

  if (!callCards.length) {
    return <></>;
  }

  return (
    <GridItem xs={12}>
      <TabCard
        id="mailbox"
        title="Mailbox Aufnahmen"
        badge={callCards.length}
        titleTranslation="reservations"
        closable
        hideTabsWhenOnlyOne
      >
        {callCards.map((call) => (
          <CallCardComponent onDashboard {...call} />
        ))}
      </TabCard>
    </GridItem>
  );
};

export default Mailbox;
