import IconButton from 'Components/Atoms/IconButton';
import { Call, Menu } from 'Components/Atoms/Icons';
import Box from 'Components/Atoms/Box';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import Typography from 'Components/Atoms/Typography';
import './styles.scss';
import { classHelper } from 'utils/helper';
import { Translations } from 'utils/types';
import { RestaurantContext } from 'Contexts/RestaurantContext';
import { isOnDemoMode } from 'utils/firebase';
import useSiaSettings from 'CustomHooks/useSiaSettings';
import useSiaContext from 'CustomHooks/useSiaContext';
import Badge from 'Components/Atoms/Badge';
import Button from 'Components/Atoms/Button';

export type NavBarProps = {
  title?: string;
  right?: React.ReactNode;
  hideMenu?: boolean;
  children?: React.ReactNode;
  hideChildren?: boolean;
  reservationBook?: boolean;
  mainProduct?: {
    label: string;
    labelTranslation?: Translations;
    link: (rId: string, date?: string) => string;
  };
  date?: string;
  className?: string;
  warnOnLeave?: boolean;
  productTranslation?: Translations;
  childrenContainerStyle?: React.CSSProperties;
};

const NavBar = ({
  title = '',
  right,
  children,
  reservationBook = false,
  hideChildren = false,
  mainProduct,
  date,
  className = '',
  warnOnLeave = false,
  hideMenu = false,
  productTranslation,
  childrenContainerStyle,
}: NavBarProps) => {
  const history = useHistory();

  const classNames = classHelper([
    'navbar',
    'space-between',
    'corner',
    reservationBook && 'reservationHeader',
    className,
  ]);

  const { restaurantId, alert } = useContext(RestaurantContext);

  const handleClick = (fn: (rId: string, date?: string) => string) => {
    const path = fn(restaurantId || '', date);

    if (warnOnLeave) {
      alert({
        title: 'Are you sure?',
        description: 'You will loose the current progress!',
        onSubmit: () => history?.push(path),
      });
    } else {
      history?.push(path);
    }
  };

  const { hasSia, openSiaModal, unseenCalls } = useSiaContext();

  // console.log("childrenStyle", childrenContainerStyle);

  return (
    <>
      <Box className={classNames} elevation={2} padding="sm" flex>
        <Box flex width="auto">
          <IconButton
            onClick={() => history?.push('/')}
            size={reservationBook ? 'medium' : 'large'}
            disabled={hideMenu}
            style={{ opacity: hideMenu ? 0 : 1 }}
            aria-label="Menu"
          >
            <Menu color="inherit" />
          </IconButton>
          <Box flex style={{ marginLeft: reservationBook ? '1rem' : '0.5rem' }}>
            {!!mainProduct && (
              <>
                <Typography
                  className="breadCrumb"
                  variant="h4"
                  translation={mainProduct.labelTranslation}
                  color="subdued"
                  onClick={() => handleClick(mainProduct.link)}
                >
                  {mainProduct.label}
                </Typography>
                <Typography variant="h4" color="subdued" className="slash">
                  /
                </Typography>
              </>
            )}
            <Typography
              variant="h4"
              translation={productTranslation || 'products'}
              style={{ whiteSpace: 'nowrap' }}
            >
              {title}
            </Typography>
          </Box>
        </Box>
        {!childrenContainerStyle ? (
          <Box flex width="auto">
            {!hideChildren && children}
          </Box>
        ) : (
          <Box style={{ ...childrenContainerStyle }}>
            {!hideChildren && children}
          </Box>
        )}

        <Box flex width="auto" className="small-gap">
          {hasSia && title !== 'Dashboard' && (
            <IconButton
              size={reservationBook ? 'small' : 'medium'}
              onClick={() => openSiaModal()}
              style={{ marginRight: 8 }}
            >
              <Badge number={unseenCalls}>
                <Call />
              </Badge>
            </IconButton>
          )}
          {hasSia && title === 'Dashboard' && (
            <Button
              style={{ whiteSpace: 'nowrap', marginRight: 8 }}
              endIcon={(p) => <Call {...p} />}
              onClick={() => openSiaModal()}
              variant="secondary"
            >
              Telefonassistent {!!unseenCalls ? `(${unseenCalls})` : ''}
            </Button>
          )}
          {!hideChildren && right}
        </Box>
      </Box>
      {isOnDemoMode && (
        <Box className="demo-mode-banner">
          Demo Mode : gastronaut-demo firebase project is used
        </Box>
      )}
    </>
  );
};

export default NavBar;
