import React, { useEffect, useMemo, useState } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import useKeyPressListener from 'CustomHooks/useKeyPressListener';
import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Slide,
  Toolbar,
} from '@material-ui/core';
import { TransitionProps } from 'react-transition-group/Transition';
import CloseIcon from '@material-ui/icons/Close';
import Typography from 'Components/Atoms/Typography';
import useDocument from 'CustomHooks/useDocument';
import useCollection from 'CustomHooks/useCollection';
import { Reservation } from 'gastronaut-shared/types/helper/reservations';
import { Ticket } from 'gastronaut-shared/types/documents/restaurants';
import { blockToTime } from 'utils/helper';
import useRestaurant from 'CustomHooks/useRestaurant';
import { Relationship } from 'gastronaut-shared/types/helper/guests';
import useAuth from 'CustomHooks/useAuth';
import useCustomEvent from 'CustomHooks/useCustomEvent';

function checkIfStringIsUniqueId(str: string) {
  if (str.length < 12 || str.includes(' ')) return false;

  const lowerCases = 'abcdefghijklmnopqrstuvwzyz';
  const upperCases = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '0123456789';

  let lastType = '',
    changes = 0;

  for (let i = 0; i < str.length; i++) {
    const char = str[i];

    if (lowerCases.includes(char)) {
      if (lastType && lastType !== 'l') {
        changes += 1;
      }
      lastType = 'l';
    } else if (upperCases.includes(char)) {
      if (lastType && lastType !== 'u') {
        changes += 1;
      }
      lastType = 'u';
    } else if (numbers.includes(char)) {
      if (lastType && lastType !== 'n') {
        changes += 1;
      }
      lastType = 'n';
    } else {
      if (lastType && lastType !== 'x') {
        changes += 1;
      }
      lastType = 'x';
    }
  }

  return changes / str.length > 0.4;
}

function flattenObject(obj: any, delimiter = '.', prefix = '') {
  const result: any = {};
  for (const [key, value] of Object.entries(obj)) {
    const newKey = prefix + key;
    if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
      if (newKey.includes('jsonPayload.body')) {
        result[newKey] = JSON.stringify(value);
      } else {
        Object.assign(
          result,
          flattenObject(value, delimiter, newKey + delimiter)
        );
      }
    } else {
      result[newKey] = value;
    }
  }
  return result;
}

export type ReservationInsightsProps = {
  id: string;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReservationInsights: React.FC<ReservationInsightsProps> = ({ id }) => {
  const [open, setopen] = useState(false);
  const { isGastronautAdmin } = useAuth();

  useKeyPressListener(
    () => {
      if (isGastronautAdmin) setopen(true);
    },
    { ctrlKeyPressed: true, key: 'i' }
  );

  useCustomEvent('insightOpened', () => {
    if (isGastronautAdmin && !open) setopen(true);
  });

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => setopen(false)}
      TransitionComponent={Transition as any}
    >
      <AppBar style={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setopen(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h3"
            style={{
              marginLeft: 16,
              flex: 1,
            }}
          >
            Reservierungs Insights
          </Typography>

          <Button
            href={`https://console.firebase.google.com/u/0/project/schillingroofbarhd/firestore/data/~2FrequestsV03~2F${id}`}
            target="_blank"
          >
            In Firebase öffnen
          </Button>
        </Toolbar>
      </AppBar>
      <ReservationInsightsModal id={id} />
    </Dialog>
  );
};

const UserField: React.FC<{ value: any }> = ({ value }) => {
  const { restaurantId } = useRestaurant();

  const [user] = useDocument('users', value);

  const name = useMemo(() => {
    if (!user.data) return 'Unbekannter Nutzer';
    return (
      user.data.restaurants?.find((r: any) => r.id === restaurantId)?.name ??
      user.data?.displayName ??
      ''
    );
  }, [user.data, restaurantId, value]);

  return (
    <Box style={{ textAlign: 'right' }}>
      {user.loading ? (
        <>Loading... ({value.replace(/"/gm, '')})</>
      ) : (
        <>
          {name} ({value.replace(/"/gm, '')}) (user-id)
        </>
      )}
    </Box>
  );
};

const ValueField: React.FC<{
  row: { key: string; type: string; value: any };
}> = ({ row }) => {
  const { key, type, value } = row;

  if (key === 'createdBy' || key === 'updatedBy') {
    if (value.length < 10) {
      return (
        <Box style={{ textAlign: 'right' }}>
          {String(value.replace(/"/gm, ''))} (user-id)
        </Box>
      );
    } else {
      return <UserField {...{ key, type, value }} />;
    }
  } else if (
    [
      'started',
      'reservationLength',
      'endTimeInBlocks',
      'startTimeInBlocks',
    ].includes(key)
  ) {
    return (
      <Box style={{ textAlign: 'right' }}>
        {blockToTime(value)} ({value} block)
      </Box>
    );
  } else if (type === 'null') {
    return <Box style={{ textAlign: 'right' }}>{type}</Box>;
  } else if (type === 'url' || type === 'email') {
    return (
      <Box style={{ textAlign: 'right' }}>
        <a href={type === 'email' ? `mailTo:${value}` : value} target="_blank">
          {value}
        </a>{' '}
        ({type})
      </Box>
    );
  } else {
    return (
      <Box style={{ textAlign: 'right', overflow: 'scroll' }}>
        {String(value)} ({type})
      </Box>
    );
  }
};

const DataTable = ({ data = {}, header = '', indent = 0 }) => {
  const [open, setopen] = useState(!header || !isNaN(Number(header)));

  const table = useMemo(() => {
    let keys = Object.keys(data).sort(
      (a, b) =>
        (a === 'id' && b !== 'id' ? -1 : 0) ||
        (b === 'id' && a !== 'id' ? 1 : 0) ||
        String((data as any)?.[b] ?? '').localeCompare(
          String((data as any)?.[a] ?? '')
        ) ||
        a.localeCompare(b)
    );

    return keys.map((key) => {
      let value = (data as any)?.[key] ?? null;

      let type: string = typeof value;

      if (value === null) {
        value = '';
        type = 'null';
      }

      if (typeof value === 'string') {
        if (
          value.includes('@') &&
          value.includes('.') &&
          !value.trim().includes(' ')
        ) {
          type = 'email';
        } else if (
          key.toLocaleLowerCase().includes('phone') &&
          value.match(/\d{3,}/gm)
        ) {
          type = 'phone';
          if (value && !value.startsWith('+') && !value.startsWith('0')) {
            value = '+' + value;
          }
        } else if (key === 'validTill') {
          value = !!value ? `${new Date(value).toLocaleString('de-DE')}` : '-';
          type = 'ts';
        } else if (value.match(/\d{4}-\d{2}-\d{2}/gm)) {
          type = 'date';
        } else if (value.startsWith('#') && value.length <= 7) {
          type = 'hex';
        } else if (key.toLocaleLowerCase().includes('status')) {
          type = 'status';
        } else if (key.toLocaleLowerCase().includes('language')) {
          type = 'lang';
        } else if (key === 'restaurant' || key === 'restaurantId') {
          type = 'restaurantId';
        } else if (value.includes('<br') || value.includes('<p')) {
          type = 'html';
        } else if (value.startsWith('http')) {
          type = 'url';
        } else if (
          checkIfStringIsUniqueId(value) ||
          key === 'customId' ||
          key === 'id'
        ) {
          type = 'unique-id';
        }
      }

      if (type === 'object') {
        type = 'object';
      }

      if (
        Array.isArray(value) &&
        (!value.length || typeof value[0] === 'string')
      ) {
        type = 'array';
        value = JSON.stringify(value);
      }

      if (type === 'string') {
        value = `"${value}"`;
      }

      if (typeof value === 'number' && String(value).length === 13) {
        value = !!value ? `${new Date(value).toLocaleString('de-DE')}` : '-';
        type = 'ts';
      }

      return {
        key,
        value,
        type,
      };
    });
  }, [data]);

  return (
    <>
      {!!header && (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 4,
            borderBottom: '1px solid lightgrey',
            paddingLeft: indent > 1 ? (indent - 1) * 14 : 0,
            fontWeight: 'bolder',
          }}
          onClick={() => setopen((x) => !x)}
        >
          {header}
        </Box>
      )}
      {(open || !header) &&
        table.map((row) =>
          row.type === 'object' ? (
            <DataTable
              key={row.key}
              data={row.value}
              header={row.key}
              indent={indent + 1}
            />
          ) : (
            <Box
              key={row.key}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: 4,
                borderBottom: '1px solid lightgrey',
                paddingLeft: indent * 14,
              }}
            >
              <Box>{row.key}</Box>
              <ValueField row={row} />
            </Box>
          )
        )}
    </>
  );
};

const ReservationInsightsModal: React.FC<ReservationInsightsProps> = ({
  id,
}) => {
  const [reservation] = useDocument<Reservation>('/requestsV03', id);

  const relationshipId = `${
    reservation?.data?.guest?.id?.replace?.(
      `-${reservation?.data?.restaurant ?? ' '}`,
      ''
    ) ?? ''
  }-${reservation?.data?.restaurant ?? ''}`;

  const [relationship] = useDocument<Relationship>(
    '/relationships',
    relationshipId
  );
  const [versions] = useCollection<Reservation>(`/requestsV03/${id}/versions`);
  const [ticket] = useDocument<Ticket>(
    '/ticketsV02',
    reservation?.data?.ticketId ?? ''
  );
  const [emails] = useCollection<{
    type: string;
    sendTo: string;
    options: { sendAt?: number };
    status: string;
    id: string;
  }>(`/emails`, {
    filter: [['belongsTo', '==', id]],
  });

  console.log(versions);

  const [tab, settab] = useState('reservation');

  const data = useMemo(() => {
    if (tab === 'reservation') return { ...reservation.data, id };
    if (tab === 'ticket')
      return { ...ticket.data, id: reservation?.data?.ticketId ?? '' };
    if (tab === 'relationship')
      return { ...relationship.data, id: relationshipId };
    if (tab.startsWith('versions')) {
      return versions.data.find((v) => v.id === tab.split('__')[1]) ?? null;
    }
    if (tab.startsWith('versions')) {
      return versions.data.find((v) => v.id === tab.split('__')[1]) ?? null;
    }

    if (tab.startsWith('emails')) {
      return emails.data.find((v) => v.id === tab.split('__')[1]) ?? null;
    }

    return null;
  }, [tab, reservation, versions, ticket, emails, relationship]);

  return (
    <Box
      style={{ display: 'flex', width: '100%', height: 'calc(100vh - 64px)' }}
    >
      <Box style={{ width: 240, overflow: 'scroll', marginRight: 10 }}>
        <List dense>
          <ListItem
            selected={tab === 'reservation'}
            onClick={() => settab('reservation')}
          >
            <ListItemText primary="Reservierung" secondary={id} />
          </ListItem>
        </List>
        {!!versions.data.length && (
          <List dense subheader={<ListSubheader>Alte Versionen</ListSubheader>}>
            {versions.data.map((resa) => (
              <ListItem
                selected={tab === `versions__${resa.id}`}
                key={resa.id}
                onClick={() => settab(`versions__${resa.id}`)}
              >
                <ListItemText
                  primary={new Date(Number(resa.id)).toLocaleString('de-DE')}
                />
              </ListItem>
            ))}
          </List>
        )}
        {!!ticket.data && (
          <List dense subheader={<ListSubheader>Ticket</ListSubheader>}>
            <ListItem
              selected={tab === 'ticket'}
              onClick={() => settab('ticket')}
            >
              <ListItemText
                primary={
                  ticket?.data?.event?.title?.['de'] ??
                  ticket?.data?.event?.title?.['en'] ??
                  'Ticket'
                }
              />
            </ListItem>
          </List>
        )}
        {!!relationship.data && (
          <List dense subheader={<ListSubheader>Gast</ListSubheader>}>
            <ListItem
              selected={tab === 'relationship'}
              onClick={() => settab('relationship')}
            >
              <ListItemText
                primary={relationship?.data?.name ?? 'Gast Eintrag'}
              />
            </ListItem>
          </List>
        )}
        {!!emails.data.length && (
          <List dense subheader={<ListSubheader>Emails</ListSubheader>}>
            {emails.data.map((email) => (
              <ListItem
                key={email.id}
                selected={tab === `emails__${email.id}`}
                onClick={() => settab(`emails__${email.id}`)}
              >
                <ListItemText
                  primary={`${email.type} => ${email.sendTo}`}
                  secondary={
                    !!email?.options?.sendAt
                      ? `${email.status} (${new Date(
                          Number(email?.options?.sendAt ?? 0)
                        ).toISOString()})`
                      : ''
                  }
                />
              </ListItem>
            ))}
          </List>
        )}
      </Box>
      <Box
        style={{
          width: '100%',
          flex: 1,
          overflow: 'scroll',
        }}
      >
        {!!data && <DataTable data={data} />}
      </Box>
    </Box>
  );
};
export default ReservationInsights;
