import React, { useContext } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import Typography from 'Components/Atoms/Typography';
import { LanguageContext } from 'Contexts/LanguageContext';
import IconButton from 'Components/Atoms/IconButton';
import { Close } from 'Components/Atoms/Icons';
import { noop } from 'utils/helper';
import { NotificationV02 } from 'CustomHooks/useNotification';

//icons
import SystemIcon from 'Components/Atoms/Icons/SystemIcon';
import TakeAwayIcon from 'Components/Atoms/Icons/TakeAwayIcon';
import TicketIcon from 'Components/Atoms/Icons/TicketIcon';
import VoucherIcon from 'Components/Atoms/Icons/VoucherIcon';
import UserIcon from 'Components/Atoms/Icons/UserIcon';
import ReservationIcon from 'Components/Atoms/Icons/ReservationIcon';
import useTranslations from 'CustomHooks/useTranslations';

export type NotificationCardProps = {
  notification: NotificationV02;
  onClick?: (id: string) => void;
  onClose?: (id: string) => void;
  lastOne?: boolean;
};

const NotificationCard = ({
  notification,
  onClick = noop,
  onClose,
  lastOne = false,
}: NotificationCardProps) => {
  // const {title} = useTranslations()
  let { id, createdAt, product = 'system', seen } = notification;

  const { dayjs } = useContext(LanguageContext);
  const updatedAtStr = dayjs(createdAt).fromNow();

  function renderNotificationIcon() {
    switch (product) {
      case 'reservation':
        return <ReservationIcon />;
      case 'system':
        return <SystemIcon />;
      case 'takeAway':
        return <TakeAwayIcon />;
      case 'ticket':
        return <TicketIcon />;
      case 'user':
        return <UserIcon />;
      case 'voucher':
        return <VoucherIcon />;
    }
  }

  console.log('inside notificationcard', { notification });
  return (
    <Box
      flex
      className={`notificationCard ${seen ? '' : 'new'}`}
      underline={!lastOne}
      padding="md"
      onClick={() => onClick(id)}
    >
      <Box width="auto" className="alignCardIcon">
        <Box flex className="center cardIconImage" padding={20} elevation={0}>
          {renderNotificationIcon()}
        </Box>
      </Box>

      <Box className="flex-column" width="100%">
        <Box flex>
          <Box>
            <Typography translation="common" variant="text-3" weight="medium">
              {notification.text.en.title}
            </Typography>
          </Box>
          <Box flex style={{ justifyContent: 'flex-end' }}>
            <Typography translation={null} variant="text-3" color="subdued">
              {updatedAtStr}
            </Typography>
          </Box>
        </Box>
        <Box style={{ marginTop: '8px' }}>
          <Typography translation={null} variant="text-3" color="subdued">
            {notification.text.en.body}
          </Typography>
        </Box>
      </Box>

      {!!onClose && (
        <IconButton
          size="small"
          className="closeNotification"
          onClick={() => onClose(id)}
        >
          <Close />
        </IconButton>
      )}
    </Box>
  );
};

export default NotificationCard;
