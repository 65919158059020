import React, { useContext, useEffect, useState } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import NavBar from 'Components/Organisms/NavBar';
import DateDropdown from 'Components/Organisms/DateDropdown';
import Button from 'Components/Atoms/Button';
import { Add } from 'Components/Atoms/Icons';
import {
  CalendarContext,
  CurrentShiftPropsType,
  View,
} from 'Contexts/CalendarContext';
import Calendar from 'Components/Molecules/Calendar';
import { classHelper, noop } from 'utils/helper';
import Week from 'App/Calendar/Components/Week';
import CurrentReservationShiftCard from 'App/Settings/Components/CurrentReservationShiftCard';
import NewEditReservationShift from 'App/Calendar/Components/NewEditReservationShiftV02';
import ShiftTemplate from 'App/Calendar/Components/ShiftTemplate';
import SelectCalendar from 'App/Calendar/Components/SelectCalendar';
import {
  GuestOrderingShift,
  OffersShift,
  ReservationShift,
  ShiftType,
  TakeAwayShift,
} from 'types/shifts';
import { ActiveShift } from 'CustomHooks/useCalendarDates';
import NewEditGuestOrderingShift from 'App/Calendar/Components/NewEditGuestOrderingShift';
import CurrentGuestOrderingShiftCard from 'App/Calendar/Components/CurrentGuestOrderingShiftCard';
import NewEditOfferShift from 'App/Calendar/Components/NewEditOfferShift';
import CurrentOfferShiftCard from 'App/Calendar/Components/CurrentOfferShiftCard';
import CurrentTakeAwayShiftCard from 'App/Calendar/Components/CurrentTakeAwayShiftCard';
import NewEditTakeAwayShift from 'App/Calendar/Components/NewEditTakeAwayShift';
import MultipleShiftsSidebar from 'App/Calendar/Components/MultipleShiftsSidebar';
import Dropdown from 'Components/Molecules/Dropdown';
import ShiftsListView from 'App/Calendar/Components/ShiftsListView';
import { Translations } from 'utils/types';
import useProtectedAction from 'utils/useProtectedAction';
import ButtonWithOptions from 'Components/Molecules/ButtonWithOptions';
import Close from '@material-ui/icons/Close';
import useRestaurant from 'CustomHooks/useRestaurant';

export type CalendarScreenProps = {
  restaurantId: string;
};

const VIEW_OPTIONS: {
  id: View;
  label?: string | undefined;
  translation?: Translations | undefined;
  disabled?: boolean | undefined;
}[] = [
  {
    id: View.WEEK,
    label: 'Week',
    translation: 'calendar',
  },
  {
    id: View.LIST,
    label: 'List',
    translation: 'calendar',
  },
];

const CalendarScreen: React.FC<CalendarScreenProps> = ({ restaurantId }) => {
  const {
    date,
    dates,
    onDateChange,
    currentShift,
    handleShiftClick,
    CurrentShiftProps,
    ActiveShiftProps,
    handleNewShift,
    closeNewShift,
    recentShifts,
    newShiftFromTemplate,
    newShiftSidebar,
    type,
    settype,
    currentMultipleShifts,
    setCurrentMultipleShifts,
    view,
    setview,
    setSelectedMultiCard,
  } = useContext(CalendarContext);

  const { products } = useRestaurant();

  console.log({ dates });

  const [viewOption, setViewOption] = useState<'List' | 'Week'>('Week');
  useEffect(() => {
    let el = document.getElementsByTagName('body')[0];

    el.classList.add('hideOverflow');
    el.classList.add('calendarscreen');
    return () => {
      el.classList.remove('hideOverflow');
      el.classList.remove('calendarscreen');
    };
  }, []);

  function viewOptionHandler() {
    if (viewOption === 'List') {
      setview(View.LIST) as unknown as React.SetStateAction<View>;
    } else if (viewOption === 'Week') {
      setview(View.WEEK) as unknown as React.SetStateAction<View>;
    }
  }

  useEffect(() => {
    viewOptionHandler();
  }, [viewOption]);

  const onCalendarChange = (id: ShiftType) => {
    settype(id);
    closeNewShift();
    CurrentShiftProps?.onClose();
    ActiveShiftProps?.onClose();
  };

  const protectedAction = useProtectedAction();

  return (
    <Box background className="calendar-screen">
      <NavBar
        productTranslation="calendar"
        title={`${type} Calendar`}
        reservationBook
        right={
          <>
            {(type === ShiftType.RESERVATION ||
              type === ShiftType.TAKEAWAY) && (
              <ButtonWithOptions
                id="closeRestaurant"
                variant="outlined"
                color="error"
                closeAutomatically
                translation="calendar"
                options={[
                  {
                    children: 'Close Restaurant for a Day',
                    variant: 'outlined',
                    translation: 'calendar',
                    color: 'error',
                    style: {
                      display:
                        type === ShiftType.RESERVATION ? 'block' : 'none',
                    },
                    onClick: () =>
                      protectedAction(
                        'chefWaiter',
                        'Neue Schicht',
                        () => {
                          newShiftFromTemplate('template-2', 'fromTemplate');
                        },
                        'calendar.newShift.write'
                      ),
                  },
                  {
                    children: 'Close for a longer Period',
                    variant: 'outlined',
                    translation: 'calendar',
                    color: 'error',
                    style: {
                      display:
                        type === ShiftType.RESERVATION ? 'block' : 'none',
                    },
                    onClick: () =>
                      protectedAction(
                        'chefWaiter',
                        'Neue Schicht',
                        () => {
                          newShiftFromTemplate('template-3', 'fromTemplate');
                        },
                        'calendar.newShift.write'
                      ),
                  },
                  {
                    children: 'Close TakeAway',
                    variant: 'outlined',
                    translation: 'calendar',
                    color: 'error',
                    style: {
                      display: products.includes('delivery') ? 'block' : 'none',
                    },
                    onClick: () =>
                      protectedAction(
                        'chefWaiter',
                        'Neue Schicht',
                        () => {
                          settype(ShiftType.TAKEAWAY);
                          newShiftFromTemplate('template-7', 'fromTemplate');
                        },
                        'calendar.newShift.write'
                      ),
                  },
                ]}
              >
                Close Restaurant
              </ButtonWithOptions>
            )}
            <Button
              variant="primary"
              translation="reservations"
              onClick={() => {
                protectedAction(
                  'chefWaiter',
                  'Neue Schicht',
                  () => {
                    handleNewShift();
                  },
                  'calendar.newShift.write'
                );
              }}
              endIcon={(p) => <Add {...p} />}
            >
              New Shift
            </Button>
          </>
        }
        hideChildren={false}
      >
        <DateDropdown
          type="week"
          value={date}
          onChange={onDateChange}
          padding={0}
        />
        <Dropdown
          options={['Week', 'List']}
          value={viewOption}
          onChange={(val: 'Week' | 'List') => setViewOption(val)}
        />
      </NavBar>
      <Box
        className={classHelper([
          'calendar-container',
          (!!CurrentShiftProps ||
            !!ActiveShiftProps ||
            !!newShiftSidebar ||
            !!currentMultipleShifts) &&
            'shift-open',
          !!ActiveShiftProps && 'editMode',
        ])}
      >
        <Box className="calendar-left" padding={22} elevation={1}>
          <Calendar width={272} value={date} onChange={onDateChange} />
          <SelectCalendar
            warnOnLeave={!!ActiveShiftProps}
            onLeave={noop}
            current={type}
            date={date}
            onCalendarChange={onCalendarChange}
          />
        </Box>
        {view === View.WEEK ? (
          <Week
            days={dates}
            currentShift={currentShift}
            activeShift={ActiveShiftProps?.activeShift}
            handleShiftClick={(date, id) => {
              handleShiftClick(date, id);
              setSelectedMultiCard(null);
            }}
          />
        ) : (
          <ShiftsListView
            days={dates}
            currentShift={currentShift}
            activeShift={ActiveShiftProps?.activeShift}
            handleShiftClick={handleShiftClick}
          />
        )}
        {currentMultipleShifts && !ActiveShiftProps && !newShiftSidebar && (
          <Box className="multiple-shifts-sidebar">
            <MultipleShiftsSidebar
              shifts={currentMultipleShifts}
              handleShiftClick={handleShiftClick}
              onClose={() => {
                setCurrentMultipleShifts(null);
                setSelectedMultiCard(null);
              }}
            />
          </Box>
        )}
        {CurrentShiftProps && !ActiveShiftProps && !newShiftSidebar && (
          <Box>
            {type === ShiftType.RESERVATION && (
              <CurrentReservationShiftCard
                restaurantId={restaurantId}
                {...(CurrentShiftProps as CurrentShiftPropsType<ReservationShift>)}
              />
            )}
            {type === ShiftType.GUEST_ORDERING && (
              <CurrentGuestOrderingShiftCard
                restaurantId={restaurantId}
                {...(CurrentShiftProps as CurrentShiftPropsType<GuestOrderingShift>)}
              />
            )}
            {type === ShiftType.HAPPY_HOURS && (
              <CurrentOfferShiftCard
                restaurantId={restaurantId}
                {...(CurrentShiftProps as CurrentShiftPropsType<OffersShift>)}
              />
            )}
            {type === ShiftType.TAKEAWAY && (
              <CurrentTakeAwayShiftCard
                restaurantId={restaurantId}
                {...(CurrentShiftProps as CurrentShiftPropsType<TakeAwayShift>)}
              />
            )}
          </Box>
        )}
        {newShiftSidebar && !ActiveShiftProps && (
          <Box>
            <ShiftTemplate
              onClose={closeNewShift}
              onClick={newShiftFromTemplate}
              recentShifts={recentShifts}
              type={type}
            />
          </Box>
        )}
        {ActiveShiftProps && (
          <Box>
            {ActiveShiftProps?.activeShift?.shift.type ===
              ShiftType.RESERVATION && (
              <NewEditReservationShift
                activeShift={{
                  ...ActiveShiftProps.activeShift,
                  shift: ActiveShiftProps.activeShift.shift as ReservationShift,
                }}
                setactiveShift={(value) =>
                  ActiveShiftProps.setactiveShift(
                    value as React.SetStateAction<ActiveShift>
                  )
                }
                onClose={ActiveShiftProps.onClose}
                onSubmit={ActiveShiftProps.onSubmit}
                spaces={ActiveShiftProps.spaces}
                settings={ActiveShiftProps.settings}
              />
            )}
            {ActiveShiftProps.activeShift?.shift.type ===
              ShiftType.GUEST_ORDERING && (
              <NewEditGuestOrderingShift
                activeShift={{
                  ...ActiveShiftProps.activeShift,
                  shift: ActiveShiftProps.activeShift
                    .shift as GuestOrderingShift,
                }}
                setactiveShift={(value) =>
                  ActiveShiftProps.setactiveShift(
                    value as React.SetStateAction<ActiveShift>
                  )
                }
                onClose={ActiveShiftProps.onClose}
                onSubmit={ActiveShiftProps.onSubmit}
                menues={ActiveShiftProps.settings?.menues ?? []}
              />
            )}
            {ActiveShiftProps.activeShift?.shift.type ===
              ShiftType.TAKEAWAY && (
              <NewEditTakeAwayShift
                activeShift={{
                  ...ActiveShiftProps.activeShift,
                  shift: ActiveShiftProps.activeShift.shift as TakeAwayShift,
                }}
                setactiveShift={(value) =>
                  ActiveShiftProps.setactiveShift(
                    value as React.SetStateAction<ActiveShift>
                  )
                }
                onClose={ActiveShiftProps.onClose}
                onSubmit={ActiveShiftProps.onSubmit}
                menues={ActiveShiftProps.settings?.menues ?? []}
              />
            )}
            {ActiveShiftProps.activeShift?.shift.type ===
              ShiftType.HAPPY_HOURS && (
              <NewEditOfferShift
                activeShift={{
                  ...ActiveShiftProps.activeShift,
                  shift: ActiveShiftProps.activeShift.shift as OffersShift,
                }}
                setactiveShift={(value) =>
                  ActiveShiftProps.setactiveShift(
                    value as React.SetStateAction<ActiveShift>
                  )
                }
                onClose={ActiveShiftProps.onClose}
                onSubmit={ActiveShiftProps.onSubmit}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CalendarScreen;
