import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const TakeAwayIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.3332 13.3333H28.3332V6.66666H4.99984C3.1665 6.66666 1.6665 8.16666 1.6665 9.99999V28.3333H4.99984C4.99984 31.1 7.23317 33.3333 9.99984 33.3333C12.7665 33.3333 14.9998 31.1 14.9998 28.3333H24.9998C24.9998 31.1 27.2332 33.3333 29.9998 33.3333C32.7665 33.3333 34.9998 31.1 34.9998 28.3333H38.3332V20L33.3332 13.3333ZM9.99984 30.8333C8.6165 30.8333 7.49984 29.7167 7.49984 28.3333C7.49984 26.95 8.6165 25.8333 9.99984 25.8333C11.3832 25.8333 12.4998 26.95 12.4998 28.3333C12.4998 29.7167 11.3832 30.8333 9.99984 30.8333ZM32.4998 15.8333L35.7665 20H28.3332V15.8333H32.4998ZM29.9998 30.8333C28.6165 30.8333 27.4998 29.7167 27.4998 28.3333C27.4998 26.95 28.6165 25.8333 29.9998 25.8333C31.3832 25.8333 32.4998 26.95 32.4998 28.3333C32.4998 29.7167 31.3832 30.8333 29.9998 30.8333Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default TakeAwayIcon;
