import './styles.scss';
import { useEffect, useMemo, useState } from 'react';
import {
  AddRounded,
  ArrowBackRounded,
  CloseRounded,
  CheckRounded,
} from '@material-ui/icons';
import useRestaurant from 'CustomHooks/useRestaurant';
import { sign } from 'crypto';

type ListProps = {
  signatures: string[];
  selectSignature: (initials: string) => void;
  addSignature: () => void;
  onClose: () => void;
  allowAddSignature: boolean;
};

type CodeProps = {
  signature: Signature;
  isNew?: boolean;
  onGoBack: () => void;
  onClose: () => void;
  onSubmit: (signature: string, timeout?: number) => void;
  message?: string;
  isConfirmation?: boolean;
  moreSignatures?: boolean;
};

type PinProps = {
  onClose?: () => void;
  onDenial?: () => void;
  onSubmit?: (signature: string, timeout?: number) => void;
  disallowAddSignature: boolean;
  signatures?: string[];
};

type Signature = {
  initials: string;
  pin: string;
  color: string;
  checkPin?: string;
};

const List = ({
  signatures = [],
  selectSignature,
  addSignature,
  onClose,
  allowAddSignature = false,
}: ListProps) => {
  const badges = useMemo(() => {
    let signatureLength = signatures.length;

    const badges = [];

    for (
      let i = 0;
      i < Math.ceil((signatureLength + (allowAddSignature ? 1 : 0)) / 4);
      i++
    ) {
      badges.push(signatures.slice(i * 4, (i + 1) * 4));
    }

    return badges;
  }, [signatures]);

  return (
    <div className="list">
      <div
        onClick={() => onClose()}
        style={{
          backgroundColor: 'var(--color-btn-default)',
          color: 'var(--color-text)',
          marginBottom: 20,
        }}
        className="p-button small"
      >
        <CloseRounded />
      </div>
      {badges.map((badge, i) => (
        <div className="row" key={i}>
          {badge.map((signature, i1) => (
            <div
              key={signature}
              style={{ backgroundColor: PIN_COLORS[i1] }}
              className="p-button"
              onClick={() => selectSignature(signature)}
            >
              <span>{signature}</span>
            </div>
          ))}
          {allowAddSignature && badges.length === i + 1 && (
            <div
              onClick={() => addSignature()}
              style={{
                backgroundColor: 'var(--color-btn-default)',
                color: 'var(--color-text)',
              }}
              className="p-button"
            >
              <AddRounded />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export async function hashPincode(p: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(p);
  const hash = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hash)); // convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join(''); // convert bytes to hex string
  return hashHex;
}

export const TIMEOUT_OPTIONS = [2, 5, 10, 15, 30, 60];

const Code = ({
  signature,
  isNew = false,
  onGoBack,
  onClose,
  onSubmit,
  message,
  isConfirmation = false,
  moreSignatures = true,
}: CodeProps) => {
  const [pin, setPin] = useState('');
  const [error, setError] = useState('');
  const [trials, settrials] = useState(0);

  const [to, setto] = useState(2);

  // const handleSubmit = (signature:string) => onSubmit(signature, to * 60000);

  useEffect(() => {
    async function check() {
      let check = await hashPincode(pin);

      console.log({ check, signature, isNew });

      if (isNew || !signature.pin || check === signature.pin) {
        onSubmit(signature.initials, to * 60000);
      } else {
        if (trials === 2) {
          onClose();
        }
        setError('Versuche es erneut');
        settrials((x) => x + 1);
        document.getElementById('pin')?.classList.add('shake');

        setTimeout(() => {
          setPin((x) => (x.length ? '' : x));
          document.getElementById('pin')?.classList?.remove?.('shake');
        }, 1000);

        setTimeout(() => {
          setError('');
        }, 2000);
      }
    }
    if (pin.length === 4) {
      check();
    } else if (!!pin.length) {
      setError('');
    }
  }, [pin, signature, onSubmit]);

  useEffect(() => {
    function recordKeyStroke(e: any) {
      let n = e.key;

      if (n === 'Backspace') {
        setPin((x) => (x.length === 0 ? x : x.substring(0, x.length - 1)));
      }

      if (isNaN(n)) {
        return;
      }

      setPin((x) => (x.length === 4 ? x : x + String(n)));

      return;
    }

    console.count('x');

    document.addEventListener('keyup', recordKeyStroke);

    return () => {
      document.removeEventListener('keyup', recordKeyStroke);
    };
  }, []);

  return (
    <>
      <div className="code">
        <span
          className="message"
          style={{ color: error ? '#CC1023' : '#000000' }}
        >
          {error || message || ''}
        </span>
        <div
          style={{
            display: 'flex',
            gap: 16,
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '32px',
          }}
        >
          {moreSignatures ? (
            <div
              onClick={() => onGoBack()}
              style={{
                backgroundColor: 'var(--color-btn-default)',
                color: 'var(--color-text)',
              }}
              className="p-button small"
            >
              <ArrowBackRounded />
            </div>
          ) : (
            <div style={{ width: 48, height: 1 }} />
          )}
          <div
            className="p-button large"
            style={{ backgroundColor: signature.color }}
          >
            {signature?.initials ?? 'x'}
          </div>
          {!isConfirmation ? (
            <div
              onClick={() => onClose()}
              style={{
                backgroundColor: 'var(--color-btn-default)',
                color: 'var(--color-text)',
              }}
              className="p-button small"
            >
              <CloseRounded />
            </div>
          ) : (
            <div
              onClick={() => onSubmit(signature?.initials ?? 'x')}
              style={{
                backgroundColor: 'var(--color-btn-default)',
                color: 'var(--color-text)',
              }}
              className="p-button small"
            >
              <CheckRounded />
            </div>
          )}
        </div>
        <div
          id="pin"
          style={{
            display: 'flex',
            gap: 20,
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '32px',
          }}
        >
          <span
            className="pinBit"
            style={{
              backgroundColor: !!error
                ? '#CC1023'
                : pin.length >= 1
                ? 'var(--color-text)'
                : 'var(--color-disabled)',
            }}
          />
          <span
            className="pinBit"
            style={{
              backgroundColor: !!error
                ? '#CC1023'
                : pin.length >= 2
                ? 'var(--color-text)'
                : 'var(--color-disabled)',
            }}
          />
          <span
            className="pinBit"
            style={{
              backgroundColor: !!error
                ? '#CC1023'
                : pin.length >= 3
                ? 'var(--color-text)'
                : 'var(--color-disabled)',
            }}
          />
          <span
            className="pinBit"
            style={{
              backgroundColor: !!error
                ? '#CC1023'
                : pin.length >= 4
                ? 'var(--color-text)'
                : 'var(--color-disabled)',
            }}
          />
        </div>
        <div className="row">
          {[1, 2, 3].map((n) => (
            <div
              key={n}
              onClick={() =>
                setPin((x) => (x.length === 4 ? x : x + String(n)))
              }
              style={{
                backgroundColor: 'var(--color-btn-default)',
                color: 'var(--color-text)',
              }}
              className="p-button"
            >
              {n}
            </div>
          ))}
        </div>
        <div className="row">
          {[4, 5, 6].map((n) => (
            <div
              key={n}
              onClick={() =>
                setPin((x) => (x.length === 4 ? x : x + String(n)))
              }
              style={{
                backgroundColor: 'var(--color-btn-default)',
                color: 'var(--color-text)',
              }}
              className="p-button"
            >
              {n}
            </div>
          ))}
        </div>
        <div className="row">
          {[7, 8, 9].map((n) => (
            <div
              key={n}
              onClick={() =>
                setPin((x) => (x.length === 4 ? x : x + String(n)))
              }
              style={{
                backgroundColor: 'var(--color-btn-default)',
                color: 'var(--color-text)',
              }}
              className="p-button"
            >
              {n}
            </div>
          ))}
        </div>
        <div className="row">
          <div
            onClick={() =>
              setPin((x) => (!x.length ? '' : x.substring(0, x.length - 1)))
            }
            style={{
              backgroundColor: 'var(--color-btn-default)',
              color: 'var(--color-text)',
            }}
            className="p-button"
          >
            R
          </div>
          <div
            onClick={() => setPin((x) => (x.length === 4 ? x : x + '0'))}
            style={{
              backgroundColor: 'var(--color-btn-default)',
              color: 'var(--color-text)',
            }}
            className="p-button"
          >
            0
          </div>
          <div
            onClick={() => setPin('')}
            style={{
              backgroundColor: 'var(--color-btn-default)',
              color: 'var(--color-text)',
            }}
            className="p-button"
          >
            C
          </div>
        </div>
      </div>
      <div
        className="p-button timeout"
        onClick={() =>
          setto(
            (to) =>
              TIMEOUT_OPTIONS[
                (TIMEOUT_OPTIONS.indexOf(to) + 1) % TIMEOUT_OPTIONS.length
              ]
          )
        }
      >
        {to}
      </div>
    </>
  );
};

export const PIN_COLORS = [
  '#10CCB9',
  '#6369D1',
  '#CCC910',
  '#49B800',
  '#EE4266',
  '#1E90FF',
];

export default function Pin({
  onClose = () => {},
  onDenial = () => {},
  onSubmit = () => {},
  signatures = [],
  disallowAddSignature = true,
}: PinProps) {
  const { securityPlus } = useRestaurant();

  const [currentSignature, setCurrentSignature] = useState<Signature | null>(
    null
  );

  const [newSignature, setNewSignature] = useState<Signature | null>(null); // { initials: '', pin: '', checkPin: '' }
  const [addNewSignature, setAddNewSignature] = useState<boolean>(false);

  // function addNewSignatureHandler(newSignature?: Signature) {}

  useEffect(() => {
    if (signatures.length === 1) {
      setCurrentSignature({
        initials: signatures[0],
        pin:
          securityPlus?.settings?.signatures?.[signatures[0]] ??
          '0ffe1abd1a08215353c233d6e009613e95eec4253832a761af28ff37ac5a150c',
        color: PIN_COLORS[0],
      });
    } else {
      setCurrentSignature(null);
    }
  }, [signatures, securityPlus]);

  return (
    <div className="pin-alert-wrapper">
      {!currentSignature && !newSignature && (
        <List
          allowAddSignature={false}
          onClose={() => {
            onClose();
            setCurrentSignature(null);
          }}
          signatures={signatures}
          selectSignature={(initials) => {
            let index = signatures.indexOf(initials);

            setCurrentSignature({
              initials: signatures[index],
              pin:
                securityPlus?.settings?.signatures?.[signatures[index]] ??
                '0ffe1abd1a08215353c233d6e009613e95eec4253832a761af28ff37ac5a150c',
              color: PIN_COLORS[index % PIN_COLORS.length],
            });
          }}
          addSignature={() => {
            setNewSignature({ initials: '', pin: '', checkPin: '', color: '' });
            setAddNewSignature(true);
          }}
        />
      )}
      {!!currentSignature && (
        <Code
          signature={currentSignature}
          onClose={() => {
            onClose();
            setCurrentSignature(null);
          }}
          moreSignatures={signatures.length > 1}
          onGoBack={() => setCurrentSignature(null)}
          onSubmit={(signature) => {
            onSubmit(signature);
            setCurrentSignature(null);
          }}
        />
      )}
      {/* {!currentSignature && addNewSignature && (
        <Code
          isNew={addNewSignature}
          signature={newSignature}
          onClose={() => {
            onClose();
            setCurrentSignature(null);
          }}
          onGoBack={() => setCurrentSignature(null)}
          onSubmit={() => {
            // addNewSignatureHandler();
          }}
        />
      )} */}
      {!!newSignature && <div></div>}
    </div>
  );
}
