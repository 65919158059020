import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const CenterVertical = (props: SvgIconProps) => {
  return (
    <SvgIcon width="36" height="36" viewBox="0 0 36 36" fill="none">
      <rect width="36" height="36" rx="18" fill="#EDF2F7" />
      <path
        d="M17.375 8.625C17.375 8.27982 17.6548 8 18 8C18.3452 8 18.625 8.27982 18.625 8.625V12.375H17.375V8.625Z"
        fill="#011311"
      />
      <path
        d="M17.375 23.625H18.625V27.375C18.625 27.7202 18.3452 28 18 28C17.6548 28 17.375 27.7202 17.375 27.375V23.625Z"
        fill="#011311"
      />
      <path
        d="M27.375 13H8.625C8.27982 13 8 13.2798 8 13.625V22.375C8 22.7202 8.27982 23 8.625 23H27.375C27.7202 23 28 22.7202 28 22.375V13.625C28 13.2798 27.7202 13 27.375 13Z"
        fill="#011311"
      />
    </SvgIcon>
  );
};

export default CenterVertical;
