import React from "react";
import { default as MaterialBadge } from "@material-ui/core/Badge";
import { Theme, withStyles, createStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      top: "10%",
      right: "80%",
    },
  })
)(MaterialBadge);

const StyledBadgeV2 = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      top: "50%",
      right: "-10%",
    },
  })
)(MaterialBadge);

export type BadgeProps = {
  children?: React.ReactChild;
  number: number;
  color?: "default" | "primary" | "secondary" | "error";
  v2?: boolean;
};

export default function Badge({
  children,
  number = 0,
  color = "secondary",
  v2 = false,
}: BadgeProps) {
  if (v2) {
    return (
      <StyledBadgeV2 badgeContent={number} color={color}>
        {children ?? <span></span>}
      </StyledBadgeV2>
    );
  }

  return (
    <StyledBadge badgeContent={number} color={color}>
      {children ?? <span></span>}
    </StyledBadge>
  );
}
