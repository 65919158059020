import { Severity } from "Contexts/RestaurantContext";
import { noop } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { asyncVoid } from "utils/helper";
import server from "utils/server";
import useAuth from "./useAuth";
import useRestaurant from "./useRestaurant";
import useToast from "./useToast";

export interface ShuffleState {
  fixedReservations: string[];
  preffered?: null | "indoor" | "outdoor";
  reservations?: {
    id: string;
    tables: string[];
    space: string;
    previousTables: string[];
  }[];
  ignoreDistance?: boolean;
  analysis?: {
    noTables: number;
    unfitingTables: number;
    overlaps: number;
  };
  scoreDiff?: number;
  useBlockedTables?: number;
  useBlockedTablesResult?: number;
  loading: boolean;
  date: string;
  error?: string;
}

export interface Shuffle {
  state: ShuffleState | null;
  set: React.Dispatch<React.SetStateAction<ShuffleState | null>>;
  activateShuffle: (date: string) => void;
  toggleFixedReservation: (resaId: string) => void;
  requestShuffle: () => Promise<void>;
  submitShuffle: () => Promise<void>;
  closeShuffle: () => void;
  warnings: number;
  hasErrors: boolean;
}

export const SHUFFLE_DEFAULT: Shuffle = {
  state: null,
  set: noop,
  activateShuffle: noop,
  toggleFixedReservation: noop,
  requestShuffle: asyncVoid,
  submitShuffle: asyncVoid,
  closeShuffle: noop,
  warnings: 0,
  hasErrors: false,
};

const useShuffle = () => {
  const { restaurantId } = useRestaurant();
  const { uid } = useAuth();
  const toast = useToast();

  const [state, set] = useState<null | ShuffleState>(null);

  useEffect(() => {
    if (state) {
      set(null);
    }
  }, [restaurantId]);

  const activateShuffle = (date: string) =>
    set({ fixedReservations: [], date, loading: false });
  const toggleFixedReservation = (resaId: string) =>
    set((s) =>
      !!s
        ? {
            ...s,
            fixedReservations: s.fixedReservations.includes(resaId)
              ? s.fixedReservations.filter((r) => r !== resaId)
              : [...s.fixedReservations, resaId],
          }
        : null
    );
  const requestShuffle = async () => {
    if (!state || state?.loading) return;
    set((s) => (!!s ? { ...s, loading: true } : null));

    try {
      const { data } = await server.post<{
        result: ShuffleState["reservations"];
        analysisAfter: ShuffleState["analysis"];
        scoreDiff: number;
        useBlockedTables: number;
      }>(`/v03/reservations/shuffle/${restaurantId}/${state.date}/check`, {
        preffered: state.preffered,
        fixedReservations: state.fixedReservations,
        useBlockedTables: state.useBlockedTables,
        ignoreDistance: state.ignoreDistance,
      });

      set((s) =>
        !!s
          ? {
              ...s,
              error: "",
              reservations: data.result,
              analysis: data.analysisAfter,
              scoreDiff: data.scoreDiff,
              loading: false,
              useBlockedTablesResult: data.useBlockedTables ? 1 : 0,
            }
          : null
      );
    } catch (error: any) {
      set((s) =>
        !!s
          ? {
              ...s,
              error:
                error?.response?.data?.message ??
                error?.message ??
                "Ein Fehler ist aufgetreten",
              loading: false,
            }
          : null
      );
    }
  };

  const submitShuffle = async () => {
    if (!state || state?.loading) return;
    set((s) => (!!s ? { ...s, loading: true } : null));

    try {
      await server.post(
        `/v03/reservations/shuffle/${restaurantId}/${state.date}/submit`,
        { uid, reservations: state.reservations }
      );

      set(null);
      toast("Reservierungen wurden neu platziert", Severity.SUCCESS, "common");
    } catch (error: any) {
      set((s) =>
        !!s
          ? {
              ...s,
              error:
                error?.response?.data?.message ??
                error?.message ??
                "Ein Fehler ist aufgetreten",
              loading: false,
            }
          : null
      );
    }
  };

  const closeShuffle = () => set(null);

  const warnings = useMemo(() => {
    if (!state) return 0;
    return (
      (state.analysis?.noTables ?? 0) +
      (state.analysis?.overlaps ?? 0) +
      (state.analysis?.unfitingTables ?? 0) +
      (state.useBlockedTablesResult ? 1 : 0) +
      (state.error ? 1 : 0)
    );
  }, [state]);

  const hasErrors = useMemo(() => {
    if (!state) return false;

    return !!(
      state.error ||
      state.analysis?.noTables ||
      state.analysis?.overlaps
    );
  }, [state]);

  return {
    state,
    activateShuffle,
    toggleFixedReservation,
    requestShuffle,
    submitShuffle,
    closeShuffle,
    warnings,
    set,
    hasErrors,
  };
};

export default useShuffle;
