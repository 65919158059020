import { useEffect, useState } from "react";
import { dateHelper } from "utils/helper";

const DAY = 60000 * 60 * 24;

export const shiftStartOfWeek = (wDay: number) => (!!wDay ? (wDay - 1) % 7 : 6);

export const getCalendarWeek = (date = new Date()) => {
  if (!(date instanceof Date)) {
    date = new Date(date);
  } else {
    date.setHours(2);
  }

  const firstJanuary = new Date(date.getFullYear(), 0, 1, 2, 0);

  let days =
    shiftStartOfWeek(firstJanuary.getDay()) <= 3
      ? shiftStartOfWeek(firstJanuary.getDay()) * -1
      : 7 - shiftStartOfWeek(firstJanuary.getDay());

  const startOfFirstWeek = new Date(date.getFullYear(), 0, 1 + days, 2, 0);

  const daysPased = Math.ceil((date.getTime() - startOfFirstWeek.getTime()) / DAY);

  const calendarWeek = Math.floor((daysPased - shiftStartOfWeek(date.getDay())) / 7);

  return calendarWeek;
};

export const getCalendarWeekString = (date = new Date()) => {
  const calendarWeek = getCalendarWeek(date);

  return (
    date.toISOString().slice(0, 10) + "-" + (calendarWeek < 10 ? "0" : "") + calendarWeek
  );
};

export const getCalendarWeekArray = (
  calendarWeek: number,
  yyyy = new Date().getFullYear()
) => {
  let firstOfYear = new Date(yyyy, 0, 1, 2, 0);

  let days =
    shiftStartOfWeek(firstOfYear.getDay()) <= 3
      ? shiftStartOfWeek(firstOfYear.getDay()) * -1
      : 7 - shiftStartOfWeek(firstOfYear.getDay());

  const startOfFirstWeek = new Date(yyyy, 0, 1 + days, 2, 0);

  let date = new Date(startOfFirstWeek.getTime() + calendarWeek * DAY * 7);

  let firstDateOfWeek = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() - shiftStartOfWeek(date.getDay()),
    2
  );

  let arr = Array.from(new Array(7), (x, i) => {
    let d = new Date(firstDateOfWeek);
    d.setDate(d.getDate() + i);

    return d.toISOString().split("T")[0];
  });

  return arr;
};

export const isSummerTime = (date = new Date()) => {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  date.setHours(10);

  let endOfWinterTime = new Date(date.getFullYear(), 3, 0, 5, 0);

  endOfWinterTime.setDate(endOfWinterTime.getDate() - endOfWinterTime.getDay());

  let endOfSummerTime = new Date(date.getFullYear(), 10, 0, 3, 0);

  endOfSummerTime.setDate(endOfSummerTime.getDate() - endOfSummerTime.getDay());

  return (
    endOfWinterTime.getTime() < date.getTime() &&
    endOfSummerTime.getTime() > date.getTime()
  );
};

export const getMonth = (monthId = new Date().toISOString().slice(0, 7)) => {
  let [yyyy, mm] = monthId.split("-").map((m) => parseInt(m));

  let firstDate = new Date(yyyy, mm - 1, 1, 3, 0);

  let fCW = getCalendarWeek(firstDate);

  let lastDate = new Date(yyyy, mm, 0, 3, 0);

  let lCW = getCalendarWeek(lastDate);

  let weeks = [];

  for (let calendarWeek = fCW; calendarWeek <= lCW; calendarWeek++) {
    weeks.push({
      calendarWeek,
      year: yyyy,
      days: getCalendarWeekArray(calendarWeek, yyyy).map((date, wDay) => {
        return {
          date,
          wDay,
          disabled: !date.startsWith(monthId),
          shift: false,
        };
      }),
    });
  }

  return {
    id: monthId,
    weeks,
  };
};

export type Day = {
  date: string;
  wDay: number;
  disabled: boolean;
  shift: boolean;
};

export type Month = {
  weeks: {
    calendarWeek: number;
    year: number;
    days: Day[];
  }[];
  id: string;
};

const useCalendar = (currentDate: string | null = null) => {
  const [month, setmonth] = useState<Month | null>(null);

  const [monthId, setmonthId] = useState(currentDate || dateHelper().slice(0, 7));

  useEffect(() => {
    if (currentDate !== null) {
      setmonthId(currentDate.slice(0, 7));
    } else {
      setmonthId(dateHelper().slice(0, 7));
    }
  }, [currentDate]);

  useEffect(() => {
    if (monthId !== null) {
      setmonth(getMonth(monthId));
    } else {
      setmonth(getMonth());
    }
  }, [monthId]);

  const nextMonth = () => {
    let [yyyy, mm] = monthId.split("-").map((x) => parseInt(x));

    let date = new Date(yyyy, mm, 1, 2);

    let newMonthId = date.toISOString().slice(0, 7);

    setmonthId(newMonthId);
  };

  const prevMonth = () => {
    let [yyyy, mm] = monthId.split("-").map((x) => parseInt(x));

    let date = new Date(yyyy, mm - 1, 1, 2);

    let newMonthId = date.toISOString().slice(0, 7);

    setmonthId(newMonthId);
  };

  return { monthId, month, nextMonth, prevMonth, setmonthId };
};

export default useCalendar;
