import useEditReservation, {
  ReservationSettings,
} from 'CustomHooks/useEditReservation';
import useFloorPlan from 'CustomHooks/useFloorPlan';
import useReservations from 'CustomHooks/useReservations';
import React, {
  createContext,
  useState,
  useMemo,
  useContext,
  useEffect,
} from 'react';
import {
  EditReservation,
  FloorPlan,
  Reservation,
  ReservationStatus,
  ResSettings,
  Warning,
  TimeSlot,
} from 'types/reservations';
import {
  blockToTime,
  dateHelper,
  noop,
  stitchShifts,
  timeHelper,
  timeToBlock,
} from 'utils/helper';
import useInitializeReservations from 'CustomHooks/useInitializeReservations';
import { AuthContext } from './AuthContext';
import { db, FieldValue } from 'utils/firebase';
import {
  RestaurantContext,
  Severity,
  useAddOnOrders,
} from './RestaurantContext';
import { SwitchReservationModalProps } from 'App/ReservationBook/Components/SwitchReservationModal';
import { ReservationShift, ShiftComment } from 'types/shifts';
import useShiftComment from 'CustomHooks/useShiftComment';
import { AlertState } from 'Components/Organisms/Alert';
import { useMediaQuery } from '@material-ui/core';
import useLocalStorageState from 'CustomHooks/useLocalStorageState';
import useShuffle, { Shuffle, SHUFFLE_DEFAULT } from 'CustomHooks/useShuffle';
import useToast from 'CustomHooks/useToast';
import NoShowModal from 'App/ReservationBook/Components/NoShowModal';
import { WaitingListEntryDocument } from 'gastronaut-shared/types/documents';
import useWaitinglist, {
  WaitingListActionType,
} from 'CustomHooks/useWaitinglist';
import { AddOnOrder } from 'App/Experiences/types/addOnOrder';
import server from 'utils/server';
import useTableInfo, { TableInfo } from 'CustomHooks/useTableInfo';
import useCollection from 'CustomHooks/useCollection';
import { RequestStatus } from 'gastronaut-shared/types/helper/reservations';
import { Ticket } from 'gastronaut-shared/types/documents/restaurants';
import useRegularTables from 'CustomHooks/useRegularTables';
import useRestaurant from 'CustomHooks/useRestaurant';

const cancelationReasons: AlertState['inputOptions'] = [
  {
    id: '',
    label: "Don't send a reason",
    translation: 'reservations',
  },
  {
    id: 'other',
    label: 'Anderer Grund (selbst verfassen)',
    translation: 'reservations',
  },
  {
    id: 'Wir sind an diesem Tag leider geschlossen.',
    label: 'We are unfortunately closed on this day.',
    translation: 'reservations',
  },
  {
    id: 'Wir haben an diesem Tag eine geschlossene Veranstaltung.',
    label: 'We have a closed event that day.',
    translation: 'reservations',
  },
  {
    id: 'Wir haben an diesem Tag eine Veranstaltung - Für mehr Infos können sie Auf unserer Webseite nachsehen.',
    label:
      'We have an event that day - For more info you can check our website.',
    translation: 'reservations',
  },
  {
    id: 'Leider haben Sie Ihre Reservierung bei uns nicht wahr genommen.',
    label: 'Unfortunately, you did not keep your reservation with us.',
    translation: 'reservations',
  },
  {
    id: 'Gast hat storniert',
    label: 'Gast hat storniert',
    translation: 'reservations',
  },
];

export const FIXED_CANCELABLE_WARNINGS = [
  'No Tables',
  'Table is too large',
  'Table is too small',
  'Blacklisted Guest',
];

export enum ReservationActionTypes {
  RELOCATE = 'relocate',
  SEAT = 'seat',
  MARK_AS_DONE = 'markAsDone',
  ATTR = 'attr',
  EXCLUDE_FROM_SLOTS = 'excludeFromSlots',
  CANCEL = 'cancel',
  UNSEAT = 'unseat',
  RESEAT = 'reseat',
  HOST_COMMENT = 'hostComment',
  GUEST_COMMENT = 'guestComment',
  NO_SHOW = 'noshow',
  CHANGE_TABLE = 'changeTable',
  CONFIRM = 'confirm',
  REFUSE = 'refuse',
  NO_SHOW_FEE = 'noshowFee',
  TOGGLE_PIN = 'togglePin',
  PARTLY_SEAT = 'partlySeat',
  FULLY_SEAT = 'fullySeat',
  MARK_AS_LATE = 'markAsLate',
  CANCEL_WARNING = 'cancelWarning',
  LATE_BY = 'lateBy',
  COMMENT = 'comment',
  CUSTOM_STATUS = 'custom_status',
}

export type NoShowModal = {
  reservationId: string;
  amount: number;
  description: string;
  statement_descriptor: string;
  uid: string;
};

// eslint-disable-next-line
type Context = {
  editReservation: EditReservation | null;
  seteditReservation: React.Dispatch<
    React.SetStateAction<EditReservation | null>
  >;
  date: string;
  setdate: React.Dispatch<React.SetStateAction<string>>;
  reservations: Reservation[];
  filteredReservations: Reservation[];
  shifts: ReservationShift[] | null;
  endOfShift: boolean;
  currentTable: string | null;
  setcurrentTable: React.Dispatch<React.SetStateAction<string | null>>;
  currentReservation: string | null;
  setcurrentReservation: React.Dispatch<React.SetStateAction<string | null>>;
  currentShift: string | null;
  setCurrentShift: React.Dispatch<React.SetStateAction<string | null>>;
  handleTableClick: (
    id: string,
    name: string,
    space: string,
    available?: boolean
  ) => void;
  handleAction: (
    id: string,
    type: ReservationActionTypes,
    payload?: any
  ) => void;
  handleNewReservation: (
    date: string,
    walkin?: boolean,
    tables?: string[] | null,
    occassion?: string,
    space?: string
  ) => void;
  handleSubmit: () => Promise<void>;
  handleRelocate: (
    reservation: Reservation | null,
    keepTables?: boolean
  ) => void;
  onReservationAction: (
    id: string,
    type: ReservationActionTypes,
    payload?: any
  ) => void;
  warnings: Warning[];
  reservationLoading: boolean;
  floorPlanProps: {
    space: string | null;
    onSpaceChange: (id: string) => void;
    floorPlan: FloorPlan | null;
    closed:
      | false
      | {
          message: string | null;
          error: boolean;
        };
    loading: boolean;
    setShifts: React.Dispatch<React.SetStateAction<ReservationShift[] | null>>;
  };
  currentTime: null | string;
  settings?: ResSettings;
  occassions?: { id: string; title: string }[];
  switchReservationState: null | SwitchReservationModalProps;
  setswitchReservationState: React.Dispatch<
    React.SetStateAction<null | SwitchReservationModalProps>
  >;
  shiftComments: ShiftComment[];
  reservationSettings?: ReservationSettings;
  tableStrs: string[];
  getStorageTableStrs: () => void;
  setStorageTableStrs: (tableStr: string) => void;
  showTimesInEditMode: boolean;
  setShowTimesInEdit: (
    param: ((oldState: boolean) => boolean) | boolean
  ) => void;
  Shuffle: Shuffle;
  shift: ReservationShift | null;
  hideLiveResas: boolean;
  toggleHideLiveResas: () => void;
  waitinglistEntries: (WaitingListEntryDocument & { index: number })[];
  handleWaitinglistAction: (
    id: string,
    type: WaitingListActionType,
    payload?: any
  ) => void;
  currentWaitinglistEntry: string | null;
  setcurrentWaitinglistEntry: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  addOnOrders: (AddOnOrder & {
    id: string;
  })[];
  getAddOnOrders: (id: string, all?: boolean) => AddOnOrder[];
  tableInfo: TableInfo;
  hasWaitingList: boolean;
  hasPendingLargeGroupRequests: number;
  showWarnings: boolean;
  setshowWarnings: React.Dispatch<React.SetStateAction<boolean>>;
  customFields: string[];
  tickets: {
    ref: any;
    data: (Ticket & {
      id: string;
    })[];
    loading: boolean;
    error: string | null;
  };
};

interface Props {
  children: any;
  restaurantId: null | string;
  setrestaurantId: Function;
}

export const ReservationContext = createContext<Context>({
  editReservation: null,
  filteredReservations: [],
  date: dateHelper(),
  setdate: noop,
  seteditReservation: noop,
  reservations: [],
  shifts: [],
  endOfShift: false,
  currentTable: null,
  setcurrentTable: noop,
  currentReservation: null,
  setcurrentReservation: noop,
  currentWaitinglistEntry: null,
  setcurrentWaitinglistEntry: noop,
  currentShift: null,
  setCurrentShift: noop,
  handleTableClick: noop,
  handleAction: noop,
  handleNewReservation: noop,
  handleSubmit: async () => {},
  handleRelocate: noop,
  floorPlanProps: {
    space: null,
    onSpaceChange: noop,
    floorPlan: null,
    closed: false,
    loading: true,
    setShifts: noop,
  },
  currentTime: null,
  onReservationAction: noop,
  occassions: [],
  reservationLoading: false,
  warnings: [],
  switchReservationState: null,
  setswitchReservationState: noop,
  shiftComments: [],
  tableStrs: [],
  getStorageTableStrs: noop,
  setStorageTableStrs: noop,
  showTimesInEditMode: false,
  setShowTimesInEdit: noop,
  Shuffle: SHUFFLE_DEFAULT,
  shift: null,
  toggleHideLiveResas: noop,
  hideLiveResas: false,
  waitinglistEntries: [],
  handleWaitinglistAction: noop,
  addOnOrders: [],
  getAddOnOrders: () => [],
  tableInfo: {},
  hasWaitingList: false,
  hasPendingLargeGroupRequests: 0,
  showWarnings: false,
  setshowWarnings: () => {},
  customFields: [],
  tickets: {
    ref: null,
    data: [],
    loading: true,
    error: null,
  },
});

/*
    Everything needed for Reservations
*/

const usePendingLargeGroups = (restaurantId: string) => {
  const [waitinglistEntryDocs] = useCollection<WaitingListEntryDocument>(
    'waitinglistEntries',
    {
      filter: [
        ['restaurant', '==', restaurantId],
        ['date', '>=', dateHelper()],
      ],
    }
  );

  const pendingLargeGroups = useMemo(() => {
    return (
      waitinglistEntryDocs.data?.filter(
        (l) =>
          l.status === RequestStatus.PENDING ||
          l.status === RequestStatus.WAITING
      ).length || 0
    );
  }, [waitinglistEntryDocs]);

  return pendingLargeGroups;
};

const ReservationContextProvider = ({
  restaurantId,
  setrestaurantId,
  children,
}: Props) => {
  const [date, setdate] = useState(dateHelper());

  const [showWarnings, setshowWarnings] = useState<boolean>(false);

  useEffect(() => {
    if (showWarnings) setshowWarnings(false);
  }, [date]);

  const [customCancelationReasons, setcustomCancelationReasons] =
    useLocalStorageState<string[]>('customCancelationReasons', []);

  const [showTimesInEditMode, setShowTimesInEdit] = useLocalStorageState(
    'showTimesInEditMode',
    false
  );

  const Shuffle = useShuffle();

  const { floorPlans, occassions, settings, ...reservationSettings } =
    useInitializeReservations(restaurantId);

  const signature = !!reservationSettings.signatureRequired;

  const { uid, isAdmin, DateNow } = useContext(AuthContext);

  const { alert, newToast, products, experimentalFlags, apps } =
    useContext(RestaurantContext);

  const isLightReservation = products.includes('light-reservation');

  const [currentReservation, setcurrentReservation] = useState<null | string>(
    null
  );

  const {
    handleRelocate,
    editReservation = null,
    seteditReservation,
    handleNewReservation,
    handleSubmit,
  } = useEditReservation(
    restaurantId,
    uid,
    alert,
    date,
    setcurrentReservation,
    reservationSettings,
    isLightReservation
  );

  const [switchReservationState, setswitchReservationState] =
    useState<SwitchReservationModalProps | null>(null);

  const [tableStrs, setTableStrs] = useState<string[]>([]);

  const [noShowModal, setNoShowModal] = useState<null | NoShowModal>(null);

  const isMobile = useMediaQuery('(max-width: 450px)');

  (window as any).isMobile = isMobile;

  const dateToUse = isMobile ? date : editReservation?.date ?? date;

  const hasWaitingList = apps.includes('waitinglist');

  const {
    reservations,
    currentTime,
    reservationLoading,
    hideLiveResas,
    setHideLiveResas,
    addOnOrders,
    getAddOnOrders,
    tickets,
  } = useReservations(restaurantId, dateToUse, Shuffle?.state);

  // const regularTables = useRegularTables(restaurantId ?? '', date);

  // useEffect(() => {
  //   let missingReservations = regularTables.filter((x) => {
  //     return !reservations.find((r) => r.stammtischId === x.id);
  //   });

  //   console.log({
  //     missingReservations,
  //     regularTables,
  //   });
  // }, [regularTables, reservations]);

  const toast = useToast();

  const onReservationAction = (
    id: string,
    type: ReservationActionTypes,
    payload?: any
  ) => {
    // console.log("?", id, type);

    const ref = db.collection('requestsV03').doc(id);

    const updateInfo = {
      updatedBy: uid,
      updatedAt: DateNow(),
      updateNote: {
        note: '',
        updatedAt: DateNow(),
      },
    };

    let res = reservations.find((res) => res.id === id);

    if (!res) return;

    if (!!res.validTill) {
      toast('Diese Reservierung kann nicht bearbeitet werden.', Severity.ERROR);
      return;
    }

    switch (type) {
      case ReservationActionTypes.ATTR: {
        let attr = payload as string[];

        updateInfo.updateNote.note = 'Attribute wurden verändert';

        ref.update({ ...updateInfo, 'guest.attr': attr }).catch((err) => {
          // Trigger Error Toast
          newToast('error500', Severity.ERROR, 'errors');
        });

        return;
      }
      case ReservationActionTypes.CANCEL: {
        // console.log('CANCEL')

        // Are you sure?

        if (
          (res.minimumConsumption &&
            res.minimumConsumption.status !== 'failed' &&
            res.minimumConsumption.status !== 'missing_payments') ||
          res.ticketId
        ) {
          alert({
            title: 'Do you really want to cancel this Reservation?',
            titleTranslation: 'reservations',
            description:
              'This Reservation is linked to a payment that will not be canceled when you cancel this reservation!',
            descriptionTranslation: 'reservations',
            severity: 'warning',
            stopAwayClick: true,
            signature,
            onSubmit: (_, __, updatedBy = updateInfo.updatedBy) => {
              updateInfo.updateNote.note = 'Reservierung wurde storniert';

              ref
                .update({
                  ...updateInfo,
                  updatedBy,
                  status: 'failed',
                  tables: [],
                  tableStr: null,
                  sendCancelationEmail: FieldValue.delete(),
                })
                .then(() => {
                  // Trigger Success Toast
                  if (res?.guest?.email) {
                    alert({
                      title: 'Would you like to send a cancelation Email?',
                      titleTranslation: 'reservations',
                      description:
                        'The guest will receive an email that the reservation has been canceled',
                      descriptionTranslation: 'reservations',
                      inputFieldLabel: 'Cancelation Reason',
                      inputFieldLabelTranslation: 'reservations',
                      maxWidth: 'md',
                      fullWidth: true,
                      inputOptions: [
                        ...cancelationReasons,
                        ...customCancelationReasons.map((x) => ({
                          id: x,
                          label: x,
                        })),
                      ],
                      actions: [
                        {
                          id: 'no',
                          label: "No, don't send an email",
                          translation: 'reservations',
                          submit: false,
                        },
                        {
                          id: 'yes',
                          label: 'Yes, send an email',
                          translation: 'reservations',
                          submit: true,
                          variant: 'primary',
                        },
                      ],
                      onSubmit: (_, cancelationReason) => {
                        if (cancelationReason === 'other') {
                          cancelationReason =
                            window.prompt(
                              'Welcher Stornierungsgrund soll angegeben werden?'
                              // "Which reason for cancellation should be given?"
                            ) ?? '';

                          if (
                            window.confirm(
                              'Als Vorlage speichern? Save as a template?'
                            )
                          ) {
                            setcustomCancelationReasons((x) =>
                              Array.from(
                                new Set([...x, cancelationReason ?? ''])
                              )
                            );
                          }
                        }

                        updateInfo.updateNote.note =
                          'Stornierungsgrund/Cancelation reason: ' +
                            cancelationReason || '';
                        updateInfo.updatedAt = DateNow();

                        ref
                          .update({
                            ...updateInfo,
                            updatedBy,
                            cancelationReason: cancelationReason || null,
                            sendCancelationEmail: true,
                          })
                          .then(() => {
                            // Trigger Success Toast
                            newToast(
                              'An Email is on its way to the guest',
                              Severity.SUCCESS,
                              'reservations'
                            );
                          })
                          .catch((err) => {
                            // Trigger Error Toast
                            newToast('error500', Severity.ERROR, 'errors');
                          });
                      },
                    });
                  }

                  newToast(
                    'The Reservation has been canceled',
                    Severity.SUCCESS,
                    'reservations',
                    () => {
                      const updateInfo = {
                        updatedBy: uid,
                        updatedAt: DateNow(),
                        updateNote: {
                          note: 'Stornierung wurde rückgängig gemacht',
                          updatedAt: DateNow(),
                        },
                      };

                      ref.update({
                        ...updateInfo,
                        status: res?.status,
                        sendCancelationEmail: FieldValue.delete(),
                        cancelationReason: FieldValue.delete(),
                        tables: res?.tables,
                      });
                    }
                  );
                  if (payload) payload();
                })
                .catch((err) => {
                  // Trigger Error Toast
                  newToast('error500', Severity.ERROR, 'errors');
                });
            },
          });

          return;
        }

        alert({
          title: 'Do you really want to cancel this Reservation?',
          titleTranslation: 'reservations',
          description:
            'The Reservation will be canceled if you continue this operation',
          descriptionTranslation: 'reservations',
          stopAwayClick: true,
          signature,
          onSubmit: (_, __, updatedBy = updateInfo.updatedBy) => {
            updateInfo.updateNote.note = 'Reservierung wurde storniert';
            ref
              .update({
                ...updateInfo,
                updatedBy,
                status: 'failed',
                tables: [],
                tableStr: null,
                sendCancelationEmail: FieldValue.delete(),
              })
              .then(() => {
                // Trigger Success Toast
                if (res?.guest?.email) {
                  alert({
                    title: 'Would you like to send a cancelation Email?',
                    titleTranslation: 'reservations',
                    description:
                      'The guest will receive an email that the reservation has been canceled',
                    descriptionTranslation: 'reservations',
                    inputFieldLabel: 'Cancelation Reason',
                    inputFieldLabelTranslation: 'reservations',
                    maxWidth: 'md',
                    fullWidth: true,
                    inputOptions: [
                      ...cancelationReasons,
                      ...customCancelationReasons.map((x) => ({
                        id: x,
                        label: x,
                      })),
                    ],
                    actions: [
                      {
                        id: 'no',
                        label: "No, don't send an email",
                        translation: 'reservations',
                        submit: false,
                      },
                      {
                        id: 'yes',
                        label: 'Yes, send an email',
                        translation: 'reservations',
                        submit: true,
                        variant: 'primary',
                      },
                    ],
                    onSubmit: (_, cancelationReason) => {
                      if (cancelationReason === 'other') {
                        cancelationReason =
                          window.prompt(
                            'Welcher Stornierungsgrund soll angegeben werden?'
                          ) ?? '';
                        if (window.confirm('Als Vorlage speichern?')) {
                          setcustomCancelationReasons((x) =>
                            Array.from(new Set([...x, cancelationReason ?? '']))
                          );
                        }
                      }

                      updateInfo.updateNote.note =
                        'Stornierungsgrund: ' + cancelationReason || '';
                      updateInfo.updatedAt = DateNow();

                      ref
                        .update({
                          ...updateInfo,
                          updatedBy,
                          cancelationReason: cancelationReason || null,
                          sendCancelationEmail: true,
                        })
                        .then(() => {
                          // Trigger Success Toast
                          newToast(
                            'An Email is on its way to the guest',
                            Severity.SUCCESS,
                            'reservations'
                          );
                        })
                        .catch((err) => {
                          // Trigger Error Toast
                          newToast('error500', Severity.ERROR, 'errors');
                        });
                    },
                  });
                }

                newToast(
                  'The Reservation has been canceled',
                  Severity.SUCCESS,
                  'reservations',
                  () => {
                    const updateInfo = {
                      updatedBy: uid,
                      updatedAt: DateNow(),
                      updateNote: {
                        note: 'Stornierung wurde rückgängig gemacht',
                        updatedAt: DateNow(),
                      },
                    };

                    ref.update({
                      ...updateInfo,
                      status: res?.status,
                      sendCancelationEmail: FieldValue.delete(),
                      cancelationReason: FieldValue.delete(),
                      tables: res?.tables,
                    });
                  }
                );
                if (payload) payload();
              })
              .catch((err) => {
                // Trigger Error Toast
                newToast('error500', Severity.ERROR, 'errors');
              });
          },
        });

        return;
      }
      case ReservationActionTypes.EXCLUDE_FROM_SLOTS: {
        let excludeFromSlots = payload as boolean;

        updateInfo.updateNote.note = 'Von Reservierungsslots ausgenommen';

        ref.update({ ...updateInfo, excludeFromSlots }).catch((err) => {
          // Trigger Error Toast
          newToast('error500', Severity.ERROR, 'errors');
        });

        return;
      }
      case ReservationActionTypes.HOST_COMMENT: {
        let hostComment = payload as string;

        updateInfo.updateNote.note = `Servicekommentar von ${
          res?.guest?.hostComment || ''
        } zu ${hostComment}`;

        ref
          .update({ ...updateInfo, 'guest.hostComment': hostComment })
          .then(() => {
            newToast(
              'The Comment has been saved',
              Severity.SUCCESS,
              'reservations'
            );
          })
          .catch((err) => {
            // Trigger Error Toast
            newToast('error500', Severity.ERROR, 'errors');
          });

        return;
      }
      case ReservationActionTypes.COMMENT: {
        let comment = payload as string;

        updateInfo.updateNote.note = `Gastkommentar von ${
          res?.guest?.comment || ''
        } zu ${comment}`;

        ref
          .update({ ...updateInfo, 'guest.comment': comment })
          .then(() => {
            newToast(
              'The Comment has been saved',
              Severity.SUCCESS,
              'reservations'
            );
          })
          .catch((err) => {
            // Trigger Error Toast
            newToast('error500', Severity.ERROR, 'errors');
          });

        return;
      }
      case ReservationActionTypes.GUEST_COMMENT: {
        let { guestComment, relationshipId } = payload as {
          guestComment: string;
          relationshipId: string;
        };

        console.log({ payload });

        if (!relationshipId) return;

        if (!relationshipId.includes(`-${restaurantId}`)) {
          relationshipId = relationshipId + `-${restaurantId}`;
        }

        return db
          .collection(`relationships`)
          .doc(relationshipId)
          .update({ guestComment, hostComment: '' })
          .then(() => {
            console.log('====================================');
            console.log('success');
            console.log('====================================');
            newToast(
              'The Comment has been saved',
              Severity.SUCCESS,
              'reservations'
            );
          })
          .catch((err) => {
            // Trigger Error Toast
            console.log('====================================');
            console.log(err);
            console.log('====================================');
            newToast('error500', Severity.ERROR, 'errors');
          });
      }
      case ReservationActionTypes.MARK_AS_DONE: {
        let { reservationLength: oldReservationLength = 8, startTimeInBlocks } =
          res;

        let reservationLength = timeToBlock(timeHelper()) - startTimeInBlocks;

        updateInfo.updateNote.note = 'Reservierung als fertig markiert';

        if (
          !isLightReservation &&
          window?.localStorage?.getItem('hideMarkAsDoneAlert') !== 'true'
        ) {
          alert({
            title: 'Do you really want to mark this Reservation as done?',
            titleTranslation: 'reservations',
            description: 'The table might be shown as free for reservations',
            descriptionTranslation: 'reservations',
            secondaryAction: {
              label: 'Nicht nochmal anzeigen',
              labelTranslation: 'reservations',
              default: false,
            },
            onSubmit: (secAction) => {
              if (secAction && !!window?.localStorage) {
                window.localStorage.setItem('hideMarkAsDoneAlert', 'true');
              }

              ref
                .update({
                  ...updateInfo,
                  done: true,
                  'customData.customStatus': FieldValue.delete(),
                  reservationLength,
                  oldReservationLength,
                })
                .then(() => {
                  // Trigger Success Toast
                  newToast(
                    'The Reservation was successfully finished',
                    Severity.SUCCESS,
                    'reservations',
                    () =>
                      ref.update({
                        ...updateInfo,
                        reservationLength: res?.reservationLength,
                        done: FieldValue.delete(),
                        'customData.customStatus':
                          res?.customData?.customStatus ?? null,
                      })
                  );
                })
                .catch((err) => {
                  // Trigger Error Toast
                  newToast('error500', Severity.ERROR, 'errors');
                });
            },
          });
        } else {
          return ref
            .update({
              ...updateInfo,
              done: true,
              'customData.customStatus': FieldValue.delete(),
              reservationLength,
              oldReservationLength,
            })
            .then(() => {
              // Trigger Success Toast
              newToast(
                'The Reservation was successfully finished',
                Severity.SUCCESS,
                'reservations',

                () =>
                  ref.update({
                    ...updateInfo,
                    reservationLength: res?.reservationLength,
                    done: FieldValue.delete(),
                    'customData.customStatus':
                      res?.customData?.customStatus ?? null,
                  })
              );
            })
            .catch((err) => {
              // Trigger Error Toast
              newToast('error500', Severity.ERROR, 'errors');
            });
        }

        return;
      }
      case ReservationActionTypes.NO_SHOW: {
        alert({
          title: 'Do you really want to cancel this Reservation?',
          titleTranslation: 'reservations',
          description:
            'The Reservation will be canceled if you continue this operation',
          descriptionTranslation: 'reservations',
          secondaryAction: {
            label: 'Mark as No Show',
            labelTranslation: 'reservations',
            default: true,
          },
          stopAwayClick: true,
          signature,
          onSubmit: (noShow = false, _, updatedBy = updateInfo.updatedBy) => {
            updateInfo.updateNote.note = noShow
              ? 'Reservierung als NoShow markiert'
              : 'Reservierung als abgesagt markiert';
            ref
              .update({
                ...updateInfo,
                updatedBy,
                status: 'failed',
                tables: [],
                noShow,
                done: FieldValue.delete(),
                sendCancelationEmail: FieldValue.delete(),
              })
              .then(() => {
                // Trigger Success Toast

                if (res?.guest?.email) {
                  alert({
                    title: 'Would you like to send a cancelation Email?',
                    titleTranslation: 'reservations',
                    description:
                      'The guest will receive an email that the reservation has been canceled',
                    descriptionTranslation: 'reservations',
                    inputFieldLabel: 'Cancelation Reason',
                    inputFieldLabelTranslation: 'reservations',

                    inputOptions: [
                      ...cancelationReasons,
                      ...customCancelationReasons.map((x) => ({
                        id: x,
                        label: x,
                      })),
                    ],
                    maxWidth: 'md',
                    actions: [
                      {
                        id: 'no',
                        label: "No, don't send an email",
                        translation: 'reservations',
                        submit: false,
                      },
                      {
                        id: 'yes',
                        label: 'Yes, send an email',
                        translation: 'reservations',
                        submit: true,
                        variant: 'primary',
                      },
                    ],
                    onSubmit: (_, cancelationReason) => {
                      if (cancelationReason === 'other') {
                        cancelationReason =
                          window.prompt(
                            'Welcher Stornierungsgrund soll angegeben werden?'
                          ) ?? '';
                        if (window.confirm('Als Vorlage speichern?')) {
                          setcustomCancelationReasons((x) =>
                            Array.from(new Set([...x, cancelationReason ?? '']))
                          );
                        }
                      }

                      updateInfo.updateNote.note = `Stornierungsgrund: ${
                        cancelationReason || ''
                      }`;
                      updateInfo.updatedAt = DateNow();

                      ref
                        .update({
                          ...updateInfo,
                          updatedBy,
                          cancelationReason: cancelationReason || null,
                          sendCancelationEmail: true,
                        })
                        .then(() => {
                          // Trigger Success Toast
                          newToast(
                            'An Email is on its way to the guest',
                            Severity.SUCCESS,
                            'reservations'
                          );
                        })
                        .catch((err) => {
                          // Trigger Error Toast
                          newToast('error500', Severity.ERROR, 'errors');
                        });
                    },
                  });
                }

                newToast(
                  'The Reservation has been canceled',
                  Severity.SUCCESS,
                  'reservations',
                  () => {
                    const updateInfo = {
                      updatedBy: uid,
                      updatedAt: DateNow(),
                      updateNote: {
                        note: 'Stornierung wurde rückgängig gemacht',
                        updatedAt: DateNow(),
                      },
                    };

                    ref.update({
                      ...updateInfo,
                      updatedBy,
                      status: res?.status,
                      tables: res?.tables,
                      noShow: FieldValue.delete(),
                    });
                  }
                );
              })
              .catch((err) => {
                // Trigger Error Toast
                newToast('error500', Severity.ERROR, 'errors');
              });
          },
        });

        return;
      }
      case ReservationActionTypes.RESEAT: {
        let reservation = reservations.find((res) => res.id === id);

        if (!reservation) return;

        let reservationLength = reservation.oldReservationLength || 8;

        updateInfo.updateNote.note = 'Reservierung wurde wieder platziert';

        ref
          .update({
            ...updateInfo,
            done: FieldValue.delete(),
            reservationLength,
            sendCancelationEmail: FieldValue.delete(),
            status: 'success',
          })
          .then(() => {
            // Trigger Success Toast
            newToast(
              'The Reservation was succesfully reseated',
              Severity.SUCCESS,
              'reservations',
              () => {
                const updateInfo = {
                  updatedBy: uid,
                  updatedAt: DateNow(),
                  updateNote: {
                    note: 'Vorherige Änderung wurde Rückgängig gemacht',
                    updatedAt: DateNow(),
                  },
                };

                ref.update({
                  ...updateInfo,
                  reservationLength: res?.reservationLength,
                  status: res?.status,
                  done: true,
                });
              }
            );
          })
          .catch((err) => {
            // Trigger Error Toast
            newToast('error500', Severity.ERROR, 'errors');
          });

        return;
      }
      case ReservationActionTypes.UNSEAT: {
        updateInfo.updateNote.note = 'Reservierung wurde deplaziert';

        ref
          .update({
            ...updateInfo,
            partlySeated: FieldValue.delete(),
            started: FieldValue.delete(),
            startedAt: FieldValue.delete(),
          })
          .then(() => {
            // Trigger Success Toast
            newToast(
              'The Reservation was succesfully unseated',
              Severity.SUCCESS,
              'reservations',
              () => {
                const updateInfo = {
                  updatedBy: uid,
                  updatedAt: DateNow(),
                  updateNote: {
                    note: 'Vorherige Änderung wurde Rückgängig gemacht',
                    updatedAt: DateNow(),
                  },
                };

                ref.update({
                  ...updateInfo,
                  started: res?.started,
                  partlySeated: !!res?.partlySeated,
                });
              }
            );
          })
          .catch((err) => {
            // Trigger Error Toast
            newToast('error500', Severity.ERROR, 'errors');
          });

        return;
      }
      case ReservationActionTypes.SEAT: {
        // console.log('Test');

        if (dateHelper() < res.date) return;

        let started = timeToBlock(timeHelper(), 0, true);

        // console.log({ started });

        updateInfo.updateNote.note =
          'Reservierung wurde als angekommen markiert';

        ref
          .update({ ...updateInfo, started, startedAt: DateNow() })
          .then(() => {
            // Trigger Success Toast
            const updateInfo = {
              updatedBy: uid,
              updatedAt: DateNow(),
              updateNote: {
                note: 'Vorherige Änderung wurde Rückgängig gemacht',
                updatedAt: DateNow(),
              },
            };
            newToast(
              'The Reservation was succesfully seated',
              Severity.SUCCESS,
              'reservations',
              () => {
                ref.update({ ...updateInfo, started: FieldValue.delete() });
              }
            );
          })
          .catch((err) => {
            // Trigger Error Toast
            newToast('error500', Severity.ERROR, 'errors');
          });

        return;
      }
      case ReservationActionTypes.FULLY_SEAT: {
        updateInfo.updateNote.note = 'Reservierung wurde vollständig platziert';

        ref
          .update({
            ...updateInfo,
            partlySeated: FieldValue.delete(),
            startedAt: FieldValue.delete(),
          })
          .then(() => {
            // Trigger Success Toast
            newToast(
              'The Reservation was succesfully seated',
              Severity.SUCCESS,
              'reservations',
              () => {
                const updateInfo = {
                  updatedBy: uid,
                  updatedAt: DateNow(),
                  updateNote: {
                    note: 'Vorherige Änderung wurde Rückgängig gemacht',
                    updatedAt: DateNow(),
                  },
                };

                ref.update({ ...updateInfo, partlySeated: res?.started });
              }
            );
          })
          .catch((err) => {
            // Trigger Error Toast
            newToast('error500', Severity.ERROR, 'errors');
          });

        return;
      }
      case ReservationActionTypes.PARTLY_SEAT: {
        // console.log('Test');

        if (dateHelper() < res.date) return;

        let started = timeToBlock(timeHelper(), 0, true);

        // console.log({ started });

        updateInfo.updateNote.note =
          'Reservierung wurde als teilweise angekommen markiert';

        ref
          .update({
            ...updateInfo,
            started,
            partlySeated: true,
            startedAt: DateNow(),
          })
          .then(() => {
            // Trigger Success Toast
            const updateInfo = {
              updatedBy: uid,
              updatedAt: DateNow(),
              updateNote: {
                note: 'Vorherige Änderung wurde Rückgängig gemacht',
                updatedAt: DateNow(),
              },
            };
            newToast(
              'The Reservation was succesfully partly seated',
              Severity.SUCCESS,
              'reservations',
              () => {
                ref.update({
                  ...updateInfo,
                  started: FieldValue.delete(),
                  partlySeated: FieldValue.delete(),
                });
              }
            );
          })
          .catch((err) => {
            // Trigger Error Toast
            newToast('error500', Severity.ERROR, 'errors');
          });

        return;
      }
      case ReservationActionTypes.MARK_AS_LATE: {
        async function checkAvailability() {
          if (!res) return;

          const { data } = await server.post<{
            availableTimes: TimeSlot[];
          }>(
            `/v03/reservations/${restaurantId}/times/${res.date}/${res.occassion}`,
            {
              guests: res.guests,
              date: res.date,
              id: res.id,
              uid,
              resLength: null,
              type: 'WAITER',
            }
          );

          const timeSlot = data.availableTimes.find(
            (t) => t.block === timeToBlock(res?.time || '') + 1
          );

          let update = {};

          if (
            !timeSlot?.combinations?.length ||
            !timeSlot?.combinations?.find(
              (x) =>
                x.tables.sort().join(', ') === res?.tables.sort().join(', ')
            )
          ) {
            update = {
              time: blockToTime(timeToBlock(res?.time || '') + 1),
              startTimeInBlock: res.startTimeInBlocks + 1,
              endTimeInBlock: res.endTimeInBlocks + 1,
            };
          } else {
            const { reservationLength } = timeSlot.combinations[0];

            update = {
              time: timeSlot.time,
              startTimeInBlock: timeSlot.block,
              endTimeInBlock: timeSlot.block + reservationLength,
              reservationLength,
            };
          }

          updateInfo.updateNote.note =
            'Reservierung wurde 15 Minuten nach hinten verschoben';

          ref
            .update({
              ...updateInfo,
              ...update,
            })
            .then(() => {
              // Trigger Success Toast
              newToast(
                'The Reservation was succesfully marked as late',
                Severity.SUCCESS,
                'reservations',
                () => {
                  const updateInfo = {
                    updatedBy: uid,
                    updatedAt: DateNow(),
                    updateNote: {
                      note: 'Vorherige Änderung wurde Rückgängig gemacht',
                      updatedAt: DateNow(),
                      time: res?.time,
                      startTimeInBlock: res?.startTimeInBlocks,
                      endTimeInBlock: res?.endTimeInBlocks,
                      reservationLength: res?.reservationLength,
                      tables: res?.tables,
                      space: res?.space,
                    },
                  };

                  ref.update({ ...updateInfo, partlySeated: res?.started });
                }
              );
            })
            .catch((err) => {
              // Trigger Error Toast
              newToast('error500', Severity.ERROR, 'errors');
            });
        }

        return checkAvailability();
      }
      case ReservationActionTypes.CHANGE_TABLE: {
        let { tables, space } = payload as { tables: string[]; space: string };
        updateInfo.updateNote.note = res.tables
          ? `Tisch von ${res.tables?.join(', ') ?? ''} zu ${
              tables?.join(', ') ?? ''
            }`
          : `Reservierung wurden Tische zugewiesen: ${
              tables?.join(', ') ?? ''
            }`;
        const updateFullInfo: {
          space: string;
          updatedBy: string | null;
          updatedAt: number;
          tableStr?: string;
          tables?: string[];
        } = { ...updateInfo, space };

        if (isLightReservation) {
          updateFullInfo.tableStr = tables.join(',');
          setStorageTableStrs(updateFullInfo.tableStr);
        } else {
          updateFullInfo.tables = tables;
        }

        ref
          .update({ ...updateFullInfo })
          .then(() => {
            // Trigger Success Toast
            newToast(
              'The Reservation was succesfully relocated',
              Severity.SUCCESS,
              'reservations',
              () =>
                ref.update({
                  ...updateInfo,
                  tables: res?.tables,
                  space: res?.space,
                })
            );
          })
          .catch((err) => {
            // Trigger Error Toast
            newToast('error500', Severity.ERROR, 'errors');
          });

        return;
      }
      case ReservationActionTypes.CONFIRM: {
        updateInfo.updateNote.note = 'Reservierung wurde bestätigt';

        ref
          .update({ ...updateInfo, status: 'success' })
          .then(() => {
            // Trigger Success Toast
            newToast(
              'The Reservation was succesfully confirmed',
              Severity.SUCCESS,
              'reservations',
              () => {
                updateInfo.updateNote.note = 'Bestätigung wurde zurückgesetzt';
                updateInfo.updatedAt = DateNow();
                ref.update({ ...updateInfo, status: res?.status });
              }
            );
          })
          .catch((err) => {
            // Trigger Error Toast
            newToast('error500', Severity.ERROR, 'errors');
          });

        return;
      }
      case ReservationActionTypes.REFUSE: {
        alert({
          title: 'Do you really want to cancel this Reservation?',
          titleTranslation: 'reservations',
          description:
            'The Reservation will be canceled if you continue this operation',
          descriptionTranslation: 'reservations',
          stopAwayClick: true,
          signature,
          onSubmit: (_, __, updatedBy = updateInfo.updatedBy) => {
            updateInfo.updateNote.note = 'Reservierung wurde abgelehnt';
            ref
              .update({
                ...updateInfo,
                updatedBy,
                status: 'failed',
                tables: [],
              })

              .then(() => {
                // Trigger Success Toast
                newToast(
                  'The Reservation has been canceled',
                  Severity.SUCCESS,
                  'reservations',
                  () => {
                    updateInfo.updateNote.note =
                      'Bestätigung wurde zurückgesetzt';
                    updateInfo.updatedAt = DateNow();
                    ref.update({
                      ...updateInfo,
                      updatedBy,
                      status: res?.status,
                      tables: res?.tables,
                    });
                  }
                );
              })
              .catch((err) => {
                // Trigger Error Toast
                newToast('error500', Severity.ERROR, 'errors');
              });
          },
        });

        return;
      }
      case ReservationActionTypes.NO_SHOW_FEE: {
        if (!isAdmin || !uid) {
          toast(
            'Nur ein Nutzer mit Admin Rechten kann eine No Show Gebühr einfordern. ',
            Severity.ERROR
          );

          return;
        }

        if (!!res.noShowFee) {
          toast('Es wurde schon eine No-Show Gebühr erhoben', Severity.ERROR);

          return;
        }

        setNoShowModal({
          amount: 0,
          description: '',
          statement_descriptor: '',
          reservationId: id,
          uid,
        });

        return;
      }
      case ReservationActionTypes.TOGGLE_PIN: {
        updateInfo.updateNote.note = !!res?.fixed
          ? 'Fixierung wurde aufgehoben'
          : 'Reservierung wurde fixiert';
        ref
          .update({ ...updateInfo, fixed: !res.fixed })
          .then(() => {
            // Trigger Success Toast
            newToast(
              !res?.fixed
                ? 'The Reservation was succesfully pinned'
                : 'The Reservation was succesfully unpinned',
              Severity.SUCCESS,
              'reservations',
              () => {
                updateInfo.updateNote.note = !!res?.fixed
                  ? 'Fixierung wurde aufgehoben'
                  : 'Reservierung wurde fixiert';
                updateInfo.updatedAt = DateNow();
                ref.update({ ...updateInfo, fixed: !!res?.fixed });
              }
            );
          })
          .catch((err) => {
            // Trigger Error Toast
            newToast('error500', Severity.ERROR, 'errors');
          });

        return;
      }
      case ReservationActionTypes.CANCEL_WARNING: {
        const text = payload as string;

        let res = reservations.find((res) => res.id === id);

        if (!res) return;

        updateInfo.updateNote.note = 'Warnung wurde entfernt';

        if (FIXED_CANCELABLE_WARNINGS.includes(text)) {
          ref
            .update({ ...updateInfo, ['ignoreWarnings.' + text]: true })
            .then(() => {
              newToast(
                'The Warning has been removed',
                Severity.SUCCESS,
                'reservations'
              );
            })
            .catch((err) => {
              // Trigger Error Toast
              newToast('error500', Severity.ERROR, 'errors');
            });

          break;
        }

        const warnings =
          res?.warnings?.filter((w) =>
            typeof w === 'string' ? w !== text : w.message !== text
          ) ?? [];

        ref
          .update({ ...updateInfo, warnings })
          .then(() => {
            newToast(
              'The Warning has been removed',
              Severity.SUCCESS,
              'reservations'
            );
          })
          .catch((err) => {
            // Trigger Error Toast
            newToast('error500', Severity.ERROR, 'errors');
          });

        break;
      }
      case ReservationActionTypes.LATE_BY: {
        let lateBy = payload as string;

        updateInfo.updateNote.note = `Verspätung von ${
          res?.customData?.['Verspätet um'] || ''
        } zu ${lateBy}`;

        ref
          .update({ ...updateInfo, 'customData.Verspätet um': lateBy })
          .then(() => {
            newToast(
              'The Comment has been saved',
              Severity.SUCCESS,
              'reservations'
            );
          })
          .catch((err) => {
            // Trigger Error Toast
            newToast('error500', Severity.ERROR, 'errors');
          });

        return;
      }
      case ReservationActionTypes.CUSTOM_STATUS: {
        let customStatus = payload as string;

        if (customStatus === 'done') {
          onReservationAction(id, ReservationActionTypes.MARK_AS_DONE);

          return;
        }

        updateInfo.updateNote.note = `Status von ${
          res?.customData?.customStatus || res.currentStatus
        } zu ${customStatus}`;

        ref
          .update({ ...updateInfo, 'customData.customStatus': customStatus })
          .then(() => {
            newToast(
              'The Status has been changed',
              Severity.SUCCESS,
              'reservations'
            );
          })
          .catch((err) => {
            // Trigger Error Toast
            newToast('error500', Severity.ERROR, 'errors');
          });

        return;
      }
      default:
        break;
    }
  };

  const [currentTable, setcurrentTable] = useState<null | string>(null);

  const [currentWaitinglistEntry, setcurrentWaitinglistEntry] = useState<
    null | string
  >(null);

  const { waitinglistEntries, handleWaitinglistAction } = useWaitinglist(
    restaurantId,
    date,
    seteditReservation
  );

  const hasPendingLargeGroupRequests = usePendingLargeGroups(
    restaurantId || ''
  );

  const {
    shifts,
    currentShift,
    setCurrentShift,
    endOfShift,
    ...floorPlanProps
  } = useFloorPlan(
    restaurantId,
    date,
    editReservation,
    floorPlans,
    isLightReservation
  );

  const handleSwitchReservations = (switchWith: Reservation) => {
    let tablesOfEditedResa = editReservation?.tables;

    if (!tablesOfEditedResa) return;

    let tables = switchWith.tables;

    db.collection(`requestsV03`)
      .doc(switchWith.id)
      .update({
        tables: tablesOfEditedResa,
        updatedAt: DateNow(),
        updatedBy: uid,
        updateNote: {
          updatedAt: DateNow(),
          note: 'Reservierung wurde getauscht',
        },
      });

    seteditReservation((res) =>
      !!res
        ? {
            ...res,
            tables,
            space: switchWith.space ?? '',
          }
        : null
    );
  };

  const handleTableClick = (
    id: string,
    name: string,
    space: string,
    available = true,
    switchResa?: string | 'blocked'
  ) => {
    if (!editReservation) {
      setcurrentTable((oV) => (oV === id ? null : id));
      setcurrentReservation(null);
    } else {
      let fn = !editReservation.tables?.includes(name)
        ? () => {
            seteditReservation((oV) => {
              if (!oV) return null;

              let tables = Array.from(oV.tables || []);

              let index = tables.indexOf(name);

              let changedTablePerHandBefore = !!oV.changedTablePerHandBefore;

              // console.log({tables, index});

              if (editReservation.lastTable?.includes(name)) {
                tables.push(name);
                changedTablePerHandBefore = true;
              } else if (index > -1) {
                tables.splice(index, 1);
                changedTablePerHandBefore = false;
              } else if (
                experimentalFlags?.data?.allowAllTableCombinations &&
                !!changedTablePerHandBefore
              ) {
                tables.push(name);
                changedTablePerHandBefore = true;
              } else if (
                oV.space !== space ||
                experimentalFlags?.data?.allowMultipleSpaces ||
                oV.timeSlot?.combinations?.find((c) => {
                  let combi = JSON.stringify(c.tables);

                  return (
                    combi === JSON.stringify(tables) ||
                    combi === JSON.stringify([name])
                  );
                })
              ) {
                tables = [name];
                changedTablePerHandBefore = true;
              } else {
                tables.push(name);
                changedTablePerHandBefore = true;
              }

              return {
                ...oV,
                space,
                tables,
                lastTable: oV.tables,
                changedTablePerHandBefore,
              };
            });
          }
        : () =>
            seteditReservation((oV) => {
              if (!oV) return null;

              let tables = Array.from(oV.tables || []);

              let index = tables.indexOf(name);

              // console.log({tables, index});
              let clickCount = oV.clickCount ?? 0;

              if (index > -1) {
                tables.splice(index, 1);
              } else if (
                !experimentalFlags?.data?.allowAllTableCombinations &&
                ((oV.space !== space &&
                  !experimentalFlags?.data?.allowMultipleSpaces) ||
                  oV.timeSlot?.combinations?.find((c) => {
                    let combi = JSON.stringify(c.tables);

                    return combi === JSON.stringify([name]);
                  }) ||
                  !clickCount)
              ) {
                clickCount = 1;
                tables = [name];
              } else {
                clickCount = 0;
                tables.push(name);
              }

              return {
                ...oV,
                space,
                tables,
                clickCount,
                lastTable: oV.tables,
              };
            });

      if (!!switchResa && switchResa !== 'blocked') {
        let switchWith = reservations.find((r) => r.id === switchResa);

        if (!!switchWith) {
          setswitchReservationState({
            reservations: [editReservation, switchWith] as Reservation[],
            onClose: () => setswitchReservationState(null),
            onSubmit: () => handleSwitchReservations(switchWith as Reservation),
            onSecondaryOption: fn,
          });

          return;
        }
      }

      if (!available && !editReservation.tables?.includes(name)) {
        if (!(editReservation.time ?? editReservation.timeSlot?.time)) {
          alert({
            title: 'Zuerst eine Uhrzeit auswählen',
            titleTranslation: 'reservations',
            description: 'Are you sure you want to continue?',
            descriptionTranslation: 'reservations',
            maxWidth: 'xs',
            onSubmit: fn,
          });
        } else {
          if (experimentalFlags?.data?.disableRelocateMessage) {
            fn();
          } else {
            alert({
              title: 'This table is blocked',
              titleTranslation: 'reservations',
              description: 'Are you sure you want to continue?',
              descriptionTranslation: 'reservations',
              maxWidth: 'xs',
              onSubmit: fn,
            });
          }
        }
      } else {
        fn();
      }
    }
  };

  const handleAction = (
    id: string,
    type: ReservationActionTypes,
    payload?: any
  ) => {
    if (type === ReservationActionTypes.RELOCATE) {
      let res = reservations?.find((r) => r.id === id) || null;

      if (!res) {
        // Trigger Error Toast
        newToast('Could not find Reservation', Severity.ERROR, 'reservations');
        return;
      }

      let tables = payload?.tables ?? res.tables;

      handleRelocate({ ...res, tables }, !!payload?.tables || res.fixed);
    } else {
      onReservationAction(id, type, payload);
    }
  };

  const filteredReservations = useMemo(() => {
    let { startStop } = stitchShifts(shifts || [], currentShift);

    let [start, close] = startStop;

    return reservations
      .map((r) => {
        if (r.space) {
          return r;
        } else {
          return {
            ...r,
            space:
              floorPlanProps.floorPlan?.tables.find(
                (t) => t.name === r.tables[0]
              )?.space ?? null,
          };
        }
      })
      .filter(
        (res) =>
          !res.blockedFullShift &&
          // (!res.stammtischId ||
          //   regularTables.find((x) => x.id === res.stammtischId && x.active)) &&
          (!currentShift ||
            ((start === null ||
              (res.startTimeInBlocks ?? timeToBlock(res.time)) >= start) &&
              (close === null ||
                (res.startTimeInBlocks ?? timeToBlock(res.time)) <= close)))
      );
  }, [shifts, currentShift, reservations]);

  const customFields = useMemo(() => {
    const customFields: string[] = [];
    for (const res of filteredReservations) {
      for (const field in res.customData) {
        if (!customFields.includes(field)) {
          customFields.push(field);
        }
      }
    }

    return customFields;
  }, [reservations]);

  const warnings = useMemo(
    () =>
      reservations
        .filter(
          (r) =>
            !isLightReservation &&
            r.status !== ReservationStatus.FAILED &&
            !r.done
        )
        .reduce((acc: Warning[], cV, i, arr) => {
          let matches = arr
            .map((r, i2) => {
              if (
                i2 === i ||
                r.status === ReservationStatus.FAILED ||
                cV.status === ReservationStatus.FAILED
              )
                return false;

              let tables =
                r.tables?.filter((t) => cV?.tables?.includes(t)) ?? [];

              if (!tables.length) return false;

              // console.log(tables, r.guest.name);

              let a1 = r.startTimeInBlocks,
                a2 = r.endTimeInBlocks,
                b1 = cV.startTimeInBlocks,
                b2 = cV.endTimeInBlocks;

              if (a1 === b1) {
                return {
                  id: r.id,
                  text: 'Two Reservations at the same time',
                  type: 'reservation',
                  cancelable: false,
                  tables,
                };
              }

              if (a2 > b1 && a1 < b1) {
                return {
                  id: r.id,
                  text: 'Reservation is blocked at the end',
                  type: 'reservation',
                  tables,
                };
              } else if (b2 > a1 && b1 < a1) {
                return {
                  id: r.id,
                  text: 'Reservation is blocked at the start',
                  type: 'reservation',
                  tables,
                };
              }

              return false;
            })
            .filter((o) => !!o);

          if (
            !cV?.tables?.length &&
            cV.status !== ReservationStatus.FAILED &&
            !(cV.event?.active && cV.event?.ignoreTables) &&
            !cV.ignoreWarnings?.['No Tables']
          ) {
            matches.push({
              id: cV.id,
              text: 'No Tables',
              type: 'reservation',
              tables: [],
            });
          }

          if (
            !cV.fixed &&
            cV.tables?.length === 1 &&
            !experimentalFlags?.data?.hideTableSizeWarning
          ) {
            const table = floorPlanProps.floorPlan?.tables.find(
              (t) => t.name === cV.tables[0]
            );

            if (
              table &&
              table.min > cV.guests &&
              !cV.ignoreWarnings?.['Table is too large']
            ) {
              matches.push({
                id: cV.id,
                text: 'Table is too large',
                type: 'reservation',
                tables: cV.tables,
              });
            } else if (
              table &&
              table.max < cV.guests &&
              !cV.ignoreWarnings?.['Table is too small']
            ) {
              matches.push({
                id: cV.id,
                text: 'Table is too small',
                type: 'reservation',
                tables: cV.tables,
              });
            }
          }

          if (
            cV?.guest?.attr?.includes('blacklist') &&
            !cV.ignoreWarnings?.['Blacklisted Guest']
          ) {
            matches.push({
              id: cV.id,
              text: 'Blacklisted Guest',
              type: 'reservation',
              tables: cV.tables,
            });
          }

          for (const warning of cV?.warnings ?? []) {
            if (typeof warning === 'string') {
              matches.push({
                id: cV.id,
                text: warning,
                cancelable: true,
                type: 'reservation',
                tables: cV.tables,
              });
            } else {
              matches.push({
                id: cV.id,
                cancelable: true,
                text: warning.message,
                type: 'reservation',
                tables: cV.tables,
              });
            }
          }

          return [...acc, ...(matches as Warning[])];
        }, []),
    [reservations, isLightReservation, experimentalFlags?.data]
  );

  const [{ data: shiftComments }] = useShiftComment<ShiftComment>(
    date,
    restaurantId
  );

  function getStorageTableStrs(): string[] {
    const tableStrs = localStorage.getItem('tableStrOptions');
    // console.log(JSON.parse(tableStrs as string));
    if (tableStrs)
      setTableStrs((JSON.parse(tableStrs) as unknown as string[]) ?? []);
    return JSON.parse(tableStrs as string);
  }

  function setStorageTableStrs(table: string) {
    let allTables = getStorageTableStrs() ?? [];
    let newTables = JSON.stringify([...new Set(allTables), table]);
    localStorage.setItem('tableStrOptions', newTables);
    setTableStrs((prev) => [...new Set([...prev, table])]);
  }

  useEffect(() => {
    getStorageTableStrs();
  }, []);

  const shift = useMemo(() => {
    return currentShift
      ? shifts?.find((s) => s.id === currentShift) ?? null
      : null;
  }, [currentShift, shifts]);

  useEffect(() => {
    // console.log({ currentTime });
    if (currentTime) {
      let currentBlock = timeToBlock(currentTime);

      // console.log(shift, currentBlock, shift?.start, shift?.close, {
      //   currentTime,
      //   hideLiveResas,
      // });

      if (shift && currentBlock < shift.start) {
        if (!hideLiveResas) setHideLiveResas(true);
      } else {
        if (hideLiveResas === true) setHideLiveResas(undefined);
      }
    }
  }, [shift, currentTime]);

  const tableInfo = useTableInfo(restaurantId, date);

  return (
    <ReservationContext.Provider
      value={{
        date,
        setdate,
        editReservation,
        seteditReservation,
        reservations,
        currentTable,
        setcurrentTable,
        currentReservation,
        setcurrentReservation,
        currentShift,
        setCurrentShift,
        handleTableClick,
        handleAction,
        handleNewReservation,
        handleSubmit,
        handleRelocate,
        floorPlanProps,
        shifts,
        endOfShift,
        currentTime,
        onReservationAction,
        filteredReservations,
        settings,
        occassions,
        reservationLoading,
        warnings,
        switchReservationState,
        setswitchReservationState,
        shiftComments,
        reservationSettings,
        tableStrs,
        getStorageTableStrs,
        setStorageTableStrs,
        Shuffle,
        showTimesInEditMode,
        setShowTimesInEdit,
        shift,
        hideLiveResas: !!shift && !!hideLiveResas,
        toggleHideLiveResas: () => setHideLiveResas((x) => !x),
        waitinglistEntries,
        handleWaitinglistAction,
        currentWaitinglistEntry,
        setcurrentWaitinglistEntry,
        addOnOrders,
        getAddOnOrders,
        tableInfo,
        hasWaitingList,
        hasPendingLargeGroupRequests,
        showWarnings,
        setshowWarnings,
        customFields,
        tickets,
      }}
    >
      <NoShowModal
        noShowModal={noShowModal}
        setNoShowModal={setNoShowModal}
        restaurantId={restaurantId}
      />
      {children}
    </ReservationContext.Provider>
  );
};

export default ReservationContextProvider;

export const useReservationContext = () => useContext(ReservationContext);
