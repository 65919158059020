import Tooltip from "Components/Atoms/Tooltip";
import Typography from "Components/Atoms/Typography";
import React from "react";
import { ReservationSnippet } from "utils/types";
import ReservationTooltip from "./ReservationTooltip";

type GroupProps = {
  res: ReservationSnippet;
};

const getColor = (guests: number) => {
  if (guests < 3) {
    return "var(--color-primary)";
  } else if (guests < 5) {
    return "var(--color-primary-1)";
  } else if (guests < 7) {
    return "var(--color-primary-2)";
  } else if (guests < 9) {
    return "var(--color-primary-3)";
  } else {
    return "var(--color-primary-4)";
  }
};

const Group = ({ res }: GroupProps) => {
  return (
    <Tooltip
      content={<ReservationTooltip res={res} />}
      className="group"
      style={{ background: getColor(res.guests) }}
    >
      <Typography variant="text-3" style={{ color: "#ffffff" }}>
        {res.guests}
      </Typography>
    </Tooltip>
  );
};

export default Group;
