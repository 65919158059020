import { useEffect, useState } from "react";

function subscribe<TDetail = any>(
  eventName: string,
  listener: (e: Event & { detail?: TDetail }) => void | Promise<void>
) {
  document.addEventListener(eventName, listener);
}

function unsubscribe<TDetail = any>(
  eventName: string,
  listener: (e: Event & { detail?: TDetail }) => void | Promise<void>
) {
  document.removeEventListener(eventName, listener);
}

function triggerCustomEvent<TDetail = any>(eventName: string, data?: TDetail) {
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
}

export { triggerCustomEvent, subscribe, unsubscribe };

export default function useCustomEvent<TDetail = any>(
  eventName: string,
  listener: (e: Event & { detail?: TDetail }) => void | Promise<void>
) {
  useEffect(() => {
    subscribe(eventName, listener);

    return () => {
      unsubscribe(eventName, listener);
    };
  }, [eventName, listener]);
}

export function useCustomTrigger(eventName: string) {
  const [trigger, settrigger] = useState(0);

  useEffect(() => {
    function listener() {
      settrigger((t) => t + 1);
    }

    subscribe(eventName, listener);

    return () => {
      unsubscribe(eventName, listener);
    };
  }, [eventName]);
}
