import React, { useState } from "react";
import { Settings, ArrowDropDown, ArrowDropUp } from "../../Atoms/Icons";
import IconButton, { IconButtonType } from "../../Atoms/IconButton";
import { textAlignToPlacement } from "../../../utils/helper";
import Popover, { PopoverProps } from "../../Atoms/Popover";
import "./styles.scss";
import { Avatar, AvatarProps } from "@material-ui/core";
import { Modify } from "utils/types";
import { Colors } from "Components/Atoms";

export type IconDropdownType = Modify<
  IconButtonType,
  {
    avatar?: string | null | boolean;
    size?: "small" | "medium" | "large";
    avatarProps?: AvatarProps;
    icon?: null | React.ReactNode;
    textAlign?: "left" | "right";
    children?: React.ReactNode;
    boxProps?: any;
    popoverProps?: PopoverProps;
    noArrow?: boolean;
    color?: Colors;
    width?: string;
    padding?: string;
    top?: string;
    left?: string;
    right?: string;
    name?: string;
  }
>;

const IconDropdown = ({
  avatar = "",
  icon = null,
  textAlign = "left",
  boxProps = {},
  avatarProps = {},
  popoverProps = {},
  children,
  noArrow = false,
  size = "medium",
  color,
  width = "100px",
  padding,
  top,
  left,
  right,
  name = "Gastronaut",
}: IconDropdownType) => {
  const [open, setopen] = useState<null | HTMLElement | EventTarget>(null);

  const handleOpen = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setopen((o) => (!o ? e.currentTarget : null));
  };

  const handleClose = () => setopen(null);

  return (
    <>
      <div className="settings-dropdown" onClick={handleOpen} {...boxProps}>
        {!avatar && icon && (
          <IconButton size={size} color={color} aria-label="Dropdown">
            {icon}
          </IconButton>
        )}
        {!icon && (
          <Avatar
            {...avatarProps}
            src={
              typeof avatar === "string"
                ? avatar
                : "https://ui-avatars.com/api/?size=128&bold=true&background=10ccb9&color=ffffff&name=" +
                  name.split(" ").join("+").replace(/\(|\)/gm, "")
            }
          />
        )}
        {!noArrow && (
          <>
            {!open ? (
              <ArrowDropDown
                color="inherit"
                fontSize="small"
                style={{ marginLeft: !!avatar ? 0 : -8 }}
              />
            ) : (
              <ArrowDropUp
                color="inherit"
                fontSize="small"
                style={{ marginLeft: !!avatar ? 0 : -8 }}
              />
            )}
          </>
        )}
      </div>
      <Popover
        style={{
          width: width,
          top: top ? top : "0px",
          padding: padding ? padding : "0px",
          left: left ? left : "0px",
          right: right ? right : "0px",
        }}
        {...popoverProps}
        anchorEl={open}
        open={!!open}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onClose={handleClose}
        placement={textAlignToPlacement(textAlign)}
      >
        {children}
      </Popover>
    </>
  );
};

export default IconDropdown;
