import { ChangeLog } from 'api/Changelog/types';
import useChangelog from 'CustomHooks/useChangelog';
import useCollection from 'CustomHooks/useCollection';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { db } from 'utils/firebase';
import { asyncVoid, noop } from '../utils/helper';
import { AuthContext } from './AuthContext';

type ChangelogContextType = {
  allShowingChangelogs: ChangeLog[];
  changelogLoading: boolean;
  showChangelog: boolean;
  setshowChangelog: React.Dispatch<React.SetStateAction<boolean>>;
  updateProfilesLatestVersion: (id: string) => void;
};

interface ContextProps {
  restaurantId: null | string;
  children?: React.ReactNode;
  storyBook?: boolean;
  currentVersion?: string;
}

export const ChangelogContext = createContext<ChangelogContextType>({
  allShowingChangelogs: [],
  changelogLoading: false,
  showChangelog: false,
  setshowChangelog: noop,
  updateProfilesLatestVersion: asyncVoid,
});

const ChangelogContextProvider = ({
  restaurantId,
  children,
  storyBook,
  currentVersion,
}: ContextProps) => {
  const { changelog, loading } = useChangelog();
  const { user } = useContext(AuthContext);

  const [showChangelog, setshowChangelog] = useState<boolean>(false);

  const changeLogs = useMemo(() => {
    return changelog.map((c) => c.fields);
  }, [changelog]);

  const showingChangelog = useMemo(() => {
    if (!user.data) return [];

    let userVersionTuple = user.data?.appVersion?.split('.') ?? null;

    const VERSION = JSON.stringify(require('../../package.json').version);

    let currentVersion = '0.0.0';
    try {
      currentVersion = VERSION.slice(1, VERSION.length - 1);
    } catch (error) {
      console.log('Cannot get version of application.');
    }

    const currentVersionTuple = currentVersion.split('.') ?? null;

    return changeLogs.filter((v) => {
      let version = v?.id.split('.') ?? ['0', '0', '0'];

      console.log({ userVersionTuple, version, currentVersionTuple });

      if (
        currentVersionTuple &&
        (parseInt(version[0]) > parseInt(currentVersionTuple[0]) ||
          parseInt(version[1]) > parseInt(currentVersionTuple[1]) ||
          parseInt(version[2]) > parseInt(currentVersionTuple[2]))
      ) {
        return false;
      }

      if (
        !userVersionTuple ||
        (parseInt(version[0]) >= parseInt(userVersionTuple[0]) &&
          parseInt(version[1]) >= parseInt(userVersionTuple[1]) &&
          parseInt(version[2]) >= parseInt(userVersionTuple[2]))
      ) {
        return v;
      } else return false;
    });
  }, [changeLogs, user]);

  async function updateProfilesLatestVersion(id: string) {
    try {
      await db
        .collection('users')
        .doc(id)
        .update({ appVersion: currentVersion });
      setshowChangelog(false);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    let x = currentVersion?.split('.') ?? ['0', '0', '0'];
    let y = user?.data?.appVersion?.split('.') ?? null;
    if (!y || (x[0] >= y[0] && x[1] >= y[1] && x[2] > y[2]))
      setshowChangelog(true);
  }, [user]);

  // console.log({ changelog });
  // console.log({ showChangelog });

  return (
    <ChangelogContext.Provider
      value={{
        allShowingChangelogs: showingChangelog,
        showChangelog: showChangelog && !!showingChangelog?.length,
        setshowChangelog,
        changelogLoading: loading,
        updateProfilesLatestVersion,
      }}
    >
      {children}
    </ChangelogContext.Provider>
  );
};

export default ChangelogContextProvider;
