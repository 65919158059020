import React, { useCallback, useMemo, useState } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import useRestaurant from 'CustomHooks/useRestaurant';
import LeftNavigation from 'App/Settings/Components/LeftNavigation';
import SettingsHeader from 'App/Settings/Components/SettingsHeader';
import useCollection from 'CustomHooks/useCollection';
import Modal, { Action } from 'Components/Molecules/Modal';
import Button from 'Components/Atoms/Button';
import { copyToClipboard, toEuropeanDate } from 'utils/helper';
import Select from 'Components/Atoms/Select';
import TextField from 'Components/Atoms/TextField';
import SwitchBox from 'Components/Atoms/Switch';
import _ from 'lodash';
import MenuItem from 'Components/Atoms/MenuItem';
import ImageField from '../ImageField';

export type LinkGeneratorProps = {
  open: boolean;
  onClose: () => void;
};

export type LinkTemplate = {
  id?: string;
  name?: string;
  header?: string;
  date?: string;
  time?: string;
  guests?: number;
  minGuests?: number;
  occassion?: string;
  hideOccassion?: 'TRUE' | 'FALSE';
  coupon_code?: string;

  //potentially in future
  type?: 'default' | 'v02';
  imageUrl?: string;
  primaryColor?: string;
  onPrimaryColor?: string;
  source?: string;
};

const hideUrl = (url: string) => {
  console.log(url);

  return url
    .replace(
      'https://firebasestorage.googleapis.com/v0/b/schillingroofbarhd.appspot.com/o/restaurants%2F',
      ''
    )
    .replace(`?`, '__Q__')
    .replace(/&/gm, '__U__');
};

const LinkGenerator: React.FC<LinkGeneratorProps> = ({ open, onClose }) => {
  const { reservationSettings, restaurantId } = useRestaurant();

  const [templates] = useCollection<LinkTemplate & { id: string }>(
    `/restaurants/${restaurantId}/linkTemplates`
  );

  const [current, setcurrent] = useState<LinkTemplate>({});

  const [loading, setloading] = useState(false);

  const handleClose = () => {
    if (loading) return;

    setloading(true);

    let templateWithChanges =
      current.id &&
      _.isEqualWith(
        current,
        templates.data.find((t) => t.id === current.id) ?? {}
      );

    let changes = Object.values(current).length;

    if (templateWithChanges) {
      if (confirm('Änderung an Vorlage speichern?')) {
        let { id, ...changes } = current;
        if (confirm('Als neue Vorlage speichern?')) {
          templates.ref.add(changes);
        } else {
          let { id, ...changes } = current;
          templates.ref.doc(id).set(changes);
        }
      }
    } else if (changes) {
      if (confirm('Änderung als Vorlage speichern?')) {
        templates.ref.add(current);
      }
    }

    setloading(false);

    setcurrent({});

    onClose();
  };

  const ACTIONS: Action[] = [
    {
      id: 'cancel',
      label: 'Close',
      onClick: () => {},
    },
  ];

  const url = useMemo(() => {
    const root = `https://gastronaut.ai/reservations/${restaurantId}`;

    const query = Object.entries(current).reduce(
      (acc, cV) =>
        cV[1] && cV[0] !== 'name' && cV[0] !== 'id'
          ? [
              ...acc,
              cV[0] === 'imageUrl'
                ? `${cV[0]}=${hideUrl(cV[1] as any)}`
                : `${cV[0]}=${cV[1]}`,
            ]
          : acc,
      [] as string[]
    );

    const url = query.length ? root + '?' + query.join('&') : root;

    return url.replaceAll(' ', '%20');
  }, [current]);

  const nameTemplate = useCallback(
    (template: LinkTemplate) => {
      if (template.name) return template.name;

      let nameString = [];

      if (template.occassion) {
        let occassion =
          reservationSettings?.occassions.find(
            (x) => x.id === template.occassion
          )?.title ?? template.occassion;

        if (template.hideOccassion) {
          nameString.push(`Nur ${occassion}`);
        } else {
          nameString.push(occassion);
        }
      }

      if (template.header) {
        nameString.push(template.header);
      }

      if (template.date) {
        let timeStr = '';

        if (template.time) {
          timeStr = ` ${template.time}`;
        }

        if (template.date === 'today') {
          nameString.push('Am selben Tag' + timeStr);
        } else {
          nameString.push(toEuropeanDate(template.date, false) + timeStr);
        }
      }

      if (template.guests) {
        nameString.push(`${template.guests} Gäste`);
      }

      if (template.minGuests) {
        nameString.push(`min. ${template.minGuests} Gäste`);
      }

      return nameString.join('; ');
    },
    [reservationSettings]
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      stopAwayClick
      dontClose
      actions={ACTIONS}
      maxWidth="md"
      fullWidth
      title="Link erstellen"
      severity="info"
      className="link-gen-state-wrapper-container"
    >
      <Box flex>
        <code
          style={{ flexGrow: 1, marginRight: 8 }}
          id="reservationLinkUrl"
          className="text-3"
        >
          {url}
        </code>
        <Button
          onClick={() => copyToClipboard('reservationLinkUrl')}
          variant="primary"
          translation="settings"
          style={{ whiteSpace: 'nowrap' }}
        >
          Copy Link
        </Button>
      </Box>

      <Select
        options={[
          { id: 'noTemplate', label: 'Keine Vorlage' },
          ...templates.data.map((t) => ({ id: t.id, label: nameTemplate(t) })),
        ]}
        value={current?.id ?? 'noTemplate'}
        onChange={(nV) => {
          let id = nV.target.value;

          if (id === 'noTemplate') {
            setcurrent({});
          }

          let template = templates.data.find((t) => t.id === id);

          setcurrent(template ?? {});
        }}
        style={{
          width: 400,
          background: 'var(--color-bg)',
          padding: '0 8px',
          marginRight: 8,
        }}
        className="mg-bt-sm"
      />

      {current.id && (
        <Button
          onClick={() => {
            if (confirm('Vorlage löschen?')) {
              templates.ref
                .doc(current.id)
                .delete()
                .then(() => setcurrent({}));
            }
          }}
        >
          Löschen
        </Button>
      )}

      <TextField
        value={current.header ?? ''}
        onChange={(e) => setcurrent((c) => ({ ...c, header: e.target.value }))}
        label="Überschrift"
        helperText={`Dieser Text erscheint anstatt "Tisch Reservieren"`}
        fullWidth
        variant="standard"
        size="small"
        className="mg-bt-md"
      />

      <TextField
        value={current.source ?? ''}
        onChange={(e) => setcurrent((c) => ({ ...c, source: e.target.value }))}
        label="Quelle"
        helperText={`Reservierungen die über diesen Link vorgenommen werden bekommen diese Quelle. Wenn dieses Feld leer bleibt wird Webseite benutzt.`}
        fullWidth
        variant="standard"
        size="small"
        className="mg-bt-md"
      />

      <TextField
        value={current.coupon_code ?? ''}
        onChange={(e) =>
          setcurrent((c) => ({ ...c, coupon_code: e.target.value }))
        }
        label="Coupon Code"
        helperText={`Fixer Coupon Code wird dann automatisch ausgewählt für alle Reservierungen über diesen Link`}
        fullWidth
        variant="standard"
        size="small"
        className="mg-bt-md"
      />

      <Box>
        <Box
          padding="sm"
          style={{
            background: 'var(--color-bg)',
            borderRadius: 4,
            border: '1px solid var(--color-border)',
          }}
          className="mg-bt-md"
        >
          <TextField
            label="Anlass vorauswählen"
            fullWidth
            variant="standard"
            size="small"
            className="mg-bt-sm"
            value={current?.occassion || 'noOccassion'}
            onChange={(e) => {
              let occassion = e.target.value as string;

              if (occassion === 'noOccassion') {
                setcurrent(({ hideOccassion, ...c }) => ({
                  ...c,
                  occassion: '',
                }));
              } else {
                setcurrent((c) => ({ ...c, occassion }));
              }
            }}
            select
          >
            <MenuItem value="noOccassion">Kein Anlass</MenuItem>
            {reservationSettings?.occassions?.map((occ) => (
              <MenuItem value={occ.id} key={occ.id}>
                {occ.title}
              </MenuItem>
            ))}
          </TextField>

          <SwitchBox
            checked={current.hideOccassion === 'TRUE'}
            onChange={(e, checked) => {
              setcurrent((c) => ({
                ...c,
                hideOccassion: checked ? 'TRUE' : 'FALSE',
              }));
            }}
            disabled={!current.occassion}
            label="Verberge die Anlass-Auswahl"
            translation="settings"
            color="primary"
            className="mg-bt-md"
          />
        </Box>

        <Box
          padding="sm"
          style={{
            background: 'var(--color-bg)',
            borderRadius: 4,
            border: '1px solid var(--color-border)',
          }}
          className="mg-bt-md"
        >
          <SwitchBox
            checked={current.date !== undefined}
            onChange={(e, checked) => {
              if (checked) {
                setcurrent((c) => ({ ...c, date: 'today' }));
              } else {
                setcurrent(({ date, time, ...c }) => c);
              }
            }}
            label="Bestimmtes Datum?"
            translation="settings"
            color="primary"
          />

          <TextField
            value={current.date ?? ''}
            onChange={(e) =>
              setcurrent((c) => ({ ...c, date: e.target.value }))
            }
            label="Datum"
            helperText={`Entweder "today" um jeweils den aktuellen Tag zu wählen oder ein Datum im Format 2023-12-31`}
            fullWidth
            variant="standard"
            size="small"
            disabled={current.date === undefined}
            className="mg-bt-sm"
          />

          <TextField
            value={current.time ?? ''}
            onChange={(e) =>
              setcurrent((c) => ({ ...c, time: e.target.value }))
            }
            label="Uhrzeit"
            helperText={`Leer lassen oder im Format 18:00 (15 Minuten Schritte)`}
            fullWidth
            variant="standard"
            size="small"
            disabled={current.date === undefined}
            className="mg-bt-sm"
          />
        </Box>

        <Box
          padding="sm"
          className="mg-bt-md"
          style={{
            background: 'var(--color-bg)',
            borderRadius: 4,
            border: '1px solid var(--color-border)',
          }}
        >
          <TextField
            value={current.guests ?? ''}
            type="number"
            onChange={(e) =>
              setcurrent((c) => ({ ...c, guests: parseInt(e.target.value) }))
            }
            label="Gäste"
            helperText={`Vorausgewählte Gastanzahl`}
            fullWidth
            variant="standard"
            size="small"
            className="mg-bt-sm"
          />

          <TextField
            value={current.minGuests ?? ''}
            type="number"
            onChange={(e) =>
              setcurrent((c) => ({ ...c, minGuests: parseInt(e.target.value) }))
            }
            label="Min. Gäste"
            helperText={`Mindestanzahl an Gästen`}
            fullWidth
            variant="standard"
            size="small"
          />
        </Box>

        <ImageField
          value={current.imageUrl || null}
          onChange={(imageUrl) =>
            setcurrent((c) => ({ ...c, imageUrl: imageUrl as string }))
          }
          type="url"
          label="Bild (nur auf dem Laptop und als eigene Webseite)"
        />
      </Box>
    </Modal>
  );
};

export default LinkGenerator;
