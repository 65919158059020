import React, { CSSProperties } from 'react';
import {
  default as MaterialUiMenuItem,
  MenuItemProps as MaterialUiMenuItemProps,
} from '@material-ui/core/MenuItem';
import './styles.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Translations } from 'utils/types';
import { classHelper, noop } from 'utils/helper';

export type MenuItemProps = {
  value?: string | number;
  selected?: boolean;
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
  translation?: Translations;
  translationKey?: string | null;
  onClick?: (e: any, value: any) => void;
  noClick?: boolean;
  native?: boolean;
  iconRight?: React.ReactChild;
};

const MenuItem = ({
  children,
  value = '',
  selected = false,
  translation = 'common',
  onClick,
  style,
  translationKey = null,
  className,
  noClick,
  iconRight,
}: MenuItemProps) => {
  const { t } = useTranslation(translation || undefined);

  const innerText =
    typeof children === 'string' && !!translation && !translationKey
      ? t(children)
      : children;

  const text = translationKey ? (
    <Trans i18nKey={translationKey}>{children}</Trans>
  ) : (
    innerText
  );

  return (
    <MaterialUiMenuItem
      className={classHelper(['menuItem', className, iconRight && 'iconRight'])}
      selected={selected}
      value={value}
      style={style}
      onClick={(e) => onClick?.(e, value)}
      disableRipple={noClick}
    >
      {text} {!!iconRight && iconRight}
    </MaterialUiMenuItem>
  );
};

export default MenuItem;
