import React, { useState, useMemo } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import TabCard from 'Components/Molecules/TabCard';
import { ReservationSnippet, Translations } from 'utils/types';
import ShiftReservation from './Components/ShiftReservation';
import EmptyState from './Components/EmptyState';
import { IconButton } from '@material-ui/core';
import { PrintRounded } from 'Components/Atoms/Icons';
import Typography from 'Components/Atoms/Typography';
import { ReservationStatus } from 'types/reservations';
import ReservationPrintModal from 'App/ReservationBook/Components/ReservationPrintModal';

export type ShiftReservationCardProps = {
  reservations: ReservationSnippet[];
  restaurantId: string;
  date: string;
  lightReservation?: boolean;
};

type Tab = {
  id: string | number | null;
  label: string;
  translation: Translations;
  badge: number;
  reservations: ReservationSnippet[];
};

const ShiftReservationCard = ({
  reservations,
  restaurantId,
  lightReservation,
  date,
}: ShiftReservationCardProps) => {
  const [value, setvalue] = useState<string | number | null>(0);

  const tabs = useMemo(() => {
    const filAndSorted = reservations
      .filter((r) => r.status !== ReservationStatus.FAILED)
      .sort((a, b) => a.start - b.start);

    const Groups = filAndSorted.filter(
      (r) =>
        r.guests >= 6 ||
        r.attr.includes('vip') ||
        r.attr.includes('regular') ||
        r.attr.includes('specialOccassions')
    );

    const Comments = filAndSorted.filter((r) => r.comment || r.hostComment);

    const arr: Tab[] = [
      {
        id: 0,
        label: 'Comments',
        badge: Comments.length,
        translation: 'dashboard',
        reservations: Comments,
      },
      {
        id: 1,
        label: "Larger Groups & VIP's",
        badge: Groups.length,
        translation: 'dashboard',
        reservations: Groups,
      },
      // {
      //   id: 2,
      //   label: 'Tickets / Vorbest.',
      //   badge: 0,
      //   translation: 'dashboard',
      //   reservations: [],
      // },
    ];

    return arr;
  }, [reservations]);

  const currentTab = useMemo(
    () => tabs.find((t) => t.id === value),
    [value, tabs]
  );

  const printHref = `https://api.gastronaut.ai/v02/reservations/printReservations/${restaurantId}/${date}.pdf`;

  /* 
    @TODO Modal
    - Views
      - Nach Tischen (Tische Bereiche)
      - Uhrzeit
    - Filter
      - Schicht
      - Anlass
      - von Uhrzeit bis Uhrzeit 
  */

  return (
    <TabCard
      id="shiftReservationCard"
      value={value}
      onChange={setvalue}
      scrollOnOverflow
      tabs={tabs}
      title={
        <Box className="flex space-between">
          <Typography variant="h5" translation="dashboard">
            Daily Sumup
          </Typography>
          <ReservationPrintModal {...{ restaurantId, date }} />
        </Box>
      }
    >
      <Box className="shiftResCard">
        {currentTab?.reservations.map((reservation) => (
          <ShiftReservation
            key={reservation.id}
            reservation={reservation}
            clickable
            restaurantId={restaurantId}
            lightReservation={lightReservation}
          />
        ))}
        {!currentTab?.reservations.length && <EmptyState />}
      </Box>
    </TabCard>
  );
};

export default ShiftReservationCard;
