import { FixedTable } from 'App/GuestBook/Components/FixedTableModal';
import { NewGuestModal } from 'App/GuestBook/Components/NewGuestModal';
import axios from 'axios';
import { GuestV03Document } from 'gastronaut-shared/types/documents';
import { Relationship } from 'gastronaut-shared/types/helper/guests';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Guest, Reservation } from 'types/reservations';
import { db } from 'utils/firebase';
import server from 'utils/server';

// Data to give to the screen
// {searchResults: {
//   id: string;
//   email: string;
//   name: string;
//   phone: string;
//   attr: string[];
//   lastReservation: string;
// }[];
//  selectedGuest: null | {
//   data: Guest;
//   reservations: Reservation[];
//   onBlur: (id: string, key: string, newValue: string | string[]) => void;
// };
//  guestLoading: boolean;
//  sort: Tab;
//  setsort: React.Dispatch<React.SetStateAction<Tab>>;
//  search: null | string;
//  setsearch: React.Dispatch<React.SetStateAction<null | string>>;
//  onGuestSelect: (id: string) => void;};
//  searchLoading: boolean ,
//  searchError: null | string,
//  guestError: null | string,
//  hasMore: boolean

type Tab = 'name' | 'last_visit_date DESC' | 'visits DESC';

const toGuestObj = (guest: any) => ({ ...guest._source, id: guest._id });

const useGuestBook = (
  restaurantId: string,
  page: number = 0,
  perPage: number = 20,
  uid: string
) => {
  const [sort, setsort] = useState<Tab | null>('name');
  const [search, setsearch] = useState<null | string>(null);
  const [selectedGuest, setSelectedGuest] = useState<null | {
    data: Guest;
    reservations: Reservation[];
    otherReservations: Reservation[];
    onBlur: (
      id: string,
      key: string,
      newValue: string | string[] | FixedTable
    ) => void;
  }>(null);
  const [searchResults, setsearchResults] = useState<
    {
      id: string;
      email: string;
      name: string;
      phone: string;
      attr: string[];
      lastReservation: string;
    }[]
  >([]);
  const [guestLoading, setguestLoading] = useState<boolean>(false);
  const [searchLoading, setsearchLoading] = useState<boolean>(false);
  const [hasMore, sethasMore] = useState<boolean | null>(null);
  const [guestError, setguestError] = useState<string | null>(null);
  const [searchError, setsearchError] = useState<string | null>(null);

  const getOneGuest = async (id: string) => {
    try {
      console.log(id);

      setguestError(null);
      setguestLoading(true);

      if (!id.includes(restaurantId)) {
        id = `${id}-${restaurantId}`;
      }

      const guestRef = await db.collection('relationships').doc(id).get();

      let guest: Guest | null = null;

      if (guestRef.exists) {
        guest = { ...guestRef.data(), id: guestRef.id } as Guest;
      } else {
        let result = searchResults.find(
          (s) =>
            s.id.replace(`-${restaurantId}`, '') ===
            id.replace(`-${restaurantId}`, '')
        );

        if (result) {
          guest = {
            id,
            email: result.email,
            name: result.name,
            phone: result.phone,
            attr: result.attr,
            lastReservation: result.lastReservation,
          };

          let relationship: Relationship = {
            ...guest,
            guestId: id.replace(`-${restaurantId}`, ''),
            restaurantId,
          };

          await db.collection('relationships').doc(id).set(relationship);
        } else {
          alert('Guest not found ' + id);
        }
      }

      setguestLoading(false);

      return guest;
    } catch (error) {
      console.log('Error on loading Guest (guest with id : ', id, ') :', error);
      setguestLoading(false);
      setguestError('An Error occured');
      return null;
    }
  };

  const getGuestReservations = async (id: string) => {
    try {
      const guestId = id.split('-')[0];
      const [reservationsRef, reservationsRef2] = await Promise.all([
        db
          .collection('requestsV03')
          .where('guest.id', '==', guestId)
          .where('restaurant', '==', restaurantId)
          .get(),
        db
          .collection('requestsV03')
          .where('guest.id', '==', `${guestId}-${restaurantId}`)
          .where('restaurant', '==', restaurantId)
          .get(),
      ]);

      let arr: Reservation[] = [];

      if (!reservationsRef.empty) {
        reservationsRef.forEach((doc) => {
          const data = { ...doc.data(), id: doc.id } as Reservation;
          arr.push(data);
        });
      }

      if (!reservationsRef2.empty) {
        reservationsRef2.forEach((doc) => {
          const data = { ...doc.data(), id: doc.id } as Reservation;
          arr.push(data);
        });
      }

      return arr.sort((a: Reservation, b: Reservation) => {
        if (a.date > b.date) return -1;
        else if (a.date < b.date) return 1;
        else return 0;
      });
    } catch (error) {
      console.log(
        `Error on loading Guest Reservations (guest with id : ${id})`,
        error
      );
      return null;
    }
  };

  const getGuestReservationsBySecondaryFactors = async (
    id: string,
    email: string,
    phone: string
  ) => {
    try {
      const guestId = id.split('-')[0];
      const [reservationsRef, reservationsRef2] = await Promise.all([
        email
          ? db
              .collection('requestsV03')
              .where('guest.email', '==', email)
              .where('restaurant', '==', restaurantId)
              .get()
          : null,
        phone?.length > 4
          ? db
              .collection('requestsV03')
              .where('guest.phone', '==', phone)
              .where('restaurant', '==', restaurantId)
              .get()
          : null,
      ]);

      let arr: Reservation[] = [];
      let ids: string[] = [];

      if (!reservationsRef?.empty) {
        reservationsRef?.forEach((doc) => {
          const data = { ...doc.data(), id: doc.id } as Reservation;
          if (
            !ids.includes(doc.id) &&
            data?.guest?.id !== guestId &&
            data?.guest?.id !== `${guestId}-${restaurantId}`
          ) {
            arr.push(data);
            ids.push(doc.id);
          }
        });
      }

      if (!reservationsRef2?.empty) {
        reservationsRef2?.forEach((doc) => {
          const data = { ...doc.data(), id: doc.id } as Reservation;
          if (
            !ids.includes(doc.id) &&
            data?.guest?.id !== guestId &&
            data?.guest?.id !== `${guestId}-${restaurantId}`
          ) {
            arr.push(data);
            ids.push(doc.id);
          }
        });
      }

      return arr.sort((a: Reservation, b: Reservation) => {
        if (a.date > b.date) return -1;
        else if (a.date < b.date) return 1;
        else return 0;
      });
    } catch (error) {
      console.log(
        `Error on loading Guest Reservations (guest with id : ${id})`,
        error
      );
      return [];
    }
  };

  const handleGuestChange = (
    id: string,
    key: string,
    newValue: string | string[] | FixedTable
  ) => {
    if (id === 'noId') return;

    try {
      // console.log({ [key]: newValue });

      if (key === 'guestComment') {
        db.collection('relationships')
          .doc(id)
          .update({ [key]: newValue, hostComment: '' });

        setSelectedGuest((x) =>
          !!x
            ? {
                ...x,
                data: {
                  ...x.data,
                  guestComment: newValue as string,
                  hostComment: '',
                },
              }
            : null
        );
      } else {
        db.collection('relationships')
          .doc(id)
          .update({ [key]: newValue });
        setSelectedGuest((x) =>
          !!x ? { ...x, data: { ...x.data, [key]: newValue } } : null
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onGuestSelect = async (id: string) => {
    setguestError(null);
    setSelectedGuest(null);
    if (selectedGuest?.data?.id === id) {
      setSelectedGuest(null);
    } else {
      setguestLoading(true);

      const data: Guest | null = await getOneGuest(id);

      const reservations: Reservation[] | null = await getGuestReservations(id);

      const otherReservations: Reservation[] | null =
        await getGuestReservationsBySecondaryFactors(
          id,
          data?.email || '',
          data?.phone || ''
        );

      if (!!data && !!reservations) {
        setSelectedGuest({
          data,
          reservations,
          otherReservations,
          onBlur: handleGuestChange,
        });
      }

      setguestLoading(false);
    }
  };

  const getGuests = async () => {
    try {
      const { data } = await axios.get<{
        results: {
          id: string;
          restaurant: string;
          name: string;
          email: string;
          phone: string;
          visits: number;
          vip: boolean;
          regular: boolean;
          blacklist: boolean;
          last_visit_date: string;
        }[];
      }>(
        `https://api.gastronaut.ai/v03/guests/searchV02/${restaurantId}/${page}/${perPage}/${
          search || ''
        }?sort=${sort}`,
        {
          headers: {
            uid,
          },
        }
      );

      const { results } = data;

      sethasMore(results.length === perPage);
      setsearchResults(
        results.map((r) => ({
          id: r.id,
          email: r.email,
          name: r.name,
          phone: r.phone,
          attr: [
            r.vip ? 'vip' : '',
            r.blacklist ? 'blacklist' : '',
            r.regular ? 'regular' : '',
          ].filter(Boolean),
          lastReservation: r.last_visit_date,
        }))
      );
      setsearchLoading(false);
      setsearchError(null);
    } catch (error: any) {
      console.log(error.message);
      setsearchError(error.message);
      setsearchLoading(false);
      sethasMore(false);
    }
  };

  const getGuestsOld = async () => {
    try {
      const { data } = await axios.get(
        `https://api-next.gastronaut.ai/v02/guests/search/${restaurantId}/${page}/${perPage}/${
          search || ''
        }?sort=${sort}`,
        {
          headers: {
            uid,
          },
        }
      );

      const { total, hits } = data.hits;

      sethasMore(total.value > perPage);
      setsearchResults(hits.map(toGuestObj));
      setsearchLoading(false);
      setsearchError(null);
    } catch (error: any) {
      console.log(error.message);
      setsearchError(error.message);
      setsearchLoading(false);
      sethasMore(false);
    }
  };

  const deleteAGuest = async (guestId: string) => {
    // console.log('guest to delete', guestId);
    try {
      setguestLoading(true);
      setSelectedGuest(null);
      await db.collection('relationships').doc(guestId).delete();
      setguestLoading(false);
      setsearchResults((sR) => sR.filter((guest) => guest.id !== guestId));
    } catch (error: any) {
      console.log('Error on delete guest : ', guestId, error);
      setguestLoading(false);
      setguestError(error.message);
    }
  };

  useEffect(() => {
    setsearchLoading(true);
    getGuests();
  }, [sort, search, perPage]);

  useEffect(() => {
    if (document.location.search) {
      let query = new URLSearchParams(document.location.search);

      let guestId = query.get('guestId');

      if (guestId) {
        onGuestSelect(`${guestId}-${restaurantId}`);
      }
    }
  }, [document.location.search]);

  const submitNewGuest = async (newGuest: NewGuestModal) => {
    // @TODO

    let phone = newGuest.phone?.replace(' ', '');

    if (phone.length <= 4) {
      phone = '';
    }

    const duplicates = (
      await Promise.all([
        db
          .collection('relationships')
          .where('restaurant', '==', restaurantId)
          .where('email', '==', newGuest.email || 'NO_EMAIL_ADDRESS')
          .get(),
        db
          .collection('relationships')
          .where('restaurant', '==', restaurantId)
          .where('phone', '==', phone || 'NO_PHONE_NUMBER')
          .get(),
      ])
    )
      .map((x) =>
        x.docs.map((x) => ({ ...(x.data() as Relationship), id: x.id }))
      )
      .flat();

    if (duplicates.length > 0) {
      alert(duplicates.length + ' Duplicates found');
      onGuestSelect(duplicates[0].id);

      return;
    }

    const guest: GuestV03Document = {
      name: newGuest.name,
      email: newGuest.email,
      phone,
      guestAt: [restaurantId],
      nameCertainty: 60,
      emailCertainty: 60,
      phoneCertainty: 60,
    };

    const { id: guestId } = await db.collection('guestsV03').add(guest);

    const relationship: Relationship = {
      ...newGuest,
      phone,
      guestId,
      restaurantId,
      confirmedReservations: 0,
      lastReservation: '',
    };

    await db
      .collection('relationships')
      .doc(`${guestId}-${restaurantId}`)
      .set(relationship);

    onGuestSelect(`${guestId}-${restaurantId}`);

    return;
  };

  const transferReservation = async (id: string) => {
    if (!selectedGuest) return;
    const { otherReservations, data } = selectedGuest;

    const reservation = otherReservations.find((r) => r.id === id);

    if (!reservation) return;

    const guestId = reservation.guest.id?.replace(`-${restaurantId}`, '');

    const updatedAt = Date.now();

    await db
      .collection('requestsV03')
      .doc(id)
      .update({
        guest: {
          ...reservation.guest,
          id: selectedGuest.data.id,
          guestId,
          guestComment: [reservation.guest.guestComment, data.guestComment]
            .filter(Boolean)
            .join(),
        },
        updatedAt,
        updateNote: {
          note: 'Umzug',
          updatedAt,
        },
      } as Partial<Reservation>);

    setSelectedGuest((sG) =>
      !!sG
        ? {
            ...sG,
            reservations: sG.reservations.concat(reservation),
            otherReservations: sG.otherReservations.filter((r) => r.id !== id),
          }
        : null
    );
  };

  return {
    sort,
    setsort,
    search,
    setsearch,
    selectedGuest,
    onGuestSelect,
    searchResults,
    guestLoading,
    searchLoading,
    searchError,
    guestError,
    hasMore,
    deleteAGuest,
    submitNewGuest,
    transferReservation,
  };
};

export default useGuestBook;
