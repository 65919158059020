import React from 'react';
import {
  default as MaterialCheckbox,
  CheckboxProps as MaterialCheckboxProps,
} from '@material-ui/core/Checkbox';
import {
  default as MaterialRadio,
  RadioProps as MaterialRadioProps,
} from '@material-ui/core/Radio';
import './styles.scss';
import { noop } from 'utils/helper';
import { Modify, Translations } from 'utils/types';
import Box from '../Box';
import Typography, { Colors } from '../Typography';

export type CheckboxProps = Modify<
  {
    className?: string;
    label?: string;
    translation?: Translations;
    style?: React.CSSProperties;
    checked: boolean;
    onChange: (
      event: React.ChangeEvent<HTMLInputElement>,
      checked: boolean
    ) => void;
    name?: string;
    noLabel?: boolean;
    labelColor?: Colors;
  },
  | ({ radio?: false | undefined } & MaterialCheckboxProps)
  | ({ radio: true } & MaterialRadioProps)
>;

const Checkbox = ({
  checked = false,
  style = {},
  onChange = noop,
  name,
  className = '',
  label,
  translation = 'common',
  noLabel = false,
  labelColor,
  ...props
}: CheckboxProps) => {
  const Component = props.radio ? MaterialRadio : MaterialCheckbox;

  return (
    <Box
      flex={!noLabel}
      style={style}
      component="label"
      className={'checkbox ' + className}
    >
      <Component checked={checked} onChange={onChange} name={name} {...props} />
      {label && (
        <Typography
          variant="text-2"
          color={labelColor || 'subdued'}
          translation={translation}
        >
          {label}
        </Typography>
      )}
    </Box>
  );
};

export default Checkbox;
