import React, { ChangeEvent, useEffect, useState, useMemo } from 'react';
import './styles.scss';
import Typography from '../../../../Components/Atoms/Typography';
import TextField from '../../../../Components/Atoms/TextField';
import useWindowResize from '../../../../CustomHooks/useWindowResize';
import { blockToTime } from '../../../../utils/helper';
import Checkbox from '../../../../Components/Atoms/Checkbox';
import IconButton from '../../../../Components/Atoms/IconButton';
import { Add, Remove } from 'Components/Atoms/Icons';
import { SlotPerBlock, SlotPerBlockRule } from 'types/shifts';
import InputBase from 'Components/Atoms/InputBase';

export type SlotState = {
  slotsPerBlock: SlotPerBlock;
  blockedAt: number[];
  maxGuests?: number;
  start: number;
  close: number;
  service: number;
  countAllGuests?: boolean;
};

export type SlotSettingsProps = {
  state: SlotState;
  onChange: (state: SlotState) => void;
};

interface SlotData {
  block: number;
  time: string;
  bookable: boolean;
  capacity: number;
}

const SlotSettings: React.FC<SlotSettingsProps> = ({ state, onChange }) => {
  const blocks: SlotData[] = useMemo(
    () =>
      Array.from(
        Array(Math.max(state.close - state.start, 0) || 0),
        (_, index) => {
          let block = state.start + index;

          let capacity =
            state.slotsPerBlock.rules.find((s) => s.blocks.includes(block))
              ?.slots ?? state.slotsPerBlock.standard;

          return {
            block,
            time: blockToTime(block),
            bookable:
              !state.blockedAt.includes(block) && block <= state.service,
            capacity,
          };
        }
      ),
    [state]
  );

  function slotNumberListener(
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    let val = parseInt(event.target.value);
    if (val < 1) {
      val = 1;
    }
    onChange({
      ...state,
      slotsPerBlock: {
        ...state.slotsPerBlock,
        standard: val,
      },
    });
  }

  function maxGuestsListener(
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    onChange({
      ...state,
      maxGuests: parseInt(event.target.value),
    });
  }

  const modifySlots = (
    block: number,
    incrementBy: number,
    asNewValue = false
  ) => {
    let rule = state.slotsPerBlock.rules.find((s) => s.blocks.includes(block));

    let currentValue = rule?.slots ?? state.slotsPerBlock.standard;

    let newValue = Math.max(
      asNewValue ? incrementBy : currentValue + incrementBy,
      0
    );

    let rules = state.slotsPerBlock.rules.reduce(
      (acc: SlotPerBlockRule[], s) => {
        if (s.slots === state.slotsPerBlock.standard) return acc;

        if (s.blocks.includes(block)) {
          if (
            s.blocks.length === 1 &&
            newValue === state.slotsPerBlock.standard
          ) {
            return acc;
          } else if (s.blocks.length === 1) {
            if (acc.find((s) => s.blocks.includes(block))) {
              return acc;
            }

            return [
              ...acc,
              {
                ...s,
                slots: newValue,
              },
            ];
          } else {
            return [
              ...acc,
              {
                ...s,
                blocks: s.blocks.filter((b) => b !== block),
              },
            ];
          }
        } else if (s.slots === newValue) {
          return [
            ...acc,
            {
              ...s,
              blocks: [...s.blocks, block],
            },
          ];
        }

        return [...acc, s];
      },
      []
    );

    if (!rules.find((s) => s.blocks.includes(block))) {
      rules.push({ blocks: [block], slots: newValue });
    }

    onChange({ ...state, slotsPerBlock: { ...state.slotsPerBlock, rules } });
  };

  function toggleBlocked(block: number, capacity: number) {
    let blockedAt = Array.from(state.blockedAt);
    let index = blockedAt.indexOf(block);
    if (index > -1) {
      blockedAt.splice(index, 1);
    } else {
      blockedAt.push(block);
    }
    onChange({ ...state, blockedAt });
  }

  function overlayWidthGetter(
    cap: number,
    blocked: boolean,
    start: number
  ): string {
    let biggestSlot = 1;

    if (!blocked || start > state.service || !cap) {
      return '10px';
    }
    blocks.forEach((item) => {
      if (item.capacity > biggestSlot && item.bookable) {
        biggestSlot = item.capacity;
      }
    });
    return `${(cap / biggestSlot) * 100}%`;
  }

  console.log({ blocks });

  return (
    <div className="slot-settings-container">
      <Typography
        variant="h5"
        style={{ marginBottom: 8 }}
        translation="settings"
      >
        Guest Volume and Reservation Restrictions
      </Typography>
      <Typography variant="text-3" color="subdued" translation="settings">
        Enter how many guests can reserve per "15 minute block".
      </Typography>
      <div className="input-fields-container">
        <div className="input-field-container">
          <TextField
            type="number"
            label="Default Value"
            labelTranslation="settings"
            value={state.slotsPerBlock.standard}
            onChange={slotNumberListener}
          />
          <Typography variant="text-4" color="subdued" translation="settings">
            This setting specifies the default value per block.
          </Typography>
        </div>
        <div className="input-field-container">
          <TextField
            label="Max Guests of Shift"
            labelTranslation="settings"
            type="number"
            value={state.maxGuests ?? ''}
            onChange={maxGuestsListener}
          />
          <Typography variant="text-4" color="subdued" translation="settings">
            This setting specifies the maximum number of reservations per shift.
          </Typography>
        </div>
      </div>
      <table className="slot-table" cellSpacing="0" cellPadding="0">
        <thead>
          <th className="blank-slot"></th>
          <th>
            <Typography color="subdued" variant="text-4" translation="settings">
              Bookable?
            </Typography>
          </th>
          <th className="blank">&nbsp;</th>
          <th>
            <Typography color="subdued" variant="text-4" translation="settings">
              Max Guests
            </Typography>
          </th>
          <th className="blank">&nbsp;</th>
        </thead>
        {blocks.map((item: SlotData) => {
          return (
            <tr key={item.block} style={{ height: 40 }}>
              <td className="time-slot">
                <Typography
                  variant="text-1"
                  color="textPrimary"
                  translation={null}
                >
                  {item.time}
                </Typography>
                <div
                  className="slot-background"
                  style={{
                    width: overlayWidthGetter(
                      item.capacity,
                      item.bookable,
                      item.block
                    ),
                  }}
                ></div>
              </td>
              <td className="blocked-container">
                <Checkbox
                  checked={item.bookable && item.block <= state.service}
                  onChange={() => toggleBlocked(item.block, item.capacity)}
                  noLabel
                  disabled={item.block > state.service}
                />
              </td>
              <td>
                <IconButton
                  size="small"
                  disabled={item.block > state.service || !item.bookable}
                >
                  <Remove onClick={() => modifySlots(item.block, -1)} />
                </IconButton>
              </td>
              <td>
                {/* <Typography
                  variant="text-1"
                  color={!item.bookable ? "subdued" : "textPrimary"}
                  translation={!item.bookable ? "settings" : null}
                >
                  {!item.bookable ? "Blocked" : item.capacity}
                </Typography> */}
                {!item.bookable ? (
                  <Typography
                    variant="text-1"
                    color={!item.bookable ? 'subdued' : 'textPrimary'}
                    translation={!item.bookable ? 'settings' : null}
                  >
                    Blocked
                  </Typography>
                ) : (
                  <Typography
                    variant="text-1"
                    color={!item.bookable ? 'subdued' : 'textPrimary'}
                    translation={!item.bookable ? 'settings' : null}
                  >
                    <InputBase
                      type="number"
                      value={item.capacity}
                      style={{ maxWidth: 40 }}
                      onChange={(e) =>
                        modifySlots(item.block, Number(e.target.value), true)
                      }
                    />
                  </Typography>
                )}
              </td>
              <td>
                <IconButton size="small" disabled={!item.bookable}>
                  <Add onClick={() => modifySlots(item.block, 1)} />
                </IconButton>
              </td>
            </tr>
          );
        })}
        <div
          className="gradient-container"
          style={{ height: `${42 * (state.close - (state.service + 1))}px` }}
        ></div>
      </table>
      <Checkbox
        label="Alle Gäste zählen (auch die ohne überschneidung von Bereichen)"
        checked={!!state.countAllGuests}
        onChange={() =>
          onChange({ ...state, countAllGuests: !state.countAllGuests })
        }
      ></Checkbox>
    </div>
  );
};

export default SlotSettings;
