import React, { useMemo, useRef, useEffect, useState, useContext } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill';
import {
  EditReservation,
  FloorPlan as FloorPlanType,
  Reservation,
  Space,
  Table,
  Warning,
} from 'types/reservations';
import TableWrapper from '../TableWrapper';
import { findOverlaps, timeToBlock } from 'utils/helper';
import { CircularProgress } from '@material-ui/core';
import Typography from 'Components/Atoms/Typography';
import SpaceSwitch from './Components/SpaceSwitch';
import ShiftMessage from '../ShiftMessage';
import { useSwipeable } from 'react-swipeable';
import useBackground from 'CustomHooks/useBackground';
import { RestaurantContext } from 'Contexts/RestaurantContext';
import { ReservationContext } from 'Contexts/ReservationContext';
import Button from 'Components/Atoms/Button';

function overlaps(
  rect1: { x: number; y: number; size: number },
  rect2: { x: number; y: number; size: number },
  axis?: 'all' | 'x' | 'y'
) {
  if (axis === 'x') {
    return (
      rect1.x < rect2.x + rect2.size &&
      rect1.x + rect1.size > rect2.x &&
      Math.abs(rect1.y - rect2.y) <= Math.max(rect1.size, rect2.size) / 2
    );
  } else if (axis === 'y') {
    return (
      rect1.y < rect2.y + rect2.size &&
      rect1.size + rect1.y > rect2.y &&
      Math.abs(rect1.x - rect2.x) <= Math.max(rect1.size, rect2.size) / 2
    );
  }

  return (
    rect1.x < rect2.x + rect2.size &&
    rect1.x + rect1.size > rect2.x &&
    rect1.y < rect2.y + rect2.size &&
    rect1.size + rect1.y > rect2.y
  );
}

type EditMode = {
  start: number | null;
  end: number | null;
  guests: number | undefined;
  tables: string[];
  new: boolean;
  id: string;
} | null;

export type FloorPlanProps = {
  floorPlan: FloorPlanType | null;
  editReservation: EditReservation | null;
  onTableClick: (
    id: string,
    name: string,
    space: string,
    available?: boolean
  ) => void;
  reservations: Reservation[];
  space: string | null;
  currentTime: string | null;
  onSpaceChange: (id: string) => void;
  currentTable: string | null;
  currentReservationTables: null | string[];
  updateSize?: string;
  loading: boolean;
  closed:
    | false
    | {
        message: string | null;
        error: boolean;
      };
  onCloseSideBar?: () => void;
  warnings: Warning[];
};

const switchReservationHelper = (
  tableName: string,
  editMode: EditMode,
  reservations: Reservation[]
) => {
  if (!editMode?.tables?.length) return;

  const overlappingReservations = reservations.filter((r) => {
    let overlaps = findOverlaps(r, editMode.start || 0, editMode.end || 0);
    let sameReservation = r.id === editMode.id;
    return !!overlaps && !sameReservation;
  });

  if (!overlappingReservations.length) {
    return;
  } else if (
    overlappingReservations.length === 1 &&
    overlappingReservations[0].guests === editMode.guests
  ) {
    return editMode.new ? 'blocked' : overlappingReservations[0].id;
  } else {
    return 'blocked';
  }
};

const FloorPlan: React.FC<FloorPlanProps> = ({
  floorPlan,
  editReservation,
  onTableClick,
  reservations,
  space,
  currentTime,
  onSpaceChange,
  currentTable,
  updateSize = '',
  loading,
  closed = false,
  warnings,
  currentReservationTables,
  onCloseSideBar,
}) => {
  const dimensions = useResizeDetector();

  const { Shuffle } = useContext(ReservationContext);

  const [update, setupdate] = useState('');

  useEffect(() => {
    setTimeout(() => {
      setupdate(updateSize);
    }, 10);
  }, [updateSize]);

  const size = useMemo(() => {
    let currentSpace = floorPlan?.spaces.find((s) => s.id === space) || null;

    if (!currentSpace) {
      return null;
    } else {
      let { height = 1, width = 1 } = dimensions;

      height -= 60;

      let h = currentSpace.size.height;
      let w = currentSpace.size.width;

      return Math.min(height / h, width / w);
    }
  }, [dimensions, floorPlan, space, update]);

  const editMode = useMemo(() => {
    if (!editReservation) return null;

    let id = editReservation?.reservationId || '';

    let start = editReservation?.timeSlot?.block || null;

    let guests = editReservation?.guests;

    let reservationLength =
      editReservation.reservationLength ??
      editReservation?.timeSlot?.reservationLength ??
      null;

    let end =
      start === null || reservationLength === null
        ? null
        : start + reservationLength;

    let tables = editReservation?.tables || [];

    return {
      start,
      end,
      guests,
      tables,
      new: !!editReservation.new,
      id,
    };
  }, [editReservation]);

  const tables = useMemo(() => {
    let currentSpace = floorPlan?.spaces.find((s) => s.id === space) || null;

    return (
      floorPlan?.tables
        .filter((t) => t.space === space)
        .map((t) => {
          const filteredReservations = reservations
            .filter(
              (r) =>
                !r.done && r.status !== 'failed' && r.tables?.includes(t.name)
            )
            .sort(
              (a, b) => (a.startTimeInBlocks || 0) - (b.startTimeInBlocks || 0)
            );

          const switchReservation = switchReservationHelper(
            t.name,
            editMode,
            filteredReservations
          ) as string | 'blocked' | undefined;

          return {
            ...t,
            reservations: filteredReservations,
            switchReservation,
          };
        }) ?? []
    );
  }, [space, reservations, floorPlan, editMode]);

  const tablesTouchingX = useMemo(() => {
    const tables =
      floorPlan?.tables.map((x) => ({
        name: x.name,
        coords: x.coords,
        space: x.space,
      })) ?? [];

    return tables.reduce((acc, cV, i, arr) => {
      const touching = tables
        .filter(
          (t) =>
            t.name !== cV.name &&
            t.space === cV.space &&
            overlaps(t.coords, cV.coords, 'x')
        )
        .map((t) => t.name);

      if (touching.length) {
        return {
          ...acc,
          [cV.name]: touching,
        };
      }

      return acc;
    }, {} as Record<string, string[]>);
  }, [floorPlan]);

  const tablesTouchingY = useMemo(() => {
    const tables =
      floorPlan?.tables.map((x) => ({
        name: x.name,
        coords: x.coords,
        space: x.space,
      })) ?? [];

    return tables.reduce((acc, cV, i, arr) => {
      const touching = tables
        .filter(
          (t) =>
            t.name !== cV.name &&
            t.space === cV.space &&
            overlaps(t.coords, cV.coords, 'y')
        )
        .map((t) => t.name);

      if (touching.length) {
        return {
          ...acc,
          [cV.name]: touching,
        };
      }

      return acc;
    }, {} as Record<string, string[]>);
  }, [floorPlan]);

  const showTables = !loading && !closed && size !== null;

  const alerts = useMemo(
    () =>
      warnings.reduce((acc: string[], cV) => {
        return [...new Set([...acc, ...cV.tables])];
      }, []),
    [warnings]
  );

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      let spaces = floorPlan?.spaces ?? [];
      let index = spaces.findIndex((s) => s.id === space);

      if (index === -1 || eventData.dir === 'Up' || eventData.dir === 'Down')
        return;

      if (eventData.dir === 'Right') {
        if (index > 0) {
          index--;
        } else {
          index = spaces.length - 1;
        }
      } else if (eventData.dir === 'Left') {
        index = (index + 1) % spaces.length;
      }

      onSpaceChange(spaces[index]?.id ?? space);
    },
  });

  const background = useBackground(floorPlan, space);

  return (
    <div id="floorPlan" ref={dimensions.ref}>
      <div id="swippable" {...handlers} onDoubleClick={onCloseSideBar} />
      {!!background && !!size && (
        <img
          src={background.imageUrl}
          id={`background`}
          style={{
            width: (background.width / background.factor) * size,
            height: (background.height / background.factor) * size,
            position: 'absolute',
            zIndex: 0,
            top: background?.offset?.y ?? 0,
            left: background?.offset?.x ?? 0,
            touchAction: 'none',
            filter: background.invert
              ? `invert(${background.invert})`
              : undefined,
          }}
        />
      )}
      {showTables &&
        tables.map(({ switchReservation, ...table }) => (
          <TableWrapper
            key={table.id}
            table={table}
            switchReservation={switchReservation}
            hasAlert={!editMode && alerts.includes(table.name)}
            touchingX={tablesTouchingX?.[table.name]}
            touchingY={tablesTouchingY?.[table.name]}
            editMode={editMode}
            isSelected={!!editReservation?.tables?.includes(table.name)}
            onTableClick={onTableClick}
            size={size || 0}
            currentTime={currentTime}
            active={
              currentTable === table.id ||
              !!currentReservationTables?.includes(table.name)
            }
          />
        ))}
      {loading && !closed && (
        <Box flex className="center" fullSize>
          <CircularProgress />
        </Box>
      )}
      {closed && (
        <Box flex className="center" fullSize>
          <Typography
            textAlign="center"
            weight="medium"
            color={closed.error ? 'error' : 'textPrimary'}
          >
            {closed.message}
          </Typography>
        </Box>
      )}
      <Box flex className="spaceSwitchContainer">
        <SpaceSwitch
          value={space}
          onChange={onSpaceChange}
          spaces={floorPlan?.spaces || []}
        />
        {showTables && (
          <>
            {/* <SpaceSwitch
            title="Bevorzugter Bereich"
            value={Shuffle.state?.preffered}
            onChange={onSpaceChange}
            spaces={floorPlan?.spaces || []}
          /> */}

            {!Shuffle.state && <ShiftMessage />}

            {!!Shuffle.state && (
              <>
                <div style={{ flexGrow: 10 }} />
                <Button
                  variant="default"
                  onClick={Shuffle.closeShuffle}
                  style={{ marginRight: 16 }}
                >
                  Abbrechen
                </Button>
                <Button
                  variant="primary"
                  loading={Shuffle?.state?.loading}
                  onClick={
                    !!Shuffle.state?.reservations?.length
                      ? Shuffle.submitShuffle
                      : Shuffle.requestShuffle
                  }
                >
                  {!!Shuffle.state?.reservations?.length
                    ? 'Änderung speichern'
                    : 'Neu Anordnen'}
                </Button>
              </>
            )}
          </>
        )}
      </Box>
    </div>
  );
};

export default FloorPlan;
