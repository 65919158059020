import React from "react";
import Box from "Components/Atoms/Box";
import Typography from "Components/Atoms/Typography";
import RoundArrow from "Components/Atoms/Icons/RoundArrow";
import "./styles.scss";
import { CircularProgress } from "@material-ui/core";

type NoGuestSelectedProps = {
  guestError: string | null;
  guestLoading: boolean;
};

const NoGuestSelected = ({
  guestError,
  guestLoading,
}: NoGuestSelectedProps) => {
  return (
    <Box
      flex
      style={{
        justifyContent: "center",
        marginTop: "calc(100vh / 4)",
      }}
    >
      {!!guestError && <Typography color="error">{guestError}</Typography>}
      {guestLoading && <CircularProgress />}
      {!guestError && !guestLoading && (
        <div style={{ position: "relative" }}>
          <RoundArrow
            style={{
              position: "absolute",
              color: "var(--color-disabled)",
              height: 129,
              width: 122,
              left: -129,
              top: -80,
            }}
          />
          <Typography
            block
            textAlign="center"
            variant="text-2"
            style={{ maxWidth: 150 }}
          >
            Select any one of the guest
          </Typography>
        </div>
      )}
    </Box>
  );
};

export default NoGuestSelected;
