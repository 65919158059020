import Box from "Components/Atoms/Box";
import NotFixedReservationIcon from "Components/Atoms/Icons/NotFixedReservationIcon";
import ShuffleModeIcon from "Components/Atoms/Icons/ShuffleModeIcon";
import { ReservationContext } from "Contexts/ReservationContext";
import React, { useContext } from "react";
import { CurrentStatus, Reservation } from "types/reservations";
import { classHelper, dateHelper } from "utils/helper";
import "./styles.scss";

export type ReservationCardProps = {
  reservation: Reservation & { previousTables?: string[] | null };
  onClick?: React.MouseEventHandler;
  onDrag?: React.DragEventHandler;
  onDragStart?: React.DragEventHandler;
  onDragEnd?: React.DragEventHandler;
  selected: boolean;
  position: {
    top: number;
    left: number;
    width: number;
    height: number;
  };
  occassions?: { id: string; title: string }[];
  id: string;
};

const groupSizeHelper = (
  guests: number,
  currentStatus: CurrentStatus,
  today = true
) => {
  if (
    currentStatus === CurrentStatus.SEATED ||
    currentStatus === CurrentStatus.HAS_ORDERED ||
    currentStatus === CurrentStatus.PAID
  ) {
    return "gzSeated";
  } else if (currentStatus === CurrentStatus.OVERDUE) {
    return "gzOverdue";
  } else if (currentStatus === CurrentStatus.UPCOMING) {
    return "gzUpcoming";
  } else if (currentStatus === CurrentStatus.DONE && today) {
    return "gzDone";
  } else if (guests >= 10) {
    return "gz10";
  } else {
    return "gz" + String(Math.floor(guests / 2) * 2);
  }
};

const nameHelper = (name: string, width: number) => {
  if (width >= 200) {
    return name;
  } else {
    return name.split(" ").reduce((acc, cV, i, arr) => {
      if (i === arr.length - 1) {
        return `${acc} ${cV}`;
      } else {
        return `${acc} ${cV[0]}`;
      }
    }, "");
  }
};

const ReservationCard = ({
  reservation,
  position,
  occassions = [],
  onClick,
  onDrag,
  selected,
  id,
  onDragStart,
  onDragEnd,
}: ReservationCardProps) => {
  const { Shuffle } = useContext(ReservationContext);

  const classNames = classHelper([
    "timeTableResCard",
    groupSizeHelper(
      reservation.guests,
      reservation.currentStatus,
      dateHelper() === reservation.date
    ),
    !!selected && "selected",
    !!reservation.blocked && "blocked",
    !!reservation.previousTables && "shuffled",
  ]);

  const guestName = nameHelper(reservation.guest.name, position.width);

  const short = position.width <= 240;

  const occassionStr =
    occassions.length < 2 || short
      ? ""
      : occassions?.find((o) => o.id === reservation.occassion)?.title || "";

  return (
    <Box
      flex
      style={position}
      className={classNames}
      onClick={onClick}
      {...(!Shuffle?.state?.reservations
        ? {
            onDrag,
            onDragStart,
            onDragEnd,
            draggable: true,
          }
        : {})}
      id={id}
    >
      {!!reservation.previousTables && !reservation.fixed && (
        <ShuffleModeIcon
          style={{
            fontSize: "100%",
            marginRight: 4,
            color: "var(--color-secondary)",
          }}
        />
      )}
      {!!reservation.fixed && (
        <NotFixedReservationIcon
          style={{
            fontSize: "100%",
            marginRight: 4,
            color: "var(--color-tertiary)",
          }}
        />
      )}
      <strong style={{ marginRight: 4 }}>{guestName}</strong>
      {!!occassionStr && !reservation.blocked && occassionStr} •{" "}
      {!reservation.blocked && reservation.guests}{" "}
      {short && !reservation.blocked ? "P." : "Pax"}{" "}
      {!short &&
        `• Pos: ${!reservation.blocked ? reservation.position ?? "" : ""}`}
    </Box>
  );
};

export default ReservationCard;
