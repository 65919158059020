import React, { useState } from 'react';
import './styles.scss';
import Box from 'Components/Atoms/Box';
import Modal from '../../Molecules/Modal';
import { Close } from '@material-ui/icons';
import TextField from '../../Atoms/TextField';
import Button from '../../Atoms/Button';
import { Signature } from '../../../Contexts/SignatureContext';
import Typography from '../../Atoms/Typography';

interface NewSignatureModalProps {
  open: boolean;
  onClose: () => void;
  onAddSignature: (s: Signature) => Promise<void> | void;
  onAddSignatureWithPincode: (s: Signature) => void;
  validateSignature: (s: string) => boolean;
}

const NewSignatureModal = ({
  open,
  onClose,
  onAddSignature,
  onAddSignatureWithPincode,
  validateSignature,
}: NewSignatureModalProps) => {
  const [signature, setSignature] = useState<string>('');
  const [signatureError, setSignatureError] = useState<string | null>(null);
  const [name, setName] = useState<string>('');
  const [nameError, setNameError] = useState<string | null>(null);

  function addSignatureWithoutPincode() {
    if (signatureValidator() && nameValidator()) {
      onAddSignature({
        signature: signature.toUpperCase(),
        name: name,
        code: undefined,
        createdAt: new Date().valueOf(),
      });
      closeModalHandler();
    }
  }

  function addSignatureWithPincode() {
    if (signatureValidator() && nameValidator()) {
      onAddSignatureWithPincode({
        signature: signature.toUpperCase(),
        name,
        createdAt: new Date().valueOf(),
        code: undefined,
      });
      closeModalHandler();
    }
  }

  //returns true if it passes validation
  function signatureValidator(): boolean {
    if (validateSignature(signature)) {
      setSignatureError('Signature already exists.');
      return false;
    }
    if (signature.length === 0 || signature.includes(' ')) {
      setSignatureError('Invalid signature.');
      return false;
    }
    return true;
  }

  //returns true if it passes validation
  function nameValidator(): boolean {
    if (name.length < 3) {
      setNameError('Invalid name.');
      return false;
    }
    return true;
  }

  function closeModalHandler() {
    onClose();
    setSignature('');
    setName('');
    setSignatureError(null);
    setNameError(null);
  }

  return (
    <Modal
      open={open}
      onClose={closeModalHandler}
      title="Add new signature"
      titleTranslation="settings"
      headerRight={<Close onClick={onClose} />}
    >
      <Box className="new-signature-modal-container">
        <TextField
          fullWidth
          label="Enter Signature"
          labelTranslation="settings"
          value={signature}
          onChange={(event) => setSignature(event.target.value.slice(0, 5))}
        />
        {!!signatureError && (
          <Box className="pincode-error-container">
            <Typography variant="text-3" color="error" translation="settings">
              {signatureError}
            </Typography>
          </Box>
        )}
        <TextField
          fullWidth
          label="Enter Name"
          labelTranslation="settings"
          value={name}
          onChange={(event) => setName(event.target.value)}
          style={{ marginTop: '12px' }}
        />
        {!!nameError && (
          <Box className="pincode-error-container">
            <Typography variant="text-3" color="error">
              {nameError}
            </Typography>
          </Box>
        )}
        <Box className="new-signature-actions-container">
          <Button
            variant="transparent"
            translation="settings"
            onClick={closeModalHandler}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            translation="settings"
            onClick={addSignatureWithoutPincode}
          >
            Without Pin
          </Button>
          <Button
            variant="primary"
            translation="settings"
            onClick={addSignatureWithPincode}
          >
            With Pin
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default NewSignatureModal;
