import React from "react";
import Box from "Components/Atoms/Box";
import "./styles.scss";
import Typography from "Components/Atoms/Typography";

export type WeekdaysProps = {
  weekDays: number[];
  onChange: (nV: number[]) => void;
};

const allWeekDays = [
  { number: 1, label: "Mon" },
  { number: 2, label: "Tue" },
  { number: 3, label: "Wed" },
  { number: 4, label: "Thu" },
  { number: 5, label: "Fri" },
  { number: 6, label: "Sat" },
  { number: 0, label: "Sun" },
];

const WeekDays: React.FC<WeekdaysProps> = ({
  weekDays,
  onChange,
}: WeekdaysProps) => {
  const handleClick = (dayNum: number) => {
    if (!!weekDays && weekDays.includes(dayNum)) {
      onChange(weekDays.filter((wD) => wD !== dayNum));
    } else {
      onChange([...weekDays, dayNum]);
    }
  };
  return (
    <Box className="week-days-select mg-bt-md">
      <Typography variant="text-4" color="subdued">
        Repeat on these Weekdays
      </Typography>
      <Box flex>
        {allWeekDays.map((day, i) => (
          <Box
            key={i}
            onClick={() => handleClick(day.number)}
            className={
              weekDays?.includes(day.number) || false
                ? "weekDay selected"
                : "weekDay"
            }
          >
            <Typography textAlign="center" variant="text-3">
              {day.label}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default WeekDays;
