import React, { useMemo, useEffect, useState } from "react";
import Box from "Components/Atoms/Box";
import "./styles.scss";
import NavBar from "Components/Organisms/NavBar";
import FeatureNavigation from "Components/Molecules/FeatureNavigation";
import Typography from "Components/Atoms/Typography";
import Dropdown from "Components/Molecules/Dropdown";
import GTable, { ListConfigHeader } from "Components/Organisms/GTable";
import { paths } from "../GuestBook/GuestBook";
import { loadStatisticsTableData } from "api/GuestBook";
import { Guest, StatisticsDocument } from "types/guestbook-statistics";
import CuttleryIcon from "Components/Atoms/Icons/Cuttlery2";
import ChairsAndTableIcon from "Components/Atoms/Icons/ChairsAndTable";
import GuestIcon from "Components/Atoms/Icons/GuestIcon";
import useCollection from "CustomHooks/useCollection";
import Graphs from "App/GuestBook/Components/Graphs";
import { CircularProgress } from "@material-ui/core";
import Button from "Components/Atoms/Button";
import { downloadCSV } from "App/GuestBook/convertJsonToCSV";
import useToast from "CustomHooks/useToast";
import { Severity } from "Contexts/RestaurantContext";
import axios from "axios";
import ReservationsHeatmap from "App/Settings/Screens/ReservationsHeatmap";
import SiaStatistic from "App/GuestBook/Components/SiaStatistic";

export type GuestStatisticsProps = {
  restaurantId: string;
  uid: string;
  date: string;
};

export type MonthOption = {
  id: string;
  label: string;
};

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function monthHandler(num: number) {
  if (num < 10) {
    return `0${num}`;
  } else {
    return `${num}`;
  }
}

const GuestStatistics = ({ restaurantId, uid, date }: GuestStatisticsProps) => {
  const today = new Date();
  const toast = useToast();

  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [startingPage, setStartingPage] = useState<number>(1);
  const [pickedMonth, setPickedMonth] = useState<MonthOption>({
    label: "This month",
    id: `${today.getFullYear()}-${monthHandler(today.getMonth() + 1)}`,
  });

  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(25);
  const [statisticsData, setStatisticsData] = useState<StatisticsDocument>();

  const [months] = useCollection(
    `/restaurants/${restaurantId}/guestStatistics`,
    {
      limit: 12,
    }
  );

  const monthsData = useMemo(() => months.data, [months]);
  const availableMonths = useMemo(
    () => monthsData.map((m) => availableMonthsHandler(m.id)),
    [months]
  );
  const reservationStatistics = useMemo(
    () => monthsData.filter((m) => m.id === pickedMonth?.id)[0]?.resStatistics,
    [monthsData, pickedMonth]
  );

  function availableMonthsHandler(id: string) {
    const date = new Date(id);
    if (
      today.getMonth() === date.getMonth() &&
      today.getFullYear() === date.getFullYear()
    ) {
      return { id, label: "This month" };
    }
    return {
      id,
      label: `${monthNames[date.getMonth()]}, ${date.getFullYear()}`,
    };
  }

  const [loading, setloading] = useState(false);

  async function downloadStatsCSV() {
    try {
      if (loading) return;

      setloading(true);

      console.log("inside");
      toast("This may take a while. Please wait.", Severity.INFO, "settings");
      const res = await axios
        .create({ baseURL: "https://api.gastronaut.ai", timeout: 600000 })
        .get(`/v03/guests/${restaurantId}/individualStatistics`, {
          params: {
            uid: uid,
          },
        });
      console.log({ res });
      downloadCSV("", res.data);
    } catch (e) {
      console.error(e);
      toast(
        "An error occurred while trying to download the CSV file.",
        Severity.ERROR,
        "settings"
      );
    }

    setloading(false);
  }

  useEffect(() => {
    let loading = true;
    setDataLoading(loading);
    loadStatistics();
    return () => {
      loading = false;
    };
    async function loadStatistics() {
      setDataLoading(loading);
      try {
        const data = await loadStatisticsTableData(
          restaurantId,
          uid,
          pickedMonth.id,
          page - 1,
          perPage
        );
        if (!loading) {
          return;
        }
        setStatisticsData(data);
        setDataLoading(false);
      } catch (e) {
        console.error("Error while loading table data: ", e);
      }
    }
  }, [restaurantId, pickedMonth, page, perPage]);

  const tableHeaders: ListConfigHeader<Guest>[] = [
    {
      field: "n",
      headerName: "Name",
    },
    {
      field: "e",
      headerName: "Email",
    },
    {
      field: "p",
      headerName: "Phone number",
    },
    {
      field: "r.done",
      headerName: "Successful reservations",
      displayFunction: (profile) => (
        <>{profile.r.filter((x) => x.done).length}</>
      ),
    },
    {
      field: "r.ns",
      headerName: "No shows",
      displayFunction: (profile) => <>{profile.r.filter((x) => x.ns).length}</>,
    },
    {
      field: "canceled",
      headerName: "Canceled reservations",
      displayFunction: (profile) => (
        <>{profile.r.filter((x) => !x.done).length}</>
      ),
    },
  ];

  return (
    <Box background id="guest-statistics">
      <NavBar
        title="Statistics"
        mainProduct={{
          label: "Guestbook",
          link: (rId) => `/${rId}/guestbook`,
        }}
        right={
          <Button
            className="download-csv-button"
            variant="primary"
            translation="settings"
            loading={loading}
            onClick={() => downloadStatsCSV()}
          >
            Download CSV
          </Button>
        }
        className="fixed-nav"
      />
      <FeatureNavigation
        date={date}
        paths={paths}
        current="statistics"
        className="featureNavigation"
      />
      <Box className="guest-statistics-container">
        <Box className="guest-statistics-header-container">
          <Box className="guest-statistics-title-container">
            <Typography variant="h5" color="textPrimary" translation="settings">
              Top guests of the month
            </Typography>
            <Typography variant="text-3" color="subdued" translation="settings">
              Here you can see top guests of the month and their reservation
              statistics as well as personal information.
            </Typography>
          </Box>
          <Dropdown
            value={pickedMonth.label}
            options={availableMonths}
            onChange={(monthId: string) =>
              setPickedMonth(availableMonthsHandler(monthId))
            }
            buttonProps={{ variant: "outlined" }}
          />
        </Box>
        <SiaStatistic month={date} restaurantId={restaurantId} />
        {dataLoading ? (
          <Box
            className="loading-stats-container"
            style={{ textAlign: "center" }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <GTable
            data={statisticsData?.guests ?? []}
            headers={tableHeaders}
            startingPage={startingPage}
            setStartingPage={setStartingPage}
            serversidePagination={{
              total: statisticsData?.total ?? 0,
              page: page,
              setPage: setPage,
              perPage: perPage,
              setPerPage: setPerPage,
            }}
          />
        )}
        <Box className="reservation-statistics-container">
          <Typography variant="h5" color="textPrimary" translation="settings">
            Reservation Statistics
          </Typography>
          <Box className="short-stats-container">
            <Box className="short-stat-container">
              <CuttleryIcon />
              <Box className="short-stat-text-container">
                <Typography
                  variant="text-3"
                  color="subdued"
                  translation="settings"
                >
                  Total reservations
                </Typography>
                <Typography
                  variant="h4"
                  color="textPrimary"
                  translation="settings"
                >
                  {months?.data?.find((i) => i.id === pickedMonth.id)
                    ?.resStatistics?.reservations ?? "No data"}
                </Typography>
              </Box>
            </Box>
            <Box className="short-stat-container">
              <ChairsAndTableIcon />
              <Box className="short-stat-text-container">
                <Typography
                  variant="text-3"
                  color="subdued"
                  translation="settings"
                >
                  Total seatings
                </Typography>
                <Typography variant="h4" color="textPrimary">
                  {months?.data?.find((i) => i.id === pickedMonth.id)
                    ?.resStatistics?.status.success ?? "No data"}
                </Typography>
              </Box>
            </Box>
            <Box className="short-stat-container">
              <GuestIcon />
              <Box className="short-stat-text-container">
                <Typography
                  variant="text-3"
                  color="subdued"
                  translation="settings"
                >
                  Individual guests
                </Typography>
                <Typography variant="h4" color="textPrimary">
                  {months?.data?.find((i) => i.id === pickedMonth.id)
                    ?.resStatistics?.total ?? 0}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Graphs
            restaurantId={restaurantId}
            reservationStatistics={reservationStatistics}
          />
        </Box>
        {/* <Box className="reservations-heatmap-wrapper">
          <ReservationsHeatmap />
        </Box> */}
      </Box>
    </Box>
  );
};

export default GuestStatistics;
