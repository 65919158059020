import React from 'react';
import useLanguage from 'CustomHooks/useLanguage';
import Box from '../../../../../Components/Atoms/Box';
import Typography from 'Components/Atoms/Typography';
import { EventDescription } from 'App/Calendar/Components/EventSettings';
import { toCurrencyString } from 'utils/helper';
import { ExtendedEvent } from '..';

const AddOnCard: React.FC<{
  addOn: {
    title: string;
    description: string;
    count: number;
    total: number;
  };
  onClick: () => void;
  selected?: boolean;
}> = ({ addOn, onClick, selected = false }) => {
  return (
    <Box
      style={{
        padding: 10,
        borderRadius: 4,
        marginBottom: 8,
        background: selected
          ? 'var(--color-disabled)'
          : 'var(--color-btn-default)',
      }}
      onClick={onClick}
    >
      <Typography
        block
        color="textPrimary"
        variant="h5"
        style={{ marginBottom: 3 }}
      >
        {addOn.title}
      </Typography>
      <Typography
        component="p"
        color="subdued"
        variant="text-3"
        style={{
          justifySelf: 'flex-start',
          alignSelf: 'flex-start',
          marginBottom: 6,
        }}
      >
        {addOn.description}
      </Typography>
      <Typography
        component="p"
        variant="text-3"
        style={{
          justifySelf: 'flex-start',
          alignSelf: 'flex-start',
          marginBottom: 2,
        }}
      >
        Verkäufe: {addOn.count} (
        {toCurrencyString(addOn.total ?? 0, '€', false, 1)})
      </Typography>
    </Box>
  );
};

export default AddOnCard;
