import { useDrag } from 'CustomHooks/useDragAndDrop';
import React from 'react';
import { DragPreviewImage } from 'react-dnd';
import { CurrentStatus } from 'types/reservations';
import { blockToTime, cubicBezier, maxMin } from 'utils/helper';

type Props = {
  color: string;
  textColor: string;
  time: string;
  guests?: number;
  status?: CurrentStatus;
  top?: number;
  bottom?: number;
  size: number;
  touching?: string[];
  additional?: any;
  id: string;
  from: number;
  till: number;
  showGuestsAlways?: boolean;
};

const Time: React.FC<Props> = ({
  color: backgroundColor,
  touching = [],
  additional = '',
  textColor: color,
  time,
  top,
  bottom,
  size,
  guests,
  id,
  from,
  till,
  showGuestsAlways,
}) => {
  let fontSize = maxMin(size * 1.5, 8, 14);

  const { drag, opacity, preview } = useDrag<{
    id: string;
    from: number;
    till: number;
    guests: number;
  }>(
    {
      id,
      from,
      till,
      guests: guests || 0,
    },
    'reservation'
  );

  return (
    <>
      {/* {!!preview && (
        <DragPreviewImage
          connect={preview}
          src={`data:image/svg+xml;utf8,<svg width="131" height="23" viewBox="0 0 131 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="131" height="23" rx="4" fill="#6369D1"/><text x="4" y="4" textLength="123" >${time} - ${blockToTime(
            till
          )} | ${guests} PAX</text></svg>`}
        />
      )} */}
      <span
        className="time"
        style={{
          top,
          bottom,
          backgroundColor,
          color,
          fontSize,
          opacity,
          lineHeight: `${fontSize}px`,
        }}
        ref={preview}
      >
        <span
          ref={drag}
          style={{
            position: 'absolute',
            left: '-30%',
            top: '-50%',
            width: '160%',
            height: '200%',
            cursor: 'grab',
          }}
        ></span>
        {time}
        {size > 7 || showGuestsAlways ? ` ${guests} P.` : ''}
      </span>
    </>
  );
};

export default Time;
