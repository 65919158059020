import { ReservationContext } from "Contexts/ReservationContext";
import { RestaurantContext } from "Contexts/RestaurantContext";
import { useContext, useEffect, useMemo, useState } from "react";
import { SpecialDay } from "types/calendar";
import { ReservationStatus } from "types/reservations";
import { Date as DateType } from "types/shifts";
import { db } from "utils/firebase";
import { dateHelper, findOverlaps, reservationToSnippet } from "utils/helper";
import { RichShift } from "utils/types";

const useStatistics = (dateToModify?: string, update?: () => void) => {
  const { restaurantId } = useContext(RestaurantContext);
  const {
    reservations,
    date: contextDate,
    shifts,
    occassions = [],
  } = useContext(ReservationContext);

  const [openModal, setOpenModal] = useState<string | null>(null);

  const date = openModal ?? contextDate ?? dateHelper();

  const [dateData, setDateData] = useState<DateType | null>(null);

  useEffect(() => {
    const getDateData = async () => {
      if (!restaurantId || !date) return;
      const datesRef = await db
        .collection(`restaurants/${restaurantId}/reservationDates`)
        .doc(date)
        .get();
      if (datesRef.exists) {
        setDateData(datesRef.data() as DateType);
      }
    };
    getDateData();
  }, [date, openModal, restaurantId]);

  const [specialDay, setspecialDay] = useState<null | SpecialDay>(null);

  const [averagePerWDay, setAveragePerWDay] = useState<null | {
    [key: number]: number[];
  }>(null);

  const reservationSnippets = useMemo(
    () =>
      reservations.filter((r) => !r.blockedFullShift).map(reservationToSnippet),
    [reservations]
  );

  const { richShifts, otherReservations } = useMemo(() => {
    let richShifts: RichShift[] = [];
    let resaIds: string[] = [];

    (shifts ?? []).forEach((s) => {
      let filteredRes = reservations.filter(
        (r) =>
          !r.blockedFullShift &&
          (s.occassions?.includes(r.occassion) ||
            s.spaces?.includes(r?.space ?? "")) &&
          findOverlaps(r, s.start || 0, s.close || 0, true) &&
          r.status !== ReservationStatus.FAILED
      );

      richShifts.push({
        ...s,
        reservations: filteredRes.map(reservationToSnippet),
      });

      resaIds.push(...filteredRes.map((r) => r.id));
    });

    let otherReservations = reservations
      .filter(
        (r) => !resaIds.includes(r.id) && r.status !== ReservationStatus.FAILED
      )
      .map(reservationToSnippet);

    return { richShifts, otherReservations };
  }, [reservations, shifts]);

  const reservationsByOccassions = useMemo(() => {
    return occassions.map((occ) => ({
      value: reservationSnippets
        .filter(
          (res) =>
            res.occassion === occ.id && res.status !== ReservationStatus.FAILED
        )
        .reduce((acc: number, cV) => acc + cV.guests, 0),
      label: occ.title,
      id: occ.id,
    }));
  }, [reservationSnippets, occassions]);

  const reservationBySource = useMemo(() => {
    let sourceTable = reservationSnippets
      .filter((res) => res.status !== ReservationStatus.FAILED)
      .reduce((acc: any, cV) => {
        if (!acc[cV.source]) {
          acc[cV.source] = cV.guests;
        } else {
          acc[cV.source] += cV.guests;
        }

        return acc;
      }, {});

    return Object.keys(sourceTable).map((id) => ({
      id,
      label: id,
      value: sourceTable[id],
    }));
  }, [reservationSnippets]);

  useEffect(() => {
    if (!restaurantId) return;
    const getAverage = async () => {
      const statRef = await db
        .collection(`restaurants/${restaurantId}/statistics`)
        .doc("byWeekday")
        .get();

      if (statRef.exists) {
        setAveragePerWDay(
          statRef.data() as {
            [key: number]: number[];
          }
        );
      }
    };
    getAverage();
  }, [restaurantId]);

  const dateAvg = useMemo(() => {
    const dateDay = new Date(date).getDay();
    const dayArr = averagePerWDay ? averagePerWDay[dateDay] : null;
    return dayArr
      ? Math.floor(
          dayArr.reduce((acc: number, num: number) => (acc += num), 0) /
            dayArr.length
        )
      : null;
  }, [date, averagePerWDay]);

  const shiftOptions = useMemo(() => {
    return [
      { id: null, label: "All day" },
      ...(dateData?.shifts
        ?.sort((a, b) => (a?.start || 0) - (b?.start || 0))
        .map((s) => ({ id: s.id, label: s.name || "" })) || []),
    ];
  }, [dateData?.shifts]);

  useEffect(() => {
    if (!restaurantId || !date) return;
    const getCalendar = async () => {
      if (restaurantId) {
        const calendarRef = await db
          .collection(`restaurants/${restaurantId}/calendarSheets`)
          .doc(date.slice(0, 7))
          .get();

        if (calendarRef.exists) {
          const data = calendarRef.data();
          const specialDayOnDate = data?.specialDays.find(
            (sD: SpecialDay) => sD.date === date
          );
          setspecialDay(specialDayOnDate || null);
        }
      }
    };
    getCalendar();
  }, [date, restaurantId]);

  const isOpen = useMemo(() => {
    if (!shifts?.length) return false;

    let closedOccassions: Record<string, 0 | 1> =
      shifts?.reduce((acc, cV) => {
        return {
          ...acc,
          ...cV.occassions?.reduce(
            (acc1, id) => ({
              ...acc1,
              [id]: cV.closed ? 0 : acc[id] === 0 || 1,
            }),
            {}
          ),
        };
      }, occassions.reduce((acc, cV) => ({ ...acc, [cV.id]: 1 }), {}) as Record<string, 0 | 1>) ??
      {};

    // console.log({ closedOccassions, shifts });

    return !!Object.keys(closedOccassions).filter(
      (x) => closedOccassions?.[x] === 1
    ).length;
  }, [shifts, occassions]);

  // console.log({ specialDay, isOpen });

  const submitDateData = async () => {
    if (!dateData?.date) return;
    await db
      .collection(`restaurants/${restaurantId}/reservationDates`)
      .doc(dateData.date)
      .set(dateData);
    if (update) update();
    setDateData(null);
    setOpenModal(null);
  };

  return {
    date,
    dateAvg,
    shiftOptions,
    isOpen,
    richShifts,
    reservationsByOccassions,
    reservationBySource,
    reservationSnippets,
    dateData,
    setDateData,
    submitDateData,
    openModal,
    setOpenModal,
    otherReservations,
  };
};

export default useStatistics;
