import React from "react";
import Box from "Components/Atoms/Box";
import { Reservation } from "types/reservations";
import Typography from "Components/Atoms/Typography";
import { Cached } from "Components/Atoms/Icons";

export type SwitchReservationInfosProps = {
  reservations: Reservation[];
};

const SwitchReservationInfos = ({
  reservations,
}: SwitchReservationInfosProps) => {
  return (
    <>
      <Typography variant="text-2" translation="reservations">
        Do you really want to switch these Reservations?
      </Typography>
      <Box flex className="space-between" style={{ margin: "16px 0" }}>
        <Box padding="sm" outline flex className="space-between">
          <Typography variant="text-3">{reservations[0].guest.name}</Typography>
          <Typography variant="text-3" color="subdued" translation={null}>
            {reservations[0].time} - {reservations[0].guests} Pax -{" "}
            {reservations[0].tables?.join(", ") ?? ""}
          </Typography>
        </Box>
        <Cached style={{ margin: 12 }} />
        <Box padding="sm" outline flex className="space-between">
          <Typography variant="text-3">{reservations[1].guest.name}</Typography>
          <Typography variant="text-3" color="subdued" translation={null}>
            {reservations[1].time} - {reservations[1].guests} Pax -{" "}
            {reservations[1].tables?.join(", ") ?? ""}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default SwitchReservationInfos;
