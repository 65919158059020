import React, { useMemo, useState } from "react";
import { createPortal } from "react-dom";
import Box from "Components/Atoms/Box";
import "./styles.scss";
import Typography from "../../../../Components/Atoms/Typography";
import {
  Create,
  Done,
  Lock,
  NotInterested,
  Star,
  Comment,
  LocalActivity,
  Moped,
  ShoppingBasket,
} from "Components/Atoms/Icons";
import { CalendarShift } from "../Week";
import { blockToTime, getTime } from "utils/helper";
import { ActiveShift } from "CustomHooks/useCalendarDates";
import { AnyShift } from "gastronaut-shared/types/helper/shifts";

interface MultipleShiftsCardProps {
  id: string;
  shift: CalendarShift;
  overlappingShifts: CalendarShift[];
  top?: number;
  selected: boolean;
  setSelected: (shift: CalendarShift) => void;
  width: number;
  isOverlapping?: boolean;
  handleCardClick?: () => void;
  onShiftPreviewClick?: (data: { date: string; id: string }) => void;
}

const Tooltip: React.FC<{
  hoverState: CalendarShift & { position: DOMRect };
  index: number;
  id: string;
}> = ({ hoverState, index, id }) => {
  return createPortal(
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: 100,
        position: !hoverState.position ? "absolute" : "fixed",
        top: (hoverState?.position?.top ?? 0) + 30,
        left: !!hoverState?.position?.left
          ? hoverState.position.left
          : -5 - index * 20,
        padding: 5,
        borderRadius: 5,
        zIndex: 100000,
        background: "var(--color-bg)",
        overflow: "visible",
        border: "1px solid var(--color-text)",
      }}
    >
      <Typography variant="text-4">{hoverState.name}</Typography>
      <Typography variant="text-4">
        {blockToTime(hoverState.start)} - {blockToTime(hoverState.close)}
      </Typography>
    </div>,
    document.getElementById(!!hoverState.position ? "root" : id) as any
  );
};

const MultipleShiftsCard = ({
  id,
  shift,
  overlappingShifts,
  top = 0,
  selected = false,
  setSelected,
  width,
  isOverlapping = false,
  handleCardClick,
  onShiftPreviewClick = () => {},
}: MultipleShiftsCardProps) => {
  const [hoverState, setHoverState] = useState<
    (CalendarShift & { position: DOMRect }) | null
  >(null);
  console.log({ selected });
  const isShiftDone =
    Date.now() > getTime(shift.date, shift.close, shift.start);

  const shiftLength =
    shift.close !== null && shift.start !== null
      ? Math.max(shift.close - shift.start, 1)
      : 24 * 4;

  const shiftStart = shift.start ? blockToTime(shift.start, false) : "";
  let shiftClose = shift.close ? blockToTime(shift.close, false) : "";

  if (shiftClose === "00:00") {
    shiftClose = "24:00";
  }

  const { border, background, opacity, commentOnly, color } = useMemo(() => {
    const commentOnly = !!shift.commentOnly;

    let borderColor = selected
      ? "var(--color-text)"
      : isOverlapping
      ? "var(--color-bg)"
      : "var(--color-bg)";

    let background = commentOnly
      ? "var(--color-bg)"
      : shift.color ?? "var(--color-primary)";

    let color = "var(--color-text)";

    return {
      border: `${
        isOverlapping && !selected ? "2px" : "3px"
      } solid ${borderColor}`,
      background,
      opacity: shift.disabled || !shift.active ? 0.5 : 1,
      commentOnly,
      color,
    };
  }, [shift, selected]);

  const shortShift = shiftLength < 13;

  return (
    <Box
      id={id}
      className="shift-container multiple"
      onClick={handleCardClick}
      style={{
        width,
        background: "var(--color-btn-default)",
        border,
        opacity,
        color,
        overflow: "scroll",
        height: shiftLength * 8 - 3 - Math.max(90 - top, 0),
        // border: selected ? "1px solid var(--color-gray-2)" : "none",
      }}
    >
      <Box className="upper-container">
        <Box
          flex
          className="title-container"
          style={{ flexDirection: isOverlapping ? "column" : "row" }}
        >
          <Typography
            className="primary-text"
            variant="text-4"
            color="inherit"
            translation="calendar"
            style={{
              fontWeight: "bolder",
              maxHeight: "2rem",
              overflow: "hidden",
            }}
          >
            {shift.name
              ? `${shift.name} + ${overlappingShifts.length - 1} more`
              : "Multiple Shifts"}
          </Typography>
        </Box>
        <Typography
          className="secondary-text"
          variant="text-4"
          color="inherit"
          style={{
            opacity: 0.6,
            marginBottom: 4,
            whiteSpace: shortShift ? "nowrap" : "normal",
          }}
        >
          {shiftStart} - {shiftClose}
        </Typography>
        <Box className="overlapping-shifts-row-container">
          {overlappingShifts.map((s, index) => (
            <div
              // todo
              // take a look at the odd behaviour then include it back
              // onClick={() => setSelected(s)}
              onMouseOver={(e) => {
                let position = e.currentTarget?.getBoundingClientRect();
                setHoverState({ ...s, position });
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onShiftPreviewClick(s);
              }}
              onMouseLeave={() => setHoverState(null)}
              style={{
                position: "relative",
                width: 24,
                height: 24,
                borderRadius: 12,
                marginRight: 8,
                marginBottom: 8,
                border: "2px solid black",
                background: s.color,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 2,
                cursor: "pointer",
              }}
            >
              {
                [
                  commentOnly && (
                    <Comment
                      width={8}
                      height={8}
                      style={{
                        display: isOverlapping ? "block" : "inline-block",
                        maxWidth: 14,
                      }}
                    />
                  ),
                  isShiftDone && (
                    <Done width={8} height={8} style={{ maxWidth: 14 }} />
                  ),
                  !!s?.overwrite?.blockReservations && (
                    <Lock
                      width={8}
                      height={8}
                      style={{
                        display: isOverlapping ? "block" : "inline-block",
                        maxWidth: 14,
                      }}
                    />
                  ),
                  s.edited && (
                    <Create
                      width={8}
                      height={8}
                      style={{
                        display: isOverlapping ? "block" : "inline-block",
                        maxWidth: 14,
                      }}
                    />
                  ),
                  !!s.closed && (
                    <NotInterested
                      width={8}
                      height={8}
                      style={{
                        display: isOverlapping ? "block" : "inline-block",
                        maxWidth: 14,
                      }}
                    />
                  ),
                  !s.regular && !commentOnly && (
                    <Star
                      width={8}
                      height={8}
                      style={{
                        display: isOverlapping ? "block" : "inline-block",
                        maxWidth: 14,
                      }}
                    />
                  ),

                  !!s?.additional?.eventV02?.active && (
                    <LocalActivity
                      width={8}
                      height={8}
                      style={{
                        display: isOverlapping ? "block" : "inline-block",
                        minWidth: 12,
                        maxWidth: 14,
                      }}
                    />
                  ),
                  s?.deliveryMethods?.includes("delivery") && (
                    <Moped
                      width={8}
                      height={8}
                      style={{
                        display: isOverlapping ? "block" : "inline-block",
                        minWidth: 12,
                        maxWidth: 14,
                      }}
                    />
                  ),
                  s?.deliveryMethods?.includes("pickup") && (
                    <ShoppingBasket
                      width={8}
                      height={8}
                      style={{
                        display: isOverlapping ? "block" : "inline-block",
                        fontSize: 12,
                        maxWidth: 14,
                      }}
                    />
                  ),
                  !!s?.advert?.active && (
                    <LocalActivity
                      width={8}
                      height={8}
                      style={{
                        display: isOverlapping ? "block" : "inline-block",
                        minWidth: 12,
                        maxWidth: 14,
                      }}
                    />
                  ),
                ].filter((x) => !!x)?.[0]
              }
              {hoverState && hoverState.id === s.id && (
                <Tooltip hoverState={hoverState} index={index} id={id} />
              )}
            </div>
          ))}
        </Box>
      </Box>
      <Box className="lower-container">
        <Typography
          className="primary-text"
          color="inherit"
          variant="text-4"
          textAlign="right"
          weight="bold"
        >
          {overlappingShifts.length} Shifts
        </Typography>
      </Box>
    </Box>
  );
};

export default MultipleShiftsCard;
