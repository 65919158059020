import Typography from 'Components/Atoms/Typography';
import React from 'react';

const TableHeader: React.FC<{ top?: number }> = ({ top }) => {
  return (
    <tr className="table-header" style={{ top }}>
      <td>
        <Typography variant="text-2" translation="feedback">
          Done
        </Typography>
      </td>
      <td>
        <Typography variant="text-2" translation="feedback">
          Name
        </Typography>
      </td>
      <td>
        <Typography variant="text-2" translation="feedback">
          Date
        </Typography>
      </td>
      <td>
        <Typography variant="text-2" translation="feedback">
          Time
        </Typography>
      </td>
      <td>
        <Typography variant="text-2" translation="feedback">
          Table
        </Typography>
      </td>
      <td>
        <Typography variant="text-2" translation="feedback">
          Guests
        </Typography>
      </td>
      <td>
        <Typography variant="text-2" translation="feedback">
          Stars
        </Typography>
      </td>
      <td>
        <Typography variant="text-2" translation="feedback">
          Contact
        </Typography>
      </td>
      <td>
        <Typography variant="text-2" translation="feedback">
          Comment
        </Typography>
      </td>
    </tr>
  );
};

export default TableHeader;
