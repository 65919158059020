import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

const TerraceClosed = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M1.93945 8.9531C5.27986 6.60513 8.62027 4.26322 11.9728 1.93945C15.3253 4.26927 18.6657 6.60513 22.0001 8.9531C18.9864 8.97731 15.9728 8.94705 12.9592 8.96521C12.935 13.3223 12.9713 17.6793 12.941 22.0303C12.2996 22.0364 11.6521 22.0424 11.0046 22.0424C10.9743 17.6854 11.0046 13.3283 10.9924 8.97126C7.97277 8.94705 4.95913 8.97126 1.93945 8.9531ZM8.28139 6.93796C10.7443 6.94402 13.2012 6.95007 15.6581 6.93191C14.4478 6.0605 13.2133 5.20725 11.9728 4.37214C10.7322 5.20725 9.49773 6.0605 8.28139 6.93796Z"
        fill="currentColor"
      />
      <path
        d="M2.10303 12.3963C2.73843 12.2571 3.37384 12.1179 4.00924 11.9788C4.28156 13.3282 4.55992 14.6717 4.84434 16.0211C6.20592 16.0211 7.57355 16.0211 8.94119 16.0211C8.95329 18.0302 8.95934 20.0393 8.93513 22.0423C8.26342 22.0363 7.59776 22.0363 6.9321 22.0242C6.9321 20.6989 6.9321 19.3676 6.9321 18.0423C6.26039 18.0423 5.58867 18.0423 4.91696 18.0423C4.91091 19.3737 4.91091 20.705 4.91091 22.0363C4.23919 22.0363 3.56748 22.0363 2.89577 22.0423C2.99259 18.7988 2.91392 15.5552 2.10303 12.3963Z"
        fill="currentColor"
      />
      <path
        d="M19.9362 11.9788C20.5716 12.1179 21.207 12.2571 21.8363 12.3963C21.0194 15.5552 20.977 18.8048 21.0376 22.0484C20.3658 22.0423 19.7002 22.0302 19.0345 22.0242C19.0345 20.6929 19.0345 19.3676 19.0285 18.0423C18.3568 18.0363 17.685 18.0363 17.0194 18.0363C17.0133 19.3676 17.0133 20.6989 17.0133 22.0302C16.3477 22.0363 15.676 22.0423 15.0103 22.0484C14.9861 20.0393 14.9921 18.0302 15.0042 16.0211C16.3719 16.0211 17.7395 16.0211 19.1071 16.0211C19.3855 14.6717 19.6639 13.3282 19.9362 11.9788Z"
        fill="currentColor"
      />
      <line
        x1="22.7071"
        y1="2.70711"
        x2="2.70711"
        y2="22.7071"
        stroke="currentColor"
        stroke-width="2"
      />
    </SvgIcon>
  );
};

export default TerraceClosed;
