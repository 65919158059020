import { Event } from './event';
import {
  Reservation as ReservationType,
  ReservationGuest,
  Occassion as OccassionTypeFromShared,
  CustomDataValue,
} from 'gastronaut-shared/types/helper/reservations';
import {
  TicketDocument,
  WaitingListEntryDocument,
} from 'gastronaut-shared/types/documents';
import { AddOnOrder } from 'App/Experiences/types/addOnOrder';
import { Ticket } from 'gastronaut-shared/types/documents/restaurants/event';

export enum ReservationStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
  POSITIVE = 'positive',
  PENDING = 'pending',
  NEGATIVE = 'negative',
}

export enum CurrentStatus {
  NORMAL = 'normal',
  DONE = 'done',
  FAILED = 'failed',
  UPCOMING = 'upcoming',
  OVERDUE = 'overdue',
  SEATED = 'seated',
  HAS_ORDERED = 'hasOrdered',
  PAID = 'paid',
}

export type Guest = ReservationGuest & {
  guestId?: string;
  createNew?: boolean;
};

export type Reservation = ReservationType & {
  id: string;
  currentStatus: CurrentStatus; // Added by Context
  position?: number; // Added by Context
  startTimeInBlocks: number;
  endTimeInBlocks: number;
  reservationLength: number;
  warnings?: ({ id: string; message: string } | string)[];
  additional?: {
    type: 'event';
    eventId: string;
    amount: number;
    selectedOptions?: Record<string, number>;
    options: { id: string; internalName: string }[];
  };
  previousTables?: string[] | null;
  noShowConfirmation?: {
    validTill: number;
    sendVia: 'sms' | 'email';
  };
  startedAt?: number;
  partlySeated?: boolean;
  ignoreWarnings?: Record<string, boolean>;
  group?: string;
  orders?: (AddOnOrder & { id: string })[];
  hasPayment?: boolean;
  ticket?: Ticket | null;
  addOnItems?: {
    id: string;
    line1: string;
    line2: string;
    price: number;
    refunded: boolean;
    payAtRestaurant?: boolean | undefined;
  }[];
  ticketData?: Record<string, number>;
  addOnData?: Record<string, number>;
};

export type LightReservation = Omit<Reservation, 'tables'> & {
  tableString: string;
};

export type Combination = {
  tables: string[];
  expression: string | false;
  priority: number;
  reservationLength: number;
  space: string;
  alias: string[];
};

export type TimeSlot = {
  block: number;
  blockedSlots: number;
  combinations: Combination[];
  maxGuestsReached: boolean;
  time: string;
  reservationLength: number;
  shorterReservationLength?: boolean;
  totalSlots: number;
  waitinglist?: boolean;
};

export type EditReservation = {
  new?: boolean;
  keepTables?: boolean;
  waitinglistEntry?: WaitingListEntryDocument;
  customData?: Record<string, CustomDataValue>;
  fixed?: boolean;
  date: string;
  timeSlot: null | TimeSlot;
  time?: string;
  space?: string;
  tables: null | string[];
  tableStr?: string | null;
  reservationLength?: number;
  reservationId?: string;
  occassion?: string;
  guests?: number;
  guest: null | (ReservationGuest & { createNew?: boolean });
  walkin?: boolean;
  page?: number;
  waiterSignature?: string;
  customId?: string;
  sendEmail?: boolean;
  clickCount?: number;
  lastTable?: string[] | null;
  sendNoShowSMS?: boolean;
  sendConfirmationEmail?: boolean;
  addToWaitinglist?: null | {
    time: string;
    flexibleTime?: null | { earliest: string; latest: string };
    preferredFormOfContact?: 'call' | 'whatsapp';
  };
  changedTablePerHandBefore?: boolean;
  originalUrl?: string;
};

export type Table = {
  id: string;
  name: string;
  coords: {
    direction: number;
    size: number;
    y: number;
    x: number;
  };
  space: string;
  windowTable?: boolean;
  occassions: string[];
  attributes?: string[];
  type: string;
  priority: number;
  expression: string;
  min: number;
  max: number;
  alias: string | null;
};

export type Space = {
  id: string;
  name: string;
  description: string;
  outdoor?: boolean;
  offset: {
    x: number;
    y: number;
  };
  size: {
    height: number;
    width: number;
  };
};

export type Decoration = {
  id: string;
  coords: {
    x: number;
    y: number;
    size: number;
    direction: number;
    height?: number;
    width?: number;
  };
  space: string;
  text: string;
  type: string;
  color: string;
};

export type Wall = {
  id: string;
  coords: {
    x: number;
    y: number;
    direction: number;
    height: number;
    width: number;
  };
  space: string;
  type: string;
};

export type TableCombination = {
  id: string;
  primaryString: string;
  secondaryString: string;
  priority: number;
  occassions: string[];
  space: string;
  tables: string[];
  attributes?: string[];
  min: number;
  max: number;
  expression: boolean | string;
  alias: string[];
};

export type Background = {
  spaceId: string;
  floorPlanId?: string;
  imageUrl: string;
  imageUrlDM?: string;
  invert?: number;
  width: number;
  height: number;
  factor: number; // defaults to 10
  offset?: {
    x: number;
    y: number;
  };
};

export type Backgrounds = Record<string, Background>;

export type FloorPlan = {
  id: string;
  deleted?: boolean;
  name: string;
  description: string;
  icon: null | string;
  active: boolean;
  standard: boolean;
  spaces: Space[];
  tables: Table[];
  tableCombinations: TableCombination[];
  decorations: Decoration[];
  walls: Wall[];
  updatedAt: number;
  publishedAt: number;
  backgrounds?: Backgrounds;
};

export type ResSettings = {
  hideResLength: boolean;
  hideOccassion: boolean;
};

export enum WaitingListStatus {
  PENDING = 'pending',
  NOTIFIED = 'notified',
  SEATED = 'seated',
  REMOVED = 'removed',
  CANCELED = 'canceled',
}

export type WaitingList = {
  id: string;
  restaurant: string;
  date: string;
  time: string;
  guests: number;
  occassion: string;
  guest: Guest;
  createdAt: number;
  status: WaitingListStatus;
  notified?: null | {
    timeStamp: number;
    time: string;
    occassion: string;
    tables?: string[];
    comment?: string;
    validTill?: number;
  };
};

export type Warning = {
  id: string;
  type: 'reservation';
  text?: string;
  cancelable?: boolean;
  tables: string[];
};

export type Occassion = OccassionTypeFromShared;

export interface DragTable extends Table {
  direction: number;
  updatedAt: number;
  style: {
    height: number;
    width: number;
    display: string;
    justifyContent: string;
    alignItems: string;
  };
  active: boolean;

  rotatable: boolean;
  position: {
    x: number;
    y: number;
    factor: number;
  };
  size: number;
  type: string;
  name: string;
  dots?: string[];
  y: number;
  x: number;
  blocked?: boolean;
}

export enum OccassionType {
  DEFAULT = 0,
  FOOD = 1, // Essen, Beides, Sushi, Abendessen,
  DRINKS_ONLY = 2, // Nur Getränke, Cocktails, zum trinken, ...
  TICKET = 3,
  BREAKFAST = 4,
  LUNCH = 5,
  BRUNCH = 6,
  DINNER = 7,
  LIVE_MUSIC = 8,
}
