import React, { useState } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import Sidebar from 'Components/Molecules/Sidebar';
import { ActiveShift } from 'CustomHooks/useCalendarDates';
import { TakeAwayShift } from 'types/shifts';
import { Menu, MenuDraft } from 'types/menu';
import Button from 'Components/Atoms/Button';
import CheckCircle from 'Components/Atoms/Icons/CheckCircle';
import TextField from 'Components/Atoms/TextField';
import ShiftColor from 'App/Settings/Components/ShiftColor';
import Typography from 'Components/Atoms/Typography';
import SwitchBox from 'Components/Atoms/Switch';
import DateRange from 'App/Settings/Components/DateRange';
import WeekDays from 'App/Settings/Components/Weekdays';
import Select from 'Components/Atoms/Select';
import { blockToTime } from 'utils/helper';
import AdvertSettings from 'App/Settings/Components/AdvertSettings';
import { OrderMethod, TakeAwayAdvert } from 'types/takeAway';
import { IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

export type NewEditTakeAwayShiftProps = {
  activeShift: ActiveShift<TakeAwayShift>;
  setactiveShift: React.Dispatch<
    React.SetStateAction<ActiveShift<TakeAwayShift>>
  >;
  onClose: VoidFunction;
  onSubmit: () => Promise<void>;
  menues: Menu[];
};

const TIMES = Array.from(Array(4 * 28 + 1), (_, i) => ({
  id: i,
  value: i,
  label: blockToTime(i),
}));

const NewEditTakeAwayShift: React.FC<NewEditTakeAwayShiftProps> = ({
  activeShift,
  onClose,
  onSubmit,
  setactiveShift,
  menues,
}) => {
  const [loading, setloading] = useState(false);
  const [hasNoName, sethasNoName] = useState<boolean>(false);
  const [openAdvertModal, setopenAdvertModal] = useState<boolean>(false);

  const hasTimeError: () => string = () => {
    if (
      activeShift?.shift.start === undefined ||
      activeShift?.shift.close === undefined ||
      activeShift?.shift.service === undefined
    ) {
      return 'All times have to be set';
    } else if (activeShift?.shift.start > activeShift?.shift.close) {
      return 'Start of Shift has to be before end of Shift';
    } else if (activeShift?.shift.service > activeShift?.shift.close) {
      return 'Last Reservation has to be before end of Shift';
    } else if (activeShift?.shift.start > activeShift?.shift.service) {
      return 'Last Reservation has to be after start of Shift';
    }

    return '';
  };

  const deliveryMethodsOptions: OrderMethod[] = [
    OrderMethod.PICKUP,
    OrderMethod.DELIVERY,
  ];

  const handleSubmit = async () => {
    if (hasTimeError()) return;
    if (!activeShift?.shift.name) {
      sethasNoName(true);
      return;
    }
    if (hasNoName) return;
    setloading(true);
    await onSubmit();
    setloading(false);
  };

  const handleNameChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    let name = e.target.value;
    if (!name) {
      sethasNoName(true);
    } else {
      sethasNoName(false);
    }
    setactiveShift((aS) =>
      !!aS ? { ...aS, shift: { ...aS.shift, name } } : null
    );
  };

  const handleStatusChange: React.ChangeEventHandler<{
    name?: string | undefined;
    value: unknown;
  }> = (e) => {
    let { value } = e.target;
    setactiveShift((aS) =>
      !!aS
        ? {
            ...aS,
            shift: {
              ...aS.shift,
              closed: value === 'closed',
              ...(value === 'closed'
                ? { allDay: true, start: 0, close: 96, service: 96 }
                : { allDay: false }),
            },
          }
        : null
    );
  };

  const handleTimeChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    c: React.ReactNode
  ) => {
    let { name = '', value } = e.target as { name: string; value: number };
    if (name === 'close' && typeof value === 'number') {
      setactiveShift((aS) =>
        !!aS
          ? { ...aS, shift: { ...aS.shift, close: value, service: value } }
          : null
      );
    } else if (name || typeof value === 'number') {
      setactiveShift((aS) =>
        !!aS ? { ...aS, shift: { ...aS.shift, [name]: value } } : null
      );
    }
  };

  const handleMenuChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    c: React.ReactNode
  ) => {
    let { name = '', value } = e.target;
    if (name || typeof value === 'string') {
      setactiveShift((aS) =>
        !!aS ? { ...aS, shift: { ...aS.shift, [name]: value } } : null
      );
    }
  };

  const handleAdvertChange = (advert: TakeAwayAdvert | null) => {
    setactiveShift((aS) =>
      !!aS ? { ...aS, shift: { ...aS.shift, advert } } : null
    );
  };

  const handleDeliveryMethods = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: OrderMethod = e.currentTarget.name as
      | OrderMethod.PICKUP
      | OrderMethod.DELIVERY;

    if (activeShift?.shift.deliveryMethods?.includes(newValue)) {
      let newDelMeth = activeShift?.shift.deliveryMethods?.filter(
        (dm) => dm !== newValue
      );

      console.log({ x: 0, newDelMeth });

      setactiveShift((aS) =>
        !!aS
          ? { ...aS, shift: { ...aS.shift, deliveryMethods: newDelMeth } }
          : null
      );
    } else {
      let newDelMeth = activeShift?.shift.deliveryMethods ?? [];
      newDelMeth.push(newValue);

      console.log({ x: 1, newDelMeth });

      setactiveShift((aS) =>
        !!aS
          ? { ...aS, shift: { ...aS.shift, deliveryMethods: newDelMeth } }
          : null
      );
    }
  };

  return (
    <Box style={{ height: '100%' }}>
      <Sidebar
        id="new-TakeAway-shift"
        title={
          activeShift?.type === 'new'
            ? 'New Guest Ordering Shift'
            : `Edit ${activeShift?.shift.name}`
        }
        titleTranslation="calendar"
        onClose={onClose}
        actions={
          <Button
            fullWidth
            endIcon={() => (
              <CheckCircle color="primary" style={{ marginLeft: 8 }} />
            )}
            onClick={handleSubmit}
            loading={loading}
            disabled={!!hasTimeError()}
            variant="outlined"
            style={{ backgroundColor: 'var(--color-bg)' }}
          >
            Save
          </Button>
        }
      >
        <Box flex padding={0} className="mg-bt-md">
          <TextField
            value={activeShift?.shift.name ?? ''}
            name="name"
            label="Name"
            onChange={handleNameChange}
            fullWidth
            autoFocus={activeShift?.type !== 'edit'}
            style={{ marginRight: 8 }}
            required
            errorMessage={hasNoName ? 'Name is mandatory' : undefined}
          />
          <ShiftColor
            value={activeShift?.shift.color ?? '#ffffff'}
            onChange={(color) =>
              setactiveShift((aS) =>
                !!aS ? { ...aS, shift: { ...aS.shift, color } } : null
              )
            }
            horizontalPosition={{ left: '-200px' }}
          />
        </Box>
        <Typography
          variant="text-4"
          color="subdued"
          style={{ marginBottom: 4, marginTop: 12 }}
          block
        >
          Status
        </Typography>
        <Box
          background
          outline
          elevation={0}
          className={!!hasTimeError() ? 'mg-bt-sm' : 'mg-bt-md'}
        >
          <Select
            name="close"
            options={[
              { id: 'closed', label: 'Closed', translation: 'common' },
              {
                id: 'open',
                label: 'Open',
                translation: 'common',
              },
            ]}
            value={activeShift?.shift.closed ? 'closed' : 'open'}
            onChange={handleStatusChange}
          />
        </Box>
        <Typography
          variant="text-4"
          color="subdued"
          style={{ marginBottom: 4, marginTop: 0 }}
          block
          translation="calendar"
        >
          Repeat Shift?
        </Typography>

        <SwitchBox
          label="Repeated?"
          color="primary"
          checked={!!activeShift?.shift?.regular}
          onChange={(_, regular) =>
            setactiveShift((aS) =>
              !!aS ? { ...aS, shift: { ...aS.shift, regular } } : null
            )
          }
          className="mg-bt-md"
          translation="calendar"
        />

        {!!activeShift && (
          <DateRange
            onlyOneDay={!activeShift.shift.regular}
            state={activeShift.shift}
            onChange={(state: any) =>
              setactiveShift((aS) =>
                !!aS ? { ...aS, shift: { ...aS.shift, ...state } } : null
              )
            }
          />
        )}
        {!!activeShift?.shift.regular && (
          <WeekDays
            weekDays={activeShift?.shift.weekDays || []}
            onChange={(weekDays) =>
              setactiveShift((aS) =>
                !!aS ? { ...aS, shift: { ...aS.shift, weekDays } } : null
              )
            }
          />
        )}

        {!!activeShift?.shift?.closed && (
          <SwitchBox
            translation="calendar"
            label="All Day?"
            color="primary"
            checked={!!activeShift?.shift?.allDay}
            onChange={(_, allDay) =>
              setactiveShift((aS) =>
                !!aS
                  ? {
                      ...aS,
                      shift: {
                        ...aS.shift,
                        allDay,
                        start: 0,
                        close: 96,
                        service: 96,
                      },
                    }
                  : null
              )
            }
            className="mg-bt-md"
          />
        )}

        {!activeShift?.shift?.allDay && (
          <>
            <Typography
              variant="text-4"
              color="subdued"
              style={{ marginBottom: 4 }}
              block
              translation="calendar"
            >
              Start of Shift
            </Typography>
            <Box background outline elevation={0} className="mg-bt-md">
              <Select
                name="start"
                options={TIMES.filter((t) => t.value < 96)}
                value={activeShift?.shift.start}
                onChange={handleTimeChange}
              />
            </Box>
            <Typography
              variant="text-4"
              color="subdued"
              style={{ marginBottom: 4 }}
              block
              translation="calendar"
            >
              End of Shift
            </Typography>
            <Box
              background
              outline
              elevation={0}
              className={!!hasTimeError() ? 'mg-bt-sm' : 'mg-bt-md'}
            >
              <Select
                name="close"
                options={TIMES.filter(
                  (t) => t.value > (activeShift?.shift?.start ?? 0)
                )}
                value={activeShift?.shift.close}
                onChange={handleTimeChange}
              />
            </Box>
          </>
        )}

        {!!hasTimeError() && (
          <Typography variant="text-3" color="error" block className="mg-bt-md">
            {hasTimeError()}
          </Typography>
        )}

        {!activeShift?.shift.closed && (
          <>
            <Box
              id="delivery-methods"
              className="column"
              style={{ marginTop: 16 }}
            >
              {deliveryMethodsOptions.map((dm) => (
                <SwitchBox
                  label={dm}
                  name={dm}
                  key={dm}
                  checked={
                    activeShift?.shift.deliveryMethods?.includes(dm) || false
                  }
                  onChange={handleDeliveryMethods}
                  color="primary"
                />
              ))}
            </Box>
            <Typography
              variant="text-4"
              color="subdued"
              style={{ marginBottom: 4 }}
              block
              translation="settings"
            >
              Menu
            </Typography>
            <Box
              background
              outline
              elevation={0}
              className={!!hasTimeError() ? 'mg-bt-sm' : 'mg-bt-md'}
            >
              <Select
                name="menu"
                options={menues.map((m) => ({
                  id: m.id,
                  label: m.shortName,
                }))}
                value={
                  activeShift?.shift.menu ??
                  menues?.find((m) => m?.takeAwayStandard)?.id ??
                  null
                }
                onChange={handleMenuChange}
              />
            </Box>
            <Box>
              <Typography
                block
                variant="text-4"
                color="subdued"
                translation="settings"
              >
                Advert
              </Typography>
              <Box className="space-between date-range-input">
                <Box>
                  <Typography variant="text-3" translation="settings">
                    {activeShift?.shift?.advert?.active
                      ? activeShift?.shift?.advert?.translations.de.title
                      : 'No Advert'}
                  </Typography>
                </Box>
                <IconButton
                  size="small"
                  onClick={() => setopenAdvertModal(true)}
                >
                  <Edit className="edit-btn" />
                </IconButton>
              </Box>
              {!!activeShift?.shift && (
                <AdvertSettings
                  advert={activeShift.shift.advert}
                  open={openAdvertModal}
                  onClose={() => setopenAdvertModal(false)}
                  onSubmit={handleAdvertChange}
                />
              )}
            </Box>
          </>
        )}
      </Sidebar>
    </Box>
  );
};

export default NewEditTakeAwayShift;
