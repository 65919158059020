import React from 'react';
import useLocalStorageState from './useLocalStorageState';

const useDateNow = () => {
  const [difference, setTime] = useLocalStorageState(
    'server_time_difference',
    0
  );

  return () => Date.now() + (difference ?? 0);
};

export default useDateNow;
