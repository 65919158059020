import Box from "Components/Atoms/Box";
import Typography from "Components/Atoms/Typography";
import React from "react";
import { Reservation } from "types/reservations";
import { toCurrencyString } from "utils/helper";

const TicketInformation: React.FC<Reservation["additional"]> = ({
  selectedOptions = {},
  options = [],
  amount = 0,
}) => {
  const optionCards = Object.keys(selectedOptions).map((id) => {
    let option = options.find((o) => o.id === id);

    return {
      id,
      value: selectedOptions[id],
      label: option?.internalName ?? "id",
    };
  });

  return (
    <div>
      <Box style={{ padding: "16px 16px 0 16px" }}>
        <Typography
          className="colon"
          variant="text-3"
          translation="reservations"
        >
          Ticket-Infos
        </Typography>
      </Box>
      <Box className="space-between reservation-field">
        <Typography variant="text-3" translation="reservations">
          Price paid
        </Typography>
        <Typography variant="text-2" color="subdued" translation={null}>
          {toCurrencyString(amount)}
        </Typography>
      </Box>
      <Box style={{ padding: "16px 16px 0 16px" }}>
        <Typography
          className="colon"
          variant="text-3"
          translation="reservations"
        >
          Options
        </Typography>
      </Box>
      {optionCards.map((o) => (
        <Box key={o.id} className="space-between reservation-field">
          <Typography variant="text-3" translation={null}>
            {o.label}
          </Typography>
          <Typography variant="text-2" color="subdued" translation={null}>
            {o.value}
          </Typography>
        </Box>
      ))}
    </div>
  );
};

export default TicketInformation;
