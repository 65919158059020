import React, { useState } from "react";
import Box from "Components/Atoms/Box";

import TextField from "Components/Atoms/TextField";
import Typography from "Components/Atoms/Typography";
import { ArrowDropDown } from "Components/Atoms/Icons";
import Popover from "Components/Atoms/Popover";
import MenuItem from "Components/Atoms/MenuItem";

interface TableStrDropdownProps {
  value: string;
  options: string[];
  onChange: (str: string | string[]) => void;
  openAddMode: () => void;
}
const TableStrDropdown = ({
  value,
  options,
  onChange,
  openAddMode,
}: TableStrDropdownProps) => {
  console.log("current table", value);
  const [anchorEl, setanchorEl] = useState<null | HTMLElement>(null);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <Box>
      <Box
        outline
        background
        padding="sm"
        flex
        className="space-between"
        onClick={(e) => setanchorEl((x) => (!x ? e.currentTarget : null))}
      >
        <Typography
          variant="text-3"
          color="subdued"
          style={{ width: 100 }}
          translation={null}
        >
          {value ? value : "-"}
        </Typography>
        <ArrowDropDown style={{ fill: "var(--color-text-subdued)" }} />
      </Box>
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setanchorEl(null)}
        placement="bottom"
        padding={0}
      >
        {/* options */}
        <Box>
          <MenuItem
            key="add-new"
            value=""
            onClick={() => openAddMode()}
            selected={false}
          >
            Add new table
          </MenuItem>
          {options.map((o) => (
            <MenuItem
              key={o}
              value={o}
              onClick={() => {
                setanchorEl(null);
                onChange(o);
              }}
              selected={o === value}
            >
              {o}
            </MenuItem>
          ))}
        </Box>
      </Popover>
    </Box>
  );
};

export default TableStrDropdown;
