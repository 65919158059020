import React from "react";
import Box from "Components/Atoms/Box";
import "./styles.scss";
import Illustration from "./Components/Illustration";
import Typography from "Components/Atoms/Typography";
import { useHistory } from "react-router-dom";
import Button from "Components/Atoms/Button";

const UnderConstruction = () => {
  const history = useHistory();
  return (
    <Box className="flex-column">
      <Illustration />
      <Typography
        variant="h3"
        weight="bold"
        translation="common"
        textAlign="center"
        style={{ padding: "32px" }}
      >
        Remaining pages are under construction
      </Typography>
      <Button variant="secondary" onClick={() => history?.push(`/`)}>
        <Typography variant="text-2">Go back to dashboard</Typography>
      </Button>
    </Box>
  );
};

export default UnderConstruction;
