import React from "react";

export type GastronautIconProps = {
  size?: number;
  className?: string;
};

const GastronautIcon = ({ size = 48, className = "" }: GastronautIconProps) => {
  return (
    <img
      alt="Gastronaut Logo"
      className={className}
      height={size}
      width={size}
      src="/logo192.png"
    />
  );
};

export default GastronautIcon;
