import { RestaurantContext } from 'Contexts/RestaurantContext'
import React, { useContext } from 'react'

const useToast = () => {

    const { newToast } = useContext(RestaurantContext);

    return newToast
}

export default useToast
