import React from 'react';
import Box from 'Components/Atoms/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import './styles.scss';
import { Translations } from 'utils/types';
import Typography, { Variant } from '../Typography';

export type SwitchBoxProps = {
  checked: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  label: string | React.ReactNode;
  name?: string;
  style?: React.CSSProperties;
  translation?: Translations;
  color?: 'secondary' | 'primary' | 'default';
  className?: string;
  labelVariant?: Variant;
  disabled?: boolean;
  boxStyle?: React.CSSProperties;
};

const SwitchBox: React.FC<SwitchBoxProps> = ({
  checked,
  onChange,
  label,
  style,
  name,
  translation,
  className = '',
  color = 'secondary',
  labelVariant = 'text-3',
  disabled = false,
  boxStyle,
}) => {
  return (
    <Box style={boxStyle} className={'switch-box ' + className ?? ''}>
      <FormControlLabel
        control={
          <Switch
            checked={checked}
            onChange={onChange}
            style={style}
            name={name}
            color={color}
            disabled={disabled}
          />
        }
        label={
          <Typography variant={labelVariant} translation={translation}>
            {label}
          </Typography>
        }
      />
    </Box>
  );
};

export default SwitchBox;
