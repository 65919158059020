import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

const ShiftTemplateIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M5.05908 2.25C4.31316 2.25 3.59779 2.54632 3.07034 3.07376C2.5429 3.60121 2.24658 4.31658 2.24658 5.0625V12.9375C2.24658 13.6834 2.5429 14.3988 3.07034 14.9262C3.59779 15.4537 4.31316 15.75 5.05908 15.75H12.9341C13.3034 15.75 13.6692 15.6773 14.0104 15.5359C14.3516 15.3946 14.6617 15.1874 14.9228 14.9262C15.184 14.6651 15.3912 14.355 15.5325 14.0138C15.6738 13.6726 15.7466 13.3068 15.7466 12.9375V5.0625C15.7466 4.69316 15.6738 4.32743 15.5325 3.9862C15.3912 3.64497 15.184 3.33493 14.9228 3.07376C14.6617 2.8126 14.3516 2.60543 14.0104 2.46409C13.6692 2.32275 13.3034 2.25 12.9341 2.25H5.05908ZM8.43746 4.5C8.58664 4.5 8.72971 4.55926 8.8352 4.66475C8.94069 4.77024 8.99996 4.91332 8.99996 5.0625V9H11.8125C11.9616 9 12.1047 9.05926 12.2102 9.16475C12.3157 9.27024 12.375 9.41332 12.375 9.5625C12.375 9.71168 12.3157 9.85476 12.2102 9.96025C12.1047 10.0657 11.9616 10.125 11.8125 10.125H8.43746C8.28827 10.125 8.1452 10.0657 8.03971 9.96025C7.93422 9.85476 7.87496 9.71168 7.87496 9.5625V5.0625C7.87496 4.91332 7.93422 4.77024 8.03971 4.66475C8.1452 4.55926 8.28827 4.5 8.43746 4.5Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default ShiftTemplateIcon;
