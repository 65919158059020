import Alert, { AlertState } from 'Components/Organisms/Alert';
import useCollection from 'CustomHooks/useCollection';
import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useMemo,
} from 'react';
import firebase, { db } from 'utils/firebase';
import { asyncVoid, dateHelper, noop } from 'utils/helper';
import { Products, Translations } from 'utils/types';
import { AuthContext } from './AuthContext';
import { Notification } from 'utils/types';
import Toasts from 'Components/Organisms/Toasts';
import { StylesProvider } from '@material-ui/core';
import SignatureContextProvider from './SignatureContext';
import useNotifications, { NotificationV02 } from 'CustomHooks/useNotification';
import useDocument from 'CustomHooks/useDocument';
import { DocumentTypes } from 'gastronaut-shared';
import { StripeSubscriptionDocument } from 'gastronaut-shared/types/documents/restaurants/credentials';
import TextHelper, { TextHelperState } from 'Components/Organisms/TextHelper';
import LinkGenerator from 'Components/Organisms/LinkGenerator';
import {
  AppId,
  SecurityPlus,
  AppSettings,
} from 'gastronaut-shared/types/documents/restaurants';
import PinAlert from 'Components/Organisms/PinAlert';
import useLocalStorageState from 'CustomHooks/useLocalStorageState';
import { AddOnOrder } from 'App/Experiences/types/addOnOrder';
import { NotificationV03 } from 'gastronaut-shared/types/helper/notification';
import ExperimentalFlagsModal, {
  ExperimentalFlags,
} from 'App/ReservationBook/Components/ExperimentalFlags';
import { IntercomWrapperRestaurant } from 'App/Dashboard/Screens/Dashboard';

type ReservationSettings =
  DocumentTypes.Restaurant.Settings.ReservationSettingsDocument;

export type PinAuth = {
  onSubmit: (signature: string) => void | Promise<void>;
  onClose?: () => void;
  signatures: string[];
  type: string;
};

export enum Severity {
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
}

export type Toast = {
  id: number;
  severity: Severity;
  description: string;
  descriptionTranslation?: Translations;
  onRevert?: VoidFunction;
};

type Props = {
  restaurantId: null | string;
  setrestaurantId: Function;
  children?: React.ReactNode;
  storyBook?: boolean;
};

type Context = {
  products: Products[];
  switchRestaurant: Function;
  restaurantId: null | string;
  alert: React.Dispatch<React.SetStateAction<AlertState | null>>;
  pinAuth: React.Dispatch<React.SetStateAction<null | null | PinAuth>>;

  // notifications: Notification[];
  newToast: (
    description: string,
    severity: Severity,
    translation?: Translations,
    onRevert?: VoidFunction
  ) => void;
  restaurantName: string;
  restaurantPhone: string;
  lightReservation: boolean;
  notifications: NotificationV02[];
  notificationsLoading: boolean;
  markNotificationAsSeen: (id: string) => Promise<void>;
  getAllNotifications: () => Promise<NotificationV02[] | void>;
  markAllNotificationsAsSeen: () => Promise<void>;
  deleteNotification: (id: string) => Promise<void>;
  reservationSettings: null | ReservationSettings;
  hasSia: boolean;
  subscriptions: null | StripeSubscriptionDocument['subscriptionItems'];
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  textHelperState: null | TextHelperState;
  initTextHelper: React.Dispatch<React.SetStateAction<TextHelperState | null>>;
  apps: AppId[];
  setapps: React.Dispatch<
    React.SetStateAction<DocumentTypes.Restaurant.AppId[]>
  >;
  securityPlus: null | AppSettings<SecurityPlus>;
  storedPins: Record<string, number>;
  filteredNotifications: (NotificationV03 & {
    id: string;
  })[];
  notificationsV03Open: boolean;
  setnotificationsV03Open: React.Dispatch<React.SetStateAction<boolean>>;
  notificationCategories: Record<string, string[]>;
  experimentalFlags: {
    ref: any;
    data: ExperimentalFlags | null;
    loading: boolean;
    error: string | null;
    exists: boolean;
  };
};

export const RestaurantContext = createContext<Context>({
  products: [],
  restaurantId: null,
  switchRestaurant: noop,
  alert: noop,
  pinAuth: noop,
  newToast: noop,
  restaurantName: '',
  restaurantPhone: '',
  lightReservation: false,
  notifications: [],
  notificationsLoading: false,
  getAllNotifications: asyncVoid,
  markAllNotificationsAsSeen: asyncVoid,
  markNotificationAsSeen: asyncVoid,
  deleteNotification: asyncVoid,
  reservationSettings: null,
  hasSia: false,
  subscriptions: null,
  search: '',
  setSearch: noop,
  textHelperState: null,
  initTextHelper: noop,
  apps: [],
  setapps: noop,
  securityPlus: null,
  storedPins: {},
  filteredNotifications: [],
  notificationsV03Open: true,
  setnotificationsV03Open: noop,
  notificationCategories: {},
  experimentalFlags: {
    ref: null,
    data: null,
    loading: false,
    error: null,
    exists: false,
  },
});

/*
    Everything needed for Restaurant
*/

type NotificationCollection = {
  ref: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
  data: Notification[];
  loading: boolean;
  error: null | string;
};

const RestaurantContextProvider = ({
  restaurantId,
  setrestaurantId,
  children,
  storyBook = false,
}: Props) => {
  const { user } = useContext(AuthContext);

  const [alertState, alert] = useState<null | AlertState>(null);
  const [pinAuthState, pinAuth] = useState<null | PinAuth>(null);

  const [securityPlusDoc] = useDocument<AppSettings<SecurityPlus>>(
    `restaurants/${restaurantId}/apps`,
    'security_plus'
  );

  const [experimentalFlags] = useDocument<ExperimentalFlags>(
    `restaurants/${restaurantId}/other`,
    'experimentalFlags'
  );

  const [storedPins, setStoredPins] = useState<Record<string, number>>({});

  const [toasts, setToasts] = useState<null | Toast[]>(null);
  const [search, setSearch] = useState<string>('');

  const [restaurantName, setRestaurantName] = useState<string>('');
  const [restaurantPhone, setRestaurantPhone] = useState<string>('');
  const [restaurantEmail, setRestaurantEmail] = useState<string>('');

  useEffect(() => {
    window.document.title = [restaurantName, 'Gastronaut Dashboard']
      .filter(Boolean)
      .join(' - ');
  }, [restaurantName]);

  const [reservationSettings] = useDocument<ReservationSettings>(
    `restaurants/${restaurantId}/settings`,
    'reservationsV02'
  );

  const [subscriptionDocument] = useDocument<StripeSubscriptionDocument>(
    `restaurants/${restaurantId}/credentials`,
    'stripeSubscription'
  );

  const subscriptions = subscriptionDocument?.data?.subscriptionItems ?? null;

  const [reservationSettingsDraft] = useDocument<ReservationSettings>(
    `restaurants/${restaurantId}/settingDrafts`,
    'reservationsV02'
  );

  const [products, setproducts] = useState<Products[]>([]);
  const [apps, setapps] = useState<AppId[]>([]);

  const switchRestaurant = (restaurantId: string) => {
    if (user?.data?.restaurants?.find((r) => r.id === restaurantId)) {
      setrestaurantId(restaurantId);
      loadData(restaurantId);
    }
  };

  const loadData = async (restaurantId: string) => {
    try {
      // if (storyBook) return;

      const ref = db.collection('restaurantData').doc(restaurantId);
      const settingsRef = db
        .collection(`restaurants/${restaurantId}/settings`)
        .doc('general');

      const [doc, settingsDoc] = await Promise.all([
        ref.get(),
        settingsRef.get(),
      ]);

      if (!doc.exists) {
        throw new Error('No Restaurant with this Id ' + restaurantId);
      }

      const restaurantData = doc?.data();

      if (restaurantData) {
        const { productsOwned = [] } = restaurantData as {
          productsOwned: Products[];
        };
        setproducts(productsOwned || []);
        setRestaurantName(restaurantData.name ?? '');
        setRestaurantPhone(restaurantData.customerInfo.phoneNumber ?? '');
        setapps(restaurantData?.apps ?? []);
        setRestaurantEmail(settingsDoc?.data()?.email ?? '');

        if (!restaurantData?.apps) {
          // @TODO run function to check apps
        }
      }

      console.log({ restaurantData });
      return;
    } catch (error) {
      console.error(error);
      setrestaurantId(null);
    }
  };

  useEffect(() => {
    if (!restaurantId) {
      setproducts([]);
      setapps([]);
    } else {
      loadData(restaurantId);
    }
    // eslint-disable-next-line
  }, [restaurantId]);

  const {
    notifications,
    notificationsLoading,
    getAllNotifications,
    markAllNotificationsAsSeen,
    markNotificationAsSeen,
    deleteNotification,
  } = useNotifications(restaurantId ?? '');

  const newToast = (
    description: string,
    severity: Severity = Severity.INFO,
    translation?: Translations,
    onRevert?: VoidFunction
  ) => {
    let newToast: Toast = {
      id: Date.now() + 5000,
      description,
      severity,
      descriptionTranslation: translation,
      onRevert,
    };

    setToasts((ts) => {
      if (!!ts) {
        let filteredTs = ts.sort((a, b) => a.id - b.id);

        if (ts.length > 2) {
          filteredTs = filteredTs.slice(ts.length - 3, ts.length);
        }

        return [...filteredTs, newToast];
      } else {
        return [newToast];
      }
    });
  };

  const lightReservation = products?.includes('light-reservation') ?? false;

  const hasSia = products?.includes('phone');

  const [textHelperState, initTextHelper] = useState<null | TextHelperState>(
    null
  );

  //////////////////////////
  const [notificationsV03Open, setnotificationsV03Open] = useLocalStorageState(
    'notificationsV03Open',
    false
  );

  const SevenDaysAgo = useMemo(() => Date.now() - 7 * 24 * 60 * 60000, []);

  const [notificationsV03] = useCollection<NotificationV03>(
    'notificationsV03',
    {
      sort: ['createdAt', 'desc'],
      filter: [
        ['restaurantId', '==', restaurantId],
        ['createdAt', '>=', SevenDaysAgo],
      ],
      limit: 100,
    }
  );

  const filteredNotifications = useMemo(
    () =>
      notificationsV03.data.filter(
        (n) =>
          n.status !== 'done' &&
          (!n.irrelevantAfter || n.irrelevantAfter < Date.now()) &&
          (!n.date || n.date >= dateHelper())
      ),
    [notificationsV03.data]
  );

  const notificationCategories = useMemo(
    () =>
      notificationsV03.data.reduce(
        (acc, n) => {
          let category = n.icon || 'other';

          acc[category] = [...(acc[category] ?? []), n.id];

          return acc;
        },
        {
          optimizations: [],
          mailbox: [],
          weather: [],
          comment: [],
          addOnOrder: [],
          rating: [],
        } as Record<string, string[]>
      ),
    [filteredNotifications]
  );

  return (
    <RestaurantContext.Provider
      value={{
        products,
        switchRestaurant,
        restaurantId,
        alert,
        pinAuth,
        newToast,
        restaurantName,
        restaurantPhone,
        lightReservation,
        notifications,
        notificationsLoading,
        getAllNotifications,
        markAllNotificationsAsSeen,
        markNotificationAsSeen,
        deleteNotification,
        subscriptions,
        hasSia,
        reservationSettings:
          reservationSettings.data ?? reservationSettingsDraft.data ?? null,
        search,
        setSearch,
        textHelperState,
        initTextHelper,
        apps,
        setapps,
        securityPlus: securityPlusDoc.data,
        storedPins,
        filteredNotifications,
        notificationsV03Open,
        setnotificationsV03Open,
        notificationCategories,
        experimentalFlags,
      }}
    >
      <SignatureContextProvider restaurantId={restaurantId}>
        <StylesProvider injectFirst>
          <Alert state={alertState} onClose={() => alert(null)} />
          <PinAlert
            isOpen={!!pinAuthState}
            onSubmit={(signature, to = 120000) => {
              if (pinAuthState?.onSubmit) pinAuthState.onSubmit(signature);
              if (pinAuthState)
                setStoredPins((p) => {
                  let newPins: Record<string, number> = {};

                  for (const pin in p) {
                    if (p[pin] > Date.now()) {
                      newPins[pin] = p[pin];
                    }
                  }

                  newPins[signature] = Date.now() + to;

                  return newPins;
                });
              pinAuth(null);
            }}
            signatures={pinAuthState?.signatures}
            onClose={() => {
              if (pinAuthState?.onClose) pinAuthState.onClose?.();
              pinAuth(null);
            }}
          />
          {!!textHelperState && restaurantId && (
            <TextHelper
              state={textHelperState}
              onClose={() => initTextHelper(null)}
              restaurantId={restaurantId}
            />
          )}
          {!experimentalFlags.data?.hideToasts && (
            <Toasts
              toasts={toasts || []}
              onClose={(id) =>
                setToasts((ts) => ts?.filter((t) => t.id !== id) || null)
              }
            />
          )}
          <ExperimentalFlagsModal />
        </StylesProvider>
        {children}
        {restaurantId && restaurantName && (
          <IntercomWrapperRestaurant
            restaurant={{
              name: restaurantName,
              email: restaurantEmail,
              id: restaurantId,
            }}
            user={{
              name: user?.data?.displayName ?? '',
              email: user?.data?.email ?? '',
              id: user?.uid ?? '',
            }}
          />
        )}
      </SignatureContextProvider>
    </RestaurantContext.Provider>
  );
};

export default RestaurantContextProvider;

export const useAddOnOrders = (restaurantId: string, date: string) => {
  const [addOnOrders] = useCollection<AddOnOrder>('addOnOrders', {
    filter: [
      ['restaurantId', '==', restaurantId],
      ['reservation.date', '==', date],
    ],
  });

  const addOnOrderMap = React.useMemo(() => {
    let map: Record<string, AddOnOrder[]> = {};

    addOnOrders?.data?.forEach((o) => {
      map[o.reservationId] = [...(map[o.reservationId] ?? []), o];
    });

    return map;
  }, [addOnOrders.data, restaurantId, date]);

  React.useEffect(() => {
    (window as any).addOnOrders = addOnOrders;
    (window as any).addOnOrderMap = addOnOrderMap;

    if (addOnOrders.error) {
      console.error('---- Add On Error ----', addOnOrders.error);
    }
  }, [addOnOrders]);

  const getAddOnOrders = React.useCallback(
    (id: string, all = false) => {
      if (all) {
        return addOnOrderMap[id] ?? [];
      } else {
        return addOnOrderMap[id]?.filter((o) => o.status !== 'failed') ?? [];
      }
    },
    [addOnOrderMap]
  );

  return {
    addOnOrders: addOnOrders?.data ?? [],
    addOnOrderMap,
    getAddOnOrders,
  };
};
