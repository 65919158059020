import React, { useEffect, useState, useMemo } from 'react';
import { Reservation, CurrentStatus } from 'types/reservations';
import firebase, { db } from 'utils/firebase';
import { dateHelper, timeToBlock } from 'utils/helper';
import { Modify } from 'utils/types';
import useCurrentTime from 'CustomHooks/useCurrentTime';
import { Date as DateType, ShiftType } from 'types/shifts';
import { Shuffle, ShuffleState } from './useShuffle';
import useDateNow from './useDateNow';
import { useAddOnOrders } from 'Contexts/RestaurantContext';
import { AddOnType } from 'App/Experiences/types/addOn';
import useCollection from './useCollection';
import { Ticket } from 'gastronaut-shared/types/documents/restaurants/event';

export const getReservationStatus = (
  res: Reservation,
  currentTime: null | string
) => {
  if (res.done) {
    return CurrentStatus.DONE;
  } else if (res.status === 'failed') {
    return CurrentStatus.FAILED;
  } else if (res.started) {
    if (
      res.timeStamps?.paidAt ||
      res.customData?.customStatus === CurrentStatus.PAID
    ) {
      return CurrentStatus.PAID;
    } else if (
      res.timeStamps?.lastOrderAt ||
      res.customData?.customStatus === CurrentStatus.HAS_ORDERED
    ) {
      return CurrentStatus.HAS_ORDERED;
    }

    return CurrentStatus.SEATED;
  } else if (currentTime !== null) {
    if (res.startTimeInBlocks === timeToBlock(currentTime)) {
      return CurrentStatus.UPCOMING;
    } else if (res.startTimeInBlocks < timeToBlock(currentTime)) {
      return CurrentStatus.OVERDUE;
    }
  }

  return CurrentStatus.NORMAL;
};

const useReservations = (
  restaurantId: string | null,
  date: string,
  shuffle?: ShuffleState | null
) => {
  const { addOnOrders, getAddOnOrders } = useAddOnOrders(
    restaurantId || '',
    date
  );

  const [tickets] = useCollection<Ticket>(`ticketsV02`, {
    filter: [
      ['restaurant', '==', restaurantId],
      ['date', '==', date],
    ],
  });

  const [data, setdata] = useState<Reservation[]>([]);
  const [loading, setloading] = useState<boolean>(true);
  const [listener, setlistener] = useState<Function[]>([]);
  const [error, seterror] = useState<null | string>(null);
  const [hideLiveResas, setHideLiveResas] = useState<boolean | undefined>();

  const currentTime = useCurrentTime(date);

  const DateNow = useDateNow();

  useEffect(() => {
    if (listener && listener.length) {
      listener.forEach((unsubsribe) => unsubsribe());
    }

    if (restaurantId) {
      setloading(true);
      setdata([]);
      seterror(null);

      const ref = db
        .collection('requestsV03')
        .where('restaurant', '==', restaurantId)
        .where('date', '==', date);

      const today = dateHelper();

      let unsubscribe = ref.onSnapshot(
        (querySnapshots: firebase.firestore.QuerySnapshot) => {
          let arr: Reservation[] = [];
          let promises: Promise<void>[] = [];

          querySnapshots.forEach((doc) => {
            let data = { ...doc.data(), id: doc.id } as Reservation;

            if (data.validTill && data.validTill < DateNow()) {
              if (data.guest.name.startsWith('Wird gerade')) {
                promises.push(doc.ref.delete());
              } else {
                promises.push(
                  doc.ref.update({
                    validTill: 0,
                    updatedAt: DateNow(),
                    updatedBy: 'system',
                  })
                );
              }
            } else if (date < today && !data.done && data.started) {
              promises.push(
                doc.ref.update({
                  done: true,
                  updatedAt: DateNow(),
                  updatedBy: 'system',
                })
              );
            }

            arr.push(data);
          });

          Promise.all(promises);

          let res: Reservation[] = arr
            .sort(
              (a, b) =>
                (a?.createdAt || 1000000000) - (b?.createdAt || 1000000000)
            )
            .map((res, i) => {
              let startTimeInBlocks = timeToBlock(res.time);
              let endTimeInBlocks =
                startTimeInBlocks + (res?.reservationLength ?? 0);

              return {
                ...res,
                position: i + 1,
                startTimeInBlocks,
                endTimeInBlocks,
              };
            });

          setdata(res);
          setloading(false);
        },
        (err: Error) => {
          seterror(err.message);
          setloading(false);
        }
      );

      setlistener([unsubscribe]);
    } else {
      setdata([]);
      setloading(false);
      seterror('No Restaurant selected');
    }

    return () => {
      if (listener && listener.length) {
        listener.forEach((unsubsribe) => unsubsribe());
      }
    };
  }, [restaurantId, date]);

  const reservations = useMemo(() => {
    let shuffleObj =
      shuffle?.reservations?.reduce(
        (acc, cV) => ({ ...acc, [cV.id]: cV }),
        {} as {
          [id: string]: {
            id: string;
            tables: string[];
            space: string;
            previousTables: string[];
          };
        }
      ) ?? {};

    console.log({ shuffleObj, shuffle });

    const ticketIds = tickets.data?.map((t) => t.id) ?? [];

    return data.map((res) => {
      let shuffleResa = shuffleObj[res.id];

      let orders = addOnOrders.filter((o) => o.reservationId === res.id);

      let addOnItems = orders.reduce((items, cV) => {
        if (cV.status !== 'processing' && cV.status !== 'succedded')
          return items;

        for (const item of cV.cart) {
          if (item.type === AddOnType.SIMPLE) {
            items.push({
              id: item.id,
              line1: item.title,
              line2: '',
              price: item.total,
              refunded: !!item.refunded,
              payAtRestaurant: cV.payAtRestaurant,
            });
          } else if (item.type === AddOnType.SELECT) {
            items.push({
              id: item.id,
              line1: item.title,
              line2: `${item.choice.title}`,
              price: item.total,
              refunded: !!item.refunded,
              payAtRestaurant: cV.payAtRestaurant,
            });
          } else if (item.type === AddOnType.MULTIPLE_SELECT) {
            items.push({
              id: item.id,
              line1: item.title,
              line2: item.choices
                .filter((x) => x.count)
                .map((c) => `${c.count}x ${c.title}`)
                .join(', '),
              price: item.total,
              refunded: !!item.refunded,
              payAtRestaurant: cV.payAtRestaurant,
            });
          } else if (item.type === AddOnType.TABLE_SELECT) {
            items.push({
              id: item.id,
              line1: item.title,
              line2: '',
              price: item.total,
              refunded: !!item.refunded,
              payAtRestaurant: cV.payAtRestaurant,
            });
          }
        }

        return items;
      }, [] as { id: string; line1: string; line2: string; price: number; refunded: boolean; payAtRestaurant?: boolean }[]);

      let ticket = tickets.data[ticketIds.indexOf(res.ticketId ?? '')] ?? null;

      let ticketData: Record<string, number> = {};

      if (
        ticket &&
        (ticket.status === 'succedded' || ticket.status === 'processing')
      ) {
        for (const option of ticket.event.options ?? []) {
          ticketData['o_' + option.internalName] =
            ticket.options?.[option.id] ?? 0;
        }

        for (const option of ticket.event.optionalAddOns ?? []) {
          ticketData['a_' + option.internalName] =
            ticket.optionalAddOns?.[option.id] ?? 0;
        }
      }

      let addOnData: Record<string, number> = {};

      for (const order of orders) {
        for (const item of order.cart) {
          if (item.refunded) {
            continue;
          } else if (item.type === AddOnType.SIMPLE) {
            addOnData[item.title] = 1;
          } else if (item.type === AddOnType.SELECT) {
            addOnData[item.title + '  ' + item.choice.title] = 1;
          } else if (item.type === AddOnType.MULTIPLE_SELECT) {
            for (const choice of item.choices) {
              if (choice.count)
                addOnData[item.title + '  ' + choice.title] = choice.count;
            }
          } else if (item.type === AddOnType.TABLE_SELECT) {
            addOnData[item.title] = 1;
          }
        }
      }

      return {
        ...res,
        reservationLength:
          res.reservationLength < 0
            ? Number(res.oldReservationLength ?? 4)
            : res.reservationLength,
        currentStatus: getReservationStatus(res, currentTime),
        previousTables:
          shuffleResa?.tables &&
          JSON.stringify(shuffleResa.tables) !== JSON.stringify(res.tables)
            ? res.tables
            : null,
        tables: shuffleResa?.tables ?? res.tables,
        space: shuffleResa?.space ?? res.space,
        orders,
        addOnItems,
        name: res.guest.name,
        ticket,
        fixed:
          res.fixed ||
          !!(
            shuffle &&
            (shuffle.fixedReservations.includes(res.id) ||
              res.fixed ||
              res.started ||
              res.done ||
              res.blocked)
          ),
        ticketData,
        addOnData,
        hasPayment:
          !!res.minimumConsumption ||
          !!res.ticketId ||
          !!orders.length ||
          !!res.hasAddOnOrders ||
          !!res.experienceVoucher,
      };
    });
  }, [data, currentTime, shuffle, hideLiveResas, addOnOrders, tickets]);

  useEffect(() => {
    if (reservations.length && date) {
      db.collection(`restaurants/${restaurantId}/reservationDates`)
        .doc(date)
        .get()
        .then((doc) => {
          if (!doc.exists) return;

          let { shifts } = doc.data() as DateType;

          shifts =
            shifts?.map((s) =>
              s.type === ShiftType.RESERVATION
                ? {
                    ...s,
                    occassions: s.occassions ?? s?.additional?.occassions ?? [],
                    spaces: s.spaces ?? s?.additional?.spaces ?? [],
                  }
                : s
            ) ?? [];

          let shiftStatistics: Record<
            string,
            Record<string, number>
          > = shifts.reduce((acc, cV) => ({ ...acc, [cV.id]: {} }), {});

          reservations.forEach((data) => {
            if (data.status === 'failed') return;

            let shiftId = shifts.find(
              (s) =>
                s.type === ShiftType.RESERVATION &&
                (s.occassions.includes(data.occassion) ||
                  s.spaces.includes(data?.space || '')) &&
                s.start <= timeToBlock(data.time) &&
                s.close >= timeToBlock(data.time)
            )?.id;

            if (!shiftId || !shiftStatistics[shiftId]) return;

            shiftStatistics[shiftId][data.id] = data.guests;
          });

          doc.ref.update({ shiftStatistics, shifts });
        });
    }
  }, [reservations.length]);

  const hideLiveResasActive =
    hideLiveResas &&
    reservations.some((r) => r.currentStatus !== CurrentStatus.NORMAL);

  return {
    reservations,
    reservationError: error,
    reservationLoading: loading,
    currentTime,
    hideLiveResas:
      hideLiveResas === undefined ? hideLiveResas : hideLiveResasActive,
    setHideLiveResas,
    addOnOrders,
    getAddOnOrders,
    tickets,
  };
};

export default useReservations;
