import React, { ChangeEvent, useEffect, useState } from "react";
import { TableCell, TableRow } from "@material-ui/core";
import { ListConfigHeader } from "../index";
import EditableTableCell from "./EditableTableCell";
import Button from "../../../Atoms/Button";
import "../styles.scss";

interface NewRowProps<DataType extends { id?: number | string }> {
  draggable?: boolean;
  selectable?: boolean;
  headers?: ListConfigHeader<DataType>[];
  rowHeight: string | number;
  onAddNewRow?: (data: Partial<DataType>) => void;
}
const NewRow = ({
  draggable,
  selectable,
  headers,
  rowHeight,
  onAddNewRow,
}: NewRowProps<any>) => {
  const [newData, setNewData] = useState<Record<string, any>>({});

  function handleCellChange(e: ChangeEvent<HTMLInputElement>) {
    setNewData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  function handleKeypress(e: any) {
    if (e.keyCode === 13) {
      setNewData({});
      onAddNewRow && onAddNewRow(newData);
    }
  }

  useEffect(() => {
    headers?.forEach((h) => setNewData((prev) => ({ ...prev, [h.field]: `` })));
  }, [headers]);

  const onSubmit = () => {
    const keys = Object.keys(newData);
    let onlyEmptyFiedls: boolean = true;

    keys.forEach((key) => {
      if (!!newData[key]) {
        onlyEmptyFiedls = false;
      }
    });
    if (!onlyEmptyFiedls) {
      setNewData({});
      onAddNewRow && onAddNewRow(newData);
    }
  };

  return (
    <TableRow>
      {draggable && <TableCell className="dragHandle" />}
      {selectable && <TableCell />}
      {/*<form onSubmit={onSubmit}>*/}
      {headers?.map((h) => {
        return (
          <TableCell className="addable-cell-container" height={rowHeight}>
            <EditableTableCell
              name={h.field}
              //@ts-ignore
              value={newData[h.field]}
              placeholder={`New ${h.headerName}`}
              onBlur={(e) => handleCellChange(e)}
              onChange={(e) => handleCellChange(e)}
              onKeyPress={handleKeypress}
            />
          </TableCell>
        );
      })}
      {/*</form>*/}
      <TableCell>
        <Button
          fullWidth
          variant="default"
          onClick={onSubmit}
          translation="settings"
        >
          Add
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default NewRow;
