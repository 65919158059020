import Box from 'Components/Atoms/Box';
import IconButton from 'Components/Atoms/IconButton';
import {
  Block,
  Close,
  Event,
  Group,
  Person,
  Search,
  Ticket,
} from 'Components/Atoms/Icons';
import Typography from 'Components/Atoms/Typography';
import SearchBar from 'Components/Molecules/SearchBar';
import React, { useState } from 'react';
import SortButton from './SortButton';
import GroupAndColumns from './GroupAndColumns';
import { Filters } from 'CustomHooks/useReservationList';
import Button from 'Components/Atoms/Button';

export type ActionBarProps = {
  totalGroups: number;
  toggleAll: () => void;
  totalPax: number;
  totalCancelations: number;
  filtersOptions: {
    occassions: { id: string; title: string }[];
    spaces: { id: string; name: string }[];
    sort: { value: string; label: string }[];
  };
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  search: string | null;
  setSearch: React.Dispatch<React.SetStateAction<string | null>>;
  styles?: React.CSSProperties;
  list?: boolean;
  hasTickets?: boolean;
};

const ActionBar = ({
  totalGroups,
  totalPax,
  totalCancelations,
  filtersOptions,
  filters,
  setFilters,
  search,
  setSearch,
  toggleAll,
  styles,
  list = false,
  hasTickets = false,
}: ActionBarProps) => {
  const [isSearching, setIsSearching] = useState<boolean>(false);

  return (
    <Box
      className="space-between corner"
      elevation={0}
      padding="sm"
      style={{ position: 'sticky', top: 0, zIndex: 2, ...styles }}
    >
      {isSearching && (
        <>
          <SearchBar
            value={search}
            onChange={setSearch}
            width="100%"
            outline={false}
            boxStyle={{ height: '28px' }}
            autoFocus
          />
          <IconButton
            onClick={() => {
              setIsSearching(false);
              setSearch(null);
            }}
            size="x-small"
            color="subdued"
          >
            <Close />
          </IconButton>
        </>
      )}
      {!isSearching && (
        <>
          <Box style={{ width: 'fit-content' }}>
            <IconButton size="small" onClick={() => setIsSearching(true)}>
              <Search />
            </IconButton>
          </Box>
          <Box flex style={{ width: 'fit-content' }} onClick={toggleAll}>
            <Box flex style={{ padding: '0px 5px' }}>
              <Group className="numberIcon" />
              <Typography variant="text-3" color="subdued">
                {totalGroups}
              </Typography>
            </Box>
            <Box flex style={{ padding: '0px 5px' }}>
              <Person className="numberIcon" />
              <Typography variant="text-3" color="subdued">
                {totalPax}
              </Typography>
            </Box>
            <Box flex style={{ padding: '0px 5px' }}>
              <Block className="numberIcon" />
              <Typography variant="text-3" color="subdued">
                {totalCancelations}
              </Typography>
            </Box>
          </Box>
          <Box style={{ width: 'fit-content' }}>
            <SortButton {...{ filtersOptions, filters, setFilters }} />
          </Box>
          {list && (
            <Box style={{ width: 'fit-content' }}>
              <GroupAndColumns
                {...{
                  filtersOptions,
                  filters,
                  setFilters,
                  disabled: filters.mode === 'tickets' && hasTickets,
                }}
              />
            </Box>
          )}
          {hasTickets && (
            <Box style={{ width: 'fit-content' }}>
              <Button
                variant="transparent"
                onClick={() =>
                  setFilters((f) => ({
                    ...f,
                    mode: f.mode === 'tickets' ? 'normal' : 'tickets',
                  }))
                }
                style={{
                  background:
                    filters.mode === 'tickets'
                      ? 'var(--color-btn-default)'
                      : '',
                }}
              >
                Ticketansicht
              </Button>
            </Box>
          )}
          {/* {!hasTickets && list && (
            <Box style={{ width: 'fit-content' }}>
              <Button
                variant="transparent"
                onClick={() =>
                  setFilters((f) => ({
                    ...f,
                    mode: f.mode === 'addons' ? 'normal' : 'addons',
                  }))
                }
                style={{
                  background:
                    filters.mode === 'addons' ? 'var(--color-btn-default)' : '',
                  marginRight: 4,
                }}
              >
                Addons
              </Button>
            </Box>
          )} */}
        </>
      )}
    </Box>
  );
};

export default ActionBar;
