import Box from "Components/Atoms/Box";
import Typography from "Components/Atoms/Typography";
import React from "react";

interface WeekDayLabelProps {
  width?: number | string;
}

const WeekDayLabel = ({ width = 232 }: WeekDayLabelProps) => {
  return (
    <Box
      flex
      padding="sm"
      style={{
        maxWidth: width,
        paddingBottom: 4,
        justifyContent: "space-between",
      }}
    >
      <Typography
        variant="text-3"
        textAlign="center"
        block
        style={{ marginRight: 8, width: 24 }}
      >
        Mon
      </Typography>
      <Typography
        variant="text-3"
        textAlign="center"
        block
        style={{ marginRight: 8, width: 24 }}
      >
        Tue
      </Typography>
      <Typography
        variant="text-3"
        textAlign="center"
        block
        style={{ marginRight: 8, width: 24 }}
      >
        Wed
      </Typography>
      <Typography
        variant="text-3"
        textAlign="center"
        block
        style={{ marginRight: 8, width: 24 }}
      >
        Thu
      </Typography>
      <Typography
        variant="text-3"
        textAlign="center"
        block
        style={{ marginRight: 8, width: 24 }}
      >
        Fri
      </Typography>
      <Typography
        variant="text-3"
        textAlign="center"
        block
        style={{ marginRight: 8, width: 24 }}
      >
        Sat
      </Typography>
      <Typography
        variant="text-3"
        textAlign="center"
        block
        style={{ width: 24 }}
      >
        Sun
      </Typography>
    </Box>
  );
};

export default WeekDayLabel;
