import React, { useContext, useState } from 'react';
import IconButton from 'Components/Atoms/IconButton';
import { Category, Group, Sort } from 'Components/Atoms/Icons';
import Popover from 'Components/Atoms/Popover';
import Box from 'Components/Atoms/Box';
import Typography from 'Components/Atoms/Typography';
import Checkbox from 'Components/Atoms/Checkbox';
import RadioButtonGroup from 'Components/Atoms/RadioButtonGroup';
import Badge from 'Components/Atoms/Badge';
import Button from 'Components/Atoms/Button';
import { Filters } from 'CustomHooks/useReservationList';
import { ReservationContext } from 'Contexts/ReservationContext';

const COLUMNS = [
  { id: 'id', title: 'Code' },
  { id: 'name', title: 'Name' },
  { id: 'guests', title: 'PAX' },
  { id: 'time', title: 'Time' },
  { id: 'tables', title: 'Tables' },
  { id: 'space', title: 'Space' },
  { id: 'occassion', title: 'Occassion' },
  { id: 'phone', title: 'Phone' },
  { id: 'email', title: 'Email' },
  { id: 'date', title: 'Date' },
  { id: 'addOnItems', title: 'Add Ons' },
  { id: 'tickets', title: 'Tickets' },
  { id: 'comment', title: 'Comment' },
  { id: 'hostComment', title: 'Host Comment' },
  { id: 'allComments', title: 'All Comments' },

  { id: 'createdAt', title: 'Created At' },
];

const COLUMN_IDS = COLUMNS.map((x) => x.id);

const GROUP_BY = [
  { value: 'none', label: 'Keine Gruppierung' },
  { value: 'occassion', label: 'Occassion' },
  { value: 'space', label: 'Space' },
  { value: 'time', label: 'Shift' },
  { value: 'table', label: 'Table' },
];

export type GroupAndColumnsProps = {
  filtersOptions: {
    occassions: { id: string; title: string }[];
    spaces: { id: string; name: string }[];
    sort: { value: string; label: string }[];
  };
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  disabled?: boolean;
};

const GroupAndColumns = ({
  filtersOptions,
  filters,
  setFilters,
  disabled,
}: GroupAndColumnsProps) => {
  const defaultFields = [
    // 'id',
    'name',
    filtersOptions.occassions.length === 1 || filters.groupBy === 'occassion'
      ? ''
      : 'occassion',
    'time',
    'tables',
    filtersOptions.spaces.length > 1 && filters.groupBy !== 'space'
      ? 'space'
      : '',
    'allComments',
    //   'comment',
    //   'hostComment',
  ];

  const { customFields } = useContext(ReservationContext);

  const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null);

  const handleClick = (e: React.SyntheticEvent) => setAnchorEl(e.currentTarget);

  const handleClose = (e: React.MouseEvent<Document>) => setAnchorEl(null);

  const toggleColumn = (column: string) => {
    if (disabled) return;
    setFilters((f) => {
      let columns = f.columns ?? defaultFields;

      if (columns.includes(column)) {
        columns = columns.filter((c) => c !== column);
      } else {
        columns.push(column);
      }

      const ids = [...COLUMN_IDS, ...customFields];

      return {
        ...f,
        columns: columns.sort((a, b) => ids.indexOf(a) - ids.indexOf(b)),
      };
    });
  };

  const handleGroupBy = (
    groupBy: 'none' | 'occassion' | 'space' | 'time' | 'table' | undefined
  ) => {
    setFilters((f) => {
      return {
        ...f,
        groupBy,
      };
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const activeFilters = [
    filters?.occassions?.length < filtersOptions?.occassions?.length,
    filters?.spaces?.length,
  ].filter((x) => !!x).length;

  return (
    <>
      <IconButton size="small" onClick={handleClick}>
        <Badge number={activeFilters}>
          <Category style={{ transform: 'scaleX(-1)' }} />
        </Badge>
      </IconButton>
      <Popover
        {...{ anchorEl, open, id }}
        width="auto"
        onClose={handleClose}
        placement="bottom-end"
      >
        <Box style={{ display: 'flex', gap: 8, alignItems: 'flex-start' }}>
          <Box style={{ minWidth: 300, width: 300 }}>
            <Box style={{ marginBottom: 8 }}>
              <Typography
                variant="text-2"
                color="subdued"
                translation="reservations"
              >
                Group By
              </Typography>
              <RadioButtonGroup
                options={GROUP_BY.map((s) => ({
                  ...s,
                  translation: 'reservations',
                }))}
                value={filters.groupBy ?? 'none'}
                name="sort.reservations"
                onChange={(e) => handleGroupBy(e.currentTarget.value as any)}
              />
              <Checkbox
                label="Leere Gruppen ausblenden"
                checked={!!filters.hideEmptyTables}
                onChange={() =>
                  setFilters((f) => ({
                    ...f,
                    hideEmptyTables: !f.hideEmptyTables,
                  }))
                }
                color="primary"
                style={{ marginLeft: -12 }}
              />
              <Checkbox
                label="Add Ons Auflistung anzeigen"
                checked={filters.mode === 'addons'}
                onChange={() =>
                  setFilters((f) => ({
                    ...f,
                    mode: f.mode === 'addons' ? 'normal' : 'addons',
                  }))
                }
                color="primary"
                style={{ marginLeft: -12 }}
              />
            </Box>
          </Box>
          <Box
            style={{
              minWidth: 300,
              width: 300,
              opacity: disabled ? '0.5' : '1',
              maxHeight: 400,
              overflowY: 'auto',
            }}
          >
            <Box className="flex space-between">
              <Typography
                variant="text-2"
                color="subdued"
                translation="reservations"
              >
                Columns
              </Typography>
              <Typography
                variant="text-2"
                color="subdued"
                translation="reservations"
                weight="medium"
                onClick={() => {
                  if (!disabled)
                    setFilters((f) => ({ ...f, columns: undefined }));
                }}
                style={{ cursor: 'pointer' }}
              >
                zurücksetzen
              </Typography>
            </Box>
            {COLUMNS.map((col) => (
              <Checkbox
                key={'col-' + col.id}
                label={col.title}
                translation={'reservations'}
                name={'column.' + col.id}
                checked={!!(filters.columns ?? defaultFields)?.includes(col.id)}
                onChange={() => toggleColumn(col.id)}
                color="primary"
              />
            ))}
            {customFields.map((col) => (
              <Checkbox
                key={'col-' + col}
                label={col}
                name={'column.' + col}
                checked={!!(filters.columns ?? defaultFields)?.includes(col)}
                onChange={() => toggleColumn(col)}
                color="primary"
              />
            ))}
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default GroupAndColumns;
