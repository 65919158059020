import React, { useEffect, useState } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import NavBar from 'Components/Organisms/NavBar';
import FeatureNavigation from 'Components/Molecules/FeatureNavigation';
import { paths } from 'App/ReservationBook/shared';
import { dateHelper, toEuropeanDate } from 'utils/helper';
import Container from 'Components/Atoms/Container';
import useCollection from 'CustomHooks/useCollection';
import {
  RequestStatus,
  Reservation,
  WaitingListEntry,
} from 'gastronaut-shared/types/helper/reservations';

import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  ColumnDef,
  OnChangeFn,
  flexRender,
} from '@tanstack/react-table';
import IconButton from 'Components/Atoms/IconButton';
import { Link } from '@material-ui/icons';
import Typography from 'Components/Atoms/Typography';
import { ReservationStatus, WaitingListStatus } from 'types/reservations';
import { useRouter } from 'CustomHooks/useRouter';
import ReservationPrintModal from 'App/ReservationBook/Components/ReservationPrintModal';

export type LargeGroupListProps = {
  restaurantId: string;
};

function reservationStatusSwitch(status: ReservationStatus, date: string) {
  switch (status) {
    case ReservationStatus.FAILED: {
      return 'Abgesagt';
    }
    case ReservationStatus.SUCCESS: {
      return 'Bestätigt';
    }
    default: {
      if (date < dateHelper()) return 'Abgelaufen';
      return 'Nicht bestätigt';
    }
  }
}

function waitinglistStatusSwitch(
  status: WaitingListStatus | RequestStatus,
  date: string
) {
  switch (status) {
    case WaitingListStatus.REMOVED: {
      return 'Abgesagt';
    }
    case WaitingListStatus.SEATED: {
      return 'Bestätigt';
    }
    case WaitingListStatus.NOTIFIED: {
      if (date < dateHelper()) return 'Abgelaufen';
      return 'Benachrichtigt';
    }
    case WaitingListStatus.CANCELED: {
      return 'Storniert';
    }
    case RequestStatus.SUCCESS: {
      return 'Bestätigt';
    }
    case RequestStatus.FAILED: {
      return 'Abgesagt';
    }
    case RequestStatus.PENDING: {
      if (date < dateHelper()) return 'Abgelaufen';
      return 'Nicht bestätigt';
    }
    default: {
      if (date < dateHelper()) return 'Abgelaufen';
      return status;
    }
  }
}

const useLargeGroup = (restaurantId: string) => {
  const [limit, setlimit] = React.useState(101);

  const router = useRouter();

  const [requests] = useCollection<WaitingListEntry>('waitinglistEntries', {
    filter: [
      ['restaurant', '==', restaurantId],
      ['type', '==', 'largeGroup'],
    ],
    sort: ['createdAt', 'desc'],
    limit,
    keepDataWhileLoading: true,
  });

  const columns = React.useMemo<ColumnDef<WaitingListEntry, any>[]>(
    () => [
      {
        header: 'Code',
        cell: (info) => '#' + info.getValue(),
        accessorKey: 'customId',
      },
      {
        header: 'Name',
        cell: (info) => info.getValue(),
        accessorKey: 'guest.name',
      },
      {
        header: 'Status',
        accessorFn: (row) => waitinglistStatusSwitch(row.status, row.date),
        cell: (info) => info.getValue(),
      },
      {
        header: 'Gäste',
        accessorKey: 'guests',
        cell: (info) => info.getValue(),
      },
      {
        header: 'Datum & Uhrzeit',
        accessorFn: (row) => toEuropeanDate(row.date) + ' ' + row.time,
        cell: (info) => info.getValue(),
      },
      {
        header: 'Erstellt am',
        cell: (info) => new Date(info.getValue()).toLocaleDateString('de-DE'),
        accessorKey: 'createdAt',
      },
      {
        header: 'Telefon',
        cell: (info) => info.getValue(),
        accessorKey: 'guest.phone',
      },
      {
        header: 'Email',
        cell: (info) => info.getValue(),
        accessorKey: 'guest.email',
      },
      {
        header: 'Kommentar',
        cell: (info) => info.getValue(),
        accessorKey: 'guest.comment',
      },
      {
        header: ' ',
        cell: (info) => (
          <>
            {info.row.original.status !== RequestStatus.CANCELED && (
              <IconButton
                onClick={() =>
                  router.push(
                    `/${restaurantId}/reservations/${info.row.original.date}/tablePlan?waitinglistId=${info.row.original.id}`
                  )
                }
                size="x-small"
              >
                <Link />
              </IconButton>
            )}
          </>
        ),
      },
    ],
    []
  );

  const filteredRequests = React.useMemo(() => {
    return requests.data.filter(
      (r) =>
        (r.status === RequestStatus.PENDING ||
          r.status === RequestStatus.WAITING) &&
        r.date >= dateHelper()
    );
  }, [requests.data]);

  const table = useReactTable({
    data: filteredRequests,
    columns,
    // Pipeline
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    //
    initialState: {
      pagination: {
        pageSize: 10,
        pageIndex: 0,
      },
    },
  });

  useEffect(() => {
    if (
      !table.getCanNextPage() &&
      !!table?.options?.state?.pagination?.pageIndex &&
      requests.data.length &&
      !requests.loading
    ) {
      console.log('xx', limit, requests.data.length);
      if (limit === requests.data.length) {
        setlimit((limit) => limit + 21);
      }
    }
  }, [table.options.state.pagination?.pageIndex]);

  return [table];
};

const useLastReservations = (restaurantId: string) => {
  const [limit, setlimit] = React.useState(31);

  const router = useRouter();

  const [requests] = useCollection<Reservation>('requestsV03', {
    filter: [
      ['restaurant', '==', restaurantId],
      ['createdAt', '<', 2698496948439],
    ],
    sort: ['createdAt', 'desc'],
    limit,
    keepDataWhileLoading: true,
  });

  const columns = React.useMemo<ColumnDef<Reservation, any>[]>(
    () => [
      {
        header: 'Code',
        cell: (info) => '#' + info.getValue(),
        accessorKey: 'customId',
      },
      {
        header: 'Name',
        cell: (info) => info.getValue() ?? null,
        accessorKey: 'guest.name',
      },
      {
        header: 'Status',
        cell: (info) =>
          reservationStatusSwitch(info.getValue(), info.row.original.date),
        accessorKey: 'status',
      },
      {
        header: 'Gäste',
        accessorKey: 'guests',
        cell: (info) => info.getValue() ?? null,
      },
      {
        header: 'Datum & Uhrzeit',
        accessorFn: (row) => toEuropeanDate(row.date) + ' ' + row.time,
        cell: (info) => info.getValue() ?? null,
      },
      {
        header: 'Erstellt am',
        cell: (info) =>
          new Date(info.getValue()).toLocaleDateString('de-DE') ?? null,
        accessorKey: 'createdAt',
      },
      {
        header: 'Telefon',
        cell: (info) => info.getValue() ?? null,
        accessorKey: 'guest.phone',
      },
      {
        header: 'Email',
        cell: (info) => info.getValue() ?? null,
        accessorKey: 'guest.email',
      },
      {
        header: 'Kommentar',
        cell: (info) => info.getValue() ?? null,
        accessorKey: 'guest.comment',
      },
      {
        header: ' ',
        cell: (info) =>
          (
            <>
              {info.row.original.status !== 'failed' && (
                <IconButton
                  onClick={() =>
                    router.push(
                      `/${restaurantId}/reservations/${info.row.original.date}/tablePlan?reservationId=${info.row.original.id}`
                    )
                  }
                  size="x-small"
                >
                  <Link />
                </IconButton>
              )}
            </>
          ) ?? null,
      },
    ],
    []
  );

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { filteredRequests, pageCount } = React.useMemo(() => {
    let filtered = requests.data.filter(
      (x) => !x.walkIn && !x.blocked && !x.validTill
    );

    return {
      filteredRequests:
        filtered.slice(
          pagination.pageIndex * pagination.pageSize,
          (pagination.pageIndex + 1) * pagination.pageSize
        ) ?? filtered,
      pageCount: Math.ceil(filtered.length / pagination.pageSize),
    };
  }, [requests.data, pagination]);

  const table = useReactTable({
    data: filteredRequests,
    columns,
    // Pipeline
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    manualPagination: true,
    pageCount,
  });

  // useEffect(() => {
  //   if (!table.getCanNextPage() && requests.data.length && !requests.loading) {
  //     if (limit === requests.data.length) {
  //       setlimit(
  //         (limit) =>
  //           limit +
  //           Math.round(
  //             (table?.options?.state?.pagination?.pageSize ?? 20) * 1.5
  //           )
  //       );
  //     }
  //   }
  // }, [
  //   table.options.state.pagination?.pageIndex,
  //   requests.data.length,
  //   table.options.state.pagination?.pageSize,
  // ]);

  useEffect(() => {
    if (pageCount <= pagination.pageIndex + 2 && !!requests.data.length) {
      if (limit === requests.data.length) {
        setlimit((limit) => limit + pagination.pageSize);
      }
    }
  }, [pagination.pageIndex, pageCount]);

  useEffect(() => {
    setlimit((l) => Math.max(l, pagination.pageSize * 4 + 1));
  }, [pagination.pageSize, pageCount]);

  return [table];
};

const LargeGroupList = ({ restaurantId }: LargeGroupListProps) => {
  const [largeGroupTable] = useLargeGroup(restaurantId);
  const [lastReservationTable] = useLastReservations(restaurantId);

  return (
    <Box background id="reservation-stat-screen">
      <NavBar
        title="Anfragen & L. Reservierungen"
        className="fixed-nav"
        mainProduct={{
          label: 'Reservations',
          link: (rId) => `/${rId}/reservations/${dateHelper()}/tablePlan`,
        }}
        reservationBook
        right={
          //   <Button
          //     variant="primary"
          //     translation="reservations"
          //     onClick={() => {
          //       history?.push(`/${restaurantId}/reservations/${date}/tablePlan`);
          //       handleNewReservation(date);
          //     }}
          //     endIcon={(p) => <Add {...p} />}
          //   >
          //     New Reservation
          //   </Button>
          <></>
        }
        childrenContainerStyle={{
          width: '270px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          transform: 'translateX(-100px)',
        }}
      ></NavBar>
      <FeatureNavigation
        date={dateHelper()}
        paths={paths}
        current="largeGroups"
        className="featureNavigation"
      />
      <Container variant="reservations">
        <Box>
          <Box>
            <Typography
              style={{ display: 'block', marginBottom: 8 }}
              variant="h3"
            >
              Große Gruppen
            </Typography>
            <Box className="largeGroupTable">
              <table>
                <thead>
                  {largeGroupTable.getHeaderGroups()?.map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        return (
                          <th key={header.id} colSpan={header.colSpan}>
                            {header.isPlaceholder ? null : (
                              <div>
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {/* {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} table={table} />
                          </div>
                        ) : null} */}
                              </div>
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  )) ?? <></>}
                </thead>
                <tbody>
                  {largeGroupTable.getRowModel()?.rows?.map((row) => {
                    return (
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <td key={cell.id}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  }) ?? <></>}
                </tbody>
              </table>
              <div className="pagination">
                <button
                  className="border rounded p-1"
                  onClick={() => largeGroupTable.setPageIndex(0)}
                  disabled={!largeGroupTable.getCanPreviousPage()}
                >
                  {'<<'}
                </button>
                <button
                  className="border rounded p-1"
                  onClick={() => largeGroupTable.previousPage()}
                  disabled={!largeGroupTable.getCanPreviousPage()}
                >
                  {'<'}
                </button>
                <button
                  className="border rounded p-1"
                  onClick={() => largeGroupTable.nextPage()}
                  disabled={!largeGroupTable.getCanNextPage()}
                >
                  {'>'}
                </button>
                <button
                  className="border rounded p-1"
                  onClick={() =>
                    largeGroupTable.setPageIndex(
                      largeGroupTable.getPageCount() - 1
                    )
                  }
                  disabled={!largeGroupTable.getCanNextPage()}
                >
                  {'>>'}
                </button>
                <span className="flex items-center gap-1">
                  <div style={{ marginRight: 2 }}>Seite </div>
                  <strong>
                    {largeGroupTable.getState().pagination.pageIndex + 1} von{' '}
                    {largeGroupTable.getPageCount()}
                  </strong>
                </span>
                <select
                  value={largeGroupTable.getState().pagination.pageSize}
                  onChange={(e) => {
                    largeGroupTable.setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize} pro Seite
                    </option>
                  ))}
                </select>
              </div>
            </Box>
          </Box>

          <Box>
            <Box style={{ display: 'flex', gap: 10 }}>
              <Typography
                style={{
                  display: 'block',
                  marginBottom: 8,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                variant="h3"
              >
                Letzte Reservierungen
              </Typography>
              <ReservationPrintModal
                {...{
                  restaurantId,
                  date: dateHelper(),
                  lastReservations: true,
                }}
              />
            </Box>

            <Box className="largeGroupTable">
              <table>
                <thead>
                  {lastReservationTable
                    ?.getHeaderGroups()
                    ?.map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                          return (
                            <th key={header.id} colSpan={header.colSpan}>
                              {header.isPlaceholder ? null : (
                                <div>
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                  {/* {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} table={table} />
                          </div>
                        ) : null} */}
                                </div>
                              )}
                            </th>
                          );
                        })}
                      </tr>
                    )) ?? <></>}
                </thead>
                <tbody>
                  {lastReservationTable.getRowModel()?.rows?.map((row) => {
                    return (
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <td key={cell.id}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  }) ?? <></>}
                </tbody>
              </table>
              <div className="pagination">
                <button
                  className="border rounded p-1"
                  onClick={() => lastReservationTable.setPageIndex(0)}
                  disabled={!lastReservationTable.getCanPreviousPage()}
                >
                  {'<<'}
                </button>
                <button
                  className="border rounded p-1"
                  onClick={() => lastReservationTable.previousPage()}
                  disabled={!lastReservationTable.getCanPreviousPage()}
                >
                  {'<'}
                </button>
                <button
                  className="border rounded p-1"
                  onClick={() => lastReservationTable.nextPage()}
                  disabled={!lastReservationTable.getCanNextPage()}
                >
                  {'>'}
                </button>
                <button
                  className="border rounded p-1"
                  onClick={() =>
                    lastReservationTable.setPageIndex(
                      lastReservationTable.getPageCount() - 1
                    )
                  }
                  disabled={!lastReservationTable.getCanNextPage()}
                >
                  {'>>'}
                </button>
                <span className="flex items-center gap-1">
                  <div style={{ marginRight: 2 }}>Seite </div>
                  <strong>
                    {lastReservationTable.getState().pagination.pageIndex + 1}{' '}
                    von {lastReservationTable.getPageCount()}
                  </strong>
                </span>
                <select
                  value={lastReservationTable.getState().pagination.pageSize}
                  onChange={(e) => {
                    lastReservationTable.setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50, 100].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize} pro Seite
                    </option>
                  ))}
                </select>
              </div>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default LargeGroupList;
