import React, { useEffect, useMemo, useState } from 'react';
import Box from 'Components/Atoms/Box';
import Typography from 'Components/Atoms/Typography';
import Button from 'Components/Atoms/Button';
import { Event, EventOption } from 'types/calendar';
import Translations from 'App/Settings/Components/Translations';
import Modal from 'Components/Molecules/Modal';
import ImageField from 'Components/Organisms/ImageField';
import TextField from 'Components/Atoms/TextField';
import { Translation } from 'utils/types';
import IconButton from 'Components/Atoms/IconButton';
import { Close } from 'Components/Atoms/Icons';
import { Severity } from 'Contexts/RestaurantContext';
import useToast from 'CustomHooks/useToast';
import InputAdornmentAtom from 'Components/Atoms/InputAdornment';
import RadioButtonGroup from 'Components/Atoms/RadioButtonGroup';
import Tooltip from 'Components/Atoms/Tooltip';

type EditOptionModalProps = {
  openEditOptions:
    | false
    | {
        isOpen: boolean;
        optId: string | null;
        type: 'options' | 'addons';
      };
  setOpenEditOptions: React.Dispatch<
    React.SetStateAction<
      | false
      | {
          isOpen: boolean;
          optId: string | null;
          type: 'options' | 'addons';
        }
    >
  >;
  setEventState: React.Dispatch<React.SetStateAction<Event | null>>;
  eventState: Event | null;
};

const EditOptionModal = ({
  openEditOptions,
  setOpenEditOptions,
  setEventState,
  eventState,
}: EditOptionModalProps) => {
  const toast = useToast();

  const [state, setstate] = useState<EventOption | undefined>();

  useEffect(() => {
    if (
      openEditOptions &&
      openEditOptions.optId &&
      openEditOptions.optId !== state?.id
    ) {
      setstate(
        eventState?.options?.find((o) => o.id === openEditOptions.optId)
      );
    } else if (!openEditOptions) {
      setstate(undefined);
    }
  }, [openEditOptions]);

  const originalOption = useMemo(
    () =>
      eventState &&
      eventState.options &&
      openEditOptions &&
      [...eventState.options].find((o) => o.id === openEditOptions.optId),
    [openEditOptions]
  );

  const modifyOption = (toEditOption: EventOption) => {
    if (eventState?.options && openEditOptions) {
      const filteredOptions = [...eventState?.options].filter(
        (o) => o.id !== openEditOptions.optId
      );

      setEventState({
        ...eventState,
        options: [...filteredOptions, toEditOption],
      });
    }
  };

  const handleCancel = () => setOpenEditOptions(false);

  const handleSave = () => {
    console.log({
      state,
      eventState,
    });

    if (state) {
      setEventState((eS) => {
        if (eS) {
          return {
            ...eS,
            options: eS.options?.map((o) => {
              if (o.id === state.id) {
                return {
                  ...state,
                  id: state.id === 'newOpt' ? `${Date.now()}` : state.id,
                };
              }
              return o;
            }),
          };
        }
        return eS;
      });
      setOpenEditOptions(false);
    }
  };

  //INTERNALNAME
  const handleNameChange = (name: string) => {
    if (eventState?.options && openEditOptions) {
      let toEditOption: any = {
        ...eventState.options.find((o) => o.id === openEditOptions.optId),
      };

      toEditOption.internalName = name;

      modifyOption(toEditOption);
    }
  };

  const handleVATChange = (rate: string) => {
    if (eventState?.options && openEditOptions) {
      let toEditOption: any = {
        ...eventState.options.find((o) => o.id === openEditOptions.optId),
      };

      toEditOption.vatRate = rate;
      modifyOption(toEditOption);
    }
  };

  const handleOptTitleAndDescriptionChange = (
    e: Record<string, Translation<string>>
  ) => {
    const newText: Record<
      string,
      {
        title: string;
        description: string;
      }
    > = Object.entries(e).reduce(
      (acc, [key, value]) => ({ ...acc, [key]: value }),
      {}
    );

    if (eventState && eventState.options && openEditOptions) {
      let toEditOption: any = {
        ...eventState.options.find((o) => o.id === openEditOptions.optId),
      };

      toEditOption.translation = newText;

      modifyOption(toEditOption);
    }
  };

  const handleOptionType = (type: EventOption['type']) => {
    if (eventState?.options && openEditOptions) {
      let toEditOption: any = {
        ...eventState.options.find((o) => o.id === openEditOptions.optId),
      };

      toEditOption.type = type;

      modifyOption(toEditOption);
    }
  };

  const handleOptionImage = (e: string | (string | null)[] | null) => {
    if (eventState?.options && openEditOptions) {
      let toEditOption: any = {
        ...eventState.options.find((o) => o.id === openEditOptions.optId),
      };

      if (typeof e === 'string') toEditOption.img = e;

      modifyOption(toEditOption);
    }
  };

  const handleOptionLimited = (e: number) => {
    if (eventState?.options && openEditOptions) {
      let toEditOption: any = {
        ...eventState.options.find((o) => o.id === openEditOptions.optId),
      };

      toEditOption.limited = e;

      modifyOption(toEditOption);
    }
  };

  const handleOptionPrice = (e: number) => {
    if (openEditOptions) {
      setEventState((eS) => {
        if (eS) {
          return {
            ...eS,
            options: eS.options?.map((o) => {
              if (o.id === (openEditOptions?.optId ?? '')) {
                return {
                  ...o,
                  amount: e,
                };
              }
              return o;
            }),
          };
        }
        return eS;
      });
    }
  };

  const handleChange = (fn: (s: EventOption) => EventOption) => {
    setstate((x) => (!!x ? fn(x) : x));
  };

  const optionsVatRatesOptions = [
    {
      value: '7.00',
      label: '7% MwSt',
    },
    {
      value: '19.00',
      label: '19% MwSt',
    },
    {
      value: '0.00',
      label: '0% MwSt',
    },
    {
      value: '10.00',
      label: '10% MwSt (Österreich)',
    },
    {
      value: '20.00',
      label: '20% MwSt (Österreich)',
    },
  ];

  if (!eventState) {
    return <></>;
  }

  return (
    <Modal
      title="Options"
      maxWidth="lg"
      open={!!openEditOptions && openEditOptions.type === 'options'}
      headerRight={
        <Box flex style={{ justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            onClick={handleSave}
            style={{ marginRight: 16 }}
            typographyProps={{ variant: 'text-3' }}
          >
            Save
          </Button>
          <IconButton size="small" onClick={handleCancel}>
            <Close color="inherit" />
          </IconButton>
        </Box>
      }
    >
      {state && (
        <>
          <Box className="mg-bt-md">
            <Typography
              className="mg-bt-xs"
              variant="h5"
              translation="calendar"
            >
              Edit or add an option to this event
            </Typography>
            <Typography color="subdued" variant="text-3" translation="calendar">
              You have to choose a name for your option, for you to recognize it
              easily (18 characters max). Then you can modify its details.
            </Typography>
          </Box>

          <Box className="mg-bt-lg" style={{ maxWidth: '45%' }}>
            <TextField
              fullWidth
              required
              label="Choose an internal name"
              value={state.internalName || ''}
              onChange={(e) =>
                handleChange((s) => ({
                  ...s,
                  internalName: e.target.value,
                }))
              }
              style={{ minWidth: '46%' }}
              labelTranslation="calendar"
              inputProps={{ maxLength: 18 }}
            />
          </Box>

          <Box flex className="mg-bt-lg">
            <Box className="max-column-width">
              <Translations
                borders
                state={{
                  de: {
                    title: state.translation.de.title || '',
                    description: state.translation.de.description || '',
                  },
                  en: {
                    title: state.translation.en.title || '',
                    description: state.translation.en.description || '',
                  },
                }}
                onChange={(t) =>
                  handleChange((s) => ({
                    ...s,
                    translation: t as any,
                  }))
                }
                keys={[
                  {
                    id: 'title',
                    label: 'Title',
                    required: true,
                  },
                  {
                    id: 'description',
                    label: 'Description',
                    required: true,
                    multiline: true,
                  },
                ]}
                addLanguage={false}
              />
            </Box>
            <ImageField
              value={state?.img || ''}
              onChange={(e) =>
                handleChange((x) => ({
                  ...x,
                  img: Array.isArray(e) ? e?.[0] ?? '' : e ?? '',
                }))
              }
              label={''}
              style={{ marginLeft: 16 }}
              type="url"
            />
          </Box>

          <Box
            fullSize
            style={{
              justifyContent: 'space-between',
              flexWrap: 'nowrap',
            }}
            className="mg-bt-md"
          >
            <Box flex style={{ marginBottom: 8 }}>
              <Box>
                <Box flex>
                  <Typography
                    style={{ minWidth: 'fit-content', marginBottom: 4 }}
                    variant="text-3"
                    translation="calendar"
                    block
                  >
                    Price and VAT
                  </Typography>
                  <Tooltip
                    style={{ marginLeft: 4 }}
                    content={
                      <Typography variant="text-5">
                        Choose if this option is 7% VAT only, 19% VAT only, or
                        is concerned by both 7% & 19% VAT
                      </Typography>
                    }
                  >
                    <Typography variant="text-3" color="subdued">
                      🛈
                    </Typography>
                  </Tooltip>
                </Box>
                <Box flex className="mg-bt-sm">
                  <TextField
                    fullWidth
                    type="number"
                    label="Option price"
                    labelTranslation="calendar"
                    value={state?.amount || ''}
                    onChange={(e) =>
                      handleChange((x) => ({
                        ...x,
                        amount: Number(e.target.value),
                      }))
                    }
                    // style={{ minWidth: "46%" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornmentAtom position="end">
                          <Typography color="subdued">€</Typography>
                        </InputAdornmentAtom>
                      ),
                    }}
                  />
                </Box>
              </Box>

              <Box width="15%" />

              <Box>
                <Typography
                  block
                  variant="text-3"
                  translation="calendar"
                  style={{ marginBottom: 4 }}
                >
                  Quantity
                </Typography>
                <TextField
                  fullWidth
                  type="number"
                  label="Specify limited quantity"
                  labelTranslation="calendar"
                  value={state?.limited || ''}
                  onChange={(e) =>
                    handleChange((x) => ({
                      ...x,
                      limited: Number(e.target.value),
                    }))
                  }
                />
              </Box>
            </Box>
            <RadioButtonGroup
              style={{ display: 'flex' }}
              value={state.vatRate}
              options={optionsVatRatesOptions}
              onChange={(e: any) =>
                handleChange((x) => ({ ...x, vatRate: e.target.value }))
              }
              isRow
            />
          </Box>
        </>
      )}
    </Modal>
  );
};

export default EditOptionModal;
