import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const Rating5 = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="100"
      height="60"
      viewBox="0 0 100 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 8C0 3.58172 3.65482 0 8.16327 0H91.8367C96.3452 0 100 3.58172 100 8V52C100 56.4183 96.3452 60 91.8367 60H8.16327C3.65482 60 0 56.4183 0 52V8ZM30.6122 43.175L41.199 49.45C43.1378 50.6 45.5102 48.9 45 46.75L42.1939 34.95L51.5561 27C53.2653 25.55 52.3469 22.8 50.102 22.625L37.7806 21.6L32.9592 10.45C32.0918 8.42502 29.1327 8.42502 28.2653 10.45L23.4439 21.575L11.1224 22.6C8.87755 22.775 7.95918 25.525 9.66837 26.975L19.0306 34.925L16.2245 46.725C15.7143 48.875 18.0867 50.575 20.0255 49.425L30.6122 43.175ZM67.8745 47.1655C69.783 48.0533 71.9451 48.4972 74.3608 48.4972C76.9819 48.4972 79.2889 47.9645 81.2819 46.8991C83.287 45.822 84.8511 44.3482 85.9745 42.478C87.1099 40.5959 87.6776 38.4534 87.6776 36.0504C87.6776 33.7185 87.1703 31.647 86.1556 29.8359C85.141 28.0248 83.752 26.6044 81.9885 25.5746C80.225 24.5329 78.2199 24.0121 75.9733 24.0121C74.4755 24.0121 73.0986 24.2784 71.8424 24.8111C70.5862 25.3319 69.6139 25.9889 68.9254 26.7819H68.708L69.7769 17.1406H85.7389V11.6364H64.2147L62.2761 30.3864L68.3456 31.3629C68.9737 30.6882 69.8252 30.1437 70.9003 29.7294C71.9753 29.3151 73.0684 29.1139 74.1796 29.1257C75.5445 29.1257 76.7524 29.4335 77.8032 30.049C78.8541 30.6527 79.6815 31.4872 80.2854 32.5525C80.8893 33.6179 81.1853 34.8371 81.1732 36.2102C81.1853 37.5597 80.8954 38.7611 80.3035 39.8146C79.7117 40.8681 78.9024 41.6908 77.8757 42.2827C76.849 42.8745 75.6774 43.1704 74.3608 43.1704C72.6819 43.1704 71.2445 42.7029 70.0487 41.7677C68.8529 40.8208 68.1946 39.6016 68.0738 38.1101H61.5513C61.6238 40.1224 62.2157 41.9098 63.3269 43.4723C64.4502 45.0348 65.9661 46.2659 67.8745 47.1655Z"
        fill="#FFA800"
      />
    </SvgIcon>
  );
};

export default Rating5;
