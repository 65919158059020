import React from 'react';

const CuttleryIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width ?? '20'}
    height={props.height ?? '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_9507_29016)">
      <path d="M9.16663 0V12.5" stroke="currentColor" stroke-width="2" />
      <ellipse
        cx="9.16663"
        cy="15.8333"
        rx="2.5"
        ry="3.33333"
        stroke="currentColor"
        stroke-width="2"
      />
      <path
        d="M19.1667 4.40346C19.1667 7.16488 17.8609 10.832 16.25 10.832C14.6392 10.832 13.3334 7.16488 13.3334 4.40346C13.3334 1.64204 14.6392 0.832031 16.25 0.832031C17.8609 0.832031 19.1667 1.64204 19.1667 4.40346Z"
        stroke="currentColor"
        stroke-width="2"
      />
      <path
        d="M0.833374 20.8346V10.8346M0.833374 10.8346V1.66797C0.833374 1.66797 4.16671 2.91797 4.16671 9.16797C4.16671 10.0013 4.16671 10.8346 4.16671 10.8346H0.833374Z"
        stroke="currentColor"
        stroke-width="2"
      />
      <path
        d="M16.25 0.832031V19.9987"
        stroke="currentColor"
        stroke-width="2"
      />
    </g>
    <defs>
      <clipPath id="clip0_9507_29016">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CuttleryIcon;
