import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const CenterHorizontal = (props: SvgIconProps) => {
  return (
    <SvgIcon width="36" height="36" viewBox="0 0 36 36" fill="none">
      <rect width="36" height="36" rx="18" fill="#EDF2F7" />
      <path
        d="M27.375 17.375C27.7202 17.375 28 17.6548 28 18C28 18.3452 27.7202 18.625 27.375 18.625H23.625V17.375H27.375Z"
        fill="#011311"
      />
      <path
        d="M12.375 17.375V18.625H8.625C8.27982 18.625 8 18.3452 8 18C8 17.6548 8.27982 17.375 8.625 17.375H12.375Z"
        fill="#011311"
      />
      <path
        d="M22.375 8H13.625C13.2798 8 13 8.27982 13 8.625V27.375C13 27.7202 13.2798 28 13.625 28H22.375C22.7202 28 23 27.7202 23 27.375V8.625C23 8.27982 22.7202 8 22.375 8Z"
        fill="#011311"
      />
    </SvgIcon>
  );
};

export default CenterHorizontal;
