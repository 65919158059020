import React, { FC, useState } from "react";
import "./styles.scss";
import { StylesProvider } from "@material-ui/core/styles";
import {
  default as MaterialAutoComplete,
  AutocompleteProps,
} from "@material-ui/lab/Autocomplete";
import TextField from "../TextField";
import { Translations } from "utils/types";
import { Paper, styled } from "@material-ui/core";

type Option = {
  title: string;
  titleTranslation?: Translations;
  id?: string;
};

type AutoCompleteFieldProps = {
  id?: string;
  multiple?: boolean;
  label: string;
  labelTranslation?: Translations;
  options: Option[];
  value?: Option | Option[] | null;
  onChange: (newValue: Option | Option[] | null) => void;
  getOptionLabel?: (option: Option) => string;
  allowCustom?: boolean;
  onDialogOpen?: (inputValue: string) => void;
  style?: React.CSSProperties;
  disableClearable?: boolean;
  clearOnBlur?: boolean;
  disabled?: boolean;
};

const AutoComplete = ({
  id = "autocomplete",
  multiple = false,
  label,
  labelTranslation,
  options,
  value,
  onChange,
  getOptionLabel = (option: Option) => option.title,
  allowCustom = false,
  onDialogOpen = console.log,
  ...props
}: AutoCompleteFieldProps) => {
  const [inputValue, setInputValue] = useState<string>("");

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: string | Option | (string | Option)[] | null,
    reason: string
  ) => {
    if (!newValue) return;
    if (allowCustom && reason !== "remove-option") {
      if (typeof newValue === "string") {
        // timeout to avoid instant validation of the dialog's form.
        setTimeout(() => {
          onDialogOpen(newValue);
        });
      } else {
        let valueToCheck = Array.isArray(newValue)
          ? (newValue[newValue.length - 1] as Option)
          : (newValue as Option);
        const isNotInOptions = !options.find(
          (opt) =>
            opt.title.toLowerCase() === valueToCheck?.id?.toLocaleLowerCase() ||
            opt.title.toLowerCase() === valueToCheck?.title?.toLocaleLowerCase()
        );
        if (isNotInOptions) {
          onDialogOpen(valueToCheck?.id || "");
        } else {
          Array.isArray(newValue)
            ? onChange(newValue as Option[])
            : onChange(newValue as Option);
        }
      }
    } else {
      onChange(newValue as Option | Option[] | null);
    }
  };

  return (
    <MaterialAutoComplete
      id={id}
      options={options}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="filled"
          labelTranslation={labelTranslation}
        />
      )}
      value={value}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      filterOptions={(options, params) => {
        const filtered = options.filter((f) =>
          f.title.toLowerCase().includes(params.inputValue.toLowerCase())
        );

        if (params.inputValue !== "" && allowCustom) {
          filtered.push({
            id: params.inputValue,
            title: `Add "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      freeSolo={allowCustom}
      multiple={multiple}
      PaperComponent={({ children }) => (
        <Paper style={{ zIndex: 2000 }}>{children}</Paper>
      )}
      filterSelectedOptions
      {...props}
    />
  );
};

export default AutoComplete;
