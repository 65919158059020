import Box from "Components/Atoms/Box";
import Button from "Components/Atoms/Button";
import { Block, CheckCircle, LockOpen } from "Components/Atoms/Icons";
import React from "react";

export type TableButtonsProps = {
  onWalkin: (tables: string[], space?: string) => void;
  onToggleBlock: (id: string, name: string) => void;
  blocked: boolean;
  id: string;
  name: string;
  space?: string;
};

const TableButtons = ({
  onWalkin,
  blocked,
  onToggleBlock,
  id,
  name,
  space,
}: TableButtonsProps) => {
  return (
    <Box
      padding={10}
      className="flex-column"
      background
      style={{
        backgroundColor: "var(--color-paper)",
        zIndex: 102,
      }}
    >
      <Button
        fullWidth
        endIcon={() => <CheckCircle color="primary" />}
        typographyProps={{
          variant: "text-2",
          style: { marginRight: 8 },
        }}
        onClick={() => onWalkin([name], space)}
        translation="reservations"
        style={{ border: "1px solid var(--color-border)" }}
      >
        Walk in
      </Button>
      {blocked ? (
        <Button
          fullWidth
          variant="outlined"
          style={{
            marginTop: 16,
            borderColor: "var(--color-primary)",
          }}
          color="primary"
          endIcon={() => <LockOpen fontSize="small" />}
          typographyProps={{
            variant: "text-2",
            color: "primary",
            style: { marginRight: 8 },
          }}
          onClick={() => onToggleBlock(id, name)}
          translation="reservations"
        >
          Unblock Table
        </Button>
      ) : (
        <Button
          fullWidth
          variant="outlined"
          style={{
            marginTop: 16,
            color: "var(--color-critical)",
            borderColor: "var(--color-critical)",
          }}
          endIcon={() => <Block fontSize="small" />}
          typographyProps={{
            variant: "text-2",
            style: { color: "var(--color-critical)", marginRight: 8 },
          }}
          onClick={() => onToggleBlock(id, name)}
          translation="reservations"
        >
          Block Table
        </Button>
      )}
    </Box>
  );
};

export default TableButtons;
