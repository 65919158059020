import React from "react";
import Box from "Components/Atoms/Box";

export type BlinkerProps = {
  position: {
    top: number;
    left: number;
    width: number;
    height: number;
    opacity?: number;
  };
};

const Blinker = ({ position }: BlinkerProps) => {
  return <Box className="blinker" style={position}></Box>;
};

export default Blinker;
