import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const FixedReservationIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="28" height="28" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM14.4 26.21V19.045C14.92 19.145 15.455 19.2 16 19.2C16.545 19.2 17.08 19.145 17.6 19.045V26.21C17.6 26.46 17.54 26.705 17.431 26.9255L16.7155 28.3565C16.4205 28.946 15.5792 28.946 15.2844 28.3565L14.5689 26.9255C14.4578 26.7033 14.4 26.4584 14.4 26.21ZM8.8 10.4C8.8 6.4235 12.0235 3.2 16 3.2C19.975 3.2 23.2 6.42351 23.2 10.4C23.2 14.3765 19.9765 17.6 16 17.6C12.0235 17.6 8.8 14.3765 8.8 10.4ZM12.8 10.355C12.8 8.59101 14.236 7.15501 16 7.15501C16.44 7.15501 16.8 6.70721 16.8 6.31001C16.8 5.91281 16.4422 5.55501 16 5.55501C13.353 5.55501 11.2 7.70801 11.2 10.355C11.2 10.84 11.5578 11.155 12 11.155C12.4422 11.155 12.8 10.7972 12.8 10.355Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default FixedReservationIcon;
