import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const Rating2 = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="98"
      height="60"
      viewBox="0 0 98 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 8C0 3.58172 3.58172 0 8 0H90C94.4183 0 98 3.58172 98 8V52C98 56.4183 94.4183 60 90 60H8C3.58172 60 0 56.4183 0 52V8ZM30 43.175L40.375 49.45C42.275 50.6 44.6 48.9 44.1 46.75L41.35 34.95L50.525 27C52.2 25.55 51.3 22.8 49.1 22.625L37.025 21.6L32.3 10.45C31.45 8.42502 28.55 8.42502 27.7 10.45L22.975 21.575L10.9 22.6C8.7 22.775 7.8 25.525 9.475 26.975L18.65 34.925L15.9 46.725C15.4 48.875 17.725 50.575 19.625 49.425L30 43.175ZM60.2315 43.2415V48H85.1605V42.4957H69.3224V42.2472L75.5902 35.8729C77.9458 33.6001 79.7746 31.6648 81.0767 30.0668C82.3906 28.4687 83.308 27.0128 83.8288 25.6989C84.3497 24.3849 84.6101 23.0473 84.6101 21.6861C84.6101 19.6501 84.0952 17.839 83.0653 16.2528C82.0473 14.6667 80.6151 13.4178 78.7685 12.5064C76.9219 11.5949 74.7794 11.1392 72.3409 11.1392C69.938 11.1392 67.8073 11.6068 65.9489 12.5419C64.1023 13.477 62.6522 14.7969 61.5987 16.5014C60.5452 18.206 60.0185 20.2124 60.0185 22.5206H66.2862C66.2862 21.2777 66.5289 20.2064 67.0142 19.3068C67.5114 18.4072 68.2098 17.7147 69.1094 17.2294C70.009 16.7322 71.0566 16.4837 72.2521 16.4837C73.4122 16.4837 74.4479 16.7086 75.3594 17.1584C76.2708 17.6082 76.987 18.2474 77.5078 19.076C78.0286 19.8928 78.2891 20.8752 78.2891 22.0234C78.2891 23.0296 78.0878 23.9766 77.6854 24.8643C77.2829 25.7521 76.6792 26.6695 75.8743 27.6165C75.0694 28.5634 74.0632 29.6465 72.8558 30.8658L60.2315 43.2415Z"
        fill="#FFA800"
      />
    </SvgIcon>
  );
};

export default Rating2;
