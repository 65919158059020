import React, { useContext, useEffect, useState } from "react";
import "./styles.scss";
import Box from "Components/Atoms/Box";
import Modal from "../../Molecules/Modal";
import { Backspace, Close } from "@material-ui/icons";
import { Signature, SignatureContext } from "../../../Contexts/SignatureContext";
import TextField from "../../Atoms/TextField";
import Typography, { Colors } from "../../Atoms/Typography";
import { useMediaQuery } from "@material-ui/core";

type PinCodeModalProps = {
  open: boolean;
  onClose: () => Promise<void> | void;
  match?: string; // this is the hashed pin that needs to be matched,
  error?: null | string;
  onValidCode?: () => Promise<void> | void;
  type?: "validate" | "new" | "edit";
  signatureModalData: Signature;
  hashPincode: (s: string) => Promise<string>;
};

type PincodeMessage = {
  message?: string;
  color?: Colors;
};

const PincodeModal = ({
  open,
  onClose,
  type,
  match,
  error,
  onValidCode,
  signatureModalData,
  hashPincode,
}: PinCodeModalProps) => {
  const {
    signatures,
    pincodeModalData,
    setPincodeModalData,
    addSignature,
    editSignature,
  } = useContext(SignatureContext);
  //used to hold first pincode entry
  const [firstPincodeEntry, setFirstPincodeEntry] = useState<string>("");
  const [pincodeInput, setPincodeInput] = useState<string>("");
  const [pincodeMessage, setPincodeMessage] = useState<PincodeMessage>({});

  function pincodeInputHandler(value: string) {
    if (pincodeInput.length < 4) setPincodeInput((prev) => prev + value);
  }
  function backspaceHandler() {
    setPincodeInput((prev) => prev.substring(0, prev.length - 1));
  }

  async function validatePincode() {
    try {
      const hash = await hashPincode(pincodeInput).then(
        (hashed) => {
          return hashed;
        },
        () => {
          console.error("error");
        }
      );
      return hash === signatureModalData.code;
    } catch (e) {
      console.error(e);
      setPincodeMessage({ message: "Invalid pin. Try again.", color: "error" });
    }
  }

  async function editPincode() {
    if (await validatePincode()) {
      setPincodeModalData({ ...pincodeModalData, type: "new" });
      setPincodeMessage({
        message: "Valid pin. Enter new pin.",
        color: "secondary",
      });
    } else {
      setPincodeMessage({ message: "Invalid pin. Try again.", color: "error" });
      setPincodeInput("");
    }
  }

  async function pinCheckOnValidate() {
    if (await validatePincode()) {
      setPincodeMessage({ message: "Good pin.", color: "primary" });
      pincodeModalData.onValidCode && pincodeModalData.onValidCode();
      setPincodeModalData({ open: false });
    } else {
      setPincodeMessage({ message: "Invalid pin. Try again.", color: "error" });
      setPincodeInput("");
    }
  }

  useEffect(() => {
    const fn = (e: any) => {
      console.log(e.charCode);
      if (e.charCode === 0 || /\D/.test(String.fromCharCode(e.charCode))) {
        setPincodeInput((p) => (p += String.fromCharCode(e.charCode)));
      }
    };

    if (document) {
      document.addEventListener("onkeypress", fn);
    }

    return document.removeEventListener("onkeypress", fn);
  }, [document]);

  function closeModalHandler() {
    onClose();
    if (type === "new") {
      editSignature({ ...signatureModalData, code: null } as Signature);
    }
  }

  console.log({ pincodeInput });
  console.log({ firstPincodeEntry });
  useEffect(() => {
    if (type === "new") {
      //new pincode flow
      if (pincodeInput.length === 4 && firstPincodeEntry.length !== 4) {
        console.log("triggered first entry");
        setFirstPincodeEntry(pincodeInput);
        setPincodeInput("");
        setPincodeMessage({ message: "Confirm pin.", color: "secondary" });
      }
      if (
        pincodeInput.length === 4 &&
        firstPincodeEntry.length === 4 &&
        pincodeInput === firstPincodeEntry
      ) {
        console.log("triggered second entry - submission");
        !signatures.find((s) => s.signature === signatureModalData.signature)
          ? addSignature({ ...signatureModalData, code: pincodeInput })
          : editSignature({ ...signatureModalData, code: pincodeInput });
        setPincodeMessage({});
      }
      if (
        pincodeInput.length === 4 &&
        firstPincodeEntry.length === 4 &&
        pincodeInput !== firstPincodeEntry
      ) {
        setPincodeMessage({
          message: "PINs not matching. Try again.",
          color: "error",
        });
        setPincodeInput("");
      }
    }

    if (type === "edit" && pincodeInput.length === 4) {
      editPincode();
    }
    if (type === "validate" && pincodeInput.length === 4) {
      pinCheckOnValidate();
    }
  }, [pincodeInput, type]);

  useEffect(() => {
    setFirstPincodeEntry("");
    setPincodeInput("");
    setPincodeMessage({});
  }, [type]);

  return (
    <Modal
      open={open}
      onClose={closeModalHandler}
      title={type === "new" ? "Enter new pin code" : "Enter your pin code"}
      titleTranslation="settings"
      headerRight={<Close onClick={onClose} />}
    >
      <Box className="pincode-modal-container">
        <Box className="pincode-modal-input-container">
          <TextField
            fullWidth
            disabled={!!("ontouchstart" in document.documentElement)}
            type={type === "validate" ? "password" : "number"}
            hidePasswordIcon
            value={pincodeInput}
            onChange={(e) => setPincodeInput(e.target.value.slice(0, 4))}
            inputProps={{
              pattern: "[0-9]*",
              min: 0,
              max: 4,
              style: { textAlign: "center", fontSize: 20, fontWeight: "bold" },
            }}
          />
          <Backspace
            onClick={backspaceHandler}
            style={{ position: "absolute", right: 8, top: 8 }}
          />
          {error ||
            (pincodeMessage?.message && (
              <Box className="pincode-error-container">
                <Typography
                  variant="text-3"
                  translation="settings"
                  color={pincodeMessage?.color ?? "subdued"}
                >
                  {pincodeMessage?.message}
                </Typography>
              </Box>
            ))}
        </Box>
        <Box className="pincode-modal-digits-container">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((digit) => (
            <Box
              key={digit}
              className={digit !== 0 ? "digit-container" : "zero-digit"}
              onClick={() => pincodeInputHandler(digit.toString())}
            >
              <Typography variant="h3" textAlign="center" translation={null}>
                {digit}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Modal>
  );
};

export default PincodeModal;
