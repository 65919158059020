import React, { useState } from 'react';
import Button from 'Components/Atoms/Button';
import { CheckCircle } from 'Components/Atoms/Icons';
import './styles.scss';
import { EditReservation } from 'types/reservations';
import FirstStep from './Components/FirstStep';
import SecondStep from './Components/SecondStep';
import { noop } from 'utils/helper';
import SignatureDropdown from 'Components/Organisms/SignatureDropdown';
import Sidebar from 'Components/Molecules/Sidebar';
import Box from 'Components/Atoms/Box';
import { Trans } from 'react-i18next';
import { db } from 'utils/firebase';

export type NewReservationSidebarProps = {
  settings?: {
    hideResLength: boolean;
    hideOccassion: boolean;
  };
  occassions: { id: string; title: string }[];
  restaurantId: string;
  uid: string;
  newReservation: EditReservation;
  setnewReservation: React.Dispatch<
    React.SetStateAction<EditReservation | null>
  >;
  handleNext: () => Promise<void>;
  onClose: () => void;
  useSignature?: boolean;
  lightReservation?: boolean;
  setStorageTableStrs?: (option: string) => void;
};

const NewReservationSidebar = ({
  newReservation,
  setnewReservation,
  occassions,
  settings,
  restaurantId,
  uid,
  handleNext,
  onClose = noop,
  useSignature = false,
  lightReservation = false,
  setStorageTableStrs,
}: NewReservationSidebarProps) => {
  const [currentTable, setCurrentTable] = useState<string>('');

  const handleClose = () => {
    if (newReservation?.reservationId && newReservation?.new) {
      db.collection('requestsV03').doc(newReservation.reservationId).delete();
    }

    return onClose();
  };

  return (
    <Box style={{ height: '100%', minWidth: 242 }}>
      <Sidebar
        id="new-reservation"
        title={
          !!newReservation.addToWaitinglist && newReservation.page === 1
            ? 'Add to Waitinglist'
            : 'New Reservation'
        }
        titleTranslation="reservations"
        onClose={handleClose}
        hideOverflowGradient={newReservation.page === 1 ? 'bottom' : null}
        actions={
          newReservation.walkin || newReservation.page === 1 ? (
            <Button
              fullWidth
              endIcon={() => (
                <CheckCircle color="primary" style={{ marginLeft: 8 }} />
              )}
              onClick={handleNext}
              variant="outlined"
              style={{ backgroundColor: 'var(--color-bg)', marginTop: 12 }}
              // disabled={
              //   !newReservation.addToWaitinglist
              //   !newReservation?.guest?.name ||
              //   !newReservation?.guest?.email ||
              //   !newReservation?.guest?.phone
              // }
            >
              {!!newReservation.addToWaitinglist &&
              newReservation.page === 1 ? (
                <Trans i18nKey="reservations_Add to Waitinglist">
                  Add to Waitinglist
                </Trans>
              ) : (
                'Save'
              )}
            </Button>
          ) : (
            <Button
              fullWidth
              onClick={() => {
                if (setStorageTableStrs) setStorageTableStrs(currentTable);
                handleNext();
              }}
              variant="outlined"
              style={{ backgroundColor: 'var(--color-bg)', marginTop: 12 }}
            >
              Next
            </Button>
          )
        }
      >
        {newReservation.page === 0 && (
          <FirstStep
            reservation={newReservation}
            onReservationChange={setnewReservation}
            isNew
            lightReservation={lightReservation}
            {...{
              settings,
              occassions,
              restaurantId,
              uid,
              currentTable,
              setCurrentTable,
            }}
          />
        )}
        {newReservation.page === 1 && (
          <SecondStep
            reservation={newReservation}
            onReservationChange={setnewReservation}
            onSave={handleNext}
          />
        )}
        {(newReservation.walkin || newReservation.page === 1) &&
          useSignature && (
            <SignatureDropdown
              value={newReservation.waiterSignature}
              onChange={(waiterSignature) =>
                setnewReservation((nR) =>
                  nR !== null ? { ...nR, waiterSignature } : null
                )
              }
            />
          )}
      </Sidebar>
    </Box>
  );
};

export default NewReservationSidebar;
