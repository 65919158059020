import React from "react";

import "./styles.scss";

interface CurrentTimeIndicatorProps {
  width: number;
  top: number;
  left: number;
}
const CurrentTimeIndicator = ({ width, top, left }: CurrentTimeIndicatorProps) => {
  return (
    <div className="current-time-indicator" style={{ top: top, left: left }}>
      <div className="red-circle"></div>
      <div className="red-line" style={{ width: width }}></div>
    </div>
  );
};

export default CurrentTimeIndicator;
