import React, { useContext } from "react";
import Box from "Components/Atoms/Box";
import "./styles.scss";
import GastronautIcon from "Components/Atoms/GastronautIcon";
import Typography from "Components/Atoms/Typography";
import Dropdown from "Components/Molecules/Dropdown";
import { LanguageContext } from "Contexts/LanguageContext";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const AuthNavBar = () => {
  const { language, LANGUAGES, changeLanguage } = useContext(LanguageContext);

  const isMobile = useMediaQuery("(max-width: 450px)");

  return (
    <Box flex elevation={0} className="auth-navbar space-between corner">
      <Box flex>
        <GastronautIcon size={isMobile ? 24 : 40} />
        <Typography variant="h4" style={{ marginLeft: 16 }}>
          Gastronaut
        </Typography>
      </Box>
      <Dropdown
        options={LANGUAGES}
        value={language}
        onChange={changeLanguage}
        textAlign="right"
      />
    </Box>
  );
};

export default AuthNavBar;
