import React, { useContext, useState } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import { Add, Close } from 'Components/Atoms/Icons';
import Button from 'Components/Atoms/Button';
import FolderList from './Components/FolderList';
import ImageList from './Components/ImageList';
import NewFolderModal from './Components/NewFolderModal';
import Modal from 'Components/Molecules/Modal';
import ImageDetails from './Components/ImageDetails';
import { ImageContext } from 'Contexts/ImageContext';
import InstagramImagesList from './Components/InstagramImagesList';

export type ImageModalProps = {
  open: boolean;
  onClose: () => void;
  allowDelete?: boolean;
};

const ImageModal = ({
  open,
  onClose,
  allowDelete = false,
}: ImageModalProps) => {
  const [openFolderModal, setopenFolderModal] = useState<boolean>(false);

  const { currentImage, handleSubmit, setcurrentImage, currentFolder } =
    useContext(ImageContext);

  return (
    <Modal
      fullWidth
      maxWidth="xl"
      open={open}
      onClose={onClose}
      title="Image Gallery"
      titleTranslation="settings"
      headerRight={<Close onClick={onClose} />}
      bottomPart={
        <Box flex className="space-between bottom-part-image-modal">
          <Box style={{ width: 'fit-content' }}>
            <Button
              onClick={() => setopenFolderModal(true)}
              endIcon={() => <Add />}
              translation="settings"
            >
              Add Folder
            </Button>
          </Box>
          <Box style={{ width: 'fit-content' }}>
            {allowDelete && (
              <Button
                className="mr-16"
                onClick={() => {
                  setcurrentImage(null);
                  handleSubmit();
                }}
                variant="outlined"
                color="error"
                translation="settings"
              >
                Delete Image
              </Button>
            )}
            <Button onClick={onClose} className="mr-16">
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="primary"
              translation="settings"
            >
              Choose
            </Button>
          </Box>
        </Box>
      }
      bodyProps={{ padding: 0 }}
      style={{ maxWidth: 'calc(100% - 186px)' }}
    >
      <Box flex style={{ alignItems: 'stretch' }}>
        <FolderList />
        {currentFolder === 'instagram' && <InstagramImagesList />}
        {currentFolder !== 'instagram' && currentFolder !== '__shared__' && (
          <ImageList />
        )}
        {currentFolder === '__shared__' && <ImageList />}
        {currentImage && <ImageDetails />}
      </Box>
      {openFolderModal && (
        <NewFolderModal
          open={openFolderModal}
          onClose={() => setopenFolderModal(false)}
        />
      )}
    </Modal>
  );
};

export default ImageModal;
