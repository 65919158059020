import React, { useEffect, useState } from 'react';
import './styles.scss';
import Modal, { Action } from 'Components/Molecules/Modal';
import { Translations } from 'utils/types';
import Typography from 'Components/Atoms/Typography';
import TextField from 'Components/Atoms/TextField';
import Checkbox from 'Components/Atoms/Checkbox';
import Select from 'Components/Atoms/Select';
import SignatureDropdown from '../SignatureDropdown';

export type AlertState = {
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  fullWidth?: boolean;
  severity?: 'info' | 'warning' | 'danger';
  submitLabel?: string;
  onSubmit?: (
    secAction?: boolean,
    value?: string,
    signature?: string | null
  ) => void;
  onCancel?: () => void;
  stopAwayClick?: boolean;
  secondaryAction?: {
    label: string;
    labelTranslation?: Translations;
    default?: boolean;
  };
  signature?: boolean;
  actions?: Action[];
  title: string;
  titleTranslation?: Translations;
  description: string;
  descriptionTranslation?: Translations;
  inputFieldLabel?: string;
  inputFieldLabelTranslation?: Translations;
  dontCloseAfterSubmit?: boolean;
  inputOptions?: {
    id: string | number | null;
    label?: string;
    translation?: null | Translations;
  }[];
};

export type AlertProps = {
  state: AlertState | null;
  onClose: VoidFunction;
};

const Alert = ({ state, onClose }: AlertProps) => {
  const [secondaryAction, setsecondaryAction] = useState<boolean>(
    !!state?.secondaryAction?.default
  );

  const [textFieldValue, settextFieldValue] = useState<string>('');
  const [signature, setsignature] = useState('');
  const [error, seterror] = useState<string | null>(null);

  useEffect(() => {
    if (state?.inputOptions) {
      settextFieldValue(`${state.inputOptions[0].id}`);
    }
  }, [state?.inputOptions]);

  useEffect(() => {
    if (state && typeof state?.secondaryAction?.default === 'boolean') {
      setsecondaryAction(state.secondaryAction.default);
    }
  }, [state]);

  const handleClose = () => {
    seterror(null);
    setsignature('');
    settextFieldValue('');
    onClose();
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.stopPropagation();
    e.preventDefault();

    console.log(e);

    if (!state) return;

    if (state.signature && !signature) {
      console.log('signature is required');
      seterror('Please sign the document');
      return;
    }

    if (state.onSubmit) {
      state?.onSubmit(
        secondaryAction,
        textFieldValue,
        state.signature ? signature : undefined
      );
    }
    handleClose();
  };

  const ACTIONS: Action[] = state?.onSubmit
    ? [
        {
          id: 'cancel',
          label: 'Cancel',
          onClick: handleClose,
        },
        {
          id: 'submit',
          label: state?.submitLabel ?? 'Continue',
          variant: 'primary',
          onClick: handleSubmit,
        },
      ]
    : [
        {
          id: 'ok',
          label: 'Ok',
          variant: 'primary',
          onClick: handleClose,
        },
      ];

  return (
    <Modal
      open={!!state}
      onClose={() => {
        console.log('closed');
        handleClose();
        state?.onCancel?.();
      }}
      stopAwayClick={state?.stopAwayClick}
      onSubmit={handleSubmit}
      dontClose
      dontCloseAfterSubmit={state?.dontCloseAfterSubmit ?? false}
      maxWidth={state?.maxWidth || 'xs'}
      fullWidth={state?.fullWidth ?? true}
      actions={state?.actions || ACTIONS}
      title={state?.title}
      severity={state?.severity}
      titleTranslation={state?.titleTranslation}
      className="alert-state-wrapper-container"
    >
      {!!state?.description?.includes('<br/>') ? (
        <Typography
          variant="text-2"
          translation={state?.descriptionTranslation}
          dangerouslySetInnerHTML={{ __html: state.description }}
        />
      ) : (
        <Typography
          variant="text-2"
          translation={state?.descriptionTranslation}
        >
          {state?.description || ''}
        </Typography>
      )}

      {!!state?.inputFieldLabel && (
        <>
          {state.inputOptions ? (
            <Select
              options={state.inputOptions}
              value={textFieldValue}
              onChange={(e) => settextFieldValue(e.target.value as string)}
              style={{ marginTop: 8 }}
              margins={8}
              label={state.inputFieldLabel}
            />
          ) : (
            <TextField
              fullWidth
              style={{ marginTop: 8 }}
              label={state.inputFieldLabel}
              labelTranslation={state?.inputFieldLabelTranslation || 'common'}
              value={textFieldValue}
              onChange={(e) => settextFieldValue(e.target.value)}
            />
          )}
        </>
      )}
      {!!state?.secondaryAction && (
        <Checkbox
          checked={secondaryAction}
          onChange={(_, secAction) => setsecondaryAction(secAction)}
          label={state.secondaryAction.label}
          translation={state.secondaryAction.labelTranslation}
        />
      )}
      {!!state?.signature && (
        <SignatureDropdown
          value={signature}
          onChange={(sig) => {
            seterror(null);
            setsignature(sig);
          }}
          dropdownOptionStyle={{ maxHeight: '95vh', overflow: 'scroll' }}
        />
      )}
      {!!error && (
        <Typography color="error" variant="text-3" translation="reservations">
          {error}
        </Typography>
      )}
    </Modal>
  );
};

export default Alert;
