import React from "react";
import "./styles.scss";
import { Reservation } from "types/reservations";
import Modal from "Components/Molecules/Modal";
import Typography from "Components/Atoms/Typography";
import { ArrowForward, Cached } from "Components/Atoms/Icons";
import SwitchReservationInfos from "./Components/SwitchReservationInfos";

export type SwitchReservationModalProps = {
  reservations: Reservation[];
  onClose: VoidFunction;
  onSubmit: VoidFunction;
  onSecondaryOption: VoidFunction;
};

const SwitchReservationModal = ({
  reservations,
  onClose,
  onSubmit,
  onSecondaryOption,
}: SwitchReservationModalProps) => {
  if (reservations.length < 2) {
    return <></>;
  }

  return (
    <Modal
      open={true}
      onClose={onClose}
      onSubmit={onSubmit}
      actions={[
        {
          id: "placeOnTop",
          label: "Place anyway",
          translation: "reservations",
          alignLeft: true,
          onClick: onSecondaryOption,
        },
        {
          id: "no",
          label: "No, I don't",
          translation: "reservations",
          onClick: onClose,
        },
        {
          id: "yes",
          label: "Yes, I do",
          translation: "reservations",
          onClick: onSubmit,
          variant: "primary",
        },
      ]}
      maxWidth="sm"
      title="Switch Reservations"
      titleTranslation="reservations"
      style={{ minWidth: 663 }}
    >
      <SwitchReservationInfos {...{ reservations }} />
    </Modal>
  );
};

export default SwitchReservationModal;
