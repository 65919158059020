import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useEffect, useState } from "react";
import { noop } from "utils/helper";
import { Translations } from "utils/types";
import Box from "../Box";
import Tab from "./Components/Tab";
import "./styles.scss";
import Typography from "Components/Atoms/Typography";

export type Tab = {
  id: string | number | null;
  label: string;
  translation?: Translations;
  disabled?: boolean;
  badge?: number;
  cancelable?: boolean;
  style?: React.CSSProperties;
};

type TabsProps = {
  value: null | number | string;
  onChange: (id: string | number | null) => void;
  tabs: Tab[];
  id: string;
  variant?: "1" | "2";
  onCloseClick?: (id: string) => void;
  style?: React.CSSProperties;
  invitationsNo?: number;
  scrollOnOverflow?: boolean;
};

type ActiveBar = {
  width: number;
  left: number;
};

const Tabs = ({
  id,
  value = null,
  onChange = noop,
  tabs = [],
  variant = "1",
  onCloseClick = noop,
  style = {},
  invitationsNo = 0,
  scrollOnOverflow = false,
}: TabsProps) => {
  const isMobile = useMediaQuery("(max-width:450px)");
  return (
    <Box
      flex
      className="tabs"
      style={{
        justifyContent: isMobile ? "space-between" : "flex-start",
        ...style,
        overflow: scrollOnOverflow ? "scroll" : "auto",
      }}
      id={id}
    >
      {tabs.map((tab, index) => (
        <div>
          <Tab
            {...tab}
            variant={variant}
            active={value === tab.id}
            onClick={() => onChange(tab.id)}
            onCloseClick={onCloseClick}
            id={String(`${id}-${tab.id}`)}
            key={"tab" + index}
            style={{ whiteSpace: scrollOnOverflow ? "nowrap" : "normal" }}
            badge={tab.badge ?? invitationsNo}
          />
          {/* {tab.id === "invitations" && invitationsNo !== 0 && (
            <div className="tab-number">
              <Typography variant="text-5">{invitationsNo}</Typography>
            </div>
          )} */}
        </div>
      ))}
    </Box>
  );
};

export default Tabs;
