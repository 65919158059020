import React from 'react';
import Grid, { GridProps } from '@material-ui/core/Grid';
import './styles.scss';
import { classHelper } from 'utils/helper';

const Container = ({
  children,
  container,
  variant = 'dashboard',
  className,
  ...props
}: GridProps & { variant?: 'dashboard' | 'feature' | 'reservations' }) => {
  return (
    <div
      {...props}
      className={classHelper(['container ' + variant, className])}
    >
      <Grid container spacing={2}>
        {children}
      </Grid>
    </div>
  );
};

export default Container;
