import React from 'react';
import Box from 'Components/Atoms/Box';
import { default as MaterialModal } from '@material-ui/core/Modal';
import './styles.scss';
import Card, { CardProps } from '../Card';
import IconButton from 'Components/Atoms/IconButton';
import { Close } from 'Components/Atoms/Icons';
import { classHelper } from 'utils/helper';
import { Modify, Translations } from 'utils/types';
import Button, { ButtonVariants, ButtonProps } from 'Components/Atoms/Button';
import TabCard, { TabCardProps } from '../TabCard';

export type Action = ButtonProps & {
  id: string;
  label: string;
  onClick?: React.ReactEventHandler<Element>;
  translation?: Translations;
  variant?: ButtonVariants;
  alignLeft?: boolean;
  loading?: boolean;
  disabled?: boolean;
  submit?: boolean;
  href?: string;
  target?: string;
};

export type ModalProps = Modify<
  {
    open: boolean;
    onClose?: () => void;
    maxWidth?: 'sm' | 'md' | 'lg' | 'xl' | 'xs';
    fullWidth?: boolean;
    actions?: Action[];
    stopAwayClick?: boolean;
    children?: any;
    onSubmit?: (e: React.FormEvent) => Promise<void | boolean> | void | boolean;
    bottomPart?: React.ReactNode;
    dontClose?: boolean;
    dontCloseAfterSubmit?: boolean;
    title?: string;
    headerRight?: React.ReactNode;
    severity?: 'info' | 'warning' | 'danger';
    tabs?: TabCardProps['tabs'];
    value?: TabCardProps['value'];
    onChange?: TabCardProps['onChange'];
  },
  Omit<CardProps, 'onSubmit' | 'onChange'>
>;

const Modal = ({
  open,
  onClose,
  maxWidth = 'xs',
  fullWidth,
  actions,
  stopAwayClick = false,
  className,
  onSubmit,
  dontClose = false,
  dontCloseAfterSubmit = false,
  bottomPart,
  title,
  headerRight,
  severity = 'info',
  ...props
}: ModalProps) => {
  const classNames = classHelper([
    'modal',
    `modal-${maxWidth}`,
    fullWidth ? 'fullWidth' : 'autoWidth',
    className,
    `severity-${severity}`,
  ]);

  const Component: any = !!props.tabs?.length ? TabCard : Card;

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    // if (!dontClose) {
    //   onClose?.();
    // }
    const keepOpen = await onSubmit?.(e);

    if (!keepOpen && !dontClose) {
      onClose?.();
    }
  };

  return (
    <MaterialModal
      open={open}
      onClose={!stopAwayClick ? onClose : undefined}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Component
        {...props}
        title={title || ''}
        onSubmit={!!onSubmit ? handleSubmit : undefined}
        component={onSubmit ? 'form' : 'div'}
        headerRight={
          !headerRight ? (
            !!onClose && (
              <IconButton size="small" onClick={onClose}>
                <Close color="inherit" />
              </IconButton>
            )
          ) : (
            <>{headerRight}</>
          )
        }
        bottomPart={
          !!actions?.length ? (
            <Box flex style={{ justifyContent: 'flex-end' }}>
              {actions.map(
                (
                  {
                    id,
                    label,
                    submit = false,
                    alignLeft = false,
                    onClick,
                    ...button
                  },
                  i
                ) => (
                  <Box
                    key={id}
                    style={{
                      marginRight: i === actions.length - 1 ? 0 : 8,
                      flexGrow: alignLeft ? 10 : undefined,
                    }}
                    width="auto"
                  >
                    <Button
                      type={submit ? 'submit' : 'button'}
                      onClick={(e) => {
                        if (!!onClick) {
                          onClick(e);
                          if (!!onClose && !dontCloseAfterSubmit) {
                            onClose();
                          }
                        } else if (!!onClose && (!submit || !onSubmit)) {
                          onClose();
                        }
                      }}
                      {...button}
                    >
                      {label}
                    </Button>
                  </Box>
                )
              )}
            </Box>
          ) : (
            bottomPart
          )
        }
        className={classNames}
        elevation={4}
      />
    </MaterialModal>
  );
};

export default Modal;
