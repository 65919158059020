import React, { useRef, useEffect, useState } from "react";
const useImpulse = (int = 5000) => {
  const [impulse, setimpulse] = useState(0);
  const interval = useRef<any>(null);

  useEffect(() => {
    if (interval.current) {
      clearInterval(interval.current);
      interval.current = null;
    }
    interval.current = setInterval(() => {
      setimpulse((i) => i + 1);
    }, int);

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = null;
      }
    };
  }, [int]);

  return impulse;
};

export default useImpulse;
