import React, { useContext } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import Path from './Components/Path';
import { RestaurantContext } from 'Contexts/RestaurantContext';
import { useHistory } from 'react-router';
import usePinAuth, { SecurityPlusKeys } from 'CustomHooks/usePinAuth';

export type PathType = {
  id: string;
  name?: string;
  icon: JSX.Element;
  notifications?: number;
  authenticate?: string;
  // linkTo?: (rId: string, date?: string) => string;
  // onClick?: () => void;
} & (
  | { linkTo: (rId: string, date?: string) => string; onClick?: never }
  | { onClick: () => void; linkTo?: never }
);

export type FeatureNavigationProps = {
  paths: PathType[];
  current?: string;
  className?: string;
  warnOnLeave?: boolean;
  onLeave?: VoidFunction;
  date?: string;
  children?: React.ReactChildren | React.ReactChild;
};

const FeatureNavigation = ({
  paths = [],
  current,
  className = '',
  warnOnLeave = false,
  date,
  onLeave,
  children,
}: FeatureNavigationProps) => {
  const { restaurantId, alert } = useContext(RestaurantContext);

  const history = useHistory();

  const { authenticate } = usePinAuth();

  const handleClick =
    (authorization?: string) =>
    (fn: (rId: string, date?: string) => string) => {
      const path = fn(restaurantId || '', date);

      if (warnOnLeave) {
        alert({
          title: 'Are you sure?',
          description: 'You will loose the current progress!',
          onSubmit: () =>
            authenticate((authorization as any) ?? 'none', () => {
              onLeave?.();
              history?.push(path);
            }),
        });
      } else {
        authenticate((authorization as any) ?? 'none', () => {
          onLeave?.();
          history?.push(path);
        });
      }
    };

  return (
    <Box flex className={'flex-column featureNavigation ' + className}>
      {paths.map((path) => (
        <Path
          key={path.id}
          active={path.id === current}
          onClick={handleClick(path.authenticate)}
          path={path}
        />
      ))}
      {!!children && <Box className="bottomNavigation">{children}</Box>}
    </Box>
  );
};

export default FeatureNavigation;
