import React from 'react';
import useRestaurant from './useRestaurant';

const useExperimentalFlags = () => {
  const { experimentalFlags } = useRestaurant();

  return experimentalFlags;
};

export default useExperimentalFlags;
