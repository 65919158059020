import React, { useState } from "react";
import Box from "Components/Atoms/Box";
import "./styles.scss";
import { ReservationLength, ReservationLengthRule } from "types/shifts";
import Typography from "../../../../Components/Atoms/Typography";
import Button from "../../../../Components/Atoms/Button";
import AddEditReservationRuleModal from "./Components/AddEditReservationRuleModal";
import ReservationRuleRow from "./Components/ReservationRuleRow";
import useIsLightReservations from "CustomHooks/useIsLightReservations";
import SwitchBox from "Components/Atoms/Switch";
import { ReservationShift } from "gastronaut-shared/types/helper/shifts";

/*
    vip = Regular Guest
    specialOccassion = Special Occassion
    occassions = add occassion id to array if checked
    minGuests = either undefined or number
*/

export type ReservationLengthRulesProps = {
  reservationLength: ReservationLength;
  occassions: { id: string; title: string }[];
  onChange: (newValue: ReservationLength) => void;
  currentShift?: Partial<ReservationShift>;
};

const ReservationLengthRules: React.FC<ReservationLengthRulesProps> = ({
  reservationLength,
  currentShift,
  occassions,
  onChange,
}) => {
  const [modalState, setModalState] = useState<{
    type: "add" | "edit";
    open: boolean;
  }>({ open: false, type: "add" });
  const [selectedRule, setSelectedRule] = useState<ReservationLengthRule | {}>(
    {}
  );

  const onAddRuleSet = (rule: ReservationLengthRule) => {
    onChange({
      ...reservationLength,
      rules: [
        ...reservationLength.rules,
        { ...rule, index: reservationLength.rules.length },
      ],
    });
  };

  const onEditRuleSet = (rule: ReservationLengthRule) => {
    onChange({
      ...reservationLength,
      rules: reservationLength.rules.map((r) =>
        r.index === rule.index ? rule : r
      ),
    });
  };

  const onDeleteRuleSet = (rule: ReservationLengthRule) => {
    onChange({
      ...reservationLength,
      rules: reservationLength.rules.filter((r) => r.index !== rule.index),
    });
  };

  const modifySlotLength = (
    rule: ReservationLengthRule | number,
    incBy: number,
    type: "minLength" | "standard" = "standard"
  ) => {
    if (reservationLength.avoidDoubleSeating) return;

    if (typeof rule === "number") {
      let newVal =
        (reservationLength?.[type] ?? reservationLength.standard ?? 0) + incBy;

      let resLength = { ...reservationLength, [type]: newVal };

      if (
        resLength.minLength &&
        resLength.minLength >= reservationLength.standard
      ) {
        delete resLength.minLength;
      }

      onChange(resLength);
    } else {
      let newRule = rule;
      newRule.length = newRule.length + incBy;
      let newRules = reservationLength.rules.map((r) =>
        r.index === rule.index ? newRule : r
      );
      onChange({ ...reservationLength, rules: newRules });
    }
  };

  const closeModal = () => {
    setModalState({ ...modalState, open: false });
    setSelectedRule({});
  };

  return (
    <Box className="reservation-rules-container">
      <AddEditReservationRuleModal
        open={modalState.open}
        modalType={modalState.type}
        occassions={occassions}
        onAddRule={onAddRuleSet}
        onEditRule={onEditRuleSet}
        initialData={selectedRule}
        onClose={closeModal}
        minLength={reservationLength.standard}
        rulesLength={reservationLength.rules.length || 1}
        currentShift={currentShift}
      />
      <Box className="title-container">
        <Typography variant="h5" translation="settings">
          Reservation length
        </Typography>
        <Typography variant="text-3" color="subdued" translation="settings">
          Set the reservation length according to the size of the group. After
          an occupancy, the table is blocked for another 15 minutes.
        </Typography>
      </Box>
      <Box>
        <SwitchBox
          label="Avoid Double Seating?"
          color="primary"
          labelVariant="h5"
          checked={!!reservationLength.avoidDoubleSeating}
          onChange={(_, avoidDoubleSeating) =>
            onChange({ ...reservationLength, avoidDoubleSeating })
          }
          className="mg-bt-md"
          translation="calendar"
        />
      </Box>
      <Box
        className="table-container"
        style={
          !!reservationLength.avoidDoubleSeating
            ? { touchAction: "none", opacity: 0.6 }
            : {}
        }
      >
        <table className="reservation-table">
          <thead>
            <th className="rulesets-cell">
              <Typography
                variant="text-4"
                color="subdued"
                translation="settings"
              >
                Rulesets
              </Typography>
            </th>
            <th className="actions-cell"></th>
            <th className="plus-minus-cell"></th>
            <th className="length-cell">
              <Typography
                variant="text-4"
                color="subdued"
                translation="settings"
              >
                Reservation length
              </Typography>
            </th>
            <th className="plus-minus-cell"></th>
          </thead>
          <tbody>
            <ReservationRuleRow
              rule={{
                index: 100000,
                length: reservationLength.standard,
              }}
              id="standard"
              name="Standard"
              occassions={occassions}
              modifySlotLength={modifySlotLength}
            />
            <ReservationRuleRow
              rule={{
                index: 100001,
                length:
                  reservationLength.minLength ?? reservationLength.standard,
              }}
              id="minLength"
              name="Min. Reservationlength"
              occassions={occassions}
              modifySlotLength={modifySlotLength}
            />
            {reservationLength.rules &&
              reservationLength.rules.map((rule) => (
                <ReservationRuleRow
                  {...{ rule, modifySlotLength, occassions, onDeleteRuleSet }}
                  openModalState={(rule) => {
                    if (reservationLength.avoidDoubleSeating) return;
                    setSelectedRule(rule);
                    setModalState({ open: true, type: "edit" });
                  }}
                  minLength={reservationLength.standard}
                />
              ))}
          </tbody>
        </table>
        <Button
          variant="primary"
          translation="settings"
          onClick={() =>
            !reservationLength.avoidDoubleSeating &&
            setModalState({ open: true, type: "add" })
          }
        >
          Add Ruleset
        </Button>
      </Box>
    </Box>
  );
};

export default ReservationLengthRules;
