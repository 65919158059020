import React from "react";
import Box from "Components/Atoms/Box";
import "./styles.scss";
import Sidebar from "Components/Molecules/Sidebar";
import Typography from "Components/Atoms/Typography";
import ShiftTemplateIcon from "Components/Atoms/Icons/ShiftTemplateIcon";
import Divider from "Components/Atoms/Divider";
import {
  AnyShift,
  GuestOrderingShift,
  OffersShift,
  ReservationShift,
  ShiftType,
} from "types/shifts";
import { Translations } from "utils/types";

export type ShiftTemplateProps = {
  onClose: () => void;
  recentShifts: AnyShift[];
  onClick: (
    id: string,
    action: "fromTemplate" | "noTemplate" | "fromRecent" | "commentTemplate"
  ) => void;
  type: ShiftType;
};

const ShiftTemplates: {
  id: string;
  label: string;
  action: "fromTemplate" | "noTemplate" | "fromRecent" | "commentTemplate";
  type: ShiftType;
  translation: Translations;
}[] = [
  {
    id: "template-1",
    label: "Regular Shift",
    action: "fromTemplate",
    type: ShiftType.RESERVATION,
    translation: "calendar",
  },
  {
    id: "template-2",
    label: "Close Restaurant for a Day",
    action: "fromTemplate",
    type: ShiftType.RESERVATION,
    translation: "calendar",
  },
  {
    id: "template-3",
    label: "Close for a longer Period",
    action: "fromTemplate",
    type: ShiftType.RESERVATION,
    translation: "calendar",
  },
  {
    id: "template-4",
    label: "Special Event (e.g. Wedding)",
    action: "fromTemplate",
    type: ShiftType.RESERVATION,
    translation: "calendar",
  },
  {
    id: "template-5",
    label: "Regular Shift",
    action: "fromTemplate",
    type: ShiftType.GUEST_ORDERING,
    translation: "calendar",
  },
  {
    id: "template-6",
    label: "Regular Shift",
    action: "fromTemplate",
    type: ShiftType.HAPPY_HOURS,
    translation: "calendar",
  },
];

const Template: React.FC<{
  label: string;
  color: string;
  onClick?: VoidFunction;
  translation?: Translations;
}> = ({ label, color, onClick, translation }) => {
  return (
    <Box
      flex
      className="flex-start"
      onClick={onClick}
      style={{ cursor: "pointer", padding: "8px 0px", alignItems: "center" }}
    >
      <ShiftTemplateIcon style={{ color, marginRight: 12 }} />
      <Typography translation={translation ?? "reservations"} variant="text-3">
        {label}
      </Typography>
    </Box>
  );
};

const Templates: React.FC<{
  templates: {
    id: string;
    label: string;
    action: "fromTemplate" | "noTemplate" | "fromRecent" | "commentTemplate";
    translation?: Translations;
    type?: ShiftType;
  }[];
  color: string;
  onClick: (
    id: string,
    action: "fromTemplate" | "noTemplate" | "fromRecent" | "commentTemplate"
  ) => void;
  label: string;
}> = ({ label, templates, color, onClick }) => {
  return (
    <Box style={{ padding: "16px 0px" }}>
      <Typography
        variant="text-4"
        color="subdued"
        translation="calendar"
        className="mg-bt-sm"
      >
        {label}
      </Typography>
      {templates.map((tmp) => (
        <Template
          color={color}
          translation={tmp.translation}
          label={tmp.label}
          onClick={() => onClick(tmp.id, tmp.action)}
        />
      ))}
    </Box>
  );
};

const ShiftTemplate: React.FC<ShiftTemplateProps> = ({
  onClose,
  recentShifts,
  onClick,
  type,
}) => {
  return (
    <Box style={{ height: "100%" }}>
      <Sidebar
        id="create-new-reservation-shift"
        title={`Create New ${type} Shift`}
        titleTranslation="calendar"
        onClose={onClose}
        deductFromHeight={12}
      >
        <Templates
          label="Create a Shift"
          color="var(--color-primary)"
          templates={
            [
              {
                id: "no-template",
                action: "noTemplate",
                label: "Create Shift from Scratch",
                translation: "calendar",
              },
              {
                id: "comment-template",
                action: "commentTemplate",
                label: "Comment Shift (no impact on reservations)",
                translation: "calendar",
              },
            ].filter(
              (x) => x.id === "no-template" || type === ShiftType.RESERVATION
            ) as any[]
          }
          onClick={onClick}
        />
        <Divider text="Or" />
        <Templates
          label="Create from our Templates"
          color="var(--color-tertiary)"
          templates={ShiftTemplates.filter((t) => t.type === type)}
          onClick={onClick}
        />
        <Divider text="Or" />
        <Templates
          label="Kopiere aus wiederholenden Schichten"
          color="var(--color-secondary)"
          templates={recentShifts
            .filter((s) => s.regular)
            .map((s) => ({
              id: s.id,
              label: s.name ?? "",
              action: "fromRecent",
              translation: "calendar",
            }))}
          onClick={onClick}
        />
        <Divider text="Or" />
        <Templates
          label="Kopiere aus einmaligen Schichten"
          color="var(--color-success)"
          templates={recentShifts
            .filter((s) => !s.regular)
            .map((s) => ({
              id: s.id,
              label: s.name ?? "",
              action: "fromRecent",
              translation: "calendar",
            }))}
          onClick={onClick}
        />
      </Sidebar>
    </Box>
  );
};

export default ShiftTemplate;
