import React, { useEffect, useRef, useState } from 'react';
import Box from 'Components/Atoms/Box';
import IconButton from 'Components/Atoms/IconButton';
import { CheckCircle, CheckRounded, Edit } from 'Components/Atoms/Icons';
import Select from 'Components/Atoms/Select';
import { Translations } from 'utils/types';
import { InputBase } from '@material-ui/core';
import Checkbox from 'Components/Atoms/Checkbox';
import { classHelper } from 'utils/helper';

type EditableFieldProps = {
  field: {
    label?: string;
    type?: string | undefined;
    value: string;
    editable: boolean;
    action?: string | undefined;
    options?: {
      id: string | number | null;
      label?: string;
      translation?: null | Translations;
    }[];
  };
  id: string;
  onAction: (id: string, type: string, payload?: any) => void;
  oneLine?: boolean;
  placeholder?: string;
  hideButton?: boolean;
};

const EditableField = ({
  field,
  onAction,
  id,
  oneLine = true,
  placeholder,
  hideButton,
}: EditableFieldProps) => {
  const [isEdited, setIsEdited] = useState<boolean | 'saved'>(false);
  const ref = useRef<HTMLInputElement>(null);

  const [value, setvalue] = useState<string>(field.value);

  useEffect(() => {
    if (field.type !== 'checkbox') {
      setvalue(field.value !== '-' ? field.value : '');
    } else {
      console.log({ field });
    }
  }, [field.value, id]);

  const handleBlur = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onAction(id, e.target.name, e.target.value);
    setIsEdited('saved');
    setTimeout(() => setIsEdited(false), 10000);
  };

  const handleSelect = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    onAction(id, field.action || '', e.target.value);
    setIsEdited('saved');
    setTimeout(() => setIsEdited(false), 10000);
  };

  const handleOnEdit = () => {
    setIsEdited(true);
    if (ref && ref.current) {
      ref.current.focus();
    }
  };

  return (
    <Box
      flex
      className={classHelper([
        'editable-field',
        field.type === 'checkbox' && 'checkbox',
      ])}
      style={
        field.type === 'checkbox' || oneLine
          ? { justifyContent: 'flex-end' }
          : { justifyContent: 'space-between' }
      }
    >
      {field.type === 'checkbox' ? (
        <Checkbox
          checked={!!field.value}
          onChange={() => {
            onAction(id, field.action || '', !field.value);
            setIsEdited(true);
          }}
          disabled={!field.editable}
          size="small"
        />
      ) : (
        <>
          {field.type !== 'select' ? (
            <InputBase
              type={field.type}
              style={{
                width: field.type === 'date' ? 142 : 'auto',
                marginRight: 4,
              }}
              className={
                !oneLine
                  ? 'subdued-input text-2 '
                  : 'subdued-input text-2 text-right'
              }
              value={value}
              onChange={(e) => setvalue(e.target.value)}
              onBlur={handleBlur}
              name={field.action}
              ref={ref}
              multiline={!oneLine}
              onFocus={() => handleOnEdit()}
              placeholder={placeholder}
            />
          ) : (
            <Select
              options={field.options || []}
              value={value}
              name={field.action}
              onChange={handleSelect}
              onOpen={() => handleOnEdit()}
              className="subdued-input select"
              style={{ width: '100%', textAlign: 'right' }}
            />
          )}
          <>
            {!isEdited ? (
              <IconButton
                className="small-icon"
                disabled={hideButton}
                onClick={() => handleOnEdit()}
              >
                <Edit className="small-icon" />
              </IconButton>
            ) : isEdited === 'saved' ? (
              <IconButton disabled={hideButton} className="small-icon success">
                <CheckCircle className="small-icon success" />
              </IconButton>
            ) : (
              <IconButton disabled={hideButton} className="small-icon">
                <CheckRounded className="small-icon" />
              </IconButton>
            )}
          </>
        </>
      )}
    </Box>
  );
};

export default EditableField;
