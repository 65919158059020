import Typography from "Components/Atoms/Typography";
import Modal from "Components/Molecules/Modal";
import React from "react";

type ConfirmationModalProps = {
  openConfirmationModal: boolean;
  setopenConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
  guestName: string;
  guestId: string;
  handleDeleteGuest: (guestId: string) => Promise<void>;
};

const ConfirmationModal = ({
  openConfirmationModal,
  setopenConfirmationModal,
  guestName,
  guestId,
  handleDeleteGuest,
}: ConfirmationModalProps) => {
  return (
    <Modal
      open={openConfirmationModal}
      title="Delete a Guest"
      onClose={() => setopenConfirmationModal(false)}
      actions={[
        {
          id: "cancel",
          label: "Cancel",
          onClick: () => setopenConfirmationModal(false),
        },
        {
          id: "submit",
          label: "Confirm",
          onClick: () => {
            handleDeleteGuest(guestId);
            setopenConfirmationModal(false);
          },
          variant: "primary",
        },
      ]}
    >
      <Typography
        variant="text-2"
        translationKey="common_deleteGuestConfirmation"
      >
        Are you sure you want to delete {{ guestName }} from your Guests ?
      </Typography>
    </Modal>
  );
};

export default ConfirmationModal;
