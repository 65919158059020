import React, { useContext, useEffect, useMemo, useState } from 'react';
import Translations from 'App/Settings/Components/Translations';
import { EventTypes } from 'gastronaut-shared/types/documents/restaurants/event';
import Box from 'Components/Atoms/Box';
import Button from 'Components/Atoms/Button';
import Typography from 'Components/Atoms/Typography';
import ImageField from 'Components/Organisms/ImageField';
import { Event } from 'types/calendar';
import SwitchBox from 'Components/Atoms/Switch';
import TextField from 'Components/Atoms/TextField';
import Select, { Option } from 'Components/Atoms/Select';
import EventDiner from './EventDiner';
import EventRules from './EventRules';
import EventEmailSettings from './EventEmailSettings';
import InputAdornmentAtom from 'Components/Atoms/InputAdornment';
import { capitalize } from 'lodash';
import { AuthContext } from 'Contexts/AuthContext';
import { CircularProgress } from '@material-ui/core';
import useToast from 'CustomHooks/useToast';
import { Severity } from 'Contexts/RestaurantContext';
import server from 'utils/server';
import SeeMore from 'Components/Molecules/SeeMore';

import '../styles.scss';
import RadioButtonGroup from 'Components/Atoms/RadioButtonGroup';
import useRestaurant from 'CustomHooks/useRestaurant';

type EventDetailsProps = {
  eventState: Event | null;
  setEventState: React.Dispatch<React.SetStateAction<Event | null>>;
  isNew?: boolean;
};

const EventDetails = ({
  eventState,
  setEventState,
  isNew = false,
}: EventDetailsProps) => {
  const { restaurantId } = useContext(AuthContext);
  const [hasMollie, setHasMollie] = useState<boolean | null>(null);
  const toast = useToast();

  const { subscriptions } = useRestaurant();
  // const subscriptions: any = { ticket: "111" };

  useEffect(() => {
    try {
      server
        .get(`/v03/mollie/organization/${restaurantId}`, {})
        .then((res) => {
          res.data.onboarding.canReceivePayments
            ? setHasMollie(true)
            : setHasMollie(false);
        })
        .catch((err) => {
          setHasMollie(false);
        });
    } catch (err) {
      toast('An error occured, please try again later', Severity.ERROR);
      console.log('ERROR', err);
    }
  }, []);

  const canBeActivated =
    !!subscriptions?.ticket || !!subscriptions?.ticketSeating || hasMollie;

  useEffect(() => {
    if (subscriptions && typeof hasMollie === 'boolean') {
      if (
        !hasMollie &&
        (!!subscriptions?.ticket || !!subscriptions?.ticketSeating)
      ) {
        setEventState((st) => (!!st ? { ...st, payAtRestaurant: true } : null));
      } else {
        setEventState((st) =>
          !!st ? { ...st, payAtRestaurant: false } : null
        );
      }
    }
  }, [hasMollie, subscriptions]);

  const handleTimingInputValue = (
    time: number,
    input: 'blockTable' | 'minInAdvance'
  ) => {
    if (input === 'blockTable') {
      let newBlock: Event['blockTableFor'] = eventState?.blockTableFor
        ? {
            ...eventState?.blockTableFor,
            value: Number(time),
          }
        : { value: Number(time), unit: 'hours' };

      eventState &&
        setEventState({
          ...eventState,
          blockTableFor: newBlock,
        });
    } else {
      let newMinAdv: Event['minInAdvance'] = eventState?.minInAdvance
        ? {
            ...eventState?.minInAdvance,
            value: Number(time),
          }
        : { value: Number(time), unit: 'hours' };

      eventState &&
        setEventState({
          ...eventState,
          minInAdvance: newMinAdv,
        });
    }
  };

  const handleTimingInputUnit = (
    unit: any,
    input: 'blockTable' | 'minInAdvance'
  ) => {
    if (input === 'blockTable') {
      let newBlock: any = eventState?.blockTableFor
        ? {
            ...eventState?.blockTableFor,
            unit: unit,
          }
        : { value: 1, unit: unit };

      eventState &&
        setEventState({
          ...eventState,
          blockTableFor: newBlock,
        });
    } else {
      let newMinAdv: any = eventState?.minInAdvance
        ? {
            ...eventState?.minInAdvance,
            unit: unit,
          }
        : { value: 1, unit: unit };

      eventState &&
        setEventState({
          ...eventState,
          minInAdvance: newMinAdv,
        });
    }
  };

  const handleTitleAndDescriptionChange = (
    e: Record<string, Record<string, string>>
  ) => {
    if (eventState) {
      const deTitle = e.de.title;

      const title: Record<string, string> = Object.entries(e).reduce(
        (acc, [lang, { title }]) => ({ ...acc, [lang]: title }),
        {}
      );
      const description: Record<string, string> = Object.entries(e).reduce(
        (acc, [lang, { description }]) => ({ ...acc, [lang]: description }),
        {}
      );

      const specialCharacters = new RegExp(
        /[`~!@#$%^&*()_|+\=?.;:'"<>\{\}\[\]\\\/]/gi
      );

      let newId = deTitle
        .slice()
        .toLocaleLowerCase()
        .replaceAll(' ', '-')
        .replaceAll(specialCharacters, '');

      if (newId[newId.length - 1] === '-') {
        newId = newId.slice(0, -1);
      }

      setEventState({
        ...eventState,
        title,
        description,
        id: isNew ? newId : eventState.id,
      });
    }
  };

  const vatRatesOptions: any = [
    {
      value: '7.00',
      label: '7% VAT',
      translation: 'calendar',
    },
    {
      value: '19.00',
      label: '19% VAT',
      translation: 'calendar',
    },
    {
      value: 'bothRates',
      label: 'Both 7% and 19% VAT',
      translation: 'calendar',
    },
    {
      value: '20.00',
      label: '20% VAT',
      translation: 'calendar',
    },
  ];

  const { cheapestOption, isFree } = useMemo(() => {
    let cheapestOption =
      (eventState?.amount || 0) +
      (eventState?.options?.sort(
        (a, b) => (a?.amount ?? 0) - (b?.amount ?? 0)
      )?.[0]?.amount ?? 0);
    let isFree =
      cheapestOption +
        (eventState?.optionalAddOns?.sort(
          (a, b) => (a?.amount ?? 0) - (b?.amount ?? 0)
        )?.[0]?.amount ?? 0) ===
      0;

    return {
      cheapestOption,
      isFree,
    };
  }, [eventState]);

  if (!eventState) {
    return <></>;
  }

  return (
    <Box
      id="event-settings-container"
      padding="md"
      style={{ width: 960, maxWidth: '100%', margin: '0 auto' }}
    >
      {canBeActivated === null && (
        <Box
          flex
          className="mg-bt-lg"
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'var(--color-bg)',
            border: '1px solid var(--color-border)',
            padding: 24,
            width: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {canBeActivated !== null && (
        <Box
          flex
          className="mg-bt-lg"
          style={
            canBeActivated || canBeActivated === null
              ? {
                  backgroundColor: 'var(--color-bg)',
                  border: eventState.active
                    ? '1px solid var(--color-border)'
                    : '1px solid var(--color-critical)',
                  padding: 24,
                  width: '100%',
                }
              : {
                  backgroundColor: 'rgba(204,16,35,0.05)',
                  border: '1px solid var(--color-critical)',
                  padding: 24,
                  width: '100%',
                }
          }
        >
          {canBeActivated ? (
            <>
              <Box>
                <Typography
                  variant="h5"
                  className="mg-bt-xs"
                  translation="calendar"
                >
                  Enable this event for this shift?
                </Typography>
                <Typography
                  color="subdued"
                  variant="text-3"
                  translation="calendar"
                >
                  Switch on if this event should be part of the shift
                </Typography>
              </Box>
              <SwitchBox
                className="event-switch-width"
                checked={eventState?.active ? true : false}
                onChange={() =>
                  setEventState(
                    eventState && { ...eventState, active: !eventState?.active }
                  )
                }
                label=""
              />
            </>
          ) : (
            <>
              <Box>
                <Typography
                  variant="h5"
                  className="mg-bt-xs"
                  translation="calendar"
                >
                  Your restaurant doesn't have Mollie
                </Typography>
                <Typography
                  color="subdued"
                  variant="text-3"
                  translation="calendar"
                >
                  To enable event creation for shifts, your restaurant needs a
                  Mollie account. Mollie is the payment provider Gastronaut uses
                  so that you can accept Payments.
                </Typography>
              </Box>
              <Button
                linkTo={`/${restaurantId}/settings/general/apps?appId=mollie`}
                variant="default"
                translation="calendar"
              >
                Go to Payment Settings
              </Button>
            </>
          )}
        </Box>
      )}

      <Box className="mg-bt-md">
        <Typography variant="h5" className="mg-bt-xs" translation="calendar">
          Add the event name, description and image
        </Typography>
        <Typography color="subdued" variant="text-3" translation="calendar">
          Name and describe your event. You can add an image if needed
        </Typography>
      </Box>

      <Box
        className="event-title-and-description mg-bt-lg space-between flex-start"
        flex
      >
        <Box className="max-600-width">
          <Translations
            borders
            state={{
              de: {
                title: eventState?.title.de || '',
                description: eventState?.description.de || '',
              },
              en: {
                title: eventState?.title.en || '',
                description: eventState?.description.en || '',
              },
            }}
            onChange={(e) => handleTitleAndDescriptionChange(e)}
            keys={[
              {
                id: 'title',
                label: 'Title',
                required: true,
              },
              {
                id: 'description',
                label: 'Description',
                required: true,
                multiline: true,
                richText: true,
                templateGroupId: 'ticket',
              },
            ]}
          />
          <TextField
            className="max-600-width mg-bt-md"
            style={{ marginTop: 8 }}
            fullWidth
            label="Learn More Button Link"
            helperTextTranslation="calendar"
            helperText="Link to a page with more information or for example a Gastronaut menu"
            labelTranslation="calendar"
            value={eventState?.learnMore ?? ''}
            onChange={(e) => {
              setEventState({
                ...eventState,
                learnMore: e.target.value,
              });
            }}
            InputProps={{
              startAdornment: (
                <InputAdornmentAtom position="start">
                  <Typography color="subdued" style={{ fontSize: '90%' }}>
                    https://
                  </Typography>
                </InputAdornmentAtom>
              ),
            }}
          />
        </Box>

        <Box style={{ width: 'auto', paddingLeft: 16 }}>
          <ImageField
            type="url"
            value={eventState?.img || ''}
            onChange={(val) =>
              typeof val === 'string'
                ? setEventState({ ...eventState, img: val })
                : setEventState({ ...eventState, img: null })
            }
          />
        </Box>
      </Box>

      <Box className="event-type-btns-container ">
        <Typography variant="h5" className="mg-bt-xs" translation="calendar">
          Price
        </Typography>

        <Box
          flex
          className="mg-bt-md"
          style={{ flexDirection: 'column', alignItems: 'flex-start' }}
        >
          <TextField
            className="max-600-width mg-bt-md"
            type="number"
            fullWidth
            label="Ticket price"
            labelTranslation="calendar"
            value={(eventState?.amount && eventState?.amount) || ''}
            onChange={(e) => {
              setEventState({
                ...eventState,
                amount: Number(e.currentTarget.value),
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornmentAtom position="end">
                  <Typography color="subdued">€</Typography>
                </InputAdornmentAtom>
              ),
            }}
          />

          {!isFree && (
            <RadioButtonGroup
              style={{ display: 'flex' }}
              value={eventState.per ?? 'perPerson'}
              options={[
                {
                  label: 'Per Person',
                  value: 'perPerson',
                  translation: 'calendar',
                },
                {
                  label: 'Per Group',
                  value: 'perGroup',
                  translation: 'calendar',
                },
              ]}
              onChange={(e: any) => {
                setEventState({
                  ...eventState,
                  per: e.target.value,
                });
              }}
              isRow
            />
          )}
        </Box>
      </Box>

      <Box className="max-600-width">
        <Box
          flex
          className="mg-bt-lg event-optional-ticket"
          style={{ alignItems: 'flex-start' }}
        >
          <Box className="event-optional-ticket" style={{ marginRight: 8 }}>
            <Typography
              variant="h5"
              className="mg-bt-xs"
              translation="calendar"
              color={isFree ? 'disabled' : 'textPrimary'}
            >
              Do you want to collect the payment at the Restaurant?
            </Typography>
            <Typography
              variant="text-3"
              translation="calendar"
              color={isFree ? 'disabled' : 'subdued'}
            >
              Normally the Money is collected digitally during the Reservation
              Process. If this Option is set to true then you will need to
              collect the money at the Restaurant.
            </Typography>
          </Box>
          <SwitchBox
            className="event-switch-width"
            checked={eventState.payAtRestaurant ?? false}
            disabled={!(subscriptions?.ticket || subscriptions?.ticketSeating)}
            onChange={() => {
              setEventState({
                ...eventState,
                payAtRestaurant: !eventState.payAtRestaurant,
              });
            }}
            label=""
          />
        </Box>
        <Box flex className="mg-bt-md event-optional-ticket">
          <Box className="event-optional-ticket">
            <Typography
              variant="h5"
              className="mg-bt-xs"
              translation="calendar"
            >
              Is it an optional ticket?
            </Typography>
            <Typography color="subdued" variant="text-3" translation="calendar">
              Switch this option on if this is an optional ticket
            </Typography>
          </Box>
          <SwitchBox
            className="event-switch-width"
            checked={eventState?.optional ? true : false}
            onChange={() =>
              setEventState(
                eventState && {
                  ...eventState,
                  optional: !eventState?.optional,
                }
              )
            }
            label=""
          />
        </Box>

        <Box flex className="mg-bt-md">
          <TextField
            type="number"
            fullWidth
            label="Max Tickets available"
            labelTranslation="calendar"
            value={eventState?.maxGuests || ''}
            onChange={(e) => {
              setEventState((eS) => {
                if (!eS) {
                  return null;
                } else if (e.target.value !== '') {
                  return {
                    ...eS,
                    maxGuests: Number(e.target.value),
                  };
                } else {
                  delete eS.maxGuests;
                  return eS;
                }
              });
            }}
          />
        </Box>
      </Box>

      <Box className="mg-bt-lg max-600-width">
        <Box className="mg-bt-sm">
          <Typography variant="h5" className="mg-bt-xs" translation="calendar">
            Time in advance
          </Typography>
          <Typography
            color="subdued"
            variant="text-3"
            translationKey="calendar_timeInAdvanceDesc"
          >
            Edit how in advance the event needs to be booked
          </Typography>
        </Box>
        <Box flex className="block-table">
          <TextField
            InputProps={{
              disableUnderline: true,
            }}
            type="number"
            fullWidth
            label="Time in advance"
            labelTranslation="calendar"
            value={
              (eventState?.minInAdvance && eventState?.minInAdvance.value) || ''
            }
            onChange={(e) => {
              handleTimingInputValue(
                Number(e.currentTarget.value),
                'minInAdvance'
              );
            }}
          />
          <Select
            className="conf-email-type-unit"
            translation="common"
            value={capitalize(
              (eventState?.minInAdvance && eventState?.minInAdvance.unit) ||
                'hours'
            )}
            options={['Minutes', 'Hours', 'Days']}
            style={{ maxWidth: 'max-content' }}
            onChange={(e: any) =>
              handleTimingInputUnit(
                e.target.value.toLocaleLowerCase(),
                'minInAdvance'
              )
            }
          />
        </Box>
      </Box>

      {eventState && eventState.type === EventTypes.DINNER_TICKET && (
        <EventDiner
          options={eventState.options}
          optionalAddOns={eventState.optionalAddOns}
          setEventState={setEventState}
          eventState={eventState}
        />
      )}

      <SeeMore
        style={{ marginTop: '16px 0 32px 0' }}
        showMore="Weitere Einstellungen"
        center
      >
        {eventState && (
          <>
            <EventRules
              rules={eventState.rules}
              setEventState={setEventState}
              eventState={eventState}
            />
            <Box
              flex
              className="mg-bt-lg event-optional-ticket"
              style={{ alignItems: 'flex-start' }}
            >
              <Box className="event-optional-ticket" style={{ marginRight: 8 }}>
                <Typography
                  variant="h5"
                  className="mg-bt-xs"
                  translation="calendar"
                >
                  Disconnect Tables
                </Typography>
                <Typography
                  color="subdued"
                  variant="text-3"
                  translation="calendar"
                >
                  Normally every Ticket is connected to reserving a table and
                  the number of tables therefor limits the number of tickets
                  than can be sold. With this option enabled the system will not
                  check for tables and will instead add the reservations without
                  any tables.
                </Typography>
              </Box>
              <SwitchBox
                className="event-switch-width"
                checked={eventState.ignoreTables ?? false}
                onChange={() => {
                  setEventState({
                    ...eventState,
                    ignoreTables: !eventState.ignoreTables,
                  });
                }}
                label=""
              />
            </Box>
            <Box
              flex
              className="mg-bt-lg event-optional-ticket"
              style={{ alignItems: 'flex-start' }}
            >
              <Box className="event-optional-ticket" style={{ marginRight: 8 }}>
                <Typography
                  variant="h5"
                  className="mg-bt-xs"
                  translation="calendar"
                >
                  QR-Code
                </Typography>
                <Typography
                  color="subdued"
                  variant="text-3"
                  translation="calendar"
                >
                  Do you want to send QR-Codes with your Ticket confirmation
                  that can be scanned in the Restaurant via the Gastronaut
                  Mobile App.
                </Typography>
              </Box>
              <SwitchBox
                className="event-switch-width"
                checked={eventState.offerQrCode ?? false}
                onChange={() => {
                  setEventState({
                    ...eventState,
                    offerQrCode: !eventState.offerQrCode,
                  });
                }}
                label=""
              />
            </Box>
          </>
        )}
        <EventEmailSettings
          eventState={eventState}
          setEventState={setEventState}
        />
      </SeeMore>
    </Box>
  );
};

export default EventDetails;
