import React from 'react';

const LockedProductIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    noStroke?: boolean;
    strokeColor?: string;
  }
) => (
  <svg
    width={props.width ?? '20'}
    height={props.height ?? '20'}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.7753 16.0231L25.5 16.2844V14.54V9.86C25.5 8.20576 24.5274 6.69738 22.9939 6.04257L15.6666 2.84519L15.6666 2.84518L15.6608 2.84268C14.6016 2.38577 13.3984 2.38577 12.3392 2.84268L12.3392 2.84267L12.3334 2.84519L5.00008 6.04519L5.00008 6.04519L4.99778 6.0462C3.48115 6.71102 2.5 8.20877 2.5 9.86V14.66C2.5 21.3617 7.19923 27.6667 13.6506 29.2121L13.9977 29.2952L14.345 29.2131C15.1868 29.0142 15.982 28.7271 16.7401 28.3918L18.4128 27.652L17.3598 26.1565C16.6082 25.0889 16.1667 23.8012 16.1667 22.42C16.1667 18.8351 19.0818 15.92 22.6667 15.92C23.0067 15.92 23.359 15.96 23.7753 16.0231Z"
      fill="currentColor"
      {...(!props.noStroke
        ? {
            stroke: props.strokeColor ?? 'var(--color-paper)',
            'stroke-width': '3',
          }
        : {})}
    />
    <path
      d="M22.6667 17.3333C19.72 17.3333 17.3334 19.7199 17.3334 22.6666C17.3334 25.6133 19.72 27.9999 22.6667 27.9999C25.6134 27.9999 28 25.6133 28 22.6666C28 19.7199 25.6134 17.3333 22.6667 17.3333ZM22.6667 19.1733C23.4934 19.1733 24.16 19.8533 24.16 20.6666C24.16 21.4799 23.48 22.1599 22.6667 22.1599C21.8534 22.1599 21.1734 21.4799 21.1734 20.6666C21.1734 19.8533 21.84 19.1733 22.6667 19.1733ZM22.6667 26.3333C21.4267 26.3333 20.3467 25.7199 19.68 24.7733C19.7467 23.8133 21.6934 23.3333 22.6667 23.3333C23.64 23.3333 25.5867 23.8133 25.6534 24.7733C24.9867 25.7199 23.9067 26.3333 22.6667 26.3333Z"
      fill="currentColor"
    />
  </svg>
);

export default LockedProductIcon;
