import React from 'react';
import './styles.scss';
import { ChangeLog } from 'api/Changelog/types';

import Modal from 'Components/Molecules/Modal';
import Box from 'Components/Atoms/Box';
import Typography, { Colors } from 'Components/Atoms/Typography';
import Button from 'Components/Atoms/Button';
import { useHistory } from 'react-router-dom';
import useRestaurant from 'CustomHooks/useRestaurant';

export const CHANGELOG_DATA_EXAMPLE = {
  id: '0.4.48',
  title: "Since you've been gone",
  description:
    'Some updates that you might have missed from our latest version',
  createdAt: 1671564862191,
  environment: 'app',
  updates: [
    {
      title: 'Guest statistics',
      description:
        'See some interesting insights about your guests. New charts, tables and a heatmap.',
      link: '',
      severity: 'feature',
      // linkType?: "button" | "loom" | "image" | "youtube"; // defaults to Button
      // children?: ReactNode;
    },
  ],
};

interface ChangelogModalProps {
  isOpen: boolean;
  onClose: () => void;
  data?: ChangeLog[] | null;
  onOk: () => void;
}

const ChangelogModal = ({
  isOpen,
  onClose,
  data,
  onOk,
}: ChangelogModalProps) => {
  const history = useHistory();

  const { restaurantId } = useRestaurant();

  function severityHandler(severity: 'feature' | 'bug-fix' | 'announcement') {
    switch (severity) {
      case 'feature':
        return 'Feature';
      case 'bug-fix':
        return 'Bug fix';
      case 'announcement':
        return 'Announcement';
      default:
        return '';
    }
  }

  function severityColorHandler(
    severity: 'feature' | 'bug-fix' | 'announcement'
  ) {
    switch (severity) {
      case 'feature':
        return 'primary';
      case 'bug-fix':
        return 'error';
      case 'announcement':
        return 'secondary';
      default:
        return 'textPrimary';
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={`Since you've been gone`}
      titleTranslation="common"
      bottomPart={
        <Box>
          <Button onClick={() => onOk()}>Ok, I understand</Button>
        </Box>
      }
    >
      <Box className="changelog-modal-wrapper">
        {data
          ?.reduceRight((acc, cV) => [...acc, cV], [] as ChangeLog[])
          ?.map((item) => (
            <>
              <Typography variant="text-3" weight="bold">
                Version: {item?.id ?? ''}
              </Typography>
              {/* <Typography variant="text-3">{item?.description ?? ""}</Typography> */}

              <Box className="changelog-container">
                <Box className="update-container">
                  <Box className="update-title-container">
                    <Typography variant="h5">{item.title}</Typography>
                  </Box>
                  {!!item?.images?.[0] && (
                    <Box className="update-media-container">
                      <img
                        className="update-media-img"
                        src={
                          item.images[0].thumbnails?.large?.url ||
                          item.images[0].url
                        }
                        alt={item.images[0].filename}
                      />
                    </Box>
                  )}
                  <Box className="update-description-container">
                    <Typography variant="text-3">{item.description}</Typography>
                  </Box>
                </Box>
                {item?.updates?.map((update) => (
                  <Box className="update-container">
                    <Box className="update-title-container">
                      <Typography variant="h5">{update.title}</Typography>
                      <Typography
                        variant="text-5"
                        color={severityColorHandler(update.severity)}
                      >
                        {severityHandler(update.severity)}
                      </Typography>
                    </Box>
                    <Box className="update-description-container">
                      <Typography variant="text-3">
                        {update.description}
                      </Typography>
                    </Box>
                    {update.linkType === 'button' && (
                      <Button
                        className="go-to-feature-button"
                        onClick={() =>
                          history.push(
                            `${update.link?.replace(
                              '{{restaurantId}}',
                              restaurantId || ''
                            )}`
                          )
                        }
                        translation="dashboard"
                      >
                        Go to feature
                      </Button>
                    )}
                    {update?.media && (
                      <Box className="update-media-container">
                        {update?.linkType === 'image' && (
                          <img
                            className="update-media-img"
                            src={update.media}
                          />
                        )}
                        {update?.linkType === 'youtube' && (
                          <iframe
                            style={{ margin: '12px 0px' }}
                            width="90%"
                            height="auto"
                            src={update.media}
                          ></iframe>
                        )}
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
            </>
          ))}
      </Box>
    </Modal>
  );
};

export default ChangelogModal;
