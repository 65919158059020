import React, { useMemo, useState } from "react";
import Box from "Components/Atoms/Box";
import "./styles.scss";
import { Translations } from "utils/types";
import Card from "../Card";
import { noop } from "utils/helper";
import Row from "./Components/Row";
import Header from "./Components/Header";
import LoadingScreen from "Components/Atoms/LoadingScreen";
import { CircularProgress } from "@material-ui/core";
import EmptyState from "Components/Organisms/ShiftReservationCard/Components/EmptyState";
import Typography from "../../Atoms/Typography";

export type Column = {
  id: string;
  label: string;
  labelTranslation?: Translations;
  align?: "right" | "left" | "center" | any;
  minWidth?: number | string;
};

export type SimpleTableProps = {
  columns: Column[];
  selected?: string | null;
  rows: { id: any }[];
  onRowClick: (id: any) => void;
  hideBorders?: boolean;
  loading?: boolean;
  className?: string;
  style?: object;
};

const SimpleTable = ({
  style,
  selected,
  className,
  columns = [],
  rows = [],
  onRowClick = noop,
  hideBorders = false,
  loading = false,
}: SimpleTableProps) => {
  const output = useMemo(() => {
    let output: any = {};

    if (rows.length) {
      Object.keys(rows[0]).forEach((key) => {
        output[key] = rows.map((obj: any) => obj[key]);
      });
      Object.keys(rows[0]).forEach((key) => {
        output[key] = rows.map((obj: any) => obj[key]);
      });
    }
    return output;
  }, [rows]);

  const noData = rows.length === 0 && !loading;
  return (
    <Card
      hideHeader
      hideBorders={hideBorders}
      subHeader={<Header columns={columns} ContentWidth={output} />}
      bodyProps={{ padding: 0 }}
      style={{ minWidth: "200px", overflowX: "auto" }}
      className="simple-table-wrapper"
    >
      {!loading &&
        rows.map((r, i) => (
          <Row
            style={style}
            className={className}
            key={r.id ?? i}
            data={r}
            id={r.id}
            selected={selected === r.id}
            onClick={onRowClick}
            columns={columns}
            ContentWidth={output}
          />
        ))}
      {loading && (
        <Box flex fullSize className="center" style={{ minHeight: 200 }}>
          <CircularProgress />
        </Box>
      )}
      {noData && (
        <Box padding={24}>
          <EmptyState description="Currently, there is no data to show" />
        </Box>
      )}
    </Card>
  );
};

export default SimpleTable;
