import React, { useState } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import { blockToTime } from 'utils/helper';
import Typography from 'Components/Atoms/Typography';
import { ArrowDropDown, Create } from 'Components/Atoms/Icons';
import Popover from 'Components/Atoms/Popover';
import ReservationLengthOptions from './Components/ReservationLengthOptions';
import { Hidden } from '@material-ui/core';
import IconButton from 'Components/Atoms/IconButton';
import usePinAuth from 'CustomHooks/usePinAuth';
import LockedProductIcon from 'Components/Atoms/Icons/LockedProduct';

const OPTIONS = Array.from(Array(16), (x, i) => ({
  id: i + 1,
  label: blockToTime(i + 1),
}));

export type ReservationLengthProps = {
  hidden?: boolean;
  value?: null | number; // 1 = 15min, 2 = 30min, ...,
  onChange: (nV: number) => void;
  style?: React.CSSProperties;
  startBlock?: number;
};

const ReservationLength = ({
  value,
  onChange,
  hidden = false,
  style,
  startBlock,
}: ReservationLengthProps) => {
  //   console.log(OPTIONS);

  const [hide, sethide] = useState(hidden);
  const [anchorEl, setanchorEl] = useState<null | HTMLElement>(null);

  const { checkAuthentication, authenticate } = usePinAuth();

  return (
    <Box>
      {!hide ? (
        <Box
          outline
          style={{ cursor: 'pointer', ...style }}
          background
          padding="sm"
          flex
          className="space-between"
          onClick={(e) => {
            let target = e.currentTarget;
            authenticate('reservations.reservationLength.write', () =>
              setanchorEl((x) => (!x ? target : null))
            );
          }}
        >
          <Typography
            variant="text-3"
            color="subdued"
            style={{ width: 100 }}
            translation={null}
          >
            {value ? blockToTime(value) : '-'}{' '}
            {startBlock && value ? `- ${blockToTime(startBlock + value)}` : ''}
          </Typography>
          {checkAuthentication(
            'reservations.reservationLength.write',
            false
          ) ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <LockedProductIcon
                noStroke
                style={{ color: 'var(--color-secondary)' }}
              />
              <ArrowDropDown style={{ fill: 'var(--color-text-subdued)' }} />
            </div>
          ) : (
            <ArrowDropDown style={{ fill: 'var(--color-text-subdued)' }} />
          )}
        </Box>
      ) : (
        <Box padding="sm" flex className="space-between">
          <Typography
            variant="text-3"
            style={{ width: 100 }}
            translation={null}
          >
            {value ? blockToTime(value) : '-'}{' '}
            {startBlock && value ? `- ${blockToTime(startBlock + value)}` : ''}
          </Typography>

          <IconButton
            color="subdued"
            onClick={() => sethide(false)}
            size="small"
          >
            <Create />
          </IconButton>
        </Box>
      )}

      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setanchorEl(null)}
        placement="bottom"
        padding={0}
        style={{ maxHeight: 300, overflowY: 'scroll' }}
      >
        <ReservationLengthOptions
          value={value}
          onChange={(nV) => {
            onChange(nV);
            setanchorEl(null);
          }}
        />
      </Popover>
    </Box>
  );
};

export default ReservationLength;
