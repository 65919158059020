import React from "react";
import Box from "Components/Atoms/Box";
import "./styles.scss";
import Typography from "Components/Atoms/Typography";
import { Translations } from "utils/types";

export type TitleWithIconProps = {
  title: string;
  titleTranslation?: Translations;
  description: string;
  descriptionTranslation?: Translations;
  descriptionTranslationKey?: string;
  hide?: boolean;
  icon: {
    (params: object): React.ReactNode;
  };
};

const iconParams = {
  fontSize: "inherit",
  color: "inherit",
};

const TitleWithIcon: React.FC<TitleWithIconProps> = (props) => {
  if (props.hide) {
    return <></>;
  }

  return (
    <Box className="title_with_icon">
      <Box className="icon_container">
        {!!props.icon &&
          props.icon({ ...iconParams, className: "btn-end-icon" })}
      </Box>
      <Box className="main_container">
        <Box className="header">
          <Typography
            variant="h5"
            component="h5"
            translation={props.titleTranslation ?? "calendar"}
          >
            {props.title}
          </Typography>
          <Typography
            variant="text-3"
            color="subdued"
            component="p"
            translation={props.descriptionTranslation}
            translationKey={props.descriptionTranslationKey}
          >
            {props.description}
          </Typography>
        </Box>
        <Box className="title_with_icon_body">{props.children}</Box>
      </Box>
    </Box>
  );
};

export default TitleWithIcon;
