import React, { useContext, useMemo, useState } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import { Occassion } from 'gastronaut-shared/types/helper';
import TabCard from 'Components/Molecules/TabCard';
import IconButton from 'Components/Atoms/IconButton';
import {
  CancelOutlined,
  Check,
  Close,
  PermPhoneMsgRounded,
  Warning,
} from '@material-ui/icons';
import { ReservationContext } from 'Contexts/ReservationContext';
import useAuth from 'CustomHooks/useAuth';
import Button from 'Components/Atoms/Button';
import FindTable from 'Components/Atoms/Icons/FindTable';
import { WaitingListActionType } from 'CustomHooks/useWaitinglist';
import {
  capitalizeWord,
  classHelper,
  dateHelper,
  toEuropeanDate,
} from 'utils/helper';
import Typography from 'Components/Atoms/Typography';
import EditableField from '../ReservationSideBar/Components/EditableField';
import { stringifyCustomDataValue } from '../ReservationSideBar/Components/ReservationDetails';

export type WaitinglistSidebarProps = {
  id: string;
  occassions: Occassion[];
  onClose: () => void;
  sidebarFontSize?: string;
};

const WaitinglistSidebar: React.FC<WaitinglistSidebarProps> = ({
  id,
  onClose,
  occassions,
  sidebarFontSize,
}) => {
  const {
    waitinglistEntries,
    handleWaitinglistAction,
    setcurrentReservation,
    setcurrentWaitinglistEntry,
    reservations,
  } = useContext(ReservationContext);

  const { isGastronautAdmin } = useAuth();

  const entry = useMemo(() => {
    return waitinglistEntries.find((e) => e.id === id);
  }, [id, waitinglistEntries]);

  const [hasOverflow, setHasOverflow] = useState<{
    bottom: boolean;
    top: boolean;
  }>({ top: false, bottom: false });

  const handleScrolling = (
    event: React.UIEvent<HTMLDivElement | HTMLLabelElement | HTMLFormElement>
  ) => {
    if (
      event.currentTarget.scrollHeight - event.currentTarget.scrollTop <=
      event.currentTarget.clientHeight + 1
    ) {
      setHasOverflow({ top: true, bottom: false });
    } else if (event.currentTarget.scrollTop === 0) {
      setHasOverflow({ top: false, bottom: true });
    } else if (
      event.currentTarget.scrollHeight - event.currentTarget.scrollTop >
        event.currentTarget.clientHeight &&
      event.currentTarget.scrollTop > 0
    ) {
      setHasOverflow({ top: true, bottom: true });
    }
  };

  const detailsFields = useMemo(() => {
    if (!entry) return [];

    const occassionString =
      occassions?.find((o) => o.id === entry.occassion)?.title ||
      capitalizeWord(entry.occassion);

    return [
      {
        label: 'Status',
        value: capitalizeWord(entry.status),
      },
      {
        label: 'Date',
        value: toEuropeanDate(entry.date),
      },
      {
        label: 'Time',
        value: entry.time,
      },
      ...(entry.flexibleTime
        ? [
            {
              label: 'Earliest Possible Time',
              value: entry.flexibleTime.earliest,
            },
            {
              label: 'Latest Possible Time',
              value: entry.flexibleTime.latest,
            },
          ]
        : []),
      {
        label: 'Occassion',
        value: occassionString,
        align: 'right',
      },
      {
        label: 'Persons',
        value: entry.guests,
      },
      {
        label: 'Telephone',
        value: entry.guest.phone,
      },
      {
        label: 'Email',
        value: entry.guest.email,
      },
      {
        label: 'Custom Id',
        value: entry.customId,
      },
      {
        label: 'Created At',
        value:
          toEuropeanDate(dateHelper(entry.createdAt), true) +
          ' ' +
          new Date(entry.createdAt || 0).toLocaleTimeString().slice(0, 5),
      },
      {
        label: 'Source',
        value: entry.source,
      },
    ];
  }, [entry]);

  console.log({ id, entry, test: '123456XYZ' });

  const reservation = useMemo(() => {
    if (entry?.status !== 'success') return null;

    return reservations.find((r) => r.id === id) || null;
  }, [entry, reservations]);

  const inPast = !!entry?.date && entry.date < dateHelper();

  if (!entry) return <></>;

  return (
    <TabCard
      tabs={[{ id: 0, label: 'Waitinglist', translation: 'reservations' }]}
      id="waitinglist-side-bar-tabs"
      className={classHelper(['sidebar', sidebarFontSize && 'customSidebar'])}
      value={0}
      onChange={() => {}}
      titleCarousel={entry?.guest?.name?.length > 24}
      title={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: isGastronautAdmin ? 'pointer' : '',
          }}
        >
          {entry?.guest?.name || 'Reservation'}
          {entry.source === 'phone' && (
            <PermPhoneMsgRounded fontSize="inherit" style={{ marginLeft: 4 }} />
          )}
        </div>
      }
      titleProps={{
        style: {
          maxWidth: 230,
        },
      }}
      bodyProps={{
        style: {
          padding: '0px !important',
          position: 'relative',
          height: 'calc(100% - 110px)',
        },
        className: 'space-between flex-column',
      }}
      style={{
        height: '100%',
        zIndex: 100,
        fontSize: sidebarFontSize ? `${sidebarFontSize}px` : undefined,
      }}
      headerRight={
        <>
          <IconButton size="small" color="subdued" onClick={onClose}>
            <Close />
          </IconButton>
        </>
      }
    >
      {hasOverflow.top && <div className="topOverflowGradient"></div>}
      <Box
        className="waitinglist-side-bar"
        onScroll={(e) => handleScrolling(e)}
      >
        {detailsFields.map((f, i) => (
          <Box
            key={i}
            className={`space-between reservation-field ${
              String(f?.value ?? '').length > 30 ? 'flex-start' : ''
            }`}
          >
            <Typography variant="text-3" translation="reservations">
              {f.label}
            </Typography>
            <Typography
              variant={String(f?.value ?? '').length > 20 ? 'text-3' : 'text-2'}
              color="subdued"
              translation={f.label === 'Status' ? 'reservations' : null}
              textAlign={f.align as any}
            >
              {f.value}
            </Typography>
          </Box>
        ))}
        {entry.customData && (
          <>
            {Object.entries(entry.customData).map(([key, value]) => (
              <Box className="space-between reservation-field" key={key}>
                <Typography variant="text-3" block style={{ marginBottom: 8 }}>
                  {key.replace('_internal', '')}
                </Typography>
                <Typography
                  variant="text-2"
                  color="subdued"
                  translation="reservations"
                  textAlign="right"
                >
                  {stringifyCustomDataValue(value)}
                </Typography>
              </Box>
            ))}
          </>
        )}
        <Box className="reservation-field">
          <Typography
            variant="text-3"
            translation="reservations"
            block
            style={{ marginBottom: 8 }}
          >
            Guest Comment
          </Typography>
          <Typography
            variant="text-2"
            color="subdued"
            translation="reservations"
          >
            {entry.guest.comment || '-'}
          </Typography>
        </Box>
        <Box className="reservation-field">
          <Typography
            variant="text-3"
            translation="reservations"
            block
            style={{ marginBottom: 8 }}
          >
            Waiter Comment
          </Typography>
          <EditableField
            field={{
              action: 'hostComment',
              type: 'text',
              value: entry.guest.hostComment || '',
              editable: true,
            }}
            onAction={(id, _, payload) =>
              handleWaitinglistAction(
                id,
                WaitingListActionType.HOST_COMMENT,
                payload
              )
            }
            id={id}
            oneLine={false}
            placeholder="..."
          />
        </Box>
      </Box>
      {hasOverflow.bottom && (
        <div className="bottomOverflowGradient" style={{ height: 150 }}></div>
      )}
      <Box padding="md" style={{ zIndex: 102 }}>
        {!inPast && entry.status === 'pending' && (
          <Button
            fullWidth
            endIcon={() => (
              <FindTable fontSize="small" style={{ marginLeft: 8 }} />
            )}
            style={{
              marginBottom: 24,
              border: '1px solid var(--color-border)',
            }}
            color="subdued"
            typographyProps={{ color: 'textPrimary' }}
            onClick={() => {
              handleWaitinglistAction(id, WaitingListActionType.FIND_TABLE);
            }}
            translation="reservations"
            className="mg-bt-md"
          >
            Find Table
          </Button>
        )}
        {!inPast && entry.status === 'waiting' && (
          <Button
            fullWidth
            variant="outlined"
            endIcon={() => <Check fontSize="small" style={{ marginLeft: 8 }} />}
            onClick={() =>
              handleWaitinglistAction(id, WaitingListActionType.ACCEPT)
            }
            translation="reservations"
          >
            Accept
          </Button>
        )}
        {!inPast && (entry.status === 'pending' || entry.status === 'waiting') && (
          <Button
            fullWidth
            variant="outlined"
            color="error"
            endIcon={() => (
              <CancelOutlined fontSize="small" style={{ marginLeft: 8 }} />
            )}
            style={{ borderColor: 'var(--color-error)' }}
            onClick={() =>
              handleWaitinglistAction(id, WaitingListActionType.CANCEL)
            }
            translation="reservations"
          >
            Cancel
          </Button>
        )}
        {!inPast && (entry.status === 'canceled' || entry.status === 'failed') && (
          <Button
            fullWidth
            variant="outlined"
            endIcon={() => <Check fontSize="small" style={{ marginLeft: 8 }} />}
            onClick={() =>
              handleWaitinglistAction(id, WaitingListActionType.UN_CANCEL)
            }
            translation="reservations"
          >
            Bring back
          </Button>
        )}
        {entry.status === 'success' && !!reservation && (
          <Button
            fullWidth
            variant="outlined"
            endIcon={() => <Check fontSize="small" style={{ marginLeft: 8 }} />}
            onClick={() => {
              setcurrentReservation(id);
            }}
            translation="reservations"
          >
            Show Reservation
          </Button>
        )}
      </Box>
    </TabCard>
  );
};

export default WaitinglistSidebar;
