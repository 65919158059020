import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper, { PopperPlacementType } from "@material-ui/core/Popper";
import Box, { BoxProps } from "Components/Atoms/Box";
import * as React from "react";
import { Modify, Size } from "utils/types";
import "./styles.scss";

type OnClose = {
  (event: React.MouseEvent<Document>): void;
};

export type PopoverProps = Modify<
  {
    anchorEl?: null | HTMLElement | any;
    children?: any;
    placement?: PopperPlacementType;
    onClose?: null | OnClose;
    open?: boolean;
    padding?: Size;
    zIndex?: number;
  },
  BoxProps
>;

const Popover = ({
  anchorEl = null,
  children,
  placement = "bottom-end",
  open = false,
  onClose = null,
  padding = "sm",
  zIndex = 30000,
  ...props
}: PopoverProps) => {
  const width = React.useMemo(() => anchorEl?.offsetWidth || "", [anchorEl]);

  if (open && !!onClose) {
    return (
      <ClickAwayListener onClickAway={onClose}>
        <Popper
          anchorEl={anchorEl}
          open={open}
          placement={placement}
          style={{ width: props.width || width, zIndex }}
          className="popoverContainer"
        >
          <Box
            elevation={3}
            className="popover"
            padding={padding}
            width={width}
            style={{ marginTop: 8 }}
            {...props}
          >
            {children}
          </Box>
        </Popper>
      </ClickAwayListener>
    );
  }

  return (
    <Popper
      anchorEl={anchorEl}
      open={open}
      placement={placement}
      style={{ width: props.width || width, zIndex }}
      className="popoverContainer"
    >
      <Box
        elevation={3}
        className="popover"
        padding={padding}
        width={width}
        style={{ marginTop: 8 }}
        {...props}
      >
        {children}
      </Box>
    </Popper>
  );
};

export default Popover;
