import React, { useEffect, useState } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import Modal from '@material-ui/core/Modal';
import Search from 'App/Apps/Components/Search';
import SearchBar from 'Components/Molecules/SearchBar';
import server from 'utils/server';
import { useVoucher } from 'Contexts/VoucherContext';
import { VoucherActions, VoucherData } from 'types/vouchersV02';
import { Cancel, CheckCircle, Redeem } from '@material-ui/icons';
import Typography from 'Components/Atoms/Typography';
import Button from 'Components/Atoms/Button';
import useDocument from 'CustomHooks/useDocument';
import { useRouter } from 'CustomHooks/useRouter';
import useRestaurant from 'CustomHooks/useRestaurant';
import { IconButton } from '@material-ui/core';

export type QuickModalProps = {
  children?: (onClick: () => void) => React.ReactNode;
};

const QuickModal: React.FC<QuickModalProps> = ({
  children = (onClick) => (
    <IconButton size="small" onClick={onClick}>
      <Redeem
        style={{
          color: 'var(--color-text)',
        }}
      />
    </IconButton>
  ),
}) => {
  const [open, setopen] = useState(false);

  const [code, setcode] = useState('');

  const ctx = useVoucher();

  const { products } = useRestaurant();

  const [voucherDoc] = useDocument<VoucherData>(
    `vouchersV02`,
    code ? `${ctx.restaurantId}_${code}` : ''
  );

  useEffect(() => {
    if (!open) setcode('');
  }, [open]);

  const voucher = voucherDoc.data;

  const onSearch = async () => {
    if (!code) return;

    await server.get<
      | {
          voucherId: string;
          restaurantId: string;
          found: false;
          valid: false;
          message: string;
        }
      | {
          voucherId: string;
          restaurantId: string;
          found: true;
          valid: boolean;
          remainingAmount: {
            value: string;
            currency: string;
          };
          data: VoucherData;
        }
    >(`/v03/vouchersV02/${ctx.restaurantId}/${code}/revalidate`, {
      headers: {
        Authorization: `Bearer user_${ctx.uid}`,
      },
    });
  };

  const router = useRouter();

  if (!products.includes('vouchers')) return null;

  return (
    <>
      <Modal open={open} onClose={() => setopen(false)}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            height: '100vh',
            width: '100vw',
            gap: 10,
          }}
          onClick={() => setopen(false)}
        >
          <Box
            style={{
              background: 'var(--color-paper)',
              maxWidth: 400,
              padding: 8,
              borderRadius: 8,
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <SearchBar
              value={code}
              onChange={(x) => {
                setcode(x.toLocaleUpperCase());
              }}
              width="100%"
              onSubmit={onSearch}
              placeHolder="Nach Gutschein suchen"
            />
          </Box>
          {!!voucher && (
            <Box
              style={{
                background: 'var(--color-paper)',
                maxWidth: 400,
                padding: 8,
                borderRadius: 8,
                display: 'flex',
                justifyContent: 'start',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 8,
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                  gap: 8,
                }}
              >
                {voucher.isValid ? (
                  <CheckCircle style={{ color: 'var(--color-success)' }} />
                ) : (
                  <Cancel color="error" />
                )}
                <Typography variant="h5" style={{ flexGrow: 10 }} block>
                  {voucher.guest.name}
                </Typography>
                <Typography variant="h5">
                  {voucher.remainingAmount.value}€
                </Typography>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                  gap: 8,
                }}
              >
                <Button
                  style={{
                    justifyContent: 'flex-start',
                    background: 'var(--color-btn-default)',
                    // color: 'var(--color-critical)',
                  }}
                  onClick={() => {
                    if (ctx.active) {
                      ctx.setactiveVoucherId(
                        `${voucher.restaurantId}_${voucher.code ?? ''}`
                      );
                    } else {
                      router.push(
                        `/${ctx.restaurantId}/vouchers?code=${voucher.code}`
                      );
                    }
                    setopen(false);
                  }}
                  variant="transparent"
                >
                  Open
                </Button>
                <Button
                  style={{
                    justifyContent: 'flex-start',
                    background: 'var(--color-btn-default)',
                    // color: 'var(--color-critical)',
                  }}
                  onClick={() => {
                    ctx.voucherAction(
                      voucher.code ?? '',
                      VoucherActions.redeem
                    );
                  }}
                  disabled={!voucher.isValid}
                  variant="transparent"
                  startIcon={(i) => <Redeem {...i} />}
                >
                  Redeem
                </Button>
              </Box>
            </Box>
          )}
          {code.length === 6 && !voucherDoc.exists && (
            <Box
              style={{
                background: 'white',
                maxWidth: 400,
                padding: 8,
                borderRadius: 8,
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <Typography variant="h5" textAlign="center" block>
                Gutschein nicht gefunden
              </Typography>
            </Box>
          )}
        </div>
      </Modal>
      {children(() => setopen((o) => !o))}
    </>
  );
};

export default QuickModal;
