import React, { useState } from "react";
import Box from "Components/Atoms/Box";
import "./styles.scss";
import Sidebar from "Components/Molecules/Sidebar";
import { ActiveShift } from "CustomHooks/useCalendarDates";
import { GuestOrderingShift } from "types/shifts";
import { Menu, MenuDraft } from "types/menu";
import Button from "Components/Atoms/Button";
import CheckCircle from "Components/Atoms/Icons/CheckCircle";
import TextField from "Components/Atoms/TextField";
import ShiftColor from "App/Settings/Components/ShiftColor";
import Typography from "Components/Atoms/Typography";
import SwitchBox from "Components/Atoms/Switch";
import DateRange from "App/Settings/Components/DateRange";
import WeekDays from "App/Settings/Components/Weekdays";
import Select from "Components/Atoms/Select";
import { blockToTime } from "utils/helper";

export type NewEditGuestOrderingShiftProps = {
  activeShift: ActiveShift<GuestOrderingShift>;
  setactiveShift: React.Dispatch<
    React.SetStateAction<ActiveShift<GuestOrderingShift>>
  >;
  onClose: VoidFunction;
  onSubmit: () => Promise<void>;
  menues: Menu[];
};

const TIMES = Array.from(Array(4 * 28 + 1), (_, i) => ({
  id: i,
  value: i,
  label: blockToTime(i),
}));

const NewEditGuestOrderingShift: React.FC<NewEditGuestOrderingShiftProps> = ({
  activeShift,
  onClose,
  onSubmit,
  setactiveShift,
  menues,
}) => {
  const [loading, setloading] = useState(false);
  const [hasNoName, sethasNoName] = useState<boolean>(false);

  const hasTimeError: () => string = () => {
    if (
      activeShift?.shift.start === undefined ||
      activeShift?.shift.close === undefined ||
      activeShift?.shift.service === undefined
    ) {
      return "All times have to be set";
    } else if (activeShift?.shift.start > activeShift?.shift.close) {
      return "Start of Shift has to be before end of Shift";
    } else if (activeShift?.shift.service > activeShift?.shift.close) {
      return "Last Reservation has to be before end of Shift";
    } else if (activeShift?.shift.start > activeShift?.shift.service) {
      return "Last Reservation has to be after start of Shift";
    }

    return "";
  };

  const handleSubmit = async () => {
    if (hasTimeError()) return;
    if (!activeShift?.shift.name) {
      sethasNoName(true);
      return;
    }
    if (hasNoName) return;
    setloading(true);
    await onSubmit();
    setloading(false);
  };

  const handleNameChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    let name = e.target.value;
    if (!name) {
      sethasNoName(true);
    } else {
      sethasNoName(false);
    }
    setactiveShift((aS) =>
      !!aS ? { ...aS, shift: { ...aS.shift, name } } : null
    );
  };

  const handleTimeChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    c: React.ReactNode
  ) => {
    let { name = "", value } = e.target;
    if (name || typeof value === "number") {
      setactiveShift((aS) =>
        !!aS ? { ...aS, shift: { ...aS.shift, [name]: value } } : null
      );
    }
  };

  const handleMenuChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    c: React.ReactNode
  ) => {
    let { name = "", value } = e.target;
    if (name || typeof value === "string") {
      setactiveShift((aS) =>
        !!aS ? { ...aS, shift: { ...aS.shift, [name]: value } } : null
      );
    }
  };

  return (
    <Box style={{ height: "100%" }}>
      <Sidebar
        id="new-guestOrdering-shift"
        title={
          activeShift?.type === "new"
            ? "New Guest Ordering Shift"
            : `Edit ${activeShift?.shift.name}`
        }
        titleTranslation="calendar"
        onClose={onClose}
        actions={
          <Button
            fullWidth
            endIcon={() => (
              <CheckCircle color="primary" style={{ marginLeft: 8 }} />
            )}
            onClick={handleSubmit}
            loading={loading}
            disabled={!!hasTimeError()}
            variant="outlined"
            style={{ backgroundColor: "var(--color-bg)" }}
          >
            Save
          </Button>
        }
      >
        <Box flex padding={0} className="mg-bt-md">
          <TextField
            value={activeShift?.shift.name ?? ""}
            name="name"
            label="Name"
            onChange={handleNameChange}
            fullWidth
            autoFocus={activeShift?.type !== "edit"}
            style={{ marginRight: 8 }}
            required
            errorMessage={hasNoName ? "Name is mandatory" : undefined}
          />
          <ShiftColor
            value={activeShift?.shift.color ?? "#ffffff"}
            onChange={(color) =>
              setactiveShift((aS) =>
                !!aS ? { ...aS, shift: { ...aS.shift, color } } : null
              )
            }
            horizontalPosition={{ left: "-200px" }}
          />
        </Box>
        <Typography
          variant="text-4"
          color="subdued"
          style={{ marginBottom: 4, marginTop: 0 }}
          block
          translation="calendar"
        >
          Repeat Shift?
        </Typography>

        <SwitchBox
          label="Repeated?"
          color="primary"
          checked={!!activeShift?.shift?.regular}
          onChange={(_, regular) =>
            setactiveShift((aS) =>
              !!aS ? { ...aS, shift: { ...aS.shift, regular } } : null
            )
          }
          className="mg-bt-md"
          translation="calendar"
        />

        {!!activeShift && (
          <DateRange
            onlyOneDay={!activeShift.shift.regular}
            state={activeShift.shift}
            onChange={(state: any) =>
              setactiveShift((aS) =>
                !!aS ? { ...aS, shift: { ...aS.shift, ...state } } : null
              )
            }
          />
        )}
        {!!activeShift?.shift.regular && (
          <WeekDays
            weekDays={activeShift?.shift.weekDays || []}
            onChange={(weekDays) =>
              setactiveShift((aS) =>
                !!aS ? { ...aS, shift: { ...aS.shift, weekDays } } : null
              )
            }
          />
        )}

        {
          <SwitchBox
            translation="calendar"
            label="All Day?"
            color="primary"
            checked={!!activeShift?.shift?.allDay}
            onChange={(_, allDay) =>
              setactiveShift((aS) =>
                !!aS
                  ? {
                      ...aS,
                      shift: {
                        ...aS.shift,
                        allDay,
                        start: 0,
                        close: 96,
                        service: 96,
                      },
                    }
                  : null
              )
            }
            className="mg-bt-md"
          />
        }

        {!activeShift?.shift?.allDay && (
          <>
            <Typography
              variant="text-4"
              color="subdued"
              style={{ marginBottom: 4 }}
              block
              translation="calendar"
            >
              Start of Shift
            </Typography>
            <Box background outline elevation={0} className="mg-bt-md">
              <Select
                name="start"
                options={TIMES.filter((t) => t.value < 96)}
                value={activeShift?.shift.start}
                onChange={handleTimeChange}
              />
            </Box>
            <Typography
              variant="text-4"
              color="subdued"
              style={{ marginBottom: 4 }}
              block
              translation="calendar"
            >
              Last Reservation
            </Typography>
            <Box background outline elevation={0} className="mg-bt-md">
              <Select
                name="service"
                options={TIMES.filter(
                  (t) => t.value > (activeShift?.shift?.start ?? 0)
                )}
                value={activeShift?.shift.service}
                onChange={handleTimeChange}
              />
            </Box>
            <Typography
              variant="text-4"
              color="subdued"
              style={{ marginBottom: 4 }}
              block
              translation="calendar"
            >
              End of Shift
            </Typography>
            <Box
              background
              outline
              elevation={0}
              className={!!hasTimeError() ? "mg-bt-sm" : "mg-bt-md"}
            >
              <Select
                name="close"
                options={TIMES.filter(
                  (t) => t.value > (activeShift?.shift?.start ?? 0)
                )}
                value={activeShift?.shift.close}
                onChange={handleTimeChange}
              />
            </Box>
          </>
        )}

        {!!hasTimeError() && (
          <Typography variant="text-3" color="error" block className="mg-bt-md">
            {hasTimeError()}
          </Typography>
        )}

        <Typography
          variant="text-4"
          color="subdued"
          style={{ marginBottom: 4 }}
          block
          translation="settings"
        >
          Menu
        </Typography>
        <Box
          background
          outline
          elevation={0}
          className={!!hasTimeError() ? "mg-bt-sm" : "mg-bt-md"}
        >
          <Select
            name="menu"
            options={menues.map((m) => ({
              id: m.id,
              label: m.shortName,
            }))}
            value={activeShift?.shift.menu}
            onChange={handleMenuChange}
          />
        </Box>
      </Sidebar>
    </Box>
  );
};

export default NewEditGuestOrderingShift;
