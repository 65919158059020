import Week from 'App/Calendar/Components/Week';
import WeekDays from 'App/Settings/Components/Weekdays';
import Button from 'Components/Atoms/Button';
import Checkbox from 'Components/Atoms/Checkbox';
import Divider from 'Components/Atoms/Divider';
import MenuItem from 'Components/Atoms/MenuItem';
import TextField from 'Components/Atoms/TextField';
import Modal from 'Components/Molecules/Modal';
import { Severity } from 'Contexts/RestaurantContext';
import useDocument from 'CustomHooks/useDocument';
import useToast from 'CustomHooks/useToast';
import {
  DayOfWeek,
  MonthlyDayOfWeekRule,
  Reservation,
  ReservationRule,
  Stammtisch,
  WeeklyRule,
} from 'gastronaut-shared/types/helper/reservations';
import React, { useEffect, useMemo, useState } from 'react';
import colors from 'utils/colors';
import { getNthWeekdayOfMonthFromDate } from 'utils/helper';

function transferDate(
  date: string,
  seperator = date.includes('.') ? '.' : '-'
) {
  return date
    .split(seperator)
    .reverse()
    .join(seperator === '.' ? '-' : '.');
}

const WEEKDAYS = [
  'Sonntag',
  'Montag',
  'Dienstag',
  'Mittwoch',
  'Donnerstag',
  'Freitag',
  'Samstag',
];

function toDotSeperatedDate(date?: string) {
  if (!date) return '01.01';

  let [_, mm, dd] = date.split('-');

  return `${dd}.${mm}`;
}

const RULE_EXAMPLES: Record<ReservationRule['type'], ReservationRule> = {
  daily: {
    type: 'daily',
    every: 1,
  },
  weekly: {
    type: 'weekly',
    every: 1,
    days: [6],
  },
  monthlyDayOfWeek: {
    type: 'monthlyDayOfWeek',
    option: 1,
    days: [1],
  },
  monthlySpecificDay: {
    type: 'monthlySpecificDay',
    day: 1,
  },
  yearlySpecificDate: {
    type: 'yearlySpecificDate',
    date: '01.01',
  },
  specific: {
    type: 'specific',
    dates: [],
  },
};

const RegularTableButton = ({ reservation }: { reservation: Reservation }) => {
  const [regularTable, setState] = useDocument<
    Stammtisch & { isNew?: boolean }
  >('regularTables', reservation.stammtischId || reservation.id || '');

  const [modalOpen, setModalOpen] = useState(false);

  const toast = useToast();

  const handleOpenNew = () => {
    if (regularTable.data !== null) return;

    const {
      createdAt,
      updatedAt,
      updatedBy,
      updateNote,
      canceledVia,
      canceledBy,
      cancelationReason,
      sendCancelationEmail,
      eventId,
      event,
      ticketId,
      minimumConsumption,
      ...resa
    } = reservation;

    if (event || eventId || ticketId || minimumConsumption) {
      //@TODO
      return toast('', Severity.ERROR);
    }

    setState({
      reservation: resa,
      isNew: true,
      restaurant: resa.restaurant,
      rule: { type: 'daily', every: 1 },
      active: true,
      id: reservation.id || '',
    });
    setModalOpen(true);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!regularTable?.data?.id || !reservation.stammtischId) {
      let data = { ...regularTable.data };
      delete data.isNew;
      await regularTable.ref.set(data);
    }

    setModalOpen(false);
  };

  const isRegularTable =
    !!reservation.stammtischId ||
    (regularTable.data !== null && !regularTable.data.isNew);

  const handleTypeSwitch = (type: ReservationRule['type']) => {
    if (!regularTable?.data) return;
    if (regularTable.data?.rule?.type === type) return;

    let rule = regularTable.data.rule;

    switch (type) {
      case 'daily':
        rule = RULE_EXAMPLES['daily'];
        break;
      case 'weekly':
        rule = {
          type: 'weekly',
          every: 1,
          days: [
            new Date(
              regularTable.data.reservation.date ?? Date.now()
            ).getDay() as DayOfWeek,
          ],
        };
        break;
      case 'monthlyDayOfWeek':
        rule = {
          type: 'monthlyDayOfWeek',
          option: regularTable.data.reservation.date
            ? getNthWeekdayOfMonthFromDate(regularTable.data.reservation.date)
            : 1,
          days: [
            new Date(
              regularTable.data.reservation.date ?? Date.now()
            ).getDay() as DayOfWeek,
          ],
        };
        break;
      case 'yearlySpecificDate':
        rule = {
          type: 'yearlySpecificDate',
          date: toDotSeperatedDate(regularTable.data.reservation.date),
        };

        break;
      case 'monthlySpecificDay':
        rule = {
          type: 'monthlySpecificDay',
          day: new Date(
            regularTable.data.reservation.date ?? Date.now()
          ).getDate(),
        };
        break;
      case 'specific': {
        rule = {
          type: 'specific',
          dates: [],
        };
        setDates('');
        break;
      }
      default:
        break;
    }

    setState({
      ...regularTable.data,
      rule,
    });
  };

  const [dates, setDates] = useState<string>(
    (regularTable?.data as any)?.dates?.join(', ') ?? ''
  );

  const date = useMemo(() => {
    const dateString = regularTable?.data?.reservation.date ?? reservation.date;
    const date = new Date(dateString);

    return {
      wDay: date.getDay(),
      day: date.getDate(),
      date: toDotSeperatedDate(dateString),
      nthWeek: getNthWeekdayOfMonthFromDate(dateString),
    };
  }, [reservation.date]);

  if (
    reservation.event ||
    reservation.eventId ||
    reservation.minimumConsumption ||
    reservation.ticketId
  )
    return <></>;

  return (
    <>
      {!isRegularTable ? (
        <Button
          onClick={handleOpenNew}
          loading={regularTable.loading}
          variant="transparent"
          fullWidth
        >
          Stammtisch erstellen
        </Button>
      ) : (
        <Button
          onClick={() => setModalOpen(true)}
          loading={regularTable.loading}
          variant="secondary"
          fullWidth
        >
          Stammtisch bearbeiten
        </Button>
      )}
      <Modal
        open={modalOpen && !!regularTable.data}
        onClose={() => {
          setModalOpen(false);
          if (regularTable.data?.isNew) setState(null);
        }}
        title="Stammtisch"
        maxWidth="xs"
        fullWidth
        onSubmit={handleSubmit}
        actions={[
          ...(isRegularTable
            ? [
                {
                  id: 'delete',
                  label: 'Delete',
                  onClick: () => regularTable.ref.delete(),
                  // color: 'error'
                },
              ]
            : []),
          { id: 'cancel', label: 'Cancel', onClick: () => setModalOpen(false) },
          { id: 'submit', label: 'Submit', submit: true, variant: 'primary' },
        ]}
      >
        {!!regularTable.data && (
          <>
            <TextField
              select
              value={regularTable?.data?.rule?.type || 'daily'}
              onChange={(e) =>
                handleTypeSwitch(e.target.value as ReservationRule['type'])
              }
              label="Regel"
              fullWidth
            >
              <MenuItem value="daily">Jeden Tag</MenuItem>
              <MenuItem value="weekly">
                Jede Woche {WEEKDAYS[date.wDay]}
              </MenuItem>
              <MenuItem value="monthlyDayOfWeek">
                Jeden {date.nthWeek}. {WEEKDAYS[date.wDay]} im Monat
              </MenuItem>
              <MenuItem value="monthlySpecificDay">
                Jeden {date.day}. Tag im Monat
              </MenuItem>
              <MenuItem value="yearlySpecificDate">
                Jedes Jahr am {date.date}
              </MenuItem>
              <MenuItem value="specific">Bestimmte Tage</MenuItem>
            </TextField>
            {regularTable.data.rule.type === 'daily' && <></>}
            {regularTable.data.rule.type === 'weekly' && (
              <>
                <WeekDays
                  weekDays={regularTable.data.rule.days}
                  onChange={(days: any) =>
                    setState((st) =>
                      !!st
                        ? {
                            ...st,
                            rule: {
                              type: 'weekly',
                              days,
                              every: (st.rule as WeeklyRule).every,
                            },
                          }
                        : null
                    )
                  }
                />
                <TextField
                  value={regularTable.data.rule.every}
                  onChange={(e) =>
                    setState((st) =>
                      !!st
                        ? {
                            ...st,
                            rule: {
                              type: 'weekly',
                              days: (st.rule as WeeklyRule).days,
                              every: parseInt(e.target.value),
                            },
                          }
                        : null
                    )
                  }
                  label="Alle x Wochen"
                  type="number"
                  fullWidth
                />
              </>
            )}
            {regularTable.data.rule.type === 'monthlySpecificDay' && (
              <>
                <TextField
                  value={regularTable.data.rule.day}
                  onChange={(e) =>
                    setState((st) =>
                      !!st
                        ? {
                            ...st,
                            rule: {
                              type: 'monthlySpecificDay',
                              day: parseInt(e.target.value),
                            },
                          }
                        : null
                    )
                  }
                  label="Tag im Monat"
                  type="number"
                  fullWidth
                  inputProps={{
                    min: 1,
                    max: 31,
                  }}
                />
              </>
            )}
            {regularTable.data.rule.type === 'monthlyDayOfWeek' && (
              <>
                <WeekDays
                  weekDays={regularTable.data.rule.days}
                  onChange={(days: any) =>
                    setState((st) =>
                      !!st
                        ? {
                            ...st,
                            rule: {
                              type: 'monthlyDayOfWeek',
                              days,
                              option: (st.rule as MonthlyDayOfWeekRule).option,
                            },
                          }
                        : null
                    )
                  }
                />
                <TextField
                  value={regularTable.data.rule.option}
                  onChange={(e) =>
                    setState((st) =>
                      !!st
                        ? {
                            ...st,
                            rule: {
                              type: 'monthlyDayOfWeek',
                              days: (st.rule as MonthlyDayOfWeekRule).days,
                              option: parseInt(e.target.value),
                            },
                          }
                        : null
                    )
                  }
                  label="Xte Woche im Monat"
                  type="number"
                  fullWidth
                  inputProps={{
                    min: 1,
                    max: 5,
                  }}
                />
              </>
            )}
            {regularTable.data.rule.type === 'yearlySpecificDate' && (
              <>
                <TextField
                  value={regularTable.data.rule.date}
                  onChange={(e) =>
                    setState((st) =>
                      !!st
                        ? {
                            ...st,
                            rule: {
                              type: 'yearlySpecificDate',
                              date: e.target.value,
                            },
                          }
                        : null
                    )
                  }
                  label="Datum"
                  type="date"
                  placeholder="DD.MM"
                  inputProps={{
                    min: `${new Date().getFullYear()}-01-01`,
                    max: `${new Date().getFullYear()}-12-31`,
                  }}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </>
            )}
            {regularTable.data.rule.type === 'specific' && (
              <>
                <TextField
                  value={dates}
                  onChange={(e) => setDates(e.target.value)}
                  onBlur={(e) => {
                    const d = dates
                      .split(',')
                      .map((x) => x.trim())
                      .filter((x) => !!x)
                      .map((x) => transferDate(x));

                    setState((st) =>
                      !!st
                        ? {
                            ...st,
                            dates: d,
                          }
                        : null
                    );

                    setDates(d.map((x) => transferDate(x)).join(', '));
                  }}
                  label="Daten"
                  helperText="Jedes Datum muss durch ein Komma getrennt werden und in folgendem Format DD.MM.JJJJ"
                  placeholder="DD.MM.JJJJ"
                  fullWidth
                  multiline
                />
              </>
            )}
            <TextField
              value={regularTable.data.endDate ?? ''}
              onChange={(e) =>
                setState((st) =>
                  !!st
                    ? {
                        ...st,
                        endDate: e.target.value,
                      }
                    : null
                )
              }
              onBlur={(e) => {
                setState((st) =>
                  !!st
                    ? {
                        ...st,
                        endDate: st.endDate || null,
                      }
                    : null
                );
              }}
              label="Letztes Datum"
              type="date"
              placeholder="DD.MM"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Divider margin="md" />
            <TextField
              value={regularTable.data.reservation.guest.name}
              onChange={(e) =>
                setState((st) =>
                  !!st
                    ? {
                        ...st,
                        reservation: {
                          ...st.reservation,
                          guest: {
                            ...st.reservation.guest,
                            name: e.target.value,
                          },
                        },
                      }
                    : null
                )
              }
              label="Name"
              fullWidth
            />
            <TextField
              value={regularTable.data.reservation.time}
              onChange={(e) =>
                setState((st) =>
                  !!st
                    ? {
                        ...st,
                        reservation: {
                          ...st.reservation,
                          time: e.target.value,
                        },
                      }
                    : null
                )
              }
              label="Uhrzeit"
              type="time"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              value={regularTable.data.reservation.guests}
              onChange={(e) =>
                setState((st) =>
                  !!st
                    ? {
                        ...st,
                        reservation: {
                          ...st.reservation,
                          guests: parseInt(e.target.value),
                        },
                      }
                    : null
                )
              }
              label="Anzahl Gäste"
              type="number"
              fullWidth
              inputProps={{
                min: 1,
              }}
            />
            <TextField
              select
              value={regularTable.data.reservation.reservationLength}
              onChange={(e) =>
                setState((st) =>
                  !!st
                    ? {
                        ...st,
                        reservation: {
                          ...st.reservation,
                          reservationLength: parseInt(e.target.value),
                        },
                      }
                    : null
                )
              }
              label="Reservierungslänge"
              fullWidth
              inputProps={{
                min: 1,
              }}
            >
              <MenuItem value={1}>00:15</MenuItem>
              <MenuItem value={2}>00:30</MenuItem>
              <MenuItem value={3}>00:45</MenuItem>
              <MenuItem value={4}>01:00</MenuItem>
              <MenuItem value={5}>01:15</MenuItem>
              <MenuItem value={6}>01:30</MenuItem>
              <MenuItem value={7}>01:45</MenuItem>
              <MenuItem value={8}>02:00</MenuItem>
              <MenuItem value={10}>02:30</MenuItem>
              <MenuItem value={12}>03:00</MenuItem>
              <MenuItem value={14}>03:30</MenuItem>
              <MenuItem value={16}>04:00</MenuItem>
              <MenuItem value={18}>04:30</MenuItem>
              <MenuItem value={20}>05:00</MenuItem>
              <MenuItem value={22}>05:30</MenuItem>
              <MenuItem value={24}>06:00</MenuItem>
              <MenuItem value={26}>06:30</MenuItem>
              <MenuItem value={28}>07:00</MenuItem>
              <MenuItem value={30}>07:30</MenuItem>
              <MenuItem value={32}>08:00</MenuItem>
            </TextField>
            <TextField
              value={regularTable.data.reservation.guest.hostComment || ''}
              onChange={(e) =>
                setState((st) =>
                  !!st
                    ? {
                        ...st,
                        reservation: {
                          ...st.reservation,
                          guest: {
                            ...st.reservation.guest,
                            hostComment: e.target.value,
                          },
                        },
                      }
                    : null
                )
              }
              label="Service-Kommentar"
              fullWidth
              multiline
              minRows={2}
              className="mg-bt-sm"
            />
            <TextField
              value={regularTable.data.reservation.tables?.join(', ') || ''}
              onChange={(e) =>
                setState((st) =>
                  !!st
                    ? {
                        ...st,
                        reservation: {
                          ...st.reservation,
                          tables: e.target.value.split(', '),
                        },
                      }
                    : null
                )
              }
              label="Tische"
              helperText="Tisch durch Komma trennen"
              fullWidth
            />
            <Checkbox
              checked={regularTable.data.reservation.guest.sendEmail}
              onChange={(e) =>
                setState((st) =>
                  !!st
                    ? {
                        ...st,
                        reservation: {
                          ...st.reservation,
                          guest: {
                            ...st.reservation.guest,
                            sendEmail: e.target.checked,
                          },
                        },
                      }
                    : null
                )
              }
              label="E-Mail senden"
            />
          </>
        )}
        {/* Rules */}

        {/* 
          Fields
          - time
          - guests
          - guest
          - reservationLength
          - attr
          - hostComment
          - sendEmail
        */}
      </Modal>
    </>
  );
};

export default RegularTableButton;
