import React from "react";
import { sizeHelper } from "utils/helper";
import { Size } from "utils/types";
import "./styles.scss";

type DividerProps = {
  margin?: Size;
  style?: React.CSSProperties;
  text?: string;
};

const Divider = ({ margin = 0, style = {} }: DividerProps) => {
  const verticalMargin = sizeHelper(margin);

  return (
    <div
      className="divider"
      style={{ margin: `${verticalMargin}px 0`, ...style }}
    />
  );
};

export default Divider;
