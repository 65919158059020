import React from "react";
import Box from "Components/Atoms/Box";
import "./styles.scss";
import { Reservation } from "types/reservations";
import Typography from "Components/Atoms/Typography";
import { toCurrencyString } from "utils/helper";
import useLanguage from "CustomHooks/useLanguage";

export type MinimumConsumptionProps = {
  reservation: Reservation;
};

const MinimumConsumption: React.FC<MinimumConsumptionProps> = ({
  reservation,
}) => {
  const { language } = useLanguage();

  const minimumConsumption = reservation.minimumConsumption;

  if (!minimumConsumption) {
    return <></>;
  }

  const amount = minimumConsumption.amount;

  const paymentStatus = minimumConsumption.status ?? null;

  return (
    <div>
      <Box style={{ padding: "16px 16px 0 16px" }}>
        <Typography
          className="colon"
          variant="text-3"
          translation="reservations"
          color="secondary"
        >
          Minimum Consumption
        </Typography>
      </Box>
      <Box className="space-between" style={{ padding: "8px 16px 0 16px" }}>
        <Typography variant="text-3" translation="reservations">
          Titel
        </Typography>
        <Typography variant="text-2" color="subdued" translation={null}>
          {minimumConsumption.title?.[language] ??
            minimumConsumption.title?.de ??
            "Mindestverzehr"}
        </Typography>
      </Box>
      <Box className="space-between" style={{ padding: "8px 16px 0px 16px" }}>
        <Typography variant="text-3" translation="reservations">
          Price paid
        </Typography>
        <Typography variant="text-2" color="subdued" translation={null}>
          {toCurrencyString(amount, "€", false, 1)}
        </Typography>
      </Box>
      <Box className="space-between reservation-field">
        <Typography variant="text-3" translation="reservations">
          Payment Status
        </Typography>
        <Typography
          variant="text-2"
          translation="reservations"
          color={
            paymentStatus !== "processing" && paymentStatus !== "succedded"
              ? "error"
              : "subdued"
          }
        >
          {paymentStatus ?? ""}
        </Typography>
      </Box>
    </div>
  );
};

export default MinimumConsumption;
