import React from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import { asyncVoid } from 'utils/helper';
import TableHeader from './Components/TableHeader';
import ReviewRow from './Components/ReviewRow';
import { CircularProgress } from '@material-ui/core';
import Typography from 'Components/Atoms/Typography';
import { Rating } from 'types/feedback';
import { max } from 'lodash';

export type FeedbackTableProps = {
  reviews: Rating[];
  onLoadMore?: () => Promise<void>; // When the end of the List is almost reached we make a request to load more reservations
  loading?: boolean;
  onDone: (id: string) => Promise<void>;
  hasMore?: boolean;
  maxHeight?: string | number;
};

const FeedbackTable = ({
  reviews,
  onLoadMore,
  loading = false,
  onDone = asyncVoid,
  hasMore = true,
  maxHeight,
}: FeedbackTableProps) => {
  return (
    <Box
      outline
      style={{
        backgroundColor: 'var(--color-paper)',
        minWidth: '100%',
        width: 'fit-content',
        maxHeight,
        overflow: !!maxHeight ? 'scroll' : undefined,
      }}
      padding={0}
    >
      <table id="feedbackTable" cellSpacing={0}>
        <TableHeader top={maxHeight ? 0 : 42} />
        {reviews.map((r, i) => (
          <ReviewRow key={'review' + i} review={r} onDone={onDone} />
        ))}
        {!reviews.length && (
          <tr>
            <td colSpan={9} align="center">
              <Typography
                variant="text-3"
                color="subdued"
                translation="feedback"
              >
                No Data
              </Typography>
            </td>
          </tr>
        )}
        {loading && hasMore && (
          <tr>
            <td colSpan={9} align="center">
              <CircularProgress />
            </td>
          </tr>
        )}
      </table>
    </Box>
  );
};

export default FeedbackTable;
