import { Star, StarHalf, StarOutline } from "Components/Atoms/Icons";
import React, { useMemo } from "react";

export type RatingStarsProps = {
  rating: number;
};

const RatingStars = ({ rating }: RatingStarsProps) => {
  const ratingArray = useMemo(() => {
    let answer = [];
    for (let i = 1; i < 6; i++) {
      if (i <= rating) {
        answer.push("full");
      } else if (i > rating) {
        answer.push("empty");
      }
    }
    // Half point handling
    if ((rating * 10) % 2 !== 0) {
      answer[Math.trunc(rating)] = "half";
    }

    return answer;
  }, [rating]);
  return (
    <div>
      {ratingArray.map((star, index) => (
        <span key={"star" + index}>
          {star === "full" && (
            <Star style={{ color: "var(--color-warning)" }} />
          )}
          {star === "empty" && (
            <StarOutline style={{ color: "var(--color-warning)" }} />
          )}
          {star === "half" && (
            <StarHalf style={{ color: "var(--color-warning)" }} />
          )}
        </span>
      ))}
    </div>
  );
};

export default RatingStars;
