import React from "react";

const ShoppingCartIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.83325 1.66797H3.56224C4.49964 1.66797 5.31127 2.31903 5.51462 3.23411L5.99992 5.41797M5.99992 5.41797L6.77082 9.65795C7.28954 12.5109 9.77433 14.5846 12.674 14.5846H13.2939C16.0953 14.5846 18.5238 12.6461 19.1447 9.91436L19.7501 7.2504C19.9634 6.31176 19.25 5.41797 18.2874 5.41797H5.99992Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <ellipse
      cx="8.08333"
      cy="17.5013"
      rx="0.833333"
      ry="0.833334"
      stroke="currentColor"
      stroke-width="2"
    />
    <ellipse
      cx="18.0833"
      cy="17.5013"
      rx="0.833333"
      ry="0.833334"
      stroke="currentColor"
      stroke-width="2"
    />
  </svg>
);

export default ShoppingCartIcon;
