import React, { useContext, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import Ticket from '@material-ui/icons/LocalActivity';
import Badge from 'Components/Atoms/Badge';
import IconButton from 'Components/Atoms/IconButton';
import { ReservationContext } from 'Contexts/ReservationContext';
import { Event } from 'gastronaut-shared/types/documents/restaurants';
import { classHelper } from 'utils/helper';
import Typography from 'Components/Atoms/Typography';
import { ArrowForward, Close } from '@material-ui/icons';
import EventCard from './Components/EventCard';
import { useEventCard } from 'App/Settings/Components/CurrentReservationShiftCard/Components/EventCard';
import useLanguage from 'CustomHooks/useLanguage';
import CurrentEvent from './Components/CurrentEvent';
import { TicketDocument } from 'gastronaut-shared/types/documents';
import useCollection from 'CustomHooks/useCollection';
import { Reservation } from 'types/reservations';
import { AddOn } from 'App/Experiences/types/addOn';
import { AddOnOrder, CartItem } from 'App/Experiences/types/addOnOrder';
import AddOnCard from './Components/AddOnCard';
import CurrentAddOn from './Components/CurrentAddOn';

export type EventModalProps = {
  className?: string;
  restaurantId: string;
};

export type ExtendedEvent = Event & {
  shift: { start: number; close: number; occassions: string[]; id: string };
  tickets: (TicketDocument & { reservation: Reservation })[];
};

const Portal: React.FC<{}> = ({ children }) => {
  return ReactDOM.createPortal(children, document.body);
};

const EventModal: React.FC<EventModalProps> = ({ className, restaurantId }) => {
  const [open, setopen] = useState(false);
  const [selected, setselected] = useState<string | null>(null);
  const [tab, setTab] = useState<'events' | 'addOns'>('events');

  const { language } = useLanguage();

  const { date, shifts, reservations, addOnOrders } =
    useContext(ReservationContext);

  const [ticketData] = useCollection<TicketDocument>(`ticketsV02`, {
    filter: [
      ['date', '==', date],
      ['restaurant', '==', restaurantId],
    ],
  });

  const tickets = useMemo(() => {
    return ticketData.data
      .filter(
        (t) => t?.guest?.name && t.guest.name !== 'Wird gerade erstellt...'
      )
      .map((t) => {
        let reservation =
          reservations.find((r) => r.id === t.reservation) ?? null;

        return {
          ...t,
          reservation,
        };
      });
  }, [reservations, ticketData]);

  const events = useMemo(() => {
    if (!shifts?.length) return [];

    let events: Omit<ExtendedEvent, 'tickets'>[] = [];

    shifts.forEach((shift) => {
      if (
        !shift.active ||
        shift.disabled ||
        shift.closed ||
        !shift?.additional?.eventV02
      )
        return;

      let event = shift.additional.eventV02;

      let index = events.findIndex(
        (e) =>
          e.id === event.id &&
          shift.occassions.every((occ) => e.shift.occassions.includes(occ))
      );

      if (index === -1) {
        events.push({
          ...event,
          shift: {
            id: shift.id,
            start: shift.start,
            close: shift.close,
            occassions: shift.occassions,
          },
        });
      } else {
        events[index].shift.occassions = Array.from(
          new Set([...events[index].shift.occassions, ...shift.occassions])
        );
      }
    });

    return events.map((event, i, arr) => {
      let filteredTickets = tickets
        .filter(
          ({ event: e, reservation }) =>
            e.id === event.id &&
            event.shift.occassions.includes(reservation?.occassion ?? '')
        )
        .sort((a, b) => {
          let paid = (a.paid ? 0 : 1) - (b.paid ? 0 : 1);

          if (!paid) return (b?.createdAt ?? 0) - (a?.createdAt ?? 0);

          return paid;
        });

      return {
        ...event,
        tickets: filteredTickets,
      } as ExtendedEvent;
    });
  }, [date, shifts, tickets]);

  const selectedEvent = useMemo(() => {
    if (tab === 'addOns') return null;
    return events.find((event) => `${event.id}-${event.shift.id}` === selected);
  }, [events, selected, tab]);

  const ticketSummary = useMemo(() => {
    const event = selectedEvent;

    if (ticketData.loading) {
      return ['Loading Tickets...'];
    } else if (ticketData.error) {
      return [ticketData.error];
    } else if (!ticketData.data.filter((t) => t.eventId === event?.id).length) {
      return ['Keine Tickets verkauft'];
    } else {
      let arr: string[] = [];

      let ticketsMissingPayments = ticketData.data
        .filter(
          (t) =>
            t.eventId === event?.id &&
            (t.createdAt ?? Date.now()) >= Date.now() - 24 * 60 * 60000 &&
            t.status === 'missing_payments'
        )
        .reduce((acc, cV) => acc + cV.guests, 0);
      let tickets = ticketData.data.filter(
        (t) =>
          t.eventId === event?.id &&
          (t.status === 'succedded' || t.status === 'processing')
      );
      // let failed = ticketData.data.filter(t => t.status === 'failed').reduce((acc, cV) => acc + cV.guests, 0);

      if (tickets.length) {
        let ticketCount = tickets.reduce((acc, cV) => acc + cV.guests, 0);
        let turnover = tickets.reduce((acc, cV) => acc + cV.amount, 0);
        arr.push(`Verkaufte Tickets: ${ticketCount} (${turnover}€)`);
      }

      if (ticketsMissingPayments) {
        arr.push(`Zahlung Ausstehend: ${ticketsMissingPayments}`);
      }

      if ((event?.options?.length ?? 0) > 1) {
        let optionMap = tickets.reduce((acc, cV) => {
          let x = { ...acc };

          Object.entries(cV.options ?? {}).forEach(([id, val]) => {
            if (val !== 0) {
              x[id] = (x[id] ?? 0) + val;
            }
          });

          return x;
        }, {} as Record<string, number>);

        let options = Object.entries(optionMap)
          .sort((a, b) => b[1] - a[1])
          .map(([id, val]) => {
            let opt = event?.options?.find((x) => x.id === id);

            return `${
              opt?.translation?.['de']?.title ?? opt?.internalName ?? id
            }: ${val}`;
          });

        if (options.length) {
          arr.push('Optionen:', ...options);
        }
      }

      let addOnMap = tickets.reduce((acc, cV) => {
        let x = { ...acc };

        Object.entries(cV.optionalAddOns ?? {}).forEach(([id, val]) => {
          if (val !== 0) {
            x[id] = (x[id] ?? 0) + val;
          }
        });

        return x;
      }, {} as Record<string, number>);

      let addOns = Object.entries(addOnMap)
        .sort((a, b) => b[1] - a[1])
        .map(([id, val]) => {
          let opt = event?.optionalAddOns?.find((x) => x.id === id);

          return `${
            opt?.translation?.['de']?.title ?? opt?.internalName ?? id
          }: ${val}`;
        });

      if (addOns.length) {
        arr.push('AddOns:', ...addOns);
      }

      return arr;
    }
  }, [ticketData, selectedEvent]);

  useEffect(() => {
    if (events?.length === 1 && !addOnOrders.length) {
      if (tab === 'events')
        setselected(`${events[0].id}-${events[0].shift.id}`);
    } else if (events.length === 0) {
      if (tab === 'events') setselected(null);
      setTab('addOns');
    } else {
      if (
        !!selected &&
        !events.find((e) => `${e.id}-${e.shift.id}` === selected)
      ) {
        if (tab === 'events') setselected(null);
      }
    }
  }, [events, addOnOrders]);

  const ticketsSold = useMemo(
    () =>
      (tickets
        ?.filter((t) => t.status === 'succedded' || t.status === 'processing')
        ?.reduce((acc, cV) => acc + cV.guests, 0) ?? 0) +
        addOnOrders
          ?.filter(
            (x) =>
              (x.status === 'succedded' || x.status === 'processing') &&
              x.cart.length > 0
          )
          ?.reduce((acc, cV) => acc + cV.reservation.guests, 0) ?? 0,
    [tickets, addOnOrders]
  );

  const addOns = useMemo(() => {
    let addOns: {
      id: string;
      title: string;
      description: string;
      total: number;
      count: number;
      orders: (AddOnOrder & { text: string })[];
      choices: Record<string, number>;
    }[] = [];

    let addOnIds = Array.from(
      new Set(
        addOnOrders
          .map((x) => x.cart.filter((x) => !x.refunded).map((x) => x.id))
          .flat(2)
      )
    );

    addOnIds.forEach((id) => {
      let orders = addOnOrders
        .map((x) => {
          let item = x.cart.find((x) => x.id === id && !x.refunded);

          return {
            ...x,
            item,
          };
        })
        .filter((x) => x.item) as (AddOnOrder & { item: CartItem })[];

      let addOn = orders?.[0]?.cart?.find((x) => x.id === id && !x.refunded);

      if (!addOn) return;

      let total = orders.reduce(
        (acc, cV) =>
          cV.status !== 'succedded' && cV.status !== 'processing'
            ? acc
            : acc + (cV?.item?.total ?? 0),
        0
      );

      let choices: Record<string, number> = {};

      if (addOn.type === 'multiple_select') {
        orders.forEach((x) => {
          if (
            !x.item ||
            x.item.type !== 'multiple_select' ||
            (x.status !== 'succedded' && x.status !== 'processing')
          )
            return;
          x.item.choices.forEach((x) => {
            choices[x.title] = (choices[x.title] ?? 0) + x.count;
          });
        });
      } else if (addOn.type === 'select') {
        orders.forEach((x) => {
          if (
            !x.item ||
            x.item.type !== 'select' ||
            (x.status !== 'succedded' && x.status !== 'processing')
          )
            return;
          choices['Gruppen: ' + x.item.choice.title] =
            (choices['Gruppen: ' + x.item.choice.title] ?? 0) + 1;
          choices['Gäste: ' + x.item.choice.title] =
            (choices['Gäste: ' + x.item.choice.title] ?? 0) +
            x.reservation.guests;
        });
      }

      Object.entries(choices).forEach(([key, val]) => {
        if (!val) {
          delete choices[key];
        }
      });

      addOns.push({
        id,
        title: addOn.title,
        description: addOn.description,
        total,
        count: orders.filter(
          (x) => x.status === 'succedded' || x.status === 'processing'
        ).length,
        orders: orders.map(({ item, ...x }) => {
          let text = '';

          if (!item) return { ...x, text };

          if (item.type === 'select') {
            text = item.choice.title;
          } else if (item.type === 'multiple_select') {
            text = item.choices
              .map((x) => `${x.count} ${x.title}`)
              .filter((x) => !x.startsWith('0'))
              .join('\n');
          }

          return {
            ...x,
            text,
          };
        }),
        choices,
      });
    });

    return addOns;
  }, [addOnOrders]);

  const title =
    events.length === 1 && !addOns.length
      ? selectedEvent?.title?.de ?? 'Events'
      : 'Events';

  const selectedAddOn = useMemo(() => {
    if (tab !== 'addOns') return null;
    return addOns.find((a) => a.id === selected);
  }, [addOns, selected, tab]);

  return (
    <>
      {(!!events.length || !!addOnOrders.length) && (
        <IconButton
          size="small"
          onClick={() => setopen((o) => !o)}
          style={{ marginRight: 8 }}
        >
          <Badge number={ticketsSold}>
            <Ticket />
          </Badge>
        </IconButton>
      )}
      {open && (
        <Portal>
          <Box className={classHelper(['eventModal', className])}>
            <Box className="mainWindow paper" outline elevation={4}>
              <Box className="flex space-between mg-bt-sm">
                <Box className="flex">
                  {!!events.length && (
                    <Typography
                      block
                      variant={title.length > 28 ? 'h6' : 'h5'}
                      style={{
                        opacity: tab === 'events' ? 1 : 0.5,
                        marginRight: 8,
                        cursor: 'pointer',
                      }}
                      onClick={() => setTab('events')}
                    >
                      {title}
                    </Typography>
                  )}
                  <Typography
                    block
                    variant={title.length > 28 ? 'h6' : 'h5'}
                    style={{
                      opacity: tab === 'addOns' ? 1 : 0.5,
                      marginRight: 4,
                      cursor: 'pointer',
                    }}
                    onClick={() => setTab('addOns')}
                  >
                    AddOns
                  </Typography>
                </Box>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    setopen(false);
                  }}
                >
                  <Close />
                </IconButton>
              </Box>
              {tab === 'events' && (
                <>
                  {!!(
                    events.length > 1 ||
                    addOns.length ||
                    (!selectedEvent && events.length)
                  ) && (
                    <div className="list" id="callList">
                      {events.map((event) => (
                        <EventCard
                          key={event.id}
                          event={event}
                          selected={
                            selected === `${event.id}-${event.shift.id}`
                          }
                          onClick={() =>
                            setselected(`${event.id}-${event.shift.id}`)
                          }
                        />
                      ))}
                      {/* {filteredCalls.length >= 1 && <NextPage />} */}
                    </div>
                  )}
                  {events.length === 1 && !!selectedEvent && !addOns.length && (
                    <div
                      style={{
                        overflow: 'scroll',
                        marginRight: -10,
                        marginBottom: -8,
                      }}
                    >
                      <CurrentEvent
                        event={selectedEvent}
                        loading={ticketData.loading}
                        error={ticketData.error}
                        date={date}
                        restaurantId={restaurantId}
                      />
                    </div>
                  )}
                  {events.length === 0 && (
                    <Typography
                      variant="text-3"
                      textAlign="center"
                      component="p"
                      style={{ marginTop: '50%' }}
                    >
                      Keine Veranstaltungen an diesem Tag
                    </Typography>
                  )}
                </>
              )}
              {tab === 'addOns' && (
                <div className="list" id="callList">
                  {addOns.map((addOn) => (
                    <AddOnCard
                      key={addOn.id}
                      addOn={addOn}
                      selected={selected === addOn.id}
                      onClick={() => setselected(addOn.id)}
                    />
                  ))}
                  {/* {filteredCalls.length >= 1 && <NextPage />} */}
                </div>
              )}
            </Box>
            {!!(events.length > 1 || addOns.length) && !!selectedEvent && (
              <Box className="selectedEvent paper" outline elevation={4}>
                <Box className="flex space-between mg-bt-sm">
                  <div>
                    <Typography block variant="h5">
                      {selectedEvent.title?.de ?? 'Kein Event Name'}
                    </Typography>
                  </div>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setselected(null);
                    }}
                  >
                    <ArrowForward />
                  </IconButton>
                </Box>
                <div
                  style={{
                    overflow: 'scroll',
                    marginRight: -10,
                    marginBottom: -8,
                  }}
                >
                  <CurrentEvent
                    event={selectedEvent}
                    loading={ticketData.loading}
                    error={ticketData.error}
                    date={date}
                    restaurantId={restaurantId}
                  />
                </div>
              </Box>
            )}
            {selectedAddOn && (
              <Box className="selectedEvent paper" outline elevation={4}>
                <Box className="flex space-between mg-bt-sm">
                  <div>
                    <Typography block variant="h5">
                      {selectedAddOn.title}
                    </Typography>
                  </div>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setselected(null);
                    }}
                  >
                    <ArrowForward />
                  </IconButton>
                </Box>
                <div
                  style={{
                    overflow: 'scroll',
                    marginRight: -10,
                    marginBottom: -8,
                  }}
                >
                  <CurrentAddOn addOn={selectedAddOn} />
                </div>
              </Box>
            )}
            {/* {!!currentCall && !settingsOpen && <CurrentCall />} */}
          </Box>
        </Portal>
      )}
    </>
  );
};

export default EventModal;
