import React, { useContext, useMemo, useState } from "react";
import Box from "Components/Atoms/Box";
import PieChartCard from "Components/Molecules/PieChartCard";
import GroupsPerInterval from "Components/Organisms/GroupsPerInterval";
import ShiftReservationCard from "Components/Organisms/ShiftReservationCard";
import UtilizationByTime from "Components/Organisms/UtilizationByTime";
import { reservationToSnippet, findOverlaps, dateHelper } from "utils/helper";
import { ReservationContext } from "Contexts/ReservationContext";
import { ReservationStatus } from "types/reservations";
import { Forecast, ReservationSnippet } from "utils/types";
import { ReservationShift } from "types/shifts";
import useStatistics from "CustomHooks/useStatistics";
import { RestaurantContext } from "Contexts/RestaurantContext";
import MobileTotalReservationsCard from "Components/Organisms/MobileTotalResCard";

import "./styles.scss";

type MobileQuicklookProps = {
  forecast: Forecast | null;
  update: () => void;
};

const MobileQuicklook = ({ forecast = null, update }: MobileQuicklookProps) => {
  const { restaurantId } = useContext(RestaurantContext);
  const {
    reservations,
    shifts,
    occassions = [],
  } = useContext(ReservationContext);

  const [dateToModify, setDateToModify] = useState<string>("");
  const { date } = useStatistics(dateToModify, update);

  const reservationSnippets = useMemo(
    () =>
      reservations
        .filter((r) => !r.blockedFullShift && r.guests)
        .map(reservationToSnippet),
    [reservations]
  );

  //Total res
  const totalReservations = reservationSnippets
    .filter((r) => r.status !== ReservationStatus.FAILED)
    .reduce((acc, cV) => acc + cV.guests, 0);

  //Groups per interval
  const { richShifts, otherReservations } = useMemo(() => {
    let richShifts: (ReservationShift & {
      reservations: ReservationSnippet[];
    })[] = [];
    let resaIds: string[] = [];

    (shifts ?? []).forEach((s) => {
      let filteredRes = reservations.filter(
        (r) =>
          !r.blockedFullShift &&
          (s.occassions?.includes(r.occassion) ||
            s.spaces?.includes(r?.space ?? "")) &&
          findOverlaps(r, s.start || 0, s.close || 0, true) &&
          r.status !== ReservationStatus.FAILED
      );

      richShifts.push({
        ...s,
        reservations: filteredRes.map(reservationToSnippet),
      });

      resaIds.push(...filteredRes.map((r) => r.id));
    });

    let otherReservations = reservations
      .filter(
        (r) => !resaIds.includes(r.id) && r.status !== ReservationStatus.FAILED
      )
      .map(reservationToSnippet);

    return { richShifts, otherReservations };
  }, [reservations, shifts]);

  //Pie charts
  const reservationsByOccassions = useMemo(() => {
    return occassions.map((occ) => ({
      value: reservationSnippets
        .filter(
          (res) =>
            res.occassion === occ.id && res.status !== ReservationStatus.FAILED
        )
        .reduce((acc: number, cV) => acc + cV.guests, 0),
      label: occ.title,
      id: occ.id,
    }));
  }, [reservationSnippets, occassions]);

  const reservationBySource = useMemo(() => {
    let sourceTable = reservationSnippets
      .filter((res) => res.status !== ReservationStatus.FAILED)
      .reduce((acc: any, cV) => {
        if (!acc[cV.source]) {
          acc[cV.source] = cV.guests;
        } else {
          acc[cV.source] += cV.guests;
        }

        return acc;
      }, {});

    return Object.keys(sourceTable).map((id) => ({
      id,
      label: id,
      value: sourceTable[id],
    }));
  }, [reservationSnippets]);

  return (
    <Box className="mobile-quicklook-body" padding="md">
      <MobileTotalReservationsCard
        totalReservations={totalReservations}
        forecast={forecast}
        // handleAction={noop}
      />

      <Box
        flex
        style={{ marginBottom: 16, display: "flex", alignItems: "stretch" }}
      >
        <PieChartCard title="Occassions" data={reservationsByOccassions} />
        <Box style={{ width: 16 }}></Box>
        <PieChartCard title="Sources" data={reservationBySource} />
      </Box>

      <Box style={{ marginBottom: 16 }}>
        <GroupsPerInterval
          shifts={richShifts}
          otherReservations={otherReservations}
          style={{ marginBottom: 16 }}
        />

        <UtilizationByTime
          shifts={richShifts}
          otherReservations={otherReservations}
        />
      </Box>

      <Box style={{ marginBottom: 68 }}>
        <ShiftReservationCard
          reservations={reservationSnippets}
          restaurantId={restaurantId ?? ""}
          date={date || dateHelper()}
        />
      </Box>
    </Box>
  );
};

export default MobileQuicklook;
