import React from "react";
import Box, { BoxProps } from "Components/Atoms/Box";
import AnimateHeight from "react-animate-height";

import "./styles.scss";

export type AccordionProps = {
  TopPart?: JSX.Element;
  BottomPart?: JSX.Element;
  open: boolean;
  boxProbs?: BoxProps;
  children: React.ReactNode;
  duration?: number;
  style?: React.CSSProperties;
  className?: string;
  unmontedChildren?: boolean;
};

const Accordion = ({
  TopPart,
  BottomPart,
  open,
  boxProbs,
  children,
  duration = 200,
  className,
  style,
  unmontedChildren = false,
}: AccordionProps) => {
  return (
    <Box
      outline
      elevation={0}
      style={style}
      {...boxProbs}
      className={className}
    >
      {TopPart}
      <AnimateHeight duration={duration} height={open ? "auto" : 0}>
        {unmontedChildren ? open ? children : <></> : children}
      </AnimateHeight>
      {BottomPart}
    </Box>
  );
};

export default Accordion;
