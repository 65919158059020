import React from 'react';
import Box from 'Components/Atoms/Box';
import MenuItem from 'Components/Atoms/MenuItem';
import {
  CheckCircle,
  Cake,
  FilterHdr,
  Image,
  LocalDining,
  NotInterested,
  Star,
  Blacklist,
} from 'Components/Atoms/Icons';
import Typography from 'Components/Atoms/Typography';

export type OptionsListProps = {
  value: string[];
  onChange: (newValue: string[]) => void;
  customAttr?: string[];
};

const OPTIONS = [
  {
    id: 'vip',
    icon: (
      <Star
        fontSize="small"
        style={{ color: 'var(--color-warning)', marginRight: 8 }}
      />
    ),
  },
  {
    id: 'regular',
    icon: (
      <Star
        fontSize="small"
        style={{ color: 'var(--color-warning)', marginRight: 8 }}
      />
    ),
  },
  {
    id: 'blacklist',
    icon: (
      <Blacklist
        width="16"
        height="16"
        fontSize="small"
        style={{ color: 'var(--color-critical)', marginRight: 8 }}
      />
    ),
  },
  {
    id: 'terrace',
    icon: (
      <FilterHdr
        fontSize="small"
        style={{ color: 'var(--color-text)', marginRight: 8 }}
      />
    ),
  },
  {
    id: 'specialOccassion',
    icon: (
      <Cake
        fontSize="small"
        style={{ color: 'var(--color-secondary)', marginRight: 8 }}
      />
    ),
  },
  {
    id: 'window',
    icon: (
      <Image
        fontSize="small"
        style={{ color: 'var(--color-tertiary)', marginRight: 8 }}
      />
    ),
  },
  {
    id: 'confirmedByGuest',
    icon: (
      <CheckCircle
        fontSize="small"
        style={{ color: 'var(--color-success)', marginRight: 8 }}
      />
    ),
  },
  {
    id: 'preOrdered',
    icon: (
      <LocalDining
        fontSize="small"
        style={{ color: 'var(--color-text)', marginRight: 8 }}
      />
    ),
  },
];

const OptionsList = ({
  value,
  onChange,
  customAttr = [],
}: OptionsListProps) => {
  const handleClick = (clicked = '') => {
    if (value.includes(clicked)) {
      onChange(value.filter((v) => v !== clicked));
    } else {
      onChange([...value, clicked]);
    }
  };

  return (
    <Box>
      {customAttr.map((attr) => (
        <MenuItem
          key={attr}
          value={attr}
          selected={value.includes(attr)}
          onClick={() => {
            handleClick(attr);
          }}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Typography translation={null} variant="text-3">
            {attr}
          </Typography>
        </MenuItem>
      ))}
      {OPTIONS.map((opt) => (
        <MenuItem
          key={opt.id}
          value={opt.id}
          selected={value.includes(opt.id)}
          onClick={() => {
            handleClick(opt.id);
          }}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          {opt.icon}
          <Typography translation="common" variant="text-3">
            {opt.id}
          </Typography>
        </MenuItem>
      ))}
    </Box>
  );
};

export default OptionsList;
