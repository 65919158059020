import { RestaurantContext } from 'Contexts/RestaurantContext';
import { useContext } from 'react'

const useIsLightReservations = () => {

    const { products } = useContext(RestaurantContext)

    return products.includes('light-reservation');
}

export default useIsLightReservations
