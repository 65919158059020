import React, { useState } from "react";
import Box from "Components/Atoms/Box";
import { Collapse } from "@material-ui/core";
import Typography, { Colors } from "Components/Atoms/Typography";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import IconButton from "Components/Atoms/IconButton";

import "./styles.scss";
import { Translations } from "utils/types";

export type SeeMoreProps = {
  children: any;
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  color?: Colors;
  style?: React.CSSProperties;
  showMore?: string;
  showLess?: string;
  translation?: Translations;
  center?: boolean;
};

const SeeMore = ({
  children,
  showMore = "See More",
  showLess = "See Less",
  open: externalOpen,
  setOpen: externalSetOpen,
  color = "secondary",
  style,
  center = false,
  translation = "calendar",
}: SeeMoreProps) => {
  const [internalOpen, setInternalOpen] = useState(false);

  const open = externalOpen ?? internalOpen;
  const setOpen = externalSetOpen ?? setInternalOpen;

  return (
    <Box style={style}>
      <Collapse in={open}>{children}</Collapse>
      <Box
        style={
          center
            ? {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }
            : {}
        }
      >
        <Typography
          onClick={() => setOpen(!open)}
          color={color}
          variant="text-2"
          translation={translation}
        >
          {!open ? showMore : showLess}
        </Typography>
        <IconButton color="subdued" onClick={() => setOpen(!open)}>
          {open ? <ArrowDropUp /> : <ArrowDropDown />}
        </IconButton>
      </Box>
    </Box>
  );
};
export default SeeMore;
