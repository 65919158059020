import { TextField } from '@material-ui/core';
import Box from 'Components/Atoms/Box';
import Modal from 'Components/Molecules/Modal';
import SignatureDropdown from 'Components/Organisms/SignatureDropdown';
import { useVoucher } from 'Contexts/VoucherContext';
import React from 'react';
import { VoucherActionParameters, VoucherActions } from 'types/vouchersV02';
import DesignPicker from './DesignPicker';
import AmountField from './AmountField';
import MenuItem from 'Components/Atoms/MenuItem';
import ItemsTable from './ItemsTable';

const title = (action: VoucherActions) => {
  switch (action) {
    case VoucherActions.addPayment:
      return 'Add Payment';
    case VoucherActions.cancelRedeem:
      return 'Cancel Redeem';
    case VoucherActions.refundPayment:
      return 'Refund Payment';
    case VoucherActions.redeem:
      return 'Redeem';
    case VoucherActions.sendPerEmail:
      return 'Send per Email';
    case VoucherActions.downloadPdf:
      return 'Download PDF';
    case VoucherActions.editRedeem:
      return 'Edit Redeem';
    default:
      return '';
  }
};

const VoucherAction: React.FC<
  VoucherActionParameters & {
    onChange: (parmeters: VoucherActionParameters['parameters']) => void;
  }
> = ({ onChange, ...a }) => {
  const [type, settype] = React.useState('items');

  switch (a.action) {
    case VoucherActions.addPayment: {
      return (
        <Box>
          {/* <TextField
            label="Amount"
            onChange={(e) =>
              onChange({
                ...a.parameters,
                amount: {
                  value: e.target.value,
                  currency: 'EUR',
                },
              })
            }
            onBlur={(e) =>
              onChange({
                ...a.parameters,
                amount: {
                  value: parseFloat(e.target.value.replace(',', '.')).toFixed(
                    2
                  ),
                  currency: 'EUR',
                },
              })
            }
            value={a.parameters?.amount?.value}
            fullWidth
            style={{ marginBottom: 8 }}
          /> */}
          <AmountField
            value={a.parameters.amount}
            onChange={(amount) => onChange({ ...a.parameters, amount })}
          />

          <TextField
            label="Beschreibung"
            onChange={(e) =>
              onChange({
                ...a.parameters,
                description: e.target.value,
              })
            }
            value={a.parameters.description}
            fullWidth
            style={{ marginBottom: 8 }}
          />

          <TextField
            label="Referenz"
            onChange={(e) =>
              onChange({
                ...a.parameters,
                reference: e.target.value,
              })
            }
            value={a.parameters.reference}
            fullWidth
            style={{ marginBottom: 8 }}
          />

          <SignatureDropdown
            onChange={(createdBy) => onChange({ ...a.parameters, createdBy })}
            value={a.parameters.createdBy}
            style={{ marginBottom: 8 }}
          />
        </Box>
      );
    }
    case VoucherActions.cancelRedeem: {
      return (
        <Box>
          <TextField
            label="Reason"
            onChange={(e) =>
              onChange({ ...a.parameters, reason: e.target.value })
            }
            value={a.parameters.reason}
            fullWidth
            style={{ marginBottom: 8 }}
          />

          <SignatureDropdown
            onChange={(createdBy) => onChange({ ...a.parameters, createdBy })}
            value={a.parameters.createdBy}
            style={{ marginBottom: 8 }}
          />
        </Box>
      );
    }
    case VoucherActions.refundPayment: {
      return (
        <Box>
          <AmountField
            value={a.parameters.amount}
            onChange={(amount) => onChange({ ...a.parameters, amount })}
            maxAmount={a.parameters.maxAmount}
          />

          <TextField
            label="Beschreibung"
            onChange={(e) =>
              onChange({
                ...a.parameters,
                description: e.target.value,
              })
            }
            value={a.parameters.description}
            fullWidth
            style={{ marginBottom: 8 }}
          />

          <SignatureDropdown
            onChange={(createdBy) => onChange({ ...a.parameters, createdBy })}
            value={a.parameters.createdBy}
            style={{ marginBottom: 8 }}
          />
        </Box>
      );
    }
    case VoucherActions.redeem: {
      return (
        <Box>
          {!!a.parameters.vatRate && (
            <>
              <AmountField
                value={a.parameters.amount}
                onChange={(amount) => onChange({ ...a.parameters, amount })}
                maxAmount={a.parameters.maxAmount}
              />

              <TextField
                label="Mehrwertsteuer"
                onChange={(e) =>
                  onChange({
                    ...a.parameters,
                    vatRate: e.target.value,
                  })
                }
                value={a.parameters.vatRate}
                fullWidth
                style={{ marginBottom: 8 }}
                select
              >
                <MenuItem value="7">7%</MenuItem>
                <MenuItem value="19">19%</MenuItem>
                <MenuItem value="0">0%</MenuItem>
              </TextField>
            </>
          )}

          {!a.parameters.vatRate && (
            <ItemsTable
              items={a.parameters.items}
              onChange={(items) => onChange({ ...a.parameters, items })}
              maxAmount={a.parameters.maxAmount}
            />
          )}

          <TextField
            label="Beschreibung / Rechnungsnummer"
            onChange={(e) =>
              onChange({
                ...a.parameters,
                description: e.target.value,
              })
            }
            value={a.parameters.description}
            fullWidth
            style={{ marginBottom: 16 }}
          />

          <TextField
            type="datetime-local"
            label="Eingelöst am"
            inputProps={{
              min: new Date().toISOString().slice(0, 8) + '01T00:00',
              max: new Date().toISOString().slice(0, 8) + '31T23:59',
            }}
            onChange={(e) =>
              onChange({
                ...a.parameters,
                createdAt: e.target.value,
              })
            }
            value={a.parameters.createdAt || ''}
            fullWidth
            style={{ marginBottom: 8 }}
            helperText="Datum muss in dem aktuellen Monat liegen wegen der Buchhaltung"
          />

          <SignatureDropdown
            onChange={(createdBy) => onChange({ ...a.parameters, createdBy })}
            value={a.parameters.createdBy}
            style={{ marginBottom: 8 }}
          />
        </Box>
      );
    }
    case VoucherActions.editRedeem: {
      return (
        <Box>
          {!a.parameters.items?.length && (
            <>
              <AmountField
                value={a.parameters.amount}
                onChange={(amount) => onChange({ ...a.parameters, amount })}
              />

              <TextField
                label="Mehrwertsteuer"
                onChange={(e) =>
                  onChange({
                    ...a.parameters,
                    vatRate: e.target.value,
                  })
                }
                value={a.parameters.vatRate}
                fullWidth
                style={{ marginBottom: 8 }}
                select
              >
                <MenuItem value="7">7%</MenuItem>
                <MenuItem value="19">19%</MenuItem>
                <MenuItem value="0">0%</MenuItem>
              </TextField>
            </>
          )}

          {!!a.parameters.items && (
            <ItemsTable
              items={a.parameters.items}
              onChange={(items) => onChange({ ...a.parameters, items })}
            />
          )}

          <TextField
            label="Beschreibung / Rechnungsnummer"
            onChange={(e) =>
              onChange({
                ...a.parameters,
                description: e.target.value,
              })
            }
            value={a.parameters.description}
            fullWidth
            style={{ marginBottom: 8 }}
          />

          <SignatureDropdown
            onChange={(updatedBy) => onChange({ ...a.parameters, updatedBy })}
            value={a.parameters.updatedBy}
            style={{ marginBottom: 8 }}
          />
        </Box>
      );
    }
    case VoucherActions.sendPerEmail: {
      return (
        <Box>
          <TextField
            label="Email"
            onChange={(e) =>
              onChange({
                ...a.parameters,
                email: e.target.value,
              })
            }
            value={a.parameters.email}
            fullWidth
            style={{ marginBottom: 8 }}
          />
        </Box>
      );
    }
    case VoucherActions.downloadPdf: {
      return (
        <Box>
          <DesignPicker
            value={a.parameters.design ?? ''}
            onChange={(design) => onChange({ ...a.parameters, design })}
            voucherId={a.parameters.voucherId}
          />
        </Box>
      );
    }
    default: {
      break;
    }
  }
  return <></>;
};

const VoucherActionModal = () => {
  const ctx = useVoucher();

  if (!ctx.action) return <></>;

  return (
    <Modal
      open={!!ctx.action}
      maxWidth="sm"
      onClose={() => ctx.setAction(null)}
      title={title(ctx.action.action)}
      titleTranslation="vouchers"
      stopAwayClick
      className="voucher-action-modal"
      actions={[
        { id: 'cancel', label: 'Cancel', onClick: () => ctx.setAction(null) },
        {
          id: 'submit',
          label: 'Send',
          onClick: () => ctx.action && ctx.submitVoucherAction(ctx.action),
        },
      ]}
    >
      <VoucherAction
        {...ctx.action}
        onChange={(parameters) =>
          ctx.setAction(
            parameters ? ({ ...ctx.action, parameters } as any) : null
          )
        }
      />
    </Modal>
  );
};

export default VoucherActionModal;
