import React from 'react';
import Button from 'Components/Atoms/Button';
import Box from 'Components/Atoms/Box';
import { Lock, Clear, LockOpen } from 'Components/Atoms/Icons';
import TotalReservationsTerraceButton from './TotalReservationsTerraceButton';
import useProtectedAction from 'utils/useProtectedAction';

export type TotalReservationsProps = {
  closed: boolean;
  onAction: (type: 'terrace' | 'close') => void;
  fullWidth?: boolean;
  margin?: string;
};

const TotalReservationsResButton = ({
  closed,
  onAction,
  fullWidth = false,
  margin,
}: TotalReservationsProps) => {
  const protectedAction = useProtectedAction();

  return (
    <>
      {!closed ? (
        <Button
          fullWidth={fullWidth}
          onClick={() => {
            protectedAction(
              'chefWaiter',
              '',
              () => {
                onAction('close');
              },
              'reservations.blockedTime.write'
            );
          }}
          variant="outlined"
          endIcon={() => (
            <Lock
              style={{ color: 'var(--color-critical)', alignSelf: 'center' }}
            />
          )}
          typographyProps={{
            variant: 'text-2',
            style: { color: 'var(--color-critical)' },
          }}
          translation="reservations"
          style={{ borderColor: 'var(--color-critical)', margin: margin }}
        >
          Close Reservation
        </Button>
      ) : (
        <Button
          fullWidth={fullWidth}
          onClick={() => {
            protectedAction(
              'chefWaiter',
              '',
              () => {
                onAction('close');
              },
              'reservations.blockedTime.write'
            );
          }}
          variant="outlined"
          endIcon={() => (
            <LockOpen
              style={{ color: 'var(--color-primary)', alignSelf: 'center' }}
            />
          )}
          typographyProps={{
            variant: 'text-2',
            style: { color: 'var(--color-primary)' },
          }}
          translation="reservations"
          style={{
            borderColor: 'var(--color-primary)',
            margin: margin,
          }}
        >
          Open Reservation
        </Button>
      )}
    </>
  );
};

export default TotalReservationsResButton;
