import React, { useState } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import { Translations } from 'utils/types';
import Typography from 'Components/Atoms/Typography';
import ImageFieldButtons from './Components/ImageFieldButtons';
import { noop } from 'utils/helper';
import { useEffect } from 'react';
import { useContext } from 'react';
import { ImageContext } from 'Contexts/ImageContext';
import ImageFieldMultiple from './Components/ImageFieldMultiple';
import ImageFieldOneImage from './Components/ImageFieldOneImage';

export type ImageFieldProps = {
  value: null | string | (string | null)[];
  fallback?: null | string;
  multiple?: boolean;
  type?: 'id' | 'url';
  onChange: (val: null | string | (string | null)[]) => void;
  label?: string;
  labelTranslation?: Translations;
  previewType?: 'div' | 'img';
  imageStyle?: React.CSSProperties;
  backgroundStyle?: React.CSSProperties;
  simpleStyle?: boolean;
  typoClassName?: string;
  style?: React.CSSProperties;
  small?: boolean;
  disabled?: boolean;
  templates?: string[];
  search?: string | null;
};

const ImageField: React.FC<ImageFieldProps> = ({
  fallback = null,
  value = null,
  multiple = false,
  type = 'id',
  label = '',
  labelTranslation,
  previewType = 'img',
  imageStyle,
  backgroundStyle,
  onChange = noop,
  simpleStyle = false,
  typoClassName = '',
  style,
  small = false,
  disabled = false,
  templates = [],
  search = null,
}) => {
  const { getUrl, openImageModal, getImages } = useContext(ImageContext);
  const [imageUrl, setimageUrl] = useState<string>('');
  const [imageArray, setimageArray] = useState<
    { id: string; url: string; originalName: string }[] | null
  >(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getImageUrl = async () => {
      setLoading(true);
      if (multiple) {
        if (!value || (value as any).length === 0) {
          setimageArray([]);
        } else if (Array.isArray(value)) {
          const images = await getImages(value, type);
          setimageArray(images);
        }
      } else if (typeof value === 'string' || value || fallback) {
        if (type === 'id' && !!(value || fallback)) {
          const url = await getUrl((value || fallback || '') as string, true);
          setimageUrl(url);
        } else if (type === 'url' && !!(value || fallback)) {
          setimageUrl((value || fallback || '') as string);
        }
      } else if (!value) {
        setimageUrl('');
      }
      setLoading(false);
    };
    getImageUrl();
  }, [value, fallback, type]);

  console.log({
    imageArray,
  });

  return (
    <Box className="image-field" style={style}>
      {!small && (
        <>
          {!!label && (
            <Typography
              block
              className={typoClassName}
              variant="text-3"
              style={{ marginBottom: 8 }}
              translation={labelTranslation}
            >
              {label}
            </Typography>
          )}
          <Typography
            block
            variant="text-3"
            color="subdued"
            style={{ marginBottom: 8 }}
          >
            Only JPG or PNG and maximum 3MB.
          </Typography>
        </>
      )}
      <Box flex style={{ alignItems: 'flex-start' }}>
        {multiple ? (
          <ImageFieldMultiple
            {...{ imageArray, setimageArray, onChange, type, loading, search }}
          />
        ) : (
          <ImageFieldOneImage
            {...{
              imageUrl,
              imageStyle,
              backgroundStyle,
              previewType,
              loading,
              simpleStyle,
              openImageModal,
              onChange,
              type,
              search,
            }}
          />
        )}
        {templates.length > 0 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              marginLeft: 10,
              height: '100%',
            }}
          >
            {templates.map((template) => (
              <Box
                key={template}
                style={{
                  backgroundImage: `url('${template}')`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  height: 60,
                  width: 80,
                  borderRadius: 10,
                  marginBottom: 10,
                  border:
                    imageUrl === template
                      ? '2px solid var(--color-primary)'
                      : '2px solid transparent',
                }}
                onClick={() => {
                  if (multiple) {
                    onChange([...(value as string[]), template]);
                  } else {
                    onChange(template);
                  }
                }}
              ></Box>
            ))}
          </Box>
        )}
      </Box>

      {!simpleStyle && (
        <ImageFieldButtons
          {...{
            value,
            multiple,
            onChange,
            openImageModal,
            type,
            disabled,
            search,
          }}
        />
      )}
    </Box>
  );
};

export default ImageField;
