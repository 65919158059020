import Box from "Components/Atoms/Box";
import Typography from "Components/Atoms/Typography";
import React from "react";
import { classHelper, maxMin } from "utils/helper";

type TableNameProps = {
  name: string;
  timeline?: {
    current: number;
    total: number;
  } | null;
  blocked: boolean;
  alert: boolean;
  chairColor: string;
  tableColor: string;
  size: number;
};

const TableName: React.FC<TableNameProps> = ({
  name,
  blocked,
  alert,
  chairColor,
  timeline,
  size,
  tableColor,
}) => {
  return (
    <Box
      className={classHelper([
        "table-name",
        blocked && "table-blocked",
        alert && "table-alert",
      ])}
      style={{
        width: maxMin(size * 5, 0, 100),
        height: maxMin(size * 5, 0, 100),
      }}
    >
      {!blocked && (
        <Typography
          weight="medium"
          component="span"
          translation={null}
          style={{ fontSize: `${maxMin(size / 10, 0.75, 1.25)}rem` }}
          textAlign="center"
        >
          {name}
        </Typography>
      )}
      {!blocked && timeline && (
        <svg
          width="100%"
          viewBox="0 0 104 18"
          fill="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="2"
            y="2"
            width="100"
            height="14"
            rx="9"
            stroke={chairColor}
            strokeWidth="2"
            fill={chairColor}
          />
          <rect
            x="2"
            y="2"
            className="timeline-bar"
            width={Math.min(
              Math.round((timeline.current / timeline.total) * 100),
              100
            )}
            height="14"
            rx="7"
            fill="inherit"
          />
        </svg>
      )}
    </Box>
  );
};

export default TableName;
