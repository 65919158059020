import React, { useCallback, useMemo, useState, useEffect } from 'react';
import isHotkey from 'is-hotkey';
import {
  Editable,
  withReact,
  useSlate,
  Slate,
  ReactEditor,
  useSlateStatic,
  useReadOnly,
} from 'slate-react';
import {
  Editor,
  Transforms,
  createEditor,
  Descendant,
  Element as SlateElement,
  BaseEditor,
} from 'slate';
import './styles.scss';

import DefaultButton, { ButtonProps } from 'Components/Atoms/Button';
import Box from 'Components/Atoms/Box';
import {
  CheckCircle,
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatQuote,
  FormatUnderlined,
  LooksOne,
  LooksTwo,
} from 'Components/Atoms/Icons';
import Checkbox from 'Components/Atoms/Checkbox';
import Divider from 'Components/Atoms/Divider';
import { uniqueId } from 'lodash';
import { noop } from 'utils/helper';

const Button: React.FC<
  ButtonProps & {
    active: boolean;
    icon: (p: { size: number; color: string }) => React.ReactNode;
  }
> = ({ active, icon, ...props }) => {
  return (
    <DefaultButton {...props} variant={active ? 'outlined' : 'default'}>
      {icon({ size: 16, color: 'inherited' })}
    </DefaultButton>
  );
};

const HOTKEYS: Record<string, string> = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

type Props = {
  value: Descendant[];
  onChange: (value: Descendant[]) => void;
  onBlur?: (value: Descendant[]) => void;
  placeholder?: string;
  autoFocus?: boolean;
  style?: React.CSSProperties;
  onlyLimitedValues?: boolean;
  reload?: any;
  onClick?: () => void;
  extraButtons?: {
    icon: (p: { size: number; color: string }) => React.ReactNode;
    onClick: () => void;
    active?: boolean;
    title?: string;
  }[];
};

let timeout: NodeJS.Timeout;

const TextEditor: React.FC<Props> = ({
  value,
  onChange,
  onBlur,
  placeholder = 'Enter some text here...',
  autoFocus,
  style,
  onlyLimitedValues = false,
  reload,
  onClick = noop,
  extraButtons,
}) => {
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(
    () => withReact(createEditor() as ReactEditor),
    [reload]
  );

  const [id] = useState(uniqueId());

  const [internalValue, setinternalValue] = useState(value);

  useEffect(() => {
    try {
      console.log({ value });
      if ((value?.length ?? 0) < (internalValue?.length ?? 0)) {
        editor.selection = {
          anchor: { offset: 0, path: [0, 0] },
          focus: { offset: 0, path: [0, 0] },
        };
      }

      setinternalValue(value);
    } catch (error) {
      console.warn(error);
    }
  }, [value]);

  const handleChange = (value: Descendant[]) => {
    try {
      let val =
        value.length === 0
          ? [{ type: 'paragraph', children: [{ text: '' }] }]
          : value;
      setinternalValue(val);
      onChange(val);
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => onBlur?.(val), 1000);
    } catch (error) {
      console.warn(error);
    }
  };

  const val = useMemo(() => {
    try {
      return internalValue.length === 0
        ? [{ type: 'paragraph', children: [{ text: '' }] }]
        : (internalValue as any[]).map(({ text, ...i }) => {
            if (typeof text === 'string') {
              return {
                ...i,
                type: 'paragraph',
                children: [{ text }],
              };
            }
            return i;
          });
    } catch (error) {
      console.warn(error);
      return [{ type: 'paragraph', children: [{ text: '' }] }];
    }
  }, [internalValue]);

  return (
    <Box id={id} className="slate-texteditor" style={style}>
      <Slate editor={editor} value={val} onChange={handleChange}>
        <Box flex className="toolbar" padding="sm">
          <Box
            style={{
              display: 'grid',
              gap: 4,
              gridTemplateColumns: '46px 46px 46px',
              marginRight: 8,
            }}
          >
            <MarkButton
              format="bold"
              icon={(p: any) => <FormatBold {...p} />}
            />
            <MarkButton
              format="italic"
              icon={(p: any) => <FormatItalic {...p} />}
            />
            <MarkButton
              format="underline"
              icon={(p: any) => <FormatUnderlined {...p} />}
            />
          </Box>
          {!onlyLimitedValues && (
            <>
              <Box
                style={{
                  display: 'grid',
                  gap: 4,
                  gridTemplateColumns: '46px 46px',
                  marginRight: 8,
                }}
              >
                <BlockButton
                  format="heading-one"
                  icon={(p: any) => <LooksOne {...p} />}
                />
                <BlockButton
                  format="heading-two"
                  icon={(p: any) => <LooksTwo {...p} />}
                />
              </Box>
              <Box
                style={{
                  display: 'grid',
                  gap: 4,
                  gridTemplateColumns: '46px 46px 46px 46px',
                  marginRight: 8,
                  width: 'auto',
                }}
              >
                <BlockButton
                  format="block-quote"
                  icon={(p: any) => <FormatQuote {...p} />}
                />
                <BlockButton
                  format="numbered-list"
                  icon={(p: any) => <FormatListNumbered {...p} />}
                />
                <BlockButton
                  format="bulleted-list"
                  icon={(p: any) => <FormatListBulleted {...p} />}
                />
                <BlockButton
                  format="check-list-item"
                  icon={(p: any) => <CheckCircle {...p} />}
                />
              </Box>
            </>
          )}
          {!!extraButtons?.length && (
            <Box
              style={{
                marginRight: 8,
                width: 'auto',
              }}
            >
              {extraButtons.map((extraButton, i) => (
                <DefaultButton
                  key={i}
                  onClick={extraButton.onClick}
                  variant={extraButton.active ? 'outlined' : 'default'}
                >
                  {extraButton.icon({ size: 16, color: 'inherited' })}{' '}
                </DefaultButton>
              ))}
            </Box>
          )}
        </Box>
        <Divider />
        <Box className="texteditor-inner">
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder={placeholder}
            spellCheck
            autoFocus={autoFocus}
            onKeyDown={(event) => {
              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event as any)) {
                  event.preventDefault();
                  const mark = HOTKEYS[hotkey];
                  toggleMark(editor, mark);
                }
              }
            }}
            onClick={onClick}
          />
        </Box>
      </Slate>
    </Box>
  );
};

const toggleBlock = (editor: BaseEditor, format: string) => {
  try {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    const options: any = {
      match: (n: Node & { type: string }) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        LIST_TYPES.includes(n.type),
      split: true,
    };

    Transforms.unwrapNodes(editor, options);

    const newProperties: any = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
      const block = { type: format, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  } catch (error) {
    console.error(error);
  }
};

const toggleMark = (editor: BaseEditor, format: string) => {
  try {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  } catch (error) {
    console.error(error);
  }
};

const isBlockActive = (editor: BaseEditor, format: string) => {
  try {
    const { selection } = editor;
    if (!selection) return false;

    const options: any = {
      at: Editor.unhangRange(editor, selection),
      match: (n: Node & { type: string }) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    };

    const [match] = Editor.nodes(editor, options);

    return !!match;
  } catch (error) {
    console.error(error);
  }

  return false;
};

const isMarkActive = (editor: BaseEditor, format: any) => {
  try {
    const marks: any = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  } catch (error) {
    console.error(error);
  }

  return false;
};

const Element: React.FC<any> = ({ attributes, children, element }) => {
  switch (element?.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'check-list-item':
      return (
        <CheckListItemElement {...{ attributes, element }}>
          {children}
        </CheckListItemElement>
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Leaf: React.FC<any> = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const BlockButton: React.FC<any> = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={(event: React.MouseEvent) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
      icon={icon}
    />
  );
};

const MarkButton: React.FC<any> = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
      icon={icon}
    ></Button>
  );
};

const CheckListItemElement: React.FC<any> = ({
  attributes,
  children,
  element,
}) => {
  const editor = withReact(useSlateStatic() as ReactEditor);
  const readOnly = useReadOnly();
  const { checked } = element;
  return (
    <div {...attributes} className="slateCheckbox">
      <span contentEditable={false} className="first-span">
        <Checkbox
          checked={checked}
          onChange={(event) => {
            try {
              const path = ReactEditor.findPath(editor, element);
              const newProperties: any = {
                checked: event.target.checked,
              };
              Transforms.setNodes(editor, newProperties, { at: path });
            } catch (error) {
              console.error(error);
            }
          }}
        />
      </span>
      <span
        contentEditable={!readOnly}
        suppressContentEditableWarning
        className="second-span"
        style={{
          opacity: checked ? 0.666 : 1,
          textDecoration: !checked ? 'none' : 'line-through',
        }}
      >
        {children}
      </span>
    </div>
  );
};
export default TextEditor;
